import { Popup, ScrollView, SelectBox, TextArea, TextBox } from "devextreme-react"
import { ToolbarItem } from "devextreme-react/autocomplete"
import ArrayStore from "devextreme/data/array_store";
import { useCallback, useMemo, useState } from "react";
import { IStammdatenData, IStammdatenSatzartData } from "./interfaces";
import { GetData } from "../../../global/communication";
import { setConfirm, setToast, useAppDispatch } from "../../../state";
import { handleContentReadySelectBox, handleContentReadyTextBox } from "../../../global/hilfs_functions";
import { MDButton } from "../../../global/components";




interface IStammdatenVerwaltePopupProps {
    callback: (update?: boolean) => void,
    row?: IStammdatenData | null,
    satzarte: IStammdatenSatzartData[],
}

const StammDataVerwaltePopup: React.FC<IStammdatenVerwaltePopupProps> = ({ callback, row, satzarte }): JSX.Element => {

    const isAdd = row === undefined;
    const satzart: IStammdatenSatzartData[] = satzarte;
    const date_source_satzart = useMemo(() =>
        new ArrayStore({
            data: satzart,
            key: 'satzart',
        }), [satzart]);
    const [satzartValue, setSatzartValue] = useState(!isAdd ? row?.f001 : undefined);
    const [bezeichnung, setBezeichnung] = useState(!isAdd ? row?.f004 : "");
    const dispatch = useAppDispatch();


    const handleClickSpeichern = useCallback(() => {
        if (!bezeichnung) {
            dispatch(setToast({
                message: "Bezeichnung darf nicht leer sein.",
                type: "error"
            }));
            return
        }
        let obj = isAdd ?
            {
                Operation: "InsertStammdaten",
                Satzartnummer: satzartValue,
                Bezeichnung: bezeichnung,
            } :
            {
                Operation: "UpdateStammdaten",
                Satzart: row!.f001,
                Sachbearbeiternummer: row?.f002,
                Nummer: row?.f003,
                Bezeichnung: bezeichnung
            };
        GetData(obj, dispatch).then((res) => {
            if (!res) {
                callback();
                return
            }

            dispatch(setToast({
                message: "Stammdaten " + bezeichnung + " wurde gespeichert.",
                type: "success",
                position: "bottom center"
            }))

            !isAdd && callback(true)
        });
    }, [bezeichnung, dispatch, isAdd, satzartValue, row, callback]);

    const handleClickSchließen = useCallback(() => {
        callback(true);
    }, [callback]);


    const handleHidePopup = useCallback(() => {
        callback();
    }, [callback])


    const memo_option_loeschen = useMemo(() => {
        return {
            text: 'Löschen',
            type: "danger",
            onClick: () => { delete_stammdaten(dispatch, row, callback) },
        }
    }, [dispatch, row, callback]);

    const memo_option_maske_schließen = useMemo(() => {
        return {
            text: 'Maske schließen',
            onClick: handleClickSchließen,
        }
    }, [handleClickSchließen]);
    const memo_option_speichern = useMemo(() => {
        return {
            text: 'Speichern',
            onClick: handleClickSpeichern,
        }
    }, [handleClickSpeichern]);

    const RenderePopupTitle = useCallback(() => {
        return (<h2 className="title_popup">{isAdd ? "Neue Stammdaten" : "Stammdaten bearbeiten"}</h2>)
    }, [isAdd]);

    return (
        <>
            <Popup
                visible={true}
                onHiding={handleHidePopup}
                dragEnabled={true}
                hideOnOutsideClick={true}
                fullScreen={false}
                showCloseButton={true}
                showTitle={true}
                height='auto'
                maxHeight="70vh"
                width='auto'
                maxWidth="90vw"
            >
                <ToolbarItem
                    toolbar="top"
                    location={'before'}
                    render={RenderePopupTitle}
                />
                {!isAdd &&
                    <ToolbarItem
                        widget="dxButton"
                        toolbar="bottom"
                        location="before"
                        options={memo_option_loeschen}
                    />}
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={isAdd ? memo_option_maske_schließen : memo_option_speichern}
                />
                <ScrollView>
                    <div className="row p-2 p-md-3">
                        <div className="col-12  p-1 p-md-2">
                            <SelectBox
                                dataSource={date_source_satzart}
                                displayExpr="bezeichnung"
                                valueExpr="satzart"
                                //acceptCustomValue={true}
                                value={satzartValue}
                                //onCustomItemCreating={customItemCreating}
                                onValueChange={setSatzartValue}
                                label='Satzart'
                                labelMode='floating'
                                stylingMode='outlined'
                                onContentReady={handleContentReadySelectBox}
                            // disabled={!isAdd}
                            />
                        </div>
                        <div className="col-12  p-1 p-md-2">
                            {
                                satzartValue === 5 ?
                                    <TextArea
                                        label="Bezeichnung"
                                        labelMode="floating"
                                        stylingMode="outlined"
                                        onValueChange={setBezeichnung}
                                        value={bezeichnung}
                                        valueChangeEvent="input"
                                        inputAttr={{ rows: "3" }}
                                        maxLength={1024}
                                        onContentReady={handleContentReadyTextBox}
                                    /> :
                                    <TextBox
                                        label="Bezeichnung"
                                        labelMode="floating"
                                        stylingMode="outlined"
                                        onValueChange={setBezeichnung}
                                        value={bezeichnung}
                                        valueChangeEvent="input"
                                        maxLength={60}
                                        onContentReady={handleContentReadyTextBox}
                                    />
                            }
                        </div>
                        {isAdd &&
                            < div className="col-12 d-flex justify-content-center align-items-center pt-4">
                                <MDButton
                                    page="satz_art_add"
                                    button_text="Speichern"
                                    onClick={handleClickSpeichern}
                                    stylingMode="contained"
                                    type="default"
                                />
                            </div>}
                    </div>
                </ScrollView>

            </Popup >
        </>
    )
}

export default StammDataVerwaltePopup;

const delete_stammdaten = (dispatch: any, data: any, callback: any) => {
    dispatch(setConfirm({
        body:
            <div className="alert alert-danger">
                <p>Wollen Sie den Stammdateneintrag mit der Bezeichnung <b> {data.f004} </b> wirklich löschen?</p>
            </div>,
        on_ok: () => {
            GetData({
                Operation: "DeleteStammdaten",
                Satzart: data.f001,
                Sachbearbeiternummer: data.f002,
                Nummer: data.f003
            }, dispatch).then((res) => {
                if (!res) return
                dispatch(setToast({
                    message: "Der Stammdateneintrag wurde gelöscht.",
                    type: "success",
                    position: "bottom center"
                }));
                callback(true);
            });
        },
        title: "Stammdaten löschen",
    }));
}


