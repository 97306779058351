import { DataGrid, TextBox } from "devextreme-react";
import { Column, Toolbar, Item as ItemGridToolbar, Button as ButtonGrid, Scrolling, Paging } from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ISelectedItemStrassen } from "..";
import { GetData } from "../../../../global/communication";
import { orte_columns } from "../../../../sonstiges/columns";
import { icon_edit, icon_pdf, MAX_ITEMS_DATA_GRID } from "../../../../global/const";
import { exportGridAsPDF, get_header_pdf_search, get_search_as_filter, handleContentReadyDataGrid, handleContentReadyTextBox } from "../../../../global/hilfs_functions";
import { MDButton } from "../../../../global/components";
import { IOrt } from "../interfaces";
import StrassenExport from "../strassen_export";
import StrassenUpload from "../strassen_import";
import OrteAdd from "./add";



interface IOrteGridProps {
    selectedItem: any,
    setSelectedItem: (obj: ISelectedItemStrassen) => void,
    setBearbeitePopup: (value: { show: boolean, row: IOrt | null }) => void,
    refresh_grid: boolean,
}


const columns = orte_columns();
const OrteGrid: React.FC<IOrteGridProps> = ({ setSelectedItem, selectedItem, setBearbeitePopup, refresh_grid }) => {
    const refGrid = useRef() as React.MutableRefObject<DataGrid<any, any>>;
    const [searchValue, setSearchValue] = useState("");
    const filter = useMemo(() => get_search_as_filter(searchValue), [searchValue]);
    const store_end = useMemo(() => store(),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [refresh_grid]);

    const get_data = useCallback(() => {
        //setSearchValue("");
        //refGrid && refGrid.current && refGrid.current.instance.clearSorting();
        refGrid && refGrid.current && refGrid.current.instance.refresh();
    }, []);

    useEffect(() => {
        refGrid && refGrid.current && refGrid.current.instance.filter(filter);
    }, [filter]);

    const handleClickOrtSelect = useCallback((e: any) => {
        if (e.event.originalEvent.currentTarget.role !== "button") {
            setSelectedItem({ ...selectedItem, lfdnr_ort: e.data.lfdnr, bezeichnung_ort: e.data.f001 })
        }

    }, [selectedItem, setSelectedItem]);

    const handleBearbeiteOrt = useCallback((e: any) => {
        setBearbeitePopup({ show: true, row: JSON.parse(e.element.getAttribute("data")) })
    }, [setBearbeitePopup]);

    const handlePDF = useCallback(() => {
        let header = get_header_pdf_search(searchValue);
        exportGridAsPDF(refGrid, "Orte.pdf", header, "landscape", "striped")
    }, [searchValue]);

    const RenderButtonOrtBearbeite = useCallback((e: any) => {
        return (
            <MDButton
                page='strassen_ort'
                tooltip_text='Ort bearbeiten'
                icon={icon_edit}
                onClick={handleBearbeiteOrt}
                data={e.data}
            />)
    }, [handleBearbeiteOrt]);
    return (
        <div className="data_grid_container  data_grid_with_header mt-2">
            <DataGrid
                dataSource={store_end}
                columnAutoWidth={false}
                rowAlternationEnabled={true}
                // keyExpr="lfdnr"
                wordWrapEnabled={true}
                renderAsync={true}
                noDataText="Keine Orte"
                className=" animate__animated animate__fadeIn cursor_pointer"
                remoteOperations={true}
                ref={refGrid}
                //onRowClick={handleBearbeiteOrt}
                onRowClick={handleClickOrtSelect}
                hoverStateEnabled={true}
                onContentReady={handleContentReadyDataGrid}
            >
                <Scrolling mode="virtual" rowRenderingMode="virtual" preloadEnabled={true} />
                <Paging defaultPageSize={MAX_ITEMS_DATA_GRID} />
                {columns.map((column) => {
                    return (
                        <Column
                            dataField={column.dataField}
                            caption={column.caption}
                            dataType={column.dataType}
                            alignment={column.alignment}
                            cellRender={column.cellRender}
                            key={column.dataField}
                            visible={column.visible}
                        />
                    )
                })}

                <Column type="buttons">
                    <ButtonGrid
                        cssClass={'d-inline-block'}
                        render={RenderButtonOrtBearbeite} />

                </Column>
                <Toolbar>
                    <ItemGridToolbar
                        location={'after'}
                        locateInMenu="never"
                    >
                        <TextBox
                            label="Suche"
                            stylingMode='outlined'
                            mode='text'
                            className="suche_feld"
                            value={searchValue}
                            onValueChange={setSearchValue}
                            showClearButton={true}
                            maxLength={60}
                            onContentReady={handleContentReadyTextBox}>
                        </TextBox>
                    </ItemGridToolbar>
                    <ItemGridToolbar location="after" locateInMenu="auto">
                        <OrteAdd get_data={get_data} />
                    </ItemGridToolbar>
                    <ItemGridToolbar location="after" locateInMenu="auto">
                        <StrassenUpload get_data={get_data} />
                    </ItemGridToolbar>
                    <ItemGridToolbar location="after" locateInMenu="auto">
                        <StrassenExport />
                    </ItemGridToolbar>
                    <ItemGridToolbar location="after" locateInMenu="auto">
                        <MDButton
                            icon={icon_pdf}
                            page="strasse_orte"
                            onClick={handlePDF}
                            tooltip_text="Tabelle als PDF herunterladen"
                        />
                    </ItemGridToolbar>
                </Toolbar>
            </DataGrid></div>
    )
}
export default memo(OrteGrid);


const store = () => {
    return new CustomStore({
        key: ["lfdnr"],
        load(loadOptions: any) {
            //Achtung isLoadingAll => pdf Download
            return GetData(
                {
                    Operation: "GetOrte",
                    skip: (loadOptions.isLoadingAll || loadOptions.requireTotalCount) ? "0" : loadOptions["skip"].toString(),
                    take: loadOptions["isLoadingAll"] ? "1000" : loadOptions["take"].toString(),
                    filter: loadOptions.filter ? loadOptions.filter : "",
                    sortSpalte: loadOptions.sort ? loadOptions.sort[0].selector : "",
                    sortRichtung: loadOptions.sort ? loadOptions.sort[0].desc ? "DESC" : "ASC" : "",
                }, undefined, false
            )
                .then((res) => {
                    if (!res || !res.erfolg)
                        return {
                            data: [],
                            totalCount: 0,
                        }

                    //  if(totalCount === 0){
                    //    setTotalCount(res.totalCount)
                    //  }else{
                    //  totalCount !== parseInt(res.totalCount) && setTotalCount(res.totalCount)
                    // }
                    return {
                        //   data: res.data,
                        data: res.data,
                        totalCount: res.totalCount,

                    }
                });
            //   }


            //  }
        }

    });
}
/*
const RenderButtonOrtDelete: React.FC = () => {
  return(
      <MDButton 
        page='strassen_orte'
        tooltip_text='Ort löschen'
        icon={icon_delete}
        type='danger'
        className="mr-2"
      />)
}*/

