import { Popup, ScrollView, TextBox } from "devextreme-react"
import { ToolbarItem } from "devextreme-react/autocomplete"
import { useCallback, useMemo, useState } from "react";
import { GetData } from "../../global/communication";
import { encodeBASE64, handleContentReadyTextBox } from "../../global/hilfs_functions";
import { resetAlert, resetDataTempObj, resetEinstellungen, resetNotification, setAlert, useAppDispatch } from "../../state";



const PasswortAbgelaufen: React.FC = (): JSX.Element => {
    const [showPopup, setShowPopup] = useState(true);
    const [neuesPasswort, setNeuesPasswort] = useState("");
    const [wiederholenNeuesPasswort, setWiederholenNeuesPasswort] = useState("");
    const dispatch = useAppDispatch();

    const handleClickSpeichern = useCallback((e: any) => {
        if (!neuesPasswort || !wiederholenNeuesPasswort) {
            dispatch(setAlert({
                text: "Das Passwort darf nicht leer sein.",
                type: "Fehler",
            }))
            return
        }
        if (neuesPasswort !== wiederholenNeuesPasswort) {
            dispatch(setAlert({
                text: "Das neue Passwort stimmt nicht mit dessen Wiederholung überein",
                type: "Fehler",
            }))
            return
        }

        setShowPopup(false);
        GetData({ Operation: "ChangePasswortabgelaufen", NeuesPasswortVerschluesselt: encodeBASE64(neuesPasswort) }, dispatch, false)
            .then((res) => {
                if (!res || !res.erfolg) {
                    dispatch(setAlert({
                        text: (res && res.hinweis) ? res.hinweis : "Es ist ein Fehler aufgetreten. Das Passwort konnte nicht geändert werden.",
                        type: "Fehler",
                        onHiding: () => {
                            setShowPopup(true)
                        }
                    }))
                    return
                }
                dispatch(setAlert({
                    text: "Das Passwort wurde geändert. Bitte neu Einloggen.",
                    type: "Hinweis",
                    onHiding: () => {
                        dispatch(resetAlert())
                        GetData({
                            Operation: "Logout",
                        }, dispatch)
                            .then((res) => {
                                if (!res) return
                                dispatch(resetEinstellungen());
                                dispatch(resetNotification());
                                dispatch(resetDataTempObj());
                            });
                    }
                }))
            });
    }, [neuesPasswort, dispatch, wiederholenNeuesPasswort]);

    const memo_setShowPopup = useCallback(() => {
        setShowPopup(false)
    }, []);


    const memo_ButtonSpeichernOptions = useMemo(() => {
        return {
            text: 'Speichern',
            onClick: handleClickSpeichern,
        }
    }, [handleClickSpeichern]);

    // const memo_ComparisonTarget = useMemo(() => neuesPasswort, [neuesPasswort]);

    return (
        <Popup
            visible={showPopup}
            onHiding={memo_setShowPopup}
            dragEnabled={true}
            hideOnOutsideClick={false}
            fullScreen={false}
            showCloseButton={false}
            height='320px'
            maxHeight="70vh"
            maxWidth="500px"
        >
            <ToolbarItem
                toolbar="top"
                location={'before'}
                render={RenderePopupTitle}
            />
            <ToolbarItem
                widget="dxButton"
                toolbar="bottom"
                location="after"
                options={memo_ButtonSpeichernOptions}
            />
            <ScrollView>
                <div className="row p-2 p-md-3">
                    <div className="col-12 p-1 p-md-2">
                        <TextBox
                            label="Neues Passwort"
                            labelMode="floating"
                            stylingMode="outlined"
                            onValueChange={setNeuesPasswort}
                            value={neuesPasswort}
                            valueChangeEvent="input"
                            mode={"password"}
                            maxLength={60}
                            onContentReady={handleContentReadyTextBox}
                        >
                            {/**
                             * 
                            <Validator >
                                {/**Default ist mit trim 
                            <RequiredRule message="Das Passwort darf nicht leer sein" trim={true} />
                        </Validator>
                             */}
                        </TextBox>
                    </div>
                    <div className="col-12 p-1 p-md-2">
                        <TextBox
                            label="Neues Passwort wiederholen"
                            labelMode="floating"
                            stylingMode="outlined"
                            onValueChange={setWiederholenNeuesPasswort}
                            value={wiederholenNeuesPasswort}
                            valueChangeEvent="input"
                            mode={"password"}
                            maxLength={60}
                            onContentReady={handleContentReadyTextBox}
                        >
                            {/**
                             * 
                             * 
                            <Validator >
                                <RequiredRule message="Das Passwort darf nicht leer sein" trim={true} />
                                <CompareRule message="Das neue Passwort stimmt nicht mit dessen Wiederholung überein" comparisonTarget={memo_ComparisonTarget} />
                            </Validator>
                             */}
                        </TextBox>
                    </div>
                </div>
            </ScrollView>

        </Popup >
    )
}

export default PasswortAbgelaufen;


const RenderePopupTitle = () => {
    return (<h2 className="title_popup">Das Passwort ist abgelaufen</h2>)
};

