import { ToolbarItem } from "devextreme-react/autocomplete";
import { Popup, ScrollView, TextArea, TextBox } from 'devextreme-react';
import { useCallback, useMemo, useState } from "react";
import { useScreenSize } from "../../../global/media-query";
import { setConfirm, useAppDispatch } from "../../../state";
import { GetData } from "../../../global/communication";
import { IExportFaelle } from "../interfaces";
import { Seperatorliste } from "../../../global/seperator_liste";
import { handleContentReadyTextBox } from "../../../global/hilfs_functions";

interface IExportBearbeiteFallPopupProps {
    handleClickHidePopup: () => void,
    update_data: () => void,
    dataDB: IExportFaelle,
}

const ExportBearbeiteFallPopup: React.FC<IExportBearbeiteFallPopupProps> = ({ update_data, dataDB, handleClickHidePopup }): JSX.Element => {
    const screenSize = useScreenSize();
    const [data, setData] = useState<IExportFaelle>(dataDB);
    const dispatch = useAppDispatch();
    const handleDeleteFall = useCallback(() => {
        handleClickHidePopup();
        GetData({
            Operation: "DeleteExportFall",
            Exportnummer: data.exportnummer,
            LfdNr: data.lfdnr_fall,
        }, dispatch)
            .then((res) => {
                if (!res) return
                update_data();
            });
    }, [data, update_data, handleClickHidePopup, dispatch]);

    const handleClickSpeichern = useCallback(() => {
        let falldaten = new Seperatorliste("%01", "%02");

        falldaten.setValue("Kennzeichenzusatz", data.kfz_kz_zusatz);
        falldaten.setValue("Fabrikat", data.fabrikat);
        falldaten.setValue("Strasse", data.strasse);
        falldaten.setValue("Hausnummer", data.hausnummer);
        falldaten.setValue("Strassendetail", data.strassendetail);
        falldaten.setValue("Zeugenzeile1", data.zeugenzeile1);
        falldaten.setValue("Zeugenzeile2", data.zeugenzeile2);
        falldaten.setValue("Zeugenzeile3", data.zeugenzeile3);
        falldaten.setValue("Bemerkung", data.bemerkung);
        falldaten.setValue("Notizen", data.notizen);

        GetData({
            Operation: "ChangeExportFall",
            Exportnummer: data.exportnummer,
            LfdNr: data.lfdnr_fall,
            Falldaten: falldaten.getSeperatorliste()
        }, dispatch)
            .then((res) => {
                if (!res) return
                handleClickHidePopup();
                update_data();
            });
    }, [data, dispatch, handleClickHidePopup, update_data]);

    const handleClickLoeschen = useCallback(() => {
        dispatch(setConfirm({
            body:
                <div className="alert alert-danger">
                    <p>Wollen Sie den Fall mit der Laufendennummer <b> {data.lfdnr_fall} </b> wirklich löschen?</p>
                </div>,
            on_ok: handleDeleteFall,
            title: "Fall löschen",
        }));
    }, [data.lfdnr_fall, dispatch, handleDeleteFall]);

    const memo_optios_delete = useMemo(() => {
        return {
            text: 'Löschen',
            stylingMode: "outlined",
            type: "danger",
            onClick: handleClickLoeschen,
        }
    }, [handleClickLoeschen]);

    const memo_optios_speichern = useMemo(() => {
        return {
            text: 'Speichern',
            onClick: handleClickSpeichern,
        }
    }, [handleClickSpeichern]);

    const memo_optios_abbrechen = useMemo(() => {
        return {
            text: 'Abbrechen',
            onClick: handleClickHidePopup,
        }
    }, [handleClickHidePopup]);


    const memo_kfzkz = useCallback((e: any) => {
        setData(prev => {
            return { ...prev, kfz_kz_zusatz: e }
        })
    }, []);

    const memo_fabrikat = useCallback((e: any) => {
        setData(prev => {
            return { ...prev, fabrikat: e }
        })
    }, []);
    const memo_strasse = useCallback((e: any) => {
        setData(prev => {
            return { ...prev, strasse: e }
        })
    }, []);
    const memo_hausnummer = useCallback((e: any) => {
        setData(prev => {
            return { ...prev, hausnummer: e }
        })
    }, []);
    const memo_strassendetail = useCallback((e: any) => {
        setData(prev => {
            return { ...prev, strassendetail: e }
        })
    }, []);
    const memo_zeugenzeile1 = useCallback((e: any) => {
        setData(prev => {
            return { ...prev, zeugenzeile1: e }
        })
    }, []);
    const memo_zeugenzeile2 = useCallback((e: any) => {
        setData(prev => {
            return { ...prev, zeugenzeile2: e }
        })
    }, []);
    const memo_zeugenzeile3 = useCallback((e: any) => {
        setData(prev => {
            return { ...prev, zeugenzeile3: e }
        })
    }, []);
    const memo_bemerkung = useCallback((e: any) => {
        setData(prev => {
            return { ...prev, bemerkung: e }
        })
    }, []);
    const memo_notiz = useCallback((e: any) => {
        setData(prev => {
            return { ...prev, notizen: e }
        })
    }, []);

    const RenderePopupTitle = useCallback(() => {
        return (<h2 className="title_popup">Export bearbeiten ({data.kfz_kz})</h2>)
    }, [data]);


    return (
        <>
            <Popup
                visible={true}
                onHiding={handleClickHidePopup}
                hideOnOutsideClick={true}
                showCloseButton={true}
                animation={undefined}
                height="auto"
                maxHeight="65vh"
                maxWidth={(screenSize.isMedium || screenSize.isSmall) ? "80vw" : "600px"}
            ><ToolbarItem
                    toolbar="top"
                    location={'before'}
                    render={RenderePopupTitle}
                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="before"
                    options={memo_optios_delete}
                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={memo_optios_speichern}
                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={memo_optios_abbrechen}
                />
                <ScrollView>
                    <div className="row p-2 p-md-3">
                        <div className="col-12 mb-1 mb-md-2">
                            <p><strong>Fahrzeug:</strong></p>
                        </div>
                        <div className="col-12 col-md-6 p-2">
                            <TextBox
                                label="Kennzeichen"
                                labelMode="floating"
                                stylingMode="outlined"
                                defaultValue={data.kfz_kz}
                                valueChangeEvent="input"
                                disabled={true}
                                onContentReady={handleContentReadyTextBox}
                            />
                        </div>
                        <div className="col-12 col-md-6 p-2">
                            <TextBox
                                label="Kennzeichenzusatz"
                                labelMode="floating"
                                stylingMode="outlined"
                                onValueChange={memo_kfzkz}
                                value={data.kfz_kz_zusatz}
                                valueChangeEvent="input"
                                maxLength={60}
                                onContentReady={handleContentReadyTextBox}
                            />
                        </div>
                        <div className="col-12 col-md-6 p-2">
                            <TextBox
                                label="Fabrikat"
                                labelMode="floating"
                                stylingMode="outlined"
                                onValueChange={memo_fabrikat}
                                value={data.fabrikat}
                                valueChangeEvent="input"
                                maxLength={60}
                                onContentReady={handleContentReadyTextBox}
                            />
                        </div>
                        <div className="col-12 mb-1 mb-md-2 mt-3 mt-md-4">
                            <p ><strong>Tatort:</strong></p>
                        </div>
                        <div className="col-12 col-md-6 p-2">
                            <TextBox
                                label="Ort"
                                labelMode="floating"
                                stylingMode="outlined"
                                defaultValue={data.ort}
                                valueChangeEvent="input"
                                disabled={true}
                                onContentReady={handleContentReadyTextBox}
                            />
                        </div>
                        <div className="col-12 col-md-6 p-2">
                            <TextBox
                                label="Bezirk"
                                labelMode="floating"
                                stylingMode="outlined"
                                defaultValue={data.bezirk}
                                valueChangeEvent="input"
                                disabled={true}
                                onContentReady={handleContentReadyTextBox}
                            />
                        </div>
                        <div className="col-12 col-md-6 p-2">
                            <TextBox
                                label="Straße"
                                labelMode="floating"
                                stylingMode="outlined"
                                onValueChange={memo_strasse}
                                value={data.strasse}
                                valueChangeEvent="input"
                                maxLength={60}
                                onContentReady={handleContentReadyTextBox}
                            />     </div>
                        <div className="col-12 col-md-6 p-2">
                            <TextBox
                                label="Haus-Nr"
                                labelMode="floating"
                                stylingMode="outlined"
                                onValueChange={memo_hausnummer}
                                value={data.hausnummer}
                                valueChangeEvent="input"
                                maxLength={30}
                                onContentReady={handleContentReadyTextBox}
                            />     </div>
                        <div className="col-12 col-md-6 p-2">
                            <TextBox
                                label="Straßendetail"
                                labelMode="floating"
                                stylingMode="outlined"
                                onValueChange={memo_strassendetail}
                                value={data.strassendetail}
                                valueChangeEvent="input"
                                maxLength={60}
                                onContentReady={handleContentReadyTextBox}
                            />
                        </div>
                        <div className="col-12 mb-1 mb-md-2 mt-3 mt-md-4">
                            <p ><strong>Zeugen:</strong></p>
                        </div>
                        <div className="col-12 col-md-6 p-2">
                            <TextBox
                                label="Zeugenzeile 1"
                                labelMode="floating"
                                stylingMode="outlined"
                                onValueChange={memo_zeugenzeile1}
                                value={data.zeugenzeile1}
                                valueChangeEvent="input"
                                maxLength={60}
                                onContentReady={handleContentReadyTextBox}
                            />
                        </div>
                        <div className="col-12 col-md-6 p-2">
                            <TextBox
                                label="Zeugenzeile 2"
                                labelMode="floating"
                                stylingMode="outlined"
                                onValueChange={memo_zeugenzeile2}
                                value={data.zeugenzeile2}
                                valueChangeEvent="input"
                                maxLength={60}
                                onContentReady={handleContentReadyTextBox}
                            />
                        </div>
                        <div className="col-12 col-md-6 p-2">
                            <TextBox
                                label="Zeugenzeile 3"
                                labelMode="floating"
                                stylingMode="outlined"
                                onValueChange={memo_zeugenzeile3}
                                value={data.zeugenzeile3}
                                valueChangeEvent="input"
                                maxLength={60}
                                onContentReady={handleContentReadyTextBox}
                            />
                        </div>
                        <div className="col-12 mb-1 mb-md-2 mt-3 mt-md-4">
                            <p ><strong>Weiter Angaben:</strong></p>
                        </div>
                        <div className="col-12 p-2">
                            <TextBox
                                label="Bemerkung"
                                labelMode="floating"
                                stylingMode="outlined"
                                onValueChange={memo_bemerkung}
                                value={data.bemerkung}
                                valueChangeEvent="input"
                                maxLength={512}
                                onContentReady={handleContentReadyTextBox}
                            />
                        </div>
                        <div className="col-12 p-2">
                            <TextArea
                                label="Notiz"
                                labelMode="floating"
                                stylingMode="outlined"
                                value={data.notizen}
                                onValueChange={memo_notiz}
                                valueChangeEvent="input"
                                inputAttr={{ rows: "5" }}
                                maxLength={1024}
                                onContentReady={handleContentReadyTextBox}
                            />
                        </div>
                    </div>
                </ScrollView>
            </Popup>
        </>
    )
}

export default ExportBearbeiteFallPopup;


