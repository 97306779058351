import { DataGrid, Popup, ScrollView } from "devextreme-react";
import { Column, MasterDetail, Button } from "devextreme-react/data-grid";
import { ReactElement, useCallback, useEffect, useMemo, useState } from "react";
import { ToolbarItem } from "devextreme-react/autocomplete";
import { IDateienHerunterladen } from "./interfaces";
import { GetData } from "../../global/communication";
import { useAppDispatch } from "../../state";
import { MDButton } from "../../global/components";
import { icon_download } from "../../global/const";

const MasterDetailSection = ({ data }: any) => {
    let arr = data.data.detailtabelle;

    return (
        <div className="p-2 pl-4">
            {arr.map((item: any, i: number) => {
                return <p key={i}>{item}</p>
            })
            }
        </div>)
}

const DatenexportHerunterladen: React.FC = (): ReactElement => {

    const [showPopup, setShowPopup] = useState(false);
    const [selectedData, setSelectedData] = useState<any>();
    const [data, setData] = useState<IDateienHerunterladen[]>([]);
    const dispatch = useAppDispatch();

    const download_datei = useCallback(() => {
        GetData(
            { Operation: "CountExportDownload", Dateiname: selectedData.filename }, dispatch
        )
            .then((res) => {
                if (!res) return
                window.location.href = '/FileRequest/' + selectedData.filename + '?Operation=DownloadExport';
            });
    }, [selectedData, dispatch]);

    useEffect(() => {
        if (selectedData && selectedData.detailtabelle) {
            selectedData.detailtabelle.length > 0 ?
                setShowPopup(true) : download_datei();
        }
    }, [selectedData, download_datei])



    useEffect(() => {
        GetData(
            { Operation: "GetExportFiles" }, dispatch
        )
            .then((res) => {
                if (!res) {
                    setData([]);
                    return
                }
                setData(res.data)
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleClickDownload = useCallback((e: any) => {
        e.event.stopPropagation();
        setSelectedData(e.row.data);
    }, []);

    const handleHidingPopup = useCallback(() => {
        setShowPopup(false);
        setSelectedData(undefined);
    }, []);

    const handleJaClick = useCallback(() => {
        setShowPopup(false);
        setSelectedData(undefined);
        download_datei();
    }, [download_datei]);

    const memo_options_ja = useMemo(() => {
        return {
            text: 'Ja',
            onClick: handleJaClick,
        }
    }
        , [handleJaClick]);

    const memo_options_nein = useMemo(() => {
        return {
            text: 'Nein',
            onClick: handleHidingPopup,
        }
    }
        , [handleHidingPopup]);



    return (
        <ScrollView>
            <DataGrid
                dataSource={data}
                columnAutoWidth={false}
                rowAlternationEnabled={true}
                keyExpr="filename"
                className="mt-2 mt-md-3"
                wordWrapEnabled={true}
                noDataText={"Keine Exporte"}
                renderAsync={true}
            >
                <MasterDetail
                    enabled={true}
                    component={MasterDetailSection}
                />
                <Column dataField="filename" caption="Datei" cellRender={RenderCell} />
                <Column dataField="timestamp" caption="Zeitstempel" cellRender={RenderCell} />
                <Column dataField="faelle_count" caption="Fälle" dataType="number" cellRender={RenderCell} />
                <Column dataField="file_size" caption="Größe (kByte)" dataType="number" cellRender={RenderCell} />
                <Column
                    type='buttons'
                >
                    <Button
                        cssClass={'d-inline-block'}
                        onClick={handleClickDownload}
                        render={RenderButtonDownload}
                    ></Button>

                </Column>
            </DataGrid>
            <Popup
                visible={showPopup}
                onHiding={handleHidingPopup}
                dragEnabled={true}
                hideOnOutsideClick={true}
                fullScreen={false}
                showCloseButton={true}
                maxHeight="200px"
                maxWidth="350px"
            > <ToolbarItem
                    toolbar="top"
                    location={'before'}
                    render={RenderePopupTitle}
                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="center"
                    options={memo_options_ja}
                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="center"
                    options={memo_options_nein}
                />
                <p>Wollen sie die Datei erneut herunterladen?</p>
            </Popup>
        </ScrollView>
    )
}
export default DatenexportHerunterladen;


const RenderButtonDownload: React.FC = () => {
    return (<MDButton
        page="daten_export_herunterladen"
        tooltip_text="Export herunterladen"
        icon={icon_download}
    />)
}

const RenderCell = (item: any) => {
    //Nur wenn nicht heruntergeladen wurde, dann fett
    if (item.data.detailtabelle && item.data.detailtabelle.length === 0) {
        return <p><strong>{item.value}</strong></p>
    }

    return <p>{item.value}</p>;
}


const RenderePopupTitle = () => {
    return (<h2 className="title_popup">Hinweis</h2>)
};

