import { Popup, TextArea } from "devextreme-react";
import { ToolbarItem } from "devextreme-react/autocomplete";
import { useMemo } from "react";
import { useState, memo, useCallback } from "react";
import { GetData } from "../../../../../global/communication";
import { icon_message } from "../../../../../global/const";
import { handleContentReadyTextBox } from "../../../../../global/hilfs_functions";
import { Seperatorliste } from "../../../../../global/seperator_liste";
import { setDataTemp, setToast, useAppDispatch, useAppSelector } from "../../../../../state";
import { MDButton } from "../../../../../global/components";
import { IFallData } from "../../../interfaces";


interface IAnmerkungProps {
    lfdnr: number,
    anmerkung: string,
    exportiert: boolean,
}
const Anmerkung: React.FC<IAnmerkungProps> = ({ lfdnr, anmerkung, exportiert }) => {
    const konfigurator = useAppSelector((state) => state.store_einstellungen.konfiguration);
    const [showPopup, setShowPopup] = useState(false);
    const [texrAreaValue, setTexrAreaValue] = useState(anmerkung);
    const data = useAppSelector((state) => state.store_temp_data.data) as IFallData[];
    const dispatch = useAppDispatch();

    const handleClickSpeichern = useCallback(() => {
        let anmerkungsdaten = new Seperatorliste("%00", "%01");
        anmerkungsdaten.setValue("LfdNr", lfdnr.toString());
        anmerkungsdaten.setValue("Anmerkung", texrAreaValue);

        GetData(
            {
                Operation: "ChangeAnmerkung",
                Parameter: anmerkungsdaten.getSeperatorliste()
            }, dispatch
        )
            .then((res) => {
                if (!res) return

                dispatch(setToast({
                    displayTime: 3000,
                    message: "Die Anmerkung wurde gespeichert",
                    type: "success",
                    position: "bottom center"
                }));
                let temp_data = [...data];
                let end_data: IFallData[] = [];
                temp_data.forEach((itemData) => {
                    if (itemData.lfdnr === lfdnr) {
                        //MD 21.09.2022 so sollte es sein
                        itemData = { ...itemData, f130: texrAreaValue }
                    }
                    end_data.push(itemData);
                });
                dispatch(setDataTemp({ data: end_data, new_data: true }));

                setShowPopup(false)
            });


    }, [lfdnr, texrAreaValue, dispatch, data]);


    const handleShowPopup = useCallback(() => {
        exportiert ? dispatch(setToast({
            message: 'Der Fall wurde bereits exportiert. Eine Änderung ist nicht mehr möglich',
            type: 'info',
        })) :
            setShowPopup(true)
    }, [exportiert, dispatch])

    const handleHidePopup = useCallback(() => {
        setShowPopup(false)
    }, [])

    const memo_options_speichern = useMemo(() => {
        return {
            text: 'Speichern',
            onClick: handleClickSpeichern,
        }
    }, [handleClickSpeichern]);

    const memo_options_abbrechen = useMemo(() => {
        return {
            text: 'Abbrechen',
            onClick: handleHidePopup,
        }
    }, [handleHidePopup]);

    const handleChangeTextArea = useCallback((e: any) => {
        setTexrAreaValue(e.value)
    }, []);


    return (
        konfigurator.faelle_anmerkungen_erfassen ?
            <>
                <li>
                    <MDButton
                        page="fall_card"
                        icon={icon_message}
                        onClick={handleShowPopup}
                        className={texrAreaValue ? "highlight" : ""}
                        tooltip_text={!texrAreaValue ? "Anmerkung erfassen" : "Anmerkung: " + texrAreaValue}
                    />
                </li>
                <Popup
                    visible={showPopup}
                    onHiding={handleHidePopup}
                    dragEnabled={true}
                    hideOnOutsideClick={true}
                    fullScreen={false}
                    showCloseButton={true}
                    maxHeight="250px"
                    maxWidth="500px"
                >
                    <ToolbarItem
                        toolbar="top"
                        location={'before'}
                        render={RenderePopupTitle}
                    />
                    <ToolbarItem
                        widget="dxButton"
                        toolbar="bottom"
                        location="after"
                        options={memo_options_speichern}
                    />
                    <ToolbarItem
                        widget="dxButton"
                        toolbar="bottom"
                        location="after"
                        options={memo_options_abbrechen}
                    />
                    <div className="row ">
                        <div className='col-12'>
                            <TextArea
                                height={90}
                                label='Anmerkung erfassen'
                                labelMode='floating'
                                stylingMode='outlined'
                                value={texrAreaValue}
                                valueChangeEvent="input"
                                onValueChanged={handleChangeTextArea}
                                maxLength={254}
                                onContentReady={handleContentReadyTextBox}
                            />
                        </div>
                    </div>
                </Popup>
            </>
            :
            <></>
    );
}
export default memo(Anmerkung);

const RenderePopupTitle = () => {
    return (<h2 className="title_popup">Anmerkung bearbeiten</h2>)
};
