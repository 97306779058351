import { Popup, ScrollView, TextBox } from "devextreme-react"
import { ToolbarItem } from "devextreme-react/autocomplete"
import { useCallback, useMemo, useState } from "react";
import { setKonfiguratorBearbeitet, setToast, useAppDispatch } from "../../../state";
import { IKonfiguratorEintrag } from "../interfaces";
import { GetData } from "../../../global/communication";
import { handleContentReadyTextBox } from "../../../global/hilfs_functions";

interface IUmbennenPopupProps {
    setShowPopUp: (name_new?: string) => void,
    row?: IKonfiguratorEintrag,
    art: "Schlüssel" | "Computer" | "Bereich",
    computername_alt?: string,
    bereichname_alt?: string,
}

const get_value = (art: string, row: any, computername_alt: any, bereichname_alt: any): string => {
    let result = '';
    (art === "Schlüssel" && row) && (result = row?.f003);
    (art === "Computer" && computername_alt) && (result = computername_alt);
    (art === "Bereich" && bereichname_alt) && (result = bereichname_alt)
    return result;
}

const UmbennenPopup: React.FC<IUmbennenPopupProps> = ({ setShowPopUp, row, art, computername_alt, bereichname_alt }): JSX.Element => {


    const [value, setValue] = useState(get_value(art, row, computername_alt, bereichname_alt));
    const dispatch = useAppDispatch();

    const handleSpeichern = useCallback(() => {
        if (!value) {
            dispatch(setToast({
                message: art + " muss vorhanden sein",
                type: "error",
                position: "center center",
                displayTime: 4000,
            }))
            return
        }

        let seperatorliste = '';
        if (art === "Schlüssel") {
            row && (seperatorliste = "computername%01" + row.f001
                + "%00bereich%01" + row.f002
                + "%00schluessel%01" + value
                + "%00schluessel-alt%01" + row.f003
                + "%00wert%01" + row.f004
                + "%00alle-computer%01Nein")
        } else if (art === "Computer") {
            computername_alt && (seperatorliste = "computername%01" + value
                + "%00computername-alt%01" + computername_alt)
        } else if (art === "Bereich") {
            bereichname_alt && (seperatorliste = "computername%01" + computername_alt
                + "%00bereich%01" + value + "%00bereich-alt%01" + bereichname_alt)
        }


        GetData({
            Operation: art === "Schlüssel" ? "ChangeKonfiguration" : art === "Computer" ? "ChangeComputer" : "ChangeBereich",
            Daten: seperatorliste,
        }, dispatch)
            .then((res) => {
                if (!res) return

                setShowPopUp(value);
                dispatch(setToast({
                    message: "Die Änderungen wurden erfolgreich vorgenommen.",
                    type: "success",
                    position: "bottom center"
                }));

                //MD new 12.01.2023
                dispatch(setKonfiguratorBearbeitet(true));
            });
    }, [value, dispatch, art, row, bereichname_alt, computername_alt, setShowPopUp]);

    const hanldeClosePopup = useCallback(() => {
        setShowPopUp();
    }, [setShowPopUp]);

    const memo_options_speichern = useMemo(() => {
        return {
            text: 'Speichern',
            onClick: handleSpeichern,
        }
    }, [handleSpeichern]);

    const memo_options_abbrechen = useMemo(() => {
        return {
            text: 'Abbrechen',
            onClick: hanldeClosePopup,
        }
    }, [hanldeClosePopup]);
    const RenderePopupTitle = useCallback(() => {
        return (<h2 className="title_popup">{art + " umbennen"}</h2>)
    }, [art]);


    return (
        <>
            <Popup
                visible={true}
                onHiding={hanldeClosePopup}
                dragEnabled={true}
                hideOnOutsideClick={true}
                fullScreen={false}
                showCloseButton={true}
                height='auto'
                maxHeight="70vh"
                maxWidth="500px"
            >
                <ToolbarItem
                    toolbar="top"
                    location={'before'}
                    render={RenderePopupTitle}
                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={memo_options_speichern}
                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={memo_options_abbrechen}
                />
                <ScrollView>
                    <div className="row p-2 p-md-3">
                        <div className="col-12  p-1 p-md-2">
                            <TextBox
                                label={art === "Schlüssel" ? "Neuer Schlüssel" : art === "Computer" ? "Neuer Computername" : "Neuer Bereichsname"}
                                labelMode="floating"
                                stylingMode="outlined"
                                onValueChange={setValue}
                                value={value}
                                maxLength={80}
                                valueChangeEvent="input"
                                onEnterKey={handleSpeichern}
                                onContentReady={handleContentReadyTextBox}
                            />
                        </div>
                    </div>
                </ScrollView>

            </Popup>
        </>
    )
}

export default UmbennenPopup;


