import { IFaelleFilter, IFallData, IFoto } from "../components/faelle/interfaces";
import { Seperatorliste } from "../global/seperator_liste";
import { MIN_DATE } from "../global/const";

import { Paths, Texte } from "../components/navigation/navigationLogik";
import { get_date_ajax_param, handleErrorImg, stretch0Left } from "../global/hilfs_functions";
import { NewlineText, RendereGridTitle } from "../global/components";
import { get_bild_nummer_gruppen } from "../components/faelle/hilfs_functions";



export const fix_document_title = (seite: string) => {
  let version_index = window.document.title.indexOf("Winowig Online NT ");
  switch (seite) {
    case "/login":
      seite = "Login"
      break;
    case Paths.PATH_FAELLEVERKEHR:
      seite = Texte.TEXT_FAELLEVERKEHR
      break;
    case Paths.PATH_ABSCHLEPPFAELLE:
      seite = Texte.TEXT_ABSCHLEPPFAELLE
      break;
    case Paths.PATH_FAELLEAOWI:
      seite = Texte.TEXT_FAELLEAOWI
      break;
    case Paths.PATH_FAELLEFLIESSVERKEHR:
      seite = Texte.TEXT_FAELLEFLIESSVERKEHR
      break;
    case Paths.PATH_DOPPELVERWARNUNGEN:
      seite = Texte.TEXT_DOPPELVERWARNUNGEN
      break;
    case Paths.PATH_DATENEXPORTSTARTEN:
      seite = "Datenexport " + Texte.TEXT_DATENEXPORTSTARTEN
      break;
    case Paths.PATH_DATENEXPORTHERUNTERLADEN:
      seite = "Datenexport " + Texte.TEXT_DATENEXPORTHERUNTERLADEN
      break;
    case Paths.PATH_DATENEXPORTBEARBEITEN:
      seite = "Datenexport " + Texte.TEXT_DATENEXPORTBEARBEITEN
      break;
    case Paths.PATH_STATISTIKBENUTZERDEFINIERT:
      seite = "Statistik " + Texte.TEXT_STATISTIK_BENUTZERDEFINIERT
      break;
    case Paths.PATH_STATISTIKINDIVIDUELL:
      seite = "Statistik " + Texte.TEXT_STATISTIK_INDIVIDUELL
      break;
    case Paths.PATH_STATISTIKTAGESAUSWERTUNG:
      seite = "Statistik " + Texte.TEXT_STATISTIK_TAGESAUSWERTUNG
      break;
    case Paths.PATH_STATISTIKMONAT:
      seite = "Statistik " + Texte.TEXT_STATISTIK_MONAT
      break;
    case Paths.PATH_STATISTIKGERAETELOGINS:
      seite = "Statistik " + Texte.TEXT_STATISTIK_GERAETELOGINS
      break;
    case Paths.PATH_DRUCKLAYOUTS:
      seite = Texte.TEXT_DRUCKLAYOUTS
      break;
    case Paths.PATH_KONFIGURATOR:
      seite = Texte.TEXT_KONFIGURATOR
      break;
    case Paths.PATH_FERNWARTUNG:
      seite = Texte.TEXT_FERNWARTUNG
      break;
    case Paths.PATH_ABSCHLEPPUNTERNEHMEN:
      seite = Texte.TEXT_ABSCHLEPPUNTERNEHMEN
      break;
    case Paths.PATH_GERAETE:
      seite = Texte.TEXT_GERAETE
      break;
    case Paths.PATH_GERAETE_KONFIGURATIONEN:
      seite = Texte.TEXT_GERAETE_KONFIGURATIONEN
      break;
    case Paths.PATH_KFZHINWEISE:
      seite = Texte.TEXT_KFZHINWEISE
      break;
    case Paths.PATH_SACHBEARBEITER:
      seite = Texte.TEXT_SACHBEARBEITER
      break;
    case Paths.PATH_SACHBEARBEITERGRUPPEN:
      seite = Texte.TEXT_SACHBEARBEITERGRUPPEN
      break;
    case Paths.PATH_STAMMDATEN:
      seite = Texte.TEXT_STAMMDATEN
      break;
    case Paths.PATH_STRASSEN:
      seite = Texte.TEXT_STRASSEN
      break;
    case Paths.PATH_TATBESTAENDE:
      seite = Texte.TEXT_TATBESTAENDE
      break;
    case Paths.PATH_TATBKATEGORIEN:
      seite = Texte.TEXT_TATBKATEGORIEN
      break;
    case Paths.PATH_TATBHINWEIS:
      seite = Texte.TEXT_TATBHINWEIS
      break;
    case Paths.PATH_PASSWORTAENDERN:
      seite = Texte.TEXT_PASSWORTAENDERN
      break;
    default:
      break;
  }

  window.document.title = seite + " | Winowig Online NT " + window.document.title.substring(version_index + 18);
}

export const get_json_object_in_arr = (arr: any, attr_name: string, attr_value: any) => {
  for (let i of arr) {
    if (i[attr_name] === attr_value) {
      return i;
    }
  }
  return undefined;
}




export const get_fotos_from_fall = (item: IFallData, mnr: string): IFoto[] => {
  let fotos: IFoto[] = [];
  let foto1 = item.f010.f1;
  if (foto1) {
    //let d1 = item.f010.d1; //Rotate brauchen wir erstmal nicht
    //d1 && (foto1 += "?d1=" + d1)
    fotos.push({
      bezeichnung: "f1",
      // url: foto1, zum testen
      url: get_url_foto(item.mandantenspezifischesFotoverzeichnis, foto1, mnr),
      alt: "Das erste Foto für den Fall mit dem Aktenzeichen " + item.f202 + " Fotoname:" + foto1,
    })
  }
  let foto2 = item.f010.f2;
  if (foto2) {
    // let d2 = item.f010.d2;

    // d2 && (foto2 += "?d2=" + d2)
    fotos.push({
      bezeichnung: "f2",
      url: get_url_foto(item.mandantenspezifischesFotoverzeichnis, foto2, mnr),
      alt: "Das zweite Foto für den Fall mit dem Aktenzeichen " + item.f202 + " Fotoname:" + foto2,
    })
  }
  let foto3 = item.f010.f3;
  if (foto3) {
    // let d3 = item.f010.d3;

    //d3 && (foto3 += "?d3=" + d3)
    fotos.push({
      bezeichnung: "f3",
      url: get_url_foto(item.mandantenspezifischesFotoverzeichnis, foto3, mnr),
      alt: "Das dritte Foto für den Fall mit dem Aktenzeichen " + item.f202 + " Fotoname:" + foto3,
    })
  }

  //MD 03.04.2023 Neu Beschilderungsfoto von 1 bis 5
  if (item.f010.fb1) {
    fotos.push({
      bezeichnung: "fb1",
      url: get_url_foto(item.mandantenspezifischesFotoverzeichnis, item.f010.fb1, mnr),
      alt: "Foto Beschilderung für den Fall mit dem Aktenzeichen " + item.f202,
    })
  }
  if (item.f010.fb2) {
    fotos.push({
      bezeichnung: "fb2",
      url: get_url_foto(item.mandantenspezifischesFotoverzeichnis, item.f010.fb2, mnr),
      alt: "Foto Beschilderung für den Fall mit dem Aktenzeichen " + item.f202,
    })
  }
  if (item.f010.fb3) {
    fotos.push({
      bezeichnung: "fb3",
      url: get_url_foto(item.mandantenspezifischesFotoverzeichnis, item.f010.fb3, mnr),
      alt: "Foto Beschilderung für den Fall mit dem Aktenzeichen " + item.f202,
    })
  }
  if (item.f010.fb4) {
    fotos.push({
      bezeichnung: "fb4",
      url: get_url_foto(item.mandantenspezifischesFotoverzeichnis, item.f010.fb4, mnr),
      alt: "Foto Beschilderung für den Fall mit dem Aktenzeichen " + item.f202,
    })
  }
  if (item.f010.fb5) {
    fotos.push({
      bezeichnung: "fb5",
      url: get_url_foto(item.mandantenspezifischesFotoverzeichnis, item.f010.fb5, mnr),
      alt: "Foto Beschilderung für den Fall mit dem Aktenzeichen " + item.f202,
    })
  }

  for (let i = 1; i < 100; i++) {
    let nr = stretch0Left(i.toString(), 2);
    if (!item.f010["fw" + nr]) break;
    fotos.push({
      bezeichnung: "fw" + nr,
      url: get_url_foto(item.mandantenspezifischesFotoverzeichnis, item.f010["fw" + nr], mnr),
      alt: "Weiteres Foto mit der Nummer " + nr + " für den Fall mit dem Aktenzeichen " + item.f202 + " Fotoname: " + item.f010["fw" + nr],
    })

  }

  return fotos;
}

const get_url_foto = (mandantenspezifischesFotoverzeichnis: boolean, fotoname: string, mnr: string) => {
  if (mandantenspezifischesFotoverzeichnis) {
    if (fotoname.indexOf("?") > -1) {
      return "/FileRequest/" + fotoname + "&Operation=GetFotoFromBenutzerdefiniertesFotoverzeichnis";
    } else {
      return "/FileRequest/" + fotoname + "?Operation=GetFotoFromBenutzerdefiniertesFotoverzeichnis";
    }
  } else {
    return "/Files/WoOnline/M" + stretch0Left(mnr, 3) + "/MobilFotos/" + fotoname.substring(0, 8) + "/" + fotoname;
  }
}


export const get_faelle_filter = (filter: IFaelleFilter, for_db: boolean = false): string => {

  let filter_end = new Seperatorliste("%00", "%01");
  if (for_db) {
    if (filter.DatumVon === get_date_ajax_param(MIN_DATE) && filter.DatumBis === get_date_ajax_param(new Date())) {
      //alle
      filter_end.setValue("DatumVon", "Alle");
      filter_end.setValue("DatumBis", "");
    } else if (filter.DatumVon === get_date_ajax_param(new Date()) && filter.DatumBis === get_date_ajax_param(new Date())) {
      //heute
      filter_end.setValue("DatumVon", "Heute");
      filter_end.setValue("DatumBis", "");
    } else {
      filter_end.setValue("DatumVon", filter.DatumVon);
      filter_end.setValue("DatumBis", filter.DatumBis);
    }
  }

  for (const [key, value] of Object.entries(filter)) {
    if (for_db && (key === "DatumVon" || key === "DatumBis")) {
      continue;
    } else {
      if (key === "KeineGeloeschten" || key === "NurAenderungen" || key === "NurFaelle" || key === "NurEingestellteFaelle" || key === "MehrereSachbearbeiter") {
        value ?
          filter_end.setValue(key, "Ja") :
          filter_end.setValue(key, "Nein")
      } else {
        (value && value !== "Alle") &&
          filter_end.setValue(key, value);
      }
    }
  }
  return filter_end.getSeperatorliste();

}



export const formatErfassungsZeit = (datum: string, uhrzeit: string) => {
  var d;
  var u;
  if (datum) d = datum;
  else d = "";

  if (uhrzeit && (uhrzeit.length === 9 || uhrzeit.length === 8)) {
    if (uhrzeit.length === 9) u = uhrzeit.substr(0, 2) + ":" + uhrzeit.substr(2, 2);
    else u = "0" + uhrzeit.substr(0, 1) + ":" + uhrzeit.substr(1, 2); // 0 rein dass es einheitlich bleibt.
  } else u = "";
  return d + " " + u;
}

export const formatTatzeit = (datum: string, uhrzeit: string) => {
  var d;
  var u;
  if (datum) d = datum;
  else d = "";

  if (uhrzeit && uhrzeit.length === 8) {
    u = uhrzeit.substr(0, 5);
  } else u = "";
  return d + " " + u;
}

/*
export const handleClickDelete = (...args: any) => {
  console.log(args)
}*/

export const format_geraete_logins_zeitstemple = (value?: any): string => {
  if (!value) return ""
  return value.substr(6, 2) + "." +
    value.substr(4, 2) + "." +
    value.substr(0, 4) + " " +
    value.substr(8, 2) + ":" +
    value.substr(10, 2) + ":" +
    value.substr(12, 2);
}




export const RendereGridTitleExporteBearbeiten = () => RendereGridTitle("Exporte bearbeiten");
export const RendereGridTitleDoppelVerwarnungen = () => RendereGridTitle("Doppelverwarnungen");
export const RendereGridTitleAbschleppUnternehemen = () => RendereGridTitle("Abschleppunternehmen");
export const RendereGridTitleGeraete = () => RendereGridTitle("Geräte");
export const RendereGridTitleSachbearbeiter = () => RendereGridTitle("Sachbearbeiter");
export const RendereGridTitleSachbearbeitergruppen = () => RendereGridTitle("Sachbearbeitergruppen");
export const RendereGridTitleStammdaten = () => RendereGridTitle("Stammdaten");
export const RendereGridTitleTatbestaende = () => RendereGridTitle("Tatbestände");
export const RendereGridTitleStatistikBenutzerdefiniert = () => RendereGridTitle("Statistik Benutzerdefiniert");
export const RendereGridTitleGeraeteLogins = () => RendereGridTitle("Gerätelogins");
export const RendereGridTitleStatistikInid = () => RendereGridTitle("Statistik individuell");
export const RendereGridTitleTagesauswertung = () => RendereGridTitle("Tagesauswertung");
export const RendereGridTitleTatbestandKategorien = () => RendereGridTitle("Tatbestand Kategorien");

export const getHandyparkenElement = (f010: any): JSX.Element | null => {
  const handyparkenStatus = f010.MPStatus; // xml.find("MPStatus").text().escapeHtml();
  const handyparkenStatusInt = parseInt(handyparkenStatus);
  let status = '';
  let tr_nummer = f010.MPNr || "";
  let zone = f010.MPZone || "";
  let op = f010.MPOp || "";
  let historie = '';

  if (handyparkenStatus && !isNaN(handyparkenStatusInt)) {
    let anfrageDatum = f010.MPAnfrDatum;
    if (anfrageDatum) anfrageDatum = anfrageDatum.substr(6, 2) + "." + anfrageDatum.substr(4, 2) + "." + anfrageDatum.substr(0, 4);

    let anfrageZeit = f010.MPAnfrZeit;
    if (anfrageZeit) anfrageZeit = anfrageZeit.substr(0, 2) + ":" + anfrageZeit.substr(2, 2);

    let key = f010.MPAnfrKey;

    let parkDatum = f010.MPDate;
    if (parkDatum) parkDatum = parkDatum.substr(6, 2) + "." + parkDatum.substr(4, 2) + "." + parkDatum.substr(0, 4);

    let parkZeit = f010.MPTime;
    if (parkZeit) parkZeit = parkZeit.substr(0, 2) + ":" + parkZeit.substr(2, 2);

    if (!anfrageDatum) anfrageDatum = "";
    if (!anfrageZeit) anfrageZeit = "";
    if (!parkDatum) parkDatum = "";
    if (!parkZeit) parkZeit = "";
    if (!key) key = "";

    if (handyparkenStatusInt < 0) status = "Status: Es ist ein Fehler aufgetreten"
    else if (handyparkenStatusInt === 0) status = "Status: Server belegt"
    else if (handyparkenStatusInt === 1) status = "Status: Fahrzeug ist nicht registriert"
    else if (handyparkenStatusInt === 2) status = "Status: Ungültiger Zeitraum"
    else if (handyparkenStatusInt === 10) status = "Status: Parkzeit ist am " + parkDatum + " um " + parkZeit + " Uhr abgelaufen"
    else if (handyparkenStatusInt === 11) status = "Status: Parkzeit läuft bis " + parkDatum + " um " + parkZeit + " Uhr";

    if ((handyparkenStatusInt === 10) || (handyparkenStatusInt === 11)) {

      tr_nummer = "Transaktions-Nr: " + tr_nummer
      zone = "Zone: " + zone
      op = "Operator: " + op
    }

    let handyparkenHistorie = f010.MPHistorie;
    if (handyparkenHistorie)
      historie = "Historie: " + handyparkenHistorie;

    return (<div className='text-left'>
      <p><strong>Handy-Parken:</strong></p>
      <p>Anfrage am {anfrageDatum} um {anfrageZeit} Uhr für {key}</p>
      <br />
      <p>{status}</p>
      <br />
      {tr_nummer && <p>{tr_nummer}</p>}
      {zone && <p>{zone}</p>}
      {op && <p>{op}</p>}
      {historie && NewlineText(historie)}
    </div>)
  } else {
    return null
  };

};

export const HauptGruppenItem = ({ data }: any) => {
  //Dies wird tatsächlich nur einmal gerendert.
  return (
    <div className="custom_items_gruppen">
      {data.f003 !== "Alle" &&
        <img
          src={"/Files/WinowigOnlineNT/Source/img/tatbestandgruppen/hauptgruppen/Hauptgruppe" + get_bild_nummer_gruppen(data.f004) + '.png'}
          alt={"Hauptgruppen Bild nummer: " + data.f004}
          onError={handleErrorImg}
          style={{ backgroundColor: "rgba(25, 94, 163,1)" }} />}
      <div className="text">{data.f003}</div>
    </div>
  )
}

export const UnterGruppenItem = ({ data }: any) => {
  return (
    <div className="custom_items_gruppen">
      {data.f003 !== "Alle" &&
        <img
          src={"/Files/WinowigOnlineNT/Source/img/tatbestandgruppen/untergruppen/Untergruppe" + get_bild_nummer_gruppen(data.f004) + '.png'}
          alt={"Untergruppen Bild nummer: " + data.f004}
          onError={handleErrorImg}
          style={{ backgroundColor: "rgba(25, 94, 163,1)" }} />}
      <div className="text">{data.f003}</div>
    </div>
  )
}