import { memo } from "react";
import { useCallback, useState } from "react";
import { Computer, TreeViewNodeKonfigurator } from ".";
import { icon_plus } from "../../../global/const";
import { MDButton } from "../../../global/components";
import { IKonfiguratorEintrag } from "../interfaces";
import KonfiguratorEintragVerwaltePopup from "./add_bearbeite_konfigurator_eintrag";


interface IKonfiguratorEintragAddProps {
    node: TreeViewNodeKonfigurator,
    setSelectedItem: (item: TreeViewNodeKonfigurator | undefined) => void,
    addToData: (row: IKonfiguratorEintrag) => void,
    allComputer: Computer[],
    get_data_all_Computer: () => void,
}
const KonfiguratorEintragAdd: React.FC<IKonfiguratorEintragAddProps> = ({ get_data_all_Computer, allComputer, node, setSelectedItem, addToData }): JSX.Element => {
    const [showPopUp, setShowPopUp] = useState(false);

    const handleCallBack = useCallback((row_new?: IKonfiguratorEintrag) => {
        if (row_new) {
            if (row_new.f001 === node.parentText && row_new.f002 === node.text) {
                //Es wurde in dem gleichen Computer und Bereich hinzugefügt.
                addToData(row_new);
            } else {
                get_data_all_Computer();
                setSelectedItem(undefined);
            }
        }
        setShowPopUp(false);
    }, [node, addToData, setSelectedItem, get_data_all_Computer]);

    const handleShowPopup = useCallback(() => {
        setShowPopUp(true)
    }, []);

    return (
        <div>
            <MDButton
                icon={icon_plus}
                type="success"
                page="konfigurator"
                tooltip_text='Neuen Wert anlegen'
                onClick={handleShowPopup}
            />
            {showPopUp && <KonfiguratorEintragVerwaltePopup allComputer={allComputer} callback={handleCallBack} selectedNode={node} />}
        </div>
    )
}

export default memo(KonfiguratorEintragAdd);