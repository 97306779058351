import { Popup, ScrollView, TextBox } from "devextreme-react"
import { ToolbarItem } from "devextreme-react/autocomplete"
import md5 from "md5";
import { useCallback, useMemo, useState } from "react";
import { GetData } from "../../../global/communication";
import { encodeBASE64, handleContentReadyTextBox } from "../../../global/hilfs_functions";
import { setToast, useAppDispatch } from "../../../state";



const PasswortAendern: React.FC = (): JSX.Element => {
    const [showPopup, setShowPopup] = useState(true);
    const [altesPasswort, setAltesPasswort] = useState("");
    const [neuesPasswort, setNeuesPasswort] = useState("");
    const [wiederholenNeuesPasswort, setWiederholenNeuesPasswort] = useState("");
    const dispatch = useAppDispatch();

    const handleClickSpeichern = useCallback(() => {
        if (neuesPasswort !== wiederholenNeuesPasswort) {
            dispatch(setToast({
                message: "Das neue Passwort stimmt nicht mit dessen Wiederholung überein",
                type: "error"
            }));
            return;
        }
        GetData({
            Operation: "ChangePassword",
            PasswortHashAlt: md5(altesPasswort),
            NeuesPasswortVerschluesselt: encodeBASE64(neuesPasswort),
        }, dispatch).then((res) => {

            setShowPopup(false);
            if (!res) return
            dispatch(setToast({
                message: "Das Passwort wurde erfolgreich geändert.",
                type: "success",
                position: "bottom center"
            }));

        });
    }, [neuesPasswort, wiederholenNeuesPasswort, dispatch, altesPasswort]);

    const handleHidePopup = useCallback(() => {
        setShowPopup(false)
    }, []);

    const memo_options_speichern = useMemo(() => {
        return {
            text: 'Speichern',
            onClick: handleClickSpeichern,
        }
    }, [handleClickSpeichern]);

    const memo_options_abbrechen = useMemo(() => {
        return {
            text: 'Abbrechen',
            onClick: handleHidePopup,
        }
    }, [handleHidePopup]);


    return (
        <Popup
            visible={showPopup}
            onHiding={handleHidePopup}
            dragEnabled={true}
            hideOnOutsideClick={true}
            fullScreen={false}
            showCloseButton={true}
            height='auto'
            maxHeight="70vh"
            maxWidth="500px"
        >
            <ToolbarItem
                toolbar="top"
                location={'before'}
                render={RenderePopupTitle}
            />
            <ToolbarItem
                widget="dxButton"
                toolbar="bottom"
                location="after"
                options={memo_options_speichern}
            />
            <ToolbarItem
                widget="dxButton"
                toolbar="bottom"
                location="after"
                options={memo_options_abbrechen}
            />
            <ScrollView>
                <div className="row p-2 p-md-3">
                    <div className="col-12 p-1 p-md-2">
                        <TextBox
                            label="Altes Passwort"
                            labelMode="floating"
                            stylingMode="outlined"
                            onValueChange={setAltesPasswort}
                            value={altesPasswort}
                            valueChangeEvent="input"
                            mode="password"
                            maxLength={60}
                            onContentReady={handleContentReadyTextBox}
                        />
                    </div>
                    <div className="col-12 p-1 p-md-2">
                        <TextBox
                            label="Neues Passwort"
                            labelMode="floating"
                            stylingMode="outlined"
                            onValueChange={setNeuesPasswort}
                            value={neuesPasswort}
                            valueChangeEvent="input"
                            mode="password"
                            onContentReady={handleContentReadyTextBox}
                            maxLength={60}
                        />
                    </div>
                    <div className="col-12 p-1 p-md-2">
                        <TextBox
                            label="Neues Passwort wiederholen"
                            labelMode="floating"
                            stylingMode="outlined"
                            onValueChange={setWiederholenNeuesPasswort}
                            value={wiederholenNeuesPasswort}
                            valueChangeEvent="input"
                            mode="password"
                            onContentReady={handleContentReadyTextBox}
                            maxLength={60}
                        />
                    </div>
                </div>
            </ScrollView>

        </Popup>
    )
}

export default PasswortAendern;

const RenderePopupTitle = () => {
    return (<h2 className="title_popup">Passwort ändern</h2>)
};
