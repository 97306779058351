import { DataGrid, SelectBox, TextBox } from "devextreme-react";
import { Column, Button as ButtonGrid } from "devextreme-react/data-grid";
import ArrayStore from "devextreme/data/array_store";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { GetData, GetDataAsync } from "../../../global/communication";
import { icon_delete, icon_plus, icon_rotate } from "../../../global/const";
import { handleContentReadySelectBox, handleContentReadyTextBox } from "../../../global/hilfs_functions";
import { setAlert, setToast, useAppDispatch } from "../../../state";
import { MDButton } from "../../../global/components";
import { HauptGruppenItem, UnterGruppenItem } from "../../../sonstiges/hilfs_functions";
import { ITatbestandVerwaltePopupProps } from "./verwalten_popup";
import { ITatbestandHauptKategorie, ITatbestandHauptUnterKategorieGrid, ITatbestandUnterKategorie } from "./interfaces";




let get_haupt_bezeichnung = (dataHauptKategorie: ITatbestandHauptKategorie[], hauptKategorieValue: number): string => {
    for (let i of dataHauptKategorie) {
        if (i.lfdnr === hauptKategorieValue) {
            return i.f003;
        }
    }
    return "";
}
let get_unter_bezeichnung = (dataUnterKategorie: ITatbestandUnterKategorie[], unterKategorieValue: number): string => {
    for (let i of dataUnterKategorie) {
        if (i.f002 === unterKategorieValue) {
            return i.f003;
        }
    }
    return "";
}



const KategorieTab: React.FC<ITatbestandVerwaltePopupProps> = ({ isAdd, data, setData }): JSX.Element => {

    const [dataHauptKategorie, setDataHauptKategorie] = useState<ITatbestandHauptKategorie[]>([]);
    const [hauptKategorieValue, setHauptKategorieValue] = useState<number>();
    const [dataUnterKategorie, setDataUnterKategorie] = useState<ITatbestandUnterKategorie[]>([]);
    const [unterKategorieValue, setUnterKategorieValue] = useState<number>();
    const [kategorienAlsSeperatorliste, setKategorienAlsSeperatorliste] = useState(data!.kategorien);
    const [dataHauptUnterGrid, setDataHauptUnterGrid] = useState<ITatbestandHauptUnterKategorieGrid[]>([]);
    const dispatch = useAppDispatch();
    const [isGridLoading, setIsGridLoading] = useState(true);
    const data_source_grid = useMemo(() => new ArrayStore({
        data: dataHauptUnterGrid,
        key: "kategorie"
    }), [dataHauptUnterGrid]);

    useEffect(() => {
        if (setData && data && (kategorienAlsSeperatorliste !== data!.kategorien)) {
            setData({ ...data, kategorien: kategorienAlsSeperatorliste.trim() });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [kategorienAlsSeperatorliste]);

    useEffect(() => {
        if (!isAdd && kategorienAlsSeperatorliste) {
            //Nur wenn bearbeiten und Kategorie nicht leer

            GetData({
                Operation: "GetKategorieBezeichnung",
                Kategorien: kategorienAlsSeperatorliste
            }, dispatch).then((res) => {
                if (!res) {
                    setDataHauptUnterGrid([])
                    return
                }
                let result: ITatbestandHauptUnterKategorieGrid[] = [];
                let arrKategorien = kategorienAlsSeperatorliste.split(";").filter(i => i);

                for (let i = 0; i < arrKategorien.length; i++) {
                    let arrGruppe = arrKategorien[i].split(".");

                    let hauptBezeichnung = res["hauptgruppe" + arrGruppe[0] + "Punkt" + arrGruppe[1]]
                    //let hauptBildnummer =  res["hauptbild" + arrGruppe[0] + "Punkt" + arrGruppe[1]]

                    let unterBezeichnung = res["untergruppe" + arrGruppe[0] + "Punkt" + arrGruppe[1]]
                    //let unterBildnummer = res["unterbild" + arrGruppe[0] + "Punkt" + arrGruppe[1]]
                    result.push({
                        hauptbezeichnung: hauptBezeichnung,
                        hauptkategorie: parseInt(arrGruppe[0]),
                        kategorie: arrKategorien[i],
                        unterbezeichnung: unterBezeichnung,
                        unterkategorie: parseInt(arrGruppe[1]),
                    });
                }
                setDataHauptUnterGrid(result);
            });

        }
        GetData({
            Operation: "GetHauptgruppen",
            filter: "",
        }, dispatch).then((res) => {
            if (!res) {
                setDataHauptKategorie([])
                return
            }
            setDataHauptKategorie(res.data)
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    useEffect(() => {
        if (hauptKategorieValue || hauptKategorieValue === 0) {
            GetData({
                Operation: "GetUntergruppen",
                Hauptgruppennummer: hauptKategorieValue.toString(),
                filter: "",
            }, dispatch).then((res) => {
                if (!res) {
                    setDataUnterKategorie([])
                    return
                }
                setDataUnterKategorie(res.data)
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hauptKategorieValue]);

    useEffect(() => {
        (dataUnterKategorie.length > 0) && setUnterKategorieValue(dataUnterKategorie[0].f002);
    }, [dataUnterKategorie])
    useEffect(() => {
        if (!isGridLoading) {
            let value: string = "";

            for (let i of dataHauptUnterGrid) {
                value !== "" && (value += ";")

                value += i.kategorie;
            }
            setKategorienAlsSeperatorliste(value);
        } else {
            setIsGridLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataHauptUnterGrid])


    const handleClickAdd = useCallback(() => {
        if ((unterKategorieValue === undefined || unterKategorieValue === null) || (hauptKategorieValue === undefined || hauptKategorieValue === null)) return;

        // prüfen ob Gruppe schon enthalten
        let vorhanden = false;
        let gruppeNeu = hauptKategorieValue + "." + unterKategorieValue;
        for (let i of dataHauptUnterGrid) {
            if (i.kategorie === gruppeNeu) {
                vorhanden = true;
                break;
            }
        }
        if (!vorhanden) {
            setDataHauptUnterGrid([...dataHauptUnterGrid, {
                kategorie: gruppeNeu,
                hauptkategorie: hauptKategorieValue,
                unterkategorie: unterKategorieValue,
                hauptbezeichnung: get_haupt_bezeichnung(dataHauptKategorie, hauptKategorieValue),
                unterbezeichnung: get_unter_bezeichnung(dataUnterKategorie, unterKategorieValue),
            }]);
        } else {
            dispatch(setToast({
                message: "Die Kategorie ist bereits gewählt!",
                type: "error",
                position: "bottom center"
            }));
        }
    }, [unterKategorieValue, hauptKategorieValue, dataHauptUnterGrid, dataHauptKategorie, dataUnterKategorie, dispatch]);


    const handleClickRefresh = useCallback(async () => {

        // Prüfe ob Eingabe gültig ist									
        let eingabe = kategorienAlsSeperatorliste;
        let tatbestandskategorien = eingabe.split(";").filter(i => i);
        /*
        let gueltig = true;
        for (let i = 0; i < tatbestandskategorien.length; i++) {
            if (allKategorie.indexOf(tatbestandskategorien[i]) === -1) {
                gueltig = false;
                break;
            }
        }
            */
        const res = await GetDataAsync({
            Operation: "CheckIfKategorienVorhanden",
            Kategorien: kategorienAlsSeperatorliste
        });
        if (!res) return
        if (!res.erfolg) {
            if (!res.gueltig) {
                dispatch(setAlert({
                    text: "Ungültige Eingabe.",
                    type: "Fehler"
                }));
            } else {
                dispatch(setAlert({
                    text: res.hinweis ? res.hinweis : "Es ist ein Fehler aufgetreten.",
                    type: "Fehler"
                }));

            }
            return
        }


        setDataHauptUnterGrid([]);
        let temp: ITatbestandHauptUnterKategorieGrid[] = [];

        // Liste neu Aufbauen
        for (let i = 0; i < tatbestandskategorien.length; i++) {
            let str = tatbestandskategorien[i].split(".");
            temp.push({
                kategorie: tatbestandskategorien[i],
                hauptbezeichnung: get_haupt_bezeichnung(dataHauptKategorie, parseInt(str[0])),
                //       hauptbild:  get_haupt_bild(dataHauptKategorie, parseInt(str[0])),
                hauptkategorie: parseInt(str[0]),
                unterbezeichnung: get_unter_bezeichnung(dataUnterKategorie, parseInt(str[1])),
                //      unterbild: get_unter_bild(dataUnterKategorie, parseInt(str[1])),
                unterkategorie: parseInt(str[1]),
            });

            setDataHauptUnterGrid(temp);


        }
    }, [kategorienAlsSeperatorliste, dataHauptKategorie, dataUnterKategorie, dispatch]);


    const handleDeleteRow = useCallback((e: any) => {
        let data: ITatbestandHauptUnterKategorieGrid = JSON.parse(e.element.getAttribute("data"));
        let temp: ITatbestandHauptUnterKategorieGrid[] = [...dataHauptUnterGrid];
        const index = temp.indexOf(data);
        temp.splice(index, 1);
        setDataHauptUnterGrid(temp);
    }, [dataHauptUnterGrid]);

    const handleClickDelete = useCallback((e: any) => {
        e.event.stopPropagation();
        handleDeleteRow(e)
    }, [handleDeleteRow]);

    const RenderButtonDelete = useCallback((e: any) => {
        return (
            <MDButton
                page='tatbestaende_kategorie_tab'
                tooltip_text='Kategorie löschen'
                icon={icon_delete}
                type='danger'
                onClick={handleClickDelete}
                data={e.data}
            />)
    }, [handleClickDelete]);

    return (<>
        <div className="row p-2 p-md-3 pt-2 pt-md-4">
            <div className="col-12 p-1 p-md-2">
                <SelectBox
                    items={dataHauptKategorie}
                    displayExpr="f003"
                    valueExpr="lfdnr"
                    value={hauptKategorieValue}
                    onValueChange={setHauptKategorieValue}
                    label='Hauptkategorie'
                    labelMode='floating'
                    stylingMode='outlined'
                    onContentReady={handleContentReadySelectBox}
                    //fieldRender funktioniert nicht wenn kein defaultValue da ist
                    //fieldRender={(e: any) => {return (<TextBox value='test' readOnly={true}/>)}}
                    /**
                 itemRender={(data: ITatbestandHauptKategorie) => {   
                        return (
                            <div className="custom_items_gruppen">
                            <img 
                                src={"/Files/WinowigOnlineNT/Source/img/tatbestandgruppen/hauptgruppen/Hauptgruppe" + get_bild_nummer_gruppen(data.f004) +'.png'}
                                alt={"Hauptgruppen Bild nummer: " + data.f004}
                                style={{backgroundColor: "rgba(25, 94, 163,1)"}} />
                            <div className="text">{data.f003}</div>
                            </div>
                      );}}
                */
                    itemComponent={HauptGruppenItem}

                />

            </div>
            <div className="col-10 p-1 p-md-2">
                <SelectBox
                    items={dataUnterKategorie}
                    displayExpr="f003"
                    valueExpr="f002"
                    disabled={(hauptKategorieValue === undefined || hauptKategorieValue === null)}
                    value={unterKategorieValue}
                    onValueChange={setUnterKategorieValue}
                    label='Unterkategorie'
                    labelMode='floating'
                    stylingMode='outlined'
                    itemComponent={UnterGruppenItem}
                    onContentReady={handleContentReadySelectBox}
                /> </div>
            <div className="col-2 p-1 p-md-2 d-flex align-items-center">
                <MDButton
                    page="tatbestaende_kategorie"
                    icon={icon_plus}
                    type="success"
                    disabled={unterKategorieValue === undefined}
                    tooltip_text="Hinzufügen"
                    onClick={handleClickAdd}
                />

            </div>

            <div className="col-10 p-1 p-md-2">
                <TextBox
                    label="Kategorien als Seperatorliste"
                    labelMode="floating"
                    stylingMode="outlined"
                    value={kategorienAlsSeperatorliste}
                    onValueChange={setKategorienAlsSeperatorliste}
                    valueChangeEvent="input"
                    maxLength={1024}
                    onContentReady={handleContentReadyTextBox}
                />
            </div>
            <div className="col-2 p-1 p-md-2  d-flex align-items-center">
                <MDButton
                    page="tatbestaende_kategorie"
                    icon={icon_rotate}
                    tooltip_text="Aktualisieren"
                    onClick={handleClickRefresh}
                />
            </div>
            {
                (dataHauptUnterGrid.length > 0) &&
                <div className="col-12 p-1 p-md-2">
                    <DataGrid
                        dataSource={data_source_grid}
                        columnAutoWidth={true}
                        wordWrapEnabled={true}
                        renderAsync={true}
                        rowAlternationEnabled={true}
                        noDataText=""
                    >
                        <Column type="buttons">
                            <ButtonGrid
                                cssClass={'d-inline-block'}
                                render={RenderButtonDelete} />
                        </Column>
                        <Column dataField="kategorie" caption="Nr." />
                        <Column dataField="hauptbezeichnung" caption="Hauptkategorie" />
                        <Column dataField="unterbezeichnung" caption="Unterkategorie" />
                    </DataGrid>
                </div>
            }

        </div>
    </>)
}

export default memo(KategorieTab);


