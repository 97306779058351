import { FileUploader, Popup, ScrollView } from "devextreme-react"
import { Position, ToolbarItem } from "devextreme-react/autocomplete"
import { useCallback, useMemo, useRef, useState } from "react";
import { datei_bereit_zum_hochladen, icon_import } from "../../../global/const";
import { useScreenSizeClass } from "../../../global/media-query";
import { setAlert, setToast, useAppDispatch } from "../../../state";
import { MDButton } from "../../../global/components";

interface IKFDZHinweiseImportPopUpProps {
    reset_data: () => void,
}

const KFZHinweiseImportPopup: React.FC<IKFDZHinweiseImportPopUpProps> = ({ reset_data }): JSX.Element => {

    const screenSize = useScreenSizeClass();
    const isScreenSmall = (screenSize === "sm" || screenSize === "md");
    const [showPopUpImport, setShowPopUpImport] = useState(false);
    const refForm = useRef() as React.MutableRefObject<HTMLFormElement>;
    const refIFrame = useRef() as React.MutableRefObject<HTMLIFrameElement>;
    const refFileUploader = useRef() as React.MutableRefObject<FileUploader>;
    const [files, setFiles] = useState<any[]>([]);
    const dispatch = useAppDispatch();

    const handleIfram = useCallback((e: any) => {
        if (refIFrame.current.contentDocument?.children[0].children[1].hasChildNodes()) {
            //MD am 10.08.2023 angepasst.
            let result = JSON.parse(refIFrame.current.contentDocument?.children[0].children[1].children[0].innerHTML);
            refFileUploader.current.instance.reset();
            if (result.erfolg) {
                dispatch(setToast({
                    message: "Datei wurde erfolgreich verarbeitet.",
                    type: "success",
                }));
            } else {
                dispatch(setAlert({
                    text: result.hinweis ? result.hinweis : "Es ist ein Fehler beim Import aufgetreten.",
                    type: "Fehler",
                    maxWidth: 600
                }));
            }
            setShowPopUpImport(false);
            reset_data();
        }
    }, [refIFrame, refFileUploader, dispatch, reset_data]);

    const handleHidePopup = useCallback(() => {
        setShowPopUpImport(false)
    }, []);
    const handleShowPopup = useCallback(() => {
        setShowPopUpImport(true)
    }, []);

    const handleClickImportStarten = useCallback(() => {
        if (files.length > 0) {
            if (refForm.current.querySelector(".dx-fileuploader-file-status-message")?.innerHTML === datei_bereit_zum_hochladen) {
                refForm.current.submit()
            }
        } else {
            dispatch(setToast({
                message: "Wählen Sie bitte eine Datei aus.",
                type: "error",
            }))
        }
    }, [files, dispatch]);

    const memo_options_starten = useMemo(() => {
        return {
            text: 'Import starten',
            onClick: handleClickImportStarten,
        }
    }, [handleClickImportStarten]);

    const memo_options_abbrechen = useMemo(() => {
        return {
            text: 'Abbrechen',
            onClick: handleHidePopup,
        }
    }, [handleHidePopup]);

    return (
        <>
            <div id='kfz_hinweis_import_button'>
                <MDButton
                    icon={icon_import}
                    tooltip_text="KFZ-Hinweise importieren"
                    page='KFZ-hinweise'
                    onClick={handleShowPopup}
                />
            </div>
            <Popup
                visible={showPopUpImport}
                onHiding={handleHidePopup}
                dragEnabled={true}
                hideOnOutsideClick={true}
                fullScreen={false}
                showCloseButton={true}
                height='auto'
                maxHeight="750px"
                maxWidth="500px"
            >
                <ToolbarItem
                    toolbar="top"
                    location={'before'}
                    render={RenderePopupTitle}
                />
                <Position
                    at={isScreenSmall ? "center" : "right"}
                    my={isScreenSmall ? "center" : "right top"}
                    of={isScreenSmall ? window : '#kfz_hinweis_import_button'}
                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="before"
                    options={memo_options_starten}
                />

                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={memo_options_abbrechen}
                />
                <ScrollView>
                    <div className='row p-2 p-md-3'>
                        <div className="col-12 p-1 p-md-2">
                            <p>Wählen Sie eine Datei (*.xml) um KFZ-Hinweise von Ihrem Rechner zu importieren:</p>
                        </div>
                        <div className="col-12 p-1 p-md-2">
                            <form ref={refForm} target="iframe_kfz_hinweise_import" method="post" action="/" encType="multipart/form-data">
                                <input type="hidden" name="Operation" value="ImportKfzHinweise" />
                                <FileUploader
                                    multiple={false}
                                    accept={"text/xml"}
                                    uploadMode={"useForm"}
                                    uploadUrl="/"
                                    labelText=""
                                    ref={refFileUploader}
                                    selectButtonText="Datei ..."
                                    name="Datei"
                                    readyToUploadMessage={datei_bereit_zum_hochladen}
                                    onValueChange={setFiles}
                                />
                            </form>
                            <iframe title="iframe_kfz_hinweise_import" name="iframe_kfz_hinweise_import" ref={refIFrame} onLoad={handleIfram} className="d-none"></iframe>
                        </div>
                    </div>
                </ScrollView>
            </Popup>
        </>
    )
}

export default KFZHinweiseImportPopup
const RenderePopupTitle = () => {
    return (<h2 className="title_popup">KFZ Hinweise importieren</h2>)
};
