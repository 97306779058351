import React, { useCallback, useRef, useState } from 'react';
import 'devextreme/data/odata/store';
import DataGrid, {
  ColumnChooser, Scrolling, Toolbar, Item as ItemGridToolbar, ColumnChooserSelection
} from 'devextreme-react/data-grid';
import '../STAT.scss';
import StatistikFilter from './Filtertabelle'
import { exportGridAsExcel, exportGridAsPDF, handleContentReadyDataGrid } from "../../../global/hilfs_functions";
import { IStatistikIndividuellAuswahl, IStatistikIndividuellDate } from '../interfaces';
import { GetData } from '../../../global/communication';
import { Seperatorliste } from '../../../global/seperator_liste';
import { useAppDispatch } from '../../../state';
import { RendereGridTitleStatistikInid } from "../../../sonstiges/hilfs_functions";
import { MDButton } from "../../../global/components";
import { icon_column_chooser, icon_excel, icon_pdf, icon_plus, icon_upload } from '../../../global/const';
import SQLHochladen from './SQLHochladen';




const Statistik_Individuell = (): JSX.Element => {
  const [hidePopUp, sethidePopUp] = useState<Boolean>(true);
  const [hidePopUpHochladen, sethidePopUpHochladen] = useState<Boolean>(true);
  const [data, setData] = useState<IStatistikIndividuellDate[]>([]);
  const dataGridRef = useRef() as React.MutableRefObject<DataGrid<any, any>>;
  const [pdfHeader, setPDFHeader] = useState("");
  const dispatch = useAppDispatch();


  const onPopupOkClick = useCallback((daten: string, datenfelder_for_pdf: string, selected: IStatistikIndividuellAuswahl) => {
    sethidePopUp(true);
    setPDFHeader(datenfelder_for_pdf);

    let end_daten = new Seperatorliste("%00", "%01");

    end_daten.setValue("LfdNr", selected.f001.toString());
    end_daten.setValue("EingabefelderXml", daten);

    GetData({
      Operation: "ExecuteStatistikIndividuell",
      Daten: end_daten.getSeperatorliste()
    }, dispatch)
      .then((res) => {
        if (!res) {
          setData([]);
          return
        }
        setData(res.data);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const handleShowPopup = useCallback(() => {
    sethidePopUp(false)
  }, []);
  const handleHidePopup = useCallback(() => {
    sethidePopUp(true)
  }, []);

  const handleShowPopupHochladen = useCallback(() => {
    sethidePopUpHochladen(false)
  }, []);
  const handleHidePopupHochladen = useCallback(() => {
    sethidePopUpHochladen(true)
  }, []);

  const handlePDf = useCallback(() => {
    exportGridAsPDF(dataGridRef, "StatstikIndividuell.pdf", pdfHeader, "landscape", "striped")
  }, [pdfHeader]);

  const handleExcel = useCallback(() => {
    exportGridAsExcel(dataGridRef, 'StatistikIndividuell.xlsx')
  }, []);

  const handleColumnChooser = useCallback(() => {
    if (dataGridRef && dataGridRef.current) {
      dataGridRef.current.instance.showColumnChooser();
    }
  }, [dataGridRef]);

  return (
    <div className="data_grid_container mt-2 mt-md-3">

      <DataGrid
        dataSource={data}
        allowColumnResizing={true}
        columnAutoWidth={true}
        rowAlternationEnabled={true}
        focusedRowEnabled={false}
        renderAsync={true}
        remoteOperations={true}
        wordWrapEnabled={true}
        columnMinWidth={100}
        //  keyExpr={keyExpr}   
        ref={dataGridRef}

        className=" animate__animated animate__fadeIn"
        noDataText={"Keine Ergebnisse vorhanden. Führen Sie zuerst eine Statistik aus."}
        //height={dataGridHeight}  
        // onOptionChanged={handleOnOptionChange}   
        height={"auto"}
        onContentReady={handleContentReadyDataGrid}

      >
        <ColumnChooser enabled={true} mode="select" title="Spalten auswählen" allowSearch={true} sortOrder="asc">
          <ColumnChooserSelection
            selectByClick={true} />
        </ColumnChooser>
        <Scrolling mode={"infinite"} />
        <Toolbar>
          <ItemGridToolbar
            location={'before'}
            render={RendereGridTitleStatistikInid}
          />
          <ItemGridToolbar location="after" locateInMenu="auto">
            <MDButton
              page='statistik_individuell'
              icon={icon_plus}
              type="success"
              onClick={handleShowPopup}
              tooltip_text="Eine neue Statstik ausführen"
            />
          </ItemGridToolbar>
          <ItemGridToolbar location="after" locateInMenu="auto">
            <MDButton
              page='statistik_individuell'
              icon={icon_upload}
              onClick={handleShowPopupHochladen}
              tooltip_text="Statstik hochladen"
            />
          </ItemGridToolbar>
          <ItemGridToolbar
            location={'after'}
            locateInMenu="auto"
          >
            <MDButton
              page='statistik_individuell'
              icon={icon_pdf}
              onClick={handlePDf}
              tooltip_text="Tabelle als PDF herunterladen"
            />
          </ItemGridToolbar>
          <ItemGridToolbar
            location={'after'}
            locateInMenu="auto"
          >
            <MDButton
              page='statistik_individuell'
              icon={icon_excel}
              onClick={handleExcel}
              tooltip_text="Tabelle als Excel herunterladen"
            />
          </ItemGridToolbar>
          <ItemGridToolbar location="after" locateInMenu="auto">
            <MDButton
              page='statistik_individuell'
              icon={icon_column_chooser}
              onClick={handleColumnChooser}
              tooltip_text="Spalten auswählen"
            />
          </ItemGridToolbar>
        </Toolbar>
      </DataGrid>
      {(hidePopUp === false) ? <StatistikFilter onCloseClick={handleHidePopup} onOkClick={onPopupOkClick}></StatistikFilter> : <></>}
      {(hidePopUpHochladen === false) ? <SQLHochladen callback={handleHidePopupHochladen} /> : <></>}
    </div>
  )
}

export default Statistik_Individuell;





