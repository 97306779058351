import { Popup, ScrollView, SelectBox, Validator } from 'devextreme-react';
import { TextBox } from 'devextreme-react/text-box';
import { ToolbarItem } from 'devextreme-react/autocomplete';
import { useCallback, useMemo, useState } from 'react';
import { EmailRule, NumericRule, StringLengthRule } from 'devextreme-react/data-grid';
import { Seperatorliste } from '../../../global/seperator_liste';
import { GetData } from '../../../global/communication';
import { IFahrerDaten } from './interfaces';
import { useAppDispatch } from '../../../state';
import { handleContentReadySelectBox, handleContentReadyTextBox } from "../../../global/hilfs_functions";

interface IFahrerPopupProps {
    callBack: (update?: boolean, loeschen?: boolean) => void,
    anlegen: boolean,
    data: IFahrerDaten,
    lfdnr_firma: number
}

const get_anrede_value = (f002: string): string => {
    switch (f002) {
        case "":
            return "";
        case "1":
            return "Herr";
        case "2":
            return "Frau";
        default:
            return "";
    }
}
const get_anrede_value_db = (anrede: string): string => {
    switch (anrede) {
        case "":
            return "";
        case "Herr":
            return "1";
        case "Frau":
            return "2";
        default:
            return "";
    }
}

const anrede_items = ["", "Herr", "Frau"];
const FahrerPopup: React.FC<IFahrerPopupProps> = ({ callBack, anlegen, data, lfdnr_firma }) => {
    const [anrede, setAnrede] = useState(anlegen ? "" : get_anrede_value(data.f002));
    const [vorname, setVorname] = useState(anlegen ? '' : data.f003);
    const [name, setName] = useState(anlegen ? '' : data.f004);
    const [strasse, setStrasse] = useState(anlegen ? '' : data.f005);
    const [plz, setPlz] = useState(anlegen ? '' : data.f006.toString());
    const [ort, setOrt] = useState(anlegen ? '' : data.f007);
    const [festnetznummer, setFestnetznummer] = useState(anlegen ? '' : data.f008);
    const [mobilNummer, setMobilNummer] = useState(anlegen ? '' : data.f009);
    const [eMail, setEMail] = useState(anlegen ? '' : data.f010);
    const dispatch = useAppDispatch();

    const handleClickSpeichern = useCallback((e: any) => {
        let result = e.validationGroup.validate();
        if (result.isValid) {

            let daten = new Seperatorliste("%00", "%01");

            !anlegen && daten.setValue("Fahrernummer", data!.f001.toString());
            daten.setValue("Firmennummer", lfdnr_firma?.toString());
            daten.setValue("Anrede", get_anrede_value_db(anrede));
            daten.setValue("Vorname", vorname);
            daten.setValue("Name", name);
            daten.setValue("Strasse", strasse);
            daten.setValue("Postleitzahl", plz);
            daten.setValue("Ort", ort);
            daten.setValue("Festnetznummer", festnetznummer);
            daten.setValue("Mobilnummer", mobilNummer);
            daten.setValue("E-Mail", eMail);

            GetData({
                Operation: anlegen ? "InsertAbschleppfahrer" : "UpdateAbschleppfahrer",
                Daten: daten.getSeperatorliste()
            }, dispatch).then((res) => {
                if (!res) return
                callBack(true);
            })

        }
    }, [data, lfdnr_firma, anrede, vorname, name, strasse, plz, ort, festnetznummer, mobilNummer, eMail, dispatch, anlegen, callBack]);

    const handleCallBack = useCallback(() => {
        callBack()
    }, [callBack]);
    const handleCallBackLoeschen = useCallback((update?: boolean, loeschen?: boolean) => {
        callBack(undefined, true)
    }, [callBack]);

    const memo_options_loeschen = useMemo(() => {
        return {
            text: 'Löschen',
            type: "danger",
            onClick: handleCallBackLoeschen,
        }
    }, [handleCallBackLoeschen]);
    const memo_options_speichern = useMemo(() => {
        return {
            text: 'Speichern',
            onClick: handleClickSpeichern,
        }
    }, [handleClickSpeichern]);
    const memo_options_abbrechen = useMemo(() => {
        return {
            text: 'Abbrechen',
            onClick: callBack,
        }
    }, [callBack]);

    const handleAnredeValue = useCallback((e: any) => {
        setAnrede(e.value)
    }, []);

    const RenderePopupTitle = useCallback(() => {
        return (<h2 className="title_popup">{anlegen ? "Neuen Fahrer anlegen" : "Fahrer bearbeiten"}</h2>)
    }, [anlegen]);

    return (
        <Popup
            visible={true}
            onHiding={handleCallBack}
            hideOnOutsideClick={true}
            fullScreen={false}
            showCloseButton={true}
            maxHeight="490px"
            maxWidth="700px"
        >
            <ToolbarItem
                toolbar="top"
                location={'before'}
                render={RenderePopupTitle}
            />
            {!anlegen &&
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="before"
                    options={memo_options_loeschen}
                />
            }


            <ToolbarItem
                widget="dxButton"
                toolbar="bottom"
                location="after"
                options={memo_options_speichern}
            />
            <ToolbarItem
                widget="dxButton"
                toolbar="bottom"
                location="after"
                options={memo_options_abbrechen}
            />
            <ScrollView>
                <div className="row">
                    <div className="col-11 col-md-6 py-2">
                        <SelectBox
                            labelMode="floating"
                            stylingMode="outlined"
                            label="Anrede"
                            value={anrede}
                            items={anrede_items}
                            onValueChanged={handleAnredeValue}
                            onContentReady={handleContentReadySelectBox}
                        />
                    </div>
                    <div className="col-11 col-md-6 py-2">
                        <TextBox
                            label='Vorname'
                            labelMode='floating'
                            stylingMode='outlined'
                            mode='text'
                            showClearButton={false}
                            onValueChange={setVorname}
                            value={vorname}
                            valueChangeEvent="input"
                            maxLength={50}
                            onContentReady={handleContentReadyTextBox}
                        />
                    </div>
                    <div className="col-11 col-md-6 py-2">
                        <TextBox
                            label='Name'
                            labelMode='floating'
                            stylingMode='outlined'
                            mode='text'
                            showClearButton={false}
                            onValueChange={setName}
                            value={name}
                            valueChangeEvent="input"
                            maxLength={50}
                            onContentReady={handleContentReadyTextBox}
                        />
                    </div>
                    <div className="col-11 col-md-6 py-2">
                        <TextBox
                            label='Straße'
                            labelMode='floating'
                            stylingMode='outlined'
                            mode='text'
                            showClearButton={false}
                            onValueChange={setStrasse}
                            value={strasse}
                            valueChangeEvent="input"
                            maxLength={50}
                            onContentReady={handleContentReadyTextBox}
                        />
                    </div>
                    <div className="col-11 col-md-6 py-2">
                        <TextBox
                            label='PLZ'
                            labelMode='floating'
                            stylingMode='outlined'
                            mode='text'
                            showClearButton={false}
                            onValueChange={setPlz}
                            value={plz}
                            valueChangeEvent="input"
                            maxLength={12}
                            onContentReady={handleContentReadyTextBox}
                        >
                            <Validator >
                                <NumericRule message='Nur Zahlen dürfen eingegeben werden.' />
                                <StringLengthRule ignoreEmptyValue={true} max={5} min={5} message='Die Postleitzahl besteht aus 5 Ziffern.' />
                            </Validator>
                        </TextBox>
                    </div>
                    <div className="col-11 col-md-6 py-2">
                        <TextBox
                            label='Ort'
                            labelMode='floating'
                            stylingMode='outlined'
                            mode='text'
                            showClearButton={false}
                            onValueChange={setOrt}
                            value={ort}
                            valueChangeEvent="input"
                            maxLength={50}
                            onContentReady={handleContentReadyTextBox}
                        />
                    </div>
                    <div className="col-11 col-md-6 py-2">
                        <TextBox
                            label='Festnetznummer'
                            labelMode='floating'
                            stylingMode='outlined'
                            mode='text'
                            showClearButton={false}
                            onValueChange={setFestnetznummer}
                            value={festnetznummer}
                            valueChangeEvent="input"
                            maxLength={30}
                            onContentReady={handleContentReadyTextBox}
                        />
                    </div>
                    <div className="col-11 col-md-6 py-2">
                        <TextBox
                            label='Mobilnummer'
                            labelMode='floating'
                            stylingMode='outlined'
                            mode='text'
                            showClearButton={false}
                            onValueChange={setMobilNummer}
                            value={mobilNummer}
                            valueChangeEvent="input"
                            maxLength={30}
                            onContentReady={handleContentReadyTextBox}
                        />
                    </div>
                    <div className="col-11 col-md-6 py-2">
                        <TextBox
                            label='E-Mail'
                            labelMode='floating'
                            stylingMode='outlined'
                            mode='text'
                            showClearButton={false}
                            onValueChange={setEMail}
                            value={eMail}
                            valueChangeEvent="input"
                            maxLength={60}
                            onContentReady={handleContentReadyTextBox}
                        >
                            <Validator >
                                <EmailRule ignoreEmptyValue={true} message='Die E-Mail-Adresse stimmt nicht.' />
                            </Validator>
                        </TextBox>
                    </div>
                </div>
            </ScrollView>
        </Popup>
    )
}
export default FahrerPopup;