import { Popup, ScrollView, TextBox } from "devextreme-react"
import { ToolbarItem } from "devextreme-react/autocomplete"
import { useCallback, useMemo, useState } from "react";
import { GetData } from "../../../global/communication";
import { handleContentReadyTextBox } from "../../../global/hilfs_functions";
import { Seperatorliste } from "../../../global/seperator_liste";
import { setConfirm, setToast, useAppDispatch } from "../../../state";
import { ITatbestandHinweis } from "./interfaces";

interface ITatbestandHinweisVerwaltePopupProps {
    callback: (update?: boolean) => void,
    row?: ITatbestandHinweis | null,
}

const TatbestandHinweisVerwaltePopup: React.FC<ITatbestandHinweisVerwaltePopupProps> = ({ callback, row }): JSX.Element => {

    const isAdd = row === undefined;
    const [tbNummer, setTbNummer] = useState((!isAdd && row?.f001) ? row?.f001 : "");
    const [hinweis, setHinweis] = useState((!isAdd && row?.f002) ? row?.f002 : "");
    const dispatch = useAppDispatch();

    const handleClickSpeichern = useCallback(() => {
        if (!tbNummer) {
            dispatch(setToast({
                message: "Tatbestandnummer muss vorhanden sein.",
                type: "error"
            }));
            return
        }
        if (!hinweis) {
            dispatch(setToast({
                message: "Hinweis muss vorhanden sein.",
                type: "error"
            }));
            return
        }

        let daten = new Seperatorliste("%00", "%01");

        daten.setValue("Tatbestandsnummer", tbNummer.trim());
        daten.setValue("Tatbestandshinweis", hinweis.trim());
        let obj;
        if (isAdd) {
            obj = {
                Operation: "InsertTatbestandshinweis",
                Daten: daten.getSeperatorliste()
            }
        } else {
            obj = {
                Operation: "UpdateTatbestandshinweis",
                Daten: daten.getSeperatorliste()
            }
        }

        GetData(obj, dispatch).then((res) => {
            if (!res) {
                callback();
                return
            }
            callback(true);

        });
    }, [tbNummer, hinweis, isAdd, dispatch, callback]);

    const handleShowConfirm = useCallback(() => {
        delete_tatbestandhinweis(dispatch, row, callback);
    }, [dispatch, row, callback]);

    const handleHidePopup = useCallback(() => {
        callback()
    }, [callback]);

    const memo_options_loeschen = useMemo(() => {
        return {
            text: 'Löschen',
            type: "danger",
            onClick: handleShowConfirm,
        }
    }, [handleShowConfirm]);

    const memo_options_speichern = useMemo(() => {
        return {
            text: 'Speichern',
            onClick: handleClickSpeichern,
        }
    }, [handleClickSpeichern]);


    const memo_options_abbrechen = useMemo(() => {
        return {
            text: 'Abbrechen',
            onClick: handleHidePopup,
        }
    }, [handleHidePopup]);

    const RenderePopupTitle = useCallback(() => {
        return (<h2 className="title_popup">{isAdd ? "Neuen Tatbestandhinweis anlegen" : "Tatbestandhinweis bearbeiten"}</h2>)
    }, [isAdd]);
    return (
        <>
            <Popup
                visible={true}
                onHiding={handleHidePopup}
                dragEnabled={true}
                hideOnOutsideClick={true}
                fullScreen={false}
                showCloseButton={true}
                height='auto'
                maxHeight="70vh"
                maxWidth="500px"
            >
                <ToolbarItem
                    toolbar="top"
                    location={'before'}
                    render={RenderePopupTitle}
                />
                {!isAdd &&
                    <ToolbarItem
                        widget="dxButton"
                        toolbar="bottom"
                        location="before"
                        options={memo_options_loeschen}
                    />}
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={memo_options_speichern}
                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={memo_options_abbrechen}
                />
                <ScrollView>
                    <div className="row p-2 p-md-3">
                        <div className="col-12  col-md-6 p-1 p-md-2">
                            <TextBox
                                label="Tatbestandsnummer"
                                labelMode="floating"
                                stylingMode="outlined"
                                onValueChange={setTbNummer}
                                value={tbNummer}
                                disabled={!isAdd}
                                valueChangeEvent="input"
                                maxLength={10}
                                onContentReady={handleContentReadyTextBox}
                            />
                        </div>
                        <div className="col-12 col-md-6 p-1 p-md-2">
                            <TextBox
                                label="Tatbestandshinweis"
                                labelMode="floating"
                                stylingMode="outlined"
                                onValueChange={setHinweis}
                                value={hinweis}
                                valueChangeEvent="input"
                                maxLength={140}
                                onContentReady={handleContentReadyTextBox}
                            />
                        </div>
                    </div>
                </ScrollView>

            </Popup>
        </>
    )
}

export default TatbestandHinweisVerwaltePopup;



const delete_tatbestandhinweis = (dispatch: any, data: any, callback: any) => {
    dispatch(setConfirm({
        body:
            <div className="alert alert-danger">
                <p>Wollen sie den Tatbestandhinweis zur Tatbestandnummer <b>{data.f001}</b> wirklich löschen?</p>
            </div>,
        on_ok: () => {

            let daten = new Seperatorliste("%00", "%01");

            daten.setValue("Tatbestandsnummer", data.f001.trim());
            daten.setValue("Tatbestandshinweis", data.f002.trim());

            GetData({
                Operation: "DeleteTatbestandshinweis",
                Daten: daten.getSeperatorliste()
            }, dispatch).then((res) => {
                if (!res) return
                dispatch(setToast({
                    message: "Tatbestandshinweis wurde erfolgreich gelöscht",
                    type: "success",
                    position: "bottom center"
                }));
                callback(true)
            });
        },
        title: "Tatbestandhinweis löschen",
    }));
}