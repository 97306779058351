
import React, { memo, useMemo } from 'react';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import './header.scss';
import { MDButton } from "../../global/components";
import { icon_menu } from '../../global/const';
import { useAppSelector } from '../../state';
import { handleContentReadyToolbar } from '../../global/hilfs_functions';

interface IHeaderProps {
  toggleMenu: () => void,
  expanded: boolean,
}


const Header: React.FC<IHeaderProps> = ({ toggleMenu, expanded }): JSX.Element => {
  const sachbearbeiternummer = useAppSelector((state) => state.store_einstellungen.konfiguration.sachbearbeiternummer);
  const sachbearbeitername = useAppSelector((state) => state.store_einstellungen.konfiguration.sachbearbeitername);
  const mandantennummer = useAppSelector((state) => state.store_einstellungen.konfiguration.mandantennummer);
  const mandantenbezeichnung = useAppSelector((state) => state.store_einstellungen.konfiguration.mandantenbezeichnung);
  const myText = useMemo(() => {
    return mandantenbezeichnung + ' (' + mandantennummer + ')§' + sachbearbeitername + ' (' + sachbearbeiternummer + ')';
  }, [sachbearbeiternummer, sachbearbeitername, mandantennummer, mandantenbezeichnung]);

  return (
    <header className={'header-component'}>
      <Toolbar className={'header-toolbar'} onContentReady={handleContentReadyToolbar}>
        <Item
          location={'before'}
          widget={'dxButton'}
          cssClass={'menu-button'}
        >
          <MDButton
            page='faelle'
            icon={icon_menu}
            onClick={toggleMenu}
            tooltip_text="Navigationsmenü"
            ariaExpanded={expanded ? "true" : "false"}
          />
        </Item>
        <Item
          location={'before'}
          text={myText}
          cssClass={"mandanteninfo"}
          render={GetMandantenInfo}
        />
        <Item
          location={'after'}
          text="Winowig Online NT"
          cssClass={"pr-2 pr-md-4"}
        />
      </Toolbar>
    </header>
  )
}

export default memo(Header);

const GetMandantenInfo = (data: any) => {
  //data.text.split('§')[0]}&nbsp;&nbsp;&nbsp;&nbsp;{data.text.split('§')[1]}


  return (
    <>
      <span>
        {data.text.split('§')[0]}
      </span>
      <span className='ml-2'>
        {data.text.split('§')[1]}
      </span>
    </>
  )
}