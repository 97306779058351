import { memo } from "react";
import { icon_file } from "../../../../../global/const";
import { MDButton } from "../../../../../global/components";

interface IABSAbschleppProtokollProps {
    lfdnr: number,
}
const ABSAbschleppProtokoll: React.FC<IABSAbschleppProtokollProps> = ({ lfdnr }): JSX.Element => {
    let abschleppprotokollUri = "/FileRequest/Abschleppprotokoll.pdf?Operation=CreateAbschleppprotokoll&LaufendeNummer=" + lfdnr;

    return (
        <li>
            <a target="_blank" href={abschleppprotokollUri} rel="noreferrer">
                <MDButton
                    page="fall_card"
                    icon={icon_file}
                    tooltip_text="Abschleppprotokoll als PDF speichern"
                />
            </a>
        </li>);
}

export default memo(ABSAbschleppProtokoll)