import React from 'react';
import Drawer from 'devextreme-react/drawer';
import Navigation from '../navigation/navigation';
import Header from "../header/header";
import './content.scss';
import { Template } from 'devextreme-react/core/template';
import useContentLogik, { MenuStatus } from './contentLogik';

export interface IContentLogikReturn {
  toggleMenu: () => void,
  onOutsideClick: () => boolean,
  isLarge: boolean,
  isSmall: boolean,
  menuStatus: number,
  onNavigationChanged: (path: string, event: any) => void,
  temporaryOpenMenu: () => void,
}

interface IContentProps {
  footer: JSX.Element,
  children: React.ReactNode,
}

const Content: React.FC<IContentProps> = ({ footer, children }): JSX.Element => {
  const data: IContentLogikReturn = useContentLogik();

  return (
    <div className={'appContent'}>
      <Header
        toggleMenu={data.toggleMenu}
        expanded={data.menuStatus === 2}
      />
      <Drawer
        className='drawer'
        position={'before'}
        closeOnOutsideClick={data.onOutsideClick}
        openedStateMode={data.isLarge ? 'shrink' : 'overlap'}
        revealMode={data.isSmall ? 'slide' : 'expand'}
        minSize={data.isSmall ? 0 : 60}
        maxSize={260}
        shading={data.isLarge ? false : true}
        opened={data.menuStatus === MenuStatus.Closed ? false : true}
        template={'nav'}
      >
        <main className={['content_container', 'mx-2 mx-lg-3'].join(" ")}>
          {children}
          {footer}
        </main>
        <Template name={'nav'}>
          <Navigation
            collapseAll={data.menuStatus === MenuStatus.Closed}
            selectedItemChanged={data.onNavigationChanged}
            openMenu={data.temporaryOpenMenu}
            expanded={data.menuStatus === 2}
          >
          </Navigation>
        </Template>
      </Drawer>
    </div>
  );
}
export default Content;
