import { memo, useCallback, useState } from "react";
import { ISelectedItemStrassen } from "..";
import { GetData } from "../../../../global/communication";
import { setConfirm, setToast, useAppDispatch } from "../../../../state";
import { IBezirk } from "../interfaces";
import BezirkVerwaltePopup from "./verwalten_popup";
import BezirkGrid from "./bezirk_grid";

interface IBezirkProps {
    selectedItem: ISelectedItemStrassen,
    setSelectedItem: (obj: ISelectedItemStrassen) => void,
}

const Bezirke: React.FC<IBezirkProps> = ({ selectedItem, setSelectedItem }) => {
    const dispatch = useAppDispatch();
    const [refreshGrid, setRefreshGrid] = useState(true);
    const [bearbeitePopUp, setBearbeitePopup] = useState<{ show: boolean, row: IBezirk | null }>({
        show: false,
        row: null,
    });

    const handleCallBack = useCallback((update?: boolean, loeschen?: boolean) => {
        (loeschen) &&
            delete_bezirk(dispatch, bearbeitePopUp.row, () => setRefreshGrid(prev => !prev));

        setBearbeitePopup({ show: false, row: null })
        update && setRefreshGrid(prev => !prev);
    }, [bearbeitePopUp, dispatch]);

    return (
        <div className="data_grid_container  data_grid_with_header mt-2 mt-md-3">
            {bearbeitePopUp.show && <BezirkVerwaltePopup selectedItem={selectedItem} row={bearbeitePopUp.row} callback={handleCallBack} />}
            <BezirkGrid refresh_grid={refreshGrid} selectedItem={selectedItem} setBearbeitePopup={setBearbeitePopup} setSelectedItem={setSelectedItem} />

        </div>
    )
}

export default memo(Bezirke);

const delete_bezirk = (dispatch: any, data: any, callback: any) => {
    dispatch(setConfirm({
        body:
            <div className="alert alert-danger">
                <p>Wollen sie den Bezirk <b>{data.f001}</b> wirklich löschen?</p>
            </div>,
        on_ok: () => {
            GetData({
                Operation: "DeleteBezirk",
                LfdNr: data.lfdnr
            }, dispatch).then((res) => {
                if (!res) return
                dispatch(setToast({
                    message: "Bezirk wurde erfolgreich gelöscht",
                    type: "success",
                    position: "bottom center"
                }));
                callback();

            });

        },
        title: "Löschen",
    }));
}