import { CheckBox, DataGrid, TextBox } from "devextreme-react";
import { Column, Toolbar, Item as ItemGridToolbar, Scrolling, Paging, ColumnChooser, ColumnChooserSelection } from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import { memo, useCallback, useMemo, useRef, useState } from "react";
import { GetData } from "../../../global/communication";
import { format_tatbestand_basis_betragsfestellung, format_tatbestand_fahrverbot, format_tatbestand_in_winowig_mobil_verwenden, format_tatbestand_tatbestandtyp, TatbestaendeAllColumns } from "../../../sonstiges/columns";
import { default_spalten_t002, icon_column_chooser, icon_csv, icon_pdf, MAX_ITEMS_DATA_GRID } from "../../../global/const";
import { exportGridAsPDF, get_header_pdf_search, handleContentReadyCheckBox, handleContentReadyDataGrid, handleContentReadyTextBox } from "../../../global/hilfs_functions";
import { useAppDispatch, useAppSelector } from "../../../state";
import { get_end_spalten_to_db } from "../../faelle/hilfs_functions";
import { RendereGridTitleTatbestaende } from "../../../sonstiges/hilfs_functions";
import { MDButton } from "../../../global/components";
import TatbestaendAdd from "./add";
import TatbestaendeFilterPopup, { IFilterTatbestand, Init_filter, filter_tatbestaende_default_number } from "./filter_popup";
import { ITatbestandData } from "./interfaces";
import { Seperatorliste } from "../../../global/seperator_liste";
import { get_date_ajax_param } from "../../../global/hilfs_functions";
import { IColumn } from "../../../global/interfaces";



interface ITatbestaendeGridProps {
    setBearbeitePopup: (value: { show: boolean, row: ITatbestandData | null }) => void,
    refresh_grid: boolean,
}

const get_columns = (spalten: string): IColumn[] => {
    !spalten && (spalten = default_spalten_t002)
    let arr: string[] = spalten.split(';').filter(i => i).map(i => i.trim());

    for (let i of arr) {
        for (let j in TatbestaendeAllColumns) {
            if (TatbestaendeAllColumns[j].dataField === i) {
                TatbestaendeAllColumns[j].visible = true;
                break;
            }
        }
    }

    return TatbestaendeAllColumns;

}

const TatbestaendeGrid: React.FC<ITatbestaendeGridProps> = ({ setBearbeitePopup, refresh_grid }) => {

    const konfiguration_spalten = useAppSelector((state) => state.store_einstellungen.konfiguration.t002_spalten);
    const columns = useMemo(() => get_columns(konfiguration_spalten), [konfiguration_spalten]);
    const [tatbestandnummerSucheCheckBox, setTatbestandnummerSucheCheckBox] = useState<boolean | null>(false);
    const dataGridRef = useRef() as React.MutableRefObject<DataGrid<any, any>>;
    const [searchValue, setSearchValue] = useState("");
    const [filterPopup, setFilterPopup] = useState<IFilterTatbestand>(Init_filter)
    const filter = useMemo(() => get_filter_tatbestaende(searchValue, tatbestandnummerSucheCheckBox!, filterPopup), [searchValue, tatbestandnummerSucheCheckBox, filterPopup]);
    const dispatch = useAppDispatch();
    const store_end = useMemo(() => store(filter),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [refresh_grid, filter]);


    const get_data = useCallback(() => {
        // setSearchValue("");
        // dataGridRef && dataGridRef.current && dataGridRef.current.instance.clearSorting();
        dataGridRef && dataGridRef.current && dataGridRef.current.instance.refresh();
    }, []);

    const handleOptionsChange = useCallback((e: any) => {
        get_end_spalten_to_db(e, konfiguration_spalten, TatbestaendeAllColumns, dispatch, "T002")
    }, [konfiguration_spalten, dispatch]);

    const handleRowClick = useCallback(({ data }: any) => {
        setBearbeitePopup({ show: true, row: data });
    }, [setBearbeitePopup]);

    const handlePDF = useCallback(() => {
        let header = get_header_pdf_search(searchValue);
        exportGridAsPDF(dataGridRef, "Tatbestände.pdf", header, "landscape", "striped", (cellData: any) => {
            if (cellData.gridCell.column.dataField === 'f02011' && cellData.gridCell.rowType === 'data') {
                cellData.pdfCell.text = format_tatbestand_fahrverbot(parseInt(cellData.pdfCell.text));
            } else if (cellData.gridCell.column.dataField === 'f02019' && cellData.gridCell.rowType === 'data') {
                cellData.pdfCell.text = format_tatbestand_tatbestandtyp(cellData.pdfCell.text);
            } else if (cellData.gridCell.column.dataField === 'f02023' && cellData.gridCell.rowType === 'data') {
                cellData.pdfCell.text = format_tatbestand_basis_betragsfestellung(cellData.pdfCell.text);
            } else if (cellData.gridCell.column.dataField === 'f02030' && cellData.gridCell.rowType === 'data') {
                cellData.pdfCell.text = format_tatbestand_in_winowig_mobil_verwenden(parseInt(cellData.pdfCell.text));
            }
        })
    }, [searchValue]);


    const handleColumnChooser = useCallback(() => {
        if (dataGridRef && dataGridRef.current) {
            dataGridRef.current.instance.showColumnChooser();
        }
    }, [dataGridRef]);

    const handleKeyDown = useCallback((e: any) => {
        if (e.event.key === "Enter") {
            let element = document.querySelector(".dx-focused");
            if (element) {
                let index = parseInt(element.parentElement?.getAttribute("aria-rowindex") || "0");
                if (index > 0) {
                    let row = dataGridRef.current.instance.getDataSource().items()[index - 1];
                    setBearbeitePopup({ show: true, row: row });
                }
            }
        }
    }, [setBearbeitePopup])

    const handleDownloadCSV = useCallback(() => {
        let arr = dataGridRef.current.instance.getVisibleColumns().filter((e) => e.sortOrder);
        let sortspalte = arr.length > 0 ? arr[0].dataField : "";
        let sortRichtung = arr.length > 0 ? arr[0].sortOrder!.toUpperCase() : "";
        window.location.href = "/Files/csv/tatbestaende.csv?Operation=GetTatbestaendeAsCSV&sortSpalte=" + sortspalte + "&sortRichtung=" + sortRichtung + "&filter=" + encodeURIComponent(filter) + "&felder=" + konfiguration_spalten;
    }, [filter, konfiguration_spalten]);


    return (
        <div className="data_grid_container  mt-2">
            <DataGrid
                dataSource={store_end}

                columnAutoWidth={true}
                renderAsync={true}
                //  keyExpr="f02001"
                wordWrapEnabled={true}
                className="animate__animated animate__fadeIn cursor_pointer"
                remoteOperations={true}
                ref={dataGridRef}
                rowAlternationEnabled={true}
                onOptionChanged={handleOptionsChange}//handleOptionsChange}    
                onRowClick={handleRowClick}
                hoverStateEnabled={true}
                noDataText="Keine Tatbestände"
                onContentReady={handleContentReadyDataGrid}
                onKeyDown={handleKeyDown}
            //focusStateEnabled={true}
            //focusedRowEnabled={true}

            >
                <ColumnChooser allowSearch={true} enabled={true} mode="select" title="Spalten auswählen" sortOrder="asc" >
                    <ColumnChooserSelection
                        selectByClick={true} />
                </ColumnChooser>
                <Scrolling mode="virtual" rowRenderingMode="virtual" preloadEnabled={true} />
                <Paging defaultPageSize={MAX_ITEMS_DATA_GRID} />
                {
                    columns.map((column) => {
                        return (
                            <Column
                                dataField={column.dataField}
                                caption={column.caption}
                                dataType={column.dataType}
                                visible={column.visible}
                                key={column.dataField}
                                cellRender={column.cellRender}
                                alignment={column.alignment}
                                allowSorting={column.allowSorting}
                                minWidth={column.minWidth}
                            />
                        )
                    })
                }

                <Toolbar>
                    <ItemGridToolbar
                        location={'before'}
                        render={RendereGridTitleTatbestaende}
                    />
                    <ItemGridToolbar location="after" locateInMenu="auto">
                        <CheckBox onContentReady={handleContentReadyCheckBox} text="Tbnr. " value={tatbestandnummerSucheCheckBox} onValueChange={setTatbestandnummerSucheCheckBox} />
                    </ItemGridToolbar>
                    <ItemGridToolbar
                        location={'after'}
                        locateInMenu="never"
                    >
                        <TextBox
                            label="Suche"
                            stylingMode='outlined'
                            mode='text'
                            showClearButton={true}
                            value={searchValue}
                            className="suche_feld"
                            onValueChange={setSearchValue}
                            maxLength={60}
                            onContentReady={handleContentReadyTextBox}>
                        </TextBox>
                    </ItemGridToolbar>
                    <ItemGridToolbar location="after" locateInMenu="auto">
                        <TatbestaendAdd get_data={get_data} />
                    </ItemGridToolbar>
                    <ItemGridToolbar location="after" locateInMenu="auto">
                        <TatbestaendeFilterPopup callback={setFilterPopup} />
                    </ItemGridToolbar>
                    <ItemGridToolbar location="after" locateInMenu="auto">
                        <MDButton
                            icon={icon_pdf}
                            page="tatbestaende_grid"
                            onClick={handlePDF}
                            tooltip_text="Tabelle als PDF herunterladen"
                        />
                    </ItemGridToolbar>
                    <ItemGridToolbar
                        location={'after'}
                        locateInMenu="auto"
                    >
                        <MDButton
                            page='statistik'
                            icon={icon_csv}
                            tooltip_text='Tatbestände als csv herunterladen'
                            onClick={handleDownloadCSV}

                        />
                    </ItemGridToolbar>
                    {/**
                     * Falls erwünscht ist
                    <ItemGridToolbar location="after" locateInMenu="auto">
                        <MDButton
                            page='tatbe_'
                            icon={icon_excel}
                            onClick={() => exportGridAsExcel(dataGridRef, 'tatbestände.xlsx')}
                            tooltip_text="Tabelle als Excel herunterladen"
                        />
                    </ItemGridToolbar>
                     * 
                     */}
                    <ItemGridToolbar
                        location={'after'}
                        locateInMenu="auto"
                    >
                        <MDButton
                            page="tatbestaende_grid"
                            icon={icon_column_chooser}
                            className="column_chooser_button"
                            onClick={handleColumnChooser}
                            tooltip_text="Spalten auswählen"
                        />
                    </ItemGridToolbar>
                </Toolbar>

            </DataGrid></div>
    )
}
export default memo(TatbestaendeGrid);


const store = (filter: string) => {
    return new CustomStore({
        key: ["f02001"],
        load(loadOptions: any) {

            //Achtung isLoadingAll => pdf Download
            return GetData(
                {
                    Operation: "GetTatbestaende",
                    skip: (loadOptions.isLoadingAll || loadOptions.requireTotalCount) ? "0" : loadOptions["skip"].toString(),
                    take: loadOptions["isLoadingAll"] ? "1000" : loadOptions["take"].toString(),
                    filter: filter ? filter : "",
                    sortSpalte: loadOptions.sort ? loadOptions.sort[0].selector : "",
                    sortRichtung: loadOptions.sort ? loadOptions.sort[0].desc ? "DESC" : "ASC" : "",
                }, undefined, false
            )
                .then((res) => {
                    if (!res || !res.erfolg) return {
                        data: [],
                        totalCount: 0,
                    }

                    //  if(totalCount === 0){
                    //    setTotalCount(res.totalCount)
                    //  }else{
                    //  totalCount !== parseInt(res.totalCount) && setTotalCount(res.totalCount)
                    // }
                    return {
                        //   data: res.data,
                        data: res.data,
                        totalCount: res.end_data ? res.data.length : res.totalCount,
                    }

                });
            //   }


            //  }
        }

    });
}


const get_filter_tatbestaende = (searchValue: string, checkbox: boolean, filter: IFilterTatbestand) => {
    let filter_end = new Seperatorliste("%00", "%01");
    searchValue && filter_end.setValue("Suchbegriff", searchValue);
    checkbox && filter_end.setValue("isTbnr", "Ja");
    filter.gueltig_ab && filter_end.setValue("GueltigAb", get_date_ajax_param(filter.gueltig_ab));
    filter.gueltig_bis && filter_end.setValue("GueltigBis", get_date_ajax_param(filter.gueltig_bis));
    ((filter.haupt_kategorie || filter.haupt_kategorie === 0) && filter.haupt_kategorie !== filter_tatbestaende_default_number)
        && filter_end.setValue("HauptKategorie", filter.haupt_kategorie.toString());
    ((filter.unter_kategorie || filter.unter_kategorie === 0) && filter.unter_kategorie !== filter_tatbestaende_default_number)
        && filter_end.setValue("UnterKategorie", filter.unter_kategorie.toString());
    ((filter.mobil_verwenden || filter.mobil_verwenden === 0) && filter.mobil_verwenden !== filter_tatbestaende_default_number)
        && filter_end.setValue("Mobil", filter.mobil_verwenden.toString());
    filter.tatbestandstyp && filter_end.setValue("TatbestandsTyp", filter.tatbestandstyp);

    return filter_end.getSeperatorliste();

}