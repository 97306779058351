
export const MAX_ITEMS = 15;
export const MAX_ITEMS_DATA_GRID = MAX_ITEMS * 4; //MD 09.08.2023 60 auf einmal
export const MAX_ITEMS_FAELLE = 7;
export const DIFFERENCE = MAX_ITEMS_FAELLE / 5;
export const PERCENT_SCROLL = 90;
export const MIN_DATE = new Date(1970, 0, 1, 0, 0);
export const NOW = new Date();
export const default_spalten_t705 = ";f005;f002;f004;faelleHeute;f003;clientversion;"; //clientversion hinzugefügt bat raus
export const default_spalten_t715 = ";f001;f002;f010;f012;f026;f030;";
export const default_spalten_t701 = ";aktenzeichen;kassenzeichen;sachbearbeitername;f002;f003;f005;f007;exportTitel;loeschen;";
export const default_spalten_t002 = ";f02001;f02002;f02007;f02018;";


export const fall_arten = [{ text: "VOWI", value: 1 },
{ text: "FOWI", value: 2 },
{ text: "AOWI", value: 3 },
{ text: "ABS", value: 4 }]


export const load_panel_enabled = {
  enabled: true
};

export const datei_bereit_zum_hochladen = "Die Datei ist bereit zum Hochladen";

export const daten_felder_vor_definiert =
  [{
    text: "Datenfelder vordefiniert",
    items: [
      { text: "Bezirk - #{bezirk}" },
      { text: "Datum - #{datum}" },
      { text: "Jahr - #{jahr}" },
      { text: "Straße - #{strasse}" },
      { text: "Tatort - #{tatort}" },
      { text: "Tattag - #{tattag}" },
      { text: "Tattag (Von - Bis) - #{tattagvonbis}" },
      { text: "Tattag (Von) - #{tattagvon}" },
      { text: "Tatzeit - #{tatzeit}" },
      { text: "Uhrzeit - #{uhrzeit}" }
    ]
  }]



//Icons
export const icon_pdf = "fas fa-file-pdf";
export const icon_csv = "fa fa-file-csv";
export const icon_excel = "xlsxfile";
export const icon_plus = "fas fa-plus";
export const icon_column_chooser = "columnproperties";
export const icon_info = "info";
export const icon_history = "fas fa-history";
export const icon_arrow_left = "fa fa-arrow-left";
export const icon_car = "car";
export const icon_handy = "fa fa-mobile-button";
export const icon_check = "fa fa-check";
export const icon_close = "close";
export const icon_message = "fa fa-message";
export const icon_hand_dollar = "fa fa-hand-holding-dollar";
export const icon_euro_sign = "fa fa-euro-sign";
export const icon_battery = "fas fa-battery-half";
export const icon_arrow_right = "fa fa-arrow-right";
export const icon_edit = "fa fa-pen";
export const icon_code = "fas fa-file-code";
export const icon_file = "fas fa-file";
export const icon_user = "user";
export const icon_theme = "palette";
export const icon_rotate = "fa fa-rotate";
export const icon_delete = "fa fa-trash";
export const icon_filter = "fa fa-filter";
export const icon_preferences = "preferences";
export const icon_download = "fas fa-download";
export const icon_import = "fa fa-file-import";
export const icon_export = "fas fa-file-export";
export const icon_menu = "menu";
export const icon_upload = "upload";
export const icon_clock = "fas fa-clock";
export const icon_car_crash = "fas fa-car-crash"
export const icon_kartei_katen_darstellung = "fa fa-table-cells";
export const icon_listen_ansicht = "fa fa-list-ul";
export const icon_eye = "far fa-eye";
export const icon_folder = "fas fa-folder";
export const icon_laptop = "fa fa-laptop";
export const icon_eye_slash = "far fa-eye-slash";
export const icon_person_running = "fa fa-person-running";
export const icon_kreis = "isnotblank";
export const icon_road = "fa fa-road";
export const icon_paste = "paste";
export const icon_copy = "copy";
export const icon_i_cursor = "fa fa-i-cursor";
export const icon_refresh = "refresh";
export const icon_steuerdaten = "fa fa-wrench";
export const icon_address = "fa fa-address-book"
export const icon_find = "find";
export const icon_prev = "fa fa-caret-left";
export const icon_next = "fa fa-caret-right";
export const icon_prev_all = "fa fa-backward";
export const icon_next_all = "fa fa-forward";
export const icon_search = "search";
export const icon_more = "more";
export const icon_attach = "attach";
export const icon_hierarchy = "hierarchy";
export const icon_arrow_up = "arrowup";
export const icon_arrow_down = "arrowdown";
export const icon_text_document = "textdocument";
export const icon_doc_file = "docfile"
export const icon_floppy = "floppy"
export const icon_warning = "warning"

export const scroll_mode_infinite = { mode: "infinite" as "infinite" }
export const scroll_mode_virtual = { mode: "virtual" as "virtual" }

export const Auswahlliste_Spalten_T712 = ["lfdnr", "lfdnrbezirk", "f001"];
export const Auswahlliste_Spalten_T710 = ["lfdnr", "f001"];
export const Auswahlliste_Spalten_T702 = ["f004"];
export const Auswahlliste_Spalten_T00105 = ["lfdnr", "f033", "f001", "f002", "f031", "f032", "f034"];
export const Auswahlliste_Spalten_T00109 = ["f001", "f002", "f045", "f016", "f998"];
export const Auswahlliste_Spalten_T017 = ["f17002", "f17001", "f17004"];
export const Auswahlliste_Spalten_T007 = ["f07001", "f07002", "f07006", "f07021", "f07046", "f07100", "f07012", "f07016", "f07007", "f07008", "f07009", "f07010", "f07017"];
export const Auswahlliste_Spalten_T002 = ["f02001", "f02002", "f02042", "f02035", "f02007", "f02009", "f02017", "f02018", "f02011", "f02030"];
export const Auswahlliste_Spalten_T006 = ["f06001", "f06003", "f06004", "f06007", "f06018"];
export const Auswahlliste_Spalten_T00180 = ["f001", "f003"];
export const Auswahlliste_Spalten_T00190 = ["lfdnr", "f001"];
export const Auswahlliste_Spalten_T751 = ["lfdnr", "f002", "f003", "f004", "f011", "f005"];

export const monate_items = ["Alle Monate", "Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"]
export const tage_items = ["Alle Tage", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag", "Sonntag"];

export const text_area_rows_1 = { rows: "1" }
export const text_area_rows_2 = { rows: "2" }
export const text_area_rows_3 = { rows: "3" }
export const text_area_rows_4 = { rows: "4" }
export const text_area_rows_5 = { rows: "5" }
export const text_area_rows_6 = { rows: "6" }
export const text_area_rows_7 = { rows: "7" }


export const mZeichen = {
  "201": { foto: "zeichen201.png", alt: "Verkehrszeichen Andreaskreuz" },
  "205": { foto: "zeichen205.png", alt: "Verkehrszeichen Vorfahrt gewähren" },
  "206": { foto: "zeichen206.png", alt: "Verkehrszeichen Halt. Vorfahrt gewähren" },
  "209": { foto: "zeichen209.png", alt: "Verkehrszeichen Vorgeschriebene Fahrtrichtung rechts" },
  "211": { foto: "zeichen211.png", alt: "Verkehrszeichen Vorgeschriebene Fahrtrichtung Hier rechts" },
  "214": { foto: "zeichen214.png", alt: "Verkehrszeichen Vorgeschriebene Fahrtrichtung geradeaus oder rechts" },
  "215": { foto: "zeichen215.png", alt: "Verkehrszeichen Kreisverkehr" },
  "220": { foto: "zeichen220.png", alt: "Verkehrszeichen Einbahnstraße" },
  "222": { foto: "zeichen222.png", alt: "Verkehrszeichen Vorgeschriebene Vorbeifahrt rechts vorbei" },
  "224": { foto: "zeichen224.png", alt: "Verkehrszeichen Haltestelle" },
  "229": { foto: "zeichen229.png", alt: "Verkehrszeichen Taxenstand" },
  "237": { foto: "zeichen237.png", alt: "Verkehrszeichen Radweg" },
  "238": { foto: "zeichen238.png", alt: "Verkehrszeichen Reitweg" },
  "239": { foto: "zeichen239.png", alt: "Verkehrszeichen Gehweg" },
  "240": { foto: "zeichen240.png", alt: "Verkehrszeichen Gemeinsamer Geh- und Radweg" },
  "241": { foto: "zeichen241.png", alt: "Verkehrszeichen Getrennter Geh- und Radweg" },
  "242": { foto: "zeichen242.png", alt: "Verkehrszeichen Beginn einer Fußgängerzone" },
  "242.1": { foto: "zeichen242_1.png", alt: "Verkehrszeichen Beginn einer Fußgängerzone" },
  "242.2": { foto: "zeichen242_2.png", alt: "Verkehrszeichen Ende einer Fußgängerzone" },
  "243": { foto: "zeichen243.png", alt: "Verkehrszeichen Fuß- und Radweg" },
  "244.1": { foto: "zeichen244_1.png", alt: "Verkehrszeichen Beginn einer Fahrradstraße" },
  "244.2": { foto: "zeichen244_2.png", alt: "Verkehrszeichen Ende einer Fahrradstraße" },
  "245": { foto: "zeichen245.png", alt: "Verkehrszeichen Bussonderfahrstraßen" },
  "250": { foto: "zeichen250.png", alt: "Verkehrszeichen Verbot für Fahrzeuge aller Art" },
  "251": { foto: "zeichen251.png", alt: "Verkehrszeichen Verbot für Kraftwagen" },
  "253": { foto: "zeichen253.png", alt: "Verkehrszeichen Verbot für Kraftfahrzeuge über 3,5 T" },
  "255": { foto: "zeichen255.png", alt: "Verkehrszeichen Verbot für Krafträder" },
  "260": { foto: "zeichen260.png", alt: "Verkehrszeichen Verbot für mehrspurige Kraftfahrzeuge und Motorräder" },
  "262": { foto: "zeichen262.png", alt: "Verkehrszeichen Verbot Fahrzeuge über angegebene tatsächliche Masse" },
  "263": { foto: "zeichen263.png", alt: "Verkehrszeichen Verbot Fahrzeuge über angegebene tatsächliche Achslast" },
  "264": { foto: "zeichen264.png", alt: "Verkehrszeichen Verbot Fahrzeuge breiter als angegeben" },
  "265": { foto: "zeichen265.png", alt: "Verkehrszeichen Verbot Fahrzeuge höher als angegeben" },
  "266": { foto: "zeichen266.png", alt: "Verkehrszeichen Verbot Fahrzeuge länger als angegeben" },
  "267": { foto: "zeichen267.png", alt: "Verkehrszeichen Verbot der Einfahrt" },
  "270.1": { foto: "zeichen270_1.png", alt: "Verkehrszeichen Beginn Umweltzone" },
  "270.2": { foto: "zeichen270_2.png", alt: "Verkehrszeichen Ende Umweltzone" },
  "272": { foto: "zeichen272.png", alt: "Verkehrszeichen Verbot des Wendens" },
  "283": { foto: "zeichen283.png", alt: "Verkehrszeichen Absolutes Halteverbot" },
  "286": { foto: "zeichen286.png", alt: "Verkehrszeichen Eingeschränktes Halteverbot" },
  "290": { foto: "zeichen290.png", alt: "Verkehrszeichen Eingeschränktes Halteverbot für eine Zone" },
  "290.1": { foto: "zeichen290_1.png", alt: "Verkehrszeichen Beginn eines eingeschränkten Halteverbots für eine Zone" },
  "290.2": { foto: "zeichen290_2.png", alt: "Verkehrszeichen Ende eines eingeschränkten Halteverbots für eine Zone" },
  "291": { foto: "zeichen291.png", alt: "Verkehrszeichen Parkscheibe" },
  "292": { foto: "zeichen292.png", alt: "Verkehrszeichen Ende eines eingeschränkten Halteverbots für eine Zone" },
  "295": { foto: "zeichen295.png", alt: "Verkehrszeichen Fahrstreifenbegrenzung" },
  "296": { foto: "zeichen296.png", alt: "Verkehrszeichen Einseitige Fahrstreifenbegrenzung" },
  "297": { foto: "zeichen297.png", alt: "Verkehrszeichen Pfeilmarkierungen" },
  "298": { foto: "zeichen298.png", alt: "Verkehrszeichen Sperrfläche" },
  "299": { foto: "zeichen299.png", alt: "Verkehrszeichen Grenzmarkierung für Park- und Haltverbote" },
  "306": { foto: "zeichen306.png", alt: "Verkehrszeichen Vorfahrtstraße" },
  "314": { foto: "zeichen314.png", alt: "Verkehrszeichen Parken" },
  "314.1": { foto: "zeichen314_1.png", alt: "Verkehrszeichen Beginn einer Parkraumbewirtschaftungszone" },
  "314.2": { foto: "zeichen314_2.png", alt: "Verkehrszeichen Ende einer Parkraumbewirtschaftungszone" },
  "315": { foto: "zeichen315.png", alt: "Verkehrszeichen Gehwegpark" },
  "318": { foto: "zeichen318.png", alt: "Verkehrszeichen Parkscheibe" },
  "325": { foto: "zeichen325.png", alt: "Verkehrszeichen Verkehrsberuhigter Bereich" },
  "325.1": { foto: "zeichen325_1.png", alt: "Verkehrszeichen Beginn eines verkehrsberuhigten Bereiches" },
  "325.2": { foto: "zeichen325_2.png", alt: "Verkehrszeichen Ende eines verkehrsberuhigten Bereiches" },
  "326": { foto: "zeichen326.png", alt: "Verkehrszeichen Ende eines verkehrsberuhigten Bereiches" },
  "350": { foto: "zeichen350.png", alt: "Verkehrszeichen Fußgängerüberwege" },
};

export const tatbestand_haupt_katogieren_alt_bilder = [
  "leeres Bild",
  "Verkehrszeichen Eingeschränktes Halteverbot",
  "Verkehrszeichen Gehwegpark",
  "Verkehrszeichen Taxenstand",
  "Verkehrszeichen Absolutes Halteverbot",
  "Verkehrszeichen Pannenhilfe",
  "Verkehrszeichen Fahrzeug",
  "Verkehrszeichen Parken",
  "Verkehrszeichen Sperrfläche",
  "Post ",
  "parkautomat alt ",
  "Verkehrszeichen Gemeinsamer Geh- und Radweg",
  "Verkehrszeichen Eingeschränktes Halteverbot für eine Zone",
  "Motorrad",
  "Verkehrszeichen Gehverbot",
  "Hupe",
  "Auto",
  "Restaurant",
  "Euro",
  "Haus",
  "Hund",
  "Baum",
  "Mensch",
  "Verkehrszeichen Gefahrstelle",
  "Spielen",
  "Mülltonne",
  "Rauchen verboten",
  "Wohnwagen",
  "Verkehrszeichen Verbot für Fahrzeuge aller Art",
  "Ampel",
  "Datei",
  "Handy",
  "Bier",
  "Drucker",
  "Masse",
  "Verkehrszeichen Gehweg",
  "Schlüssel",
  "Achslast",
  "Anhängerkopplung",
  "Halt, Polizei",
  "Haltestelle",
  "Verkehrszeichen Taxenstand",
  "Verkehrszeichen Kreisverkehr",
  "Fahrrad",
  "Andreaskreuz",
  "Reparatur",
  "Traurigs Gesicht",
  "Geld abgeben oder empfangen",
  "Plakatieren verboten",
  "Halt- Vorfahrt gewähren",
  "Sonne",
  "Parken verboten",
  "Mülltonne",
  "Auto",
  "Schwimmen verboten",
  "Baum",
  "Abschlussmütze",
  "Erde",
  "Kreuz",
  "Baum",
  "Hund",
  "Kiosk",
  "Pflanze",
  "Stehen verboten neben laterne",
  "Krankenwagen",
  "Saft",
  "Gefahrstelle",
  "Ball",
  "Maler",
  "Baustelle",
  "Auto",
  "Wasserhahn",
  "Glas",
  "Vergnügungspark",
  "Wassertropf",
  "Kirche",
]

export const tatbestand_unter_katogieren_alt_bilder = [
  "leers Bild",
  "Verkehrszeichen Gehweg",
  "Tüv plakette",
  "Krankenwagen",
  "Haltelinien",
  "Ampel",
  "Parkautomat",
  "Parkautomat defekt",
  "Parkautomat alt",
  "Parkautomat alt defekt",
  "Rad",
  "Schlüssel",
  "Tüv plakette alt",
  "Tüv plakette",
  "Tüv plakette",
  "Andreaskreuz",
  "Verkehrszeichen Vorfaht gewähren",
  "Verkehrszeichen Halt- Vorfahrt gewähren",
  "Verkehrszeichen Haltestelle",
  "Verkehrszeichen Taxenstand",
  "Verkehrszeichen Radweg",
  "Verkehrszeichen Gehweg",
  "Verkehrszeichen Gemeinsamer Geh- und Radweg",
  "Verkehrszeichen Getrennter Geh- und Radweg",
  "Verkehrszeichen Beginn einer Fußgängerzone",
  "Verkehrszeichen Ende einer Fußgängerzone",
  "Verkehrszeichen Verbot für Fahrzeuge aller Art",
  "Verkehrszeichen Absolutes Halteverbot",
  "Verkehrszeichen Absolutes Halteverbot Anfahrt",
  "Verkehrszeichen Absolutes Halteverbot Zufahrt",
  "Verkehrszeichen Absolutes Halteverbot Rettungsweg",
  "Verkehrszeichen Eingeschränktes Halteverbot",
  "Verkehrszeichen Eingeschränktes Halteverbot Bewohner",
  "Verkehrszeichen Eingeschränktes Halteverbot für eine Zone",
  "Verkehrszeichen Eingeschränktes Halteverbot für eine Zone Bewohner",
  "Verkehrszeichen Eingeschränktes Halteverbot für eine Zone Stunden",
  "Verkehrszeichen Ende eines eingeschränkten Halteverbots für eine Zone",
  "Verkehrszeichen Fahrstreifenbegrenzung",
  "Verkehrszeichen Einseitige Fahrstreifen­begrenzung",
  "Verkehrszeichen Pfeilmarkierungen",
  "Verkehrszeichen Sperrfläche",
  "Verkehrszeichen Grenzmarkierung für Park- und Haltverbote",
  "Verkehrszeichen Vorfahrtstraße",
  "Verkehrszeichen Parken",
  "Verkehrszeichen Parken Bewohner",
  "Verkehrszeichen Parke Stunden",
  "Verkehrszeichen Behindertenparkplatz",
  "Verkehrszeichen Parken",
  "Verkehrszeichen Gehwegpark",
  "Verkehrszeichen Gehwegpark Bewhoner",
  "Verkehrszeichen Gehwegpark Stunden",
  "Verkehrszeichen Behindertengehwegpark",
  "Verkehrszeichen Gehwegpark",
  "Verkehrszeichen Beginn eines verkehrsberuhigten Bereiches",
  "Verkehrszeichen Ende eines verkehrsberuhigten Bereiches",
  "Verkehrszeichen Verbot für Kraftwagen",
  "Verkehrszeichen Verbot für LKW",
  "Verkehrszeichen Verbot für Motorrad",
  "Verkehrszeichen Verbot für Krafträder",
  "Verkehrszeichen Verbot für Kraftfahrzeuge über 5,5 T",
  "Verkehrszeichen Verbot Fahrzeuge über angegebene tatsächliche Achslast",
  "Verkehrszeichen Verbot Fahrzeuge breiter als angegeben",
  "Verkehrszeichen Verbot Fahrzeuge höher als angegeben",
  "Verkehrszeichen Verbot Fahrzeuge länger als angegeben",
  "Verkehrszeichen Verbot der Einfahrt",
  "Verkehrszeichen Reitweg",
  "Verkehrszeichen Parkplatz Zone",
  "Verkehrszeichen Kreis",
  "Verkehrszeichen Fahrradstraße",
  "Verkehrszeichen Fahrradstraße Zone",
  "Verkehrszeichen Bussonderfahrstraßen",
  "Verkehrszeichen Beginn Umweltzone",
  "Verkehrszeichen Verbot Fahrrad",
  "Verkehrszeichen Parken für E-Autos",
  "Verkehrszeichen Parken",
  "Verkehrszeichen Verbot für Motorrad",
  "Verkehrszeichen Verbot für Bus",
  "Verkehrszeichen Verbot für Kraftwagen",
  "Verkehrszeichen Verbot für Kraftwagen mit Anhänger",
  "Verkehrszeichen Verbot für LKW",
  "Verkehrszeichen Verbot für Landwirtschaftliche Fahrzeuge",
]