import React, { useCallback, useEffect, useState } from 'react';
import Drawer from 'devextreme-react/drawer';
import { Template } from 'devextreme-react/core/template';
import { LoadPanel, Toolbar } from 'devextreme-react';
import { useScreenSize } from '../../../global/media-query';
import KonfiguratorGrid from './data_grid';
import { setToast, useAppDispatch } from '../../../state';
import KonfiguratorNav from './konfigurator_nav';
import './index.scss';
import { icon_menu, icon_refresh } from '../../../global/const';
import { GetData } from '../../../global/communication';
import { Item } from 'devextreme-react/toolbar';
import AbgleichKonfigurator from './Abgleich';
import { MDButton } from '../../../global/components';
import { handleContentReadyToolbar } from "../../../global/hilfs_functions";


export const MenuStatus = {
  Closed: 1,
  Opened: 2,
  TemporaryOpened: 3
};
export interface TreeViewNodeKonfigurator {
  id: string,
  text: string,
  parentId?: string,
  parentText?: string,
  icon?: string,
  hasItems?: boolean,
  items?: TreeViewNodeKonfigurator[]
}

export interface Computer {
  name: string,
  bereiche: string[],
}
const KonfiguratorMain: React.FC = (): JSX.Element => {
  const [selectedItem, setSelectedItem] = useState<TreeViewNodeKonfigurator | undefined>(undefined);
  const { isSmall, isXLarge, isXXLarge, isXXXLarge } = useScreenSize();
  const isBig = isXLarge || isXXLarge || isXXXLarge;
  const [menuStatus, setMenuStatus] = useState(
    isBig ? MenuStatus.Opened : MenuStatus.Closed
  );
  // const allComputer = useAppSelector((state) => state.store_einstellungen.konfiguration2.all_Computer);
  const [allComputer, setAllComputer] = useState<Computer[]>([]);
  const [searchValue, setSearchValue] = useState("");
  const [isloading, setisloading] = useState(true);
  const dispatch = useAppDispatch();

  const get_data = useCallback(() => {
    setSelectedItem(undefined);
    setisloading(true);
    GetData({
      Operation: "GetAllComputer", searchValue: searchValue || ""
    }, dispatch)
      .then((res) => {
        if (!res) return
        setAllComputer(res.AllComputer);
        setisloading(false);
      });
  }, [dispatch, searchValue]);

  const Speicherung_Verwerfen = useCallback(() => {
    GetData({
      Operation: "VerwerfenZwischenspeicherung"
    }, dispatch)
      .then((res) => {
        if (!res) return
        dispatch(setToast({
          message: "Die Zwischenspeicherung wurde verworfen.",
          type: "success",
          position: "bottom center"
        }));
      });
  }, [dispatch]);

  useEffect(() => {
    get_data();
    // eslint-disable-next-line
  }, []);


  const toggleMenu = useCallback(() => {
    setMenuStatus(
      prevMenuStatus => prevMenuStatus === MenuStatus.Closed
        ? MenuStatus.Opened
        : MenuStatus.Closed
    );
    // event.stopPropagation();
  }, []);

  const onOutsideClick = useCallback(() => {
    setMenuStatus(
      prevMenuStatus => prevMenuStatus !== MenuStatus.Closed && !isBig
        ? MenuStatus.Closed
        : prevMenuStatus
    );
    return true; // Dies muss zurückgegeben werden.
  }, [isBig]);

  const onNavigationChanged = useCallback((event: any) => {
    if (!isBig) {
      event.stopPropagation();
      setMenuStatus(MenuStatus.Closed);
    }

  }, [isBig]);

  const temporaryOpenMenu = useCallback(() => {
    setMenuStatus(
      prevMenuStatus => prevMenuStatus === MenuStatus.Closed
        ? MenuStatus.TemporaryOpened
        : prevMenuStatus
    );
  }, []);

  const callBackAbgleich = useCallback(() => {
    get_data();
  }, [get_data]);



  return (
    <>
      {(isloading) && <LoadPanel visible={true} />}
      <div className='konfigurator_app_content mt-2 mt-md-3 d-flex'>
        <Drawer
          className={isBig ? "drawer_konfigurator_big" : "drawer_konfigurator"}
          position={'before'}
          closeOnOutsideClick={onOutsideClick}
          openedStateMode={isBig ? 'shrink' : 'overlap'}
          revealMode={isSmall ? 'slide' : 'expand'}
          minSize={isSmall ? 0 : 60}
          maxSize={400}
          shading={isBig ? false : true}
          opened={menuStatus === MenuStatus.Closed ? false : true}
          template={'konfiguratorNav'}
        >
          <Toolbar className='pr-2' onContentReady={handleContentReadyToolbar}>
            <Item location="after" locateInMenu="auto">
              <AbgleichKonfigurator callBack={callBackAbgleich} />
            </Item>
            <Item location="after" locateInMenu="auto">
              <MDButton
                page='konfig_'
                icon={icon_refresh}
                onClick={Speicherung_Verwerfen}
                tooltip_text="Zwischenspeicherung verwerfen"
              />
            </Item>
            {!isBig &&
              <Item location="after" locateInMenu="auto">
                <MDButton
                  page="konfigurator"
                  icon={icon_menu}
                  tooltip_text="Navigationsmenü"
                  onClick={toggleMenu}
                  ariaExpanded={menuStatus === MenuStatus.Opened ? "true" : "false"}
                />
              </Item>}
          </Toolbar>
          <div className='h-100'>
            {selectedItem && selectedItem?.id.split("_").length > 1 &&
              <KonfiguratorGrid node={selectedItem} setSelectedItem={setSelectedItem}
                allComputer={allComputer}
                get_data_all_Computer={get_data}
                SearchValue={searchValue}
              />}
          </div>
          <Template name={'konfiguratorNav'}>
            <KonfiguratorNav
              collapseAll={menuStatus === MenuStatus.Closed}
              selectedItemChanged={onNavigationChanged}
              openMenu={temporaryOpenMenu}
              setSelectedItem={setSelectedItem}
              allComputer={allComputer}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              setAllComputer={setAllComputer}
              get_data={get_data}
            >
            </KonfiguratorNav>
          </Template>
        </Drawer></div>
    </>
  );
}



export default KonfiguratorMain;

