import { Tooltip as TooltipInstanz } from 'devextreme-react';
import { memo, useRef, useState } from 'react';
import CardBackPopup from "./card_back_popup";
import { IFallData, IFallDataZusaetzlich } from '../../../interfaces';
import Exportiert from '../../card_control/symbole/exportiert';
import './index.scss';
import { useCallback } from 'react';
import { useId } from "react-id-generator";
import { icon_close } from '../../../../../global/const';
import { MDButton } from '../../../../../global/components';
import Tooltip from "devextreme/ui/tooltip";

export interface ICardFooterProps {
    item: IFallData,
    itemZus: IFallDataZusaetzlich,
}


const CardFooter: React.FC<ICardFooterProps> = ({ item, itemZus }): JSX.Element => {
    const geloeschter_fall = item.f006 >= 2;
    const tatmehrheit = item.f010.Tatmehrheit === "Ja";
    const [tooltipVisibleTatbestand, setTooltipVisibleTatbestand] = useState(false);
    const refTatbestand = useRef(null);
    const [showPopupCard, setShowPopupCard] = useState(false);


    let str = itemZus.tatort;
    let absAbstellort = item.f010.AbsOrt;
    if (absAbstellort) str += ' (Abs: ' + absAbstellort + ')';
    //  if (str.length > 40) // Sieht hier trotzdem gut aus.
    //      str = itemZus.tatort.substr(0, 47);// + '<span class="tatort-zusatz" style="color:blue; font-weight:bold;">...</span>';

    const handleHidePopup = useCallback(() => {
        setShowPopupCard(false)
    }, []);
    const handleShowPopup = useCallback(() => {
        setShowPopupCard(true)
    }, []);
    const handleShowTatbestandTooltip = useCallback(() => {
        hide_all_tooltip();
        setTooltipVisibleTatbestand(true)
    }, []);
    const handleHideatbestandTooltip = useCallback(() => {
        setTooltipVisibleTatbestand(false)
    }, []);

    const handleKeyUpFall = useCallback((e) => {
        if (e.key === "Enter") {
            setShowPopupCard(true)
        }
    }, [])
    return (
        <div className={geloeschter_fall ? "container_card_footer mt-1 mt-lg-2 geloeschter_fall alert-danger px-2 px-md-3 pb-2 pb-md-3 pt-1 pt-md-2" : "container_card_footer mt-1 mt-lg-2  px-2 px-md-3 pb-2 pb-md-3 pt-1 pt-md-2"}>
            <div tabIndex={0}/* aria-label={"Daten des Falles mit dem Aktenzeichen " + item.f202 + " anzeigen"}*/ role="button" onClick={handleShowPopup} className='cursor_pointer' onKeyUp={handleKeyUpFall}>
                <p className='sr-only'>Daten des Falles mit dem Aktenzeichen {item.f202} anzeigen</p>
                <div className='mt-2 d-flex flex-wrap align-items-center'>
                    <KFZKennzeichen item={item} itemZus={itemZus} />
                    <span className='ml-1'>{itemZus.record_typ && "(" + itemZus.record_typ + ")"}</span>
                    <span className='ml-1'>{itemZus.zeit_von.substr(7, 5) + ' ' + itemZus.zeit_von_bis}</span>
                    <div className='ml-auto'>
                        <Exportiert item={item} itemZus={itemZus} />
                    </div>
                </div>
                <div className="mt-2">
                    <span>{str}</span>
                </div>
                <div className="mt-2">
                    <span
                        className="card_footer_tbnr mr-1 mr-md-3"
                        ref={refTatbestand}
                        onMouseEnter={handleShowTatbestandTooltip}
                    //  onMouseLeave={handleHideatbestandTooltip}
                    >
                        {itemZus.fall_art === 5 ? 'NR: ' + itemZus.angezeigte_tatbestand_nummer : 'TBNR: ' + itemZus.angezeigte_tatbestand_nummer}
                    </span>
                    <span className='ml-1'>{itemZus.gesamt_betrag_aus_f010 > 0 && itemZus.gesamt_betrag_aus_f010 + ' €'}</span>
                    <span className='ml-1'>{tatmehrheit && '(Tatmehrheit)'}</span>
                    {(itemZus.tatbestaende && itemZus.tatbestaende.length > 0) &&
                        <span className='ml-1'>
                            {itemZus.tatbestaende[0].punkte > 0 ? itemZus.tatbestaende[0].punkte === 1 ? itemZus.tatbestaende[0].punkte + ' Punkt' : itemZus.tatbestaende[0].punkte + ' Punkte' : <></>}
                        </span>}
                </div>
            </div>
            <TooltipInstanz
                target={refTatbestand.current!}
                visible={(tooltipVisibleTatbestand && itemZus.tatbestaende && itemZus.tatbestaende.length > 0)}
                hideOnOutsideClick={true}
                onHidden={handleHideatbestandTooltip}
                maxWidth={'450px'}

            >
                {/**
                * 
                * <ToolbarItem location={"after"} widget="dxButton" options={{ icon: icon_close, onClick: handleHideatbestandTooltip }} />
                */}
                {(itemZus.tatbestaende && itemZus.tatbestaende.length > 0) &&
                    <div className='d-flex'>
                        <div>
                            <p>{itemZus.tatbestaende[0].tatbestandstext}</p>
                            <p className="card_paragraph">{itemZus.tatbestaende[0].paragraphenzeile}</p></div>
                        <MDButton icon={icon_close} onClick={handleHideatbestandTooltip} page="fall_" className='ml-3' ariaLabel={"Schließen"} /></div>

                }
            </TooltipInstanz>
            {showPopupCard && <CardBackPopup handleClickHidePopup={handleHidePopup} item={item} itemZus={itemZus} />}
        </div>
    );
}

export default memo(CardFooter);

interface IKFZKennzeichenProps {
    item: IFallData,
    itemZus: IFallDataZusaetzlich,
}
const KFZKennzeichen: React.FC<IKFZKennzeichenProps> = memo(({ item, itemZus }) => {
    const geloescht = item.f006 >= 2;
    const ids = useId(1, "Kennzeichen_");
    const [tooltip, setTooltip] = useState(false);

    const handleHideTooltip = useCallback(() => {
        setTooltip(false);
    }, []);
    const handleShowTooltip = useCallback(() => {
        hide_all_tooltip();
        setTooltip(true);
    }, []);
    return (
        geloescht ?
            <>
                <h2
                    className="card_kennzeichen"
                    id={ids[0]}
                    onMouseEnter={handleShowTooltip}
                    onMouseLeave={handleHideTooltip}
                >{itemZus.kfz_kennziehcen}
                </h2>
                <TooltipInstanz
                    target={"#" + ids[0]}
                    visible={tooltip}
                    hideOnOutsideClick={false}
                    maxWidth={'450px'}
                >
                    <div className="text-left">
                        <p>{itemZus.loesch_text}</p> <br />
                        {/**MD 19.07.2023 wenn bei Opcode 2 wird kein Löschsatz generiert. Da wird der Fall bereites beim Versenden aus Mobil gelöscht. */}
                        {(item.f004 === 2) ? (item.f010.Löschgrund && <p><strong>Grund:</strong><br />{item.f010.Löschgrund}</p>) : (item.f010.Grund && <p><strong>Grund:</strong><br />{item.f010.Grund}</p>)}
                    </div>
                </TooltipInstanz>
            </> :
            <>
                <h2
                    className="card_kennzeichen"
                >{itemZus.kfz_kennziehcen}
                </h2></>
    );
});



const hide_all_tooltip = () => {
    const elements = document.querySelectorAll(".dx-tooltip:not(.dx-state-invisible)");
    elements.forEach((item) => {
        let instance = Tooltip.getInstance(item) as Tooltip;
        instance.hide();
    });
}