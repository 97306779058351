
import TabPanel, { Item } from "devextreme-react/tab-panel";
import Informationen from "./tabs/informationen";
import Historie from "./tabs/historie";
import Akku from "./tabs/akku";
import SqlSkript from "./tabs/sql_skript";
import Protokoll from "./tabs/protokoll";
import Verwaltung from "./tabs/verwaltung";
import { IGeraeteDaten } from "./interfaces";
import { icon_battery, icon_code, icon_file, icon_history, icon_info, icon_preferences } from "../../../global/const";
import { memo } from "react";
import { handleContentReadyTabPanel } from "../../../global/hilfs_functions";

const MasterDetailsTest = (data: any) => {
    var item: IGeraeteDaten = data.data.data;


    return (
        <TabPanel focusStateEnabled={true} animationEnabled={true} id={"geraete_master_details_" + item.f001} onContentReady={handleContentReadyTabPanel} deferRendering={false}>
            <Item title="Information" icon={icon_info}>
                <Informationen item={item} />
            </Item>
            {(item.f020 && item.f020.length > 0) &&
                <Item title="Historie" icon={icon_history}>
                    <Historie f020={item.f020} />
                </Item>}
            <Item title="Akku" icon={icon_battery} >
                <Akku geraet_nummer={item.f001} />
            </Item>
            <Item title="SQL_Skript" icon={icon_code}>
                <SqlSkript geraet_nummer={item.f001} />
            </Item>
            <Item title="Protokoll" icon={icon_file}>
                <Protokoll geraet_nummer={item.f001} />
            </Item>
            <Item title="Verwalten" icon={icon_preferences}>
                <Verwaltung item={item} />
            </Item>
        </TabPanel>
    )
}

export default memo(MasterDetailsTest);