import { Popup, ScrollView } from "devextreme-react";
import { ToolbarItem } from "devextreme-react/autocomplete";
import { useCallback, useMemo } from "react";
import { IAlert } from "./notificationsSlice";


const Alert: React.FC<IAlert> = ({ onHiding, title, type, text, maxWidth }) => {
    const alert_class = type === "Fehler" ? "alert alert-danger" : type === "Warnung" ? "alert alert-warning" : type === "Info" ? "alert alert-info" : "";

    const memo_options_ok = useMemo(() => {
        return {
            text: 'Ok',
            onClick: onHiding,
        }
    }, [onHiding]);
    const RenderePopupTitle = useCallback(() => {
        return (<h2 className="title_popup">{title}</h2>)
    }, [title]);

    return (
        <Popup
            visible={true}
            onHiding={onHiding}
            dragEnabled={true}
            hideOnOutsideClick={true}
            fullScreen={false}
            showCloseButton={true}
            height={type === "Modal" ? undefined : "auto"}
            width="auto"
            maxHeight="70vh"
            maxWidth={maxWidth ? maxWidth + "px" : "80vw"} //MD 10.08.2023 hat gefehlt
        >
            <ToolbarItem
                toolbar="top"
                location={'before'}
                render={RenderePopupTitle}
            />

            <ToolbarItem
                widget="dxButton"
                toolbar="bottom"
                location="center"
                options={memo_options_ok}
            />

            <ScrollView className={alert_class}>
                {typeof text === "string" ? <p className="pre_wrap">{text}</p> : text}
            </ScrollView>
        </Popup>
    )
}

export default Alert;