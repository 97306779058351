import { memo, useCallback, useState } from "react";
import { icon_plus } from "../../../global/const";
import { useAppSelector } from "../../../state";
import { MDButton } from "../../../global/components";
import SachbearbeiterVerwaltePopup from "./verwalten_popup";

interface IAddSBProps {
    get_data: () => void
}
const SachbearbeiterAdd: React.FC<IAddSBProps> = ({ get_data }): JSX.Element => {
    const [showPopUp, setShowPopUp] = useState(false);
    //MD 09.01.2023 SB dürfen nicht bearbeitet werden bei amm
    const auftragserfassung = useAppSelector((state) => state.store_einstellungen.konfiguration.auftragserfassung);

    const handleShowPoprp = useCallback(() => {
        setShowPopUp(true)
    }, []);

    const handleHidePoprp = useCallback((update?: boolean) => {
        setShowPopUp(false);
        update && get_data()
    }, [get_data]);

    return (
        <div>
            {!auftragserfassung &&
                <MDButton
                    icon={icon_plus}
                    type="success"
                    tooltip_text="Neuen Sachbearbeiter anlegen"
                    onClick={handleShowPoprp}
                    page="sachbearbeiter"
                />
            }
            {showPopUp && <SachbearbeiterVerwaltePopup callback={handleHidePoprp} />}
        </div>
    )
}

export default memo(SachbearbeiterAdd);