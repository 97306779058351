import { Popup, ScrollView, TextBox } from "devextreme-react"
import { ToolbarItem } from "devextreme-react/autocomplete"

import { useCallback, useMemo, useState } from "react";
import { IOrt } from "../interfaces";
import { setToast, useAppDispatch } from "../../../../state";
import { GetData } from "../../../../global/communication";
import { handleContentReadyTextBox } from "../../../../global/hilfs_functions";




interface IOrteVerwaltePopupProps {
    callback: (update?: boolean, loeschen?: boolean) => void,
    row?: IOrt | null,
}

const OrteVerwaltePopup: React.FC<IOrteVerwaltePopupProps> = ({ callback, row }): JSX.Element => {

    const isAdd = row === undefined;
    const [bezeichnung, setBezeichnung] = useState((!isAdd && row?.f001) ? row?.f001 : "");
    const [aowiTatBestaende, setAowiTatBestaende] = useState((!isAdd && row?.f002) ? row?.f002 : "");
    const dispatch = useAppDispatch();


    const handleClickSpeichern = useCallback(() => {
        if (!bezeichnung) {
            dispatch(setToast({
                message: "Bezeichnung darf nicht leer sein.",
                type: "error"
            }));
            return
        }
        let obj = isAdd ?
            {
                Operation: "InsertOrt",
                Bezeichnung: bezeichnung.trim(),
                AOWITatbestaende: aowiTatBestaende.trim(),
            }
            :
            {
                Operation: "ChangeOrt",
                LfdNr: row?.lfdnr,
                Bezeichnung: bezeichnung.trim(),
                AOWITatbestaende: aowiTatBestaende.trim(),
            };
        GetData(obj, dispatch).then((res) => {
            if (!res) return
            callback(true)
        });
    }, [bezeichnung, dispatch, callback, row, aowiTatBestaende, isAdd]);

    const handleHidePopup = useCallback(() => {
        callback();
    }, [callback]);

    const handleHidePopupLoeschen = useCallback(() => {
        callback(undefined, true);
    }, [callback]);
    const memo_options_loeschen = useMemo(() => {
        return {
            text: 'Löschen',
            type: "danger",
            onClick: handleHidePopupLoeschen,
        }
    }, [handleHidePopupLoeschen]);

    const memo_options_speichern = useMemo(() => {
        return {
            text: 'Speichern',
            onClick: handleClickSpeichern,
        }
    }, [handleClickSpeichern]);

    const memo_options_abbrechen = useMemo(() => {
        return {
            text: 'Abbrechen',
            onClick: handleHidePopup,
        }
    }, [handleHidePopup]);

    const RenderePopupTitle = useCallback(() => {
        return (<h2 className="title_popup">{isAdd ? "Neuen Ort anlegen" : "Ort bearbeiten"}</h2>)
    }, [isAdd]);

    return (
        <>
            <Popup
                visible={true}
                onHiding={handleHidePopup}
                dragEnabled={true}
                hideOnOutsideClick={true}
                fullScreen={false}
                showCloseButton={true}
                height='auto'
                maxHeight="350px"
                maxWidth="500px"
            >

                <ToolbarItem
                    toolbar="top"
                    location={'before'}
                    render={RenderePopupTitle}
                />
                {!isAdd &&
                    <ToolbarItem
                        widget="dxButton"
                        toolbar="bottom"
                        location="before"
                        options={memo_options_loeschen}
                    />}
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={memo_options_speichern}
                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={memo_options_abbrechen}
                />
                <ScrollView>
                    <div className="row p-2 p-md-3">
                        <div className="col-12  p-1 p-md-2">
                            <TextBox
                                label="Bezeichnung"
                                labelMode="floating"
                                stylingMode="outlined"
                                onValueChange={setBezeichnung}
                                value={bezeichnung}
                                valueChangeEvent="input"
                                maxLength={50}
                                onContentReady={handleContentReadyTextBox}
                            />
                        </div>
                        <div className="col-12  p-1 p-md-2">
                            <TextBox
                                label="AOWI Tatbestände"
                                labelMode="floating"
                                stylingMode="outlined"
                                onValueChange={setAowiTatBestaende}
                                value={aowiTatBestaende}
                                valueChangeEvent="input"
                                maxLength={512}
                                onContentReady={handleContentReadyTextBox}
                            />
                        </div>
                    </div>
                </ScrollView>

            </Popup>
        </>
    )
}

export default OrteVerwaltePopup;


