
import { CheckBox, DataGrid, DateBox, SelectBox } from "devextreme-react";
import { Column, Toolbar, Item as ItemGridToolbar } from "devextreme-react/data-grid";
import ArrayStore from "devextreme/data/array_store";
import { useCallback } from "react";
import { useEffect, useMemo, useRef, useState } from "react";
import { GetData } from "../../../global/communication";
import { Statistik_GeraeteLogins_Spalten } from "../../../sonstiges/columns";
import { icon_excel, icon_pdf, scroll_mode_infinite } from "../../../global/const";
import { exportGridAsExcel, exportGridAsPDF, get_date_ajax_param, handleContentReadyCheckBox, handleContentReadyDataGrid, handleContentReadyDateBox, handleContentReadySelectBox } from "../../../global/hilfs_functions";
import { IColumn, IZeuge } from "../../../global/interfaces";
import { useAppDispatch } from "../../../state";
import { RendereGridTitleGeraeteLogins } from "../../../sonstiges/hilfs_functions";
import { MDButton } from "../../../global/components";
import { IStatistikGeraeteLogins } from "../interfaces";
import { format_geraete_logins_zeitstemple } from "../../../sonstiges/hilfs_functions";


const columns: IColumn[] = Statistik_GeraeteLogins_Spalten();

const Statistik_GeraeteLogins: React.FC = (): JSX.Element => {

  const [sachbearbeiter, setSachbearbeiter] = useState(0);
  const [data, setData] = useState<IStatistikGeraeteLogins[]>([]);
  const dataGridRef = useRef() as React.MutableRefObject<DataGrid<any, any>>;
  const [datumVon, setDatumVon] = useState(new Date());
  const [datumBis, setDatumBis] = useState(new Date());
  const [loginsOhneFaelleAnzeigen, setLoginsOhneFaelleAnzeigen] = useState<boolean | null>(true);
  const [zeugen, setZeugen] = useState<IZeuge[]>([]);
  //const zeuge = useAppSelector((state) => state.store_einstellungen.konfiguration2.faelle_zeugen);
  const data_source_zeuge = useMemo(() => new ArrayStore({
    data: zeugen,
    key: 'sachbearbeiternummer',
  }), [zeugen]);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (zeugen.length === 0) {
      GetData({ Operation: "GetZeugen" }, dispatch)
        .then((res) => {
          if (!res) return
          setZeugen(res.faelle_zeugen);
        });
    }
  }, [zeugen, dispatch]);

  useEffect(() => {
    GetData({
      Operation: "GetGeraetelogins",
      Filter: statistik_build_filter_geraete_logins(sachbearbeiter.toString(), get_date_ajax_param(datumVon), get_date_ajax_param(datumBis), loginsOhneFaelleAnzeigen ? 'Ja' : 'Nein')
    }, dispatch)
      .then((res) => {
        if (!res) {
          setData([]);
          return
        }
        setData(res.data);
      });
  }, [sachbearbeiter, datumVon, datumBis, loginsOhneFaelleAnzeigen, dispatch]);

  const handleChangeDateVon = useCallback((e: any) => {
    setDatumVon(e.value)
  }, []);
  const handleChangeDateBis = useCallback((e: any) => {
    setDatumBis(e.value)
  }, []);

  const handlePDF = useCallback(() => {
    let header = get_header_pdf_geraete_logins(sachbearbeiter, datumVon, datumBis, loginsOhneFaelleAnzeigen!, zeugen);
    exportGridAsPDF(dataGridRef, 'GeräteLogins.pdf', header, "landscape", "striped", (cellData: any) => {
      if (cellData.gridCell.column.dataField === 'zeitstempel' && cellData.gridCell.rowType === 'data') {
        cellData.pdfCell.text = format_geraete_logins_zeitstemple(cellData.pdfCell.text)
      }
    })
  }, [sachbearbeiter, datumBis, datumVon, zeugen, loginsOhneFaelleAnzeigen]);

  const handleExcel = useCallback(() => {
    exportGridAsExcel(dataGridRef, 'GeräteLogins.xlsx')
  }, []);
  return (
    <div className="data_grid_container header_grid_thick mt-2 mt-md-3">
      <DataGrid
        dataSource={data}
        allowColumnResizing={true}
        columnAutoWidth={false}
        rowAlternationEnabled={true}
        focusedRowEnabled={false}
        remoteOperations={true}
        ref={dataGridRef}
        renderAsync={true}
        scrolling={scroll_mode_infinite}
        wordWrapEnabled={true}
        keyExpr={'id'}
        className=" animate__animated animate__fadeIn"
        noDataText={"Keine Gerätelogins"}
        height={"auto"}
        onContentReady={handleContentReadyDataGrid}
      >
        {
          columns.map((column) => {
            return (
              <Column
                dataField={column.dataField}
                caption={column.caption}
                dataType={column.dataType}
                cellRender={column.cellRender}
                key={column.dataField}
                minWidth={column.minWidth}
                alignment={column.alignment}
                visible={column.visible}
              />
            )
          })
        }
        <Toolbar>
          <ItemGridToolbar
            location={'before'}
            render={RendereGridTitleGeraeteLogins}
          ></ItemGridToolbar>

          <ItemGridToolbar
            location="after"
            locateInMenu="auto"
          >
            <SelectBox
              dataSource={data_source_zeuge}
              displayExpr="sachbearbeitername"
              valueExpr="sachbearbeiternummer"
              value={sachbearbeiter}
              onValueChange={setSachbearbeiter}
              label='Sachbearbeiter'
              labelMode='floating'
              stylingMode='outlined'
              onContentReady={handleContentReadySelectBox}
            />
          </ItemGridToolbar>
          <ItemGridToolbar
            location="after"
            locateInMenu="auto"
          >
            <DateBox
              value={datumVon}
              onValueChanged={handleChangeDateVon}
              displayFormat={"dd.MM.yyyy"}
              showClearButton={true}
              useMaskBehavior={true}
              label='Startdatum:'
              labelMode='floating'
              stylingMode='outlined'
              onContentReady={handleContentReadyDateBox}
            /**
             dropDownOptions={{
              maxHeight: "50vh",
              height: "322",
              onShowing(e) {
                const content = e.element.querySelector('.dx-popup-content');
                content && new ScrollView(content, {})
              }
            }}
          */

            />
          </ItemGridToolbar>
          <ItemGridToolbar
            location="after"
            locateInMenu="auto"
          >
            <DateBox
              value={datumBis}
              onValueChanged={handleChangeDateBis}
              displayFormat={"dd.MM.yyyy"}
              showClearButton={true}
              useMaskBehavior={true}
              label='Enddatum:'
              labelMode='floating'
              stylingMode='outlined'
              onContentReady={handleContentReadyDateBox}
            />
          </ItemGridToolbar>
          <ItemGridToolbar
            location="after"
            locateInMenu="auto"
          >
            <CheckBox
              value={loginsOhneFaelleAnzeigen}
              onValueChange={setLoginsOhneFaelleAnzeigen}
              onContentReady={handleContentReadyCheckBox}
              text="Logins ohne Fälle anzeigen" />

          </ItemGridToolbar>
          <ItemGridToolbar location="after" locateInMenu="auto">
            <MDButton
              page='geraete_logins'
              icon={icon_pdf}
              onClick={handlePDF}
              tooltip_text="Tabelle als PDF herunterladen"
            />
          </ItemGridToolbar>
          <ItemGridToolbar location="after" locateInMenu="auto">
            <MDButton
              page='geraete_logins'
              icon={icon_excel}
              //   icon='xlsxfile'
              onClick={handleExcel}
              tooltip_text="Tabelle als Excel herunterladen"
            />
          </ItemGridToolbar>
        </Toolbar>
      </DataGrid></div>
  )
}

export default Statistik_GeraeteLogins;

export const statistik_build_filter_geraete_logins = (sachbearbeiter: string, datumVon: string, datumBis: string, loginsOhneFaelleAnzeigen: string): string => {
  let daten: string = '';
  daten += "Sachbearbeiternummer%01" + sachbearbeiter + "%00";
  daten += "DatumVon%01" + datumVon + "%00";
  daten += "DatumBis%01" + datumBis + "%00";
  daten += "NurLoginsOhneFaelleAnzeigen%01" + loginsOhneFaelleAnzeigen + "%00";

  return daten;
}

const get_header_pdf_geraete_logins = (sachbearbeiter: number, datumVonDate: Date, datumBisDate: Date, loginsOhneFaelleAnzeigen: boolean, zeugen: IZeuge[]) => {
  function get_sb_name() {
    let sb_name = '';
    for (let i of zeugen) {
      if (i.sachbearbeiternummer === sachbearbeiter) {
        sb_name = i.sachbearbeitername;
        break;
      }
    }
    return sb_name;
  }

  let result = '';
  let datumVon = get_date_ajax_param(datumVonDate);
  let datumBis = get_date_ajax_param(datumBisDate);
  let sachbearbeitername = get_sb_name();
  let login_value = loginsOhneFaelleAnzeigen ? "Ja" : "Nein";

  result = 'Gerätelogins von ' + datumVon + ' bis ' + datumBis;
  result += '\nSachbearbeitername: ' + sachbearbeitername;
  result += '\nLogin ohne Fälle anzeigen: ' + login_value;
  return result;
}