import React, { useEffect, memo } from 'react';
import TreeView from 'devextreme-react/tree-view';
import './navigation.scss';
import { useLocation } from 'react-router-dom'
import useNavigationLogik from './navigationLogik';
import { INavigation } from '../../global/interfaces';
import { fix_document_title } from '../../sonstiges/hilfs_functions';
import { handleContentReadyTreeView } from '../../global/hilfs_functions';


export interface INavigationLogikReturn {
  getWrapperRef: (element: any) => void,
  treeViewRef: React.RefObject<any>,
  items: INavigation[],
  ClickItem: ({ itemData, event, node }: any) => void
}

interface INavigationProps {
  children: React.ReactNode,
  selectedItemChanged: (path: string, event: any) => void,
  openMenu: () => void,
  collapseAll: Boolean,
  expanded: boolean;
}

const aria_expanded_true = { "aria-expanded": "true" };
const aria_expanded_false = { "aria-expanded": "false" };

const Navigation: React.FC<INavigationProps> = ({ expanded, children, collapseAll, openMenu, selectedItemChanged }): JSX.Element => {

  const data: INavigationLogikReturn = useNavigationLogik({ openMenu, selectedItemChanged, collapseAll });
  const location = useLocation();

  useEffect(() => {
    // const treeView = treeViewRef.current && treeViewRef.current.instance;
    const node = data.treeViewRef.current as any;
    const treeView = node!.instance;

    if (!treeView) {
      return;
    }
    if (treeView.getSelectedNodeKeys()[0] !== location.pathname) treeView.selectItem(location.pathname);

    if (collapseAll) {
      treeView.collapseAll();
    }

    fix_document_title(location.pathname);
  }, [collapseAll, location.pathname, data.treeViewRef]);


  return (
    <aside
      className={'side-navigation'}
      ref={data.getWrapperRef}
    >
      {children}
      <div className={'nav-container'}>
        <TreeView
          id="haupt_treeview"
          ref={data.treeViewRef}
          items={data.items}
          onContentReady={handleContentReadyTreeView}
          keyExpr={'path'}
          selectionMode={'single'}
          focusStateEnabled={true}
          expandEvent={'click'}
          onItemClick={data.ClickItem}
          animationEnabled={true}
          width={'100%'}
          elementAttr={expanded ? aria_expanded_true : aria_expanded_false}
        />
      </div>
    </aside>
  );
}

export default memo(Navigation);

