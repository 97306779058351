import { ToolbarItem } from "devextreme-react/autocomplete";
import { Popup, ScrollView } from 'devextreme-react';
import CardBack from "../../card_popup";
import { useCallback, useMemo, useState } from "react";
import { IFallData, IFallDataZusaetzlich, IFoto } from "../../../interfaces";
import { useAppSelector } from "../../../../../state";
import { useScreenSize } from "../../../../../global/media-query";
import PopupGallery from "../card_header/fotos_gallery";
import { MDButton } from "../../../../../global/components";
import { get_fotos_from_fall } from "../../../../../sonstiges/hilfs_functions";

interface ICardBackPopupProps {
    item: IFallData,
    itemZus: IFallDataZusaetzlich,
    handleClickHidePopup: (e: any) => void,
}

const CardBackPopup: React.FC<ICardBackPopupProps> = ({ item, itemZus, handleClickHidePopup }): JSX.Element => {
    const [showFotos, setShowFotos] = useState(false);
    const screenSize = useScreenSize();
    const mnr = useAppSelector((state) => state.store_einstellungen.konfiguration.mandantennummer);
    const listenAnsicht = useAppSelector((state) => state.store_einstellungen.konfiguration.faelle_ansicht_liste);
    const fotos: IFoto[] = useMemo(() => get_fotos_from_fall(item, mnr), [item, mnr]);

    const handleShowFotos = useCallback(() => {
        setShowFotos(true);
    }, []);
    const handleHideFotos = useCallback(() => {
        setShowFotos(false);
    }, []);
    const handleClickAbrrechen = useCallback(() => {
        handleClickHidePopup(undefined);
    }, [handleClickHidePopup]);


    const RenderePopupTitle = useCallback(() => {
        return (<h2 className="title_popup">{(itemZus.record_typ === "Löschsatz") ? itemZus.record_typ : "Fall " + itemZus.record_typ}</h2>)
    }, [itemZus.record_typ]);
    return (<>
        <Popup
            visible={true}
            onHiding={handleClickHidePopup}
            dragEnabled={true}
            hideOnOutsideClick={true}
            fullScreen={false}
            showCloseButton={true}
            className="p-0"
            height="auto"
            maxHeight="70vh"
            maxWidth={(screenSize.isMedium || screenSize.isSmall) ? "90vw" : "700px"}
        >
            <ToolbarItem
                toolbar="top"
                location={'before'}
                render={RenderePopupTitle}
            />
            <ToolbarItem
                widget="dxButton"
                toolbar="bottom"
                location="after"
            >
                <MDButton
                    button_text={"Abbrechen"}
                    page="fall_card_back_popup"
                    className="abbrechen_button_card_Back"
                    onClick={handleClickAbrrechen}
                />
            </ToolbarItem>
            {(listenAnsicht && fotos.length > 0) &&
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="before"
                >
                    <MDButton
                        button_text={"Fotos"}
                        page="fall_card_back_popup"
                        className="fotos_ansehen_button"
                        onClick={handleShowFotos}
                    />
                </ToolbarItem>}
            <ScrollView>
                <CardBack item={item} itemZus={itemZus} />
            </ScrollView>
        </Popup>
        { /**showFotos && <FotosPopup fotos={fotos} onHide={handleHideFotos} />*/}
        {showFotos && <PopupGallery selectedItem={0} fotos={fotos} onHide={handleHideFotos} />}
    </>
    )
}

export default CardBackPopup;
