import { memo } from "react";
import { icon_car } from "../../../../../global/const";
import { MDButton } from "../../../../../global/components";


interface ISchleppungProps {
    abs_zsu: string,
}
const SchleppungVOWI: React.FC<ISchleppungProps> = ({ abs_zsu }): JSX.Element => {


    return (
        abs_zsu ?
            <li>
                <MDButton
                    className="highlight schleppung_vowi"
                    page="fall_card"
                    icon={icon_car}
                    tooltip_text={"Schleppung"}
                />
            </li> : <></>);
}

export default memo(SchleppungVOWI);