import { DataGrid, Popup, ScrollView } from "devextreme-react";
import { ToolbarItem } from "devextreme-react/autocomplete"
import { Column, Paging, Selection } from "devextreme-react/data-grid";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { GetData } from "../../../global/communication";
import { sachbearbeiter_einfach_columns } from "../../../sonstiges/columns";
import { useAppDispatch, useAppSelector } from "../../../state";
import { ISachbearbeiterEinfach } from "../../einstellungen/sb_gruppen/interfaces";
import { MDButton } from "../../../global/components";
import { IColumn } from "../../../global/interfaces";

interface ISQLHochladenSachbearbeiterProps {
    callback: (selected_sb: ISachbearbeiterEinfach[]) => void,
}


const columns: IColumn[] = sachbearbeiter_einfach_columns;

const SQLHochladenSachbearbeiter: React.FC<ISQLHochladenSachbearbeiterProps> = ({ callback }) => {
    const [showPopUp, setShowPopUp] = useState(false);
    const dispatch = useAppDispatch();
    const [allSachbearbeiter, setAllSachbearbeiter] = useState<ISachbearbeiterEinfach[]>([]);
    const refDataGrid = useRef() as React.MutableRefObject<DataGrid<any, any>>;
    const sb_nummer = useAppSelector((state) => state.store_einstellungen.konfiguration.sachbearbeiternummer);

    const handleHidePopup = useCallback(() => {
        setShowPopUp(false)
    }, []);
    const handleShowPopup = useCallback(() => {
        setShowPopUp(true)
    }, []);

    const handleClickSpeichern = useCallback(() => {
        const selected_s: ISachbearbeiterEinfach[] = (refDataGrid && refDataGrid.current && refDataGrid.current.instance) ? refDataGrid.current.instance.getSelectedRowsData() : [];
        setShowPopUp(false)
        callback(selected_s);
    }, [callback]);

    const memo_options_starten = useMemo(() => {
        return {
            text: 'Speichern',
            onClick: handleClickSpeichern,
        }
    }, [handleClickSpeichern]);
    const memo_options_abbrechen = useMemo(() => {
        return {
            text: 'Abbrechen',
            onClick: handleHidePopup,
        }
    }, [handleHidePopup]);


    useEffect(() => {
        if (showPopUp && allSachbearbeiter.length === 0) {
            GetData({
                Operation: "GetAllSachbearbeiter",
            }, dispatch).then((res) => {
                if (!res) return
                setAllSachbearbeiter(res.data);
            })
        }
    }, [showPopUp, allSachbearbeiter, dispatch]);
    useEffect(() => {
        if (allSachbearbeiter.length > 0) {
            if ((refDataGrid && refDataGrid.current && refDataGrid.current.instance)) {
                // Angemedelter Sachbearbeiter selektieren
                refDataGrid.current.instance.selectRows([sb_nummer], true);
            }
        }
    }, [allSachbearbeiter, sb_nummer]);

    return (
        <div>
            <MDButton
                page="sql_hochladen_sachbearbeiter"
                button_text="Neuer Sachbearbeiter"
                onClick={handleShowPopup}
            />
            <Popup
                visible={showPopUp && allSachbearbeiter.length > 0}
                onHiding={handleHidePopup}
                dragEnabled={true}
                hideOnOutsideClick={true}
                fullScreen={false}
                showCloseButton={true}
                height='auto'
                maxHeight="70vh"
                maxWidth="700px"
            >
                <ToolbarItem
                    toolbar="top"
                    location={'before'}
                    render={RenderePopupTitle}
                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="before"
                    options={memo_options_starten}
                />

                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={memo_options_abbrechen}
                />
                <ScrollView>
                    <DataGrid
                        dataSource={allSachbearbeiter}
                        allowColumnResizing={true}
                        columnAutoWidth={true}
                        renderAsync={true}
                        ref={refDataGrid}
                        wordWrapEnabled={true}
                        rowAlternationEnabled={true}
                        keyExpr={'f07001'}
                        height={"auto"}
                        noDataText=""
                    //  selectedRowKeys={selectedRowKeys}
                    // onSelectedRowKeysChange={(e: any) => console.log(e)}
                    //    onSelectionChanged={handleSelectedRowChangeSB}
                    >
                        <Paging enabled={false} />
                        <Selection mode="multiple" showCheckBoxesMode={"always"} />
                        {
                            columns.map((column) => {
                                return (
                                    <Column
                                        dataField={column.dataField}
                                        caption={column.caption}
                                        dataType={column.dataType}
                                        alignment={column.alignment}
                                        cellRender={column.cellRender}
                                        key={column.dataField}
                                        visible={column.visible}
                                    />
                                )
                            })
                        }
                    </DataGrid>
                </ScrollView>
            </Popup>
        </div>)
}


export default memo(SQLHochladenSachbearbeiter);

const RenderePopupTitle = () => {
    return (<h2 className="title_popup">Sachbearbeiter auswählen</h2>)
};
