import React, { useCallback, useState } from 'react';
import Popup from 'devextreme-react/popup';
import ScrollView from 'devextreme-react/scroll-view';
import TextBox from 'devextreme-react/text-box';
import { ToolbarItem } from 'devextreme-react/autocomplete';
import { useScreenSize } from '../../../global/media-query';
import { TextArea } from 'devextreme-react';
import { useMemo } from 'react';
import { setAlert, setToast, useAppDispatch, useAppSelector } from '../../../state';
import { GetData } from '../../../global/communication';
import NeuerSachbearbeiter from './NeuerSachbearbeiter';
import { ISachbearbeiterEinfach, ISachbearbeiterGruppenData } from '../../einstellungen/sb_gruppen/interfaces';
import NeueSGruppe from './NeueSGruppe';
import { handleContentReadyTextBox } from "../../../global/hilfs_functions";


interface ISQLHochladenProps {
    callback: () => void,
}

const rows = { rows: "5" };
const rows1 = { rows: "3" };
function get_Value(benutzer: any) {
    let result = ";";
    for (let i of benutzer.s)
        result = result + "S" + i.nummer + ";";
    for (let i of benutzer.g)
        result = result + "G" + i.nummer + ";";
    return result;
}

const SQL_Hochladen: React.FC<ISQLHochladenProps> = ({ callback }): JSX.Element => {
    const screenSize = useScreenSize();
    const [bezeichnung, setBezeichnung] = useState("");
    const [sqlText, setSqlText] = useState("");
    const [notiz, setNotiz] = useState("");
    const sb_Nummer = useAppSelector((state) => state.store_einstellungen.konfiguration.sachbearbeiternummer);
    const sb_Name = useAppSelector((state) => state.store_einstellungen.konfiguration.sachbearbeitername);
    const [benutzer, setBenutzer] = useState<{ s: { name: string, nummer: number }[], g: { name: string, nummer: number }[] }>({ s: [{ name: sb_Name, nummer: parseInt(sb_Nummer) }], g: [] });
    const dispatch = useAppDispatch();

    const handleClickSpeichern = useCallback(() => {
        if (!bezeichnung || !sqlText) {
            dispatch(setAlert({
                text: !bezeichnung ? "Die Bezeichnung darf nicht leer sein." : " Der Sql-Text darf nicht leer sein",
                type: "Fehler",
            }))
            return
        }

        GetData({
            Operation: "SqlTextHochladen",
            bezeichnung: bezeichnung,
            sqlText: sqlText,
            notiz: notiz,
            benutzer: get_Value(benutzer),
        }, dispatch)
            .then((res) => {
                if (!res) return
                dispatch(setToast({
                    message: "Der Sql-Text wurde erfolgreich in der Datenbank gespeichert.",
                    type: "success",
                    position: "bottom center"
                }));
                callback();
            });

    }, [callback, bezeichnung, sqlText, dispatch, notiz, benutzer]);

    const memo_options_speichern = useMemo(() => {
        return {
            text: 'Speichern',
            stylingMode: "outlined",
            onClick: handleClickSpeichern,
        }
    }, [handleClickSpeichern]);

    const memo_options_abbrechen = useMemo(() => {
        return {
            text: 'Abbrechen',
            stylingMode: "outlined",
            onClick: callback,
        }
    }, [callback]);

    const handleCallBackSB = useCallback((selected_sb: ISachbearbeiterEinfach[]) => {
        let result = [];
        for (let i of selected_sb) {
            result.push({ name: i.f07002, nummer: i.f07001 });
        }
        setBenutzer({ ...benutzer, s: result });
    }, [benutzer]);
    const handleCallBackG = useCallback((selected_g: ISachbearbeiterGruppenData[]) => {
        let result = [];
        for (let i of selected_g) {
            result.push({ name: i.f17002, nummer: i.f17001 });
        }
        setBenutzer({ ...benutzer, g: result });
    }, [benutzer]);

    return (
        <Popup
            visible={true}
            hideOnOutsideClick={true}
            showCloseButton={true}
            onHiding={callback}
            height="auto"
            maxHeight="75vh"
            maxWidth={(screenSize.isMedium || screenSize.isSmall) ? "90vw" : "600px"}
        >
            <ToolbarItem
                toolbar="top"
                location={'before'}
                render={RenderePopupTitle}
            />
            <ScrollView>
                <div className="row g-0">
                    <div className='col-12'>
                        <TextBox
                            label='Bezeichnung'
                            labelMode='floating'
                            stylingMode='outlined'
                            mode='text'
                            showClearButton={false}
                            onValueChange={setBezeichnung}
                            value={bezeichnung}
                            valueChangeEvent="input"
                            maxLength={30}
                            onContentReady={handleContentReadyTextBox}
                        />
                    </div>
                    <div className='col-12 pt-2'>
                        <TextArea
                            label="SQL-Text"
                            labelMode="floating"
                            stylingMode="outlined"
                            value={sqlText}
                            onValueChange={setSqlText}
                            valueChangeEvent="input"
                            inputAttr={rows}
                            maxLength={4254}
                            onContentReady={handleContentReadyTextBox}
                        />
                    </div>
                    <div className='col-12 pt-2'>
                        <TextArea
                            label="Notiz"
                            labelMode="floating"
                            stylingMode="outlined"
                            value={notiz}
                            onValueChange={setNotiz}
                            valueChangeEvent="input"
                            inputAttr={rows1}
                            maxLength={254}
                            onContentReady={handleContentReadyTextBox}
                        />
                    </div>
                    <div className='col-12 row g-0 pt-2'>
                        <div className='col-7 p-2'>
                            {benutzer.s.map((item) => <p key={item.nummer}>Sachbearbeiter: {item.name}</p>)}
                            {benutzer.g.map((item) => <p key={item.nummer}>Gruppe: {item.name}</p>)}
                        </div>
                        <div className='col-5 d-flex flex-column  justify-content-start'>
                            <NeuerSachbearbeiter callback={handleCallBackSB} />
                            <NeueSGruppe callback={handleCallBackG} />
                        </div>
                    </div>
                </div>
            </ScrollView>
            <ToolbarItem
                widget="dxButton"
                toolbar="bottom"
                location="after"
                options={memo_options_speichern}
            />
            <ToolbarItem
                widget="dxButton"
                toolbar="bottom"
                location="after"
                options={memo_options_abbrechen}
            />
        </Popup>
    );
}

export default SQL_Hochladen;

const RenderePopupTitle = () => {
    return (<h2 className="title_popup">SQL-Text hochladen</h2>)
};
