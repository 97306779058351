

import { NumberBox, SelectBox } from "devextreme-react";
import ArrayStore from "devextreme/data/array_store";
import { memo, useEffect, useState } from "react";
import { handleContentReadySelectBox, handleContentReadyTextBox } from "../../../global/hilfs_functions";
import { ITatbestandVerwaltePopupProps } from "./verwalten_popup";

const data_source_1 = new ArrayStore({
    data: [{ text: "Automatik", value: 0 },
    { text: "Nein", value: 1 },
    { text: "Ja", value: 2 },
    { text: "Pflicht", value: 3 }],
    key: 'value',
});

const data_source_2 = new ArrayStore({
    data: [
        { text: "Automatik", value: 0 },
        { text: "Pflicht", value: 3 }],
    key: 'value',
});

const MobilDatenTab: React.FC<ITatbestandVerwaltePopupProps> = ({ isAdd, data, setData }): JSX.Element => {

    const [ventilstellungValue, setVentilstellungValue] = useState(data!.f02100);
    const [konkretisierungBehValue, setKonkretisierungBehValue] = useState(data!.f02101);
    const [konkretisierungenValue, setKonkretisierungenValue] = useState(data!.f02102);
    const [parkscheibeValue, setParkscheibeValue] = useState(data!.f02103);
    const [parkscheinValue, setParkscheinValue] = useState(data!.f02104);
    const [beschilderungsFoto, setBeschilderungsFoto] = useState(data!.f02110);
    const [foto1, setFoto1] = useState(data!.f02107);
    const [foto2, setFoto2] = useState(data!.f02108);
    const [foto3, setFoto3] = useState(data!.f02109);
    const [mindestZeitInMinuten, setMindestZeitInMinuten] = useState(data!.f02111);

    useEffect(() => {
        if (setData && data && (
            (ventilstellungValue !== data!.f02100) ||
            (konkretisierungBehValue !== data!.f02101) ||
            (konkretisierungenValue !== data!.f02102) ||
            (parkscheibeValue !== data!.f02103) ||
            (parkscheinValue !== data!.f02104) ||
            (beschilderungsFoto !== data!.f02110) ||
            (foto1 !== data!.f02107) ||
            (foto2 !== data!.f02108) ||
            (foto3 !== data!.f02109) ||
            (mindestZeitInMinuten !== data!.f02111)
        )) {
            setData({
                ...data,
                f02100: ventilstellungValue,
                f02101: konkretisierungBehValue,
                f02102: konkretisierungenValue,
                f02103: parkscheibeValue,
                f02104: parkscheinValue,
                f02110: beschilderungsFoto,
                f02107: foto1,
                f02108: foto2,
                f02109: foto3,
                f02111: mindestZeitInMinuten,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ventilstellungValue, konkretisierungBehValue, konkretisierungenValue, parkscheibeValue, parkscheinValue, beschilderungsFoto, foto1, foto2, foto3, mindestZeitInMinuten]);

    return (<>
        <div className="row p-2 p-md-3 pt-2 pt-md-4">
            <div className="col-12 col-md-6 p-1 p-md-2">
                <SelectBox
                    dataSource={data_source_1}
                    displayExpr="text"
                    valueExpr="value"
                    value={ventilstellungValue}
                    onValueChange={setVentilstellungValue}
                    label='Ventilstellung'
                    labelMode='floating'
                    stylingMode='outlined'
                    onContentReady={handleContentReadySelectBox}
                />
            </div>
            <div className="col-12 col-md-6 p-1 p-md-2">
                <SelectBox
                    dataSource={data_source_2}
                    displayExpr="text"
                    valueExpr="value"
                    value={konkretisierungBehValue}
                    onValueChange={setKonkretisierungBehValue}
                    label='Konkretisierung (Beh)'
                    labelMode='floating'
                    stylingMode='outlined'
                    onContentReady={handleContentReadySelectBox}
                />
            </div>
            <div className="col-12 col-md-6 p-1 p-md-2">
                <SelectBox
                    dataSource={data_source_2}
                    displayExpr="text"
                    valueExpr="value"
                    value={konkretisierungenValue}
                    onValueChange={setKonkretisierungenValue}
                    label='Konkretisierung (*)'
                    labelMode='floating'
                    stylingMode='outlined'
                    onContentReady={handleContentReadySelectBox}
                />
            </div>
            <div className="col-12 col-md-6 p-1 p-md-2">
                <SelectBox
                    dataSource={data_source_1}
                    displayExpr="text"
                    valueExpr="value"
                    value={parkscheibeValue}
                    onValueChange={setParkscheibeValue}
                    label='Parkscheibe'
                    labelMode='floating'
                    stylingMode='outlined'
                    onContentReady={handleContentReadySelectBox}
                />
            </div>
            <div className="col-12 col-md-6 p-1 p-md-2">
                <SelectBox
                    dataSource={data_source_1}
                    displayExpr="text"
                    valueExpr="value"
                    value={parkscheinValue}
                    onValueChange={setParkscheinValue}
                    label='Parkschein'
                    labelMode='floating'
                    stylingMode='outlined'
                    onContentReady={handleContentReadySelectBox}
                />
            </div>
            <div className="col-12 col-md-6 p-1 p-md-2">
                <SelectBox
                    dataSource={data_source_2}
                    displayExpr="text"
                    valueExpr="value"
                    value={beschilderungsFoto}
                    onValueChange={setBeschilderungsFoto}
                    label='Beschilderungsfoto'
                    labelMode='floating'
                    stylingMode='outlined'
                    onContentReady={handleContentReadySelectBox}
                />
            </div>
            <div className="col-12 col-md-6 p-1 p-md-2">
                <SelectBox
                    dataSource={data_source_2}
                    displayExpr="text"
                    valueExpr="value"
                    value={foto1}
                    onValueChange={setFoto1}
                    label='Foto 1'
                    labelMode='floating'
                    stylingMode='outlined'
                    onContentReady={handleContentReadySelectBox}
                />
            </div>
            <div className="col-12 col-md-6 p-1 p-md-2">
                <SelectBox
                    dataSource={data_source_2}
                    displayExpr="text"
                    valueExpr="value"
                    value={foto2}
                    onValueChange={setFoto2}
                    label='Foto 2'
                    labelMode='floating'
                    stylingMode='outlined'
                    onContentReady={handleContentReadySelectBox}
                />
            </div>
            <div className="col-12 col-md-6 p-1 p-md-2">
                <SelectBox
                    dataSource={data_source_2}
                    displayExpr="text"
                    valueExpr="value"
                    value={foto3}
                    onValueChange={setFoto3}
                    label='Foto 3'
                    labelMode='floating'
                    onContentReady={handleContentReadySelectBox}
                    stylingMode='outlined'
                />
            </div>
            <div className="col-12 col-md-6 p-1 p-md-2">
                <NumberBox
                    label="Mindestzeit in Minuten"
                    labelMode="floating"
                    stylingMode="outlined"
                    value={mindestZeitInMinuten}
                    onValueChange={setMindestZeitInMinuten}
                    valueChangeEvent="input"
                    onContentReady={handleContentReadyTextBox}
                />
            </div>

        </div>
    </>)
}

export default memo(MobilDatenTab);