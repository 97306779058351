import { LoadPanel } from "devextreme-react";
import { memo, useCallback, useEffect, useState } from "react";
import { GetData } from "../../../../global/communication";
import { icon_download } from "../../../../global/const";
import { useAppDispatch } from "../../../../state";
import { MDButton } from "../../../../global/components";
import { IGeraeteProtokolle } from "../interfaces";



interface IProtokollProps {
    geraet_nummer: string,
}
const Protokoll: React.FC<IProtokollProps> = ({ geraet_nummer }) => {
    const [isloading, setIsloading] = useState(true);
    const [data, setData] = useState<IGeraeteProtokolle[]>([]);
    const dispatch = useAppDispatch();

    useEffect(() => {
        get_data();
        setIsloading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const get_data = useCallback(() => {
        GetData({
            Operation: "GetGeraeteprotokolle",
            Geraetenummer: geraet_nummer,
        }, dispatch)
            .then((res) => {
                if (!res) {
                    setData([])
                    return
                }
                setData(res.data)
            });
    }, [geraet_nummer, dispatch]);


    const handleClickDownload = useCallback((e) => {
        window.open(JSON.parse(e.element.getAttribute("data")), '_blank')
    }, [])
    return (
        <div className="p-3 p-md-5 ">
            {isloading ? <LoadPanel visible={true} /> :
                <div className="protokoll_table">
                    <table>
                        <thead>
                            <tr>
                                <th>Datei</th>
                                <th>Größe</th>
                                <th>Zeitstempel</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.length > 0 && data.map((item) => {
                                return (
                                    <tr key={item.datei} >
                                        <td>{item.datei}</td>
                                        <td>{item.groesse}</td>
                                        <td>{item.zeitstempel}</td>
                                        <td className="text-center">
                                            <MDButton
                                                icon={icon_download}
                                                data={item.url}
                                                onClick={handleClickDownload}
                                                page="geraete_protokoll_download"
                                                tooltip_text="Protokoll herunterladen"
                                            />
                                        </td>
                                    </tr>
                                )
                            })}</tbody>
                    </table></div>}
        </div >
    )
}


export default memo(Protokoll);


/** MD Datagrid hat nicht funktoiniert hier. contentTemplate( ). is not a function
        <DataGrid
                    dataSource={data}
                    columnAutoWidth={true}
                    rowAlternationEnabled={true}
                    wordWrapEnabled={true}
                    keyExpr="datei"
                    noDataText="Keine Protokolle"
                >
                    <Column dataField="datei" caption="Datei" />
                    <Column dataField="zeitstempel" caption="Zeitstempel" />
                    <Column dataField="groesse" caption="Größe" alignment="left" />
                    <Column dataField="url" caption="" cellRender={urlProtokollRender} />
                </DataGrid> */