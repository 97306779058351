import { useCallback, useEffect, useState } from "react";
import { ISelectedItemKategorie } from "..";
import UnterGruppeVerwaltePopup from "./verwalten_untergruppe";
import { ITatbestandUnterKategorie } from "../../tatbestaende/interfaces";
import { setConfirm, setToast, useAppDispatch } from "../../../../state";
import { GetData } from "../../../../global/communication";
import UnterGruppenGrid from "./unter_gruppen_grid";

interface IUnterGruppenProps {
    selectedItem: ISelectedItemKategorie,
    setSelectedItem: (obj: ISelectedItemKategorie) => void,
}


const UnterGruppen: React.FC<IUnterGruppenProps> = ({ selectedItem, setSelectedItem }) => {
    const [bearbeitePopUp, setBearbeitePopup] = useState<{ show: boolean, row: ITatbestandUnterKategorie | null }>({
        show: false,
        row: null,
    });
    const dispatch = useAppDispatch();
    const [refreshGrid, setRefreshGrid] = useState(true);

    const handleCallBack = useCallback((update?: boolean, loeschen?: boolean) => {
        (loeschen) &&
            delete_untergruppe(dispatch, bearbeitePopUp.row, () => setRefreshGrid(prev => !prev));
        setBearbeitePopup({ show: false, row: null })
        update && setRefreshGrid(prev => !prev);
    }, [bearbeitePopUp, dispatch]);

    useEffect(() => {
        selectedItem.haupt_gruppen_bezeichnung && setRefreshGrid(prev => !prev);
    }, [selectedItem]);
    return (<>
        {bearbeitePopUp.show && <UnterGruppeVerwaltePopup row={bearbeitePopUp.row} callback={handleCallBack} selectedItem={selectedItem} />}
        <UnterGruppenGrid refresh_grid={refreshGrid} selectedItem={selectedItem} setBearbeitePopup={setBearbeitePopup} setSelectedItem={setSelectedItem} />

    </>
    )
}

export default UnterGruppen;

const delete_untergruppe = (dispatch: any, data: any, callback: any) => {
    dispatch(setConfirm({
        body:
            <div className="alert alert-danger">
                <p>Wollen sie die Untergruppe <b>{data.f003}</b> wirklich löschen?</p>
            </div>,
        on_ok: () => {
            GetData({
                Operation: "DeleteUntergruppe",
                Hauptgruppennummer: data.f001,
                Untergruppennummer: data.f002
            }, dispatch).then((res) => {
                if (!res) return
                dispatch(setToast({
                    message: "Untergruppe wurde erfolgreich gelöscht",
                    type: "success",
                    position: "bottom center"
                }));
                callback();
            });
        },
        title: "Untergruppe löschen",
    }));
}