import { CheckBox, DateBox, NumberBox, Popup, ScrollView, SelectBox, TagBox, TextBox } from "devextreme-react"
import { ToolbarItem } from "devextreme-react/autocomplete"
import ArrayStore from "devextreme/data/array_store";
import { useCallback, useEffect, useMemo, useState } from "react";
import { GetData } from "../../../global/communication";
import { encodeBASE64, get_date_ajax_param, handleContentReadyCheckBox, handleContentReadyDateBox, handleContentReadySelectBox, handleContentReadyTextBox } from "../../../global/hilfs_functions";
import { ISachbearbeiter } from "../../../global/interfaces";
import { Seperatorliste } from "../../../global/seperator_liste";
import { setConfirm, setToast, useAppDispatch, useAppSelector } from "../../../state";




interface ISachbearbeiterVerwaltePopupProps {
    callback: (update?: boolean) => void,
    row?: ISachbearbeiter | null,
}

const dataSourceBedingung = new ArrayStore({
    data: [
        { text: "Deaktiviert", value: " " },
        { text: "Winowig Fallbearbeitung (T003)", value: "0" },
        { text: "Winowig Online (T701)", value: "2" }
    ],
    key: 'value',
});

const dataSourceGueltigkeit = new ArrayStore({
    data: [
        { text: "Läuft nie ab", value: -1 },
        { text: "Passwort ist abgelaufen", value: 0 },
        { text: "läuft monatlich ab", value: 1 },
        { text: "läuft zweimonatlich ab", value: 2 },
        { text: "läuft alle 3 Monate ab", value: 3 },
        { text: "läuft alle 4 Monate ab", value: 4 },
        { text: "läuft alle 5 Monate ab", value: 5 },
        { text: "läuft alle 6 Monate ab", value: 6 },
        { text: "läuft alle 7 Monate ab", value: 7 },
        { text: "läuft alle 8 Monate ab", value: 8 },
        { text: "läuft alle 9 Monate ab", value: 9 },
        { text: "läuft alle 10 Monate ab", value: 10 }
    ],
    key: 'value',
})

const dataSourceStatus = new ArrayStore({
    data: [
        { text: "Nicht gesperrt", value: 0 },
        //{ text: "Sperrung", value: "1", enabled: false }, // Immer Vollsperrung bei Online: Sachbearbeiter die bereits eine "einfache" Sperrung haben gelten als "Nicht gesperrt"
        { text: "Gesperrt", value: 2 }
    ],
    key: 'value',
})

const dataSourceWinowigMail = new ArrayStore({
    data: [
        { text: "Deaktiviert", value: "deaktiviert" },
        { text: "Aktiviert", value: "aktiviert" }
    ],
    key: 'value',
})


const SachbearbeiterVerwaltePopup: React.FC<ISachbearbeiterVerwaltePopupProps> = ({ callback, row }): JSX.Element => {

    const isAdd = row === undefined;
    const konfiguration = useAppSelector((state) => state.store_einstellungen.konfiguration);
    const [sbNummer, setSbNummer] = useState<number>(!isAdd ? row!.f07001 : 0); // Get GetNextSachbearbeiternummer
    const [sbname, setSbName] = useState(!isAdd ? row!.f07002 : "");
    const [aussenDarstellung, setAussenDarstellung] = useState(!isAdd ? row!.f07013 : "");
    const [sqlBedingung, setSqlBedigung] = useState(!isAdd ? row!.f07030 : "");
    const [freifeld1, setFreifeld1] = useState(!isAdd ? row!.f07040 : "");
    const [freifeld2, setFreifeld2] = useState(!isAdd ? row!.f07041 : "");
    const [freifeld3, setFreifeld3] = useState(!isAdd ? row!.f07042 : "");
    const [freifeld4, setFreifeld4] = useState(!isAdd ? row!.f07043 : "");
    const [freifeld5, setFreifeld5] = useState(!isAdd ? row!.f07044 : "");
    const [freifeld6, setFreifeld6] = useState(!isAdd ? row!.f07045 : "");
    const [passwort, setPasswort] = useState(!isAdd ? row!.f07011 : "");
    const [passwortDatum, setPasswortDatum] = useState((!isAdd) ? row!.f07100 ? (new Date(parseInt(row!.f07100.split('.')[2]), parseInt(row!.f07100.split('.')[1]) - 1, parseInt(row!.f07100.split('.')[0]))) : undefined : new Date());
    const [gueltigskeitDauerValue, setGueltigskeitDauerValue] = useState(!isAdd ? row!.f07101 : -1);
    const [status, setStatus] = useState(!isAdd ? row!.f07110 : 0);
    const [winowigMail, setWinowigMail] = useState(!isAdd ? row!.WinowigMail : "deaktiviert");
    const [gruppenValue, setGruppenValue] = useState<any[] | undefined>(undefined);
    const [bedingungValue, setBedigungValue] = useState(" ");
    const [sbGruppenInfo, setSbGruppenInfo] = useState<{ f17001: number, f17002: string }[]>([]);
    const dataSoruceSBGruppenInfo = useMemo(() => new ArrayStore({
        data: sbGruppenInfo,
        key: 'f17001',
    }), [sbGruppenInfo]);
    const dispatch = useAppDispatch();
    const [einmalPasswort, setEinmalPasswort] = useState<boolean | null>((!isAdd && row?.f07103 === 1) ? true : false);
    //MD 09.01.2023 SB dürfen nicht bearbeitet werden bei amm
    const auftragserfassung = useAppSelector((state) => state.store_einstellungen.konfiguration.auftragserfassung);




    useEffect(() => {
        GetData({
            Operation: "GetSachbearbeitergruppenInfo",
        }, dispatch).then((res) => {
            if (!res) return
            setSbGruppenInfo(res.data);
        })

        if (!isAdd) {
            let bezeichnung = row!.f07025;
            !row!.f07025 && (row!.f07025 = " ");
            ((row!.f07025 !== " ") && (row!.f07025 !== "0") && (row!.f07025 !== "2")) &&
                (bezeichnung = "Undefinierter Wert: " + bezeichnung);

            dataSourceBedingung.insert({ text: bezeichnung, value: row!.f07025 });
            setBedigungValue(row!.f07025);

            //Gruppen
            let arr = row!.f07012.split(';');//.map((item) => {return parseInt(item)})
            let arr2 = arr.filter(ele => ele.trim().length > 0);
            setGruppenValue(arr2.map((item) => { return parseInt(item) }));
        } else {
            GetData({
                Operation: "GetNextSachbearbeiternummer",
            }, dispatch).then((res) => {
                if (!res) return
                setSbNummer(parseInt(res.nummer))
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getSachbearbeitergruppen = useCallback((): string => {
        let result = "";
        if (gruppenValue) {
            for (let i of gruppenValue) {
                if (result !== "")
                    result += "%03";
                result += i;
            }
        }
        return result;
    }, [gruppenValue]);

    const handleClickSpeichern = useCallback(() => {
        if (!sbname) {
            dispatch(setToast({
                message: "Sachbearbeitername muss vorhanden sein",
                type: "error",
                position: "center center"
            }));
            return;
        }
        //MD 21.07.2023 neu eingebaut. Bug: RE1-I1713
        let f07012 = getSachbearbeitergruppen();
        if (!f07012) {
            dispatch(setToast({
                message: "Der Sachbearbeiter sollte mindestens einer Berechtigungsgruppe zugeordnet werden",
                type: "error",
                position: "center center"
            }));
            return;
        }

        let daten = new Seperatorliste("%01", "%02");
        daten.setValue("Sachbearbeiternummer", sbNummer.toString());
        daten.setValue("Sachbearbeitername", sbname);
        daten.setValue("Aussendarstellung", aussenDarstellung);
        daten.setValue("SqlBedingungAktiviertFuer", bedingungValue);
        daten.setValue("SqlBedingungFuerFallanzeige", sqlBedingung);
        daten.setValue("Freifeld1", freifeld1);
        daten.setValue("Freifeld2", freifeld2);
        daten.setValue("Freifeld3", freifeld3);
        daten.setValue("Freifeld4", freifeld4);
        daten.setValue("Freifeld5", freifeld5);
        daten.setValue("Freifeld6", freifeld6);
        daten.setValue("Passwort", encodeBASE64(passwort));
        daten.setValue("PasswortDatum", get_date_ajax_param(passwortDatum));
        daten.setValue("Status", status.toString());
        daten.setValue("Gueltigkeitsdauer", gueltigskeitDauerValue.toString());
        daten.setValue("Gruppenzugehoerigkeit", f07012);
        daten.setValue("WinowigMail", winowigMail);
        daten.setValue("EinmalPasswort", einmalPasswort ? "1" : "0");
        GetData({
            Operation: isAdd ? "InsertSachbearbeiter" : "UpdateSachbearbeiter",
            Daten: daten.getSeperatorliste()
        }, dispatch).then((res) => {
            if (!res) return
            callback(true);
        })
    }, [sbname, einmalPasswort, dispatch, sbNummer, isAdd, callback, aussenDarstellung, bedingungValue, sqlBedingung, freifeld1, freifeld2, freifeld3, freifeld4, freifeld5, freifeld6, passwort, passwortDatum, status, gueltigskeitDauerValue, getSachbearbeitergruppen, winowigMail]);


    const handleHidePopup = useCallback(() => {
        callback()
    }, [callback]);

    const memo_options_loeschen = useMemo(() => {
        return {
            text: 'Löschen',
            type: "danger",
            onClick: () => { delete_sachbearbeiter(dispatch, row!, callback) },
        }
    }, [dispatch, row, callback]);

    const memo_options_speichern = useMemo(() => {
        return {
            text: 'Speichern',
            onClick: handleClickSpeichern
        }
    }, [handleClickSpeichern]);

    const memo_options_abbrechen = useMemo(() => {
        return {
            text: 'Abbrechen',
            onClick: handleHidePopup,
        }
    }, [handleHidePopup]);

    const handlePasswortChange = useCallback((e: any) => {
        setPasswort(e);
        (get_date_ajax_param(passwortDatum) !== get_date_ajax_param(new Date()) && !isAdd) && setPasswortDatum(new Date())
    }, [passwortDatum, isAdd]);

    const RenderePopupTitle = useCallback(() => {
        return (<h2 className="title_popup">{isAdd ? "Neuer Sachbearbeiter" : "Sachbearbeiter ändern"}</h2>)
    }, [isAdd]);



    return (
        <>
            <Popup
                visible={true}
                onHiding={handleHidePopup}
                dragEnabled={true}
                hideOnOutsideClick={true}
                fullScreen={false}
                showCloseButton={true}
                height='auto'
                // maxHeight="750px"
                // maxWidth="700px"
                maxHeight="80vh"
                maxWidth="70vw"
                animation={undefined}
            >
                <ToolbarItem
                    toolbar="top"
                    location={'before'}
                    render={RenderePopupTitle}
                />
                {(!isAdd && !auftragserfassung) &&
                    <ToolbarItem
                        widget="dxButton"
                        toolbar="bottom"
                        location="before"
                        options={memo_options_loeschen}
                    />}
                {!auftragserfassung &&
                    <ToolbarItem
                        widget="dxButton"
                        toolbar="bottom"
                        location="after"
                        options={memo_options_speichern}
                    />
                }
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={memo_options_abbrechen}
                />
                <ScrollView>
                    <div className='row p-2 p-md-3 g-0'>
                        <div className="col-12 col-md-6 p-1 p-md-2">
                            <NumberBox
                                label="Sachbearbeiternummer"
                                labelMode="floating"
                                stylingMode="outlined"
                                onValueChange={setSbNummer}
                                value={sbNummer}
                                disabled={auftragserfassung || !isAdd}
                                onContentReady={handleContentReadyTextBox}
                            //valueChangeEvent="input"
                            />
                        </div>
                        <div className="col-12 col-md-6 p-1 p-md-2">
                            <TextBox
                                label="Sachbearbeitername"
                                labelMode="floating"
                                stylingMode="outlined"
                                onValueChange={setSbName}
                                value={sbname}
                                valueChangeEvent="input"
                                maxLength={60}
                                onContentReady={handleContentReadyTextBox}
                                disabled={auftragserfassung}
                            />
                        </div>
                        <div className="col-12 col-md-6 p-1 p-md-2">
                            <TextBox
                                label="Aussendarstellung"
                                labelMode="floating"
                                stylingMode="outlined"
                                onValueChange={setAussenDarstellung}
                                value={aussenDarstellung}
                                valueChangeEvent="input"
                                maxLength={60}
                                onContentReady={handleContentReadyTextBox}
                                disabled={auftragserfassung}
                            />
                        </div>
                        <div className="col-12 col-md-6 p-1 p-md-2">
                            <SelectBox
                                dataSource={dataSourceBedingung}
                                displayExpr="text"
                                valueExpr="value"
                                //acceptCustomValue={true}
                                value={bedingungValue}
                                //onCustomItemCreating={customItemCreating}
                                onValueChange={setBedigungValue}
                                label='Bedingung für Fallabfrage'
                                labelMode='floating'
                                stylingMode='outlined'
                                onContentReady={handleContentReadySelectBox}
                                disabled={auftragserfassung}
                            />
                        </div>
                        <div className="col-12 p-1 p-md-2">
                            <TextBox
                                label="Sql-Bedingung"
                                labelMode="floating"
                                stylingMode="outlined"
                                onValueChange={setSqlBedigung}
                                value={sqlBedingung}
                                valueChangeEvent="input"
                                maxLength={250}
                                onContentReady={handleContentReadyTextBox}
                                disabled={auftragserfassung}
                            />
                        </div>
                        <div className="col-12 col-md-6 p-1 p-md-2">
                            <TextBox
                                label="Freifeld1"
                                labelMode="floating"
                                stylingMode="outlined"
                                onValueChange={setFreifeld1}
                                value={freifeld1}
                                valueChangeEvent="input"
                                maxLength={60}
                                onContentReady={handleContentReadyTextBox}
                                disabled={auftragserfassung}
                            />
                        </div>
                        <div className="col-12 col-md-6 p-1 p-md-2">
                            <TextBox
                                label="Freifeld2"
                                labelMode="floating"
                                stylingMode="outlined"
                                onValueChange={setFreifeld2}
                                value={freifeld2}
                                valueChangeEvent="input"
                                maxLength={60}
                                onContentReady={handleContentReadyTextBox}
                                disabled={auftragserfassung}
                            />
                        </div>
                        <div className="col-12 col-md-6 p-1 p-md-2">
                            <TextBox
                                label="Freifeld3"
                                labelMode="floating"
                                stylingMode="outlined"
                                onValueChange={setFreifeld3}
                                value={freifeld3}
                                valueChangeEvent="input"
                                maxLength={60}
                                onContentReady={handleContentReadyTextBox}
                                disabled={auftragserfassung}
                            />
                        </div>
                        <div className="col-12 col-md-6 p-1 p-md-2">
                            <TextBox
                                label="Freifeld4"
                                labelMode="floating"
                                stylingMode="outlined"
                                onValueChange={setFreifeld4}
                                value={freifeld4}
                                valueChangeEvent="input"
                                maxLength={60}
                                onContentReady={handleContentReadyTextBox}
                                disabled={auftragserfassung}
                            />
                        </div>
                        <div className="col-12 col-md-6 p-1 p-md-2">
                            <TextBox
                                label="Freifeld5"
                                labelMode="floating"
                                stylingMode="outlined"
                                onValueChange={setFreifeld5}
                                value={freifeld5}
                                valueChangeEvent="input"
                                maxLength={60}
                                onContentReady={handleContentReadyTextBox}
                                disabled={auftragserfassung}
                            />
                        </div>
                        <div className="col-12 col-md-6 p-1 p-md-2">
                            <TextBox
                                label="Freifeld6"
                                labelMode="floating"
                                stylingMode="outlined"
                                onValueChange={setFreifeld6}
                                value={freifeld6}
                                valueChangeEvent="input"
                                maxLength={60}
                                onContentReady={handleContentReadyTextBox}
                                disabled={auftragserfassung}
                            />
                        </div>
                        <div className="col-12 col-md-6 p-1 p-md-2">
                            <TextBox
                                label="Passwort"
                                labelMode="floating"
                                stylingMode="outlined"
                                onValueChange={handlePasswortChange}
                                value={passwort}
                                mode="password"
                                valueChangeEvent="input"
                                maxLength={60}
                                onContentReady={handleContentReadyTextBox}
                                disabled={auftragserfassung}
                            />
                        </div>
                        <div className="col-12 col-md-6 p-1 p-md-2 d-flex">
                            <CheckBox
                                value={einmalPasswort}
                                onValueChange={setEinmalPasswort}
                                onContentReady={handleContentReadyCheckBox}
                                text={"Einmalpasswort"}
                                disabled={auftragserfassung}
                            />
                        </div>
                        <div className="col-12 col-md-6 p-1 p-md-2">
                            <DateBox
                                label='Datum der letzten Passwortänderung'
                                labelMode='floating'
                                stylingMode='outlined'
                                displayFormat={"dd.MM.yyyy"}
                                hint={"Passwort ist gültig ab diesem Datum"} // Oder machen wir hier tooltip
                                onValueChange={setPasswortDatum}
                                value={passwortDatum}
                                showClearButton={true}
                                useMaskBehavior={true}
                                onContentReady={handleContentReadyDateBox}
                                disabled={auftragserfassung}
                            // value={isAdd ? gueltigBis : (gueltigBis?.split('.')[1] + '.' + gueltigBis?.split('.')[0] + '.' + gueltigBis?.split('.')[2])}
                            //   valueChangeEvent="input"
                            />
                        </div>
                        <div className="col-12 col-md-6 p-1 p-md-2">
                            <SelectBox
                                dataSource={dataSourceGueltigkeit}
                                displayExpr="text"
                                valueExpr="value"
                                //acceptCustomValue={true}
                                value={gueltigskeitDauerValue}
                                //onCustomItemCreating={customItemCreating}
                                onValueChange={setGueltigskeitDauerValue}
                                label='Gültigkeitsdauer'
                                labelMode='floating'
                                stylingMode='outlined'
                                onContentReady={handleContentReadySelectBox}
                                disabled={auftragserfassung}
                            />
                        </div>
                        <div className="col-12 col-md-6  p-1 p-md-2">
                            <SelectBox
                                dataSource={dataSourceStatus}
                                displayExpr="text"
                                valueExpr="value"
                                //acceptCustomValue={true}
                                value={status}
                                //onCustomItemCreating={customItemCreating}
                                onValueChange={setStatus}
                                label='Status'
                                labelMode='floating'
                                stylingMode='outlined'
                                onContentReady={handleContentReadySelectBox}
                                disabled={auftragserfassung}
                            />
                        </div>
                        {
                            konfiguration.winowig_mail &&
                            <div className="col-12 col-md-6  p-1 p-md-2">
                                <SelectBox
                                    dataSource={dataSourceWinowigMail}
                                    displayExpr="text"
                                    valueExpr="value"
                                    //acceptCustomValue={true}
                                    value={winowigMail}
                                    //onCustomItemCreating={customItemCreating}
                                    onValueChange={setWinowigMail}
                                    label='Winowig Mail'
                                    labelMode='floating'
                                    stylingMode='outlined'
                                    onContentReady={handleContentReadySelectBox}
                                    disabled={auftragserfassung}
                                />
                            </div>
                        }
                        <div className="col-12  p-1 p-md-2">
                            <TagBox
                                dataSource={dataSoruceSBGruppenInfo}
                                displayExpr="f17002"
                                valueExpr="f17001"
                                //acceptCustomValue={true}
                                value={gruppenValue}
                                //onCustomItemCreating={customItemCreating}
                                onValueChange={setGruppenValue}
                                label='Gruppen'
                                labelMode='floating'
                                stylingMode='outlined'
                                disabled={auftragserfassung}
                            />
                        </div>
                    </div>
                </ScrollView>
            </Popup>
        </>
    )
}

export default SachbearbeiterVerwaltePopup;


const delete_sachbearbeiter = (dispatch: any, data: ISachbearbeiter, callback: any) => {
    dispatch(setConfirm({
        body:
            <div className="alert alert-danger">
                <p>Wollen Sie den Sachbearbeiter mit dem Namen <b>{data.f07002}</b> wirklich löschen?</p>
            </div>,
        on_ok: () => {
            GetData({
                Operation: "DeleteSachbearbeiter",
                Sachbearbeiternummer: data.f07001,
            }, dispatch).then((res) => {
                if (!res) return
                callback(true);
            })
        },
        title: "Sachbearbeiter löschen",
    }));
}