import { useCallback, useEffect, useRef, useState } from "react";
import TabPanel, { Item } from "devextreme-react/tab-panel";
import Orte from "./orte/orte";
import Bezirke from "./bezirke/bezirk";
import StrassenTab from "./strassen_tab/strassen";
import StrassenDetail from "./strassendetail/strasse_detail";

export interface ISelectedItemStrassen {
    lfdnr_ort?: number,
    lfdnr_bezirk?: number,
    lfdnr_strasse?: number,
    bezeichnung_ort?: string,
    bezeichnung_bezirk?: string,
    bezeichnung_strasse?: string,
}

const Strasse: React.FC = () => {
    const refTabPanel = useRef() as React.MutableRefObject<any>;

    /*
    const get_tab =() : number  =>{
        if(selectedItem.lfdnr_strasse){
            return 3;
        }else if (selectedItem.lfdnr_bezirk){
            return 2;
        } else if (selectedItem.lfdnr_ort){
                return 1;
        }else{
            return 0
        }; 
    }*/
    
    const [selectedItem, setSelectedItem] = useState<ISelectedItemStrassen>({})
    const [selectedIndexTabPanel, setSelectedIndexTabPanel] = useState(0);

    useEffect(() =>{
        if(selectedItem.lfdnr_strasse || selectedItem.lfdnr_strasse === 0){
            setSelectedIndexTabPanel(3);
         }else if (selectedItem.lfdnr_bezirk || selectedItem.lfdnr_bezirk === 0){
            setSelectedIndexTabPanel(2);
        } else if (selectedItem.lfdnr_ort || selectedItem.lfdnr_ort === 0){
           setSelectedIndexTabPanel(1);
       }else{
            setSelectedIndexTabPanel(0);
       }
    }, [selectedItem])

    const handleChangeSelectedItem = useCallback((value: number) => {
        if (value === 0) {
           setSelectedItem({});
        }else if (value === 1) {
            setSelectedItem({...selectedItem, lfdnr_bezirk: undefined, lfdnr_strasse: undefined, bezeichnung_bezirk: undefined, bezeichnung_strasse: undefined });
        }else if (value === 2) {
            setSelectedItem({...selectedItem, lfdnr_strasse: undefined, bezeichnung_strasse: undefined });
        }else{
            setSelectedItem({...selectedItem })
        }
    }, [selectedItem]);
/*
    useEffect(() => {
        //refTabPanel.current.instance.repaint();
    }, [selectedIndexTabPanel]);
*/
    return (
        <TabPanel ref={refTabPanel} animationEnabled={true} className="mt-1 mt-md-3 " selectedIndex={selectedIndexTabPanel} onSelectedIndexChange={handleChangeSelectedItem} >
            <Item title="Orte" ><Orte selectedItem={selectedItem} setSelectedItem={setSelectedItem}></Orte></Item>
            <Item title='Bezirke' disabled={selectedItem.lfdnr_ort !== undefined ? false : true}> <Bezirke selectedItem={selectedItem} setSelectedItem={setSelectedItem}/></Item>
            <Item title='Straßen' disabled={selectedItem.lfdnr_bezirk !== undefined ? false : true}><StrassenTab selectedItem={selectedItem} setSelectedItem={setSelectedItem}/></Item>
            <Item title='Straßendetails' disabled={selectedItem.lfdnr_strasse !== undefined ? false : true}><StrassenDetail selectedItem={selectedItem} setSelectedItem={setSelectedItem}/></Item>
        </TabPanel>
    )
}

export default Strasse;
