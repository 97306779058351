import { IGeraeteDaten } from "../interfaces";

const GetStatusInfo = (status: 0 | 1 | 2 | 20, gesperrtAb: string, gesperrtBis: string, datumLoeschung: string, uhrzeitLoeschung: string) => {
    let result = "";
    switch (status) {
        case 0:
            result = "Gerät betriebsbereit";
            break;
        case 1:
            result = "Gerät gespert vom " + gesperrtAb + " bis " + gesperrtBis;
            break;
        case 2:
            result = "Daten auf Gerät am " + datumLoeschung + " um " + uhrzeitLoeschung + " Uhr gelöscht und Gerät gesperrt";
            break;
        case 20:
            result = "Daten sollen gelöscht werden";
            break;
        default:
            break;
    }
    return result;
}

interface IInofrmationProps {
    item: IGeraeteDaten,
}
const Informationen: React.FC<IInofrmationProps> = ({item}) => {
    return(
        <div className="p-3 p-md-5 ">
            <p className="mb-2"><strong>Status:</strong>&nbsp;&nbsp;&nbsp;{GetStatusInfo(item.f005, item.f006,item.f007, item.f010,item.f011)}</p>
            <p className="mb-2"><strong>Gerätenummer:</strong>&nbsp;&nbsp;&nbsp;{item.f001}</p>
            <p className="mb-2"><strong>Gerätename:</strong>&nbsp;&nbsp;&nbsp;{item.f002}</p>
            <p className="mb-2"><strong>Modell:</strong>&nbsp;&nbsp;&nbsp;{item.f012}</p>
            {item.clientversion && (<><p className="mb-2"><strong>Clientversion:</strong>&nbsp;&nbsp;&nbsp;{item.clientversion}</p></>)}
            <p className="mb-2"><strong>Datum letzte Kommunikation:</strong>&nbsp;&nbsp;&nbsp;{item.f003}</p>
            <p className="mb-2"><strong>Letzter Gerätebenutzer:</strong>&nbsp;&nbsp;&nbsp;{item.f004}</p>
        </div>
    )
}


export default Informationen;
