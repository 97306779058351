import { DateBox, LoadPanel, SelectBox } from 'devextreme-react';
import {
    Chart,
    Series,
    Legend,
    Tooltip
} from 'devextreme-react/chart';
import ArrayStore from 'devextreme/data/array_store';
import { useCallback, useEffect, useState } from "react";
import { GetData } from '../../../../global/communication';
import { get_date_ajax_param, handleContentReadyDateBox, handleContentReadySelectBox } from "../../../../global/hilfs_functions";
import { useAppDispatch } from '../../../../state';


interface IAkkuProps {
    geraet_nummer: string,
}


const legendClickHandler = (e: any) => {
    const series = e.target;
    if (series.isVisible()) {
        series.hide();
    } else {
        series.show();
    }
}
const arr_items = new ArrayStore({
    data: [
        { text: "Monat", value: 1 },
        { text: "Woche", value: 2 },
        { text: "Tag", value: 3 },
    ],
    key: 'value',
});

const Akku: React.FC<IAkkuProps> = ({ geraet_nummer }) => {
    const [zeitraum, setZeitraum] = useState(1);
    const [dateValue, setDateValue] = useState();
    const [isloading, setIsloading] = useState(true);
    const [data, setData] = useState<{ datum: string, min: number, max: number }[]>([]);
    const dispatch = useAppDispatch();

    useEffect(() => {
        get_data();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [zeitraum, dateValue]);



    const get_data = useCallback(() => {
        GetData({
            Operation: "GetAkkuladung",
            Geraetenummer: geraet_nummer,
            Zeitraum: zeitraum,
            Datum: get_date_ajax_param(dateValue),
        }, dispatch)
            .then((res) => {
                if (!res) {
                    setData([])
                    setIsloading(false);
                    return
                }
                setData(res.data)
                setIsloading(false);
            });
    }, [geraet_nummer, zeitraum, dateValue, dispatch]);


    const handleDateChange = useCallback((e: any) => {
        setDateValue(e.value)
    }, []);


    return (
        isloading ? <LoadPanel visible={true} /> :
            <div className="p-3 p-md-5 ">
                <div className='row'>
                    <div className='col-12'>
                        <div className='d-flex justify-content-around'>
                            <SelectBox
                                dataSource={arr_items}
                                displayExpr="text"
                                valueExpr="value"
                                value={zeitraum}
                                onValueChange={setZeitraum}
                                label='Zeitraum'
                                width={"auto"}
                                labelMode='floating'
                                stylingMode='outlined'
                                onContentReady={handleContentReadySelectBox}
                            />
                            <DateBox
                                value={dateValue}
                                onValueChanged={handleDateChange}
                                displayFormat={"dd.MM.yyyy"}
                                showClearButton={true}
                                useMaskBehavior={true}
                                label='Datum bis:'
                                labelMode='floating'
                                width={"auto"}
                                stylingMode='outlined'
                                onContentReady={handleContentReadyDateBox}
                            />
                        </div>
                    </div>
                    <div className='col-12 mt-2 mt-md-4'>
                        <Chart
                            dataSource={data}
                            onLegendClick={legendClickHandler}
                            //  elementAttr={elementAttrChart}
                            onDrawn={handleDrawn}
                        >
                            <Series argumentField="datum" valueField="max" name="Max" />
                            <Series argumentField="datum" valueField="min" name="Min" />
                            <Legend
                                verticalAlignment="bottom"
                                horizontalAlignment="center"
                                itemTextPosition="bottom"
                            />
                            <Tooltip enabled={true} />
                        </Chart>
                        <table className="sr-only">
                            <thead>
                                <tr>
                                    <th scope="col">Datum</th>
                                    <th scope="col">Min</th>
                                    <th scope="col">Max</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((item) => {
                                    return (
                                        <tr key={item.datum}>
                                            <td>{item.datum}</td>
                                            <td>{item.min}</td>
                                            <td>{item.max}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
    )
}


export default Akku;

const handleDrawn = (e: any) => {
    let ele = e.element as HTMLElement;
    if (!e.component._MD_Init) {
        let svg = ele.querySelector("svg");
        if (svg) {
            svg.setAttribute("role", "img");
            svg.setAttribute("aria-label", "Nach diesem Diagramm befindet sich eine Tabelle. Diese Tabelle speigelt die Daten des Diagrammes wider.");
        }
        e.component._MD_Init = true;
    }

    ele.querySelectorAll("text").forEach((item) => {
        item.setAttribute("aria-hidden", "true");
    });
}