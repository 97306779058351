
import { memo, useCallback, useState } from "react";
import { icon_plus } from "../../../../global/const";
import { MDButton } from "../../../../global/components";
import HauptGruppeVerwaltePopup from "./verwalten_hauptgruppe";

interface IHauptGruppenAddProps {
    get_data: () => void,
}


const HauptGruppenAdd: React.FC<IHauptGruppenAddProps> = ({ get_data }): JSX.Element => {
    const [showPopUp, setShowPopUp] = useState(false);

    const handleHidePopup = useCallback((update?: boolean) => {
        setShowPopUp(false);
        update && get_data()
    }, [get_data]);
    const handleShowPopup = useCallback(() => {
        setShowPopUp(true);
    }, []);
    return (
        <div>
            <MDButton
                page="tatbestand_kategorie"
                icon={icon_plus}
                type="success"
                tooltip_text='Neue Hauptgruppe anlegen'
                onClick={handleShowPopup}
            />
            {showPopUp && <HauptGruppeVerwaltePopup callback={handleHidePopup} />}
        </div>
    )
}

export default memo(HauptGruppenAdd);