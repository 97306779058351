
import { Column, Toolbar, Item as ItemGridToolbar } from "devextreme-react/data-grid";
import { DataGrid } from "devextreme-react";
import { useCallback, useEffect, useRef, useState } from "react";
import React from 'react';
import { druck_layouts_columns } from "../../../../sonstiges/columns";
import { exportGridAsPDF, handleContentReadyDataGrid } from "../../../../global/hilfs_functions";
import ImportDrucker from "../add";
import LayoutBearbeitePopup from "./layout_bearbeite";
import { IDrucklayout } from "../../interfaces";
import { GetData } from "../../../../global/communication";
import { useAppDispatch } from "../../../../state";
import { MDButton } from "../../../../global/components";
import { icon_import, icon_pdf, scroll_mode_infinite } from "../../../../global/const";



const columns = druck_layouts_columns
const Drucklayouts = () => {
    const [data, setData] = useState<IDrucklayout[]>([]);
    const refGrid = useRef() as React.MutableRefObject<DataGrid<any, any>>;
    const [showImportPopup, setShowImportPopup] = useState(false);
    const [bearbeitePopUp, setBearbeitePopup] = useState<{ show: boolean, row: IDrucklayout | null }>({
        show: false,
        row: null,
    });
    const dispatch = useAppDispatch();


    useEffect(() => {
        get_data();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const get_data = useCallback(() => {
        GetData({
            Operation: "GetDrucklayouts",
        }, dispatch).then((res) => {
            if (!res) {
                setData([]);
                return
            }
            setData(res.data)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleCallBackBearbeiten = useCallback(() => {
        get_data();
        setBearbeitePopup({ show: false, row: null })
    }, [get_data]);

    const handleHideImportPopup = useCallback(() => {
        setShowImportPopup(false)
    }, []);
    const handleShowImportPopup = useCallback(() => {
        setShowImportPopup(true)
    }, []);

    const handleBearbeite = useCallback(({ data }: any) => {
        setBearbeitePopup({ show: true, row: data })
    }, []);

    const handlePDF = useCallback(() => {
        exportGridAsPDF(refGrid, "Layouts.pdf");
    }, []);
    return (<>
        <div className="data_grid_container data_grid_with_header mt-1 mt-md-3">
            {bearbeitePopUp.show && <LayoutBearbeitePopup row={bearbeitePopUp.row!} setShowPopUp={handleCallBackBearbeiten} />}
            {showImportPopup && <ImportDrucker bild={false} callback={handleHideImportPopup} />}
            <DataGrid
                dataSource={data}
                columnAutoWidth={false}
                keyExpr="druckermodell"
                rowAlternationEnabled={true}
                wordWrapEnabled={true}
                renderAsync={true}
                ref={refGrid}
                scrolling={scroll_mode_infinite}
                onRowClick={handleBearbeite}
                hoverStateEnabled={true}
                className="cursor_pointer"
                noDataText="Keine Drucklayouts"
                onContentReady={handleContentReadyDataGrid}
            >
                {columns.map((column) => {
                    return (
                        <Column
                            dataField={column.dataField}
                            caption={column.caption}
                            dataType={column.dataType}
                            alignment={column.alignment}
                            cellRender={column.cellRender}
                            key={column.dataField}
                            visible={column.visible}
                        />
                    )
                })}
                <Toolbar>
                    <ItemGridToolbar location="after" locateInMenu="auto">
                        {/** <ImportDrucker bild={false} />*/}
                        <MDButton
                            icon={icon_import}
                            tooltip_text={'Drucklayout importieren'}
                            onClick={handleShowImportPopup}
                            page="layouts_bilder"
                        />
                    </ItemGridToolbar>
                    <ItemGridToolbar location="after" locateInMenu="auto">
                        <MDButton
                            icon={icon_pdf}
                            onClick={handlePDF}
                            tooltip_text="Tabelle als PDF herunterladen"
                            page="layouts_bilder"
                        />
                    </ItemGridToolbar>
                </Toolbar>
            </DataGrid>
        </div>
        {/**
         * 
         * 
         *   <div>
            <Toolbar>
                <ItemToolBar
                    location={'before'}
                    text={"Drucklayouts"}
                />
            </Toolbar>
            <TabPanel >
                <ItemTabPanel title="Layouts">
                    <ScrollView className="mt-2 mt-md-4">
                        <DataGrid
                            dataSource={layouts_layouts}
                            columnAutoWidth={true}
                            onRowClick={({ data, handled, event }: any) => { handleClickRow(data, handled, event) }}
                            ref={dataGridRef}
                            onRowRemoved={(data: any) => { console.log(data)/* sende Anfrage zu Server }}
                            className='drucklayouts_layouts_data_grid'
                            hoverStateEnabled={true}
                        >
                            <Column dataField="druckermodell" caption="Druckermodell" />
                            <Column dataField="dateiname" caption="Dateiname" />
                        </DataGrid>
                    </ScrollView>
                </ItemTabPanel>
                <ItemTabPanel title="Bilder">
                    <ScrollView className="mt-2 mt-md-4">
                        <DataGrid
                            dataSource={layouts_bilder}
                            columnAutoWidth={true}
                            onRowClick={({ data, handled, event }: any) => { handleClickRow(data, handled, event) }}
                            ref={dataGridRef}
                            onRowRemoved={(data: any) => { console.log(data)/* sende Anfrage zu Server }}
                            className='drucklayouts_bilder_data_grid'
                            hoverStateEnabled={true}
                        >
                            <Column dataField="druckermodell" caption="Druckermodell" />
                            <Column dataField="dateiname" caption="Dateiname" />
                        </DataGrid>
                    </ScrollView>
                </ItemTabPanel>
            </TabPanel > 
            <Toolbar>
                <ItemGridToolbar>
                    <Button
                        icon="fa fa-plus"
                        text='Drucklayout importieren'
                        type="normal"
                        stylingMode="text"
                        hint="Neues Drucklayout importieren"
                    />
                    <Button
                        icon="fa fa-plus"
                        text='Bild importieren'
                        type="normal"
                        stylingMode="text"
                        hint="Neues Bild importieren"
                    />
                </ItemGridToolbar>
            </Toolbar>
        </div>)
        {DrucklayoutPopupDaten.visible && <DrucklayoutPopup callBack={callBackPopUp}/>}
         * 
         * 
         */}

    </>)
}

export default Drucklayouts;