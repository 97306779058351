import { Popup } from "devextreme-react";
import { ToolbarItem } from "devextreme-react/autocomplete";
import { useState, memo, useCallback, useMemo } from "react";
import { GetData } from "../../../../../global/communication";
import { icon_pdf } from "../../../../../global/const";
import { useScreenSizeClass } from "../../../../../global/media-query";
import { useAppDispatch, useAppSelector } from "../../../../../state";
import { MDButton } from "../../../../../global/components";


interface IFallSpeichernProps {
    lfdnr: number,
    zsu: string,
    aktzenzeichen: string,
    match_code: string,
    f007: string
}
const FallSpeichern: React.FC<IFallSpeichernProps> = ({ lfdnr, aktzenzeichen, match_code, zsu, f007 }) => {
    const konfiguration = useAppSelector((state) => state.store_einstellungen.konfiguration);
    const dispatch = useAppDispatch();
    const [showPopup, setShowPopup] = useState(false);
    const screen = useScreenSizeClass();
    //MD 24.05.2023 f008 reicht nicht. f007 auch dazu prüfen
    let fall_pdf_uri = "/FileRequest/Fallprotokoll_" + aktzenzeichen + "_" + match_code + ".pdf?Operation=CreateFallProtokoll&Zsu=" + zsu + "&f007=" + f007;

    const handleClickPDF = useCallback(() => {
        GetData({ Operation: "AenderungenVorhanden", zsu: zsu, f007: f007, lfdnr: lfdnr }, dispatch)
            .then((res) => {
                if (!res) return
                res.Aenderungen ?
                    setShowPopup(true)
                    :
                    window.open(fall_pdf_uri, '_blank');
            });

    }, [dispatch, zsu, lfdnr, fall_pdf_uri, f007])

    const handleClickDiesenFall = useCallback(() => {
        setShowPopup(false);
        //  fall_pdf_uri += "&Lfdnr=" + lfdnr;
        window.open(fall_pdf_uri + "&Lfdnr=" + lfdnr, '_blank');
    }, [lfdnr, fall_pdf_uri]);

    const handleClickNeuestenFall = useCallback(() => {
        setShowPopup(false);
        window.open(fall_pdf_uri, '_blank');
    }, [fall_pdf_uri])

    const handleHidePopup = useCallback(() => {
        setShowPopup(false)
    }, []);

    const memo_options_neuer_Fall = useMemo(() => {
        return {
            text: 'Neuesten Fall',
            onClick: handleClickNeuestenFall,
        }
    }, [handleClickNeuestenFall]);

    const memo_options_diesen_Fall = useMemo(() => {
        return {
            text: 'Diesen Fall',
            onClick: handleClickDiesenFall,
        }
    }, [handleClickDiesenFall]);

    const memo_options_zurueck = useMemo(() => {
        return {
            text: 'Zurück',
            onClick: handleHidePopup,
        }
    }, [handleHidePopup]);
    return (
        konfiguration.fall_protokoll_anzeigen ?
            <>  <li>
                <MDButton
                    icon={icon_pdf}
                    tooltip_text="Fall als PDF speichern"
                    page="fall_card"
                    onClick={handleClickPDF}
                /></li>
                <Popup
                    visible={showPopup}
                    onHiding={handleHidePopup}
                    dragEnabled={true}
                    hideOnOutsideClick={true}
                    fullScreen={false}
                    showCloseButton={true}
                    maxHeight="250px"
                    maxWidth="500px"
                >
                    <ToolbarItem
                        toolbar="top"
                        location={'before'}
                        render={RenderePopupTitle}
                    />
                    <ToolbarItem
                        widget="dxButton"
                        toolbar="bottom"
                        location="before"
                        options={memo_options_neuer_Fall}
                    />
                    <ToolbarItem
                        widget="dxButton"
                        toolbar="bottom"
                        location={(screen !== 'sm' && screen !== 'md') ? "before" : "after"}
                        options={memo_options_diesen_Fall}
                    />
                    {
                        (screen !== 'sm' && screen !== 'md') &&
                        <ToolbarItem
                            widget="dxButton"
                            toolbar="bottom"
                            location="after"
                            options={memo_options_zurueck}
                        />
                    }
                    <div className="row ">
                        <p>Zu diesem Fall sind Änderungen vorhanden. Möchten Sie diesen Fall oder den neuesten Fall drucken?</p>
                    </div>
                </Popup>
            </>

            : <></>
    );
}
export default memo(FallSpeichern);
const RenderePopupTitle = () => {
    return (<h2 className="title_popup">Änderungen vorhanden</h2>)
};
