
import { DataGrid, TextBox } from "devextreme-react";
import { Column, Toolbar, Item as ItemGridToolbar, Scrolling, Paging, } from "devextreme-react/data-grid";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { exportGridAsPDF, handleContentReadyDataGrid, handleContentReadyTextBox } from "../../../global/hilfs_functions";
import StammdatenAdd from "./add";
import StammDataVerwaltePopup from "./verwalten_popup";
import SatzartAddLoeschen from "./satzart_add_loeschen";
import SatzartFilter from "./satzart_filter";
import { IStammdatenData, IStammdatenSatzartData } from "./interfaces";
import { GetData } from "../../../global/communication";
import CustomStore from "devextreme/data/custom_store";
import { icon_pdf, MAX_ITEMS_DATA_GRID } from "../../../global/const";
import { useAppDispatch } from "../../../state";
import { RendereGridTitleStammdaten, get_json_object_in_arr } from "../../../sonstiges/hilfs_functions";
import { MDButton } from "../../../global/components";
import { Seperatorliste } from "../../../global/seperator_liste";



const get_satzart_bezeichnung = (value: number, satzarte: IStammdatenSatzartData[]): string => {

  for (let i of satzarte) {
    if (parseInt((i.satzart).toString()) === parseInt((value).toString())) {
      return i.bezeichnung;
    }
  }
  return "";
}
/*
export const stammdaten_columns = (satzarte: IStammdatenSatzartData[]): IColumn[] => {
  return [
    {
      dataField: "f001",
      caption: "Satzart",
      alignment: "left",
      /*
      cellRender: (item) => {
        return <>{
          get_satzart_bezeichnung(item.value, satzarte)}</>
      }
    }, {
  dataField: "f004",
    alignment: "left",
      caption: "Bezeichnung",
    }
  ]
}*/

const Stammdaten: React.FC = (): JSX.Element => {
  const [satzarte, setSatzarte] = useState<IStammdatenSatzartData[]>([]);
  // const columns: IColumn[] = useMemo(() => satzarte.length > 0 ? stammdaten_columns(satzarte) : [], [satzarte]);
  const [bearbeitePopUp, setBearbeitePopup] = useState<{ show: boolean, row: IStammdatenData | null }>({
    show: false,
    row: null,
  });
  const refGrid = useRef() as React.MutableRefObject<DataGrid<any, any>>;
  const [searchValue, setSearchValue] = useState("");
  const [kategorie, setKategorie] = useState(0);
  const filter = useMemo(() => get_stamm_daten_filter(searchValue, kategorie), [searchValue, kategorie]);
  const store_end = useMemo(() => {
    // Wenn columns leer ist danach geladen wird dann store nochmal von Grid aufgerufen
    if (satzarte.length > 0) {
      return store(filter)
    } else {
      return []
    }
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [satzarte, filter]);
  const dispatch = useAppDispatch();

  useEffect(() => {
    get_data();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const get_data = useCallback(() => {
    setKategorie(0);
    setSearchValue("");
    refGrid && refGrid.current && refGrid.current.instance.clearSorting();

    GetData({
      Operation: "GetStammdatenSatzart",
      NurTatauswahlen: "Nein",
    }, dispatch).then((res) => {
      if (!res) return
      //MD 11.08.2023 mal alphabetisch sortieren
      setSatzarte(res.data.sort((a: IStammdatenSatzartData, b: IStammdatenSatzartData) => a.bezeichnung.localeCompare(b.bezeichnung)));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const refresh_grid = useCallback(() => {
    refGrid && refGrid.current && refGrid.current.instance.refresh();
  }, []);

  const handleCallBackBearbeite = useCallback((update?: boolean) => {
    setBearbeitePopup({ show: false, row: null });
    update && refresh_grid()
  }, [refresh_grid]);

  const handleRowClick = useCallback(({ data }: any) => {
    setBearbeitePopup({ show: true, row: data })
  }, []);

  const handleClickPDF = useCallback(() => {
    let header = get_header_pdf_stammdaten(satzarte, searchValue, kategorie);
    exportGridAsPDF(refGrid, "Stammdaten.pdf", header, "landscape", "striped", (cellData: any) => {
      if (cellData.gridCell.column.dataField === 'f001' && satzarte && satzarte.length > 0 && cellData.gridCell.rowType === 'data') {
        let tmp_value = get_json_object_in_arr(satzarte, "satzart", cellData.pdfCell.text);
        cellData.pdfCell.text = tmp_value ? tmp_value.bezeichnung : cellData.pdfCell.text;
      }
    })
  }, [satzarte, searchValue, kategorie]);

  const GetCustomValue = useCallback((props: any) => {
    let value = props.value;
    return get_satzart_bezeichnung(value, satzarte);
  }, [satzarte]);

  return (
    <>
      {bearbeitePopUp.show && <StammDataVerwaltePopup satzarte={satzarte} row={bearbeitePopUp.row} callback={handleCallBackBearbeite} />}
      <div className="data_grid_container mt-2 mt-md-3">
        <DataGrid
          dataSource={store_end}
          allowColumnResizing={true}
          columnAutoWidth={false}
          renderAsync={true}
          // rowAlternationEnabled={true}
          focusedRowEnabled={false}
          ref={refGrid}
          className=" animate__animated animate__fadeIn cursor_pointer"
          remoteOperations={true}
          rowAlternationEnabled={true}
          wordWrapEnabled={true}
          onRowClick={handleRowClick}
          hoverStateEnabled={true}
          noDataText="Keine Stammdaten"
          onContentReady={handleContentReadyDataGrid}
        >
          <Scrolling mode="virtual" rowRenderingMode="virtual" preloadEnabled={true} />
          <Paging defaultPageSize={MAX_ITEMS_DATA_GRID} />
          {/*
            columns.map((column) => {
              return (
                <Column
                  dataField={column.dataField}
                  caption={column.caption}
                  dataType={column.dataType}
                  alignment={column.alignment}
                  //  cellRender={column.cellRender} Sonst wird dies sehr oft beim scrollen z.b. neu gerendert. Wegen satzarte
                  customizeText={GetCustomValue}
                  key={column.dataField}
                                visible={column.visible}
                />
              )
            })*/
          }
          <Column
            dataField="f001"
            caption="Satzart"
            alignment="left"
            customizeText={GetCustomValue}
          />
          <Column
            dataField="f004"
            alignment="left"
            caption="Bezeichnung"
          />
          <Toolbar>
            <ItemGridToolbar
              location={'before'}
              render={RendereGridTitleStammdaten}
            ></ItemGridToolbar>
            <ItemGridToolbar
              location={'after'}
              locateInMenu="never"
            >
              <TextBox
                label="Suche"
                stylingMode='outlined'
                mode='text'
                className="suche_feld"
                value={searchValue}
                onValueChange={setSearchValue}
                showClearButton={true}
                onContentReady={handleContentReadyTextBox}
                maxLength={60}>
              </TextBox>
            </ItemGridToolbar>
            <ItemGridToolbar location="after" locateInMenu="auto">
              <StammdatenAdd satzarte={satzarte} callback={refresh_grid} />
            </ItemGridToolbar>
            <ItemGridToolbar location="after" locateInMenu="auto">
              <SatzartAddLoeschen get_data={get_data} />
            </ItemGridToolbar>
            <ItemGridToolbar location="after" locateInMenu="auto">
              <SatzartFilter satzarte={satzarte} setKategorie={setKategorie} />
            </ItemGridToolbar>
            <ItemGridToolbar location="after" locateInMenu="auto">
              <MDButton
                icon={icon_pdf}
                page="stammdaten"
                tooltip_text="Tabelle als PDF herunterladen"
                onClick={handleClickPDF}
              />
            </ItemGridToolbar>
          </Toolbar>
        </DataGrid></div></>
  )
}

export default Stammdaten;



const store = (filter: string) => {
  return new CustomStore({
    key: ["f001"],
    load(loadOptions: any) {

      //Achtung isLoadingAll => pdf Download
      return GetData(
        {
          Operation: "GetStammdaten",
          skip: (loadOptions.isLoadingAll || loadOptions.requireTotalCount) ? "0" : loadOptions["skip"].toString(),
          take: loadOptions["isLoadingAll"] ? "1000" : loadOptions["take"].toString(),
          filter: filter ? filter : "",
          sortSpalte: loadOptions.sort ? loadOptions.sort[0].selector : "",
          sortRichtung: loadOptions.sort ? loadOptions.sort[0].desc ? "DESC" : "ASC" : "",

        }, undefined, false
      )
        .then((res) => {
          if (!res || !res.erfolg)
            return {
              data: [],
              totalCount: 0,
            }


          //  if(totalCount === 0){
          //    setTotalCount(res.totalCount)
          //  }else{
          //  totalCount !== parseInt(res.totalCount) && setTotalCount(res.totalCount)
          // }
          return {
            //   data: res.data,
            data: res.data,
            totalCount: res.totalCount,
          }

        });
      //   }


      //  }
    }

  });
}


const get_header_pdf_stammdaten = (satzarte: IStammdatenSatzartData[], searchValue: string, kategorie: number) => {
  let header = "";
  searchValue && (header += "Suchbegriff: " + searchValue + "\n");
  let value = get_json_object_in_arr(satzarte, "satzart", kategorie);
  if (value !== undefined) {
    value = get_json_object_in_arr(satzarte, "satzart", kategorie).bezeichnung
  } else {
    value = kategorie.toString()
  }
  (kategorie && kategorie !== 0) && (header += "Kategorie: " + value + "\n")
  return header
}

const get_stamm_daten_filter = (seachValue: string, kategorie: number) => {
  let filter_end = new Seperatorliste("%00", "%01");
  seachValue && filter_end.setValue("Suchbegriff", seachValue);
  (kategorie && kategorie !== 0) && filter_end.setValue("Kategorie", kategorie.toString());
  return filter_end.getSeperatorliste();
}