import { DateBox } from "devextreme-react";
import { useCallback, useState } from "react";
import { GetData } from "../../../../global/communication";
import { icon_delete } from "../../../../global/const";
import { get_date_ajax_param, handleContentReadyDateBox } from "../../../../global/hilfs_functions";
import { setToast, useAppDispatch } from "../../../../state";
import { MDButton } from "../../../../global/components";
import { IGeraeteDaten } from "../interfaces";



interface IVerwaltungProps {
    item: IGeraeteDaten,
}
const Verwaltung: React.FC<IVerwaltungProps> = ({ item }) => {
    const [isGesperrt, setIsGesperrt] = useState(item.f005 !== 0);
    const [geraetSperrenAbValue, setGeraetSperrenAbValue] = useState();
    const [geraetSperrenBisValue, setGeraetSperrenBisValue] = useState();
    const dispatch = useAppDispatch();

    const handleClickSperren = useCallback(() => {
        if (!geraetSperrenAbValue || !geraetSperrenBisValue || geraetSperrenAbValue > geraetSperrenBisValue) {
            dispatch(setToast({
                message: "Bitte einen gültigen Zeitraum eingeben.",
                type: "error"
            }));
        } else {
            GetData({
                Operation: "SetGeraetestatusToGesperrt",
                Geraetenummer: item.f001,
                DatumVon: get_date_ajax_param(geraetSperrenAbValue),
                DatumBis: get_date_ajax_param(geraetSperrenBisValue),
            }, dispatch)
                .then((res) => {
                    if (!res) return
                    setIsGesperrt(true);
                });
        }
    }, [geraetSperrenAbValue, geraetSperrenBisValue, dispatch, item.f001]);

    const handleClickEntsperren = useCallback(() => {
        GetData({
            Operation: "SetGeraetestatusToAktiviert",
            Geraetenummer: item.f001,
        }, dispatch)
            .then((res) => {
                if (!res) return
                setIsGesperrt(false);
            });

    }, [item.f001, dispatch]);

    const handleClickDatenLoeschen = useCallback(() => {
        GetData({
            Operation: "SetGeraetestatusToLoeschen",
            Geraetenummer: item.f001,
        }, dispatch)
            .then((res) => {
                if (!res) return
                setIsGesperrt(true);
            });

    }, [item.f001, dispatch]);

    const handleClickGeraetLoeschen = useCallback(() => {
        GetData({
            Operation: "DeleteGeraet",
            Geraetenummer: item.f001,
        }, dispatch)
            .then((res) => {
                if (!res) return

                setIsGesperrt(true);
                dispatch(setToast({
                    message: "Das Gerät wurde erfolgreich aus der Geräteverwaltung entfernt",
                    type: "success"
                }));

            });

    }, [item.f001, dispatch]);


    const handleSperrenAbVAlue = useCallback((e: any) => {
        setGeraetSperrenAbValue(e.value)
    }, []);
    const handleSperrenBisVAlue = useCallback((e: any) => {
        setGeraetSperrenBisValue(e.value)
    }, []);

    return (
        <div className="row p-3 p-md-5">
            {
                !isGesperrt ?
                    <div className="col-12">
                        <p style={{ fontSize: "large" }}><strong>Gerät sperren:</strong></p>
                        <div className="row">
                            <div className="col-12 col-md-6 text-center mt-3">
                                <DateBox
                                    value={geraetSperrenAbValue}
                                    onValueChanged={handleSperrenAbVAlue}
                                    displayFormat={"dd.MM.yyyy"}
                                    showClearButton={true}
                                    useMaskBehavior={true}
                                    label='Sperren ab:'
                                    labelMode='floating'
                                    stylingMode='outlined'
                                    onContentReady={handleContentReadyDateBox}
                                />
                            </div>
                            <div className="col-12 col-md-6 text-center mt-3">
                                <DateBox
                                    value={geraetSperrenBisValue}
                                    onValueChanged={handleSperrenBisVAlue}
                                    displayFormat={"dd.MM.yyyy"}
                                    showClearButton={true}
                                    useMaskBehavior={true}
                                    label='Sperren bis einschließlich:'
                                    labelMode='floating'
                                    stylingMode='outlined'
                                    onContentReady={handleContentReadyDateBox}
                                />
                            </div>
                        </div>
                        <div className="d-flex justify-content-center">
                            <MDButton
                                button_text="sperren"
                                onClick={handleClickSperren}
                                page='geraete_verwaltung'
                                type="danger"
                                stylingMode="outlined"
                                className='mt-4'
                            />
                        </div>
                    </div>
                    :
                    <div className="col-12 text-center">
                        <MDButton
                            button_text="entsperren"
                            onClick={handleClickEntsperren}
                            page='geraete_verwaltung'
                            stylingMode="outlined"
                            type="success"
                            className='mt-4'
                        />
                    </div>
            }
            <div className="space100"></div>
            <div className="row">
                <div className="col-12 col-sm-11 col-md-6">
                    <div className="d-flex">
                        <p className="d-inline mr-auto"><strong>Alle Daten auf dem Gerät löschen:</strong></p>
                        <MDButton
                            icon={icon_delete}
                            onClick={handleClickDatenLoeschen}
                            page='geraete_verwaltung'
                            type="danger"
                            tooltip_text='Gerätstatus auf "Daten sollen gelöscht werden" setzen'
                        /><br />
                    </div>
                    <div className="d-flex mt-3">
                        <p className="d-inline mr-auto"><strong>Gerät löschen:</strong></p>
                        <MDButton
                            icon={icon_delete}
                            onClick={handleClickGeraetLoeschen}
                            page='geraete_verwaltung'
                            type="danger"
                            tooltip_text='Gerät löschen'
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}


export default Verwaltung;
