import { Popup, ScrollView } from "devextreme-react"
import { ToolbarItem } from "devextreme-react/autocomplete"
import TabPanel, { Item } from "devextreme-react/tab-panel";
import { useCallback, useMemo, useState } from "react";
import GrundDatenTab from "./grund_daten_tab";
import KategorieTab from "./kategorie_tab";
import MobilDatenTab from "./mobil_tab";
import { ITatbestandData } from "./interfaces";
import { useScreenSize } from "../../../global/media-query";
import { GetData, GetDataAsync } from "../../../global/communication";
import { setAlert, setConfirm, setToast, useAppDispatch } from "../../../state";
import { get_date_ajax_param } from "../../../global/hilfs_functions";
import { Seperatorliste } from "../../../global/seperator_liste";


export interface ITatbestandVerwaltePopupProps {
    callback?: (update?: boolean) => void,
    row?: ITatbestandData | null,
    setData?: (value: ITatbestandData) => void,
    data?: ITatbestandData,
    isAdd?: boolean,
}

const get_default_data = (row?: ITatbestandData): ITatbestandData => {
    if (!row) {
        return {
            f02001: "",
            f02002: "",
            f02007: 0,
            f02008: "",
            f02009: 0,
            f02010: "",
            f02011: 0,
            f02017: get_date_ajax_param(new Date()),
            f02018: get_date_ajax_param(new Date(2099, 12, 31)),
            f02019: "0",
            f02023: "",
            f02025: 0,
            f02030: 0,
            f02040: "0",
            f02041: "",
            f02042: "",
            f02047: "",
            f02050: 0,
            f02051: 0,
            f02100: 0,
            f02101: 0,
            f02102: 0,
            f02103: 0,
            f02104: 0,
            f02107: 0,
            f02108: 0,
            f02109: 0,
            f02110: 0,
            f02111: 0,
            kategorien: ""
        }
    } else {
        return {
            f02001: row.f02001,
            f02002: row.f02002,
            f02007: row.f02007,
            f02008: row.f02008,
            f02009: row.f02009,
            f02010: row.f02010,
            f02011: row.f02011,
            f02017: row.f02017,
            f02018: row.f02018,
            f02019: row.f02019,
            f02023: row.f02023,
            f02025: row.f02025,
            f02030: row.f02030,
            f02040: row.f02040,
            f02041: row.f02041,
            f02042: row.f02042,
            f02047: row.f02047,
            f02050: row.f02050,
            f02051: row.f02051,
            f02100: row.f02100,
            f02101: row.f02101,
            f02102: row.f02102,
            f02103: row.f02103,
            f02104: row.f02104,
            f02107: row.f02107,
            f02108: row.f02108,
            f02109: row.f02109,
            f02110: row.f02110,
            f02111: row.f02111,
            kategorien: row.kategorien,
        }
    }
}

const TatbestandVerwaltePopup: React.FC<ITatbestandVerwaltePopupProps> = ({ callback, row }): JSX.Element => {

    const isAdd = (row === undefined) || (row === null);
    const screenSize = useScreenSize();
    const dispatch = useAppDispatch();
    const [data, setData] = useState<ITatbestandData>(get_default_data(!isAdd ? row : undefined));

    const handleClickSpeichern = useCallback(async () => {
        if (!data.f02001) {
            dispatch(setToast({
                message: "Tatbestandnummer muss vorhanden sein.",
                type: "error"
            }));
            return
        }
        if (!data.f02002) {
            dispatch(setToast({
                message: "Tatbestandstext muss vorhanden sein.",
                type: "error"
            }));
            return
        }
        if ((!data.f02007) && (data.f02007 !== 0)) {
            dispatch(setToast({
                message: "Betrag muss vorhanden sein.",
                type: "error"
            }));
            return
        }
        if ((!data.f02011) && (data.f02011 !== 0)) {
            dispatch(setToast({
                message: "Fahrverbot muss vorhanden sein.",
                type: "error"
            }));
            return
        }
        if (!data.f02017) {
            dispatch(setToast({
                message: "Gültig ab muss vorhanden sein.",
                type: "error"
            }));
            return
        }
        if (!data.f02018) {
            dispatch(setToast({
                message: "Gültig bis muss vorhanden sein.",
                type: "error"
            }));
            return
        }



        let daten = new Seperatorliste("%00", "%01");

        daten.setValue("Tatbestandstext", data.f02002.trim());
        daten.setValue("Betrag", data.f02007.toString());
        daten.setValue("Punkte", data.f02009.toString());
        daten.setValue("Fahrverbot", data.f02011.toString());
        daten.setValue("DatumVon", data.f02017);
        daten.setValue("DatumBis", data.f02018);
        daten.setValue("Tatbestandstyp", data.f02040);
        daten.setValue("Kategorien", data.kategorien);
        daten.setValue("Winowigmobil", data.f02030.toString());
        daten.setValue("Ventilstellung", data.f02100.toString());
        daten.setValue("KonkretisierungBehinderung", data.f02101.toString());
        daten.setValue("KonkretisierungStern", data.f02102.toString());
        daten.setValue("Parkscheibe", data.f02103.toString());
        daten.setValue("Parkschein", data.f02104.toString());
        daten.setValue("Beschilderungsfoto", data.f02110.toString());
        daten.setValue("Foto1", data.f02107.toString());
        daten.setValue("Foto2", data.f02108.toString());
        daten.setValue("Foto3", data.f02109.toString());
        daten.setValue("MindestzeitInMinuten", data.f02111.toString());
        daten.setValue("Klammer-a", data.f02042);
        let obj;
        if (isAdd) {
            obj = {
                Operation: "NewTatbestand",
                Tatbestandsnummer: data.f02001.trim(),
                Tatbestandsdaten: daten.getSeperatorliste()
            }
        } else {
            obj = {
                Operation: "ChangeTatbestand",
                Tatbestandsnummer: data.f02001.trim(),
                //     GueltigVon: data.f02017, MD 09.08.2023 war falsch
                GueltigVon: row.f02017,
                Tatbestandsdaten: daten.getSeperatorliste()
            }
        }
        const res = await GetDataAsync(obj);
        if (!res) return
        if (!res.erfolg) {
            dispatch(setAlert({
                text: res.hinweis ? res.hinweis : "Es ist ein Fehler aufgetreten. Die Änderungen konnten nicht vorgenommen werden.",
                type: "Fehler"
            }));
            callback && callback();
            return
        } else {
            dispatch(setToast({
                message: !isAdd ? "Änderungen wurden erfolgreich gespeichert" : "Tatbestand wurde erfolgreich hinzugefügt.",
                type: "success",
                position: "bottom center"
            }));
            callback && callback(true);
            return
        }

    }, [data, dispatch, isAdd, callback, row]);


    const handleConfirmShow = useCallback(() => {
        delete_tatbestand(dispatch, row, callback)
    }, [dispatch, row, callback]);

    const handleClosePopup = useCallback(() => {
        callback && callback();
    }, [callback]);

    const memo_options_loeschen = useMemo(() => {
        return {
            text: 'Löschen',
            type: "danger",
            onClick: handleConfirmShow,
        }
    }, [handleConfirmShow]);

    const memo_options_speichern = useMemo(() => {
        return {
            text: 'Speichern',
            onClick: handleClickSpeichern,
        }
    }, [handleClickSpeichern]);
    const memo_options_abbrechen = useMemo(() => {
        return {
            text: 'Abbrechen',
            onClick: handleClosePopup,
        }
    }, [handleClosePopup]);
    const RenderePopupTitle = useCallback(() => {
        return (<h2 className="title_popup">{isAdd ? "Neuen Tatbestand anlegen" : "Tatbestand bearbeiten"}</h2>)
    }, [isAdd]);
    return (
        <>
            <Popup
                visible={true}
                onHiding={handleClosePopup}
                dragEnabled={true}
                hideOnOutsideClick={true}
                fullScreen={false}
                showCloseButton={true}
                height='auto'
                maxHeight="75vh"
                maxWidth={(screenSize.isMedium || screenSize.isSmall) ? "90vw" : "900px"}
            >
                <ToolbarItem
                    toolbar="top"
                    location={'before'}
                    render={RenderePopupTitle}
                />

                {!isAdd &&
                    <ToolbarItem
                        widget="dxButton"
                        toolbar="bottom"
                        location="before"
                        options={memo_options_loeschen}
                    />}
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={memo_options_speichern}
                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={memo_options_abbrechen}
                />
                <ScrollView>
                    <div className="row">
                        <div className="col-12">
                            <TabPanel animationEnabled={true}>
                                <Item title="Grunddaten">
                                    <GrundDatenTab setData={setData} data={data} isAdd={isAdd} />
                                </Item>
                                <Item title="Kategorien" ><KategorieTab setData={setData} data={data} isAdd={isAdd} /></Item>
                                <Item title="Mobildaten" ><MobilDatenTab setData={setData} data={data} isAdd={isAdd} /></Item>
                            </TabPanel>
                        </div>
                    </div>
                </ScrollView>

            </Popup>
        </>
    )
}

export default TatbestandVerwaltePopup;


const delete_tatbestand = (dispatch: any, data: any, callback?: any) => {
    dispatch(setConfirm({
        body:
            <div className="alert alert-danger">
                <p>Wollen sie den Tatbestand der Tatbestandsnummer <b>{data.f02001}</b> wirklich löschen?</p>
            </div>,
        on_ok: () => {
            GetData({
                Operation: "DeleteTatbestand",
                Tatbestandsnummer: data.f02001,
                GueltigVon: data.f02017,
            }, dispatch).then((res) => {
                if (!res) return
                dispatch(setToast({
                    message: "Tatbestand wurde erfolgreich gelöscht",
                    type: "success",
                    position: "bottom center"
                }));
                callback && callback(true)
            });
        },
        title: "Tatbestand löschen",
    }));
}