import { LoadPanel, ScrollView } from 'devextreme-react';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Card from './card/card';
import PagesHeader from './header/header';
import ListenAnsicht from './listenAnsicht';
import { DataGrid } from "devextreme-react";
import { get_faelle_filter } from '../../sonstiges/hilfs_functions';
import { IFaelleFilter, IFallData } from './interfaces';
import { resetDataTempSatz, setAlert, setDataTemp, setFaelleDarstellung, setIsLoadingData, setTotalCount, useAppDispatch, useAppSelector } from '../../state';
import { GetData } from '../../global/communication';
import { MAX_ITEMS_FAELLE, MIN_DATE } from '../../global/const';
import { get_date_ajax_param } from '../../global/hilfs_functions';



interface IFaellePorps {
  fall_art: 1 | 2 | 3 | 4,
}

const Faelle: React.FC<IFaellePorps> = ({ fall_art }): JSX.Element => {
  // const konfigurator = useAppSelector((state) => state.store_einstellungen.konfiguration);
  //const [data, setData] = useState<IFallData []>([]);
  const data = useAppSelector((state) => state.store_temp_data.data) as IFallData[];
  // const [iterator, setIterator] = useState(1);
  //  const [listenAnsicht, setListenAnsicht] = useState<boolean>(konfigurator.faelle_ansicht_liste);
  const listenAnsicht = useAppSelector((state) => state.store_einstellungen.konfiguration.faelle_ansicht_liste);
  const dataGridRef = useRef() as React.MutableRefObject<DataGrid<any, any>>;
  const refScroll = useRef() as React.MutableRefObject<ScrollView>;
  const [refresh, setRefresh] = useState(true);
  //  const [recordCount, setRecordCount] = useState(0);
  const recordCount = useAppSelector((state) => state.store_temp_data.total_count);
  const dispatch = useAppDispatch();
  const temp_data = useAppSelector((state) => state.store_temp_data.temp_data_satz);
  const is_loading_data = useAppSelector((state) => state.store_temp_data.is_loading);
  //KFZ-Suche
  const [kfzSuche, setKfzSuche] = useState<boolean | null>(true);
  let filter_datum_db = useAppSelector((state) => state.store_einstellungen.konfiguration.filter_datum);
  const f1 = useMemo(() => get_datum_filter_von_bis(filter_datum_db), [filter_datum_db]);
  const f2 = useAppSelector((state) => state.store_einstellungen.konfiguration.filter_keine_geloeschten);
  const f3 = useAppSelector((state) => state.store_einstellungen.konfiguration.filter_nur_aenderungen);
  const f4 = useAppSelector((state) => state.store_einstellungen.konfiguration.filter_nur_eingestellte_faelle);
  const f5 = useAppSelector((state) => state.store_einstellungen.konfiguration.filter_vornotierungen_anzeigen);
  const f6 = useAppSelector((state) => state.store_einstellungen.konfiguration.filter_sachbearbeiter);
  const f7 = useAppSelector((state) => state.store_einstellungen.konfiguration.filter_dienst_gruppe);
  const f8 = useAppSelector((state) => state.store_einstellungen.konfiguration.filter_ort);
  const f9 = useAppSelector((state) => state.store_einstellungen.konfiguration.filter_nur_faelle); //MD neu ab 14.09.2022
  const f10 = useAppSelector((state) => state.store_einstellungen.konfiguration.filter_mehrere_sachbearbeiter); //MD neu ab 06.02.2023
  /**
   *     const [filter, setFilter] = useState<IFaelleFilter>({
    Suchbegriff: "",
    DatumVon: f1.von,
    DatumBis: f1.bis,
    KeineGeloeschten: f2 ? "Ja" : "Nein",
    NurAenderungen: f3 ? "Ja" : "Nein",
    NurEingestellteFaelle: f4 ? "Ja" : "Nein",
    VornotierungenAnzeigen: (f5 === "Nein" ? "Nein" : f5 === "Ausschließlich" ? "Ausschließlich" : "Ja"),
    Sachbearbeiternummer: f6 || "",
    FilterDienstgruppe: f7 || "",
    Ort: f8 || "",
  });
   * 
   */
  const [filter, setFilter] = useState<IFaelleFilter>({
    Suchbegriff: "",
    DatumVon: f1.von,
    DatumBis: f1.bis,
    KeineGeloeschten: f2,
    NurAenderungen: f3,
    NurEingestellteFaelle: f4,
    NurFaelle: f9,
    MehrereSachbearbeiter: f10,
    VornotierungenAnzeigen: (f5 === "Nein" ? "Nein" : f5 === "Ausschließlich" ? "Ausschließlich" : "Ja"),
    Sachbearbeiternummer: f6 || "",
    FilterDienstgruppe: f7 || "",
    Ort: f8 || "",
  });

  useEffect(() => {
    if (!listenAnsicht && temp_data) {
      dispatch(resetDataTempSatz());
    }
    GetData(
      {
        Operation: "SetKonfiguration",
        Key: "FaelleAnsicht",
        Wert: listenAnsicht ? "Liste" : "Kartei",
      }, undefined, false
    )
      .then((res) => {
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listenAnsicht])

  useEffect(() => {
    if (!listenAnsicht) {
      dispatch(setIsLoadingData());
      // setIterator(1);
      GetData(
        {
          Operation: fall_art === 1 ?
            "GetFaelleVerkehr" : fall_art === 2 ?
              "GetFaelleFliessverkehr" : fall_art === 3 ?
                "GetFaelleAOWI" : "GetFaelleABS",
          skip: "0",
          take: (MAX_ITEMS_FAELLE * 2).toString(), //Nur beim ersten mal 
          matchcodeSuche: !kfzSuche ? "Nein" : "Ja",
          filter: get_faelle_filter(filter),
          sortSpalte: "",
          sortRichtung: "",
        }, dispatch
      )
        .then((res) => {
          if (!res) {
            dispatch(setDataTemp({ data: [], new_data: true }));
            return
          }

          dispatch(setDataTemp({ data: res.data, new_data: true }));
          dispatch(setTotalCount(res.totalCount));
          //dispatch(set_total_count(999999999));
        });
    }

    return (() => {
      dispatch(setDataTemp({ data: [], new_data: true }));
      dispatch(resetDataTempSatz())
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fall_art, filter, refresh]);

  /* MD 29.06.2023 iterator nicht mehr. jetzt e.reachedBottom verwenden
    useEffect(() => {
      if (iterator > 1) {
        //   dispatch(set_is_loading_data());
        GetData(
          {
            Operation: fall_art === 1 ?
              "GetFaelleVerkehr" : fall_art === 2 ?
                "GetFaelleFliessverkehr" : fall_art === 3 ?
                  "GetFaelleAOWI" : "GetFaelleABS",
            skip: ((MAX_ITEMS_FAELLE * (iterator - 1)) + MAX_ITEMS_FAELLE), // bei dem ersten mal haben wir 2 Mal MAX_ITEMS_FAELLE 
            take: MAX_ITEMS_FAELLE.toString(),
            matchcodeSuche: !kfzSuche ? "Nein" : "Ja",
            filter: get_faelle_filter(filter),
            sortSpalte: "",
            sortRichtung: "",
          }
        )
          .then((res) => {
            if (!res || !res.erfolg) {
              dispatch(setAlert({
                text: (res && res.hinweis) ? res.hinweis : "Es ist ein Fehler aufgetreten. Fälle konnten nicht geladen werden.",
                type: "Fehler",
              }))
              dispatch(setDataTemp({ data: [], new_data: true }));
              return
            }
            //let rettData = data.slice(data.length - DIFFERENCE , data.length)
  
  
            // let rettData = data;
            // dispatch(set_data_temp(rettData.concat(res.data)));
            dispatch(setDataTemp({ data: res.data, new_data: false })); //MD 15.08.2022 Data in Redux concaten
            //  dispatch(set_data_temp(rettData.concat(generet_data(1, iterator, MAX_ITEMS_FAELLE))));
  
          });
        //let rettData = data.slice(data.length - DIFFERENCE , data.length)
        // dispatch(set_data_temp([...rettData].concat(obj)));
        //dispatch(set_data_temp(rettData.concat(obj)));
  
  
      }
  
  
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iterator]);*/


  const handleScroll = useCallback((e: any) => {
    /*
    // let data_length_all: number = iterator === 1 ? data.length : ((iterator - 1) * MAX_ITEMS_FAELLE)  +  (data.length - DIFFERENCE);
    let data_length_all: number = data.length;
    console.log(recordCount)
    console.log(data_length_all)
    if (recordCount > data_length_all) {
      let container_height = e.component._$content[0].clientHeight;
      let element_height = e.component._$element[0].clientHeight;
      let scrolled = e.scrollOffset.top;
      let now_percent = Math.floor((scrolled / (container_height - element_height)) * 100);
      if (now_percent >= PERCENT_SCROLL) {
        setIterator(prev => prev + 1);
        //e.scrollOffset.top = 0;
        //e.reachedBottom = false;
        //e.reachedTop = true;

        //e.component.release();
      }
    }*/
    // console.log("aufgerufen")

    if (data.length >= recordCount) {
      return
    }

    //MD 03.07.2023 nur dxscroll. dxscrollend nicht. Sonst wird die Anfrage 2 Mal gesendet unter Windows
    if (e.reachedBottom && e.event.type === "dxscroll") {
      GetData(
        {
          Operation: fall_art === 1 ?
            "GetFaelleVerkehr" : fall_art === 2 ?
              "GetFaelleFliessverkehr" : fall_art === 3 ?
                "GetFaelleAOWI" : "GetFaelleABS",
          skip: data.length,  //((MAX_ITEMS_FAELLE * (iterator - 1)) + MAX_ITEMS_FAELLE), // bei dem ersten mal haben wir 2 Mal MAX_ITEMS_FAELLE 
          take: MAX_ITEMS_FAELLE.toString(),
          matchcodeSuche: !kfzSuche ? "Nein" : "Ja",
          filter: get_faelle_filter(filter),
          sortSpalte: "",
          sortRichtung: "",
        }, dispatch
      )
        .then((res) => {
          if (!res) {
            dispatch(setDataTemp({ data: [], new_data: true }));
            return
          }
          //e.component.release(); das bruachen wir nicht bei onScroll
          dispatch(setDataTemp({ data: res.data, new_data: false })); //MD 15.08.2022 Data in Redux concaten
        });
    }

  }, [data, dispatch, fall_art, filter, kfzSuche, recordCount]);


  const handleListenAnsichtCallBack = useCallback((value: boolean) => {
    dispatch(setFaelleDarstellung(value))
  }, [dispatch]);

  const handleRefresh = useCallback(() => {
    setRefresh(prev => !prev);
  }, []);

  return (
    <>
      <PagesHeader refresh={handleRefresh} filter={filter} setFilter={setFilter} kfz_suche={kfzSuche!} setKfz_suche={setKfzSuche} fall_art={fall_art} liste_ansicht={handleListenAnsichtCallBack} dataGridRef={dataGridRef}></PagesHeader>
      {(listenAnsicht) ?
        <ListenAnsicht handleRefresh={handleRefresh} refresh={refresh} fall_art={fall_art} dataGridRef={dataGridRef} filter={filter} setFilter={setFilter} kfz_suche={kfzSuche!} /> :
        <ScrollView
          onScroll={handleScroll}
          //onReachBottom={handleReachBottom}
          ref={refScroll}
        >
          <ul className="row">
            {
              (is_loading_data) ?
                <LoadPanel
                  visible={true}
                ></LoadPanel>
                :
                (data && data.length === 0) ?
                  <div className='col-12 keine_daten_vorhanden'>
                    Es wurde keine Fälle gefunden.
                  </div> :
                  <RendereData data={data} /> /** so bin ich sicher dass die Daten nicht nochmal gerendert werden wenn data gleich bleibt */
            }

          </ul>
        </ScrollView>
      }
    </>
  )
}
export default memo(Faelle);

interface IDataProps {
  data: IFallData[],
}
const RendereData: React.FC<IDataProps> = memo(({ data }) => {

  return (
    <>
      {
        data.map((item) => {
          return (
            <Card item={item} key={item.lfdnr}></Card>
          )
        })
      }</>)
})


const get_datum_filter_von_bis = (filter_datum: string | undefined) => {

  if (filter_datum && filter_datum.split(";").length === 2) {
    if (filter_datum.split(";")[0] === "Alle") {
      return {
        von: get_date_ajax_param(MIN_DATE),
        bis: get_date_ajax_param(new Date()),
      }
    } else if (filter_datum.split(";")[0] === "Heute") {
      return {
        von: get_date_ajax_param(new Date()),
        bis: get_date_ajax_param(new Date()),
      }
    } else {
      return {
        von: filter_datum.split(";")[0],
        bis: filter_datum.split(";")[1],
      }
    }
  } else {
    return {
      von: get_date_ajax_param(new Date()),
      bis: get_date_ajax_param(new Date()),
    }
  }
}



/**
 * 
 * 
  
  const handleReachBottom = useCallback((e) => {
    console.log("rt")

    if (data.length >= recordCount) {
      e.component.release(true);
      e.reachedBottom = true;
      console.log("Bottom from oben")
      return
    }

    GetData(
      {
        Operation: fall_art === 1 ?
          "GetFaelleVerkehr" : fall_art === 2 ?
            "GetFaelleFliessverkehr" : fall_art === 3 ?
              "GetFaelleAOWI" : "GetFaelleABS",
        skip: data.length,  //((MAX_ITEMS_FAELLE * (iterator - 1)) + MAX_ITEMS_FAELLE), 
        take: MAX_ITEMS_FAELLE.toString(),
        matchcodeSuche: !kfzSuche ? "Nein" : "Ja",
        filter: get_faelle_filter(filter),
        sortSpalte: "",
        sortRichtung: "",
      }
    )
      .then((res) => {
        if (!res || !res.erfolg) {
          dispatch(setAlert({
            text: (res && res.hinweis) ? res.hinweis : "Es ist ein Fehler aufgetreten. Fälle konnten nicht geladen werden.",
            type: "Fehler",
          }))
          dispatch(setDataTemp({ data: [], new_data: true }));
          e.component.release();
          return
        }
        
        if ((data.length + res.data.length) >= recordCount) {
          e.component.release(true);
          //document.querySelectorAll(".dx-scrollview-scrollbottom-indicator")[0].classList.add("d-none");
          e.reachedBottom = true
          console.log("Bottom")
        } else
e.component.release();
dispatch(setDataTemp({ data: res.data, new_data: false }));

      });


  }, [data, dispatch, fall_art, filter, kfzSuche, recordCount]);

 */
/*

export const generet_data = (fall_art: 1 | 2 | 3 | 4 | 5, iterator: number, count: number): IFallData[] => {
  let result: IFallData[] = [];
  for (let i = 0; i < count; i++) {
    let myObj: IFallData = {
      lfdnr: parseInt(iterator.toString() + i.toString()),
      f001: Math.floor(Math.random() * 100), // Sachbearbeiternummer
      f002: '12.05.' + (Math.floor(Math.random() * 2020) + 1000),//"08.01.2019", //Datum
      f003: (Math.floor(Math.random() * 99999999) + 1), //Uhrzeit hhmmsszzzz
      f004: 1, // OpCode 1 = Insert 2 = Insert Deleted 3 = Update 4 = Delet
      f005: i % 2 === 0 ? "Fall Vornetierung" : "Fall", // RecordTyp
      f006: 1,//Math.floor(Math.random() * 6), // Status 
      f007: "RW-AB 1", // Ordnungsbegriff
      f008: "20190108134702005", // Zeitstempel Ursprungsfall als string
      f009: fall_art,//Math.floor(Math.random() * 6) +1, //1 = VOWI  2 = FOWI 3 = AOWI 4 = ABS 5 = Auftrag
      f010: {
        key: "RW-AB 1",
        Tatzeit: "13:47 (08.01.2019)",
        Kontrolle: "13:45 (08.01.2019)",
        strasse: "strasse " + makeStr(Math.floor(Math.random() * 30)),
        Hnrvorsatz: '34',
        Betrag1: '0',
        mBetrag1: '12,4',
        ziptbnr: '112112',
        Betrag2: '110',
        f1: "https://dummyimage.com/600x400/000/fff",
        ziptbnr2: '221221',
        kfzkz2: "AB",
        Tatmehrheit: 'Ja',
        bezirk: "Rottweil Saline",
        kzArt: "Standard",
        kfzkz1: "RW",
        ort: "Rottweil",
        massnahmenArt: "Vornotierung",
        zipkfzkz3: "ziptest",
        kfzTyp: "PKW",
        kfzkz: "RW_wo 20",
        fArt: "VOWI",
        MPStatus: "10",
        MPAnfrDatum: "20211019",
        MPAnfrZeit: "1412",
        MPAnfrKey: "myKey",
        MPDate: "20211011",
        MPTime: "1411",
        MPNr: "NummerTest",
        MPZone: "ZoneTest",
        MPOp: "OPTest",
        MPHistorie: "Hier sollte meine Historie sein\\n und das ist neue Zeile",
        sbNr: "14",
        abs1seite: makeStr(Math.random() * 10), // wenn da dann schaden vorhanden
        absZSU: "2018943254",
        Bar: 'Ja',
        QNr: 'test',
        Akz: parseInt(iterator.toString() + i.toString()),//Math.floor(Math.random() * (999999999 - 111111111 + 1) + 111111111),
        Kassenzeichen: 'Hier ist kassenzeichen',
        Grund: 'TestGrund', //Manchmal wird grund manchmal löschgrund benutzt
        loeschgrund: 'testLöschgrund',
        mbName: 'bescilderungname',
        mbKfz: 'kfztest',
        Bedingungen: 'V',
        AnschriftFBehoerde: 'Dillingen',
        AnschriftFNummer: '5437',
        FrzAnrede1: 'anrede1',
        FrzVorname1: 'vorname1',
        FrzName1: 'name1',
        FrzStrasse1: 'strasse1',
        FrzHausnummer1: '1',
        FrzLand1: 'testland1',
        FrzPostleitzahl1: '56354',
        FrzStadt1: 'stadt',
        FrzAnrede2: 'anrede1',
        FrzVorname2: 'vorname1',
        FrzName2: 'name1',
        FrzStrasse2: 'strasse1',
        FrzHausnummer2: '1',
        FrzLand2: 'testland1',
        FrzPostleitzahl2: '56354',
        FrzStadt2: 'stadt',
        AnschriftPersoNr: "435464",
        AnschriftAnrede: "herr",
        AnschriftVorname: "test",
        AnschriftName: "Name",
        AnschriftStrasse: "teststra",
        AnschriftHausnummer: "5",
        AnschriftPostleitzahl: "89435",
        AnschriftStadt: "desfes",
        AnschriftGeburtsdatum: "01.01.2012",
        AnschriftGeburtsname: "testname",
        AnschriftGeburtsort: "Damaksus",
        zeugen_formatiert: 'hier ist zeuge',
        //  AOWIziptbnrBetrag: '',
        // Betrag1: 'rew',
      },
      f070: "RWAB1", //MatchCode
      f100: "65465787687", // DeviceID,
      f101: "Support", // Device Name
      f102: "Android", //Device OS
      f103: "1", //Device Version
      f104: "GT-19100", // Device Model
      f105: "3.12", // Winowig Mobil Client Version,
      f106: 90, // Batterielevel
      f110: "20.03.2019", // Export Datum
      f111: 234546543, // Export Uhr Zeit
      f112: 6, // Export Sachbearbeiter Mu,,er
      f113: 320, // Winowig Mobil Server version 320 für 3.20
      f120: "", // Latitude
      f121: "", // Longitude
      f130: makeStr(Math.random() * 10), //WiNOWiG Online Anmerkungen
      f131: "", // Zahlbertrag Online  SE
      f132: "", // Quittungsnummer Online SE
      f200: makeStr(Math.random() * 50), // Knöllchen id
      f201: "", //Kassenzeichen
      f202: "", //Aktenzeichen
      f210: "", //Mailstatus
      f211: "", //MAil Datum Abschlepp Protokoll
      f212: 0, //Mail UhrZeit Abschlepp Protokoll
      f230: "", //Online Knöllchen Zugriff Datum
      f231: 0, //Online Knöllchen Zugriff Uhrzeit
      f232: "2", //Online Knöllchen Zahlung Status   0= Keine Zahlung  1 = Zahlwege angesehen  2 = Zahlung über Online Bezahlplattform angestossen 3 = Zahlung über Online Bezahlplattform bestätigt
      f233: "", // Zahlbertrag Knöllchen
      f300: "08.01.2019", // Empfangsdatum Server
      f301: 1918556, //Empfangszeit Server
      sachbearbeitername: "muhanad",
      exportSachbearbeitername: "test",
      mandantenspezifischesFotoverzeichnis: true,
      istBarBezahlt: false,

    }
result.push(myObj);
  }
return result;
}*/