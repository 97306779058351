
import { Popup, SelectBox } from "devextreme-react"
import { ToolbarItem } from "devextreme-react/autocomplete";
import ArrayStore from "devextreme/data/array_store";
import { memo, useCallback, useMemo, useState } from "react";
import { icon_filter } from "../../../global/const";
import { handleContentReadySelectBox } from "../../../global/hilfs_functions";
import { MDButton } from "../../../global/components";
import { IStammdatenSatzartData } from "./interfaces";

interface IStammDatenSatzartFilterProps {
    satzarte: IStammdatenSatzartData[],
    setKategorie: (value: number) => void,
}
const SatzartFilter: React.FC<IStammDatenSatzartFilterProps> = ({ satzarte, setKategorie }): JSX.Element => {
    const [showPopUp, setShowPopUp] = useState(false);
    const [satzartValue, setSatzartValue] = useState<number>(0);
    const data_source = useMemo(() => new ArrayStore({
        data: [{ satzart: 0, bezeichnung: "Alle" }].concat(satzarte),
        key: 'satzart',
    }), [satzarte]);
    const isGefiltert = useMemo(() => satzartValue ? true : false, [satzartValue]);

    const handleClickSpeichern = useCallback(() => {
        setShowPopUp(false);
        setKategorie(satzartValue);
    }, [satzartValue, setKategorie]);

    const handleShowPopup = useCallback(() => {
        setShowPopUp(true)
    }, []);
    const handleHidePopup = useCallback(() => {
        setShowPopUp(false)
    }, []);

    const memo_option_speichern = useMemo(() => {
        return {
            text: 'Speichern',
            onClick: handleClickSpeichern,
        }
    }, [handleClickSpeichern]);

    const memo_option_abbrechen = useMemo(() => {
        return {
            text: 'Abbrechen',
            onClick: handleHidePopup,
        }
    }, [handleHidePopup]);


    return (
        <>
            <MDButton
                page="stammdaten_filter"
                icon={icon_filter}
                tooltip_text="Satzart filtern"
                onClick={handleShowPopup}
                className={isGefiltert ? "highlight" : ""}
            />
            <Popup
                visible={showPopUp}
                onHiding={handleHidePopup}
                dragEnabled={true}
                hideOnOutsideClick={true}
                fullScreen={false}
                showCloseButton={true}
                height='auto'
                width='auto'
                maxHeight="70vh"
                maxWidth="90vw"
                minWidth={"400px"}
            >
                <ToolbarItem
                    toolbar="top"
                    location={'before'}
                    render={RenderePopupTitle}
                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={memo_option_speichern}
                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={memo_option_abbrechen}
                />
                <SelectBox
                    dataSource={data_source}
                    displayExpr="bezeichnung"
                    valueExpr="satzart"
                    value={satzartValue}
                    onValueChange={setSatzartValue}
                    label='Satzart'
                    labelMode='floating'
                    stylingMode='outlined'
                    onContentReady={handleContentReadySelectBox}
                />
            </Popup >

        </>
    )
}

export default memo(SatzartFilter);
const RenderePopupTitle = () => {
    return (<h2 className="title_popup">Satzart auswählen</h2>)
};
