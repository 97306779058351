import { DateBox, Popup, ScrollView, SelectBox, TextArea, TextBox } from "devextreme-react"
import { Position, ToolbarItem } from "devextreme-react/autocomplete"
import { useCallback, useMemo, useState } from "react";
import { GetData } from "../../../global/communication";
import { get_date_ajax_param, handleContentReadyDateBox, handleContentReadySelectBox, handleContentReadyTextBox } from "../../../global/hilfs_functions";
import { useScreenSizeClass } from "../../../global/media-query";
import { setConfirm, setToast, useAppDispatch } from "../../../state";
import { IKFZHinweise } from "./interfaces";


interface IKFZHinweiseBearbeitePopupProps {
    callBack: (update?: boolean) => void,
    row?: IKFZHinweise | null,
    kategorie: string[],
}

const KFZHinweiseVerwaltePopup: React.FC<IKFZHinweiseBearbeitePopupProps> = ({ kategorie, callBack, row }): JSX.Element => {

    const screenSize = useScreenSizeClass();
    const isScreenSmall = (screenSize === "sm" || screenSize === "md");
    const isAdd = row === undefined;
    const [kategorieValue, setKategorieValue] = useState(!isAdd && row?.f25002);
    const [kfz_kz, setKFZ_KZ] = useState(!isAdd ? row?.f25001 : "");
    const [firma, setFirma] = useState(!isAdd ? row?.f25004 : "");
    const [hinweis, setHinweis] = useState(!isAdd ? row?.f25003 : "");
    const [gueltigBis, setGueltigBis] = useState((!isAdd && row!.f25010) ? new Date(parseInt(row!.f25010.split('.')[2]), parseInt(row!.f25010.split('.')[1]) - 1, parseInt(row!.f25010.split('.')[0])) : undefined);
    const [kategorieSource, setKategorieSource] = useState<string[]>(kategorie);
    const dispatch = useAppDispatch();

    const customItemCreating = useCallback((args: any) => {
        if (!args.text) {
            args.customItem = null;
            return;
        }

        setKategorieSource(prev => [args.text].concat(prev));
        args.customItem = args.text;
    }, []);

    const handleClickSpeichern = useCallback(() => {
        if (!kfz_kz || !kategorieValue) {
            dispatch(setToast({
                message: "Unzureichende Eingabe",
                type: "error",
                position: "bottom center"
            }));
            return
        }
        let obj = isAdd ?
            {
                Operation: "InsertKfzHinweis",
                f25001: kfz_kz.toUpperCase(),
                f25002: kategorieValue,
                f25003: hinweis,
                f25004: firma,
                f25010: get_date_ajax_param(gueltigBis),
            } :
            {
                Operation: "UpdateKfzHinweis",
                LFDNR: row?.lfdnr,
                f25001: kfz_kz.toUpperCase(),
                f25002: kategorieValue,
                f25003: hinweis,
                f25004: firma,
                f25010: get_date_ajax_param(gueltigBis),
                f25011: row?.f25011
            };
        GetData(obj, dispatch).then((res) => {
            if (!res) return
            callBack(true);
        });
    }, [kfz_kz, kategorieValue, dispatch, isAdd, hinweis, firma, gueltigBis, row, callBack]);


    const handleCallback = useCallback(() => {
        callBack();
    }, [callBack]);

    const memo_options_loeschen = useMemo(() => {
        return {
            text: 'Löschen',
            type: "danger",
            onClick: () => (delete_kfz_hinweis(dispatch, row, callBack)),
        }
    }, [dispatch, callBack, row]);

    const memo_options_speichern = useMemo(() => {
        return {
            text: 'Speichern',
            onClick: handleClickSpeichern,
        }
    }, [handleClickSpeichern]);

    const memo_options_abbrechen = useMemo(() => {
        return {
            text: 'Abbrechen',
            onClick: handleCallback,
        }
    }, [handleCallback]);

    const RenderePopupTitle = useCallback(() => {
        return (<h2 className="title_popup">{isAdd ? "Neuer KFZ Hinweis" : "KFZ Hinweis ändern"}</h2>)
    }, [isAdd]);


    return (
        <>
            <Popup
                visible={true}
                onHiding={handleCallback}
                dragEnabled={true}
                hideOnOutsideClick={true}
                fullScreen={false}
                showCloseButton={true}
                height='auto'
                maxHeight="750px"
                maxWidth="500px"
            >
                <ToolbarItem
                    toolbar="top"
                    location={'before'}
                    render={RenderePopupTitle}
                />
                {isAdd &&
                    <Position
                        at={isScreenSmall ? "center" : "right"}
                        my={isScreenSmall ? "center" : "right top"}
                        of={isScreenSmall ? window : '#kfz_hinweis_add_button'}
                    />
                }
                {!isAdd &&
                    <ToolbarItem
                        widget="dxButton"
                        toolbar="bottom"
                        location="before"
                        options={memo_options_loeschen}
                    />}
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={memo_options_speichern}
                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={memo_options_abbrechen}
                />
                <ScrollView>
                    <div className='row g-0'>
                        <div className="col-12 col-md-6 p-1 p-md-2">
                            <TextBox
                                label="KFZ-Kennzeichen"
                                labelMode="floating"
                                stylingMode="outlined"
                                onValueChange={setKFZ_KZ}
                                value={kfz_kz}
                                valueChangeEvent="input"
                                maxLength={15}
                                onContentReady={handleContentReadyTextBox}

                            />
                        </div>
                        <div className="col-12 col-md-6 p-1 p-md-2">
                            <TextBox
                                label="Firma / Name"
                                labelMode="floating"
                                stylingMode="outlined"
                                onValueChange={setFirma}
                                value={firma}
                                valueChangeEvent="input"
                                maxLength={60}
                                onContentReady={handleContentReadyTextBox}
                            />
                        </div>
                        <div className="col-12 p-1 p-md-2">
                            <SelectBox
                                dataSource={kategorieSource}
                                acceptCustomValue={true}
                                value={kategorieValue}
                                onCustomItemCreating={customItemCreating}
                                onValueChange={setKategorieValue}
                                label='Kategorie'
                                labelMode='floating'
                                stylingMode='outlined'
                                onContentReady={handleContentReadySelectBox}
                            />
                        </div>
                        <div className="col-12 p-1 p-md-2">
                            <TextArea
                                label='Hinweis'
                                labelMode='floating'
                                stylingMode='outlined'
                                maxLength='1024'
                                onValueChange={setHinweis}
                                value={hinweis}
                                valueChangeEvent="input"
                                onContentReady={handleContentReadyTextBox}
                            />
                        </div>
                        <div className="col-12 p-1 p-md-2">
                            <DateBox
                                label='Gültig bis'
                                labelMode='floating'
                                stylingMode='outlined'
                                displayFormat={"dd.MM.yyyy"}
                                onValueChange={setGueltigBis}
                                value={gueltigBis}
                                showClearButton={true}
                                onContentReady={handleContentReadyDateBox}
                                useMaskBehavior={true}
                            // useMaskBehavior={true}
                            //valueChangeEvent="input"
                            />
                        </div>
                    </div>
                </ScrollView>
            </Popup>
        </>
    )
}

export default KFZHinweiseVerwaltePopup;

const delete_kfz_hinweis = (dispatch: any, data: any, callBack: any) => {
    dispatch(setConfirm({
        body:
            <div className="alert alert-danger">
                <p>Wollen Sie den Hinweis zum Kennzeichen <b>{data.f25001}</b> wirklich löschen?</p>
            </div>,
        on_ok: () => {
            GetData({
                Operation: "DeleteKfzHinweis",
                LFDNR: data.lfdnr
            }, dispatch).then((res) => {
                if (!res) return

                callBack(true);
            });
        },
        title: "KFZ Hinweis löschen",
    }));
}