import { memo, useCallback } from 'react';
import LeichteSprache from '../../sonstiges/leichte_sprache';
import { setAlert, useAppDispatch } from '../../state';
import { MDButton } from "../../global/components";
import './footer.scss';


const Footer = (props: any) => {
  const year = new Date().getFullYear();
  const dispatch = useAppDispatch();

  const handleClickLS = useCallback(() => {
    dispatch(setAlert({
      title: "Leichte Sprache",
      text: <LeichteSprache />,
      type: "Modal"
    }));
  }, [dispatch]);

  const handleClickGS = useCallback(() => {
    window.open("/Files/WinowigOnlineNT/Source/videos/Winowig-Barrierefrei-OnlineNT.mp4", '_blank');
  }, []);

  return (
    <>
      <footer className='footer'>
        <ul>
          {props.barrierefreiheit &&
            <li>
              <img src="/Files/WinowigOnlineNT/Source/img/sonstige/leichte_sprache_logo.png" alt="" />
              <MDButton
                page='footer_'
                button_text='Leichte Sprache'
                stylingMode='text'
                onClick={handleClickLS}
              />
            </li>}
          {props.barrierefreiheit &&
            <li>
              <img src="/Files/WinowigOnlineNT/Source/img/sonstige/dgs_logo.png" alt="" />
              <MDButton
                page='footer_'
                button_text='Gebärdensprache'
                stylingMode='text'
                onClick={handleClickGS}
              />
            </li>}
          <li className='ml-auto'>
            <p>© by Schelhorn OWiG Software GmbH {year}</p>
          </li>
        </ul>
      </footer>
    </>);
}

export default memo(Footer);
