import { Popup, ScrollView, Validator } from 'devextreme-react';
import { TextBox, Button as TextBoxButton } from 'devextreme-react/text-box';
import { ToolbarItem } from 'devextreme-react/autocomplete';
import { useCallback, useMemo, useState } from 'react';
import { EmailRule, NumericRule, RequiredRule, StringLengthRule } from 'devextreme-react/data-grid';
import { IUnternehmenDaten } from './interfaces';
import { GetData } from '../../../global/communication';
import { Seperatorliste } from '../../../global/seperator_liste';
import { useAppDispatch } from '../../../state';
import { icon_eye, icon_eye_slash } from '../../../global/const';
import { handleContentReadyTextBox } from "../../../global/hilfs_functions";

interface IUnternehmenPopupPros {
    callBack: (update?: boolean, loeschen?: boolean) => void,
    anlegen: boolean,
    data: IUnternehmenDaten | undefined,
}
const UnternehmenPopup: React.FC<IUnternehmenPopupPros> = ({ callBack, anlegen, data }) => {
    const [firmenname1, setFirmenname1] = useState(anlegen ? '' : data!.f001);
    const [firmenname2, setFirmenname2] = useState(anlegen ? '' : data!.f002);
    const [strasse, setStrasse] = useState(anlegen ? '' : data!.f003);
    const [plz, setPlz] = useState(anlegen ? '' : data!.f004);
    const [ort, setOrt] = useState(anlegen ? '' : data!.f005);
    const [festnetznummer, setFestnetznummer] = useState(anlegen ? '' : data!.f006);
    const [mobilNummer, setMobilNummer] = useState(anlegen ? '' : data!.f007);
    const [eMail, setEMail] = useState(anlegen ? '' : data!.f008);
    const [protokollPasswort, setProtokollPasswort] = useState(anlegen ? '' : data!.f009);
    const [isPassowrd, setIsPassword] = useState(true);
    const dispatch = useAppDispatch();
    const handleClickSpeichern = useCallback((e: any) => {
        let result = e.validationGroup.validate();
        if (result.isValid) {
            let daten = new Seperatorliste("%00", "%01");
            !anlegen && daten.setValue("Firmennummer", data!.lfdnr.toString());
            daten.setValue("Firmenname1", firmenname1);
            daten.setValue("Firmenname2", firmenname2);
            daten.setValue("Strasse", strasse);
            daten.setValue("Postleitzahl", plz);
            daten.setValue("Ort", ort);
            daten.setValue("Festnetznummer", festnetznummer);
            daten.setValue("Mobilnummer", mobilNummer);
            daten.setValue("E-Mail", eMail);
            daten.setValue("Protokollpasswort", protokollPasswort);
            !anlegen && daten.setValue("Protokollpasswortaenderung", protokollPasswort !== data!.f009 ? "Ja" : "Nein");

            GetData({
                Operation: anlegen ? "InsertAbschleppunternehmen" : "UpdateAbschleppunternehmen",
                Daten: daten.getSeperatorliste()
            }, dispatch).then((res) => {
                if (!res) return
                callBack(true);
            })
        }
    }, [anlegen, data, firmenname1, firmenname2, strasse, plz, ort, festnetznummer, mobilNummer, eMail, protokollPasswort, callBack, dispatch]);

    const handleHidingPopup = useCallback(() => {
        callBack();
    }, [callBack]);

    const handleCallbackLoeschen = useCallback(() => {
        callBack(undefined, true);
    }, [callBack]);
    const memo_options_loeschen = useMemo(() => {
        return {
            text: 'Löschen',
            type: "danger",
            onClick: handleCallbackLoeschen,
        }
    }, [handleCallbackLoeschen]);

    const memo_options_speichern = useMemo(() => {
        return {
            text: 'Speichern',
            onClick: handleClickSpeichern,
        }
    }, [handleClickSpeichern]);

    const memo_options_abbrechen = useMemo(() => {
        return {
            text: 'Abbrechen',
            onClick: handleHidingPopup,
        }
    }, [handleHidingPopup]);

    const handlePasswort = useCallback(() => {
        setIsPassword(prev => !prev);
    }, []);


    const memo_options_passwort = useMemo(() => {
        return {
            icon: isPassowrd ? icon_eye : icon_eye_slash,
            onClick: handlePasswort,
        }
    }, [isPassowrd, handlePasswort]);
    const RenderePopupTitle = useCallback(() => {
        return (<h2 className="title_popup">{anlegen ? "Neues Unternehmen anlegen" : "Unternehmen bearbeiten"}</h2>)
    }, [anlegen]);

    return (
        <Popup
            visible={true}
            onHiding={handleHidingPopup}
            hideOnOutsideClick={true}
            fullScreen={false}
            showCloseButton={true}
            maxHeight="490px"
            maxWidth="700px"
        >
            <ToolbarItem
                toolbar="top"
                location={'before'}
                render={RenderePopupTitle}
            />
            {!anlegen && <ToolbarItem
                widget="dxButton"
                toolbar="bottom"
                location="before"
                options={memo_options_loeschen}
            />}
            <ToolbarItem
                widget="dxButton"
                toolbar="bottom"
                location="after"
                options={memo_options_speichern}
            />
            <ToolbarItem
                widget="dxButton"
                toolbar="bottom"
                location="after"
                options={memo_options_abbrechen}
            />
            <ScrollView>
                <div className="row">
                    <div className="col-11 col-md-6 py-2">
                        <TextBox
                            label='Firmenname 1*'
                            labelMode='floating'
                            stylingMode='outlined'
                            mode='text'
                            showClearButton={false}
                            onValueChange={setFirmenname1}
                            value={firmenname1}
                            maxLength={50}
                            onContentReady={handleContentReadyTextBox}
                            valueChangeEvent="input" // hier default ist Change. Wenn man außerhalb des TextFelds klickt wird OnChange getriggert.
                        //D.h. wenn man ein TextFeld ausfüllt und auf ein Button klickt, wird dann onChange getriggert und nicht onClick des Buttons.
                        //Deshalb sollte man überall input defenieren als ChangeEvent
                        >
                            <Validator >
                                <RequiredRule message='Firmenname 1 ist ein Pflichtfeld.' />
                            </Validator>
                        </TextBox>
                    </div>
                    <div className="col-11 col-md-6 py-2">
                        <TextBox
                            label='Firmenname 2'
                            labelMode='floating'
                            stylingMode='outlined'
                            mode='text'
                            showClearButton={false}
                            onValueChange={setFirmenname2}
                            value={firmenname2}
                            valueChangeEvent="input"
                            maxLength={50}
                            onContentReady={handleContentReadyTextBox}
                        />
                    </div>
                    <div className="col-11 col-md-6 py-2">
                        <TextBox
                            label='Straße'
                            labelMode='floating'
                            stylingMode='outlined'
                            mode='text'
                            showClearButton={false}
                            onValueChange={setStrasse}
                            value={strasse}
                            valueChangeEvent="input"
                            maxLength={50}
                            onContentReady={handleContentReadyTextBox}
                        />
                    </div>
                    <div className="col-11 col-md-6 py-2">
                        <TextBox
                            label='PLZ'
                            labelMode='floating'
                            stylingMode='outlined'
                            mode='text'
                            showClearButton={false}
                            onValueChange={setPlz}
                            value={plz}
                            valueChangeEvent="input"
                            maxLength={12}
                            onContentReady={handleContentReadyTextBox}
                        >
                            <Validator >
                                <NumericRule message='Nur Zahlen dürfen eingegeben werden.' />
                                <StringLengthRule ignoreEmptyValue={true} max={5} min={5} message='Die Postleitzahl besteht aus 5 Ziffern.' />
                            </Validator>
                        </TextBox>
                    </div>
                    <div className="col-11 col-md-6 py-2">
                        <TextBox
                            label='Ort'
                            labelMode='floating'
                            stylingMode='outlined'
                            mode='text'
                            showClearButton={false}
                            onValueChange={setOrt}
                            value={ort}
                            valueChangeEvent="input"
                            maxLength={50}
                            onContentReady={handleContentReadyTextBox}
                        />
                    </div>
                    <div className="col-11 col-md-6 py-2">
                        <TextBox
                            label='Festnetznummer'
                            labelMode='floating'
                            stylingMode='outlined'
                            mode='text'
                            showClearButton={false}
                            onValueChange={setFestnetznummer}
                            value={festnetznummer}
                            valueChangeEvent="input"
                            maxLength={30}
                            onContentReady={handleContentReadyTextBox}
                        />
                    </div>
                    <div className="col-11 col-md-6 py-2">
                        <TextBox
                            label='Mobilnummer'
                            labelMode='floating'
                            stylingMode='outlined'
                            mode='text'
                            showClearButton={false}
                            onValueChange={setMobilNummer}
                            value={mobilNummer}
                            valueChangeEvent="input"
                            maxLength={30}
                            onContentReady={handleContentReadyTextBox}
                        />
                    </div>
                    <div className="col-11 col-md-6 py-2">
                        <TextBox
                            label='E-Mail'
                            labelMode='floating'
                            stylingMode='outlined'
                            mode='text'
                            showClearButton={false}
                            onValueChange={setEMail}
                            value={eMail}
                            valueChangeEvent="input"
                            maxLength={60}
                            onContentReady={handleContentReadyTextBox}
                        >
                            <Validator >
                                <EmailRule message='Die E-Mail-Adresse stimmt nicht.' />
                            </Validator>
                        </TextBox>
                    </div>
                    <div className="col-11 col-md-6 py-2">
                        <TextBox
                            label='Protokollpasswort'
                            labelMode='floating'
                            stylingMode='outlined'
                            mode={isPassowrd ? 'password' : 'text'}
                            showClearButton={false}
                            onValueChange={setProtokollPasswort}
                            value={protokollPasswort}
                            valueChangeEvent="input"
                            maxLength={60}
                            onContentReady={handleContentReadyTextBox}
                        >
                            <TextBoxButton
                                name='passwortUnternehmenAnlegen'
                                location="after"
                                options={memo_options_passwort}
                            />
                        </TextBox>
                    </div>
                </div>
            </ScrollView>
        </Popup>
    )
}
export default UnternehmenPopup;