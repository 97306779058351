import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { IFallData } from '../../components/faelle/interfaces';

interface IStateDataTemp {
    data: IFallData[],
    temp_data_satz: IFallData | undefined,
    total_count: number,
    is_loading: boolean,
}
const initialState: IStateDataTemp = {
    data: [],
    temp_data_satz: undefined,
    total_count: 0,
    is_loading: false,
}

export const dataTempSlice = createSlice({
    name: 'dataTemp',
    initialState,
    reducers: {
        resetDataTempObj: () => initialState,
        setDataTempSatz: (state, action: PayloadAction<IFallData>) => {
            state.temp_data_satz = action.payload;
        },
        resetDataTempSatz: (state) => {
            state.temp_data_satz = undefined;
        },
        setDataTemp: (state, action: PayloadAction<ISetDataPayload>) => {
            if (action.payload.new_data) {
                state.data = action.payload.data;
                state.is_loading = false;
            } else {
                state.data = state.data.concat(action.payload.data);
                state.is_loading = false;
            }
        },
        resetDataTemp: (state) => {
            state.data = []; // Vorher war hier undefined.
            state.total_count = 0;
        },
        setTotalCount: (state, action: PayloadAction<number>) => {
            state.total_count = action.payload;
        },
        resetTotalCount: (state) => {
            state.total_count = 0;
        },
        setIsLoadingData: (state) => {
            state.is_loading = true;
        },
    }
});

export default dataTempSlice.reducer;
export const { resetDataTempObj, setDataTempSatz, resetDataTempSatz, setDataTemp, resetDataTemp, setTotalCount, resetTotalCount, setIsLoadingData } = dataTempSlice.actions;

interface ISetDataPayload {
    new_data: boolean,
    data: IFallData[],
}