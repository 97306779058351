import { DataGrid, Popup, ScrollView } from "devextreme-react";
import { ToolbarItem } from "devextreme-react/autocomplete"
import { Column, Paging, Selection } from "devextreme-react/data-grid";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { GetData } from "../../../global/communication";
import { sachbearbeiter_gruppen__columns } from "../../../sonstiges/columns";
import { load_panel_enabled } from "../../../global/const";
import { useAppDispatch } from "../../../state";
import { ISachbearbeiterGruppenData } from "../../einstellungen/sb_gruppen/interfaces";
import { MDButton } from "../../../global/components";
import { IColumn } from "../../../global/interfaces";

interface ISQLHochladenSachbearbeiterGruppeProps {
    callback: (selected_g: ISachbearbeiterGruppenData[]) => void,
}


const columns: IColumn[] = sachbearbeiter_gruppen__columns;

const SQLHochladenSachbearbeiterGruppen: React.FC<ISQLHochladenSachbearbeiterGruppeProps> = ({ callback }) => {
    const [showPopUp, setShowPopUp] = useState(false);
    const dispatch = useAppDispatch();
    const [gruppen, setGruppen] = useState<ISachbearbeiterGruppenData[]>([]);
    const refDataGrid = useRef() as React.MutableRefObject<DataGrid<any, any>>;

    const handleHidePopup = useCallback(() => {
        setShowPopUp(false)
    }, []);
    const handleShowPopup = useCallback(() => {
        setShowPopUp(true)
    }, []);

    const handleClickSpeichern = useCallback(() => {
        const selected_gruppen: ISachbearbeiterGruppenData[] = (refDataGrid && refDataGrid.current && refDataGrid.current.instance) ? refDataGrid.current.instance.getSelectedRowsData() : [];
        setShowPopUp(false)
        callback(selected_gruppen);
    }, [callback]);

    const memo_options_starten = useMemo(() => {
        return {
            text: 'Speichern',
            onClick: handleClickSpeichern,
        }
    }, [handleClickSpeichern]);
    const memo_options_abbrechen = useMemo(() => {
        return {
            text: 'Abbrechen',
            onClick: handleHidePopup,
        }
    }, [handleHidePopup]);


    useEffect(() => {
        if (showPopUp && gruppen.length === 0) {
            GetData({
                Operation: "GetSachbearbeitergruppen",
                Filter: "",
            }, dispatch).then((res) => {
                if (!res) return
                setGruppen(res.data);
            })
        }
    }, [showPopUp, gruppen, dispatch]);

    return (
        <div>
            <MDButton
                page="sql_hochladen_sachbearbeiter_gruppen"
                button_text="Neue Gruppe"
                onClick={handleShowPopup}
            />
            <Popup
                visible={showPopUp && gruppen.length > 0}
                onHiding={handleHidePopup}
                dragEnabled={true}
                hideOnOutsideClick={true}
                fullScreen={false}
                showCloseButton={true}
                height='auto'
                maxHeight="70vh"
                maxWidth="700px"
            >
                <ToolbarItem
                    toolbar="top"
                    location={'before'}
                    render={RenderePopupTitle}
                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="before"
                    options={memo_options_starten}
                />

                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={memo_options_abbrechen}
                />
                <ScrollView>
                    <DataGrid
                        dataSource={gruppen}
                        allowColumnResizing={true}
                        columnAutoWidth={true}
                        ref={refDataGrid}
                        renderAsync={true}
                        wordWrapEnabled={true}
                        loadPanel={load_panel_enabled}
                        rowAlternationEnabled={true}
                        keyExpr={'f17001'}
                        height={"auto"}
                        noDataText=""
                    //  selectedRowKeys={selectedRowKeys}
                    // onSelectedRowKeysChange={(e: any) => console.log(e)}
                    //    onSelectionChanged={handleSelectedRowChangeSB}
                    >
                        <Paging enabled={false} />
                        <Selection mode="multiple" showCheckBoxesMode={"always"} />
                        {
                            columns.map((column) => {
                                return (
                                    <Column
                                        dataField={column.dataField}
                                        caption={column.caption}
                                        dataType={column.dataType}
                                        alignment={column.alignment}
                                        cellRender={column.cellRender}
                                        key={column.dataField}
                                        visible={column.visible}
                                    />
                                )
                            })
                        }
                    </DataGrid>
                </ScrollView>
            </Popup>
        </div>)
}


export default memo(SQLHochladenSachbearbeiterGruppen);

const RenderePopupTitle = () => {
    return (<h2 className="title_popup">Sachbearbeitergruppe auswählen</h2>)
};
