import { useRef } from "react";
import TabPanel, { Item } from "devextreme-react/tab-panel";
import Drucklayouts from "./layouts";
import BilderLayouts from "./bilder";


const DruckerLayouts: React.FC = () => {
    const refTabPanel = useRef() as React.MutableRefObject<any>;

    return (
        <TabPanel ref={refTabPanel} animationEnabled={true} className="mt-1 mt-md-3"  >
            <Item title="Layouts" ><Drucklayouts /></Item>
            <Item title='Bilder' > <BilderLayouts /></Item>
        </TabPanel>
    )
}

export default DruckerLayouts;
