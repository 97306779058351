
import { DataGrid, TextBox } from "devextreme-react";
import { Column, Toolbar, Item as ItemGridToolbar, Scrolling, Paging, } from "devextreme-react/data-grid";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { kfz_hinweise_columns } from "../../../sonstiges/columns";
import { exportGridAsPDF, handleContentReadyDataGrid, handleContentReadyTextBox } from "../../../global/hilfs_functions";
import KFZHinweiseAddPopup from "./add";
import KFZHinweiseExportPopup from "./export_popup";
import KFZHinweiseFilterKategoriePopup from "./filter_kategorie_popup";
import KFZHinweiseImportPopup from "./import_popup";
import KFZHinweiseLoeschenPopup from "./loeschen_popup";
import KFZHinweiseVerwaltePopup from "./verwalten_popup";
import { IKFZHinweise } from "./interfaces";
import CustomStore from "devextreme/data/custom_store";
import { GetData } from "../../../global/communication";
import { icon_pdf, MAX_ITEMS_DATA_GRID } from "../../../global/const";
import { useAppDispatch } from "../../../state";
import { MDButton, RendereGridTitle } from "../../../global/components";
import { Seperatorliste } from "../../../global/seperator_liste";
import { IColumn } from "../../../global/interfaces";


const columns: IColumn[] = kfz_hinweise_columns;

const KFZ_Hinweis: React.FC = (): JSX.Element => {
    const [bearbeitePopUp, setBearbeitePopup] = useState<{ show: boolean, row: IKFZHinweise | null }>({
        show: false,
        row: null,
    });
    const refGrid = useRef() as React.MutableRefObject<DataGrid<any, any>>;
    const [searchValue, setSearchValue] = useState("");
    const [kategorie, setKategorie] = useState("");
    const [kategorieArray, setKategorieArray] = useState<string[]>([]);
    const filter = useMemo(() => get_krz_hinweise_filter(searchValue, kategorie), [searchValue, kategorie]);
    const store_end = useMemo(() => store(filter),
        // warum habe ich vorher kategorieArray hier eingegeben? 
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [filter]);
    const dispatch = useAppDispatch();



    useEffect(() => {
        get_KFZ_Hinweise_Kategorie();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const get_KFZ_Hinweise_Kategorie = useCallback(() => {
        GetData({
            Operation: "GetKfzHinweisKategorien",
        }, dispatch).then((res) => {
            if (!res) return

            setKategorieArray(res.data);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const refresh_grid = useCallback(() => {
        refGrid && refGrid.current && refGrid.current.instance.refresh();
    }, []);

    const reset_data = useCallback(() => {
        get_KFZ_Hinweise_Kategorie();
        // setSearchValue("");
        // setKategorie("");
        refresh_grid();
    }, [get_KFZ_Hinweise_Kategorie, refresh_grid]);


    const handleCallBackPopup = useCallback((update?: boolean) => {
        setBearbeitePopup({ show: false, row: null });
        update && refresh_grid()
    }, [refresh_grid]);

    const handleRowClick = useCallback(({ data }: any) => {
        setBearbeitePopup({ show: true, row: data })
    }, []);

    const handleClickPDf = useCallback(() => {
        let header = get_header_pdf_kfz_hinweise(searchValue, kategorie);
        exportGridAsPDF(refGrid, "kfz_hinweise.pdf", header)
    }, [searchValue, kategorie]);

    const RendereTitle = useCallback(() => {
        return RendereGridTitle((kategorie && kategorie !== "Alle") ? "KFZ Hinweise (Kategorie: " + kategorie + ")" : "KFZ Hinweise");
    }, [kategorie]);

    return (
        <>{bearbeitePopUp.show && <KFZHinweiseVerwaltePopup kategorie={kategorieArray} row={bearbeitePopUp.row} callBack={handleCallBackPopup} />}
            <div className="data_grid_container mt-2 mt-md-3">
                <DataGrid
                    dataSource={store_end}
                    allowColumnResizing={true}
                    columnAutoWidth={true}
                    rowAlternationEnabled={true}
                    renderAsync={true}
                    focusedRowEnabled={false}
                    ref={refGrid}
                    noDataText={"Keine KFZ-Hinweise"}
                    className=" animate__animated animate__fadeIn cursor_pointer"
                    remoteOperations={true}
                    wordWrapEnabled={true}
                    onRowClick={handleRowClick}
                    hoverStateEnabled={true}
                    onContentReady={handleContentReadyDataGrid}
                >
                    <Scrolling mode="virtual" rowRenderingMode="virtual" preloadEnabled={true} />
                    <Paging defaultPageSize={MAX_ITEMS_DATA_GRID} />
                    {
                        columns.map((column) => {
                            return (
                                <Column
                                    dataField={column.dataField}
                                    caption={column.caption}
                                    alignment={column.alignment}
                                    dataType={column.dataType}
                                    key={column.dataField}
                                    minWidth={column.minWidth}
                                    visible={column.visible}
                                />
                            )
                        })
                    }
                    <Toolbar>
                        <ItemGridToolbar
                            location={'before'}
                            render={RendereTitle}
                        ></ItemGridToolbar>
                        <ItemGridToolbar
                            location={'after'}
                            locateInMenu="never"
                        >
                            <TextBox
                                label="Suche"
                                stylingMode='outlined'
                                mode='text'
                                className="suche_feld"
                                value={searchValue}
                                onValueChange={setSearchValue}
                                showClearButton={true}
                                maxLength={60}
                                onContentReady={handleContentReadyTextBox}>
                            </TextBox>
                        </ItemGridToolbar>
                        <ItemGridToolbar location="after" locateInMenu="auto">
                            <KFZHinweiseAddPopup kategorieArray={kategorieArray} callback={refresh_grid} />
                        </ItemGridToolbar>
                        <ItemGridToolbar location="after" locateInMenu="auto">
                            <KFZHinweiseLoeschenPopup kategorieArray={kategorieArray} reset_data={reset_data} />
                        </ItemGridToolbar>
                        <ItemGridToolbar location="after" locateInMenu="auto">
                            <KFZHinweiseImportPopup reset_data={reset_data} />
                        </ItemGridToolbar>
                        <ItemGridToolbar location="after" locateInMenu="auto">
                            <KFZHinweiseExportPopup />
                        </ItemGridToolbar>
                        <ItemGridToolbar location="after" locateInMenu="auto">
                            <KFZHinweiseFilterKategoriePopup setKategorie={setKategorie} kategorieArray={kategorieArray} />
                        </ItemGridToolbar>
                        <ItemGridToolbar location="after" locateInMenu="auto">
                            <MDButton
                                icon={icon_pdf}
                                tooltip_text="Tabelle als PDF herunterladen"
                                page="kfz_hinweis"
                                onClick={handleClickPDf}
                            />
                        </ItemGridToolbar>
                    </Toolbar>
                </DataGrid></div></>
    )
}

export default KFZ_Hinweis;



const store = (filter: string) => {
    return new CustomStore({
        key: ["lfdnr"],
        load(loadOptions: any) {

            //Achtung isLoadingAll => pdf Download
            //Achtung group => Löschgrund aufsplitten maximal 1000
            return GetData(
                {
                    Operation: "GetKfzHinweise",
                    skip: (loadOptions.isLoadingAll || loadOptions.requireTotalCount) ? "0" : loadOptions["skip"].toString(),
                    take: loadOptions["isLoadingAll"] ? "1000" : loadOptions["take"].toString(),
                    filter: filter ? filter : "",
                    sortSpalte: loadOptions.sort ? loadOptions.sort[0].selector : "",
                    sortRichtung: loadOptions.sort ? loadOptions.sort[0].desc ? "DESC" : "ASC" : "",
                }, undefined, false
            )
                .then((res) => {
                    if (!res || !res.erfolg)
                        return {
                            data: [],
                            totalCount: 0,
                        }


                    //  if(totalCount === 0){
                    //    setTotalCount(res.totalCount)
                    //  }else{
                    //  totalCount !== parseInt(res.totalCount) && setTotalCount(res.totalCount)
                    // }
                    return {
                        //   data: res.data,
                        data: res.data,
                        totalCount: res.totalCount,
                    }

                });
            //   }


            //  }
        }

    });
}

const get_header_pdf_kfz_hinweise = (searchValue: string, kategorie: string) => {
    let header = "";
    (kategorie && kategorie !== "Alle") && (header += "Kategorie: " + kategorie + "\n")
    searchValue && (header += "Suchbegriff: " + searchValue + "\n")
    return header
}
const get_krz_hinweise_filter = (seachValue: string, kategorie: string) => {
    let filter_end = new Seperatorliste("%00", "%01");
    seachValue && filter_end.setValue("Suchbegriff", seachValue);
    (kategorie && kategorie !== "Alle") && filter_end.setValue("Kategorie", kategorie);
    return filter_end.getSeperatorliste();
}