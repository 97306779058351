import { Button, LoadPanel } from "devextreme-react";
import { useCallback, useEffect, useState } from "react";
import { GetData } from "../../../global/communication";
import { setAlert, useAppDispatch, useAppSelector } from "../../../state";


const style1 = { maxWidth: "450px" };
const style2 = { height: "350px" };
const style3 = { maxWidth: "420px" };
const style4 = { maxHeight: "350px" };

const Fernwartung: React.FC = () => {
    const berechtigung = useAppSelector((state) => state.store_einstellungen.konfiguration.support_zugang_aktivieren);
    const [lognId, setLogId] = useState("");
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        !berechtigung &&
            window.open('https://owig.de/fernwartung.php');


        GetData({
            Operation: "GetStatusSupportLoginID",
        }, dispatch)
            .then((res) => {
                setLoading(false);
                if (!res) return
                if (res.loginId) {
                    setLogId(res.loginId);
                }

            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleClickSupportAktivieren = useCallback(() => {
        GetData({
            Operation: "CreateSupportLoginID",
        }, dispatch)
            .then((res) => {
                if (!res) return
                if (res.loginId) {
                    setLogId(res.loginId);
                    dispatch(setAlert({
                        text: "Zugangs-ID: " + res.loginId, type: "Info"
                    }));
                }

            });

    }, [dispatch]);

    const handleClickSupportDeaktivieren = useCallback(() => {

        GetData({
            Operation: "DeleteSupportLoginID",
        }, dispatch)
            .then((res) => {
                if (!res) return
                setLogId("");
                dispatch(setAlert({
                    text: "Der Supportzugang wurde deaktiviert und die Zugangs-ID nicht länger nutzbar.", type: "Info"
                }));


            });
    }, [dispatch]);

    const handleAnzeigen = useCallback(() => {
        dispatch(setAlert({ text: "Zugangs-ID: " + lognId, type: "Info" }))
    }, [dispatch, lognId]);


    return (<>
        {berechtigung &&
            <>{loading ? <LoadPanel visible={true} /> :
                <div className="row mt-2 mt-md-4">
                    <div className="col-12 col-md-6 text-center animate__animated animate__fadeIn">
                        <div className="card card-plain mx-auto" style={style1}>
                            <a href="https://owig.de/fernwartung.php" target="_blank" rel="noreferrer">
                                {/** 	<img src={require('../../../../img/sonstige/fernwartung1920x1281.jpeg').default} alt="fernwartungbild"  style={{height: "350px"}}/>*/}
                                <img src={"/Files/WinowigOnlineNT/Source/img/sonstige/fernwartung1920x1281.jpeg"} alt="fernwartungbild" style={style2} />
                            </a>
                            <div className="card-body ">
                                <a href="https://owig.de/fernwartung.php" target="_blank" rel="noreferrer">
                                    <h3 className="card-title">Fernwartung</h3>
                                </a>
                                <p className="card-description">
                                    Mit Teamviewer oder Fastviewer können beliebige Bildschirminhalte via Internet live von einem PC zu einem anderen übertragen sowie gemeinsam Dokumente bearbeitet werden.
                                </p>
                            </div>
                        </div>
                    </div>
                    {lognId ?
                        <>
                            <div className="col-12 col-md-6 text-center  animate__animated animate__fadeIn animate__delay-1s">
                                <div className="card card-plain mx-auto" style={style3}>
                                    <div role={"button"} onClick={handleClickSupportDeaktivieren}>
                                        {/**<img src={require('../../../../img/sonstige/support_zugang_deaktivieren.jpg').default} alt="Support Zugang deaktivieren" style={{maxHeight: "350px"}}/> */}
                                        <img src={"/Files/WinowigOnlineNT/Source/img/sonstige/support_zugang_deaktivieren.jpg"} alt="Support Zugang deaktivieren" style={style4} />
                                    </div>
                                    <div className="card-body ">
                                        <div role={"button"} onClick={handleClickSupportDeaktivieren}>
                                            <h3 className="card-title">Deaktiviere Supportzugang</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 text-center  animate__animated animate__fadeIn animate__delay-2s">
                                <Button text="Supportzugangs-ID anzeigen" stylingMode="outlined" type="default" onClick={handleAnzeigen} />
                            </div></>
                        :
                        <div className="col-12 col-md-6 text-cente animate__animated animate__fadeIn animate__delay-1s">
                            <div className="card card-plain mx-auto" style={style1}>
                                <div role={"button"} onClick={handleClickSupportAktivieren}>
                                    {/** 	<img src={require('../../../../img/sonstige/support_zugang_aktivieren.png').default} alt="Support Zugang aktivieren"  style={{height: "350px"}}/> */}
                                    <img src={"/Files/WinowigOnlineNT/Source/img/sonstige/support_zugang_aktivieren.png"} alt="Support Zugang aktivieren" style={style2} />

                                </div>
                                <div className="card-body ">
                                    <div role={"button"} onClick={handleClickSupportAktivieren}>
                                        <h3 className="card-title">Aktiviere Supportzugang</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            }
            </>


        }
    </>)
}

export default Fernwartung;