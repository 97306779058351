
import { memo, useCallback, useState } from "react";
import { icon_plus } from "../../../global/const";
import { MDButton } from "../../../global/components";
import StammDataVerwaltePopup from "./verwalten_popup";
import { IStammdatenSatzartData } from "./interfaces";

interface IStammdatenAddProps {
    satzarte: IStammdatenSatzartData[],
    callback: () => void,
}
const StammdatenAdd: React.FC<IStammdatenAddProps> = ({ satzarte, callback }): JSX.Element => {
    const [showPopUp, setShowPopUp] = useState(false);

    const handleHidePopup = useCallback((update?: boolean) => {
        setShowPopUp(false);
        update && callback()
    }, [callback]);

    const handleShowPopup = useCallback(() => {
        setShowPopUp(true)
    }, []);
    return (<>
        <MDButton
            page="stammdaten"
            icon={icon_plus}
            type="success"
            onClick={handleShowPopup}
            tooltip_text="Stammdaten anlegen"
        />
        {showPopUp && <StammDataVerwaltePopup satzarte={satzarte} callback={handleHidePopup} />}
    </>
    )
}

export default memo(StammdatenAdd);