import { DataGrid, ScrollView } from "devextreme-react";
import { Column, Button } from "devextreme-react/data-grid";
import { useCallback, useEffect, useState } from "react";
import { GetData } from "../../../global/communication";
import { icon_delete } from "../../../global/const";
import { setAlert, setConfirm, setToast, useAppDispatch } from "../../../state";
import { MDButton } from "../../../global/components";



interface IMobilKonfig {
    filename: string,
    timestamp: string,
    size: number,
}
const GeraeteKonfigurationen = () => {
    const [is_loading, setIsLoading] = useState(true);
    const dispatch = useAppDispatch();
    const [data, setData] = useState<IMobilKonfig[]>([]);

    useEffect(() => {
        if (is_loading) {
            GetData({ Operation: "GetGeraeteKonfigurationen" }, dispatch)
                .then((res) => {
                    if (!res) {
                        setData([]);
                        return
                    }
                    setIsLoading(false);
                    setData(res.data);
                });
        }
    }, [is_loading, dispatch])

    const handleClickDelete = useCallback((e) => {
        delete_konfiguration(dispatch, data, e, setData);
    }, [data, dispatch]);


    const handleRowClick = useCallback((e) => {
        //nicht den löschbutton
        if (e.event.originalEvent.currentTarget.className.indexOf("dx-button") === -1) {
            GetData({ Operation: "GetGeraeteKonfigurationInhalte", filename: e.key }, dispatch)
                .then((res) => {
                    if (!res) return
                    dispatch(setAlert({
                        text: res.data,
                        type: "Modal",
                        title: "Konfigurationsdatei: " + e.key
                    }))

                });

        }

    }, [dispatch]);
    return (
        <>
            <ScrollView>
                <DataGrid
                    dataSource={data}
                    columnAutoWidth={false}
                    rowAlternationEnabled={true}
                    renderAsync={true}
                    keyExpr="filename"
                    className="mt-2 mt-md-3 cursor_pointer"
                    wordWrapEnabled={true}
                    onRowClick={handleRowClick}
                    noDataText={"Keine Konfigurationdatei gefunden"}
                >
                    <Column dataField="filename" caption="Datei" />
                    <Column dataField="timestamp" caption="Zeitstempel" />
                    <Column dataField="size" caption="Größe (Byte)" dataType="number" alignment={"left"} />
                    <Column
                        type='buttons'
                    >
                        <Button
                            cssClass={'d-inline-block'}
                            onClick={handleClickDelete}
                            render={RenderButtonDelete}
                        ></Button>

                    </Column>
                </DataGrid>
            </ScrollView></>
    )
}

export default GeraeteKonfigurationen;

const RenderButtonDelete: React.FC = () => {
    return (
        <MDButton
            icon={icon_delete}
            type="danger"
            page="mobil_konfigurationen"
            tooltip_text="Löschen"
        />)
}


const delete_konfiguration = (dispatch: any, data: any, e: any, setData: any) => {
    dispatch(setConfirm({
        body:
            <div className="alert alert-danger">
                <p>Wollen Sie die Konfirugationdatei <b> {e.row.data.filename} </b> wirklich löschen?</p>
            </div>,
        on_ok: () => {
            GetData({ Operation: "DeleteGeraeteKonfigurationen", filename: e.row.data.filename }, dispatch)
                .then((res) => {
                    if (!res) return
                    let new_data: IMobilKonfig[] = [...data];
                    new_data = new_data.filter((item) => item.filename !== e.row.data.filename);
                    setData(new_data);
                    dispatch(setToast({
                        message: "Die Konfigurationdatei " + e.row.data.filename + " wurde erfoglreich gelöscht.",
                        type: "success",
                    }));
                });
        },
        title: "Löschen",
    }));
}
