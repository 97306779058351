import React, { useState, memo, useMemo } from "react"
import FotosPopup from "./fotos_popup";
import { IFallData, IFallDataZusaetzlich } from "../../../interfaces";
import { useCallback } from "react";
import Anmerkung from "../../card_control/symbole/anmerkung";
import { useAppSelector } from "../../../../../state";
import ZahlBetrag from "../../card_control/symbole/zahl_betrag";
import BarBezahlung from "../../card_control/symbole/bar_bezahlung";
import PopupGallery from "./fotos_gallery";
import HandyParken from "../../card_control/symbole/handy_parken";
import { MDButton } from "../../../../../global/components";
import { icon_kreis, icon_more } from "../../../../../global/const";
import { get_fotos_from_fall } from "../../../../../sonstiges/hilfs_functions";
import { handleErrorImg } from "../../../../../global/hilfs_functions";



interface ICardHeaderProps {
    item: IFallData,
    itemZus: IFallDataZusaetzlich,
}

const get_symbole_anzeigen = (item: IFallData, itemZus: IFallDataZusaetzlich, anmerkung_erfassen: boolean, zahlbetrag_erfassen: boolean): boolean => {
    if (
        (anmerkung_erfassen && item.f130) ||
        (zahlbetrag_erfassen && itemZus.bezahlt) ||
        (itemZus.bar_bezahlt_f010) ||
        (item.f010.MPStatus && !isNaN(parseInt(item.f010.MPStatus))) // Handyparken auch noch aufnehmen
    ) return true;
    return false;
}
const CardHeader: React.FC<ICardHeaderProps> = ({ item, itemZus }): JSX.Element => {
    const anmerkung_erfassen = useAppSelector((state) => state.store_einstellungen.konfiguration.faelle_anmerkungen_erfassen);
    const zahlbetrag_erfassen = useAppSelector((state) => state.store_einstellungen.konfiguration.zahlbetrag_erfassen);
    const symbole_anzeigen = useMemo(() => get_symbole_anzeigen(item, itemZus, anmerkung_erfassen, zahlbetrag_erfassen), [item, itemZus, anmerkung_erfassen, zahlbetrag_erfassen]);

    const [imgNummer, setImgNummer] = useState(0);
    const [showMoreFotos, setShowMoreFotos] = useState(false);
    const mnr = useAppSelector((state) => state.store_einstellungen.konfiguration.mandantennummer);
    const fotos = useMemo(() => get_fotos_from_fall(item, mnr), [item, mnr]);
    const fallBilder = useMemo(() => getFallBilder(fotos), [fotos]);
    const weitereBilder = useMemo(() => getWeitereBilder(fotos), [fotos]);
    const [showGallery, setShowGallery] = useState<{ show: boolean, key: number | undefined }>({
        show: false,
        key: undefined
    });

    const handleChangeImg = useCallback((e: any) => {
        setImgNummer(JSON.parse(e.event.currentTarget.getAttribute("data")).key);
    }, []);

    const handleShowMoreFotos = useCallback(() => {
        setShowMoreFotos(true)
    }, []);
    const handleHideMoreFotos = useCallback(() => {
        setShowMoreFotos(false)
    }, []);

    const handleClickBild = useCallback((e: any) => {
        setShowGallery({
            show: true,
            key: parseInt(e.target.dataset.key)
        });
    }, []);

    const handleHidingGallery = useCallback(() => {
        setShowGallery({
            show: false,
            key: undefined
        });
    }, []);


    return (
        <React.Fragment>

            {showGallery.show && <PopupGallery fotos={fotos} onHide={handleHidingGallery} selectedItem={showGallery.key!} />}
            <div role="group" aria-label="Foto zum Anzeigen aussuchen:">
                <div
                    className="card_header px-2 px-md-3 pt-2 pt-md-3"
                    aria-roledescription={fallBilder.length > 0 ? "Karussell" : undefined}
                    aria-label={fallBilder.length > 0 ? "Fotos für den Fall mit dem Aktenzeichen: " + item.f202 : undefined}
                >
                    {fallBilder.length === 0 && <div className="kein_bild text-primary">
                        <span>Kein Foto</span>
                    </div>}
                    {
                        (fallBilder.length > 0) &&
                        <div role="group" aria-roledescription="Foto">
                            <img
                                onKeyUp={handleEventEnterToClick}
                                tabIndex={0}
                                onClick={handleClickBild}
                                src={fallBilder[imgNummer].url}
                                alt={"Alle Fotos für den Fall mit dem Aktenzeichen " + item.f202 + " in der Bildergalerie anzeigen"}
                                data-key={imgNummer}
                                onError={handleErrorImg}
                            />
                        </div>
                    }
                </div>
                <div className={symbole_anzeigen ? "card_front_control d-flex align-items-center  justify-content-between px-2 px-md-3 pt-2 pt-md-3" : "card_front_control d-flex align-items-center  justify-content-center px-2 px-md-3 pt-2 pt-md-3"}>
                    {
                        symbole_anzeigen ?
                            /**  <div className="d-flex justify-content-between">*/
                            <>
                                <ul className="container_card_control d-flex  flex-wrap">
                                    {(anmerkung_erfassen && item.f130) && <Anmerkung anmerkung={item.f130} lfdnr={item.lfdnr} exportiert={itemZus.exportiert} />}
                                    {(zahlbetrag_erfassen && itemZus.bezahlt) && <ZahlBetrag item={item} itemZus={itemZus} />}
                                    {itemZus.bar_bezahlt_f010 && <BarBezahlung itemZus={itemZus} lfdnr={item.lfdnr} />}
                                    {itemZus.fall_art !== 5 && <HandyParken f010={item.f010} lfdnr={item.lfdnr} />}
                                </ul>
                                <Fotos fallBilder={fallBilder} handleChangeImg={handleChangeImg} handleHideMoreFotos={handleHideMoreFotos} handleShowMoreFotos={handleShowMoreFotos} imgNummer={imgNummer} showMoreFotos={showMoreFotos} weitereBilder={weitereBilder} />
                            </>
                            /**</div> */
                            :
                            <Fotos fallBilder={fallBilder} handleChangeImg={handleChangeImg} handleHideMoreFotos={handleHideMoreFotos} handleShowMoreFotos={handleShowMoreFotos} imgNummer={imgNummer} showMoreFotos={showMoreFotos} weitereBilder={weitereBilder} />

                    }
                </div>
            </div>
        </React.Fragment >
    )
}

export default memo(CardHeader);

interface IFotosProps {
    fallBilder: any[],
    imgNummer: number,
    handleChangeImg: (e: any) => void,
    weitereBilder: any[],
    handleShowMoreFotos: () => void,
    showMoreFotos: boolean,
    handleHideMoreFotos: () => void,
}
const Fotos: React.FC<IFotosProps> = memo(({ fallBilder, imgNummer, handleChangeImg, weitereBilder, handleShowMoreFotos, showMoreFotos, handleHideMoreFotos }) => {

    return (
        fallBilder.length > 1 ?
            <ul className="d-flex align-items-center justify-content-center">
                {
                    fallBilder.length > 1 && fallBilder.map((foto, i) => {
                        let obj_data = { key: i };
                        return (
                            <li key={i}>
                                <MDButton
                                    icon={icon_kreis}
                                    page="fall_card"
                                    stylingMode="text"
                                    onClick={handleChangeImg}
                                    data={obj_data}
                                    className={imgNummer === i ? "highlight dot" : "dot"}
                                    ariaLabel={foto.alt}
                                    ariaDisabled={imgNummer === i ? true : false}
                                />
                            </li>
                        )
                    })
                }
                {weitereBilder.length > 0 && <li>
                    <MDButton
                        icon={icon_more}
                        page="fall_card"
                        stylingMode="text"
                        onClick={handleShowMoreFotos}
                        className="dot more_fotos"
                        ariaLabel={"Weiter Fotos anzeigen"}
                    />

                </li>}

                {showMoreFotos && <FotosPopup onHide={handleHideMoreFotos} fotos={weitereBilder} weitere_fotos={true} />}
            </ul > : <></>
    );
});

const getFallBilder = (arr: any) => {
    let result = [];
    for (let i of arr) {
        if (i.bezeichnung.substring(0, 2) !== "fw") result.push(i);
        //  if (result.length === 4) break;
    }
    return result;
}
const getWeitereBilder = (arr: any) => {
    let result = [];
    for (let i of arr) {
        if (i.bezeichnung.substring(0, 2) === "fw") result.push(i);
    }
    return result;
}

const handleEventEnterToClick = (e: any) => {
    if (e.keyCode === 13) {
        let element = e.target as HTMLElement;
        element.click();
    }
}