
import { useState, useCallback, useEffect } from 'react';
import { IScreenSize, TScreenSizeClasses } from './interfaces';

export const useScreenSize = (): IScreenSize => {
  const [screenSize, setScreenSize] = useState(getScreenSize());
  const onSizeChanged = useCallback(() => {
    setScreenSize(getScreenSize());
  }, []);

  useEffect(() => {
    subscribe(onSizeChanged);

    return () => {
      unsubscribe(onSizeChanged);
    };
  }, [onSizeChanged]);

  return screenSize;
};

export const useScreenSizeClass = (): TScreenSizeClasses => {
  const screenSize = useScreenSize();

  if (screenSize.isXXLarge) {
    return 'xxl';
  }

  if (screenSize.isXLarge) {
    return 'xl';
  }

  if (screenSize.isLarge) {
    return 'lg';
  }

  if (screenSize.isMedium) {
    return 'md';
  }

  if (screenSize.isSmall) {
    return 'sm';
  }

  return 'xxxl';
}

let handlers: any[] = [];
/*
const xSmallMedia = window.matchMedia('(max-width: 599.99px)');
const smallMedia = window.matchMedia('(min-width: 600px) and (max-width: 959.99px)');
const mediumMedia = window.matchMedia('(min-width: 960px) and (max-width: 1279.99px)');
const largeMedia = window.matchMedia('(min-width: 1280px)');
*/
/*Bootsrtap breakpoint */
//const smallMedia = window.matchMedia('(max-width: 575.99px)');
const smallMedia = window.matchMedia('(max-width: 767.99px)');
const mediumMedia = window.matchMedia('(min-width: 768px) and (max-width: 991.99px)');
const largeMedia = window.matchMedia('(min-width: 992px) and (max-width: 1199.99px)');
const xLargeMedia = window.matchMedia('(min-width: 1200px) and (max-width: 1399.99px)');
const xxLargeMedia = window.matchMedia('(min-width: 1400px) and (max-width: 2099.99px)');
const xxxLargeMedia = window.matchMedia('(min-width: 2100px)');


[smallMedia, mediumMedia, largeMedia, xLargeMedia, xxLargeMedia, xxxLargeMedia].forEach(media => {
  media.addEventListener("change", (e) => {
    e.matches && handlers.forEach(handler => handler());
  });
});

const subscribe = (handler: any) => handlers.push(handler);

const unsubscribe = (handler: any) => {
  handlers = handlers.filter(item => item !== handler);
};

function getScreenSize(): IScreenSize {
  return {
    isSmall: smallMedia.matches,
    isMedium: mediumMedia.matches,
    isLarge: largeMedia.matches,
    isXLarge: xLargeMedia.matches,
    isXXLarge: xxLargeMedia.matches,
    isXXXLarge: xxxLargeMedia.matches,
  };
}
