import { useCallback, useState } from "react";
import TatbestandVerwaltePopup from "./verwalten_popup";
import { ITatbestandData } from "./interfaces";
import TatbestaendeGrid from "./tatbestaende_grid";


const Tatbestaende: React.FC = () => {

    const [bearbeitePopUp, setBearbeitePopup] = useState<{ show: boolean, row: ITatbestandData | null }>({
        show: false,
        row: null,
    });
    const [refreshGrid, setRefreshGrid] = useState(true);

    const handleCallBackBearbeite = useCallback((update?: boolean) => {
        setBearbeitePopup({ show: false, row: null })
        update && setRefreshGrid(prev => !prev)
    }, [])

    return (<>
        {bearbeitePopUp.show && <TatbestandVerwaltePopup row={bearbeitePopUp.row} callback={handleCallBackBearbeite} />}
        <TatbestaendeGrid refresh_grid={refreshGrid} setBearbeitePopup={setBearbeitePopup} />
    </>
    )
}
export default Tatbestaende;