import { memo } from "react";
import { icon_hand_dollar } from "../../../../../global/const";
import { MDButton } from "../../../../../global/components";
import { IFallDataZusaetzlich } from "../../../interfaces";

interface IBarBezaglungProps {
    lfdnr: number,
    itemZus: IFallDataZusaetzlich,
}

const BarBezahlung: React.FC<IBarBezaglungProps> = ({ lfdnr, itemZus }) => {
    const qu_text = itemZus.quittungsnummer ? "Es wurde Bar bezahlt. Quittungsnummer: " + itemZus.quittungsnummer : "Es wurde Bar bezahlt.";

    return (
        itemZus.bar_bezahlt_f010 ?
            <li>
                <MDButton
                    page="fall_card"
                    tooltip_text={qu_text}
                    className="bar_bezahlung"
                    icon={icon_hand_dollar}
                /></li>
            : <></>
    );
}
export default memo(BarBezahlung);