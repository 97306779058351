import React, { useEffect } from 'react';
import { Router } from "react-router";
import { locale } from "devextreme/localization";
import { GetData } from '../../global/communication';
import { setKonfiguration, useAppDispatch, useAppSelector } from '../../state';
import { LoadPanel } from 'devextreme-react';
import PasswortAbgelaufen from './passwort_aenderung_popup';
import SwitchNav from './switch';
import NotificationsWinowig from '../../global/MDNotifications';
import themes from 'devextreme/ui/themes';
import deMessages from "devextreme/localization/messages/de.json";
import { loadMessages } from "devextreme/localization";

/** Falls Router gebraucht wird.
 * import {
  BrowserRouter as Router1,
  Redirect,
  Route
} from "react-router-dom";
 */
import Login from './login';

interface IMainProps {
  history: any
}
const Main: React.FC<IMainProps> = ({ history }) => {
  const dispatch = useAppDispatch();
  const is_loading = useAppSelector((state) => state.store_einstellungen.is_loading_konfigurator);
  // const passwort_aenderung = useAppSelector((state) => state.store_einstellungen.konfiguration.passwortaenderung);
  const passwort_aenderung = useAppSelector((state) => state.store_einstellungen.passwortaenderung)
  useEffect(() => {
    if (is_loading) {
      GetData({ Operation: "GetKonfiguration" }, dispatch, false)
        .then((res) => {
          if (!res) return
          if (res.theme && res.theme !== "-1") {
            if (res.theme === "0") {
              themes.current("material.blue.light");
            } else if (res.theme === "1") {
              themes.current("material.blue.dark")
            } else if (res.theme === "2") {
              themes.current("material.orange.light");
            } else if (res.theme === "3") {
              themes.current("material.orange.dark");
            } else if (res.theme === "4") {
              themes.current("material.purpel.light");
            } else if (res.theme === "5") {
              themes.current("material.limette.dark");
            }
          }
          delete res.theme;
          dispatch(setKonfiguration(res));
        });
    }
  }, [is_loading, dispatch])


  return (
    <>
      <Router history={history}>
        {is_loading ?
          <LoadPanel
            visible={true}
          ></LoadPanel>
          : (passwort_aenderung ? <PasswortAbgelaufen /> : <SwitchNav />)
        }
      </Router>
    </>
  );
}

const RouterMain: React.FC<IMainProps> = ({ history }) => {
  const loggedIn = useAppSelector((state) => state.store_einstellungen.loggedIn);
  useEffect(() => {
    loadMessages(deMessages);
    locale(navigator.language);
  }, []);

  return (<>
    {/**
       * Router bauche ich hier eher nicht
        <Route path={['/', '/login']} exact >
            {loggedIn ? <Redirect to='/index'/> : <Login/>}
        </Route>
        <Route path='/'>
            {loggedIn ? <Main history={history}/>: <Redirect to='/login'/> }
        </Route>
        */}
    <NotificationsWinowig />
    {loggedIn ? <Main history={history} /> : <Login />}
  </>
  )
}

export default RouterMain;

