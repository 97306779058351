import { Popup, ScrollView } from "devextreme-react"
import { Position, ToolbarItem } from "devextreme-react/autocomplete"
import { useCallback, useMemo, useState } from "react";
import { icon_export } from "../../../global/const";
import { useScreenSizeClass } from "../../../global/media-query";
import { MDButton } from "../../../global/components";




const KFZHinweiseExportPopup: React.FC = (): JSX.Element => {
    const screenSize = useScreenSizeClass();
    const isScreenSmall = (screenSize === "sm" || screenSize === "md");
    const [showPopUpExport, setShowPopUpExport] = useState(false);

    const handleButtonExportierenClik = useCallback(() => {
        setShowPopUpExport(true)
    }, []);
    const handleHidePopup = useCallback(() => {
        setShowPopUpExport(false)
    }, []);
    const handleButtonExportStarten = useCallback(() => {
        setShowPopUpExport(false);
        window.location.href = "/Files/WoOnline/Source/Export/T025.xml"
    }, []);

    const memo_options_export_starten = useMemo(() => {
        return {
            text: 'Export starten',
            onClick: handleButtonExportStarten
        }
    }, [handleButtonExportStarten]);

    const memo_options_abbrechen = useMemo(() => {
        return {
            text: 'Abbrechen',
            onClick: handleHidePopup,
        }
    }, [handleHidePopup]);

    return (
        <>
            <div id='kfz_hinweis_export_button'>
                <MDButton
                    icon={icon_export}
                    tooltip_text="KFZ Hinweise exportieren"
                    page="kfz_hinweise_exportieren"
                    onClick={handleButtonExportierenClik}
                />
            </div>
            <Popup
                visible={showPopUpExport}
                onHiding={handleHidePopup}
                dragEnabled={true}
                hideOnOutsideClick={true}
                fullScreen={false}
                showCloseButton={true}
                height='auto'
                maxHeight="750px"
                maxWidth="500px"
            >
                <ToolbarItem
                    toolbar="top"
                    location={'before'}
                    render={RenderePopupTitle}
                />
                <Position
                    at={isScreenSmall ? "center" : "right"}
                    my={isScreenSmall ? "center" : "right top"}
                    of={isScreenSmall ? window : '#kfz_hinweis_export_button'}
                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="before"
                    options={memo_options_export_starten}
                />

                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={memo_options_abbrechen}
                />
                <ScrollView>
                    <div className='row p-2 p-md-3'>
                        <div className="col-12 p-1 p-md-2">
                            <p>Wählen Sie 'Export starten' um alle KFZ-Hinweise zu exportieren.</p>
                        </div>
                    </div>
                </ScrollView>
            </Popup>
        </>
    )
}

export default KFZHinweiseExportPopup
const RenderePopupTitle = () => {
    return (<h2 className="title_popup">KFZ Hinweise exportieren</h2>)
};