import { DataGrid, DateBox, SelectBox } from "devextreme-react";
import { Column, Toolbar, Item as ItemGridToolbar, } from "devextreme-react/data-grid";
import ArrayStore from "devextreme/data/array_store";
import { useEffect, useMemo } from "react";
import { useCallback } from "react";
import { useRef, useState } from "react";
import { GetData } from "../../../global/communication";
import { Statistik_Tag_Spalten } from "../../../sonstiges/columns";
import { fall_arten, icon_excel, icon_pdf, NOW, scroll_mode_infinite } from "../../../global/const";
import { exportGridAsExcel, exportGridAsPDF, get_date_ajax_param, handleContentReadyDataGrid, handleContentReadyDateBox, handleContentReadySelectBox } from "../../../global/hilfs_functions";
import { useAppDispatch } from "../../../state";
import { RendereGridTitleTagesauswertung } from "../../../sonstiges/hilfs_functions";
import { MDButton } from "../../../global/components";
import { IStatistikDataTagAuswertung } from "../interfaces";
import { IColumn } from "../../../global/interfaces";


const data_Source = new ArrayStore({
  data: fall_arten,
  key: 'value',
});

const columns: IColumn[] = Statistik_Tag_Spalten;
const Statistik_Tag: React.FC = (): JSX.Element => {
  const [data, setData] = useState<IStatistikDataTagAuswertung>({ anzahlFaelleGesamt: 0, summeVerwarngeldGesamt: 0, data: [] });
  const dataGridRef = useRef() as React.MutableRefObject<DataGrid<any, any>>;
  const [fallArtValue, setFallArtValue] = useState(1);
  const [datumValue, setDatumValue] = useState(new Date());
  const headerForExport = useMemo(() => get_header_pdf_statistik_tag(datumValue, fallArtValue, data.anzahlFaelleGesamt, data.summeVerwarngeldGesamt), [
    datumValue, fallArtValue, data.anzahlFaelleGesamt, data.summeVerwarngeldGesamt
  ]);
  const dispatch = useAppDispatch();

  useEffect(() => {
    GetData({
      Operation: "GetTagesauswertung",
      Datum: get_date_ajax_param(datumValue),
      Fallart: fallArtValue.toString(),
    }, dispatch)
      .then((res) => {
        if (!res) {
          setData({ anzahlFaelleGesamt: 0, summeVerwarngeldGesamt: 0, data: [] });
          return
        }
        setData({
          anzahlFaelleGesamt: res.anzahlFaelleGesamt,
          summeVerwarngeldGesamt: res.summeVerwarngeldGesamt,
          data: res.data
        });
      });
  }, [datumValue, fallArtValue, dispatch]);

  const handleChangeDate = useCallback((e: any) => {
    setDatumValue(e.value)
  }, []);
  const handlePDf = useCallback(() => {
    exportGridAsPDF(dataGridRef, 'Tagesauswertung.pdf', headerForExport)
  }, [headerForExport]);

  const handleExcel = useCallback(() => {
    exportGridAsExcel(dataGridRef, 'Tagesauswertung.xlsx')
  }, []);
  return (
    <div className="data_grid_container header_grid_thick mt-2 mt-md-3">
      <DataGrid
        dataSource={data.data}
        allowColumnResizing={true}
        columnAutoWidth={false}
        rowAlternationEnabled={true}
        renderAsync={true}
        className=" animate__animated animate__fadeIn"
        focusedRowEnabled={false}
        // remoteOperations={true}
        ref={dataGridRef}
        scrolling={scroll_mode_infinite}
        wordWrapEnabled={true}
        keyExpr={'geraetenummer'}
        noDataText={"Keine Statistiken"}
        height={"auto"}
        onContentReady={handleContentReadyDataGrid}
      // onContentReady= {set_column_chooser_position }
      >
        {/**
       * <ColumnChooser  enabled={true} mode="dragAndDrop" emptyPanelText="Ziehe eine Spalte hierher, um sie auszublenden" title="Spalten auswählen" width={300}/>
            
       * 
       */}
        {
          columns.map((column) => {
            return (
              <Column
                dataField={column.dataField}
                caption={column.caption}
                dataType={column.dataType}
                key={column.dataField}
                alignment={column.alignment}
                visible={column.visible}
              />
            )
          })
        }
        <Toolbar>
          <ItemGridToolbar
            location={'before'}
            render={RendereGridTitleTagesauswertung}
          />
          <ItemGridToolbar
            location={'center'}
            locateInMenu="auto"
            text={"Fälle gesamt: " + data.anzahlFaelleGesamt}
            cssClass="pr-2 pr-md-4"
          />
          <ItemGridToolbar
            location={'center'}
            locateInMenu="auto"
            text={"Verwarng. gesamt: " + data.summeVerwarngeldGesamt}
          />
          <ItemGridToolbar location="after" locateInMenu="auto">
            <SelectBox
              dataSource={data_Source}
              displayExpr="text"
              valueExpr="value"
              value={fallArtValue}
              onValueChange={setFallArtValue}
              label='Fallart'
              labelMode='floating'
              stylingMode='outlined'
              onContentReady={handleContentReadySelectBox}
            />
          </ItemGridToolbar>
          <ItemGridToolbar location="after" locateInMenu="auto">
            <DateBox
              value={datumValue}
              onValueChanged={handleChangeDate}
              displayFormat={"dd.MM.yyyy"}
              showClearButton={true}
              useMaskBehavior={true}
              label='Tag'
              labelMode='floating'
              stylingMode='outlined'
              max={NOW}
              onContentReady={handleContentReadyDateBox}
            />
          </ItemGridToolbar>
          <ItemGridToolbar location="after" locateInMenu="auto">
            <MDButton
              icon={icon_pdf}
              page="statistik_tag"
              tooltip_text="Tabelle als PDf herunterladen"
              onClick={handlePDf}
            />

          </ItemGridToolbar>
          <ItemGridToolbar location="after" locateInMenu="auto">
            <MDButton
              icon={icon_excel}
              page="statistik_tag"
              tooltip_text="Tabelle als Excel herunterladen"
              onClick={handleExcel}
            />
          </ItemGridToolbar>
        </Toolbar>
      </DataGrid></div>)
}

export default Statistik_Tag;



const get_header_pdf_statistik_tag = (datumObj: Date, fallArtValue: number, anzahl_faelle_gesamt: number, summe_verwarngeld_gesamt: number) => {
  function get_fall_art() {
    let fallart = '';
    for (let i of fall_arten) {
      if (i.value === fallArtValue) {
        fallart = i.text;
        break;
      }
    }
    return fallart;
  }

  let result = '';
  let datum = get_date_ajax_param(datumObj);
  let fall_art = get_fall_art();

  result = 'Tagesauswertung ' + datum + ' Fallart: ' + fall_art;
  result += '\nFälle gesamt: ' + anzahl_faelle_gesamt.toString();
  result += '\nVerwarngelder gesamt: ' + summe_verwarngeld_gesamt.toString();
  return result;
}