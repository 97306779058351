import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { default_spalten_t002, default_spalten_t701, default_spalten_t705, default_spalten_t715 } from "../../global/const";
import { IFaelleFilter } from '../../components/faelle/interfaces';
import { GetData } from '../../global/communication';
import { get_faelle_filter } from '../../sonstiges/hilfs_functions';



interface IStateEinstellungen {
    loggedIn: boolean,
    passwortaenderung: boolean,
    konfiguration: IKonfiguration,
    is_loading_konfigurator: boolean,
    konfigurator_bearbeitet: boolean,
}

const initialState: IStateEinstellungen = {
    loggedIn: false,
    passwortaenderung: false,
    konfiguration: {
        vowi: true,
        aowi: true,
        fowi: false,
        abs: false,
        gemeinsam_genutzte_tatbestandstabelle: true,

        //filter
        filter_keine_geloeschten: false,
        filter_nur_aenderungen: false,
        filter_nur_faelle: false,
        filter_nur_eingestellte_faelle: false,
        filter_mehrere_sachbearbeiter: false,
        filter_vornotierungen_anzeigen: "Ja",



        faelle_ansicht_liste: false,
        exportierte_faelle_loeschen: false,
        winowig_mail: false,
        zahlbetrag_erfassen: false,
        lieferanten_kennung_In_statistik_aufnehmen: true,

        mobil: false,
        fall_protokoll_anzeigen: false,
        nach_zeugen_filtern: false,
        faelle_verkehr: false,
        faelle_verkehr_loeschen: false,
        faelle_verkehr_aktivieren: false,
        faelle_anmerkungen_erfassen: false,
        faelle_abschlepp: false,
        faelle_abschleppen_loeschen: false,
        faelle_abschleppen_aktivieren: false,
        faelle_aowi: false,
        faelle_aowi_loeschen: false,
        faelle_aowi_aktivieren: false,
        faelle_fliess_verkehr: false,
        faelle_fliess_verkehr_loeschen: false,
        faelle_fliess_verkehr_aktivieren: false,

        daten_export: false,
        daten_export_starten: false,
        daten_export_bearbeiten: false,
        daten_export_herunterladen: false,

        statistik: false,
        statistik_benutzer_definiert: false,
        statistik_individuell: false,
        statistik_tages_auswertung: false,
        statistik_monat_auswertung: false,
        statistik_geraete_logins: false,
        statistik_benutzer_definiert_value: "",

        support: false,
        support_konfigurator: false,
        support_druck_layouts: false,

        einstellungen: false,
        einstellungen_abschleppunternehmen: false,
        einstellungen_kfz_hinweise: false,
        einstellungen_stammdaten: false,
        einstellungen_strassen: false,
        einstellungen_geraete: false,
        einstellungen_tatbestaende: false,
        einstellungen_tatbestaende_kategorie: false,
        einstellungen_tatbestaende_hinweise: false,
        einstellungen_sachbearbeiter: false,
        einstellungen_sachbearbeiter_gruppen: false,
        einstellungen_fernwartung: false,
        einstellungen_passwort_aendern: false,
        mehrere_faelle_anzeigen: false,
        fotos_bearbeiten: false,
        mobil_loeschen: false,
        doppel_verwarnungen: false,
        support_zugang_aktivieren: false,

        programmversion: "",
        mandantennummer: "",
        mandantenbezeichnung: "",
        sachbearbeiternummer: "",
        sachbearbeitername: "",
        //   passwortaenderung: false,

        //MD Default spalten
        t701_vowi_spalten: default_spalten_t701,
        t701_aowi_spalten: default_spalten_t701,
        t701_abs_spalten: default_spalten_t701,
        t715_spalten: default_spalten_t715,
        t705_spalten: default_spalten_t705,
        t002_spalten: default_spalten_t002,

        support_zugang_authentifiziert: false,
        is_administrator: false,
        auftragserfassung: false,
        barrierefreiheit: false,

        //MD 20.07.2023 neu
        t733_vorhanden: false,
    },
    is_loading_konfigurator: true,
    konfigurator_bearbeitet: false,
    // is_developer_mode: false, brauchen wir vielleicht nicht
}

export const einstellungenSlice = createSlice({
    name: 'einstellungen',
    initialState,
    reducers: {
        resetEinstellungen: () => initialState,
        setLoggedIn: (state, action: PayloadAction<boolean>) => {
            state.loggedIn = action.payload;
        },
        setPasswortaenderung: (state, action: PayloadAction<boolean>) => {
            state.passwortaenderung = action.payload;
        },
        setKonfiguration: (state, action: PayloadAction<IKonfiguration>) => {
            //Check if tabellenspalten nicht leer sind ansonsten in db die default werte schreiben
            if (!action.payload.t701_abs_spalten)
                saveTabellenSpalten("T701_ABS", default_spalten_t701);
            if (!action.payload.t701_aowi_spalten)
                saveTabellenSpalten("T701_AOWI", default_spalten_t701);
            if (!action.payload.t701_vowi_spalten)
                saveTabellenSpalten("T701_VOWI", default_spalten_t701);
            if (!action.payload.t002_spalten)
                saveTabellenSpalten("T002", default_spalten_t002);
            if (!action.payload.t705_spalten)
                saveTabellenSpalten("T705", default_spalten_t705);
            if (!action.payload.t715_spalten)
                saveTabellenSpalten("T715", default_spalten_t715);

            state.konfiguration = action.payload;
            state.is_loading_konfigurator = false;
        },
        setKonfigSupportZugangAuth: (state, action: PayloadAction<boolean>) => {
            state.konfiguration.support_zugang_authentifiziert = action.payload;
            state.konfigurator_bearbeitet = false;
        },
        setKonfiguratorBearbeitet: (state, action: PayloadAction<boolean>) => {
            state.konfigurator_bearbeitet = action.payload;
        },
        setTabellenSpalten: (state, action: PayloadAction<ISetTabellenSpalten>) => {
            saveTabellenSpalten(action.payload.tabelle, action.payload.value);

            switch (action.payload.tabelle) {
                case 'T715':
                    state.konfiguration.t715_spalten = action.payload.value
                    break;
                case 'T002':
                    state.konfiguration.t002_spalten = action.payload.value
                    break;
                case 'T701_ABS':
                    state.konfiguration.t701_abs_spalten = action.payload.value
                    break;
                case 'T701_AOWI':
                    state.konfiguration.t701_aowi_spalten = action.payload.value
                    break;
                case 'T701_VOWI':
                    state.konfiguration.t701_vowi_spalten = action.payload.value
                    break;
                case 'T705':
                    state.konfiguration.t705_spalten = action.payload.value
                    break;
            }
        },
        SetBenutzerDefStatistik: (state, action: PayloadAction<string>) => {
            state.konfiguration.statistik_benutzer_definiert_value = action.payload;
        },
        setFaelleDarstellung: (state, action: PayloadAction<boolean>) => {
            state.konfiguration.faelle_ansicht_liste = action.payload;
        },
        setFilterKonfig: (state, action: PayloadAction<IFaelleFilter>) => {
            GetData(
                {
                    Operation: 'SetFilterFaelle',
                    Filter: get_faelle_filter(action.payload, true)
                }, undefined, false)
                .then((res) => {
                });
            state.konfiguration.filter_datum = action.payload.DatumVon + ';' + action.payload.DatumBis;
            state.konfiguration.filter_sachbearbeiter = action.payload.Sachbearbeiternummer;
            state.konfiguration.filter_dienst_gruppe = action.payload.FilterDienstgruppe;
            state.konfiguration.filter_ort = action.payload.Ort;
            state.konfiguration.filter_keine_geloeschten = action.payload.KeineGeloeschten;
            state.konfiguration.filter_nur_aenderungen = action.payload.NurAenderungen;
            state.konfiguration.filter_nur_faelle = action.payload.NurFaelle;
            state.konfiguration.filter_nur_eingestellte_faelle = action.payload.NurEingestellteFaelle;
            state.konfiguration.filter_vornotierungen_anzeigen = action.payload.VornotierungenAnzeigen;
        },
    }
});

export default einstellungenSlice.reducer;
export const { setKonfiguratorBearbeitet, resetEinstellungen, setLoggedIn, setKonfiguration, setKonfigSupportZugangAuth, setTabellenSpalten, SetBenutzerDefStatistik, setFaelleDarstellung, setFilterKonfig, setPasswortaenderung } = einstellungenSlice.actions


function saveTabellenSpalten(tabelle: "T701_ABS" | "T701_AOWI" | "T701_VOWI" | "T705" | "T715" | "T002", value: string) {
    GetData({ Operation: "SetTabellenspalten", Tabelle: tabelle, Wert: value }, undefined, false)
        .then((res) => { });
}
interface ISetTabellenSpalten {
    tabelle: "T701_ABS" | "T701_AOWI" | "T701_VOWI" | "T705" | "T715" | "T002",
    value: string,
}
export interface IKonfiguration {
    vowi: boolean,
    aowi: boolean,
    fowi: boolean,
    abs: boolean,
    gemeinsam_genutzte_tatbestandstabelle: boolean,

    filter_datum?: string,
    filter_sachbearbeiter?: string, //kann auch alle stehen
    filter_ort?: string,
    filter_keine_geloeschten: boolean,
    filter_nur_aenderungen: boolean,
    filter_nur_faelle: boolean, //MD neu ab 14.09.2022
    filter_nur_eingestellte_faelle: boolean,
    filter_mehrere_sachbearbeiter: boolean, //Neu ab 06.02.2023
    filter_vornotierungen_anzeigen: string,
    filter_dienst_gruppe?: string,

    faelle_ansicht_liste: boolean,
    exportierte_faelle_loeschen: boolean,
    winowig_mail: boolean,
    zahlbetrag_erfassen: boolean,
    lieferanten_kennung_In_statistik_aufnehmen: boolean,
    filter_auf_freifeld2?: string,

    mobil: boolean,
    fall_protokoll_anzeigen: boolean,
    nach_zeugen_filtern: boolean,
    faelle_verkehr: boolean,
    faelle_verkehr_loeschen: boolean,
    faelle_verkehr_aktivieren: boolean,
    faelle_anmerkungen_erfassen: boolean,
    faelle_abschlepp: boolean,
    faelle_abschleppen_loeschen: boolean,
    faelle_abschleppen_aktivieren: boolean,
    faelle_aowi: boolean,
    faelle_aowi_loeschen: boolean,
    faelle_aowi_aktivieren: boolean,
    faelle_fliess_verkehr: boolean,
    faelle_fliess_verkehr_loeschen: boolean,
    faelle_fliess_verkehr_aktivieren: boolean,

    daten_export: boolean,
    daten_export_starten: boolean,
    daten_export_bearbeiten: boolean,
    daten_export_herunterladen: boolean,

    statistik: boolean,
    statistik_benutzer_definiert: boolean,
    statistik_individuell: boolean,
    statistik_tages_auswertung: boolean,
    statistik_monat_auswertung: boolean,
    statistik_geraete_logins: boolean,
    statistik_benutzer_definiert_value?: string,

    support: boolean
    support_konfigurator: boolean,
    support_druck_layouts: boolean,

    einstellungen: boolean,
    einstellungen_abschleppunternehmen: boolean,
    einstellungen_kfz_hinweise: boolean,
    einstellungen_stammdaten: boolean,
    einstellungen_strassen: boolean,
    einstellungen_geraete: boolean,
    einstellungen_tatbestaende: boolean,
    einstellungen_tatbestaende_kategorie: boolean,
    einstellungen_tatbestaende_hinweise: boolean,
    einstellungen_sachbearbeiter: boolean,
    einstellungen_sachbearbeiter_gruppen: boolean,
    einstellungen_fernwartung: boolean,
    einstellungen_passwort_aendern: boolean,
    mehrere_faelle_anzeigen: boolean,
    fotos_bearbeiten: boolean,
    mobil_loeschen: boolean,
    doppel_verwarnungen: boolean,
    support_zugang_aktivieren: boolean,

    programmversion: string,
    mandantennummer: string,
    mandantenbezeichnung: string,
    sachbearbeiternummer: string,
    sachbearbeitername: string,
    // passwortaenderung: boolean,

    //MD neu ab 07.02.2023
    t701_vowi_spalten: string,
    t701_aowi_spalten: string,
    t701_abs_spalten: string,
    t715_spalten: string,
    t705_spalten: string,
    t002_spalten: string,

    support_zugang_authentifiziert: boolean,
    is_administrator: boolean,
    auftragserfassung: boolean,
    barrierefreiheit: boolean,

    t733_vorhanden: boolean,
}