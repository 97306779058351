import { tatbestand_haupt_katogieren_alt_bilder, tatbestand_unter_katogieren_alt_bilder } from "../global/const";
import { IColumn } from "../global/interfaces";
import { formatErfassungsZeit, formatTatzeit, format_geraete_logins_zeitstemple } from "./hilfs_functions";
import { dbTimeToString, get_column_min_width, handleErrorImg, stretch0Left } from "../global/hilfs_functions";
import { IFallData } from "../components/faelle/interfaces";
import { getGesamtbetragAusF010, get_angezeigte_tatbestand_nummer, sortTatbestaende } from "../components/faelle/hilfs_functions";



const formatStatus = (item: any) => {
  let value = item.value;
  switch (value) {
    case 1:
      return (<i aria-label="Gerät gesperrt von/bis Datum" className="dx-icon-isnotblank geraetGesperrt"></i>)
    case 0:
      return (<i aria-label="Gerät betriebsbereit" className="dx-icon-isnotblank geraetAktiv"></i>)
    case 2:
      return (<i aria-label="Daten auf Gerät gelöscht und Gerät gesperrt" className="dx-icon-isnotblank geraetGeperrtGeloescht"></i>)
    case 20:
      return (<i aria-label="Daten sollen gelöscht werden" className="dx-icon-isnotblank geraetDatenloeschen"></i>)
    default:
      return (<></>)
  }

}

const formatStatus_sachbearbeiter = (value: number): string => {
  if (value === 0) {
    return "Keine Sperrung";
  } else if (value === 1) {
    return "Sperrung";
  } else if (value === 2) {
    return "Vollsperrung";
  } else if (value === 3) {
    return "Sofortige Vollsperrung";
  } else {
    return "Fehler";
  }
}
export const sachbearbeiter_columns: IColumn[] =
  [
    {
      dataField: "f07001",
      caption: "Sb-Nr.",
      alignment: "left",
      minWidth: get_column_min_width(6),
    }, {
      dataField: "f07002",
      caption: "Name",
      minWidth: get_column_min_width(4),
    }, {
      dataField: "f07110",
      caption: "Status",
      dataType: "number",
      alignment: "left",
      cellRender: (item) => { return <>{formatStatus_sachbearbeiter(item.value)}</> },
      minWidth: get_column_min_width(6),
    }, {
      dataField: "f07013",
      caption: "Außendarstellung",
      minWidth: get_column_min_width(16),
    }
  ]
export const format_gruppen_art = (value: number): string => {

  if (value === 0) {
    return "Berechtigung & Vertretung";
  } else if (value === 1) {
    return "Berechtigung";
  } else if (value === 2) {
    return "Vertretung";
  } else if (value === 3) {
    return "Winowig Mail";
  } else {
    return "Fehler";
  }
}
export const sachbearbeiter_gruppen__columns: IColumn[] =
  [
    {
      dataField: "f17001",
      caption: "Gruppen-Nr.",
      alignment: "left",
    }, {
      dataField: "f17002",
      caption: "Bezeichnung",
    }, {
      dataField: "f17004",
      caption: "Gruppenart",
      alignment: "left",
      cellRender: (item) => { return <>{format_gruppen_art(item.value)}</> }
    }
  ]



export const kfz_hinweise_columns: IColumn[] = [
  {
    dataField: "lfdnr",
    caption: "Lfdnr",
    visible: false,
    minWidth: get_column_min_width(5),
    alignment: "left",
  }, {
    dataField: "f25001",
    caption: "Kennzeichen",
    minWidth: get_column_min_width(11),
    alignment: "left",
  }, {
    dataField: "f25004",
    caption: "Firma / Name",
    minWidth: get_column_min_width(12),
  }, {
    dataField: "f25002",
    caption: "Kategorie",
    minWidth: get_column_min_width(9),
    alignment: "left",
  }, {
    dataField: "f25010",
    caption: "Gültig bis",
    minWidth: get_column_min_width(10),
    alignment: "left",
  }, {
    dataField: "f25003",
    caption: "Hinweistext",
    minWidth: get_column_min_width(11),
    alignment: "left",
  }
]

export const geraete_columns: IColumn[] = [
  {
    dataField: "f005",
    caption: " ",
    alignment: "center",
    width: "60",
    cellRender: formatStatus,
    showInColumnChooser: false,
    visible: false,
    allowSorting: false,
  }, {
    dataField: "f002",
    caption: "Name",
    visible: false,
    minWidth: get_column_min_width(4),
  }, {
    dataField: "f004",
    caption: "Benutzer",
    visible: false,
    minWidth: get_column_min_width(8),
  }, {
    dataField: "faelleHeute",
    caption: "Fälle heute",
    dataType: "number",
    alignment: "left",
    visible: false,
    allowSorting: false,
    minWidth: get_column_min_width(12),
  }, {
    dataField: "faelleGesamt",
    caption: "Fälle ges.",
    dataType: "number",
    alignment: "left",
    visible: false,
    allowSorting: false,
    minWidth: get_column_min_width(11),
  }, {
    dataField: "f012",
    caption: "Modell",
    visible: false,
    alignment: "left",
    minWidth: get_column_min_width(6),
  }, {
    dataField: "f001",
    caption: "Nummer",
    visible: false,
    alignment: "left",
    minWidth: get_column_min_width(6),
  }, {
    dataField: "f003",
    caption: "Letzter Fall",
    visible: false,
    alignment: "left",
    minWidth: get_column_min_width(12),
  }, {
    dataField: "batterielevelMinMax",
    caption: "Bat. Min / Max",
    visible: false,
    allowSorting: false,
    alignment: "left",
    minWidth: get_column_min_width(14),
  }, {
    dataField: "bat",
    caption: "Bat.",
    dataType: "number",
    alignment: "left",
    visible: false,
    allowSorting: false,
    minWidth: get_column_min_width(4),
  }, {
    dataField: "clientversion",
    caption: "Client-Version",
    visible: false,
    alignment: "left",
    allowSorting: false,
    minWidth: get_column_min_width(14),
  }

]


export const DPVTabellenSpalten: IColumn[] =
  [
    {
      dataField: "f001",
      caption: "Exportnummer",
      dataType: "number",
      alignment: "left",
    },
    {
      dataField: "f002",
      caption: "Exportart",
      alignment: "left",
      cellRender: (data) => {
        if (data.value === 0) {
          return <p>VOWI</p>;
        } else if (data.value === 1) {
          return <p>AOWI</p>;
        } else {
          return <></>;
        }
      }
    },
    {
      dataField: "f010",
      caption: "Exportdatum",
      dataType: "date"
    },
    {
      dataField: "f011",
      caption: "Exportuhrzeit",
      alignment: "left",
    },
    {
      dataField: "f040",
      caption: "Anzahl Sätze",
      dataType: "number",
      alignment: 'left'
    }
  ];

const renderVal = (val: any) => {
  let value = <p>{val.value}</p>;
  value = <><b>{val.data.tatbestandsnummer1}</b>&nbsp;{value}</>;

  return (<>
    {value}
  </>)
}
export const sachbearbeiter_einfach_columns: IColumn[] =
  [{
    dataField: "f07002",
    caption: "Sachbearbeiter",
    alignment: "left",
  }, {
    dataField: "f07001",
    caption: "Nr",
    alignment: "left",
  }
  ]

export const DPVTabellenDetailsSpalten: IColumn[] =
  [
    {
      dataField: "tatzeitbis",
      caption: "Tatzeit",
      alignment: "left",
      minWidth: get_column_min_width(7),
    },
    {
      dataField: "sachbearbeiternummer",
      caption: "Sachbearbeiternummer",
      dataType: "number",
      alignment: 'left',
      minWidth: get_column_min_width(20),
    },
    {
      dataField: "tattext1",
      caption: "Tattext",
      cellRender: renderVal,
      minWidth: get_column_min_width(7),
    },
    {
      dataField: "betrag1",
      caption: "Betrag",
      alignment: "left",
      minWidth: get_column_min_width(6),
    },
    {
      dataField: "ventilstellung",
      caption: "Ventilstellung",
      alignment: "left",
      minWidth: get_column_min_width(14),
    }
  ]

export const Statistik_Tag_Spalten: IColumn[] = [
  {
    dataField: "geraetename",
    caption: "Gerätename",
    alignment: "left",
  }, {
    dataField: "geraetenummer",
    caption: "Gerätenummer",
    alignment: "left",
  }, {
    dataField: "anzahlfaelle",
    caption: "Fälle",
    alignment: "left",
    dataType: "number",
  }, {
    dataField: "verwarngeldsumme",
    caption: "Summe Verwarngelder",
    alignment: "left",
    dataType: "number"
  }
]

export const StatistikTabellenSpaltenAlle: IColumn[] =
  [
    {
      dataField: "sachbearbeitername",
      caption: "Sb. Name",
      visible: false,
      allowSorting: false,
      minWidth: get_column_min_width(8),
    },
    {
      dataField: "f001",
      caption: "Sb. Nummer",
      alignment: 'left',
      visible: false,
      minWidth: get_column_min_width(10),
    },
    {
      dataField: "f002",
      caption: "Erfassung",
      alignment: "left",
      cellRender: (item) => {
        return <p>{formatErfassungsZeit(item.data.f002, item.data.f003)}</p>
      },
      visible: false,
      minWidth: get_column_min_width(9),
    },
    {
      dataField: "f004",
      caption: "Fallart",
      alignment: "left",
      visible: false,
      minWidth: get_column_min_width(7),
    },
    {
      dataField: "f007",
      caption: "Löschgrund",
      visible: false,
      minWidth: get_column_min_width(10),
    },
    {
      dataField: "f010",
      caption: "Ort",
      visible: false,
      minWidth: get_column_min_width(3),
    },
    {
      dataField: "lieferantenkennung",
      caption: "Lieferantenkennung",
      alignment: "left",
      visible: false,
      allowSorting: false,
      minWidth: get_column_min_width(18),
    },
    {
      dataField: "f011",
      caption: "Bezirk",
      visible: false,
      minWidth: get_column_min_width(6),
    },
    {
      dataField: "f012",
      caption: "Straße",
      visible: false,
      minWidth: get_column_min_width(6),
    },
    {
      dataField: "f014",
      caption: "Hausnummer",
      visible: false,
      minWidth: get_column_min_width(11),
      alignment: "left"
    },
    {
      dataField: "f015",
      caption: "Straßendetails",
      visible: false,
      minWidth: get_column_min_width(14),
    },
    {
      dataField: "f019",
      caption: "Ordnungsbegriff",
      alignment: "left",
      visible: false,
      minWidth: get_column_min_width(15),
    },
    {
      dataField: "f024",
      caption: "Tatzeit Von",
      alignment: "left",
      cellRender: (item) => {
        return <p>{formatTatzeit(item.data.f024, item.data.f025)}</p>
      },
      visible: false,
      minWidth: get_column_min_width(11),
    },
    {
      dataField: "f026",
      caption: "Tatzeit Bis",
      alignment: "left",
      cellRender: (item) => {
        return <p>{formatTatzeit(item.data.f026, item.data.f027)}</p>
      },
      visible: false,
      minWidth: get_column_min_width(11),
    },
    {
      dataField: "f030",
      caption: "Tatbestand",
      alignment: "left",
      visible: false,
      minWidth: get_column_min_width(10),
    },
    {
      dataField: "f035",
      caption: "Betrag",
      alignment: "left",
      visible: false,
      minWidth: get_column_min_width(6),
    },
    {
      dataField: "f700",
      caption: "Gerätenummer",
      alignment: "left",
      visible: false,
      minWidth: get_column_min_width(12),
    }
  ];

export const StatistikTabelleIndividuellSpalten: IColumn[] =
  [
    {
      dataField: "f003",
      alignment: "left",
      caption: "Bezeichnung",
    },
    {
      dataField: "f160",
      caption: "Notiz",
      alignment: "left",
    },
  ]

export const format_tatbestand_fahrverbot = (value: number): string => {
  if (value === 0) return "Nein"
  else return value.toString();
}

export const format_tatbestand_basis_betragsfestellung = (s: string) => {
  if (s === "V") {
    return "Vorsätzliches Handeln";
  } else if (s === "F") {
    return "Fahrlässiges Handeln";
  } else {
    return (s) ? s : "";
  }
}
export const format_tatbestand_tatbestandtyp = (value: string) => {
  if (value === "0") {
    return "ruhend";
  } else if (value === "1") {
    return "fließend";
  } else if (value === "2") {
    return "AOWI";
  } else {
    return (value) ? value : "";
  }
}
export const format_tatbestand_in_winowig_mobil_verwenden = (value: number) => {
  if (value === 0) {
    return "Automatische Festlegung";
  } else if (value === 1) {
    return "Ja";
  } else if (value === 2) {
    return "Nein";
  } else {
    return (value) ? value.toString() : "";
  }
}
export const TatbestaendeAllColumns: IColumn[] = [

  {
    dataField: "f02001",
    caption: "Tbnr.",
    dataType: "number",
    alignment: "left",
    visible: false,
    minWidth: get_column_min_width(5),
  }, {
    dataField: "f02002",
    caption: "Tatbestandstext",
    visible: false,
    minWidth: get_column_min_width(15 + 20),
  }, {
    dataField: "f02007",
    caption: "Betrag",
    alignment: "left",
    visible: false,
    minWidth: get_column_min_width(6),
  }, {
    dataField: "f02008",
    caption: "Tatkennziffer",
    alignment: "left",
    visible: false,
    minWidth: get_column_min_width(13),
  }, {
    dataField: "f02009",
    caption: "Punkte",
    dataType: "number",
    alignment: "left",
    visible: false,
    minWidth: get_column_min_width(6),
  }, {
    dataField: "f02010",
    caption: "Tbnr. Abgabe",
    alignment: "left",
    visible: false,
    minWidth: get_column_min_width(12),
  }, {
    dataField: "f02011",
    caption: "Fahrverbot",
    alignment: "left",
    cellRender: (data) => {
      return <>{format_tatbestand_fahrverbot(data.value)}</>
    },
    visible: false,
    minWidth: get_column_min_width(10),
  }, {
    dataField: "f02017",
    caption: "Gültig ab",
    alignment: "left",
    visible: false,
    minWidth: get_column_min_width(9),
  }, {
    dataField: "f02018",
    caption: "Gültig bis",
    alignment: "left",
    visible: false,
    minWidth: get_column_min_width(10),
  }, {
    dataField: "f02019",
    caption: "Tatbestandstyp",
    alignment: "left",
    visible: false,
    cellRender: (data) => {
      return <>{format_tatbestand_tatbestandtyp(data.value)}</>
    },
    minWidth: get_column_min_width(14),
  }, {
    dataField: "f02023",
    caption: "Basis der Betragsfestlegung",
    alignment: "left",
    visible: false,
    cellRender: (data) => {
      return <>{format_tatbestand_basis_betragsfestellung(data.value)}</>
    },
    minWidth: get_column_min_width(27),
  }, {
    dataField: "f02025",
    caption: "Verjährung in Monaten",
    dataType: "number",
    alignment: "left",
    visible: false,
    minWidth: get_column_min_width(21),
  }, {
    dataField: "f02030",
    caption: "In Winowig Mobil verwenden",
    alignment: "left",
    visible: false,
    cellRender: (data) => {
      return <>{format_tatbestand_in_winowig_mobil_verwenden(data.value)}</>
    },
    minWidth: get_column_min_width(26),
  }, /* {
  dataField: "f02040",
  caption: "KZ Tatbestandkatalog",
},*/ {
    dataField: "f02041",
    caption: "Kz Fahrverbot auf Probe",
    alignment: "left",
    visible: false,
    minWidth: get_column_min_width(23),
  }, {
    dataField: "f02042",
    caption: "Klammer-a",
    alignment: "left",
    visible: false,
    minWidth: get_column_min_width(9),
  }, {
    dataField: "f02047",
    caption: "Tabellen-Nummer",
    alignment: "left",
    visible: false,
    minWidth: get_column_min_width(15),
  }, {
    dataField: "f02050",
    caption: "Berechnungs-Modus",
    alignment: "left",
    visible: false,
    minWidth: get_column_min_width(17),
  }, {
    // dataField: "f02051",
    // 24.06.2022 f02111 statt f02051
    dataField: "f02111",
    caption: "Mindestens (Minuten etc...)",
    visible: false,
    alignment: "left",
    minWidth: get_column_min_width(27),
  }, {
    dataField: "kategorien",
    caption: "Kategorien",
    visible: false,
    alignment: "left",
    allowSorting: false,
    minWidth: get_column_min_width(10),
  }
]

export const tatbestand_hinweise_columns = (): IColumn[] => {
  return [
    {
      dataField: "f001",
      caption: "Tatbestandsnummer",
      dataType: "number",
      alignment: "left"
    }, {
      dataField: "f002",
      caption: "Hinweistext",
    }
  ]

}
export const druck_layouts_columns: IColumn[] =
  [
    {
      dataField: "druckermodell",
      caption: "Druckermodell",
      alignment: "left",
    }, {
      dataField: "dateiname",
      caption: "Dateiname",
      alignment: "left",
    }
  ]


export const export_faelle_columns: IColumn[] =
  [
    {
      dataField: "kfz_kz",
      caption: "KFZ-Kennzeichen",
      alignment: "left",
    }, {
      dataField: "tattag",
      caption: "Tattag",
      alignment: "left",
    }, {
      dataField: "tatzeit",
      caption: "Tatzeit",
      alignment: "left",
    }
  ];



export const exporte_columns = (): IColumn[] => {
  return [
    {
      dataField: "exportnummer",
      caption: "Exportnummer",
      dataType: "number",
      alignment: "left",
    }, {
      dataField: "exportdatum",
      caption: "Exportdatum",
      alignment: "left",
    }, {
      dataField: "exportuhrzeit",
      caption: "Exportuhrzeit",
      alignment: "left",
    }, {
      dataField: "anzahl",
      caption: "Anzahl Sätze",
      alignment: "left",
    }
  ];

}

export const format_abschleppunternehmen_fahrer_anrede = (value: string): string => {
  if (value === "0") {
    return "";
  } else if (value === "1") {
    return "Herr";
  } else if (value === "2") {
    return "Frau";
  } else {
    return "";
  }
}
export const abschlepp_unternehmen_columns_fahrer: IColumn[] = [
  {
    dataField: "f001",
    caption: "Fahrer-Nr.",
    dataType: "number",
    alignment: "left",
    minWidth: get_column_min_width(10),
  }, {
    dataField: "f002",
    caption: "Anrede",
    alignment: "left",
    cellRender: (item) => {
      return <>{format_abschleppunternehmen_fahrer_anrede(item.value)}</>
    },
    minWidth: get_column_min_width(6),
  }, {
    dataField: "f003",
    caption: "Vorname",
    alignment: "left",
    minWidth: get_column_min_width(7),
  }, {
    dataField: "f004",
    caption: "Name",
    minWidth: get_column_min_width(4),
  }, {
    dataField: "f005",
    caption: "Straße",
    minWidth: get_column_min_width(6),
  }, {
    dataField: "f006",
    caption: "PLZ",
    alignment: "left",
    minWidth: get_column_min_width(3),
  }, {
    dataField: "f007",
    caption: "Ort",
    minWidth: get_column_min_width(3),
  }, {
    dataField: "f008",
    caption: "Festnetznummer",
    alignment: "left",
    minWidth: get_column_min_width(14),
  }, {
    dataField: "f009",
    caption: "Mobilnummer",
    alignment: "left",
    minWidth: get_column_min_width(11),
  }, {
    dataField: "f010",
    caption: "E-Mail",
    alignment: "left",
    minWidth: get_column_min_width(6),
  }
]

export const abschlepp_unternehmen_columns: IColumn[] = [
  {
    dataField: "lfdnr",
    caption: "Firmen-Nr.",
    dataType: "number",
    alignment: "left",
    minWidth: get_column_min_width(10),
  }, {
    dataField: "f001",
    caption: "Firmenname 1",
    minWidth: get_column_min_width(12),
  }, {
    dataField: "f002",
    caption: "Firmenname 2",
    minWidth: get_column_min_width(12),
  }, {
    dataField: "f003",
    caption: "Straße",
    minWidth: get_column_min_width(6),
  }, {
    dataField: "f004",
    caption: "PLZ",
    alignment: "left",
    minWidth: get_column_min_width(3),
  }, {
    dataField: "f005",
    caption: "Ort",
    minWidth: get_column_min_width(3),
  }, {
    dataField: "f006",
    caption: "Festnetznummer",
    alignment: "left",
    minWidth: get_column_min_width(14),
  }, {
    dataField: "f007",
    caption: "Mobilnummer",
    alignment: "left",
    minWidth: get_column_min_width(11),
  }, {
    dataField: "f008",
    caption: "E-Mail",
    alignment: "left",
    minWidth: get_column_min_width(6),
  }, {
    dataField: "f009",
    caption: "Protokollpasswort",
    alignment: "left",
    visible: false,
  }
]
export const konfigurator_columns: IColumn[] =
  [
    {
      dataField: "f003",
      caption: "Schlüssel",
      alignment: "left",
    }, {
      dataField: "f004",
      caption: "Wert",
      alignment: "left",
    }, {
      dataField: "f005",
      alignment: "left",
      caption: "Optionen",
    }
  ];
export const haupt_gruppen_columns = (): IColumn[] => {
  return [
    {
      dataField: "lfdnr",
      caption: "Hautpgruppen-Nr",
      dataType: "number",
      alignment: "left",
      minWidth: get_column_min_width(15),
    }, {
      dataField: "f003",
      caption: "Bezeichnung",
      minWidth: get_column_min_width(11),
    }, {
      dataField: "f004",
      caption: "Darstellung",
      dataType: "number",
      alignment: "left",
      cellRender: (item) => {
        //Was machen wir hier mit PDF 
        return (item.value) ?
          (<>
            <img
              //  alt={"Bildnummer: " + stretch0Left(item.value.toString(), 3)}
              alt={tatbestand_haupt_katogieren_alt_bilder[item.value]}
              src={"/Files/WinowigOnlineNT/Source/img/tatbestandgruppen/hauptgruppen/Hauptgruppe" + stretch0Left(item.value.toString(), 3) + ".png"}
              style={{ backgroundColor: "rgba(25, 94, 163,1)", width: "65px", height: "65px" }}
              onError={handleErrorImg}
            />
          </>) : <></>
        // return (<>hier ist Bild nummer{item.value}</>)

      },
      minWidth: get_column_min_width(11),
    }, {
      dataField: "f005",
      caption: "Gruppe verwendet",
      dataType: "number",
      alignment: "left",
      cellRender: (item) => {
        return <>{format_haupt_gruppe_verwendet(item.value)}</>
      },
      minWidth: get_column_min_width(16),
    }
  ]
}

export const format_haupt_gruppe_verwendet = (value: number): string => {
  switch (value) {
    case 0:
      return "Nein";
    case 1:
      return "Allgemeine OWIG";
    case 2:
      return "Verkehrs OWIG";
    case 3:
      return "Allg. OWIG und Verkehrs-OWIG";
    case 4:
      return "Fliessverkehr";
    case 5:
      return "Fliessverkehr und Verkehrs-OWIG";
    case 6:
      return "in den Bereichen AOWI, VOWI, FOWI verwenden";
    case 7:
      return "AMM Feststellungen";
    case 8:
      return "AMM und AOWI";
    case 9:
      return "AMM, AOWI und VOWI";
    default:
      return "Nicht definiert";
  }
}
export const unter_gruppen_columns = (): IColumn[] => {
  return [
    {
      dataField: "f001",
      caption: "Hautpgruppen-Nr",
      visible: false,
      alignment: "left",
      minWidth: get_column_min_width(15),
    }, {
      dataField: "f002",
      caption: "Untergruppen-Nr",
      dataType: "number",
      alignment: "left",
      minWidth: get_column_min_width(15),

    }, {
      dataField: "f003",
      caption: "Bezeichnung",
      minWidth: get_column_min_width(11),

    }, {
      dataField: "f004",
      caption: "Darstellung",
      dataType: "number",
      alignment: "left",
      cellRender: (item) => {
        return (item.value) ?
          (<>
            <img
              //alt={"Bildnummer: " + stretch0Left(item.value.toString(), 3)}
              alt={tatbestand_unter_katogieren_alt_bilder[item.value]}
              src={"/Files/WinowigOnlineNT/Source/img/tatbestandgruppen/untergruppen/Untergruppe" + stretch0Left(item.value.toString(), 3) + ".png"}
              style={{ backgroundColor: "rgba(25, 94, 163,1)" }}
              onError={handleErrorImg}
            />
          </>) : <></>
      },
      minWidth: get_column_min_width(11),
    }, {
      dataField: "f005",
      caption: "Gruppe verwendet",
      dataType: "number",
      alignment: "left",
      cellRender: (item) => {
        return <>{format_unter_gruppe_verwendet(item.value)}</>
      },
      minWidth: get_column_min_width(16),
    }
  ]
}

export const format_unter_gruppe_verwendet = (value: number): string => {
  switch (value) {
    case 0:
      return "Nein";
    case 1:
      return "Ja";
    default:
      return "Nicht definiert";
  }
}

export const orte_columns = (): IColumn[] => {
  return [
    {
      dataField: "lfdnr",
      caption: "Laufende Nummer",
      dataType: "number",
      alignment: "left"
    }, {
      dataField: "f001",
      caption: "Bezeichnung",
    }, {
      dataField: "f002",
      caption: "AOWI Tatbestände",
      alignment: "left",
    }
  ]
}
export const bezirk_columns = (): IColumn[] => {
  return [{
    dataField: "lfdnr",
    caption: "Laufende Nummer",
    dataType: "number",
    alignment: "left"
  }, {
    dataField: "lfdnrort",
    caption: "Laufende Nummer",
    dataType: "number",
    alignment: "left",
    visible: false,
  }, {
    dataField: "f001",
    caption: "Bezeichnung",
  }
  ]
}

export const strasse_columns = (): IColumn[] => {
  return [{
    dataField: "lfdnr",
    caption: "Laufende Nummer",
    dataType: "number",
    alignment: "left"
  }, {
    dataField: "f001",
    caption: "Bezeichnung",
  }, /*{ MD 22.08.2022 Handyparken wird nicht benötigt.
    dataField: "f002",
    caption: "Handyparken",
    alignment: "left",
    cellRender: (item) => { return (<p>{item.value === 1 ? "Ja" : "Nein"}</p>) }
  }, */{
    dataField: "f003",
    caption: "Parkzone",
    alignment: "left",
  }, {
    dataField: "f005",
    caption: "Hinweis",
  },
  ]
}

export const strasse_detail_columns = (): IColumn[] => {
  return [{
    dataField: "lfdnr",
    caption: "Laufende Nummer",
    dataType: "number",
    alignment: "left"
  }, {
    dataField: "f001",
    caption: "Bezeichnung",
  }
  ]
}

export const Statistik_GeraeteLogins_Spalten = (): IColumn[] => {
  return [
    {
      dataField: "sachbearbeitername",
      caption: "Sachbearbeiter",
      minWidth: get_column_min_width(14),
    }, {
      dataField: "zeitstempel",
      caption: "Loginuhrzeit",
      alignment: "left",
      cellRender: (data) => {
        return <>{format_geraete_logins_zeitstemple(data.value)}</>
      },
      minWidth: get_column_min_width(12),
    }, {
      dataField: "ort",
      caption: "Ort",
      minWidth: get_column_min_width(3),
    }, {
      dataField: "strasse",
      caption: "Straße",
      minWidth: get_column_min_width(6),
    }, {
      dataField: "strassendetail",
      caption: "Straßendetail",
      minWidth: get_column_min_width(13),
    }, {
      dataField: "fallanzahl",
      caption: "Fallanzahl",
      alignment: "left",
      dataType: 'number',
      minWidth: get_column_min_width(10),
    }
  ]
}



export const format_fallart = (row: IFallData): string => {
  let response = "";
  row.f005 === "Fall Aenderung" ? response = "Fall Änderung" : response = row.f005;
  (row.f010 && row.f010.absZSU) && (response += " (Schleppung)");
  return response
}

export const format_faelle_kfz_kennzeichen = (row: IFallData): string => {
  let response = row.f007;
  (row.f010 && row.f010.kzzusatz) && (response += " " + row.f010.kzzusatz)

  return response;
}
export const format_faelle_status = (status: number): string => {
  if (status === 0) {
    return "noch zu verarbeiten"
  } else if (status === 1) {
    return "verarbeitet";
  } else if (status === 2) {
    return "storniert durch Winowig Online SB";
  } else if (status === 3) {
    return "storniert durch MDE SB";
  } else if (status === 4) {
    return "storniert in Vorverarbeitung";
  } else if (status === 5) {
    return "storniert da bereits exportiert";
  } else if (status === 6) {
    return "storniert da bereits gelöscht";
  } else if (status === 7) {
    return "storniert da QRCode bereits verwendet wurde";
  } else if (status === 9) {
    return "storniert aufgrund doppeltem Aktenzeichen";
  } else {
    return "";
  }
}

export const format_faelle_zeitstempel = (f008: string): string => {
  let zeitstempel = f008;
  let response = '';
  zeitstempel &&
    (response = zeitstempel.substr(8, 2) + ":" + zeitstempel.substr(10, 2) + ":" + zeitstempel.substr(12, 2) + " am " + zeitstempel.substr(6, 2) + "." + zeitstempel.substr(4, 2) + "." + zeitstempel.substr(0, 4));
  return response
}

export const format_faelle_export_sachbearbeiter_nummer = (value?: number): string => {
  return (value && value !== 0) ? value.toString() : "";
}
export const format_faelle_bar_bezahlt = (f010: any): string => {
  return (f010 && f010.Bar && f010.Bar === "Ja") ? "Ja" : "Nein";
}
export const rendere_tatbestandsnummer = (data: IFallData): string => {
  return get_angezeigte_tatbestand_nummer(sortTatbestaende(data));
}

export const format_faelle_handy_parken = (f010: any): string => {
  const handyparkenStatus = f010.MPStatus;
  const handyparkenStatusInt = parseInt(handyparkenStatus);
  let status = '';

  if (handyparkenStatus && !isNaN(handyparkenStatusInt)) {
    let parkDatum = f010.MPDate;
    if (parkDatum) parkDatum = parkDatum.substr(6, 2) + "." + parkDatum.substr(4, 2) + "." + parkDatum.substr(0, 4);

    let parkZeit = f010.MPTime;
    if (parkZeit) parkZeit = parkZeit.substr(0, 2) + ":" + parkZeit.substr(2, 2);

    if (!parkDatum) parkDatum = "";
    if (!parkZeit) parkZeit = "";

    if (handyparkenStatusInt < 0) status = "Es ist ein Fehler aufgetreten"
    else if (handyparkenStatusInt === 0) status = "Server belegt"
    else if (handyparkenStatusInt === 1) status = "Fahrzeug ist nicht registriert"
    else if (handyparkenStatusInt === 2) status = "Ungültiger Zeitraum"
    else if (handyparkenStatusInt === 10) status = "Parkzeit ist am " + parkDatum + " um " + parkZeit + " Uhr abgelaufen"
    else if (handyparkenStatusInt === 11) status = "Parkzeit läuft bis " + parkDatum + " um " + parkZeit + " Uhr";

    return status
  } else {
    return ""
  };
}
export const format_faelle_grund = (item: any): string => {
  return item.f010 ? ((item.f004 === 2) ? item.f010.Löschgrund : item.f010.Grund ? item.f010.Grund : item.f010.loeschgrund) : "";
}

export const format_faelle_export_title = (row: IFallData): string => {

  let status: number = row.f006;
  let datum: string = row.f110;
  let uhrzeit: number = row.f111;
  let response: string = "";

  if (status === 0) {
    response = "Nicht exportiert";
  } else if (status === 1) {
    if (datum && uhrzeit && uhrzeit !== 0) {
      response = dbTimeToString(uhrzeit) + ' Uhr am ' + datum;
    } else {
      response = "Nicht exportiert";
    }
  } else if (status >= 2) {
    response = "storniert";
  }
  return response
}
export const FaelleAllColumns: IColumn[] =
  [
    {
      dataField: "f010.Akz",
      caption: "Aktenzeichen",
      dataType: "number",
      alignment: "left",
      visible: false,
      allowSorting: true,
      minWidth: get_column_min_width(12),
    }, {
      dataField: "f010.Kassenzeichen",
      caption: "Kassenzeichen",
      alignment: "left",
      visible: false,
      allowSorting: true,
      minWidth: get_column_min_width(13),
    }, {
      dataField: "f001",
      caption: "Sb. Nummer",
      alignment: "left",
      visible: false,
      allowSorting: true,
      minWidth: get_column_min_width(10),
    }, {
      dataField: "sachbearbeitername",
      caption: "Sb. Name",
      visible: false,
      allowSorting: false,
      minWidth: get_column_min_width(8),
    }, {
      dataField: "f002",
      caption: "Datum",
      alignment: "left",
      visible: false,
      allowSorting: true,
      minWidth: get_column_min_width(9),
    }, {
      dataField: "f003",
      caption: "Uhrzeit",
      alignment: "left",
      visible: false,
      cellRender: (item) => { return <>{dbTimeToString(item.value)}</> },
      allowSorting: true,
      minWidth: get_column_min_width(7),
    },
    /* brauchen wir das?? testen
      f110: {
        id: "f300",
        title: "Empfangsdatum",
        field: "f300",
        width: "100px",
        text: "Empfangsdatum"
      },
      f111: {
        id: "f301",
        title: "Empfangsuhrzeit",
        field: "f301",
        width: "100px",
        text: "Empfangsuhrzeit",
        template: "#= gContentFaelleVerkehr.formatUhrzeitColumn(f301) #"
      },*/

    {
      dataField: "f005",
      caption: "Fallart",
      alignment: "left",
      visible: false,
      cellRender: (item) => {
        return <>{format_fallart(item.data)}</>
      },
      allowSorting: true,
      minWidth: get_column_min_width(17), //MD Fall vornetierung
    }, {
      dataField: "f006",
      alignment: "left",
      caption: "Status",
      visible: false,
      cellRender: (item) => {
        let status: number = item.data.f006;
        return <>{format_faelle_status(status)}</>
      },
      allowSorting: true,
      minWidth: get_column_min_width(15), //Vorverarbeitung
    }, {
      dataField: "f007",
      caption: "KFZ-Kennzeichen",
      visible: false,
      cellRender: (item) => {
        let response = format_faelle_kfz_kennzeichen(item.data);
        if (item.data.f006 > 1) {
          return (<span style={{ textDecoration: "line-through" }}>{response}</span>);
        } else {
          return <>{response}</>;
        }
      },
      alignment: "left",
      allowSorting: true,
      minWidth: get_column_min_width(15),
    }, {
      dataField: "f008",
      caption: "Zeitstempel Ursp.",
      visible: false,
      cellRender: (item) => {
        return <>{format_faelle_zeitstempel(item.data.f008)}</>
      },
      allowSorting: true,
      alignment: "left",
      minWidth: get_column_min_width(17),
    }, {
      dataField: "betrag",
      caption: "Betrag",
      alignment: "left",
      visible: false,
      cellRender: (item) => {
        return <>{getGesamtbetragAusF010(item.data.f010)}</>
      },
      allowSorting: false,
      minWidth: get_column_min_width(6),
    }, {
      dataField: "f70",
      caption: "Matchcode",
      alignment: "left",
      visible: false,
      allowSorting: true,
      minWidth: get_column_min_width(9),
    }, {
      dataField: "f100",
      caption: "Gerätekennung",
      visible: false,
      alignment: "left",
      allowSorting: true,
      minWidth: get_column_min_width(13),
    }, {
      dataField: "f101",
      caption: "Gerätename",
      visible: false,
      alignment: "left",
      allowSorting: true,
      minWidth: get_column_min_width(10),
    }
    , {
      dataField: "f102",
      caption: "Geräte OS",
      visible: false,
      allowSorting: true,
      minWidth: get_column_min_width(9),
    }
    , {
      dataField: "f103",
      caption: "Geräte OS-Version",
      visible: false,
      alignment: "left",
      allowSorting: true,
      minWidth: get_column_min_width(17),
    }
    , {
      dataField: "f104",
      caption: "Gerätemodell",
      visible: false,
      alignment: "left",
      allowSorting: true,
      minWidth: get_column_min_width(12),
    }
    , {
      dataField: "f105",
      caption: "Client Version",
      visible: false,
      allowSorting: true,
      alignment: "left",
      minWidth: get_column_min_width(14),
    }, {
      dataField: "f106",
      caption: "Batterielevel",
      visible: false,
      dataType: "number",
      alignment: "left",
      allowSorting: true,
      minWidth: get_column_min_width(13),
    }, {
      dataField: "f110",
      caption: "Exportdatum",
      visible: false,
      alignment: "left",
      allowSorting: true,
      minWidth: get_column_min_width(11),
    }, {
      dataField: "f111",
      caption: "Exportuhrzeit",
      dataType: "number",
      alignment: "left",
      visible: false,
      cellRender: (item) => { return <>{dbTimeToString(item.value)}</> },
      allowSorting: true,
      minWidth: get_column_min_width(13),
    }, {
      dataField: "f112",
      caption: "Export Sb. Nummer",
      dataType: "number",
      alignment: "left",
      visible: false,
      cellRender: (item) => { return <>{format_faelle_export_sachbearbeiter_nummer(item.value)}</> },
      allowSorting: true,
      minWidth: get_column_min_width(17),
    }, {
      dataField: "f120",
      caption: "GPS",
      visible: false,
      cellRender: (item) => { return <>{item.data.f120 ? (item.data.f120 + "," + item.data.f121) : ""}</> },
      allowSorting: false,
      minWidth: get_column_min_width(8),
    }, {
      dataField: "f200",
      caption: "QR-ID",
      visible: false,
      allowSorting: true,
      alignment: "left",
      minWidth: get_column_min_width(6), //MD 15.02.2023 neu
    }, {
      dataField: "exportSachbearbeitername",
      caption: "Export Sb. Name",
      visible: false,
      allowSorting: false,
      alignment: "left",
      minWidth: get_column_min_width(15),
    }
    , {
      dataField: "f113",
      caption: "Serverversion",
      dataType: "number",
      visible: false,
      allowSorting: true,
      alignment: "left",
      minWidth: get_column_min_width(13),
    }, {
      dataField: "f010.strasse",
      caption: "Straße",
      visible: false,
      allowSorting: false,
      minWidth: get_column_min_width(6),
    }, {
      dataField: "f010.ort",
      caption: "Ort",
      visible: false,
      allowSorting: false,
      minWidth: get_column_min_width(3),
    }, {
      dataField: "f010.KzArt",
      caption: "Kennzeichenart",
      visible: false,
      allowSorting: false,
      alignment: "left",
      minWidth: get_column_min_width(14),
    }, {
      dataField: "f010.Zeugen",
      caption: "Zeugen",
      visible: false,
      allowSorting: false,
      minWidth: get_column_min_width(6),
    }, {
      dataField: "f010.bar",
      caption: "Bar bezahlt",
      visible: false,
      cellRender: (item) => { return <>{format_faelle_bar_bezahlt(item.data.f010)}</> },
      allowSorting: false,
      alignment: "left",
      minWidth: get_column_min_width(11),
    }, {
      dataField: "f010.MPStatus",
      caption: "Handyparken",
      visible: false,
      cellRender: (item) => { return <>{format_faelle_handy_parken(item.data.f010)}</> },
      allowSorting: false,
      alignment: "left",
      minWidth: get_column_min_width(11),
    }, { //MD 11.08.2023 Tatbestandsnummer neu aufnehmen
      dataField: "f010.tatbestandsnummer",
      caption: "Tatbestandsnummer",
      visible: false,
      cellRender: (item) => { return <>{rendere_tatbestandsnummer(item.data)}</> },
      allowSorting: false,
      alignment: "left",
      minWidth: get_column_min_width(14),
    }, {
      dataField: "f010.Grund",
      caption: "Löschgrund",
      visible: false,
      cellRender: (item) => {
        return <>
          {format_faelle_grund(item.data)}</>
      },
      allowSorting: false,
      alignment: "left",
      minWidth: get_column_min_width(10),
    }, {
      dataField: "exportTitel",
      caption: "Export",
      visible: false,
      cellRender: (item) => {
        return <>{format_faelle_export_title(item.data)}</>
      },
      allowSorting: true,
      alignment: "left",
      minWidth: get_column_min_width(10), //Nicht exportiert. Nur exportiert, rest neue Zeile
    }, {
      dataField: "f010.Notiz",
      caption: "Notiz",
      visible: false,
      allowSorting: false,
      alignment: "left",
      minWidth: get_column_min_width(5),
    }, {
      dataField: "f132",
      caption: "Quittungsnummer",
      visible: false,
      allowSorting: true,
      alignment: "left",
      minWidth: get_column_min_width(15),
    }, {
      dataField: "f300",
      caption: "Empfangsdatum",
      visible: false,
      allowSorting: true,
      minWidth: get_column_min_width(13),
    }, {
      dataField: "f301",
      caption: "Empfangszeit",
      dataType: "number",
      alignment: "left",
      visible: false,
      cellRender: (item) => { return <>{dbTimeToString(item.value)}</> },
      allowSorting: true,
      minWidth: get_column_min_width(12),
    },
    /*
    { // Name und Vorname neu. Müssen noch angepasst werden
      dataField:"f010.Name",
      caption:"Name",
      visible: false,
    },{
      dataField:"f010.Vorname",
      caption:"Vorname",
      visible: false,
    },*/

    /*		Das brauchen wir momentan nicht. Beim klicken kann man den Fall löschen	
    loeschen: {
      sortable: false,
      id: "loeschen",
      title: gLocalizedStrings.faelleVerkehrLoeschenTitel[gLocale],
      field: "",
      width: "54px",	
      text: gLocalizedStrings.faelleVerkehrLoeschenText[gLocale],							
      template: "#= gContentFaelleVerkehr.formatDeleteColumn(fallart, f007, f002, f003, f006, f110, f111, f008, f005, bar) #"
    }*/
  ]