import { Popup, Validator } from 'devextreme-react';
import { TextBox } from 'devextreme-react/text-box';
import { ToolbarItem } from 'devextreme-react/autocomplete';
import { useCallback, useMemo, useState } from 'react';
import { NumericRule, PatternRule, StringLengthRule } from 'devextreme-react/data-grid';
import { IFahrzeugDaten } from './interfaces';
import { Seperatorliste } from '../../../global/seperator_liste';
import { GetData } from '../../../global/communication';
import { useAppDispatch } from '../../../state';
import { handleContentReadyTextBox } from "../../../global/hilfs_functions";

interface IFahrzeugPopupProps {
    callBack: (update?: boolean, loeschen?: boolean) => void,
    anlegen: boolean,
    data: IFahrzeugDaten,
    lfdnr: number,
}
const Ausdruck = /([a-zA-Z]{1,3})-([a-zA-Z]{1,2})\s([0-9]{1,4})/; // aus alte winowig online
const FahrzeugPopup: React.FC<IFahrzeugPopupProps> = ({ callBack, anlegen, data, lfdnr }) => {

    let data_kfz_kennzeichen = null;
    if (data !== null) data_kfz_kennzeichen = Ausdruck.exec(data.f001);
    const dispatch = useAppDispatch();
    const [kfzKennzeichen1, setKFZKennzeichen1] = useState((anlegen || data_kfz_kennzeichen === null) ? "" : data_kfz_kennzeichen[1]);
    const [kfzKennzeichen2, setKFZKennzeichen2] = useState((anlegen || data_kfz_kennzeichen === null) ? "" : data_kfz_kennzeichen[2]);
    const [kfzKennzeichen3, setKFZKennzeichen3] = useState((anlegen || data_kfz_kennzeichen === null) ? "" : data_kfz_kennzeichen[3]);

    const handleClickSpeichern = useCallback((e: any) => {
        let result = e.validationGroup.validate();
        if (result.isValid) {

            let daten = new Seperatorliste("%00", "%01");


            daten.setValue("Firmennummer", lfdnr?.toString());
            //MD 20.07.2023 hat gefehlt.
            !anlegen && daten.setValue("KfzKennzeichenAlt", data.f001);
            daten.setValue("KfzKennzeichen", kfzKennzeichen1 + '-' + kfzKennzeichen2 + ' ' + kfzKennzeichen3);

            GetData({
                Operation: anlegen ? "InsertAbschleppfahrzeug" : "UpdateAbschleppfahrzeug",
                Daten: daten.getSeperatorliste()
            }, dispatch).then((res) => {
                if (!res) return
                callBack(true);
            })

        }
    }, [lfdnr, kfzKennzeichen1, kfzKennzeichen2, kfzKennzeichen3, anlegen, callBack, dispatch, data.f001]);


    const handleCallBack = useCallback(() => {
        callBack();
    }, [callBack]);

    const memo_options_ok = useMemo(() => {
        return {
            text: 'OK',
            onClick: handleCallBack,
        }
    }, [handleCallBack]);
    const memo_options_abbrechen = useMemo(() => {
        return {
            text: 'Abbrechen',
            onClick: handleCallBack,
        }
    }, [handleCallBack]);
    const handleCallBackLoeschen = useCallback(() => {
        callBack(undefined, true)
    }, [callBack]);

    const memo_options_loeschen = useMemo(() => {
        return {
            text: 'Löschen',
            type: "danger",
            onClick: handleCallBackLoeschen,
        }
    }, [handleCallBackLoeschen]);




    const memo_options_speichern = useMemo(() => {
        return {
            text: 'Speichern',
            onClick: handleClickSpeichern,
        }
    }, [handleClickSpeichern]);


    const handleChangeKFZ1 = useCallback((e: any) => {
        setKFZKennzeichen1(e.toUpperCase())
    }, []);
    const handleChangeKFZ2 = useCallback((e: any) => {
        setKFZKennzeichen2(e.toUpperCase())
    }, []);

    const RenderePopupTitle = useCallback(() => {
        return (<h2 className="title_popup">{anlegen ? "Neues Fahrzeug anlegen" : "Fahrzeug bearbeiten"}</h2>)
    }, [anlegen]);

    if (!anlegen && (data_kfz_kennzeichen === null || data_kfz_kennzeichen.length < 1)) return (<>
        <Popup
            visible={true}
            onHiding={handleCallBack}
            hideOnOutsideClick={true}
            fullScreen={false}
            showCloseButton={true}
            maxHeight="210px"
            maxWidth="350px"
        >
            <ToolbarItem
                toolbar="top"
                location={'before'}
                render={RenderePopupTitleFehler}
            />
            <ToolbarItem
                widget="dxButton"
                toolbar="bottom"
                location="after"
                options={memo_options_ok}
            />
            <p>KFZ-Kennzeichen "{data.f001}" kann nicht richtig gelesen werden.</p></Popup>
    </>);
    return (
        <Popup
            visible={true}
            onHiding={handleCallBack}
            hideOnOutsideClick={true}
            fullScreen={false}
            showCloseButton={true}
            // maxHeight="319px"
            maxWidth="550px"
            height="250px"
        >
            <ToolbarItem
                toolbar="top"
                location={'before'}
                render={RenderePopupTitle}
            />
            {!anlegen &&
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="before"
                    options={memo_options_loeschen}
                />
            }
            <ToolbarItem
                widget="dxButton"
                toolbar="bottom"
                location="after"
                options={memo_options_speichern}
            />
            <ToolbarItem
                widget="dxButton"
                toolbar="bottom"
                location="after"
                options={memo_options_abbrechen}
            />
            <div className='row'>
                <div className='col-12'>
                    <h4 className='mt-3 mb-2'>KFZ-Kennzeichen</h4>
                </div>
                <div className='col-4'>
                    <TextBox
                        label=''
                        labelMode='floating'
                        stylingMode='outlined'
                        mode='text'
                        showClearButton={false}
                        onValueChange={handleChangeKFZ1}
                        value={kfzKennzeichen1}
                        valueChangeEvent="input"
                        placeholder='AB'
                        maxLength={3}
                        onContentReady={handleContentReadyTextBox}
                    >
                        <Validator >
                            {/** <NumericRule message='Nur Zahlen dürfen eingegeben werden.'/> */}

                            <PatternRule
                                pattern="^[a-zA-Z]+$"
                                message="Es dürfen nur Bechstaben eingegeben werden."
                            />
                            <StringLengthRule ignoreEmptyValue={false} min={1} max={3} message='Das Unterscheidungszeichen darf nur aus 1 bis 3 Buchstaben bestehen' />
                        </Validator>
                    </TextBox>
                </div>
                <div className='col-1' style={{ textAlign: "center", paddingTop: "0.3rem", fontWeight: "bolder", fontSize: "xx-large" }}>
                    <p>-</p>
                </div>
                <div className='col-3'>
                    <TextBox
                        label=''
                        labelMode='floating'
                        stylingMode='outlined'
                        mode='text'
                        showClearButton={false}
                        onValueChange={handleChangeKFZ2}
                        value={kfzKennzeichen2}
                        valueChangeEvent="input"
                        placeholder='CD'
                        maxLength={2}
                        onContentReady={handleContentReadyTextBox}
                    >
                        <Validator >
                            {/** <NumericRule message='Nur Zahlen dürfen eingegeben werden.'/> */}

                            <PatternRule
                                pattern="^[a-zA-Z]+$"
                                message="Es dürfen nur Bechstaben eingegeben werden."
                            />
                            <StringLengthRule ignoreEmptyValue={false} min={1} max={2} message='Der erste Teil der Erkennungsnummer darf nur aus 1 bis 2 Buchstaben bestehen' />
                        </Validator>
                    </TextBox>
                </div>
                <div className='col-4'>
                    <TextBox
                        label=''
                        labelMode='floating'
                        stylingMode='outlined'
                        mode='text'
                        showClearButton={false}
                        onValueChange={setKFZKennzeichen3}
                        value={kfzKennzeichen3}
                        valueChangeEvent="input"
                        placeholder='123'
                        maxLength={4}
                        onContentReady={handleContentReadyTextBox}
                    >
                        <Validator >
                            <NumericRule message='Nur Zahlen dürfen eingegeben werden.' />
                            <StringLengthRule ignoreEmptyValue={false} min={1} max={4} message='Der zweite Teil der Erkennungsnummer darf nur aus 1 bis 4 stelligen Zahl bestehen' />
                        </Validator>
                    </TextBox>
                </div>
            </div>

        </Popup>
    )
}
export default FahrzeugPopup;

const RenderePopupTitleFehler = () => {
    return (<h2 className="title_popup">Fehler</h2>)
};