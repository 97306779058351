import { DataGrid, TextBox } from "devextreme-react";
import { Column, Toolbar, Item as ItemGridToolbar, Button as ButtonGrid, Scrolling, Paging } from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import { memo, useCallback, useMemo, useRef, useState } from "react";
import { ISelectedItemStrassen } from "..";
import { GetData } from "../../../../global/communication";
import { bezirk_columns } from "../../../../sonstiges/columns";
import { icon_edit, icon_pdf, MAX_ITEMS_DATA_GRID } from "../../../../global/const";
import { exportGridAsPDF, get_search_as_filter, handleContentReadyDataGrid, handleContentReadyTextBox } from "../../../../global/hilfs_functions";
import { MDButton, RendereGridTitle } from "../../../../global/components";
import { IBezirk } from "../interfaces";
import StrassenExport from "../strassen_export";
import StrassenUpload from "../strassen_import";
import BezirkAdd from "./add";



interface IBezirkGridProps {
    selectedItem: any,
    setSelectedItem: (obj: ISelectedItemStrassen) => void,
    setBearbeitePopup: (value: { show: boolean, row: IBezirk | null }) => void,
    refresh_grid: boolean,
}


const columns = bezirk_columns();
const BezirkeGrid: React.FC<IBezirkGridProps> = ({ setSelectedItem, selectedItem, setBearbeitePopup, refresh_grid }) => {
    const refGrid = useRef() as React.MutableRefObject<DataGrid<any, any>>;
    const [searchValue, setSearchValue] = useState("");
    const filter = useMemo(() => get_search_as_filter(searchValue), [searchValue]);
    const store_end = useMemo(() => store(filter, selectedItem.lfdnr_ort),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [selectedItem.lfdnr_ort, refresh_grid, filter]);

    const get_data = useCallback(() => {
        //  setSearchValue("");
        //  refGrid && refGrid.current && refGrid.current.instance.clearSorting();
        refGrid && refGrid.current && refGrid.current.instance.refresh();
    }, []);

    const handleClickBezirkSelected = useCallback((e: any) => {
        if (e.event.originalEvent.currentTarget.role !== "button") {
            setSelectedItem({ ...selectedItem, lfdnr_bezirk: e.data.lfdnr, bezeichnung_bezirk: e.data.f001 })
        }
    }, [selectedItem, setSelectedItem]);


    const handleClickBearbeiteBezirk = useCallback((e: any) => {
        setBearbeitePopup({ show: true, row: JSON.parse(e.element.getAttribute("data")) })
    }, [setBearbeitePopup]);

    const handlePDF = useCallback(() => {
        let header = get_header_pdf_bezirk(searchValue, selectedItem.bezeichnung_ort || "")
        exportGridAsPDF(refGrid, "bezirke.pdf", header, "landscape", "striped")
    }, [searchValue, selectedItem]);

    const RendereTitle = useCallback(() => {
        return RendereGridTitle(selectedItem.bezeichnung_ort)
    }, [selectedItem.bezeichnung_ort]);

    const RenderButtonBezirkBearbeite = useCallback((e: any) => {
        return (<MDButton
            page='strassen_bezirk'
            tooltip_text='Bezirk bearbeiten'
            icon={icon_edit}
            onClick={handleClickBearbeiteBezirk}
            data={e.data}
        />)
    }, [handleClickBearbeiteBezirk]);

    return (
        <div className="data_grid_container  data_grid_with_header mt-2">
            <DataGrid
                dataSource={store_end}
                columnAutoWidth={false}
                //  keyExpr="lfdnr"
                wordWrapEnabled={true}
                renderAsync={true}
                className=" animate__animated animate__fadeIn cursor_pointer"
                remoteOperations={true}
                ref={refGrid}
                rowAlternationEnabled={true}
                noDataText="Keine Bezirke"
                onRowClick={handleClickBezirkSelected}
                hoverStateEnabled={true}
                onContentReady={handleContentReadyDataGrid}
            >
                <Scrolling mode="virtual" rowRenderingMode="virtual" preloadEnabled={true} />
                <Paging defaultPageSize={MAX_ITEMS_DATA_GRID} />
                {columns.map((column) => {
                    return (
                        <Column
                            dataField={column.dataField}
                            caption={column.caption}
                            dataType={column.dataType}
                            alignment={column.alignment}
                            cellRender={column.cellRender}
                            key={column.dataField}
                            visible={column.visible}
                        />
                    )
                })}
                <Column type="buttons">
                    <ButtonGrid
                        cssClass={'d-inline-block'}
                        render={RenderButtonBezirkBearbeite} />
                </Column>
                <Toolbar>
                    <ItemGridToolbar
                        location={'before'}
                        render={RendereTitle}
                    ></ItemGridToolbar>
                    <ItemGridToolbar
                        location={'after'}
                        locateInMenu="never"
                    >
                        <TextBox
                            label="Suche"
                            stylingMode='outlined'
                            mode='text'
                            className="suche_feld"
                            value={searchValue}
                            onValueChange={setSearchValue}
                            showClearButton={true}
                            maxLength={60}
                            onContentReady={handleContentReadyTextBox}>
                        </TextBox>
                    </ItemGridToolbar>
                    <ItemGridToolbar location="after" locateInMenu="auto">
                        <BezirkAdd selectedItem={selectedItem} get_data={get_data} />
                    </ItemGridToolbar>
                    <ItemGridToolbar location="after" locateInMenu="auto">
                        <StrassenUpload get_data={get_data} />
                    </ItemGridToolbar>
                    <ItemGridToolbar location="after" locateInMenu="auto">
                        <StrassenExport />
                    </ItemGridToolbar>
                    <ItemGridToolbar location="after" locateInMenu="auto">
                        <MDButton
                            page="strasse_bezirke"
                            icon={icon_pdf}
                            onClick={handlePDF}
                            tooltip_text="Tabelle als PDF herunterladen"
                        />
                    </ItemGridToolbar>
                </Toolbar>
            </DataGrid>
        </div>
    )
}
export default memo(BezirkeGrid);






const store = (filter: string, lfdnr_ort?: number) => {
    //Dies sollte hier abgefragt werden und nicht unten. So wird sicher gestellt, dass kein neues Store generiert wird.
    if ((!lfdnr_ort) && lfdnr_ort !== 0) return []

    return new CustomStore({
        key: ["lfdnr"],
        load(loadOptions: any) {

            //Achtung isLoadingAll => pdf Download
            return GetData(
                {
                    Operation: "GetBezirke",
                    LfdNrOrt: (lfdnr_ort || lfdnr_ort === 0) ? lfdnr_ort.toString() : "", // lfdnrort ist immer da
                    skip: (loadOptions.isLoadingAll || loadOptions.requireTotalCount) ? "0" : loadOptions["skip"].toString(),
                    take: loadOptions["isLoadingAll"] ? "1000" : loadOptions["take"].toString(),
                    filter: filter ? filter : "",
                    sortSpalte: loadOptions.sort ? loadOptions.sort[0].selector : "",
                    sortRichtung: loadOptions.sort ? loadOptions.sort[0].desc ? "DESC" : "ASC" : "",
                }, undefined, false
            )
                .then((res) => {
                    if (!res || !res.erfolg || res.data.length === 0)
                        return {
                            data: [],
                            totalCount: 0,
                        }
                    //  if(totalCount === 0){
                    //    setTotalCount(res.totalCount)
                    //  }else{
                    //  totalCount !== parseInt(res.totalCount) && setTotalCount(res.totalCount)
                    // }
                    return {
                        data: res.data,
                        totalCount: res.totalCount,
                    }

                });
            //   }


            //  }
        }

    });
}
/**
const RenderButtonBezirkDelete: React.FC = () => {
  return( <MDButton 
      page='strassen_bezirk'
      tooltip_text='Bezirk löschen'
      icon={icon_delete}
      type='danger'
      className="mr-2"
    />)
}

*/


export const get_header_pdf_bezirk = (searchValue: string, ort: string) => {
    let header = "";
    searchValue && (header += "Suchbegriff: " + searchValue + "\n");
    header += "Ort: " + ort;
    return header
}