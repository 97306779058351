import { Button, Popup, ScrollView, TextArea } from "devextreme-react"
import { useCallback, useEffect, useState } from "react";
import { IDrucklayout } from "../../interfaces";
import { GetData } from "../../../../global/communication";
import { useScreenSize } from "../../../../global/media-query";
import { setConfirm, useAppDispatch } from "../../../../state";
import { useMemo } from "react";
import { icon_refresh } from "../../../../global/const";
import { handleErrorImg, handleContentReadyTextBox } from "../../../../global/hilfs_functions";
import { ToolbarItem } from "devextreme-react/autocomplete";




interface ILayoutBearbeitePopupProps {
    setShowPopUp: (value: boolean) => void,
    row: IDrucklayout,
}

const LayoutBearbeitePopup: React.FC<ILayoutBearbeitePopupProps> = ({ setShowPopUp, row }): JSX.Element => {
    const [layoutValue, setLayoutValue] = useState("");
    const [imgParam, setImgParam] = useState({
        Operation: "CreateDrucklayoutVorschau",
        Time: new Date().getTime(),
        Druckermodell: row.druckermodell,
        Dateiname: row.dateiname,
    });
    const screenSize = useScreenSize();
    const dispatch = useAppDispatch();

    useEffect(() => {
        GetData({
            Operation: "GetDrucklayout",
            Druckermodell: row.druckermodell,
            Dateiname: row.dateiname,
        }, dispatch).then((res) => {
            if (!res) {
                setLayoutValue("");
                return
            }
            //.replace(/&#xD;&#xA;/g, "\n");
            const value3 = res.layout.replace(/%254/g, String.fromCharCode(254)).replace(/\r\n/g, "\n");
            setLayoutValue(value3)

        })
    }, [row, dispatch]);

    const handleHidePopup = useCallback(() => {
        setShowPopUp(false)
    }, [setShowPopUp]);

    const handleShowPopupLoeschen = useCallback(() => {
        delete_layout(dispatch, row, handleHidePopup);
    }, [handleHidePopup, dispatch, row]);

    const memo_options_loeschen = useMemo(() => {
        return {
            text: 'Löschen',
            type: "danger",
            stylingMode: "outlined",
            onClick: handleShowPopupLoeschen,
        }
    }, [handleShowPopupLoeschen]);

    const memo_options_abbrechen = useMemo(() => {
        return {
            text: 'Abbrechen',
            onClick: handleHidePopup,
        }
    }, [handleHidePopup]);

    const handleClickThron = useCallback(() => {

        const ele_focused = document.getElementById("druck_layout_text_area") as HTMLTextAreaElement;
        const selection_start = ele_focused.getElementsByTagName("textarea")[0].selectionStart;
        let sub1 = layoutValue.substring(0, selection_start);
        let sub2 = layoutValue.substring(selection_start);
        const new_value = sub1 + String.fromCharCode(254) + sub2;
        setLayoutValue(new_value);
    }, [layoutValue]);

    const memo_options_thron = useMemo(() => {
        return {
            //icon: thorn,
            text: "þ",
            onClick: handleClickThron
        }
    }, [handleClickThron]);

    const handleClickRefresh = useCallback(() => {
        GetData({
            Operation: "SetTempLayout",
            Layout: layoutValue,
        }, dispatch).then((res) => {
            if (!res) return
            setImgParam({
                Operation: "CreateDrucklayoutVorschauFromTempLayout",
                Time: new Date().getTime(),
                Dateiname: row.dateiname,
                Druckermodell: row.druckermodell,
            })

        })
    }, [layoutValue, dispatch, row.dateiname, row.druckermodell]);
    const memo_options_refresh = useMemo(() => {
        return {
            icon: icon_refresh,
            onClick: handleClickRefresh
        }
    }, [handleClickRefresh])

    const handleSpeichernClick = useCallback(() => {
        GetData({
            Operation: "ChangeDrucklayout",
            Druckermodell: row.druckermodell,
            Dateiname: row.dateiname,
            Layout: layoutValue,
        }, dispatch).then((res) => {
            if (!res) return
            setShowPopUp(false)

        })
    }, [layoutValue, row.dateiname, row.druckermodell, dispatch, setShowPopUp]);

    const memo_options_speichern = useMemo(() => {
        return {
            text: 'Speichern',
            onClick: handleSpeichernClick
        }
    }, [handleSpeichernClick]);
    return (
        <>
            <Popup
                visible={true}
                onHiding={handleHidePopup}
                dragEnabled={true}
                hideOnOutsideClick={true}
                fullScreen={false}
                showCloseButton={true}
                maxHeight="80vh"
                height="auto"
                maxWidth={(screenSize.isMedium || screenSize.isSmall) ? "90vw" : "1500px"}
            >
                <ToolbarItem
                    toolbar="top"
                    location={'before'}
                    render={RenderePopupTitle}
                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="before"
                    options={memo_options_loeschen}
                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="center"
                    render={ButtonThorn}
                    options={memo_options_thron}
                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="center"
                    options={memo_options_refresh}
                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={memo_options_speichern}
                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={memo_options_abbrechen}
                />

                {
                    screenSize.isSmall ?
                        <ScrollView>
                            <Child layoutValue={layoutValue} setLayoutValue={setLayoutValue} imgParam={imgParam} />
                        </ScrollView> : <Child layoutValue={layoutValue} setLayoutValue={setLayoutValue} imgParam={imgParam} />
                }

            </Popup>
        </>
    )
}

export default LayoutBearbeitePopup;
const RenderePopupTitle = () => {
    return (<h2 className="title_popup">Layout ändern</h2>)
};

const ButtonThorn = (props: any) => {

    return (
        <Button
            className="thorn"
            text={props.options.text}
            onClick={props.options.onClick}
            stylingMode="text"
            type="default"
        />
    )
}
const Child = (props: any) => {
    return (
        <div className="row p-2 p-md-3 mr-4">
            <div className="col-12 col-sm-8 p-1 p-md-2">
                <ScrollView>
                    <TextArea
                        label=""
                        labelMode="floating"
                        stylingMode="outlined"
                        value={props.layoutValue}
                        onValueChange={props.setLayoutValue}
                        valueChangeEvent="input"
                        height={"60vh"}
                        //  inputAttr={{ rows: "20" }}
                        id="druck_layout_text_area"
                        onContentReady={handleContentReadyTextBox}
                    />
                </ScrollView>
            </div>
            <div className="col-12 col-sm-4 p-1 p-md-2" style={{ maxHeight: "61.5vh" }}>
                <ScrollView>
                    <img
                        src={"/FileRequest/drucklayout.bmp?Operation=" + props.imgParam.Operation + "&Timestamp=" + props.imgParam.Time + "&Druckermodell=" + props.imgParam.Druckermodell + "&Dateiname=" + props.imgParam.Dateiname}
                        alt={"Layout Druckermodell: " + props.imgParam.Druckermodell + " Dateiname: " + props.imgParam.Dateiname}
                        onError={handleErrorImg}
                        width={"100%"}
                    ></img>
                </ScrollView>

            </div>
        </div>
    );
}


const delete_layout = (dispatch: any, data: any, callback: any) => {
    dispatch(setConfirm({
        body:
            <div className="alert alert-danger">
                <p>Wollen sie das DruckLayout mit dem Dateiname <b>{data.dateiname}</b> wirklich löschen?</p>
            </div>,
        on_ok: () => {
            GetData({
                Operation: "DeleteDrucklayout",
                Druckermodell: data.druckermodell,
                Dateiname: data.dateiname
            }, dispatch).then((res) => {
                if (!res) return
                callback();
            })
        },
        title: "Drucklayout löschen",
    }));
}