import { ContextMenu, DateBox, NumberBox, SelectBox, TextArea, TextBox } from "devextreme-react";
import ArrayStore from "devextreme/data/array_store";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { GetData } from "../../../global/communication";
import { daten_felder_vor_definiert } from "../../../global/const";
import { get_date_ajax_param, handleContentReadyDateBox, handleContentReadySelectBox, handleContentReadyTextBox } from "../../../global/hilfs_functions";
import { useAppDispatch } from "../../../state";
import { ITatbestandVerwaltePopupProps } from "./verwalten_popup";


const get_context_menu_items = (text_konkretisierungen: any[], daten_felder_vor_definiert: any[]) => {
    let end_arr = [];
    if (text_konkretisierungen.length === 0) return daten_felder_vor_definiert
    for (let i of text_konkretisierungen) {
        let _text = i.beispiel ? ' (' + i.beispiel + ')' : "";
        let end_text = i.satzart + _text;
        end_text && end_arr.push({ text: end_text });
    }
    return daten_felder_vor_definiert.concat([{ text: "Datenfelder benutzerdefiniert", items: end_arr }])
}

const text_area_row = { rows: "5" }
const data_source_tatbestand_typ = new ArrayStore({
    data: [
        { text: "Volltext-Tatbestand", value: "0" },
        { text: "Bundeseinheitl. Tatkatalog Basis-Tatbestand", value: "1" },
        { text: "Bundeseinheitl. Tatkatalog Tabellen-Tatbestand", value: "2" }
    ],
    key: 'value',
})
const data_source_winowig_mobil = new ArrayStore({
    data: [
        { text: "Automatische Festlegung", value: 0 },
        { text: "Ja", value: 1 },
        { text: "Nein", value: 2 }
    ],
    key: 'value',
})

const GrundDatenTab: React.FC<ITatbestandVerwaltePopupProps> = ({ setData, data, isAdd }): JSX.Element => {
    const [tbNummer, setTbNummer] = useState(data!.f02001!);
    const [tbText, setTbText] = useState(data!.f02002!);
    const [betrag, setBetrag] = useState(data!.f02007!);
    const [punkte, setPunkte] = useState(data!.f02009!);
    const [fahrVerbot, setFahrVerbot] = useState(data!.f02011!);
    const [gueltigVon, setGueltigVon] = useState(data!.f02017 ? new Date(parseInt(data!.f02017.substring(6, 10)), parseInt(data!.f02017.substring(3, 5)) - 1, parseInt(data!.f02017.substring(0, 2))) : undefined);
    const [gueltigBis, setGueltigBis] = useState(data!.f02018 ? new Date(parseInt(data!.f02018.substring(6, 10)), parseInt(data!.f02018.substring(3, 5)) - 1, parseInt(data!.f02018.substring(0, 2))) : undefined);
    const [tatbestandsKatalogValue, setTatbestandsKatalogValue] = useState(data!.f02040!);
    const [winowigMobilValue, setWinowigMobilValue] = useState(data!.f02030!);
    const refTextArea = useRef() as React.MutableRefObject<TextArea>;
    //const text_konkretisierungen = useAppSelector((state) => state.store_einstellungen.konfiguration2.text_konkretisierungen);
    const [textKonkretisierungen, setTextKonkretisierungen] = useState<{ satzart: string, beispiel: string }[] | undefined>(undefined);
    const contextMenuItems = useMemo(() => get_context_menu_items(textKonkretisierungen || [], daten_felder_vor_definiert), [textKonkretisierungen]);
    const dispatch = useAppDispatch();
    const [klammer_a, setKlammer_a] = useState(data!.f02042!);

    useEffect(() => {
        if (!textKonkretisierungen) {
            GetData({ Operation: "GetTextkonkretisierungen" }, dispatch)
                .then((res) => {
                    if (!res) return
                    setTextKonkretisierungen(res.text_konkretisierungen);
                });
        }
    }, [textKonkretisierungen, dispatch]);

    useEffect(() => {
        if ((tbNummer !== data!.f02001) || (tbText !== data!.f02002) || (klammer_a !== data!.f02042) || (betrag !== data!.f02007) || (punkte !== data!.f02009) || (fahrVerbot !== data!.f02011)
            || (get_date_ajax_param(gueltigVon) !== data!.f02017) || (get_date_ajax_param(gueltigBis) !== data!.f02018) || (tatbestandsKatalogValue !== data!.f02040) || (winowigMobilValue !== data!.f02030)) {
            if (data) {
                setData && setData({
                    ...data,
                    f02001: tbNummer,
                    f02002: tbText,
                    f02007: betrag,
                    f02009: punkte,
                    f02011: fahrVerbot,
                    f02017: get_date_ajax_param(gueltigVon),
                    f02018: get_date_ajax_param(gueltigBis),
                    f02040: tatbestandsKatalogValue,
                    f02042: klammer_a,
                    f02030: winowigMobilValue,
                });
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tbNummer, tbText, klammer_a, betrag, punkte, fahrVerbot, gueltigBis, gueltigVon, tatbestandsKatalogValue, winowigMobilValue])

    const handleChangeTatbestand = useCallback((e: any) => {
        //Max 10
        (e && e.toString().length > 10) && (e = e.toString().substring(0, e.toString().length - 1));
        setTbNummer(e)
    }, []);

    const handleContextMenuClick = useCallback((e: any) => {
        if (e.itemData.items) return;
        let value = e.itemData.text.substring(e.itemData.text.indexOf("#"), e.itemData.text.length);
        (value.includes('(') && value.includes(')')) &&
            (value = value.substring(0, value.indexOf("(")).trim());
        !value.includes('#') && (value = "#{" + value + '}');
        const focused = document.getElementById("tatbestandstext") as HTMLTextAreaElement;
        const selection_start = focused.getElementsByTagName("textarea")[0].selectionStart;
        const new_value = tbText?.substring(0, selection_start) + value + tbText?.substr(selection_start);
        setTbText(new_value);
    }, [tbText]);


    const handleChangePunkte = useCallback((e: any) => {
        //Max 10
        (e && e.toString().length > 2) && (e = parseInt(e.toString().substring(0, e.toString().length - 1)));
        setPunkte(e)
    }, []);
    const handleChangeFahrverbot = useCallback((e: any) => {
        //Max 10
        (e && e.toString().length > 2) && (e = parseInt(e.toString().substring(0, e.toString().length - 1)));
        setFahrVerbot(e)
    }, []);
    const handleChangeGueltigVon = useCallback((e: any) => {
        setGueltigVon(e.value)
    }, []);
    const handleChangeGueltigBis = useCallback((e: any) => {
        setGueltigBis(e.value)
    }, []);
    return (<div className="row p-2 p-md-3 pt-2 pt-md-4">
        <div className="col-12 p-1 p-md-2">
            <TextBox
                label="Tatbestandnummer"
                labelMode="floating"
                stylingMode="outlined"
                disabled={!isAdd}
                value={tbNummer}
                onValueChange={handleChangeTatbestand}
                valueChangeEvent="input"
                maxLength={10}
                onContentReady={handleContentReadyTextBox}

            />
        </div>
        <div className="col-12 p-1 p-md-2">
            <TextArea
                label="Tatbestandstext"
                labelMode="floating"
                stylingMode="outlined"
                value={tbText}
                onValueChange={setTbText}
                valueChangeEvent="input"
                id="tatbestandstext"
                inputAttr={text_area_row}
                ref={refTextArea}
                maxLength={7200}
                onContentReady={handleContentReadyTextBox}
            />
            <ContextMenu
                dataSource={contextMenuItems}
                target="#tatbestandstext"
                // itemComponent={Test}
                onItemClick={handleContextMenuClick}
            />
        </div>
        <div className="col-12 col-md-6 p-1 p-md-2">
            <NumberBox
                label="Betrag"
                labelMode="floating"
                stylingMode="outlined"
                value={betrag}
                format={BetragReplace}
                onValueChange={setBetrag}
                onContentReady={handleContentReadyTextBox}
                valueChangeEvent="input"
            />
        </div>
        <div className="col-12 col-md-6 p-1 p-md-2">
            <NumberBox
                label="Punkte"
                labelMode="floating"
                stylingMode="outlined"
                value={punkte}
                onValueChange={handleChangePunkte}
                valueChangeEvent="input"
                onContentReady={handleContentReadyTextBox}

            />
        </div>
        <div className="col-12 col-md-6 p-1 p-md-2">
            <NumberBox
                label="Fahrverbot"
                labelMode="floating"
                stylingMode="outlined"
                value={fahrVerbot}
                onValueChange={handleChangeFahrverbot}
                valueChangeEvent="input"
                onContentReady={handleContentReadyTextBox}

            />
        </div>
        <div className="col-12 col-md-6"></div>
        <div className="col-12  col-md-6 p-1 p-md-2">
            <DateBox
                value={gueltigVon}
                onValueChanged={handleChangeGueltigVon}
                displayFormat={"dd.MM.yyyy"}
                showClearButton={true}
                useMaskBehavior={true}
                label='Gültig ab'
                labelMode='floating'
                stylingMode='outlined'
                onContentReady={handleContentReadyDateBox}
            />       </div>
        <div className="col-12 col-md-6 p-1 p-md-2">
            <DateBox
                value={gueltigBis}
                onValueChanged={handleChangeGueltigBis}
                displayFormat={"dd.MM.yyyy"}
                showClearButton={true}
                useMaskBehavior={true}
                onContentReady={handleContentReadyDateBox}
                label='Gültig bis'
                labelMode='floating'
                stylingMode='outlined'
            />
        </div>
        <div className="col-12 col-md-6 p-1 p-md-2">
            <SelectBox
                dataSource={data_source_tatbestand_typ}
                displayExpr="text"
                valueExpr="value"
                value={tatbestandsKatalogValue}
                onValueChange={setTatbestandsKatalogValue}
                label='Tatbestandstyp'
                labelMode='floating'
                stylingMode='outlined'
                onContentReady={handleContentReadySelectBox}
            />
        </div>
        <div className="col-12 col-md-6 p-1 p-md-2">
            <SelectBox
                dataSource={data_source_winowig_mobil}
                displayExpr="text"
                valueExpr="value"
                value={winowigMobilValue}
                onValueChange={setWinowigMobilValue}
                label='Winowig Mobil'
                labelMode='floating'
                stylingMode='outlined'
                onContentReady={handleContentReadySelectBox}
            />
        </div>
        <div className="col-12 p-1 p-md-2">
            <TextArea
                label="Klammer-a"
                labelMode="floating"
                stylingMode="outlined"
                value={klammer_a}
                onValueChange={setKlammer_a}
                valueChangeEvent="input"
                maxLength={254}
                onContentReady={handleContentReadyTextBox}
            />
        </div>
    </div>)

}

export default memo(GrundDatenTab);




const BetragReplace = (data: any) => {
    return data.toString().replace(/\./g, ",")
}