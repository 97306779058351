import { DateBox, Popup, ScrollView, SelectBox } from "devextreme-react"
import { Position, ToolbarItem } from "devextreme-react/autocomplete"
import ArrayStore from "devextreme/data/array_store";
import { useCallback, useEffect, useMemo, useState } from "react";
import { GetData } from "../../../global/communication";
import { icon_filter } from "../../../global/const";
import { handleContentReadyDateBox, handleContentReadySelectBox } from "../../../global/hilfs_functions";
import { useScreenSizeClass } from "../../../global/media-query";
import { useAppDispatch } from "../../../state";
import { MDButton } from "../../../global/components";
import { HauptGruppenItem, UnterGruppenItem } from "../../../sonstiges/hilfs_functions";
import { ITatbestandHauptKategorie, ITatbestandUnterKategorie } from "./interfaces";

export const filter_tatbestaende_default_number = 9999999999;

export interface IFilterTatbestand {
    gueltig_ab: Date | undefined,
    gueltig_bis: Date | undefined,
    tatbestandstyp: string,
    mobil_verwenden: number,
    haupt_kategorie: number,
    unter_kategorie: number,
}
export const Init_filter: IFilterTatbestand = {
    gueltig_ab: undefined,
    gueltig_bis: undefined,
    tatbestandstyp: "",
    mobil_verwenden: filter_tatbestaende_default_number,
    haupt_kategorie: filter_tatbestaende_default_number,
    unter_kategorie: filter_tatbestaende_default_number
}
function UpdateFilter<K extends keyof IFilterTatbestand, V extends IFilterTatbestand[K]>(value: V, obj: IFilterTatbestand, prop: K) {
    obj[prop] = value;
}

const is_gefiltert = (filter: IFilterTatbestand): boolean => {
    for (let [key, value] of Object.entries(filter)) {
        if (Init_filter[key as keyof IFilterTatbestand] !== value) {
            return true
        }
    }
    return false;
}

const array_tatbestandstyp = new ArrayStore({
    data: [
        { text: "Alle", value: "" },
        { text: "Ruhender Verkehr", value: "0" },
        { text: "Fließender Verkehr", value: "1" },
        { text: "Allgemeine OWiG", value: "2" }],
    key: 'value',
});

const array_mobil_verwenden = new ArrayStore({
    data: [
        { text: "Alle", value: filter_tatbestaende_default_number },
        { text: "Automatische Festlegung", value: 0 },
        { text: "Ja", value: 1 },
        { text: "Nein", value: 2 }],
    key: 'value',
});

interface ITatbestaendeFilterPopupProps {
    callback: (filter: IFilterTatbestand) => void,
}


const TatbestaendeFilterPopup: React.FC<ITatbestaendeFilterPopupProps> = ({ callback }): JSX.Element => {
    const screenSize = useScreenSizeClass();
    const isScreenSmall = (screenSize === "sm" || screenSize === "md");
    const [showPopUpFilter, setShowPopUpFilter] = useState(false);
    const [filter, setFilter] = useState<IFilterTatbestand>(Init_filter);
    const dispatch = useAppDispatch();
    const [dataHauptKategorie, setDataHauptKategorie] = useState<ITatbestandHauptKategorie[]>([]);
    const [dataUnterKategorie, setDataUnterKategorie] = useState<ITatbestandUnterKategorie[]>([]);
    const isGefiltert = is_gefiltert(filter);
    const handleShowPopup = useCallback(() => {
        setShowPopUpFilter(true)
    }, []);

    const handleHidePopup = useCallback(() => {
        setShowPopUpFilter(false)
    }, []);

    const handleClcikSpeichern = useCallback(() => {
        callback(filter);
        setShowPopUpFilter(false);
    }, [filter, callback]);

    useEffect(() => {
        GetData({
            Operation: "GetHauptgruppen",
            filter: "",
        }, dispatch).then((res) => {
            if (!res) {
                setDataHauptKategorie([])
                return
            }
            setDataHauptKategorie([{ lfdnr: filter_tatbestaende_default_number, f003: "Alle", f004: 0, f005: 0, f006: 0 }].concat(res.data))
        });
    }, [dispatch]);

    const memo_options_speichern = useMemo(() => {
        return {
            text: 'Anwenden',
            onClick: handleClcikSpeichern
        }
    }, [handleClcikSpeichern]);
    const memo_options_abbrechen = useMemo(() => {
        return {
            text: 'Abbrechen',
            onClick: handleHidePopup,
        }
    }, [handleHidePopup]);


    const handleChangeFilter = useCallback((e: any) => {
        if (!e.event) return
        let new_filter = { ...filter };
        if (e.value === null) e.value = undefined;
        let name = e.component.option("name") as keyof typeof filter;
        UpdateFilter(e.value, new_filter, e.component.option("name") as keyof typeof filter)
        if (name === "haupt_kategorie" && e.value === filter_tatbestaende_default_number) {
            UpdateFilter(filter_tatbestaende_default_number, new_filter, "unter_kategorie")
        }
        setFilter(new_filter)
    }, [filter]);

    useEffect(() => {
        if ((filter.haupt_kategorie || filter.haupt_kategorie === 0) && filter.haupt_kategorie !== filter_tatbestaende_default_number) {
            GetData({
                Operation: "GetUntergruppen",
                Hauptgruppennummer: filter.haupt_kategorie.toString(),
                filter: "",
            }, dispatch).then((res) => {
                if (!res) {
                    setDataUnterKategorie([])
                    return
                }
                setDataUnterKategorie([{ f001: filter_tatbestaende_default_number, f002: filter_tatbestaende_default_number, f003: "Alle", f004: 0, f005: 0, f006: 0 }].concat(res.data))
            });
        }

    }, [filter.haupt_kategorie, dispatch]);

    return (
        <>
            <div id='filter_tatbestaende'>
                <MDButton
                    page='filter_tatbestaende_page'
                    icon={icon_filter}
                    className={isGefiltert ? "highlight" : ""}
                    tooltip_text="Tatbestände filtern"
                    onClick={handleShowPopup}
                />
            </div>
            <Popup
                visible={showPopUpFilter}
                onHiding={handleHidePopup}
                dragEnabled={true}
                hideOnOutsideClick={true}
                fullScreen={false}
                showCloseButton={true}
                height='auto'
                maxHeight="50vh"
                maxWidth="600px"
            >
                <ToolbarItem
                    toolbar="top"
                    location={'before'}
                    render={RenderePopupTitle}
                />
                <Position
                    at={isScreenSmall ? "center" : "right"}
                    my={isScreenSmall ? "center" : "right top"}
                    of={isScreenSmall ? window : '#filter_tatbestaende'}
                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="before"
                    options={memo_options_speichern}
                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={memo_options_abbrechen}
                />
                <ScrollView>
                    <div className='row g-0'>
                        <div className='col-12 col-md-6 p-1'>
                            <DateBox
                                value={filter.gueltig_ab}
                                onValueChanged={handleChangeFilter}
                                displayFormat={"dd.MM.yyyy"}
                                showClearButton={true}
                                useMaskBehavior={true}
                                label='Gültigkeit ab'
                                labelMode='floating'
                                stylingMode='outlined'
                                onContentReady={handleContentReadyDateBox}
                                name="gueltig_ab"
                            />
                        </div>
                        <div className='col-12 col-md-6 p-1'>
                            <DateBox
                                value={filter.gueltig_bis}
                                onValueChanged={handleChangeFilter}
                                displayFormat={"dd.MM.yyyy"}
                                showClearButton={true}
                                useMaskBehavior={true}
                                label='Gültigkeit bis'
                                labelMode='floating'
                                stylingMode='outlined'
                                onContentReady={handleContentReadyDateBox}
                                name="gueltig_bis"
                            />
                        </div>
                        <div className='col-12 col-md-6 p-1'>
                            <SelectBox
                                dataSource={array_tatbestandstyp}
                                displayExpr="text"
                                valueExpr="value"
                                value={filter.tatbestandstyp}
                                onValueChanged={handleChangeFilter}
                                label="Tatbestandstyp"
                                labelMode='floating'
                                stylingMode='outlined'
                                onContentReady={handleContentReadySelectBox}
                                name="tatbestandstyp"
                            />
                        </div>
                        <div className='col-12 col-md-6 p-1'>
                            <SelectBox
                                dataSource={array_mobil_verwenden}
                                displayExpr="text"
                                valueExpr="value"
                                value={filter.mobil_verwenden}
                                onValueChanged={handleChangeFilter}
                                label="Mobil verwenden"
                                labelMode='floating'
                                stylingMode='outlined'
                                onContentReady={handleContentReadySelectBox}
                                name="mobil_verwenden"
                            />
                        </div>
                        <div className='col-12 p-1'>
                            <SelectBox
                                items={dataHauptKategorie}
                                displayExpr="f003"
                                valueExpr="lfdnr"
                                value={filter.haupt_kategorie}
                                onValueChanged={handleChangeFilter}
                                label="Hauptkategorie"
                                labelMode='floating'
                                stylingMode='outlined'
                                onContentReady={handleContentReadySelectBox}
                                name="haupt_kategorie"
                                itemComponent={HauptGruppenItem}
                            />
                        </div>
                        <div className='col-12 p-1'>
                            <SelectBox
                                items={dataUnterKategorie}
                                displayExpr="f003"
                                valueExpr="f002"
                                disabled={(filter.haupt_kategorie === undefined || filter.haupt_kategorie === null || filter.haupt_kategorie === filter_tatbestaende_default_number)}
                                value={filter.unter_kategorie}
                                onValueChanged={handleChangeFilter}
                                label='Unterkategorie'
                                labelMode='floating'
                                stylingMode='outlined'
                                itemComponent={UnterGruppenItem}
                                onContentReady={handleContentReadySelectBox}
                                name="unter_kategorie"
                            />
                        </div>
                    </div>
                </ScrollView>
            </Popup>
        </>
    )
}

export default TatbestaendeFilterPopup
const RenderePopupTitle = () => {
    return (<h2 className="title_popup">Filter</h2>)
};