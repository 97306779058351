import React, { useCallback, useEffect, useState } from 'react';
import Popup from 'devextreme-react/popup';
import DetailFooter from './Footer';
import Data from './Tabelle';
import { GetData } from '../../../global/communication';
import { LoadPanel } from 'devextreme-react';
import { IDetailData } from '../interfaces';
import { useAppDispatch } from '../../../state';
import { ToolbarItem } from 'devextreme-react/autocomplete';

interface IDialogProps {
  onHideDialog: () => void,
  exportNumber: number
}

const DPV_Details: React.FC<IDialogProps> = ({ onHideDialog, exportNumber }): JSX.Element => {
  const [aktPage, setAktPage] = useState<number>(1);
  const [selectedRows, setSelectedRows] = useState<IDetailData[]>([]);
  const [anzSaetze, setAnzahlSaetze] = useState(0);
  const [isloading, setIsloading] = useState(true);
  const [data, setData] = useState<IDetailData[]>();
  const dispatch = useAppDispatch();

  useEffect(() => {
    GetData(
      {
        Operation: "GetDoppelverwarnungen",
        Exportnummer: exportNumber.toString(),
      }, dispatch
    ).then((res) => {
      if (!res) {
        setAnzahlSaetze(0);
        return
      }

      setAnzahlSaetze(res.anzahl);
      if (res.anzahl > 0) {
        get_data(aktPage - 1);
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isloading) { // Das Erste brauchen wir nicht
      get_data(aktPage - 1);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aktPage]);

  const get_data = useCallback((index: number) => {
    GetData(
      {
        Operation: "GetDoppelverwarnung",
        Index: index,
      }, dispatch
    ).then((res) => {
      isloading && setIsloading(false);
      if (!res) {
        setData(undefined);
        return
      }
      setData(res.data)
    });
  }, [isloading, dispatch]);

  const ClickCombine = useCallback(() => {
    let daten: string = "";
    selectedRows.forEach((item: IDetailData) => {
      if (daten !== "")
        daten += "%01";

      daten += item.exportnummer + "%02" + item.lfdnr;
    });
    if (daten !== "") {
      GetData(
        {
          Operation: "MergeDoppelverwarnung",
          FaelleHauptschluessel: daten,
        }, dispatch
      ).then((res) => {
        if (!res) return
        get_data(aktPage - 1);
      });

    }
  }, [selectedRows, aktPage, get_data, dispatch]);


  const onDeleteClick = useCallback((e: any) => {
    GetData({
      Operation: "DeleteDoppelverwarnungsatz",
      Exportnummer: e.row.data.exportnummer.toString(),
      LfdNr: e.row.data.lfdnr.toString(),
    }, dispatch).then((res) => {
      if (!res) return
      get_data(aktPage - 1);
    });
  }, [get_data, aktPage, dispatch]);

  const handleSelectionchange = useCallback((e: any) => {
    setSelectedRows(e.selectedRowsData)
  }, [])

  const handleClickNext = useCallback(() => {
    if (aktPage < anzSaetze) { setAktPage(aktPage + 1) }
    else { setAktPage(1) }
  }, [aktPage, anzSaetze])

  const handleClickPerv = useCallback(() => {
    if (aktPage > 1) { setAktPage(aktPage - 1) }
    else { setAktPage(anzSaetze) }
  }, [aktPage, anzSaetze])


  return (
    <>
      {isloading ? <LoadPanel visible={true} />
        :
        <Popup
          visible={true}
          hideOnOutsideClick={true}
          showCloseButton={true}
          onHiding={onHideDialog}
          resizeEnabled={true}
          height={"auto"}
        // animation={undefined}
        >
          <ToolbarItem
            toolbar="top"
            location={'before'}
            render={RenderePopupTitle}
          />
          <>
            {data && <Data onSelectionChanged={handleSelectionchange} data={data} onDeleteClick={onDeleteClick}></Data>}
            <DetailFooter
              maxPages={anzSaetze}
              aktPage={aktPage}
              onClickNext={handleClickNext}
              onClickPrevious={handleClickPerv}
              onClickCombine={ClickCombine} />
          </>
        </Popup>
      }
    </>);
}

export default DPV_Details;

const RenderePopupTitle = () => {
  return (<h2 className="title_popup">Doppelverwarnung bearbeiten</h2>)
};