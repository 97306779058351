import { IGeraeteHistorie } from "../interfaces";


interface IHistorieProps {
    f020: IGeraeteHistorie[],
}
const Historie: React.FC<IHistorieProps> = ({ f020 }) => {
    return (
        <div className="p-3 p-md-5 ">
            <div className='row'>
                {f020.map((ele: IGeraeteHistorie) => {
                    return (
                        <div className='col-11 col-sm-6 col-md-4 col-xl-3 mx-auto' key={ele.datum + ' ' + ele.uhrzeit}>
                            <div className="m-2 m-md-3 p-3 p-md-4 GeraeteHistorieCard">
                                <p className="mb-2"><strong>Datum:</strong>&nbsp;&nbsp;&nbsp;{ele.datum}</p>
                                <p className="mb-2"><strong>Uhrzeit:</strong>&nbsp;&nbsp;&nbsp;{ele.uhrzeit}</p>
                                <p className="mb-2"><strong>Aktion:</strong>&nbsp;&nbsp;{ele.aktion}</p>
                                <p className="mb-2"><strong>Sachbearbeiter:</strong>&nbsp;&nbsp;&nbsp;{ele.sachbearbeiter}</p>
                                {ele.details && <p className="mb-2"><strong>Details:</strong>&nbsp;&nbsp;&nbsp;{ele.details}</p>}
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}


export default Historie;
