
import { DataGrid, TextBox } from "devextreme-react";
import { Column, Toolbar, Item as ItemGridToolbar, } from "devextreme-react/data-grid";
import { useCallback, useEffect, useRef, useState } from "react";
import { sachbearbeiter_columns } from "../../../sonstiges/columns";
import { exportGridAsPDF, handleContentReadyDataGrid, handleContentReadyTextBox } from "../../../global/hilfs_functions";
import SachbearbeiterAdd from "./add";
import SachbearbeiterVerwaltePopup from "./verwalten_popup";
import { IColumn, ISachbearbeiter } from "../../../global/interfaces";
import { useAppDispatch } from "../../../state";
import { Seperatorliste } from "../../../global/seperator_liste";
import { GetData } from "../../../global/communication";
import { RendereGridTitleSachbearbeiter } from "../../../sonstiges/hilfs_functions";
import { MDButton } from "../../../global/components";
import { icon_pdf, scroll_mode_infinite } from "../../../global/const";


const customizeCell = (cellData: any) => {
    if (cellData.gridCell.column.dataField === 'f07110' && cellData.gridCell.rowType === 'data') {
        switch (cellData.pdfCell.text.toString()) {
            case "0":
                cellData.pdfCell.text = "Keine Sperrung";
                break;
            case "1":
                cellData.pdfCell.text = "Sperrung";
                break;
            case "2":
                cellData.pdfCell.text = "Vollsperrung";
                break;
            case "3":
                cellData.pdfCell.text = "Sofortige Vollsperrung";
                break;
            default:
                cellData.pdfCell.text = "";
                break;
        }

    }
}

const columns: IColumn[] = sachbearbeiter_columns;
const Sachbearbeiter: React.FC = (): JSX.Element => {
    //  const data_redux = useAppSelector((state) => state.store_einstellungen.konfiguration2.sachbearbeiter);
    const [data, setData] = useState<ISachbearbeiter[]>([]);
    const [bearbeitePopUp, setBearbeitePopup] = useState<{ show: boolean, row: ISachbearbeiter | null }>({
        show: false,
        row: null,
    });
    const refGrid = useRef() as React.MutableRefObject<DataGrid<any, any>>;
    const [searchValue, setSearchValue] = useState("");
    const dispatch = useAppDispatch();
    /*
    useEffect(() => {
        setData(data_redux);
        setSearchValue("");
    }, [data_redux]);
*/

    const get_data = useCallback(() => {
        let filter = new Seperatorliste("%00", "%01");
        filter.setValue("Suchbegriff", searchValue);
        GetData({
            Operation: "GetSachbearbeiter",
            Filter: filter.getSeperatorliste(),
        }, dispatch).then((res) => {
            if (!res) return
            setData(res.data)
        })
    }, [searchValue, dispatch]);

    useEffect(() => {
        get_data();
    }, [get_data]);




    const handleCallBackBearbeiten = useCallback((update?: boolean) => {
        setBearbeitePopup({ show: false, row: null });
        update && get_data()
    }, [get_data]);

    const handleRowClick = useCallback(({ data }: any) => {
        setBearbeitePopup({ show: true, row: data })
    }, []);

    const handlePDF = useCallback(() => {
        exportGridAsPDF(refGrid, "Sachbearbeiter.pdf", searchValue ? "Suchbegriff: " + searchValue + "\n" : "", "landscape", "striped", customizeCell)
    }, [searchValue]);

    return (
        <>
            {bearbeitePopUp.show && <SachbearbeiterVerwaltePopup row={bearbeitePopUp.row} callback={handleCallBackBearbeiten} />}
            <div className="data_grid_container mt-2 mt-md-3">
                <DataGrid
                    dataSource={data}
                    allowColumnResizing={true}
                    columnAutoWidth={false}
                    rowAlternationEnabled={true}
                    focusedRowEnabled={false}
                    ref={refGrid}
                    renderAsync={true}
                    remoteOperations={true}
                    scrolling={scroll_mode_infinite}
                    // onOptionChanged={onFilterChanged}    
                    wordWrapEnabled={true}
                    keyExpr={'f07001'}
                    noDataText="Keine Sachbearbeiter"
                    onRowClick={handleRowClick}
                    hoverStateEnabled={true}
                    className="cursor_pointer"
                    onContentReady={handleContentReadyDataGrid}
                // onContentReady= {set_column_chooser_position }
                >
                    {
                        columns.map((column) => {
                            return (
                                <Column
                                    dataField={column.dataField}
                                    caption={column.caption}
                                    dataType={column.dataType}
                                    alignment={column.alignment}
                                    cellRender={column.cellRender}
                                    key={column.dataField}
                                    minWidth={column.minWidth}
                                    visible={column.visible}
                                />
                            )
                        })
                    }
                    <Toolbar>
                        <ItemGridToolbar
                            location={'before'}
                            render={RendereGridTitleSachbearbeiter}
                        ></ItemGridToolbar>
                        <ItemGridToolbar
                            location={'after'}
                            locateInMenu="never"
                        >
                            <TextBox
                                label="Suche"
                                stylingMode='outlined'
                                mode='text'
                                className="suche_feld"
                                showClearButton={true}
                                value={searchValue}
                                onValueChange={setSearchValue}
                                onEnterKey={get_data}
                                maxLength={60}
                                onContentReady={handleContentReadyTextBox}>
                            </TextBox>
                        </ItemGridToolbar>
                        <ItemGridToolbar location="after" locateInMenu="auto">
                            <SachbearbeiterAdd get_data={get_data} />
                        </ItemGridToolbar>
                        <ItemGridToolbar location="after" locateInMenu="auto">
                            <MDButton
                                page="sachbearbeiter"
                                icon={icon_pdf}
                                onClick={handlePDF}
                                tooltip_text="Tabelle als PDF herunterladen"
                            />
                        </ItemGridToolbar>
                    </Toolbar>
                </DataGrid></div></>
    )
}

export default Sachbearbeiter;