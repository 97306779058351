import { CheckBox, Popup, ScrollView, SelectBox, TextArea, TextBox } from "devextreme-react"
import { ToolbarItem } from "devextreme-react/autocomplete"
import { useCallback, useEffect, useState } from "react";
import TabPanel, { Item } from "devextreme-react/tab-panel";
import { IKonfiguratorEintrag } from "../interfaces";
import { setAlert, setConfirm, setKonfiguratorBearbeitet, setToast, useAppDispatch } from "../../../state";
import { GetData } from "../../../global/communication";
import { Computer, TreeViewNodeKonfigurator } from ".";
import { useMemo } from "react";
import { handleContentReadyCheckBox, handleContentReadySelectBox, handleContentReadyTextBox } from "../../../global/hilfs_functions";




interface IKonfiguratorEintragVerwaltePopupProps {
    callback: (row_new?: IKonfiguratorEintrag, delete_row?: boolean) => void,
    row?: IKonfiguratorEintrag | undefined,
    selectedNode?: TreeViewNodeKonfigurator,
    einfuegen?: boolean,
    einfuegen_computer?: boolean,
    einfuegen_bereich?: boolean,
    allComputer: Computer[],
}



const KonfiguratorEintragVerwaltePopup: React.FC<IKonfiguratorEintragVerwaltePopupProps> = ({ allComputer, callback, row, selectedNode, einfuegen, einfuegen_computer, einfuegen_bereich }): JSX.Element => {

    const isAdd = row === undefined;
    const [schluessel, setSchluessel] = useState(!isAdd ? row?.f003 : undefined);
    const [computerName, setComputerName] = useState(get_computer_name(isAdd, row, selectedNode));
    const [bereich, setBereich] = useState(get_bereich_name(isAdd, row, selectedNode));
    const [wert, setWert] = useState(!isAdd ? row?.f004 : "");
    const [optionen, setOptionen] = useState(!isAdd ? row?.f005 : "");
    const [optionenSelectBox, setOptionenSelectBox] = useState<string[]>([]);
    const [checkBoxValue, setCheckBoxValue] = useState<boolean | null>(false);
    const all_Computer = useMemo(() =>
        allComputer.map((computer) => computer.name), [allComputer]);
    const all_bereiche = useMemo(() => {
        let result: string[] = [];
        for (let i of allComputer) {
            for (let j of i.bereiche) {
                if (result.indexOf(j) === -1) {
                    result.push(j)
                }
            }
        }
        return result
    }, [allComputer]);

    const [allSchluessel, setAllSchluessel] = useState<string[]>([]);
    const dispatch = useAppDispatch();
    const data_source_options = useMemo(() => optionenSelectBox.filter(i => i).map((item: any) => { return { text: item, value: item } }), [optionenSelectBox]);
    useEffect(() => {
        if (bereich) {
            GetData({
                Operation: "GetSchluessel",
                Bereich: bereich,
            }, dispatch).then((res) => {
                if (!res) {
                    setAllSchluessel([]);
                    return
                }
                setAllSchluessel(res.data)
            })
        }
    }, [bereich, dispatch]);

    useEffect(() => {
        let arr_end: string[] = []
        //Aufpassen. Aus DB ist \r\n. Aus DOM ist \n
        if (optionen) {
            if (optionen.indexOf("\r\n") > -1) {
                arr_end = optionen.split("\r\n").filter(i => i);
            } else {
                arr_end = optionen.split("\n").filter(i => i);
            }
        }
        setOptionenSelectBox(arr_end);
        // setWert(optionen?.split("\n")[0]);
    }, [optionen])

    const customItemCreating = useCallback((args: any) => {

        if (!args.text) {
            args.customItem = null;
            return;
        }
        args.customItem = args.text;
    }, []);

    const handleSpeichern = useCallback(() => {
        if (!computerName) {
            dispatch(setToast({
                message: "Computername muss vorhanden sein",
                type: "error",
                displayTime: 4000,
            }))
            return
        }

        let seperatorliste = "";
        //Kopieren
        if (einfuegen_bereich || einfuegen || einfuegen_computer) {
            // Eintrag kopieren
            if (einfuegen) {
                if (!bereich) {
                    dispatch(setToast({
                        message: "Bereich muss vorhanden sein",
                        type: "error",
                        displayTime: 4000,
                    }))
                    return
                }
                if (!schluessel) {
                    dispatch(setToast({
                        message: "Schlüssel muss vorhanden sein",
                        type: "error",
                        displayTime: 4000,
                    }))
                    return
                }

                seperatorliste = "computername%01" + computerName
                    + "%00bereich%01" + bereich
                    + "%00schluessel%01" + schluessel
                    + "%00wert%01" + wert;

                // am Ende immer ein Zeilenumbruch
                if (optionen && optionen.length > 0) {
                    seperatorliste = seperatorliste + "%00optionen%01" + optionen + '\n';
                }
            }
            // Bereich Kopieren
            else if (einfuegen_bereich) {
                if (!bereich) {
                    dispatch(setToast({
                        message: "Bereich muss vorhanden sein",
                        type: "error",
                        displayTime: 4000,
                    }))
                    return
                }
                if (!row) return
                let computer_alt = row?.f001.split("_").filter(i => i).length > 1 ? row?.f001.split("_").filter(i => i)[1] : row?.f001.split("_").filter(i => i)[0];
                let bereich_alt = row?.f002.split("_").filter(i => i).length > 1 ? row?.f002.split("_").filter(i => i)[1] : row?.f002.split("_").filter(i => i)[0];
                seperatorliste = "computername%01" + computerName
                    + "%00computername-alt%01" + computer_alt
                    + "%00bereich%01" + bereich
                    + "%00bereich-alt%01" + bereich_alt
            }
            // Computer kopieren
            else {
                seperatorliste = "computername%01" + computerName
                    + "%00computername-alt%01" + row?.f001;
            }
        }
        //Neu oder bearbeiten eines Eintrages
        else {
            if (!bereich) {
                dispatch(setToast({
                    message: "Bereich muss vorhanden sein",
                    type: "error",
                    displayTime: 4000,
                }))
                return
            }
            if (!schluessel) {
                dispatch(setToast({
                    message: "Schlüssel muss vorhanden sein",
                    type: "error",
                    displayTime: 4000,
                }))
                return
            }

            seperatorliste = "computername%01" + computerName
                + "%00bereich%01" + bereich
                + "%00schluessel%01" + schluessel
                + "%00wert%01" + wert;

            if (optionen && optionen.length > 0) {
                seperatorliste = seperatorliste + "%00optionen%01" + optionen + "\n";
            }

            if (!isAdd) {
                let alleComputer = checkBoxValue ? "Ja" : "Nein";
                seperatorliste += "%00schluessel-alt%01" + schluessel
                    + "%00alle-computer%01" + alleComputer;
            }


        }

        const get_operation = (): string => {
            if (einfuegen) {
                return "KonfiguratorSchluesselEinfuegen"
            } else if (einfuegen_bereich) {
                return "KonfiguratorBereichEinfuegen"
            } else if (einfuegen_computer) {
                return "KonfiguratorComputerEinfuegen"
            } else if (!isAdd) {
                return "ChangeKonfiguration"
            } else {
                return "NewKonfiguration"
            }
        }
        //Jetzt Anfrage senden
        GetData({
            Operation: get_operation(),
            Daten: seperatorliste,
        }, dispatch)
            .then((res) => {
                if (!res) return
                callback({
                    f001: computerName ? computerName : "",
                    f002: bereich ? bereich : "",
                    f003: schluessel ? schluessel : "",
                    f004: wert ? wert : "",
                    f005: optionen ? optionen : ""
                });
                if (res.hinweis) {
                    dispatch(setAlert({
                        text: res.hinweis,
                        type: "Info",
                    }))
                } else {
                    dispatch(setToast({
                        message: "Die Änderungen wurden erfolgreich vorgenommen.",
                        type: "success",
                        position: "bottom center"
                    }));
                    //MD new 12.01.2023
                    dispatch(setKonfiguratorBearbeitet(true));
                }
            });
    }, [computerName, einfuegen_bereich, einfuegen, einfuegen_computer, bereich, schluessel, dispatch, wert, optionen, row, isAdd, checkBoxValue, callback]);



    const handleShowPopupLoeschen = useCallback(() => {
        delete_konfigurator_eintrag(dispatch, row, callback)
    }, [dispatch, row, callback]);

    const handleCallBack = useCallback(() => { callback() }, [callback]);

    const memo_options_loeschen = useMemo(() => {
        return {
            text: 'Löschen',
            type: "danger",
            onClick: handleShowPopupLoeschen,
        }
    }, [handleShowPopupLoeschen]);

    const memo_options_speichern = useMemo(() => {
        return {
            text: 'Speichern',
            onClick: handleSpeichern,
        }
    }, [handleSpeichern]);

    const memo_options_abbrechen = useMemo(() => {
        return {
            text: 'Abbrechen',
            onClick: handleCallBack,
        }
    }, [handleCallBack]);

    const RenderePopupTitle = useCallback(() => {
        return (<h2 className="title_popup">{einfuegen_bereich ? "Bereich einfügen" : einfuegen_computer ? "Computer einfügen" : isAdd ? "Neuen Eintrag anlegen" : (!einfuegen) ? "Eintrag bearbeiten" : "Eintrag einfügen"}</h2>)
    }, [isAdd, einfuegen_bereich, einfuegen_computer, einfuegen]);

    return (
        <>
            <Popup
                visible={true}
                onHiding={handleCallBack}
                dragEnabled={true}
                hideOnOutsideClick={true}
                fullScreen={false}
                showCloseButton={true}
                height='auto'
                maxHeight="70vh"
                maxWidth="500px"
            >

                <ToolbarItem
                    toolbar="top"
                    location={'before'}
                    render={RenderePopupTitle}
                />
                {(!isAdd && !einfuegen && !einfuegen_computer && !einfuegen_bereich) &&
                    <ToolbarItem
                        widget="dxButton"
                        toolbar="bottom"
                        location="before"
                        options={memo_options_loeschen}
                    />}
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={memo_options_speichern}
                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={memo_options_abbrechen}
                />
                <ScrollView>
                    <TabPanel animationEnabled={true}>
                        <Item title="Werte">
                            <div className="row p-2 p-md-3">
                                <div className="col-12 p-1 p-md-2">
                                    <SelectBox
                                        // dataSource={dataSourceComputerNamen} 
                                        // displayExpr="text"
                                        //valueExpr="value"
                                        dataSource={all_Computer}
                                        acceptCustomValue={true}
                                        value={computerName}
                                        onCustomItemCreating={customItemCreating}
                                        onValueChange={setComputerName}
                                        label='Computername'
                                        labelMode='floating'
                                        stylingMode='outlined'
                                        disabled={(!isAdd && !einfuegen && !einfuegen_computer && !einfuegen_bereich)}
                                        searchEnabled={true}
                                        searchMode={"contains"}
                                        noDataText={"Keine Daten"}
                                        onContentReady={handleContentReadySelectBox}
                                    />
                                </div>
                                <div className="col-12 p-1 p-md-2">
                                    <SelectBox
                                        // dataSource={dataSourceBereiche} 
                                        // displayExpr="text"
                                        // valueExpr="value"
                                        dataSource={all_bereiche}
                                        acceptCustomValue={true}
                                        value={bereich}
                                        onCustomItemCreating={customItemCreating}
                                        onValueChange={setBereich}
                                        label='Bereich'
                                        labelMode='floating'
                                        stylingMode='outlined'
                                        disabled={(!isAdd && !einfuegen && !einfuegen_bereich) || (einfuegen_computer)}
                                        searchEnabled={true}
                                        searchMode={"contains"}
                                        noDataText={"Keine Daten"}
                                        onContentReady={handleContentReadySelectBox}
                                    />
                                </div>
                                <div className="col-12 p-1 p-md-2">
                                    <SelectBox
                                        //dataSource={dataSourceSchluessel} 
                                        //displayExpr="text"
                                        //valueExpr="value"
                                        dataSource={allSchluessel}
                                        acceptCustomValue={true}
                                        value={schluessel}
                                        onCustomItemCreating={customItemCreating}
                                        onValueChange={setSchluessel}
                                        label='Schlüssel'
                                        labelMode='floating'
                                        stylingMode='outlined'
                                        disabled={(!isAdd && !einfuegen) || (einfuegen_computer || einfuegen_bereich)}
                                        searchEnabled={true}
                                        searchMode={"contains"}
                                        noDataText={"Keine Daten"}
                                        onContentReady={handleContentReadySelectBox}
                                    />
                                </div>
                                {(optionenSelectBox && optionenSelectBox.length > 0) &&
                                    <div className="col-12  p-1 p-md-2">
                                        <SelectBox
                                            dataSource={data_source_options}
                                            displayExpr="text"
                                            valueExpr="value"
                                            value={wert}
                                            onValueChange={setWert}
                                            label='Wert'
                                            labelMode='floating'
                                            stylingMode='outlined'
                                            onContentReady={handleContentReadySelectBox}
                                        />
                                    </div>
                                }
                                {(!optionenSelectBox || optionenSelectBox.length === 0) &&
                                    <div className="col-12  p-1 p-md-2">
                                        <TextBox
                                            label="Wert"
                                            labelMode="floating"
                                            stylingMode="outlined"
                                            onValueChange={setWert}
                                            value={wert}
                                            maxLength={4000}
                                            valueChangeEvent="input"
                                            disabled={einfuegen_computer || einfuegen_bereich}
                                            onContentReady={handleContentReadyTextBox}
                                        />
                                    </div>}
                                {(!isAdd && !einfuegen_computer && !einfuegen_bereich && !einfuegen) &&
                                    <div className="col-12  p-1 p-md-2">
                                        <CheckBox
                                            text="Änderung auf allen Computern anwenden"
                                            onContentReady={handleContentReadyCheckBox}
                                            value={checkBoxValue}
                                            onValueChange={setCheckBoxValue}
                                        />
                                    </div>

                                }
                            </div>
                        </Item>
                        <Item title="Optionen" disabled={einfuegen_computer || einfuegen_bereich}>
                            <div className="row p-2 p-md-3">
                                <div className="col-12 p-1 p-md-2">
                                    <TextArea
                                        label='Optionen'
                                        labelMode='floating'
                                        stylingMode='outlined'
                                        maxLength='7200'
                                        onValueChange={setOptionen}
                                        value={optionen}
                                        valueChangeEvent="input"
                                        inputAttr={{ rows: 7 }}
                                        onContentReady={handleContentReadyTextBox}
                                    />
                                </div>
                            </div>
                        </Item>
                    </TabPanel>

                </ScrollView>

            </Popup>
        </>
    )
}

export default KonfiguratorEintragVerwaltePopup;


const delete_konfigurator_eintrag = (dispatch: any, data: any, callback: any) => {
    dispatch(setConfirm({
        body:
            <div className="alert alert-danger">
                <p>Wollen sie den Eintrag <b>{data.f003}</b> wirklich löschen?</p>
            </div>,
        on_ok: () => {
            callback(data, true);
        },
        on_hiding: () => {
            callback()
        },
        title: "Eintrag löschen",
    }));
}

const get_computer_name = (isAdd: boolean, row?: IKonfiguratorEintrag, selectedNode?: TreeViewNodeKonfigurator) => {
    if (!isAdd) {
        if (!row) return ""
        let arr = row?.f001.split("_").filter(i => i);
        return arr[0]
        /*
        if(arr.length === 1){
            return arr[0]
        }else if(arr.length === 2){
            return arr[0]
        }*/
    } else {
        return selectedNode?.parentText
    }
}

const get_bereich_name = (isAdd: boolean, row?: IKonfiguratorEintrag, selectedNode?: TreeViewNodeKonfigurator) => {
    if (!isAdd) {
        if (!row) return ""
        let arr = row?.f002.split("_").filter(i => i);

        return arr[0] ? arr[0] : ""
        /*
        if(arr.length === 1){
            return arr[0]
        }else if(arr.length === 2){
            return arr[0]
        }*/
    } else {
        return selectedNode?.text
    }
}
