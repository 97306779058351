import { memo } from "react";
import { DataGrid, LoadPanel, TextBox } from "devextreme-react";
import { Column, Toolbar, Item as ItemGridToolbar, } from "devextreme-react/data-grid";
import { useCallback, useEffect, useRef, useState } from "react";
import { format_gruppen_art, sachbearbeiter_gruppen__columns } from "../../../sonstiges/columns";
import { exportGridAsPDF, handleContentReadyDataGrid, handleContentReadyTextBox } from "../../../global/hilfs_functions";
import SachbearbeiterGruppenAdd from "./add";
import { ISachbearbeiterGruppenData } from "./interfaces";
import { GetData } from "../../../global/communication";
import { useAppDispatch, useAppSelector } from "../../../state";
import { Seperatorliste } from "../../../global/seperator_liste";
import { RendereGridTitleSachbearbeitergruppen } from "../../../sonstiges/hilfs_functions";
import { MDButton } from "../../../global/components";
import { icon_pdf, scroll_mode_infinite } from "../../../global/const";
import { IColumn } from "../../../global/interfaces";


interface ISBGruppeGridPops {
    setBearbeitePopup: (value: { show: boolean, row: ISachbearbeiterGruppenData | null }) => void,
    refreshGrid: boolean,
}

const columns: IColumn[] = sachbearbeiter_gruppen__columns;
const SBGruppeGrid: React.FC<ISBGruppeGridPops> = ({ setBearbeitePopup, refreshGrid }) => {
    const [data, setData] = useState<ISachbearbeiterGruppenData[]>([]);
    const [searchValue, setSearchValue] = useState("");
    const [isLoading, setIstLoading] = useState(true);
    const refGrid = useRef() as React.MutableRefObject<DataGrid<any, any>>;
    const dispatch = useAppDispatch();
    const isAdministrator = useAppSelector((state) => state.store_einstellungen.konfiguration.is_administrator);

    const get_data = useCallback(() => {
        let filter = new Seperatorliste("%00", "%01");
        filter.setValue("Suchbegriff", searchValue);
        GetData({
            Operation: "GetSachbearbeitergruppen",
            Filter: filter.getSeperatorliste(),
        }, dispatch).then((res) => {
            if (!res) return
            setData(res.data)
        })
    }, [searchValue, dispatch]);

    useEffect(() => {
        get_data();
        setIstLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refreshGrid]);


    const handleRowClick = useCallback(({ data }: any) => {
        setBearbeitePopup({ show: true, row: data })
    }, [setBearbeitePopup]);

    const handlePDF = useCallback(() => {
        exportGridAsPDF(refGrid, "Sachbearbeitergruppen.pdf", searchValue ? "Suchbegriff: " + searchValue + "\n" : "", "landscape", "striped", (cellData: any) => {
            if (cellData.gridCell.column.dataField === 'f17004' && cellData.gridCell.rowType === 'data') {
                cellData.pdfCell.text = format_gruppen_art(parseInt(cellData.pdfCell.text));
            }
        })
    }, [searchValue]);
    return (
        isLoading ? <LoadPanel visible={true} /> :
            <div className="data_grid_container mt-2 mt-md-3" >
                <DataGrid
                    dataSource={data}
                    allowColumnResizing={true}
                    columnAutoWidth={false}
                    rowAlternationEnabled={true}
                    focusedRowEnabled={false}
                    ref={refGrid}
                    renderAsync={true}
                    remoteOperations={true}
                    scrolling={scroll_mode_infinite}
                    // onOptionChanged={onFilterChanged}    
                    wordWrapEnabled={true}
                    keyExpr={'f17001'}
                    onRowClick={handleRowClick}
                    hoverStateEnabled={true}
                    className="cursor_pointer"
                    noDataText="Keine Sachbearbeitergruppen"
                    onContentReady={handleContentReadyDataGrid}
                // onContentReady= {set_column_chooser_position }
                >
                    {
                        columns.map((column) => {
                            return (
                                <Column
                                    dataField={column.dataField}
                                    caption={column.caption}
                                    dataType={column.dataType}
                                    alignment={column.alignment}
                                    cellRender={column.cellRender}
                                    key={column.dataField}
                                    visible={column.visible}
                                />
                            )
                        })
                    }
                    <Toolbar>
                        <ItemGridToolbar
                            location={'before'}
                            render={RendereGridTitleSachbearbeitergruppen}
                        ></ItemGridToolbar>
                        <ItemGridToolbar
                            location={'after'}
                            locateInMenu="never"
                        >
                            <TextBox
                                label="Suche"
                                stylingMode='outlined'
                                mode='text'
                                className="suche_feld"
                                showClearButton={true}
                                value={searchValue}
                                onValueChange={setSearchValue}
                                onEnterKey={get_data}
                                maxLength={50}
                                onContentReady={handleContentReadyTextBox}>
                            </TextBox>
                        </ItemGridToolbar>
                        {isAdministrator && // MD Nur administratoren dürfen Gruppen anlegen
                            <ItemGridToolbar location="after" locateInMenu="auto">
                                <SachbearbeiterGruppenAdd get_data={get_data} />
                            </ItemGridToolbar>}
                        <ItemGridToolbar location="after" locateInMenu="auto">
                            <MDButton
                                icon={icon_pdf}
                                page="sachbearbeiter_gruppen"
                                tooltip_text="Tabelle als PDf herunterladen"
                                onClick={handlePDF}
                            />
                        </ItemGridToolbar>
                    </Toolbar>
                </DataGrid></div >
    )
}
export default memo(SBGruppeGrid);