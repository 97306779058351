import { useCallback, useState } from "react";
import { useHistory } from "react-router";
import { useScreenSize } from "../../global/media-query";
import { IContentLogikReturn } from "./content";

export const MenuStatus = {
  Closed: 1,
  Opened: 2,
  TemporaryOpened: 3
};

const useContentLogik = (): IContentLogikReturn => {
  const history = useHistory();
  const { isSmall, isXLarge, isXXLarge, isXXXLarge } = useScreenSize();
  const isBig = isXLarge || isXXLarge || isXXXLarge;
  const [menuStatus, setMenuStatus] = useState(
    isBig ? MenuStatus.Opened : MenuStatus.Closed
  );


  const toggleMenu = useCallback(() => {
    setMenuStatus(
      prevMenuStatus => prevMenuStatus === MenuStatus.Closed
        ? MenuStatus.Opened
        : MenuStatus.Closed
    );
    //   event.stopPropagation();
  }, []);

  const temporaryOpenMenu = useCallback(() => {
    setMenuStatus(
      prevMenuStatus => prevMenuStatus === MenuStatus.Closed
        ? MenuStatus.TemporaryOpened
        : prevMenuStatus
    );
  }, []);

  const onOutsideClick = useCallback(() => {
    setMenuStatus(
      prevMenuStatus => prevMenuStatus !== MenuStatus.Closed && !isBig
        ? MenuStatus.Closed
        : prevMenuStatus
    );
    return true; // Dies muss zurückgegeben werden.
  }, [isBig]);

  const onNavigationChanged = useCallback((path: string, event: any) => {

    //if (menuStatus === MenuStatus.Closed || !path)
    if (!path)
      return;

    history.push(path);

    //if (!isLarge || menuStatus === MenuStatus.TemporaryOpened) {
    if (!isBig) {
      event.stopPropagation();
      setMenuStatus(MenuStatus.Closed);
    }

  }, [history, isBig]);



  return {
    toggleMenu: toggleMenu,
    onOutsideClick: onOutsideClick,
    isLarge: isBig,
    isSmall: isSmall,
    menuStatus: menuStatus,
    onNavigationChanged: onNavigationChanged,
    temporaryOpenMenu: temporaryOpenMenu,
  }
}

export default useContentLogik;