
import { useCallback, useState } from "react";
import 'jspdf-autotable';
import SachbearbeiterGruppenVerwaltePopup from "./verwalten_popup";
import { ISachbearbeiterGruppenData } from "./interfaces";
import SBGruppeGrid from "./sb_gruppe_Grid";

const SachbearbeiterGruppen: React.FC = (): JSX.Element => {
    const [bearbeitePopUp, setBearbeitePopup] = useState<{ show: boolean, row: ISachbearbeiterGruppenData | null }>({
        show: false,
        row: null,
    });
    const [refreshGrid, setRefreshGrid] = useState(false);

    const handeCallBack = useCallback((update?: boolean) => {
        setBearbeitePopup({ show: false, row: null });
        update && setRefreshGrid(prev => !prev);
    }, []);
    return (
        <>
            {bearbeitePopUp.show && <SachbearbeiterGruppenVerwaltePopup row={bearbeitePopUp.row} callback={handeCallBack} />}
            <SBGruppeGrid setBearbeitePopup={setBearbeitePopup} refreshGrid={refreshGrid} />
        </>
    )
}

export default SachbearbeiterGruppen;