import { DataGrid, NumberBox, Popup, ScrollView, SelectBox, TextBox } from "devextreme-react"
import { ToolbarItem } from "devextreme-react/autocomplete"
import ArrayStore from "devextreme/data/array_store";
import TabPanel, { Item } from "devextreme-react/tab-panel";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { sachbearbeiter_einfach_columns } from "../../../sonstiges/columns";
import { Column, Paging, Selection, } from "devextreme-react/data-grid";
import { ISachbearbeiterGruppenBerechtigungen, ISachbearbeiterGruppenData, ISachbearbeiterEinfach } from "./interfaces";
import { setAlert, setConfirm, setToast, useAppDispatch, useAppSelector } from "../../../state";
import { GetData, GetDataAsync } from "../../../global/communication";
import { Seperatorliste } from "../../../global/seperator_liste";
import { handleContentReadySelectBox, handleContentReadyTextBox } from "../../../global/hilfs_functions";
import { IColumn } from "../../../global/interfaces";

interface ISachbearbeiterGruppenVerwaltePopupProps {
    row?: ISachbearbeiterGruppenData | null,
    callback: (update?: boolean) => void,
}

const dataSourceGruppenArtWithWinowigMail = new ArrayStore({
    data: [
        { text: "Berechtigung", value: 1 },
        { text: "Vertretung", value: 2 },
        { text: "Beide", value: 0 },
        { text: "Winowig Mail", value: 3 }
    ],
    key: 'value',
})

const dataSourceGruppenArt = new ArrayStore({
    data: [
        { text: "Berechtigung", value: 1 },
        { text: "Vertretung", value: 2 },
        { text: "Beide", value: 0 }
    ],
    key: 'value',
});

const columns: IColumn[] = sachbearbeiter_einfach_columns;
/*
const get_is_administrator = (sb_nummer: number, f17003: string): boolean => {
    let arr: number[] = row!.f17003.split(";").filter(i => i).map(i => parseInt(i));
    for (let i of allSachbearbeiter) {
        for (let k of arr) {
            if (k === i.f07001) {
                result.push(i);
                break;
            }
        }
    }
}*/
const SachbearbeiterGruppenVerwaltePopup: React.FC<ISachbearbeiterGruppenVerwaltePopupProps> = ({ callback, row }): JSX.Element => {

    const isAdd = row === undefined;
    const konfiguration = useAppSelector((state) => state.store_einstellungen.konfiguration);
    const [gruppenNummer, setGruppenNummer] = useState<number>(!isAdd ? row!.f17001 : 0);
    const [bezeichnung, setBezeichnung] = useState(!isAdd ? row!.f17002 : "");
    const [gruppenartValue, setGruppenartValue] = useState(!isAdd ? row!.f17004 : 1);

    const dispatch = useAppDispatch();
    const auftragserfassung = useAppSelector((state) => state.store_einstellungen.konfiguration.auftragserfassung);
    const [allSachbearbeiter, setAllSachbearbeiter] = useState<ISachbearbeiterEinfach[]>([]);
    const refDataGrid = useRef() as React.MutableRefObject<DataGrid<any, any>>;
    const refDataGridVertretung = useRef() as React.MutableRefObject<DataGrid<any, any>>;
    const refDataGridBerchtigungen = useRef() as React.MutableRefObject<DataGrid<any, any>>;
    const [alleBerechtigungen, setAlleBerchtigungen] = useState<ISachbearbeiterGruppenBerechtigungen[]>([]);
    const [selectedMitglieder, setSelectedMitglieder] = useState<ISachbearbeiterEinfach[]>([]);
    const [selectedMitgliederGeaendert, setSelectedMitgliederGeaendert] = useState<ISachbearbeiterEinfach[]>([]);
    const [showMitgliedTable, setShowMitgliedTable] = useState(false);
    const [selectedVertreter, setSelectedVertreter] = useState<ISachbearbeiterEinfach[]>([]);
    const [showVertreterTable, setShowVertreterTable] = useState(false);
    const [selectedBerechtigung, setSelectedBerechtigung] = useState<ISachbearbeiterGruppenBerechtigungen[]>([]);
    const [showBerechtigungTable, setShowBerechtigungTable] = useState(false);
    const [selectedBerechtigungGeaendert, setSelectedBerechtigungGeaendert] = useState<ISachbearbeiterGruppenBerechtigungen[]>([]);
    const selectedRowAllSachbearbeiter = useMemo(() => selectedMitglieder.map((item) => { return item.f07001 }), [selectedMitglieder]);
    const selectedRowVertreter = useMemo(() => selectedVertreter.map((item) => { return item.f07001 }), [selectedVertreter]);
    const selectedRowBerechtigung = useMemo(() => selectedBerechtigung.map((item) => { return item.f18001 }), [selectedBerechtigung]);

    useEffect(() => {
        if (allSachbearbeiter.length > 0) {
            let result: ISachbearbeiterEinfach[] = [];
            if (gruppenartValue === 3) {
                let arr: number[] = row!.f17003.split(";").filter(i => i).map(i => parseInt(i));
                for (let i of allSachbearbeiter) {
                    for (let k of arr) {
                        if (k === i.f07001) {
                            result.push(i);
                            break;
                        }
                    }
                }

                setSelectedMitglieder(result);
                setSelectedMitgliederGeaendert(result);
                setShowMitgliedTable(true);
            } else {
                let result: ISachbearbeiterEinfach[] = [];
                for (let i of allSachbearbeiter) {
                    if (i.f07012 && i.f07012.indexOf(";" + gruppenNummer.toString() + ";") > -1) {
                        result.push(i);
                    }
                }
                setSelectedMitglieder(result);
                setSelectedMitgliederGeaendert(result);
                setShowMitgliedTable(true);
            }
        }
    }, [allSachbearbeiter, gruppenartValue, gruppenNummer, row]);

    useEffect(() => {
        if (row?.f17003) {
            let result: ISachbearbeiterEinfach[] = [];
            if (gruppenartValue === 0 || gruppenartValue === 2) {
                let arr: number[] = row!.f17003.split(";").filter(i => i).map(i => parseInt(i));
                for (let i of allSachbearbeiter) {
                    for (let k of arr) {
                        if (k === i.f07001) {
                            result.push(i);
                            break;
                        }
                    }
                }
                setSelectedVertreter(result);
            } else {
                setSelectedVertreter(result);
            }
            setShowVertreterTable(true);
        }
    }, [row, allSachbearbeiter, gruppenartValue]);

    useEffect(() => {
        if (alleBerechtigungen.length > 0) {
            let result: ISachbearbeiterGruppenBerechtigungen[] = [];
            if (gruppenartValue === 3) {
                setSelectedBerechtigung(result);
                setSelectedBerechtigungGeaendert(result);
            } else {
                for (let i of alleBerechtigungen) {
                    if (i.f18002 && i.f18002.indexOf(";" + gruppenNummer.toString() + ";") > -1) {
                        result.push(i);
                    }
                }
                setSelectedBerechtigung(result);
                setSelectedBerechtigungGeaendert(result);
            }
            setShowBerechtigungTable(true);
        }
    }, [alleBerechtigungen, gruppenartValue, gruppenNummer]);


    useEffect(() => {
        GetData({
            Operation: "GetAllSachbearbeiter",
        }, dispatch).then((res) => {
            if (!res) return
            setAllSachbearbeiter(res.data);
        })
        GetData({
            Operation: "GetAllBerechtigungen",
        }, dispatch).then((res) => {
            if (!res) return
            setAlleBerchtigungen(res.data);
        })
        if (isAdd) {
            GetData({
                Operation: "ErmittleFreieGruppennummer",
            }, dispatch).then((res) => {
                if (!res) return
                setGruppenNummer(parseInt(res.nummer));
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleClickSpeichern = useCallback(async () => {
        if (gruppenNummer < 1) {
            dispatch(setToast({
                message: "Gruppennummer muss vorhanden sein",
                type: "error",
                displayTime: 3000
            }));
            return;
        }

        if (!bezeichnung) {
            dispatch(setToast({
                message: "Bezeichnung muss vorhanden sein",
                type: "error",
                displayTime: 3000
            }));
            return;
        }

        let alle_funktionen_gesperrt = false;

        let vertreteneSachbearbeiter = ";";

        const mitglieder_selected: ISachbearbeiterEinfach[] = (refDataGrid && refDataGrid.current && refDataGrid.current.instance) ? refDataGrid.current.instance.getSelectedRowsData() : [];
        const vertretung_selected: ISachbearbeiterEinfach[] = (refDataGridVertretung && refDataGridVertretung.current && refDataGridVertretung.current.instance) ? refDataGridVertretung.current.instance.getSelectedRowsData() : [];
        const berechtigung_Selected: ISachbearbeiterGruppenBerechtigungen[] = (refDataGridBerchtigungen && refDataGridBerchtigungen.current && refDataGridBerchtigungen.current.instance) ? refDataGridBerchtigungen.current.instance.getSelectedRowsData() : [];


        if (gruppenartValue === 3) { // Bei Mobil Gruppen: Sachbearbeiter in Vertreter Sachbearbeiter (T017 f17003 speichern)
            for (let i of mitglieder_selected) {
                vertreteneSachbearbeiter = vertreteneSachbearbeiter + i.f07001 + ";";
            }
        } else {
            for (let i of vertretung_selected) {
                vertreteneSachbearbeiter = vertreteneSachbearbeiter + i.f07001 + ";";
            }
        }

        if (isAdd) {
            // Berechtigungsgruppe -> keine Vertretungsdaten
            if (gruppenartValue === 1) {
                vertreteneSachbearbeiter = ";";
            }

            // Vertretungsgruppe -> alle Funktionen sperren
            else if (gruppenartValue === 2 || gruppenartValue === 3) {
                alle_funktionen_gesperrt = true;
            }

        } else {
            if (row?.f17004 !== gruppenartValue) {
                // vorher Vertretung jetzt Berechtigung -> Vertretungsdaten löschen
                if (row?.f17004 === 2 && gruppenartValue === 1) {
                    vertreteneSachbearbeiter = ";";
                }

                // vorher Beides oder Berechtigung jetzt Vertretung -> alle Funktionen sperren
                if (gruppenartValue === 2 || gruppenartValue === 3) {
                    alle_funktionen_gesperrt = true;
                }
            }

        }


        // Gruppendaten			
        let daten = new Seperatorliste("%01", "%02");
        daten.setValue("Gruppennummer", gruppenNummer.toString());
        daten.setValue("Bezeichnung", bezeichnung.trim());
        daten.setValue("VertreteneSachbearbeiter", vertreteneSachbearbeiter);
        daten.setValue("Gruppenart", gruppenartValue.toString());
        let erfolg = true;

        const res = await GetDataAsync({
            Operation: isAdd ? "InsertSachbearbeitergruppe" : "UpdateSachbearbeitergruppe",
            Daten: daten.getSeperatorliste()
        });
        if (!res) return
        res.erfolg ? (erfolg = true) : (erfolg = false);

        if (!erfolg) {
            dispatch(setAlert({
                text: res.hinweis ? res.hinweis : "Es ist ein Fehler aufgetreten. Die Änderungen konnten nicht vorgenommen werden.",
                type: "Fehler"
            }));
            callback();
            return
        }

        if (gruppenartValue === 3) { // Bei Mobil Gruppen: Sachbearbeiter in Vertreter Sachbearbeiter (T017 f17003 speichern)

            const res1 = await GetDataAsync({
                Operation: "UpdateSachbearbeitergruppenMitglieder",
                Sachbearbeitergruppendaten: ""
            });
            if (!res1) return
            res1.erfolg ? (erfolg = true) : (erfolg = false);
            if (!erfolg) {
                dispatch(setAlert({
                    text: res1.hinweis ? res1.hinweis : "Es ist ein Fehler aufgetreten. Die Änderungen konnten nicht vorgenommen werden.",
                    type: "Fehler"
                }));
                callback();
                return
            }
        } else {
            // Sb Daten
            let listeMitglieder = new Seperatorliste("%00", "%01");
            if (isAdd) {
                // Hier muss noch dem Sachbearbeiter der Gruppe hinzugefügt werden.
                for (let j of selectedMitglieder) {
                    let gruppenSb = j.f07012;
                    if (!gruppenSb || gruppenSb == null || gruppenSb === "") {
                        gruppenSb = ";";
                    }
                    if (gruppenSb.indexOf(";" + gruppenNummer.toString() + ";") < 0) {
                        gruppenSb = gruppenSb + gruppenNummer.toString() + ";";
                    }
                    listeMitglieder.setValue(j.f07001.toString(), gruppenSb);
                }
            } else {
                for (let j of selectedMitgliederGeaendert) {
                    let gruppenSb = j.f07012;
                    listeMitglieder.setValue(j.f07001.toString(), gruppenSb);
                }
            }


            var mitgliederGeaendert = listeMitglieder.getSeperatorliste();
            if (mitgliederGeaendert) {

                const res2 = await GetDataAsync({
                    Operation: "UpdateSachbearbeitergruppenMitglieder",
                    Sachbearbeitergruppendaten: mitgliederGeaendert
                });
                if (!res2) return
                res2.erfolg ? (erfolg = true) : (erfolg = false);
                if (!erfolg) {
                    dispatch(setAlert({
                        text: res2.hinweis ? res2.hinweis : "Es ist ein Fehler aufgetreten. Die Änderungen konnten nicht vorgenommen werden.",
                        type: "Fehler"
                    }));
                    callback();
                    return
                }
            }
        }

        if (gruppenartValue === 3) { // Bei Mobil Gruppen: Sachbearbeiter in Vertreter Sachbearbeiter (T017 f17003 speichern)
            const res3 = await GetDataAsync({
                Operation: "UpdateGesperrteFunktionen",
                Funktionsdaten: ""
            });
            if (!res3) return
            res3.erfolg ? (erfolg = true) : (erfolg = false);
            if (!erfolg) {
                dispatch(setAlert({
                    text: res3.hinweis ? res3.hinweis : "Es ist ein Fehler aufgetreten. Die Änderungen konnten nicht vorgenommen werden.",
                    type: "Fehler"
                }));
                callback();
                return
            }
        } else {
            var listeGesperrt = new Seperatorliste("%00", "%01");

            let temp_arr = [];
            // wenn vertretung oder mobil dann alle Funktionen sperren
            if (alle_funktionen_gesperrt) {
                temp_arr = alleBerechtigungen;
            }
            else {
                isAdd ?
                    temp_arr = berechtigung_Selected : temp_arr = selectedBerechtigungGeaendert;
            }

            if (isAdd) {

                for (let k of temp_arr) {
                    let gesperrteGruppen = k.f18002;
                    if (!gesperrteGruppen || gesperrteGruppen == null || gesperrteGruppen === "") {
                        gesperrteGruppen = ";";
                    }
                    (gesperrteGruppen.indexOf(";" + gruppenNummer.toString() + ";") < 0) &&
                        (gesperrteGruppen = gesperrteGruppen + gruppenNummer.toString() + ";")
                    listeGesperrt.setValue(k.f18001, gesperrteGruppen);
                }
            } else {
                for (let p of temp_arr) {
                    let gruppen = p.f18002;
                    listeGesperrt.setValue(p.f18001, gruppen);
                }
            }

            var funktionenGeaendert = listeGesperrt.getSeperatorliste();

            if (funktionenGeaendert) {
                const res4 = await GetDataAsync({
                    Operation: "UpdateGesperrteFunktionen",
                    Funktionsdaten: funktionenGeaendert
                });
                if (!res4) return
                res4.erfolg ? (erfolg = true) : (erfolg = false);
                if (!erfolg) {
                    dispatch(setAlert({
                        text: res4.hinweis ? res4.hinweis : "Es ist ein Fehler aufgetreten. Die Änderungen konnten nicht vorgenommen werden.",
                        type: "Fehler"
                    }));
                    callback();
                    return
                }
            }
        }

        callback(true);
    }, [gruppenNummer, dispatch, bezeichnung, callback, alleBerechtigungen, selectedBerechtigungGeaendert, selectedMitgliederGeaendert, selectedMitglieder, gruppenartValue, isAdd, row])




    const handleCallBack = useCallback(() => {
        callback();
    }, [callback]);


    const memo_options_loeschen = useMemo(() => {
        return {
            text: 'Löschen',
            type: "danger",
            onClick: () => { delete_sachbearbeitergruppe(dispatch, row, callback) },
        }
    }, [dispatch, row, callback]);

    const memo_options_speichern = useMemo(() => {
        return {
            text: 'Speichern',
            onClick: handleClickSpeichern,
        }
    }, [handleClickSpeichern]);

    const memo_options_abrrechen = useMemo(() => {
        return {
            text: 'Abbrechen',
            onClick: handleCallBack,
        }
    }, [handleCallBack]);

    const handleSelectedRowChangeSB = useCallback((data: any) => {
        let result = selectedMitgliederGeaendert;

        //Zuerst alle gruppennummer weg löschen
        for (let k in result) {
            if (result[k].f07012.indexOf(";" + gruppenNummer.toString() + ";") > -1) {
                result[k].f07012 = result[k].f07012.replace(";" + gruppenNummer.toString() + ";", ";");
            }
        }
        for (let i of data.selectedRowsData) {
            let new_sb: ISachbearbeiterEinfach = i;
            let gruppenSb = new_sb.f07012;
            if (!gruppenSb || gruppenSb == null || gruppenSb === "") {
                gruppenSb = ";";
            }
            if (gruppenSb.indexOf(";" + gruppenNummer.toString() + ";") < 0) {
                // Selektriert
                gruppenSb = gruppenSb + gruppenNummer.toString() + ";";
            }
            new_sb.f07012 = gruppenSb;
            let found = false
            for (let j in result) {
                if (result[j].f07001 === new_sb.f07001) {
                    result[j] = new_sb;
                    found = true;
                    break;
                }
            }
            if (!found) {
                result.push(new_sb);
            }
        }


        setSelectedMitgliederGeaendert(result);
        //  setSelectedMitglieder(data.selectedRowsData)

    }, [selectedMitgliederGeaendert, gruppenNummer]);
    /*
        const handleSelectChangeVertreter = useCallback((data: any) => {
            setSelectedVertreter(data.selectedRowsData)
        }, []);
    */
    const handleSelectChangeBerechtigung = useCallback((data: any) => {
        let result = selectedBerechtigungGeaendert;

        //Zuerst alle gruppennummer weg löschen
        for (let k in result) {
            if (result[k].f18002.indexOf(";" + gruppenNummer.toString() + ";") > -1) {
                result[k].f18002 = result[k].f18002.replace(";" + gruppenNummer.toString() + ";", ";");
            }
        }

        for (let i of data.selectedRowsData) {
            let new_sb: ISachbearbeiterGruppenBerechtigungen = i;
            let gruppenSb = new_sb.f18002;
            if (!gruppenSb || gruppenSb == null || gruppenSb === "") {
                gruppenSb = ";";
            }
            if (gruppenSb.indexOf(";" + gruppenNummer.toString() + ";") < 0) {
                // Selektriert
                gruppenSb = gruppenSb + gruppenNummer.toString() + ";";
            }
            new_sb.f18002 = gruppenSb;
            let found = false
            for (let j in result) {
                if (result[j].f18001 === new_sb.f18001) {
                    result[j] = new_sb;
                    found = true;
                    break;
                }
            }
            if (!found) {
                result.push(new_sb);
            }
        }
        setSelectedBerechtigungGeaendert(result);
        //  setSelectedBerechtigung(data.selectedRowsData)


    }, [selectedBerechtigungGeaendert, gruppenNummer]);

    const RenderePopupTitle = useCallback(() => {
        return (<h2 className="title_popup">{isAdd ? "Sachbearbeitergruppe anlegen" : "Sachbearbeitergruppen bearbeiten"}</h2>)
    }, [isAdd]);

    return (
        <>
            <Popup
                visible={true}
                onHiding={handleCallBack}
                dragEnabled={true}
                hideOnOutsideClick={true}
                fullScreen={false}
                //animation={undefined}
                showCloseButton={true}
                // height='auto'
                maxHeight="80vh"
                maxWidth="70vw"
            >
                <ToolbarItem
                    toolbar="top"
                    location={'before'}
                    render={RenderePopupTitle}
                />
                {(!isAdd && (bezeichnung.toLowerCase() !== "administratoren" && bezeichnung.toLowerCase() !== "mobil" && !auftragserfassung)) &&
                    <ToolbarItem
                        widget="dxButton"
                        toolbar="bottom"
                        location="before"
                        options={memo_options_loeschen}
                    />}
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={memo_options_speichern}
                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={memo_options_abrrechen}
                />
                <TabPanel animationEnabled={true} height={"100%"}>
                    <Item title="Gruppe und Mitglieder" >
                        <ScrollView>
                            <div className='row p-2 p-md-3'>
                                <div className="col-12 col-md-6 p-1 p-md-2">
                                    <NumberBox
                                        label="Gruppennummer"
                                        labelMode="floating"
                                        stylingMode="outlined"
                                        onValueChange={setGruppenNummer}
                                        value={gruppenNummer}
                                        disabled={!isAdd}
                                        max={99999}
                                        onContentReady={handleContentReadyTextBox}
                                    //valueChangeEvent="input"
                                    />
                                </div>
                                <div className="col-12 col-md-6 p-1 p-md-2">
                                    <TextBox
                                        label="Bezeichnung"
                                        labelMode="floating"
                                        stylingMode="outlined"
                                        onValueChange={setBezeichnung}
                                        value={bezeichnung}
                                        disabled={(bezeichnung.toLowerCase() === "administratoren" || bezeichnung.toLowerCase() === "mobil" || auftragserfassung)}
                                        valueChangeEvent="input"
                                        maxLength={50}
                                        onContentReady={handleContentReadyTextBox}
                                    />
                                </div>
                                <div className="col-12 p-1 p-md-2">
                                    <SelectBox
                                        dataSource={konfiguration.winowig_mail ? dataSourceGruppenArtWithWinowigMail : dataSourceGruppenArt}
                                        displayExpr="text"
                                        valueExpr="value"
                                        //acceptCustomValue={true}
                                        value={gruppenartValue}
                                        disabled={bezeichnung.toLowerCase() === "administratoren" || auftragserfassung}
                                        //onCustomItemCreating={customItemCreating}
                                        onValueChange={setGruppenartValue}
                                        label='Gruppenart'
                                        labelMode='floating'
                                        stylingMode='outlined'
                                        onContentReady={handleContentReadySelectBox}
                                    />
                                </div>
                                <div className="col-12 p-1 p-md-2 mt-4">
                                    {showMitgliedTable &&
                                        <>
                                            <p className="m-2"><strong>Mitglieder</strong></p>
                                            <DataGrid
                                                dataSource={allSachbearbeiter}
                                                allowColumnResizing={true}
                                                columnAutoWidth={true}
                                                renderAsync={true}
                                                //rowAlternationEnabled={true}
                                                //focusedRowEnabled={true} 
                                                ref={refDataGrid}
                                                disabled={auftragserfassung}
                                                //   remoteOperations={true}
                                                // scrolling={scroll_mode_infinite}
                                                // onOptionChanged={onFilterChanged}    
                                                wordWrapEnabled={true}
                                                rowAlternationEnabled={true}
                                                keyExpr={'f07001'}
                                                height={"auto"}
                                                noDataText="Keine Mitglieder"
                                                defaultSelectedRowKeys={selectedRowAllSachbearbeiter}
                                                //  selectedRowKeys={selectedRowAllSachbearbeiter}
                                                onSelectionChanged={handleSelectedRowChangeSB}
                                            >
                                                <Paging enabled={false} />
                                                <Selection
                                                    mode="multiple"
                                                    showCheckBoxesMode={"always"} // wenn das weg ist, wird selection bei Klicken eines Rows getriggt.
                                                />
                                                {
                                                    columns.map((column) => {
                                                        return (
                                                            <Column
                                                                dataField={column.dataField}
                                                                caption={column.caption}
                                                                dataType={column.dataType}
                                                                alignment={column.alignment}
                                                                cellRender={column.cellRender}
                                                                key={column.dataField}
                                                                visible={column.visible}
                                                            />
                                                        )
                                                    })
                                                }
                                            </DataGrid></>}
                                </div>
                            </div>
                        </ScrollView>
                    </Item>
                    {(gruppenartValue !== 1 && gruppenartValue !== 3 && showVertreterTable) && <Item title="Vertretung">
                        <ScrollView>
                            <div className='row g-0'>
                                <div className="col-12 p-1 p-md-2">
                                    <p className="m-2"><strong>Vertretung für</strong></p>
                                    <DataGrid
                                        dataSource={allSachbearbeiter}
                                        allowColumnResizing={true}
                                        columnAutoWidth={true}
                                        renderAsync={true}
                                        //rowAlternationEnabled={true}
                                        //focusedRowEnabled={false} 
                                        ref={refDataGridVertretung}
                                        rowAlternationEnabled={true}
                                        disabled={auftragserfassung}
                                        //  remoteOperations={true}
                                        //  scrolling={scroll_mode_infinite}
                                        //pager={{visible: false}}
                                        // onOptionChanged={onFilterChanged}    
                                        wordWrapEnabled={true}
                                        keyExpr={'f07001'}
                                        height={"auto"}
                                        noDataText="Keine Sachbearbeiter"
                                        //selectedRowKeys={selectedRowVertreter}
                                        defaultSelectedRowKeys={selectedRowVertreter}
                                    //    onSelectionChanged={handleSelectChangeVertreter}
                                    >
                                        <Paging enabled={false} />
                                        <Selection
                                            mode="multiple"
                                            showCheckBoxesMode={"always"} // wenn das weg ist, wird selection bei Klicken eines Rows getriggt.
                                        />
                                        {
                                            columns.map((column) => {
                                                return (
                                                    <Column
                                                        dataField={column.dataField}
                                                        caption={column.caption}
                                                        dataType={column.dataType}
                                                        alignment={column.alignment}
                                                        cellRender={column.cellRender}
                                                        key={column.dataField}
                                                        visible={column.visible}
                                                    />
                                                )
                                            })
                                        }
                                    </DataGrid>
                                </div></div></ScrollView>
                    </Item>}
                    {(gruppenartValue !== 2 && gruppenartValue !== 3 && showBerechtigungTable) && <Item title="Gesperrte Funktionen"
                        disabled={bezeichnung.toLowerCase() === "administratoren"}>
                        <ScrollView>
                            <div className='row g-0'>
                                <div className="col-12 p-1 p-md-2">
                                    <p className="m-2"><strong>Gesperrte Funktionen</strong></p>
                                    <DataGrid
                                        dataSource={alleBerechtigungen}
                                        allowColumnResizing={true}
                                        columnAutoWidth={true}
                                        renderAsync={true}
                                        //rowAlternationEnabled={true}
                                        //focusedRowEnabled={false} 
                                        ref={refDataGridBerchtigungen}
                                        disabled={auftragserfassung}
                                        rowAlternationEnabled={true}
                                        // remoteOperations={true}
                                        // scrolling={scroll_mode_infinite}
                                        //pager={{visible: false}}
                                        // onOptionChanged={onFilterChanged}    
                                        wordWrapEnabled={true}
                                        keyExpr={'f18001'}
                                        height={"auto"}
                                        noDataText="Keine Funktionen"
                                        // height={530}
                                        //  selectedRowKeys={selectedRowBerechtigung}
                                        defaultSelectedRowKeys={selectedRowBerechtigung}
                                        onSelectionChanged={handleSelectChangeBerechtigung}
                                    >
                                        <Paging enabled={false} />
                                        <Selection
                                            mode="multiple"
                                            showCheckBoxesMode={"always"} // wenn das weg ist, wird selection bei Klicken eines Rows getriggt.
                                        />
                                        {
                                            <Column dataField="f18001" caption="Programme" cellRender={ProgrammRender} />
                                        }
                                    </DataGrid>
                                </div>
                            </div>
                        </ScrollView>
                    </Item>}
                </TabPanel>

            </Popup>
        </>
    )
}

export default SachbearbeiterGruppenVerwaltePopup;


const ProgrammRender = (props: any) => {
    if (props.data.f18001.toUpperCase().indexOf(".EXE") > -1) {
        return <p className="fw-500">{props.data.f18001}</p>
    } else {
        return <p className="ml-3">{props.data.f18001}</p>
    }
}

const delete_sachbearbeitergruppe = (dispatch: any, data: any, callback: any) => {
    dispatch(setConfirm({
        body:
            <div className="alert alert-danger">
                <p>Wollen Sie die Sachbearbeitergruppe mit der Bezeichnung <b>{data.f17002}</b> wirklich löschen?</p>
            </div>,
        on_ok: () => {
            GetData({
                Operation: "DeleteSachbearbeitergruppe",
                Sachbearbeitergruppennummer: data.f17001.toString(),
            }, dispatch).then((res) => {
                if (!res) return
                callback(true);
            })

        },
        title: "Sachbearbeitergruppe löschen",
    }));
}