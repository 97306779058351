import React, { memo, useMemo, useRef, useState } from 'react';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import './header.scss';
import { useScreenSizeClass } from '../../../global/media-query';
import { CheckBox, DataGrid, Tooltip } from 'devextreme-react';
import TextBox from 'devextreme-react/text-box';
import HeaderFilter from './filter';
import { useAppSelector } from '../../../state';
import { IFaelleFilter } from '../interfaces';
import { MIN_DATE, icon_column_chooser, icon_kartei_katen_darstellung, icon_listen_ansicht, icon_pdf, icon_refresh } from '../../../global/const';
import { dbTimeToString, exportGridAsPDF, get_date_ajax_param, handleContentReadyCheckBox, handleContentReadyTextBox, handleContentReadyToolbar, setTooltipEvents } from '../../../global/hilfs_functions';
import { useId } from "react-id-generator";
import { MDButton, RendereGridTitle } from '../../../global/components';
import { useCallback } from 'react';
import { format_faelle_bar_bezahlt, format_faelle_export_sachbearbeiter_nummer, format_faelle_export_title, format_faelle_handy_parken, format_faelle_kfz_kennzeichen, format_faelle_status, format_faelle_zeitstempel, format_fallart, rendere_tatbestandsnummer } from '../../../sonstiges/columns';
import { getGesamtbetragAusF010 } from '../hilfs_functions';



interface IHeaderProps {
  fall_art: number,
  liste_ansicht: (value: boolean) => void,
  dataGridRef?: React.MutableRefObject<DataGrid<any, any>>,
  kfz_suche: boolean,
  setKfz_suche: (value: boolean | null) => void,
  setFilter: (value: IFaelleFilter) => void,
  filter: IFaelleFilter,
  refresh: () => void,
}

const style1 = { maxWidth: "400px" }
const PagesHeader: React.FC<IHeaderProps> = ({ refresh, fall_art, liste_ansicht, dataGridRef, kfz_suche, setKfz_suche, setFilter, filter }): JSX.Element => {
  const cssClass = useScreenSizeClass();
  const listAnsichtAktiv = useAppSelector((state) => state.store_einstellungen.konfiguration.faelle_ansicht_liste);
  const recordCount = useAppSelector((state) => state.store_temp_data.total_count);
  const temp_data_satz = useAppSelector((state) => state.store_temp_data.temp_data_satz);
  const title = useMemo(() => get_title(fall_art), [fall_art]);
  const refSearchTextBox = useRef() as React.MutableRefObject<TextBox>;
  const ids = useId(1, "Faelle_");
  const [tooltip, setTooltip] = useState({
    kfz_suche: false
  });

  const handleClickKarteiKarten = useCallback(() => {
    liste_ansicht(false)
  }, [liste_ansicht]);
  const handleClickListenansicht = useCallback(() => {
    liste_ansicht(true)
  }, [liste_ansicht]);

  const handleContentReady = useCallback((item: any) => {
    setTooltipEvents(item, (value: boolean) => { setTooltip({ ...tooltip, kfz_suche: value }) });
    handleContentReadyCheckBox(item);
  }, [tooltip]);

  const handleEnterSuche = useCallback((e: any, value?: string) => {
    //Hier auch datum filter auf alle setzen. Der Rest bleibt.
    //MD 02.02.2023 Warum? MAchen wir besser weg    
    setFilter({
      ...filter,
      Suchbegriff: (value !== undefined) ? value : e.event.currentTarget.value,
      //    DatumVon: get_date_ajax_param(MIN_DATE),
      //    DatumBis: get_date_ajax_param(new Date()),
    });

  }, [filter, setFilter]);

  const handleClickRefresh = useCallback(() => {
    let ele = refSearchTextBox.current.instance.$element() as any;
    let input = ele[0].querySelector("input");
    if (input) {
      handleEnterSuche(undefined, input.value);
    }
  }, [handleEnterSuche]);

  const handlePDF = useCallback(() => {
    let header = get_pdf_header_faelle(filter, fall_art, kfz_suche);
    exportGridAsPDF(dataGridRef, "Faelle.pdf", header, "landscape", "striped", (cellData: any) => {
      if (cellData.gridCell.column.dataField === 'f003' && cellData.gridCell.rowType === 'data') {
        cellData.pdfCell.text = dbTimeToString(parseInt(cellData.pdfCell.text));
      } else if (cellData.gridCell.column.dataField === 'f005' && cellData.gridCell.rowType === 'data') {
        cellData.pdfCell.text = format_fallart(cellData.gridCell.data);
      } else if (cellData.gridCell.column.dataField === 'f006' && cellData.gridCell.rowType === 'data') {
        cellData.pdfCell.text = format_faelle_status(cellData.gridCell.data.f006);
      } else if (cellData.gridCell.column.dataField === 'f007' && cellData.gridCell.rowType === 'data') {
        cellData.pdfCell.text = format_faelle_kfz_kennzeichen(cellData.gridCell.data);
      } else if (cellData.gridCell.column.dataField === 'f008' && cellData.gridCell.rowType === 'data') {
        cellData.pdfCell.text = format_faelle_zeitstempel(cellData.gridCell.data.f008);
      } else if (cellData.gridCell.column.dataField === 'betrag' && cellData.gridCell.rowType === 'data') {
        cellData.pdfCell.text = getGesamtbetragAusF010(cellData.gridCell.data.f010);
      } else if (cellData.gridCell.column.dataField === 'f111' && cellData.gridCell.rowType === 'data') {
        cellData.pdfCell.text = dbTimeToString(parseInt(cellData.pdfCell.text));
      } else if (cellData.gridCell.column.dataField === 'f112' && cellData.gridCell.rowType === 'data') {
        cellData.pdfCell.text = format_faelle_export_sachbearbeiter_nummer(parseInt(cellData.pdfCell.text));
      } else if (cellData.gridCell.column.dataField === 'f010.bar' && cellData.gridCell.rowType === 'data') {
        cellData.pdfCell.text = format_faelle_bar_bezahlt(cellData.gridCell.data.f010);
      } else if (cellData.gridCell.column.dataField === 'exportTitel' && cellData.gridCell.rowType === 'data') {
        cellData.pdfCell.text = format_faelle_export_title(cellData.gridCell.data);
      } else if (cellData.gridCell.column.dataField === 'f010.MPStatus' && cellData.gridCell.rowType === 'data') {
        cellData.pdfCell.text = format_faelle_handy_parken(cellData.gridCell.data.f010);
      } else if (cellData.gridCell.column.dataField === 'f010.tatbestandsnummer' && cellData.gridCell.rowType === 'data') {
        cellData.pdfCell.text = rendere_tatbestandsnummer(cellData.gridCell.data);
      } else if (cellData.gridCell.column.dataField === 'f301' && cellData.gridCell.rowType === 'data') {
        cellData.pdfCell.text = dbTimeToString(parseInt(cellData.pdfCell.text));
      }
    })
  }, [filter, fall_art, kfz_suche, dataGridRef]);

  const handleShowColumnChooser = useCallback(() => {
    if (dataGridRef && dataGridRef.current) {
      dataGridRef.current.instance.showColumnChooser();
    }
  }, [dataGridRef]);


  const RendereTitle = useCallback(() => {
    return RendereGridTitle(title);
  }, [title]);

  return (
    <Toolbar className={"pagesHeader_container " + cssClass} onContentReady={handleContentReadyToolbar}>
      <Item
        location={'before'}
        render={RendereTitle}
      />
      <Item
        location={'before'}
        cssClass={"anzahl " + cssClass}
        text={temp_data_satz ? "Anzahl: 1" : "Anzahl: " + recordCount}
        locateInMenu="auto"
      />

      <Item
        location={'center'}
        widget={'dxButton'}
        locateInMenu="auto"
      >
        <MDButton
          page='faelle'
          icon={icon_kartei_katen_darstellung}
          onClick={handleClickKarteiKarten}
          tooltip_text="Karteikartendarstellung"
        />
      </Item>
      <Item
        location={'center'}
        widget={'dxButton'}
        locateInMenu="auto"
      >
        <MDButton
          page='faelle'
          icon={icon_listen_ansicht}
          tooltip_text='Tabellendarstellung'
          onClick={handleClickListenansicht}
        />
      </Item>
      <Item
        location={'after'}
        locateInMenu="auto"
      >
        <CheckBox
          id={ids[0]}
          value={kfz_suche}
          className='kfz_suche'
          onValueChange={setKfz_suche}
          onContentReady={handleContentReady}
          text={"KFZ-Suche"}
        />
        <Tooltip
          target={'#' + ids[0]}
          visible={tooltip.kfz_suche}
          hideOnOutsideClick={false}
        >
          <p
            style={style1}>Es werden lediglich die Kennzeichen durchsucht (Schnellere Durchführung der Suche). Falls über alle Daten der Fälle gesucht werden soll, deaktivieren Sie diese Funktion</p>
        </Tooltip>
      </Item>

      <Item
        location={'after'}
        locateInMenu="auto"
      >
        <TextBox
          stylingMode='outlined'
          mode='text'
          label='Suche'
          ref={refSearchTextBox}
          showClearButton={true}
          defaultValue={filter.Suchbegriff}
          onEnterKey={handleEnterSuche}
          maxLength={60}
          className="suche_feld"
          onContentReady={handleContentReadyTextBox}
        >
        </TextBox>
      </Item>
      <Item
        location={'after'}
        locateInMenu="auto"
      >
        <HeaderFilter fall_art={fall_art} filter={filter} setFilter={setFilter} />
      </Item>


      {(listAnsichtAktiv && dataGridRef !== undefined) &&
        <Item
          location={'after'}
          locateInMenu="auto"
        >
          <MDButton
            page='faelle'
            icon={icon_pdf}
            tooltip_text='Tabelle als PDF herunterladen'
            className="faelle_export_pdf"
            onClick={handlePDF}
          />
        </Item>
      }

      {(listAnsichtAktiv && dataGridRef !== undefined) &&
        <Item
          location={'after'}
          locateInMenu="auto"
        >
          <MDButton
            page='faelle'
            icon={icon_column_chooser}
            className="column_chooser_button"
            onClick={handleShowColumnChooser}
            tooltip_text="Spalten auswählen"
          />
        </Item>
      }

      <Item
        location={'after'}
        widget={'dxButton'}
      >
        <MDButton
          page='faelle'
          icon={icon_refresh}
          tooltip_text='Aktualisieren'
          onClick={handleClickRefresh}
        />
      </Item>
    </Toolbar>

    /*
    <Toolbar items={test} className={"pagesHeader_container " + cssClass}>
    </Toolbar>*/
  )
}

export default memo(PagesHeader);





const get_title = (fall_art: number): string => {
  let result = '';
  switch (fall_art) {
    case 1:
      result = "Fälle Verkehr";
      break;
    case 2:
      result = "Fälle Fließverkehr";
      break;
    case 3:
      result = "Fälle AOWI";
      break;
    case 4:
      result = "Abschleppfälle";
      break;
    case 5:
      result = "Aufträge";
      break;
    default:
      result = "";
      break;
  }
  return result;
}


const get_pdf_header_faelle = (filter: IFaelleFilter, fall_art: number, kfz_suche: boolean): string => {

  let pdfHeader = "";
  fall_art === 1 ?
    pdfHeader = "Fälle Verkehr" :
    fall_art === 2 ?
      pdfHeader = "Fälle Fließverkehr" :
      fall_art === 3 ?
        pdfHeader = "AOWI Fälle" : pdfHeader = "Abschleppfälle";


  if (filter.Suchbegriff) {
    if (kfz_suche) {
      pdfHeader += '\nKFZ-Suche: "' + filter.Suchbegriff + '"';
    }
    else {
      pdfHeader += '\nSuchbegriff: "' + filter.Suchbegriff + '"';
    }
  }
  /*
  var marginTop = 1.3;
  var cssTop = 40;
  var template = '<div style="position: absolute; width: 100%; height: 100%; top: 0;left: 0; font-family: "DejaVu Sans", "Arial", sans-serif;">'
            +'<div style="position: absolute; top: ' + cssTop + 'px; left: 50px; right: 55px; border-bottom: 1px solid #888; color: #888">'
            +	'<div style="float: right">Seite #: pageNum # von #: totalPages #</div>'
            +	pdfHeader 
            +'</div>';
            */
  //Datum von bis anzeigen im Pdf Header außer wenn 'Alle' ausgewählt ist
  if (filter.DatumVon !== get_date_ajax_param(MIN_DATE)) {
    pdfHeader += '\nDatum von: ' + filter.DatumVon;
    pdfHeader += ' bis: ' + filter.DatumBis;
    //cssTop += 25;
    //template += '<div style="position: absolute; top: ' + cssTop + 'px; left: 50px; right: 55px; border-bottom: 1px solid #888; color: #888">'
    //      +	DatumVonBis 
    //    +'</div>';
    //   marginTop += 0.4;	
  }

  //Filtereinstellungen im Header anzeigen
  var filterText = "";
  if (filter.Sachbearbeiternummer && filter.Sachbearbeiternummer !== "Alle") {
    filterText += '\nZeuge "' + filter.Sachbearbeiternummer + '",';
  }

  if (filter.Ort && filter.Ort !== "Alle") {
    filterText += '\nOrt "' + filter.Ort + '",';
  }

  if (filter.KeineGeloeschten) {
    filterText += '\nGelöschte Fälle nicht anzeigen,';
  }

  if (filter.NurAenderungen) {
    filterText += '\nNur Änderungen anzeigen,';
  }

  if (filter.NurEingestellteFaelle) {
    filterText += '\nNur eingestellte Fälle,';
  }

  if (filter.VornotierungenAnzeigen === "Ja") {
    filterText += '\nVornotierungen anzeigen: ' + filter.VornotierungenAnzeigen + ',';
  }

  if (filterText !== '' && filterText.length > 1) {
    filterText = filterText.slice(0, -1); //letztes Komma entfernen

    if (filterText !== '') {
      filterText = '\nFilter:' + filterText;
    }
    pdfHeader += filterText;
    //  cssTop += 25;
    //template += '<div style="position: absolute; top: ' + cssTop + 'px; left: 50px; right: 55px; border-bottom: 1px solid #888; color: #888">'
    //       +	'Filter: ' + filterText 
    //      +'</div>';
    //   marginTop += 0.4;
  }


  //template += '</div>'; 

  return pdfHeader
}