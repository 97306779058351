import { memo, useCallback, useState } from "react";
import { icon_plus } from "../../../global/const";
import { MDButton } from "../../../global/components";
import SachbearbeiterGruppeVerwaltePopup from "./verwalten_popup";


interface ISBGruppeAddProps {
    get_data: () => void,
}
const SachbearbeiterGruppenAdd: React.FC<ISBGruppeAddProps> = ({ get_data }): JSX.Element => {
    const [showPopUp, setShowPopUp] = useState(false);

    const handleHidePopup = useCallback((update?: boolean) => {
        setShowPopUp(false);
        update && get_data()
    }, [get_data]);

    const handleShowPopup = useCallback(() => {
        setShowPopUp(true)
    }, []);

    return (
        <div>
            <MDButton
                icon={icon_plus}
                type="success"
                page="sachbearbearbeiter_gruppen"
                onClick={handleShowPopup}
                tooltip_text="Sachbearbeitergruppe anlegen"
            />
            {showPopUp && <SachbearbeiterGruppeVerwaltePopup callback={handleHidePopup} />}
        </div>
    )
}

export default memo(SachbearbeiterGruppenAdd);