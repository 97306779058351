import { Popup, ScrollView } from "devextreme-react";
import { useState, memo, useMemo, useCallback } from "react";
import { IFallData, IFallDataZusaetzlich } from "../../../interfaces";
import { ToolbarItem } from "devextreme-react/autocomplete";
import { handleErrorImg, stretch0Left } from "../../../../../global/hilfs_functions";
import { useAppSelector } from "../../../../../state";
import { MDButton } from "../../../../../global/components";
import { icon_car } from "../../../../../global/const";


interface IABSAbschleppFotoProps {
  item: IFallData,
  itemZus: IFallDataZusaetzlich,
}

interface ISchaden {
  absSeite: string,
  absText: string,
  absArt: {
    left: number,
    top: number,
    marker: string
  }[],
  schadennummer: number,
  fotos: {
    url: string,
    alt: string,
  }[]
}


const ABSAbschleppFoto: React.FC<IABSAbschleppFotoProps> = ({ item, itemZus }): JSX.Element => {
  const mnr = useAppSelector((state) => state.store_einstellungen.konfiguration.mandantennummer);
  const [showPopupSchaden, setShowPopupSchaden] = useState(false);
  const schaden_vorhanden = itemZus.fall_art === 4 && item.f010.abs1Seite;
  const schaden = useMemo(() => get_schaden_data(item, schaden_vorhanden, item.mandantenspezifischesFotoverzeichnis, mnr), [item, schaden_vorhanden, mnr]);

  const handleClick = useCallback(() => {
    schaden_vorhanden && setShowPopupSchaden(true)
  }, [schaden_vorhanden]);

  const handleHidePopup = useCallback(() => {
    setShowPopupSchaden(false)
  }, []);

  const memo_options_ok = useMemo(() => {
    return {
      text: 'OK',
      stylingMode: 'outlined',
      onClick: handleHidePopup,
    }
  }, [handleHidePopup]);
  const RenderePopupTitle = useCallback(() => {
    return (<h2 className="title_popup">{"Schaden " + item.f007}</h2>)
  }, [item]);

  return (
    <>
      <li>
        <MDButton
          page="fall_card"
          icon={icon_car}
          className={schaden_vorhanden ? "highlight" : ""}
          tooltip_text={schaden_vorhanden ? "Es sind Schäden vorhanden" : "Es sind keine Schäden vorhanden"}
          onClick={handleClick}
        /></li>
      <Popup
        visible={showPopupSchaden}
        onHiding={handleHidePopup}
        dragEnabled={true}
        hideOnOutsideClick={true}
        fullScreen={false}
        showCloseButton={true}
        className="p-0"
        height="auto"
        maxHeight="75vh"
        maxWidth={"1000px"}
      >
        <ToolbarItem
          toolbar="top"
          location={'before'}
          render={RenderePopupTitle}
        />
        <ToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location={"center"}
          options={memo_options_ok}
        />
        <ScrollView>
          {
            schaden.map((item, i) => {
              return (

                <div className="row p-2 p-md-3" key={i}>
                  <div className="col-12 col-md-6 text-center">
                    <div className="schaden_images">
                      <img
                        width='400px'
                        height="100%"
                        src={"/Files/WinowigOnlineNT/Source/img/abschleppen/auto_" + item.absSeite.toLocaleLowerCase() + ".png"}
                        alt="Autoansicht"
                        onError={handleErrorImg} />
                      {<Images absArt={item.absArt} />}
                    </div>
                    <div><p>{item.absText}</p></div>
                  </div>
                  <div className="col-12 col-md-6">
                    {
                      item.fotos.map((foto, k) => {
                        return (
                          <a key={k} href={foto.url} target="_blank" rel="noreferrer">
                            <img
                              width="450px"
                              height="450px"
                              src={foto.url}
                              alt={foto.alt}
                              onError={handleErrorImg} />
                          </a>

                        )
                      })
                    }

                  </div>
                </div>
              )
            })
          }
        </ScrollView>
      </Popup>

    </>);
}

export default memo(ABSAbschleppFoto);


const Images: React.FC<{ absArt: any }> = memo(({ absArt }) => {

  return (
    <>
      {absArt.map((itemAbsArtWerte: any, j: any) => {
        var ratio = 400 / 320;

        var sideLength = 50 * ratio;
        var left = itemAbsArtWerte.left * ratio;
        var top = itemAbsArtWerte.top * ratio;


        return (
          <img
            style={{ position: "absolute", top: top + "px", left: left + "px" }}
            width={sideLength}
            height={sideLength}
            key={j}
            src={"/Files/WinowigOnlineNT/Source/img/abschleppen/marker_" + itemAbsArtWerte.marker.toLocaleLowerCase() + ".png"}
            alt={itemAbsArtWerte.marker.toLowerCase() + " " + itemAbsArtWerte.top}
          />
        )
      })}
    </>
  )
});

const get_schaden_data = (item: IFallData, schaden_vorhanden: boolean, mMandantenspezifischesFotoverzeichnis: boolean, mnr: string): ISchaden[] => {
  let schadennummer = 1;
  let absSeite = item.f010["abs" + schadennummer.toString() + "Seite"];
  let result: ISchaden[] = [];
  if (!schaden_vorhanden) return result;
  while (absSeite) {
    let absArtItem = [];

    let markernummer = 1;
    let absArt = item.f010["abs" + schadennummer.toString() + "Art" + markernummer.toString()];

    while (absArt) {
      let xy = item.f010["abs" + schadennummer.toString() + "XY" + markernummer.toString()].split('/');
      let left = Math.round(parseFloat(xy[0]));
      let top = Math.round(parseFloat(xy[1]));
      absArtItem.push({
        left: left,
        top: top,
        marker: absArt,
      });
      //  html += '<img positionX="' + left + '" positionY="' + top + '" class="schadensmarker ' + absArt.toLowerCase() + '" style="position:absolute; top:' + top + 'px; left:' + left + 'px;" width="50" height="50" src="/Files/WoOnline/Source/images/abschleppen/marker_' + absArt.toLowerCase() + '.png">';

      markernummer++;
      absArt = item.f010["abs" + schadennummer.toString() + "Art" + markernummer.toString()];
    }

    let fotosItem = [];
    let fotonummer = 1;
    let absFoto = item.f010["abs" + schadennummer.toString() + "Foto" + fotonummer.toString()];
    while (absFoto) {
      let fotoURL = "";
      if (mMandantenspezifischesFotoverzeichnis) {
        fotoURL = "/FileRequest/" + absFoto + "?Operation=GetFotoFromBenutzerdefiniertesFotoverzeichnis";
      } else {
        fotoURL = "/Files/WoOnline/M" + stretch0Left(mnr, 3) + "/MobilFotos/" + absFoto.substring(0, 8) + "/" + absFoto;
      }

      fotosItem.push({
        url: fotoURL,
        alt: "Schadenfoto " + fotonummer
      })

      fotonummer++;
      absFoto = item.f010["abs" + schadennummer.toString() + "Foto" + fotonummer.toString()];
    }

    let schaden_item: ISchaden = {
      absSeite: absSeite,
      absText: item.f010["abs" + schadennummer.toString() + "Text"] ? item.f010["abs" + schadennummer.toString() + "Text"] : "",
      absArt: absArtItem,
      schadennummer: schadennummer,
      fotos: fotosItem,
    }
    result.push(schaden_item);
    schadennummer++;
    absSeite = item.f010["abs" + schadennummer.toString() + "Seite"];
  }
  return result;

}