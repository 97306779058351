import { DataGrid, TextBox } from "devextreme-react";
import { Column, MasterDetail, Toolbar, Item as ItemGridToolbar, ColumnChooser, Scrolling, Paging, ColumnChooserSelection } from "devextreme-react/data-grid";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import MasterDetailsGeraete from "./master_details";
import { geraete_columns } from "../../../sonstiges/columns";
import { exportGridAsPDF, get_search_as_filter, handleContentReadyDataGrid, handleContentReadyTextBox } from "../../../global/hilfs_functions";
import { useAppDispatch, useAppSelector } from "../../../state";
import { GetData } from "../../../global/communication";
import { default_spalten_t705, icon_column_chooser, icon_csv, icon_pdf } from "../../../global/const";
import { get_end_spalten_to_db } from "../../faelle/hilfs_functions";
import { RendereGridTitleGeraete } from "../../../sonstiges/hilfs_functions";
import { MDButton } from "../../../global/components";
import CustomStore from "devextreme/data/custom_store";
import "./style.scss";
import { IColumn } from "../../../global/interfaces";

const get_columns = (spalten: string): IColumn[] => {
    !spalten && (spalten = default_spalten_t705)
    let arr: string[] = spalten.split(';').filter(i => i).map(i => i.trim());

    for (let j in geraete_columns) {
        geraete_columns[j].visible = false;
    }

    for (let i of arr) {
        for (let j in geraete_columns) {
            if (geraete_columns[j].dataField === i) {
                geraete_columns[j].visible = true;
                break;
            }
        }
    }

    return geraete_columns;

}



const customizeCell = (cellData: any) => {
    if (cellData.gridCell.column.dataField === 'f005' && cellData.gridCell.rowType === 'data') {
        switch (cellData.pdfCell.text.toString()) {
            case "0":
                cellData.pdfCell.text = "Betriebsbereit";
                break;
            case "1":
                cellData.pdfCell.text = "Gesperrt";
                break;
            case "2":
                cellData.pdfCell.text = "Gelöscht und Gesperrt";
                break;
            case "20":
                cellData.pdfCell.text = "Daten sollen gelöscht";
                break;
            default:
                cellData.pdfCell.text = "";
                break;
        }

    }
}
const Geraete = () => {
    const [searchValue, setSearchValue] = useState('');
    const dataGridRef = useRef() as React.MutableRefObject<DataGrid<any, any>>;
    const konfiguration_spalten = useAppSelector((state) => state.store_einstellungen.konfiguration.t705_spalten);


    const columns = useMemo(() => get_columns(konfiguration_spalten), [konfiguration_spalten]);

    const dispatch = useAppDispatch();
    const filter = useMemo(() => get_search_as_filter(searchValue), [searchValue]);
    const store_end = useMemo(() => {
        dataGridRef.current && dataGridRef.current.instance.hideColumnChooser();
        return store(konfiguration_spalten)
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [konfiguration_spalten]);

    const handleOptionsChange = useCallback((e: any) => {
        get_end_spalten_to_db(e, konfiguration_spalten, geraete_columns, dispatch, "T705")
    }, [konfiguration_spalten, dispatch]);


    const handleClickPDf = useCallback(() => {
        exportGridAsPDF(dataGridRef, "Geräte.pdf", searchValue ? "Suchbegriff: " + searchValue + "\n" : "", "landscape", "striped", customizeCell)
    }, [searchValue]);

    const handleColumnChooser = useCallback(() => {
        if (dataGridRef && dataGridRef.current) {
            dataGridRef.current.instance.showColumnChooser();
        }
    }, [dataGridRef]);


    useEffect(() => {
        dataGridRef && dataGridRef.current && dataGridRef.current.instance.filter(filter);
    }, [filter]);

    const handleDownloadCSV = useCallback(() => {
        let arr = dataGridRef.current.instance.getVisibleColumns().filter((e) => e.sortOrder);
        let sortspalte = arr.length > 0 ? arr[0].dataField : "";
        let sortRichtung = arr.length > 0 ? arr[0].sortOrder!.toUpperCase() : "";
        window.location.href = "/Files/csv/geraete.csv?Operation=GetGeraeteAsCSV&sortSpalte=" + sortspalte + "&sortRichtung=" + sortRichtung + "&searchValue=" + searchValue + "&felder=" + konfiguration_spalten;
    }, [konfiguration_spalten, searchValue]);

    return (
        <div className="data_grid_container mt-2 mt-md-3">
            <DataGrid
                dataSource={store_end}
                columnAutoWidth={true}
                wordWrapEnabled={true}
                rowAlternationEnabled={true}
                renderAsync={true}
                keyExpr="f001"
                // scrolling={scroll_mode_infinite}
                ref={dataGridRef}
                noDataText="Keine Geräte"
                onOptionChanged={handleOptionsChange}
                remoteOperations={true}
                onContentReady={handleContentReadyDataGrid}
            >
                <Scrolling mode="virtual" rowRenderingMode="virtual" preloadEnabled={true} />
                <Paging defaultPageSize={10} />
                <MasterDetail
                    enabled={true}
                    component={MasterDetailsGeraete}
                />
                {/* wegen Barrierfreiheit soll search hier daktiviert werden auch nicht viele items */}
                <ColumnChooser enabled={true} mode="select" title="Spalten auswählen" allowSearch={false} sortOrder="asc">
                    <ColumnChooserSelection
                        selectByClick={true} />
                </ColumnChooser>
                {
                    columns.map((column) => {
                        return (
                            <Column
                                dataField={column.dataField}
                                key={column.dataField}
                                caption={column.caption}
                                dataType={column.dataType}
                                visible={column.visible}
                                width={column.width}
                                cellRender={column.cellRender}
                                showInColumnChooser={column.showInColumnChooser}
                                alignment={column.alignment}
                                allowSorting={column.allowSorting}
                                minWidth={column.minWidth}
                            />
                        )
                    })
                }
                <Toolbar>
                    <ItemGridToolbar
                        location={'before'}
                        render={RendereGridTitleGeraete}
                    />
                    <ItemGridToolbar location="after" locateInMenu="auto" >
                        <TextBox
                            label="Suche"
                            stylingMode='outlined'
                            mode='text'
                            className="suche_feld"
                            value={searchValue}
                            onValueChange={setSearchValue}
                            showClearButton={true}
                            maxLength={60}
                            onContentReady={handleContentReadyTextBox}
                        >
                        </TextBox>
                    </ItemGridToolbar>
                    <ItemGridToolbar location="after" locateInMenu="auto">
                        <MDButton
                            icon={icon_pdf}
                            page='geraete'
                            tooltip_text="Tabelle als PDF herunterladen"
                            onClick={handleClickPDf}
                        />
                    </ItemGridToolbar>
                    <ItemGridToolbar
                        location={'after'}
                        locateInMenu="auto"
                    >
                        <MDButton
                            page='Geräte'
                            icon={icon_csv}
                            tooltip_text='Geräte als csv herunterladen'
                            onClick={handleDownloadCSV}

                        />
                    </ItemGridToolbar>
                    <ItemGridToolbar location="after" locateInMenu="auto">
                        <MDButton
                            icon={icon_column_chooser}
                            page='geraete'
                            onClick={handleColumnChooser}
                            tooltip_text="Spalten auswählen"
                        />
                    </ItemGridToolbar>
                </Toolbar>
            </DataGrid>
        </div>
    )
}

export default Geraete;



const store = (konfiguration_spalten: string) => {
    return new CustomStore({
        key: ["f001"],
        load(loadOptions: any) {

            //Achtung isLoadingAll => pdf Download
            //Achtung group => Löschgrund aufsplitten maximal 1000
            return GetData(
                {
                    Operation: "GetGeraete",
                    skip: (loadOptions.isLoadingAll || loadOptions.requireTotalCount) ? "0" : loadOptions["skip"].toString(),
                    take: loadOptions["isLoadingAll"] ? "1000" : loadOptions["take"].toString(),
                    filter: loadOptions.filter ? loadOptions.filter : "",
                    sortSpalte: loadOptions.sort ? loadOptions.sort[0].selector : "",
                    sortRichtung: loadOptions.sort ? loadOptions.sort[0].desc ? "DESC" : "ASC" : "",
                    felder: konfiguration_spalten
                }, undefined, false
            )
                .then((res) => {
                    if (!res || !res.erfolg)
                        return {
                            data: [],
                            totalCount: 0,
                        }

                    return {
                        data: res.data,
                        totalCount: res.totalCount,
                    }

                });
            //   }


            //  }
        }

    });
}
