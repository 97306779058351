import { Popup, ScrollView, TextArea, TextBox } from "devextreme-react"
import { ToolbarItem } from "devextreme-react/autocomplete"
import { useCallback, useEffect, useMemo, useState } from "react";
import { IStrasse } from "../interfaces";
import { ISelectedItemStrassen } from "..";
import { setToast, useAppDispatch } from "../../../../state";
import { GetData, GetDataAsync } from "../../../../global/communication";
import { useScreenSize } from "../../../../global/media-query";
import { MDButton } from "../../../../global/components";
import { icon_delete, icon_plus } from "../../../../global/const";
import { handleContentReadyTextBox } from "../../../../global/hilfs_functions";




interface IStrassenTabVerwaltePopupProps {
    callback: (update?: boolean, loeschen?: boolean) => void,
    selectedItem: ISelectedItemStrassen,
    row?: IStrasse | null,
}
/*

const data_source =
    new ArrayStore({
        data: [
            { text: "Nein", value: 0 },
            { text: "Ja", value: 1 }
        ],
        key: 'value',
    })
*/
const StrassenTabVerwaltePopup: React.FC<IStrassenTabVerwaltePopupProps> = ({ callback, row, selectedItem }): JSX.Element => {

    const isAdd = row === undefined;
    const [bezeichnung, setBezeichnung] = useState((!isAdd && row?.f001) ? row?.f001 : "");
    const [addTatbestandNummerPopup, setAddTatbestandNummerPopup] = useState(false);
    const [addTatbestandNummer, setAddTatbestandNummer] = useState("");
    const [dataErhoehung, setDataErhoehung] = useState<{ tbnr: string, vonBisTbnr: string }[]>([]);
    const [dataf004, setDataf004] = useState(row ? (row.f004.slice(-1) === ";" ? row.f004 : row.f004 + ';') : "");
    //const [handyParkenValue, setHandyParkenValue] = useState((!isAdd && row?.f002) ? row?.f002 : 0);
    const [parkZone, setParkZone] = useState((!isAdd && row?.f003) ? row?.f003 : "");
    const [hinweis, setHinweis] = useState((!isAdd && row?.f005) ? row?.f005 : "");
    const [gkz, setGkz] = useState((!isAdd && row?.f006) ? row?.f006 : "");
    const [swLat, setSwLat] = useState((!isAdd && row?.f007) ? row?.f007 : "");
    const [swLong, setSwLong] = useState((!isAdd && row?.f008) ? row?.f008 : "");
    const [noLat, setNoLat] = useState((!isAdd && row?.f009) ? row?.f009 : "");
    const [noLong, setNoLong] = useState((!isAdd && row?.f010) ? row?.f010 : "");
    const dispatch = useAppDispatch();
    const screenSize = useScreenSize();
    // const gpsRule =  { R:/[A-Z,a-z, ]/ };


    const get_erhoehung = useCallback(async () => {
        let mErhoehungen: { tbnr: string, vonBisTbnr: string }[] = [];
        let auswahl = dataf004.split(";").filter(i => i); //Data wurde schon in Useeffect gefragt

        for (let i = 0; i < auswahl.length; i++) {
            let tbnr = auswahl[i];
            let token = tbnr.split("/").filter(i => i);

            if (token.length > 1) {	//mit Tabellennummer nach dem Slash
                tbnr = token[0];
                const res = await GetDataAsync({
                    Operation: "GetTatbestandMitTabelle",
                    Tatbestandsnummer: tbnr
                });
                if (res && res.erfolg && res.erhoehungen) {
                    let vonBisTbnr = '';
                    //von bis ermitteln
                    let token = res.erhoehungen.split(";").filter((i: any) => i);
                    if (token.length > 1) {
                        vonBisTbnr = token[0];
                        vonBisTbnr = vonBisTbnr + ' - ' + token[token.length - 1];
                        mErhoehungen.push({ tbnr: tbnr, vonBisTbnr: vonBisTbnr });
                    }
                }
            }
        }
        setDataErhoehung(mErhoehungen);
    }, [dataf004]);

    useEffect(() => {
        if (!isAdd && row && row.f004) {
            get_erhoehung();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const handleClickSpeichern = useCallback(() => {
        if (!bezeichnung) {
            dispatch(setToast({
                message: "Bezeichnung darf nicht leer sein.",
                type: "error"
            }));
            return
        }
        let obj = isAdd ?
            {
                Operation: "InsertStrasse",
                LfdNrBezirk: (selectedItem.lfdnr_bezirk || selectedItem.lfdnr_bezirk === 0) ? selectedItem.lfdnr_bezirk.toString() : "",
                Bezeichnung: bezeichnung.trim(),
                //     Handyparken: handyParkenValue.toString(),
                Parkzone: parkZone.trim(),
                Hinweis: hinweis.trim(),
                Gemeindekennzeichen: gkz.trim(),
                SuedWestLatitude: swLat.trim(),
                SuedWestLongitude: swLong.trim(),
                NordOstLatitude: noLat.trim(),
                NordOstLongitude: noLong.trim()
            } :
            {
                Operation: "ChangeStrasse",
                LfdNr: row?.lfdnr,
                LfdNrBezirk: (selectedItem.lfdnr_bezirk || selectedItem.lfdnr_bezirk === 0) ? selectedItem.lfdnr_bezirk.toString() : "",
                Bezeichnung: bezeichnung.trim(),
                //  Handyparken: handyParkenValue.toString(),
                Parkzone: parkZone.trim(),
                Hinweis: hinweis.trim(),
                Gemeindekennzeichen: gkz.trim(),
                SuedWestLatitude: swLat.trim(),
                SuedWestLongitude: swLong.trim(),
                NordOstLatitude: noLat.trim(),
                NordOstLongitude: noLong.trim(),
                Tatbestandsfavoriten: dataf004,
            };


        GetData(obj, dispatch).then((res) => {
            if (!res) return
            callback(true)
        });


    }, [bezeichnung, isAdd, parkZone, hinweis, gkz, swLat, swLong, noLat, noLong, dataf004, dispatch, row, selectedItem, callback]);

    const get_von_bis_tbnr = useCallback((tbnr: string) => {
        for (let i of dataErhoehung) {
            if (i.tbnr === tbnr) return i.vonBisTbnr
        }
    }, [dataErhoehung]);

    const handleAddTatbestand = useCallback(async () => {
        if (addTatbestandNummer) {
            if ((";" + dataf004).includes(';' + addTatbestandNummer + '/')) {
                dispatch(setToast({
                    message: "Tatbestandsnummer ist bereits in den Favoriten enthalten.",
                    type: "error",
                }));
                return
            }

            const res = await GetDataAsync({
                Operation: "GetTatbestandMitTabelle",
                Tatbestandsnummer: addTatbestandNummer
            });

            if (res && res.erfolg) {
                let tabnr = res.tabellennummer || "";
                if (tabnr && dataf004.includes('/' + tabnr + ';')) {
                    dispatch(setToast({
                        message: "Tatbestandsnummer ist bereits durch einen Erhöhungstatbestand in den Favoriten enthalten.",
                        type: "error",
                    }));
                    return
                }
                else {
                    let mErhoehungen: { tbnr: string, vonBisTbnr: string }[] = [];
                    let erhoehungen = res.erhoehungen || "";
                    if (erhoehungen) {
                        let vonBisTbnr = '';
                        //von bis ermitteln
                        let token = res.erhoehungen.split(";").filter((i: any) => i);
                        if (token.length > 1) {
                            vonBisTbnr = token[0];
                            vonBisTbnr = vonBisTbnr + ' - ' + token[token.length - 1];
                            mErhoehungen.push({ tbnr: addTatbestandNummer, vonBisTbnr: vonBisTbnr });
                        }
                        setDataErhoehung(dataErhoehung.concat(mErhoehungen));
                    }
                    setDataf004(prev => prev + addTatbestandNummer + "/" + tabnr + ";");

                }
            }
            setAddTatbestandNummerPopup(false);
            setAddTatbestandNummer("");
        } else {
            dispatch(setToast({
                message: "Tatbestandsnummer mus vorhanden sein.",
                type: "error",
            }));
        }


    }, [addTatbestandNummer, dataErhoehung, dataf004, dispatch]);


    const handleHidePopup = useCallback(() => {
        callback()
    }, [callback]);

    const handleHidePopupLoeschen = useCallback(() => {
        callback(undefined, true)
    }, [callback]);


    const memo_options_loeschen = useMemo(() => {
        return {
            text: 'Löschen',
            type: "danger",
            onClick: handleHidePopupLoeschen,

        }
    }, [handleHidePopupLoeschen]);

    const memo_options_speichern = useMemo(() => {
        return {
            text: 'Speichern',
            onClick: handleClickSpeichern,

        }
    }, [handleClickSpeichern]);

    const memo_options_abbrechen = useMemo(() => {
        return {
            text: 'Abbrechen',
            onClick: handleHidePopup,

        }
    }, [handleHidePopup]);


    const handleChangSWLat = useCallback((e: any) => {

        let arr = [];
        for (let i of e)
            i === " " ? arr.push(0) : arr.push(i);
        while (arr.length < 9) arr.push(0)

        setSwLat(arr.join(""))
    }, []);



    const handleChangSWLong = useCallback((e: any) => {
        let arr = [];
        for (let i of e)
            i === " " ? arr.push(0) : arr.push(i);
        while (arr.length < 9) arr.push(0)

        setSwLong(arr.join(""))
    }, []);


    const handleChangNOLat = useCallback((e: any) => {

        let arr = [];
        for (let i of e)
            i === " " ? arr.push(0) : arr.push(i);
        while (arr.length < 9) arr.push(0)
        setNoLat(arr.join(""))
    }, []);



    const handleChangNOLong = useCallback((e: any) => {

        let arr = [];
        for (let i of e)
            i === " " ? arr.push(0) : arr.push(i);
        while (arr.length < 9) arr.push(0)
        setNoLong(arr.join(""))
    }, []);

    const handleAdd = useCallback(() => {
        setAddTatbestandNummerPopup(true);
    }, []);

    const handleHidePopupBestandnummer = useCallback(() => {
        setAddTatbestandNummerPopup(false);
    }, []);

    const memo_options_loeschen_tatbestand = useMemo(() => {
        return {
            text: 'Speichern',
            onClick: handleAddTatbestand
        }
    }, [handleAddTatbestand]);

    const memo_options_abbrechen_tatbestand = useMemo(() => {
        return {
            text: 'Abbrechen',
            onClick: handleHidePopupBestandnummer,
        }
    }, [handleHidePopupBestandnummer]);

    const RenderePopupTitle = useCallback(() => {
        return (<h2 className="title_popup">{isAdd ? "Neue Straße anlegen" : "Straße bearbeiten"}</h2>)
    }, [isAdd]);

    return (
        <>
            <Popup
                visible={true}
                onHiding={handleHidePopup}
                dragEnabled={true}
                hideOnOutsideClick={true}
                fullScreen={false}
                showCloseButton={true}
                height='auto'
                //width={"auto"} wenn diese da ist, wird scrolling nicht funktionieren 
                maxHeight="75vh"
                maxWidth={(screenSize.isMedium || screenSize.isSmall) ? "90vw" : "900px"}
            >
                <ToolbarItem
                    toolbar="top"
                    location={'before'}
                    render={RenderePopupTitle}
                />
                {!isAdd &&
                    <ToolbarItem
                        widget="dxButton"
                        toolbar="bottom"
                        location="before"
                        options={memo_options_loeschen}
                    />}
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={memo_options_speichern}
                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={memo_options_abbrechen}
                />
                <ScrollView className="h-100">
                    <div className="row p-2 p-md-3 g-0 h-100" >
                        <div className="col-12 col-md-6 p-1 p-md-2">
                            <TextBox
                                label="Bezeichnung"
                                labelMode="floating"
                                stylingMode="outlined"
                                onValueChange={setBezeichnung}
                                value={bezeichnung}
                                valueChangeEvent="input"
                                maxLength={50}
                                onContentReady={handleContentReadyTextBox}
                            />
                        </div>
                        {/**
                         * 
                         *   <div className="col-12 col-md-6 p-1 p-md-2">
                            <SelectBox
                                dataSource={data_source}
                                displayExpr="text"
                                valueExpr="value"
                                //acceptCustomValue={true}
                                value={handyParkenValue}
                                //onCustomItemCreating={customItemCreating}
                                onValueChange={setHandyParkenValue}
                                label='Handyparken'
                                labelMode='floating'
                                stylingMode='outlined'
                    onContentReady={handleContentReadySelectBox}
                            />
                        </div>
                         */}

                        <div className="col-12  col-md-6 p-1 p-md-2">
                            <TextBox
                                label="Parkzone"
                                labelMode="floating"
                                stylingMode="outlined"
                                onValueChange={setParkZone}
                                value={parkZone}
                                valueChangeEvent="input"
                                maxLength={40}
                                onContentReady={handleContentReadyTextBox}
                            />
                        </div>
                        <div className="col-12  col-md-6 p-1 p-md-2">
                            <TextArea
                                label="Hinweis"
                                labelMode="floating"
                                stylingMode="outlined"
                                onValueChange={setHinweis}
                                value={hinweis}
                                valueChangeEvent="input"
                                maxLength={254}
                                onContentReady={handleContentReadyTextBox}
                            />
                        </div>
                        <div className="col-12  col-md-6 p-1 p-md-2">
                            <TextBox
                                label="Gemeindekennziffern"
                                labelMode="floating"
                                stylingMode="outlined"
                                onValueChange={setGkz}
                                value={gkz}
                                valueChangeEvent="input"
                                maxLength={30}
                                onContentReady={handleContentReadyTextBox}
                            />
                        </div>
                        <div className="col-12 p-1 p-md-2 pt-3 pt-md-4">
                            <h3 className="mb-0">GPS</h3>
                        </div>
                        <div className="col-12 p-1 p-md-2">
                            Links unten (SW)
                        </div>
                        <div className="col-12  col-md-6 p-1 p-md-2">
                            <TextBox
                                label="Lat"
                                labelMode="floating"
                                stylingMode="outlined"
                                onValueChange={handleChangSWLat}
                                value={swLat}
                                valueChangeEvent="input"
                                mask={'00,0000000'}
                                onContentReady={handleContentReadyTextBox}
                            // maskRules={gpsRule}
                            />
                        </div>
                        <div className="col-12  col-md-6 p-1 p-md-2">
                            <TextBox
                                label="Long"
                                labelMode="floating"
                                stylingMode="outlined"
                                onValueChange={handleChangSWLong}
                                value={swLong}
                                valueChangeEvent="input"
                                mask={'00,0000000'}
                                onContentReady={handleContentReadyTextBox}
                            //  maskRules={gpsRule}
                            />
                        </div>
                        <div className="col-12 p-1 p-md-2">
                            Rechts oben (NO)
                        </div>
                        <div className="col-12  col-md-6 p-1 p-md-2">
                            <TextBox
                                label="Lat"
                                labelMode="floating"
                                stylingMode="outlined"
                                onValueChange={handleChangNOLat}
                                value={noLat}
                                valueChangeEvent="input"
                                mask={'00,0000000'}
                                onContentReady={handleContentReadyTextBox}
                            // maskRules={gpsRule}
                            />
                        </div>
                        <div className="col-12  col-md-6 p-1 p-md-2">
                            <TextBox
                                label="Long"
                                labelMode="floating"
                                stylingMode="outlined"
                                onValueChange={handleChangNOLong}
                                value={noLong}
                                valueChangeEvent="input"
                                mask={'00,0000000'}
                                onContentReady={handleContentReadyTextBox}
                            //  maskRules={gpsRule}
                            />
                        </div>
                        {!isAdd &&
                            <>
                                <div className="col-12s p-1 p-md-2 pt-3 pt-md-4 d-flex  align-items-baseline ">
                                    <h3 className="mb-0 mr-3 mr-md-5">Tatbestandsfavoriten</h3>
                                    <MDButton
                                        page="strasse_strassetab"
                                        icon={icon_plus}
                                        type="success"
                                        tooltip_text="Tatbestandsfavorit hinzufügen"
                                        onClick={handleAdd}
                                    />

                                </div>
                                <div className="col-12 p-1 p-md-2">
                                    <div className="row">
                                        {/**MD 21.07.2023 das war falsch  {(row && row.f004 && dataf004 && row.f004.split(";").filter(i => i).length > 0) &&*/}
                                        {(dataf004 && dataf004.split(";").filter(i => i).length > 0) &&
                                            dataf004.split(";").filter(i => i).map((item) => {
                                                console.log(item)
                                                let tbnr = item.replace(/\/.*/g, '');
                                                let vonBisTbnr = get_von_bis_tbnr(tbnr);
                                                let s: string = "";
                                                vonBisTbnr ? s = vonBisTbnr : s = tbnr;
                                                return (
                                                    <div key={s} className="col-12 col-md-6 col-xxl-4 p-2 text-center">
                                                        <h4 className="d-inline mr-5">{s}</h4>
                                                        <MDButton
                                                            page="strasse_strassetab"
                                                            icon={icon_delete}
                                                            type="danger"
                                                            onClick={() => {
                                                                //wird nur gerendert wenn item da ist und somit braucht kein useCallback
                                                                let f004_new = dataf004.replace(item + ';', '');
                                                                setDataf004(f004_new);
                                                                let new_dataErhoehung = [];
                                                                for (let i of dataErhoehung) {
                                                                    i.tbnr !== tbnr && new_dataErhoehung.push(i)
                                                                }
                                                                setDataErhoehung(new_dataErhoehung);
                                                            }}
                                                            tooltip_text="löschen"
                                                        />
                                                    </div>
                                                )
                                            })
                                        }

                                    </div>
                                </div>
                            </>
                        }



                    </div>
                </ScrollView>

            </Popup>

            <Popup
                visible={addTatbestandNummerPopup}
                onHiding={handleHidePopupBestandnummer}
                dragEnabled={true}
                hideOnOutsideClick={true}
                fullScreen={false}
                showCloseButton={true}
                height='auto'
                maxHeight="250px"
                maxWidth="450px"
            >

                <ToolbarItem
                    toolbar="top"
                    location={'before'}
                    render={RenderePopupTitleTatbestand}
                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={memo_options_loeschen_tatbestand}
                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={memo_options_abbrechen_tatbestand}
                />
                <ScrollView>
                    <div className="row p-2 p-md-3">
                        <div className="col-12 p-1 p-md-2">
                            <TextBox
                                label="Tatbestandsnummer"
                                labelMode="floating"
                                stylingMode="outlined"
                                onValueChange={setAddTatbestandNummer}
                                value={addTatbestandNummer}
                                valueChangeEvent="input"
                                onEnterKey={handleAddTatbestand}
                                maxLength={10}
                                onContentReady={handleContentReadyTextBox}
                            />
                        </div>
                    </div>
                </ScrollView>

            </Popup>
        </>
    )
}

export default StrassenTabVerwaltePopup;

const RenderePopupTitleTatbestand = () => {
    return (<h2 className="title_popup">Tatbestandsfavorit hinzufügen</h2>)
};


