import { DataGrid, ScrollView } from "devextreme-react";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import 'jspdf-autotable';
import TabPanel, { Item } from "devextreme-react/tab-panel";
import { Column, Item as ItemGridToolbar, Toolbar, Button as ButtonGrid } from "devextreme-react/data-grid";
import FahrerPopup from "./fahrer_popup";
import FahrzeugPopup from "./fahrzeug_popup";
import { IFahrerDaten, IFahrzeugDaten, IUnternehmenDaten, IVerwahflaecheDaten } from "./interfaces";
import { Seperatorliste } from "../../../global/seperator_liste";
import { GetData } from "../../../global/communication";
import { setConfirm, setToast, useAppDispatch, useAppSelector } from "../../../state";
import { abschlepp_unternehmen_columns_fahrer, format_abschleppunternehmen_fahrer_anrede } from "../../../sonstiges/columns";
import { exportGridAsPDF, handleContentReadyDataGrid } from "../../../global/hilfs_functions";
import { MDButton } from "../../../global/components";
import { icon_car, icon_delete, icon_pdf, icon_plus, icon_road, icon_user } from "../../../global/const";
import VerwahrFlaechePopup from "./verwahr_flaeche_popup";



const columns_fahrer = abschlepp_unternehmen_columns_fahrer;
const MasterDetailSection = ({ data }: any) => {
    const unternehmen: IUnternehmenDaten = data.data;
    const [popupDaten, setPopupDaten] = useState({ target: "", visible: false, anlegen: false, data: null });
    const [dataFahrer, setDataFahrer] = useState<IFahrerDaten[]>([]);
    const [dataFahrzeuge, setDataFahrezeuge] = useState<IFahrzeugDaten[]>([]);
    const [dataVerwahrflaeche, setDataVerwahrflaeche] = useState<IVerwahflaecheDaten[]>([]);
    const dataGridRefFahrer = useRef() as React.MutableRefObject<DataGrid<any, any>>;
    const dataGridRefFahrzeug = useRef() as React.MutableRefObject<DataGrid<any, any>>;
    const dataGridRefVerwahrflaeche = useRef() as React.MutableRefObject<DataGrid<any, any>>;
    const is_verwahr_flaeche_vorhanden = useAppSelector(state => state.store_einstellungen.konfiguration.t733_vorhanden);

    const dispatch = useAppDispatch();
    const handleClickRow = useCallback((data: any, handled: any, event: any, target: string) => {
        event.stopPropagation();
        //nur beim Klicken von dataGrid-Rows
        if (!handled) {
            setPopupDaten({
                target: target,
                anlegen: false,
                visible: true,
                data: data
            });
        }
    }, []);

    useEffect(() => {
        get_data();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const get_data = useCallback(() => {
        //Ich brauche hier kein Search Die Daten gehören hier genau zu einem Unternehmen
        GetData({
            Operation: "GetAbschleppunternehmenDaten",
            Lfdnr: unternehmen.lfdnr.toString(),
        }, dispatch).then((res) => {
            if (!res) {
                setDataFahrer([]);
                setDataFahrezeuge([]);
                return
            }
            setDataFahrer(res.fahrer);
            setDataFahrezeuge(res.fahrzeuge);
            //MD 20.07.2023 Neu Verwahrfläche aufnehmen
            if (is_verwahr_flaeche_vorhanden) {
                setDataVerwahrflaeche(res.verwahr_flaeche);
            }

        })
    }, [unternehmen, dispatch, is_verwahr_flaeche_vorhanden]);


    const callBackPopUp = useCallback((art: number, update?: boolean, loeschen?: boolean) => {
        /**
         * art:
         * 1: fahrer
         * 2: fahrzeug
         * 3: verwahrfläche
         */
        if (loeschen) {
            switch (art) {
                case 1:
                    delete_satz_fahrer(dispatch, popupDaten.data!, unternehmen.lfdnr, dataFahrer, setDataFahrer)
                    break;
                case 2:
                    delete_satz_fahrzeug(dispatch, popupDaten.data!, unternehmen.lfdnr, dataFahrzeuge, setDataFahrezeuge)
                    break;
                case 3:
                    delete_satz_verwahr_flaeche(dispatch, popupDaten.data!, unternehmen.lfdnr, dataVerwahrflaeche, setDataVerwahrflaeche)
                    break;
                default:
                    break;
            }
        } else {
            if (update) {
                get_data();
            }
        }

        setPopupDaten({
            target: "",
            visible: false,
            anlegen: false,
            data: null,
        })
    }, [popupDaten, get_data, dispatch, unternehmen.lfdnr, dataFahrer, dataFahrzeuge, dataVerwahrflaeche]);


    const handleClickFahrerDelete = useCallback((e: any) => {
        delete_satz_fahrer(dispatch, JSON.parse(e.element.getAttribute("data")), unternehmen.lfdnr, dataFahrer, setDataFahrer);
    }, [dispatch, unternehmen.lfdnr, dataFahrer])
    const handleClickFahrzeugDelete = useCallback((e: any) => {
        delete_satz_fahrzeug(dispatch, JSON.parse(e.element.getAttribute("data")), unternehmen.lfdnr, dataFahrzeuge, setDataFahrezeuge);
    }, [dispatch, unternehmen.lfdnr, dataFahrzeuge])
    const handleClickVerwahrFlaecheDelete = useCallback((e: any) => {
        delete_satz_verwahr_flaeche(dispatch, JSON.parse(e.element.getAttribute("data")), unternehmen.lfdnr, dataVerwahrflaeche, setDataVerwahrflaeche);
    }, [dispatch, unternehmen.lfdnr, dataVerwahrflaeche])


    const handleRowClickFahrer = useCallback((e: any) => {
        if (e.event.originalEvent.currentTarget.role !== "button") {
            handleClickRow(e.data, e.handled, e.event, "fahrer")
        }
    }, [handleClickRow]);
    const handleClickPDFFahrer = useCallback(() => {
        exportGridAsPDF(dataGridRefFahrer, "Fahrer.pdf", "", "landscape", "striped", (cellData: any) => {
            if (cellData.gridCell.column.dataField === 'f002' && cellData.gridCell.rowType === 'data') {
                cellData.pdfCell.text = format_abschleppunternehmen_fahrer_anrede(cellData.pdfCell.text.toString());
            }
        })
    }, []);
    const handleClickPlusFahrer = useCallback(() => {
        setPopupDaten({ ...popupDaten, target: "fahrer", anlegen: true, visible: true });
    }, [popupDaten]);

    const handleCallBackPopupFahrer = useCallback((update?: boolean, loeschen?: boolean) => {
        callBackPopUp(1, update, loeschen)
    }, [callBackPopUp]);

    const handleCallBackPopupFahrzeug = useCallback((update?: boolean, loeschen?: boolean) => {
        callBackPopUp(2, update, loeschen)
    }, [callBackPopUp]);
    const handleCallBackPopupVerwahrFlaeche = useCallback((update?: boolean, loeschen?: boolean) => {
        callBackPopUp(3, update, loeschen)
    }, [callBackPopUp]);

    const handleRowClickFahrzeug = useCallback((e: any) => {
        if (e.event.originalEvent.currentTarget.role !== "button") {
            handleClickRow(e.data, e.handled, e.event, "fahrzeug")
        }
    }, [handleClickRow]);

    const handleRowClickVerwahrFlaeche = useCallback((e: any) => {
        if (e.event.originalEvent.currentTarget.role !== "button") {
            handleClickRow(e.data, e.handled, e.event, "verwahr_flaeche")
        }
    }, [handleClickRow]);

    const handleClickPDFFahrzeug = useCallback(() => {
        exportGridAsPDF(dataGridRefFahrzeug, "Fahrzeuge.pdf", "", "landscape", "striped")
    }, []);
    const handleClickPlusFahrzeug = useCallback(() => {
        setPopupDaten({ ...popupDaten, target: "fahrzeug", anlegen: true, visible: true })
    }, [popupDaten]);

    const handleClickPDFVerwahrflaeche = useCallback(() => {
        exportGridAsPDF(dataGridRefVerwahrflaeche, "Verwahrfläche.pdf", "", "landscape", "striped")
    }, []);
    const handleClickPlusVerwahrFlaeche = useCallback(() => {
        setPopupDaten({ ...popupDaten, target: "verwahr_flaeche", anlegen: true, visible: true })
    }, [popupDaten]);

    const RenderButtonFahrerDelete = useCallback((e: any) => {
        return (<MDButton
            icon={icon_delete}
            type="danger"
            tooltip_text="Fahrer löschen"
            onClick={handleClickFahrerDelete}
            data={e.data}
            page="abschlepp_unternehmen_fahrer" />)
    }, [handleClickFahrerDelete]);

    const RenderButtonFahrzeugDelete = useCallback((e: any) => {
        return (<MDButton
            icon={icon_delete}
            type="danger"
            tooltip_text="Fahrzeug löschen"
            onClick={handleClickFahrzeugDelete}
            data={e.data}
            page="abschlepp_unternehmen_fahrzeug" />)
    }, [handleClickFahrzeugDelete]);

    const RenderButtonVerwahrFlaecheDelete = useCallback((e: any) => {
        return (<MDButton
            icon={icon_delete}
            type="danger"
            tooltip_text="Verwahrfläche löschen"
            onClick={handleClickVerwahrFlaecheDelete}
            data={e.data}
            page="abschlepp_unternehmen_verwahr_flaeche" />)
    }, [handleClickVerwahrFlaecheDelete]);


    return (
        <div className="p-2 pl-4">
            <TabPanel animationEnabled={true}>
                <Item title="Fahrer" icon={icon_user}>
                    <ScrollView className="mt-2 mt-md-3">
                        <DataGrid
                            dataSource={dataFahrer}
                            columnAutoWidth={false}
                            rowAlternationEnabled={true}
                            wordWrapEnabled={true}
                            keyExpr="f001"
                            hoverStateEnabled={true}
                            className="cursor_pointer"
                            noDataText="Keine Fahrer"
                            ref={dataGridRefFahrer}
                            renderAsync={true}
                            onRowClick={handleRowClickFahrer}
                            onContentReady={handleContentReadyDataGrid}
                        >
                            {columns_fahrer.map((column) => {
                                return (
                                    <Column
                                        dataField={column.dataField}
                                        caption={column.caption}
                                        dataType={column.dataType}
                                        alignment={column.alignment}
                                        cellRender={column.cellRender}
                                        key={column.dataField}
                                        minWidth={column.minWidth}
                                        visible={column.visible}
                                    />
                                )
                            })}
                            <Column type="buttons">
                                <ButtonGrid
                                    cssClass={'d-inline-block'}
                                    render={RenderButtonFahrerDelete} />
                            </Column>
                            <Toolbar>
                                <ItemGridToolbar location="after" locateInMenu="auto">
                                    <MDButton
                                        icon={icon_pdf}
                                        onClick={handleClickPDFFahrer}
                                        page="abschlepp_unternehmen_fahrer"
                                        tooltip_text="Tabelle als PDF herunterladen" />
                                </ItemGridToolbar>
                                <ItemGridToolbar location="after" locateInMenu="auto">
                                    <MDButton
                                        icon={icon_plus}
                                        onClick={handleClickPlusFahrer}
                                        page="abschlepp_unternehmen_fahrer"
                                        tooltip_text="Einen neuen Fahrer anlegen"
                                        type="success" />
                                </ItemGridToolbar>
                            </Toolbar>
                        </DataGrid>
                    </ScrollView>
                    {(popupDaten.visible && popupDaten.target === "fahrer") && <FahrerPopup lfdnr_firma={unternehmen.lfdnr} anlegen={popupDaten.anlegen} data={popupDaten.data! as IFahrerDaten} callBack={handleCallBackPopupFahrer} />
                    }
                </Item>
                <Item title="Fahrzeug" icon={icon_car}>
                    <ScrollView className="mt-2 mt-md-3">
                        <DataGrid
                            dataSource={dataFahrzeuge}
                            columnAutoWidth={false}
                            rowAlternationEnabled={true}
                            wordWrapEnabled={true}
                            keyExpr="f001"
                            noDataText="Keine Fahrzeuge"
                            renderAsync={true}
                            ref={dataGridRefFahrzeug}
                            onRowClick={handleRowClickFahrzeug}
                            hoverStateEnabled={true}
                            className="cursor_pointer"
                            onContentReady={handleContentReadyDataGrid}
                        >
                            <Column dataField="f001" caption="KFZ-Kennzeichen" />
                            <Column type="buttons">
                                <ButtonGrid
                                    cssClass={'d-inline-block'}
                                    render={RenderButtonFahrzeugDelete} />
                            </Column>
                            <Toolbar>
                                <ItemGridToolbar location="after" locateInMenu="auto">
                                    <MDButton
                                        icon={icon_pdf}
                                        onClick={handleClickPDFFahrzeug}
                                        tooltip_text="Tabelle als PDF herunterladenn"
                                        page="abschlepp_unternehmen_fahrzeug"
                                    />
                                </ItemGridToolbar>
                                <ItemGridToolbar location="after" locateInMenu="auto">
                                    <MDButton icon={icon_plus}
                                        type="success"
                                        page="abschlepp_unternehmen_fahrzeug"
                                        tooltip_text="Ein neues Fahrzeug anlegen"
                                        onClick={handleClickPlusFahrzeug}
                                    />
                                </ItemGridToolbar>
                            </Toolbar>
                        </DataGrid>
                    </ScrollView>
                    {(popupDaten.visible && popupDaten.target === "fahrzeug") && <FahrzeugPopup lfdnr={unternehmen.lfdnr} anlegen={popupDaten.anlegen} data={popupDaten.data! as IFahrzeugDaten} callBack={handleCallBackPopupFahrzeug} />}
                </Item>
                {is_verwahr_flaeche_vorhanden &&
                    <Item title="Verwahrfläche" icon={icon_road}>
                        <ScrollView className="mt-2 mt-md-3">
                            <DataGrid
                                dataSource={dataVerwahrflaeche}
                                columnAutoWidth={false}
                                rowAlternationEnabled={true}
                                wordWrapEnabled={true}
                                keyExpr="f001"
                                noDataText="Keine Verwahrfläche"
                                ref={dataGridRefVerwahrflaeche}
                                renderAsync={true}
                                onRowClick={handleRowClickVerwahrFlaeche}
                                hoverStateEnabled={true}
                                className="cursor_pointer"
                                onContentReady={handleContentReadyDataGrid}
                            >
                                <Column dataField="f001" caption="Verwahrfläche" />
                                <Column type="buttons">
                                    <ButtonGrid
                                        cssClass={'d-inline-block'}
                                        render={RenderButtonVerwahrFlaecheDelete} />
                                </Column>
                                <Toolbar>
                                    <ItemGridToolbar location="after" locateInMenu="auto">
                                        <MDButton
                                            icon={icon_pdf}
                                            onClick={handleClickPDFVerwahrflaeche}
                                            tooltip_text="Tabelle als PDF herunterladenn"
                                            page="abschlepp_unternehmen_verwahrflaeche"
                                        />
                                    </ItemGridToolbar>
                                    <ItemGridToolbar location="after" locateInMenu="auto">
                                        <MDButton icon={icon_plus}
                                            type="success"
                                            page="abschlepp_unternehmen_verwahr_flaeche"
                                            tooltip_text="Eine neue Verwahrfläche anlegen"
                                            onClick={handleClickPlusVerwahrFlaeche}
                                        />
                                    </ItemGridToolbar>
                                </Toolbar>
                            </DataGrid>
                        </ScrollView>
                        {(popupDaten.visible && popupDaten.target === "verwahr_flaeche") && <VerwahrFlaechePopup lfdnr={unternehmen.lfdnr} anlegen={popupDaten.anlegen} data={popupDaten.data! as IVerwahflaecheDaten} callBack={handleCallBackPopupVerwahrFlaeche} />}
                    </Item>}
            </TabPanel>
        </div>
    )
}

export default memo(MasterDetailSection);

const delete_satz_fahrzeug = (dispatch: any, data: any, unternehmen_lfdnr: number, dataFahrzeuge: any, setDataFahrezeuge: any) => {
    dispatch(setConfirm({
        body:
            <div className="alert alert-danger">
                <p>Wollen sie das Fahrzeug mit dem Kassenzeichen <b>{data.f001}</b> wirklich löschen?</p>
            </div>,
        on_ok: () => {
            let daten = new Seperatorliste("%00", "%01");
            daten.setValue("Firmennummer", unternehmen_lfdnr.toString());
            daten.setValue("KfzKennzeichen", data.f001);

            GetData({
                Operation: "DeleteAbschleppfahrzeug",
                Daten: daten.getSeperatorliste()
            }, dispatch).then((res) => {
                if (!res) return
                let new_data = [...dataFahrzeuge];
                new_data.splice(new_data.indexOf(data), 1);
                setDataFahrezeuge(new_data);
                dispatch(setToast({
                    message: "Das Fahrzeug wurde erfolgreich gelöscht.",
                    type: "success",
                    position: "bottom center"
                }));
            })
        },
        title: "Fahrzeug löschen",
    }
    ));
}
const delete_satz_verwahr_flaeche = (dispatch: any, data: any, unternehmen_lfdnr: number, dataVerwahrflaeche: any, setDataVerwahrflaeche: any) => {
    dispatch(setConfirm({
        body:
            <div className="alert alert-danger">
                <p>Wollen sie die Verwahrfläche <b>{data.f001}</b> wirklich löschen?</p>
            </div>,
        on_ok: () => {
            let daten = new Seperatorliste("%00", "%01");
            daten.setValue("Firmennummer", unternehmen_lfdnr.toString());
            daten.setValue("VerwahrFlaeche", data.f001);

            GetData({
                Operation: "DeleteAbschleppVerwahrFlaeche",
                Daten: daten.getSeperatorliste()
            }, dispatch).then((res) => {
                if (!res) return
                let new_data = [...dataVerwahrflaeche];
                new_data.splice(new_data.indexOf(data), 1);
                setDataVerwahrflaeche(new_data);
                dispatch(setToast({
                    message: "Die Verwahrfläche wurde erfolgreich gelöscht.",
                    type: "success",
                    position: "bottom center"
                }));
            })
        },
        title: "Verwahrfläche löschen",
    }
    ));
}

const delete_satz_fahrer = (dispatch: any, data: any, unternehmen_lfdnr: number, dataFahrer: any, setDataFahrer: any) => {
    dispatch(setConfirm({
        body:
            <div className="alert alert-danger">
                <p>Wollen sie den Fahrer mit dem Namen <b>{data.f003 + ' ' + data.f004}</b> wirklich löschen?</p>
            </div>,
        on_ok: () => {
            let daten = new Seperatorliste("%00", "%01");
            daten.setValue("Firmennummer", unternehmen_lfdnr.toString());
            daten.setValue("Fahrernummer", data.f001.toString());
            GetData({
                Operation: "DeleteAbschleppfahrer",
                Daten: daten.getSeperatorliste()
            }, dispatch).then((res) => {
                if (!res) return
                let new_data = [...dataFahrer];
                new_data.splice(new_data.indexOf(data), 1);
                setDataFahrer(new_data);
                dispatch(setToast({
                    message: "Der Fahrer wurde erfolgreich gelöscht.",
                    type: "success",
                    position: "bottom center"
                }));
            })
        },
        title: "Fahrer löschen",
    }
    ));
}