import {useCallback, useState} from 'react';
import './DPV.scss';
import Detail from './Details/Details'
import DoppelverwarnungenGrid from './grid';

const Doppelverwarnungen=():JSX.Element => {
  const [exportNumber,setexportNumber] = useState<number>(0);

  const handleHideDialog = useCallback(() => {
    setexportNumber(0)
  }, []);

  return (<>
    {(exportNumber > 0) && <Detail onHideDialog={handleHideDialog} exportNumber={exportNumber} />}
    <DoppelverwarnungenGrid setexportNumber={setexportNumber} />
    </>

    )
  }

export default Doppelverwarnungen;





