import { dbTimeToString } from "../../global/hilfs_functions";
import { setTabellenSpalten } from "../../state";
import { IAuftragAnschriftDaten, IFaelleAnschriftDaten, IFallData, IFallDataZusaetzlich, IFremdZeuge, ITatBestand } from "./interfaces";


const get_angezeigte_tatbestand_nummer_weiter = (tatbestaende: ITatBestand[]) => {
    let result = "";
    if (tatbestaende.length > 1)
        result += ', ' + tatbestaende[1].nummer;

    if (tatbestaende.length > 2)
        result += ', ' + tatbestaende[2].nummer;

    if (tatbestaende.length > 3)
        result += ', ' + tatbestaende[3].nummer;

    if (tatbestaende.length > 4)
        result += ', ' + tatbestaende[4].nummer;

    return result;
}

export const get_angezeigte_tatbestand_nummer = (tatbestaende: ITatBestand[]) => {
    let angezeigteTatbestandsnummer = "-";
    if (tatbestaende.length > 0)
        angezeigteTatbestandsnummer = tatbestaende[0].nummer;

    return angezeigteTatbestandsnummer;
}

export const get_fall_data_zusaetzlich = (item: IFallData): IFallDataZusaetzlich => {
    const zahlbetrag_soll: number = getGesamtbetragAusF010(item.f010);
    //  const handyparkenRueckseite: string = getHandyparkenRueckseite(item.f010);

    let bezahlt = false;

    let zahlbetrag_ist: number = parseFloat(item.f131.replace(",", "."));

    //MD 19.09.2023 Unterscheiden zwischen f010 Bar bezahlt und f131 Online Zahlung
    // if (!zahlbetrag_ist && item.f010.Bar === "Ja") // f131 geht vor
    //     zahlbetrag_ist = zahlbetrag_soll;

    if (zahlbetrag_ist && zahlbetrag_ist > 0)
        bezahlt = true;

    //let quittungsnummer = item.f132;
    //if (!quittungsnummer) // f132 geht vor
    //    quittungsnummer = item.f010.QNr;
    //if (!bezahlt) quittungsnummer = "";

    let tatbestaende: ITatBestand[] = sortTatbestaende(item);

    return ({
        gesamt_betrag_aus_f010: zahlbetrag_soll,
        //  quittungsnummer: quittungsnummer,quittungsnummer ist hier für bar f010
        quittungsnummer: item.f010.QNr,
        bezahlt: bezahlt,
        bar_bezahlt_f010: item.f010.Bar === "Ja",
        exportiert: item.f006 === 1,
        // exportDatum: item.f110,
        exportUhrZeit: dbTimeToString(item.f111),
        uhrzeit: dbTimeToString(item.f003), // Formatiert uhrzeit
        //    handyparkenRueckseite: handyparkenRueckseite,
        fall_art: get_fall_art(item),
        kfz_kennziehcen: get_kfz_kennzeichen(item),
        record_typ: get_record_typ(item.f005),
        zeit_von: item.f010.Kontrolle || item.f010.Tatzeit || "", //11:05 (28.06.2021) Sollen wir das ausm Server?? muss man noch drüber nachdenken // Wegen Datum
        zeit_von_bis: get_zeit_von_bis(item),
        tatzeit_back: get_tatzeit_back(item.f010),
        fahrzeug_back: getFahrzeugRueckseite(item.f010),
        tatort: get_adresse(item.f010), // Kein Ort
        tatbestaende: tatbestaende,
        angezeigte_tatbestand_nummer: get_angezeigte_tatbestand_nummer(tatbestaende),
        angezeigte_tatbestand_nummer_weiter: get_angezeigte_tatbestand_nummer_weiter(tatbestaende),
        loesch_text: get_loesch_text(item),
        loesch_text_back: get_loesch_text_back(item),
        status_text: get_status_text(item.f006),
        erschwerte_bedingungen: get_erschwerte_bedingungen(item.f010.Bedingungen),
        //  abs_firmen_daten: get_abs_firmen_daten(), //{   //Siehe methode GetAbschleppunternehmendetails wird gleich in f010 übergeben
        //abs_fahrer_daten: get_abs_fahrer_daten(),   //Wenn in f010 fahrer = neu dann brauchen wir dieses Objekt nicht wird gleich in f010 übergeben
    });
}

function get_erschwerte_bedingungen(kuerzel: any) {
    if (!kuerzel) return "";

    let str = "";

    if (kuerzel.indexOf("V") >= 0) {
        str += 'Verschmutzung';
    }
    if (kuerzel.indexOf("R") >= 0) {
        if (str !== "")
            str += ', ';

        str += 'Regen/Nässe';
    }
    if (kuerzel.indexOf("D") >= 0) {
        if (str !== "")
            str += ', ';

        str += 'Dunkelheit';
    }
    if (kuerzel.indexOf("S") >= 0) {
        if (str !== "")
            str += ', ';

        str += 'Schnee/Eis';
    }
    return str;
}



const get_status_text = (status: number): string => {
    if (status === 0) {
        return "Noch zu verarbeiten";
    } else if (status === 1) {
        return "Verarbeitet";
    } else if (status === 2) {
        return "Storniert durch Winowig Online SB";
    } else if (status === 3) {
        return "Storniert durch MDE SB";
    } else if (status === 4) {
        return "Storniert in Vorverarbeitung";
    } else if (status === 5) {
        return "Storniert da bereits exportiert";
    } else if (status === 6) {
        return "Storniert da bereits gelöscht";
    } else if (status === 7) {
        return "Storniert da QRCode bereits verwendet wurde";
    } else if (status === 9) {
        return "Storniert aufgrund doppeltem Aktenzeichen";
    } else {
        return "";
    }
}

function getFahrzeugRueckseite(f010: any): string {
    let fahrzeugRueckseite = "";
    let kfzTyp = f010.KFZTyp;
    let kfzGewicht = f010.Gewicht;
    let fabrikat = f010.Fabrikat;
    let farbe = f010.Farbe;
    let ventil = f010.ventilstellg;

    if (kfzTyp) {
        if (fahrzeugRueckseite)
            fahrzeugRueckseite += ", ";
        fahrzeugRueckseite += kfzTyp;
    }

    if (kfzGewicht) {
        if (fahrzeugRueckseite)
            fahrzeugRueckseite += " ";
        fahrzeugRueckseite += kfzGewicht;
    }

    if (fabrikat) {
        if (fahrzeugRueckseite)
            fahrzeugRueckseite += ", ";
        fahrzeugRueckseite += fabrikat;
    }

    if (farbe) {
        if (fahrzeugRueckseite)
            fahrzeugRueckseite += ", ";
        fahrzeugRueckseite += farbe;
    }

    if (ventil) {
        if (fahrzeugRueckseite)
            fahrzeugRueckseite += ", ";
        fahrzeugRueckseite += ventil;
    }

    return fahrzeugRueckseite;
}


const get_tatzeit_back = (f010: any): string => {
    let zeitVon = f010.Kontrolle;
    let zeitBis = '';
    if (zeitVon)
        zeitBis = f010.Tatzeit || "";
    else
        zeitVon = f010.Tatzeit || "";

    let tatzeitRueckseite = zeitVon.substr(0, 5);
    if (zeitBis)
        tatzeitRueckseite += " - " + zeitBis.substr(0, 5);

    if (zeitBis === "" || (zeitVon.substr(7, 10) === zeitBis.substr(7, 10))) {
        tatzeitRueckseite += " (" + zeitVon.substr(7, 10) + ")";
    } else {
        tatzeitRueckseite += " (" + zeitVon.substr(7, 2) + ".-" + zeitBis.substr(7, 10) + ")";
    }
    return tatzeitRueckseite;
}
const get_loesch_text_back = (item: any): string => {
    if (item.f006 >= 2) {
        //MD 19.07.2023 Neu 
        //wenn bei Opcode 2 wird kein Löschsatz generiert. Da wird der Fall bereites beim Versenden aus Mobil gelöscht.
        const loesch_sachbearbeiter_name = (item.f004 === 2) ? item.sachbearbeitername : item.f010.LoeschSachbearbeitername;
        const loesch_datum = (item.f004 === 2) ? item.f002 : item.f010.LoeschsatzDatum;
        const loesch_uhrzeit = (item.f004 === 2) ? dbTimeToString(item.f003) : item.f010.LoeschsatzUhrzeit;

        let text_fall_geloescht = "Fall gelöscht ";
        if (item.f006 <= 4) {
            if (loesch_sachbearbeiter_name) text_fall_geloescht += "von " + loesch_sachbearbeiter_name + " ";
            if (loesch_datum) text_fall_geloescht += "am " + loesch_datum + " ";
            if (loesch_uhrzeit) text_fall_geloescht += "um " + loesch_uhrzeit + " ";
        } else {
            text_fall_geloescht += "durch das System ";
            text_fall_geloescht += 'am ' + item.f002 + ' um ' + dbTimeToString(item.f003);
        }

        // if(loesch_grund) text_fall_geloescht += "<br><br>Grund:  " +loesch_grund;
        //Ohne Grund
        return text_fall_geloescht
    } else return ''
        ;
}

const get_loesch_text = (item: any): string => {
    if (item.f006 >= 2) {

        //MD 19.07.2023 Neu 
        //wenn bei Opcode 2 wird kein Löschsatz generiert. Da wird der Fall bereites beim Versenden aus Mobil gelöscht.
        const loesch_sachbearbeiter_name = (item.f004 === 2) ? item.sachbearbeitername : item.f010.LoeschSachbearbeitername;
        const loesch_datum = (item.f004 === 2) ? item.f002 : item.f010.LoeschsatzDatum;
        const loesch_uhrzeit = (item.f004 === 2) ? dbTimeToString(item.f003) : item.f010.LoeschsatzUhrzeit;

        let text_fall_geloescht = "Fall gelöscht ";
        if (loesch_sachbearbeiter_name) text_fall_geloescht += "von " + loesch_sachbearbeiter_name + " ";
        if (loesch_datum) text_fall_geloescht += "am " + loesch_datum + " ";
        if (loesch_uhrzeit) text_fall_geloescht += "um " + loesch_uhrzeit + " ";
        // if(loesch_grund) text_fall_geloescht += "<br><br>Grund:  " +loesch_grund;
        //Ohne Grund
        return text_fall_geloescht
    } else return ''
        ;
}
export function sortTatbestaende(item: IFallData): ITatBestand[] {
    let tatbestaende: ITatBestand[] = [];

    function compareByTatbestandsbetrag(a: ITatBestand, b: ITatBestand) {
        if (a.betrag > b.betrag)
            return -1;
        else if (a.betrag < b.betrag)
            return 1;
        else
            return 0;
    }

    let tatbestand1 = item.f010.ziptbnr;
    let tatbestandAOWI1 = item.f010.AOWIziptbnr;
    let betrag1 = item.f010.Betrag1;
    let betrag1Vorsatz = item.f010.mBetrag1;
    let betrag1AOWI = item.f010.AOWIziptbnrBetrag;
    // 6.6.2018: Bugfix für Fehler in der iOS Version. Manchmal kein Betrag vorhanden
    // MD lassen wir mal drin
    if (!betrag1AOWI)
        betrag1AOWI = item.f010.AOWITatbestand1Ursprungsbetrag;



    let tatbestand2 = item.f010.ziptbnr2;
    let tatbestandAOWI2 = item.f010.AOWIziptbnr2;
    let betrag2 = item.f010.Betrag2;
    let betrag2Vorsatz = item.f010.mBetrag2;
    let betrag2AOWI = item.f010.AOWIziptbnr2Betrag;
    if (!betrag2AOWI)
        betrag2AOWI = item.f010.AOWITatbestand2Ursprungsbetrag;

    let tatbestand3 = item.f010.ziptbnr3;
    let tatbestandAOWI3 = item.f010.AOWIziptbnr3;
    let betrag3 = item.f010.Betrag3;
    let betrag3Vorsatz = item.f010.mBetrag3;
    let betrag3AOWI = item.f010.AOWIziptbnr3Betrag;
    if (!betrag3AOWI)
        betrag3AOWI = item.f010.AOWITatbestand3Ursprungsbetrag;

    let tatbestand4 = item.f010.ziptbnr4;
    let tatbestandAOWI4 = item.f010.AOWIziptbnr4;
    let betrag4 = item.f010.Betrag4;
    let betrag4Vorsatz = item.f010.mBetrag4;
    let betrag4AOWI = item.f010.AOWIziptbnr4Betrag;
    if (!betrag4AOWI)
        betrag4AOWI = item.f010.AOWITatbestand4Ursprungsbetrag;

    let tatbestand5 = item.f010.ziptbnr5;
    let tatbestandAOWI5 = item.f010.AOWIziptbnr5;
    let betrag5 = item.f010.Betrag5;
    let betrag5Vorsatz = item.f010.mBetrag5;
    let betrag5AOWI = item.f010.AOWIziptbnr5Betrag;
    if (!betrag5AOWI)
        betrag5AOWI = item.f010.AOWITatbestand5Ursprungsbetrag;

    let tatbestand1Nummer = '';
    let tatbestand1Betrag = 0;
    //let tatbestand1Text = '';  //MD tatbestandtext 17.08.2022 Neu vorher wurde immer  tatbestandstext ausgelesen auch für AOWI.  //Das ist doch richtig
    if (betrag1) {
        tatbestand1Nummer = tatbestand1;
        tatbestand1Betrag = parseFloat(betrag1.replace(",", "."));

        if (betrag1Vorsatz) {
            tatbestand1Betrag = parseFloat(betrag1Vorsatz.replace(",", "."));
        }
        //  tatbestand1Text = item.f010.tatbestandstext;
    } else if (betrag1AOWI) {
        tatbestand1Nummer = tatbestandAOWI1;
        tatbestand1Betrag = parseFloat(betrag1AOWI.replace(",", "."));
        // tatbestand1Text = item.f010.AOWItbtext;
    }

    if (tatbestand1Nummer) {
        tatbestaende.push({
            index: 1,
            nummer: tatbestand1Nummer,
            betrag: tatbestand1Betrag,
            paragraphenzeile: item.f010.paragraphenzeile1,
            punkte: item.f010.punkte1, // das ist number
            tatbestandstext: item.f010.tatbestandstext1,
            //    tatbestandstext: tatbestand1Text
        }
        );
    }

    let tatbestand2Nummer = '';
    let tatbestand2Betrag = 0;
    //   let tatbestand2Text = '';
    if (betrag2) {
        tatbestand2Nummer = tatbestand2;
        tatbestand2Betrag = parseFloat(betrag2.replace(",", "."));

        if (betrag2Vorsatz) {
            tatbestand2Betrag = parseFloat(betrag2Vorsatz.replace(",", "."));
        }
        //   tatbestand2Text = item.f010.tatbestandstext2;
    } else if (betrag2AOWI) {
        tatbestand2Nummer = tatbestandAOWI2;
        tatbestand2Betrag = parseFloat(betrag2AOWI.replace(",", "."));
        //  tatbestand2Text = item.f010.AOWItbtext2;
    }

    if (tatbestand2Nummer) {
        tatbestaende.push({
            index: 2, nummer: tatbestand2Nummer, betrag: tatbestand2Betrag,
            paragraphenzeile: item.f010.paragraphenzeile2,
            punkte: item.f010.punkte2, // das ist number
            //  tatbestandstext: tatbestand2Text
            tatbestandstext: item.f010.tatbestandstext2,
        });
    }

    let tatbestand3Nummer = '';
    let tatbestand3Betrag = 0;
    //  let tatbestand3Text = '';
    if (betrag3) {
        tatbestand3Nummer = tatbestand3;
        tatbestand3Betrag = parseFloat(betrag3.replace(",", "."));

        if (betrag3Vorsatz) {
            tatbestand3Betrag = parseFloat(betrag3Vorsatz.replace(",", "."));
        }
        //    tatbestand3Text = item.f010.tatbestandstext3;
    } else if (betrag3AOWI) {
        tatbestand3Nummer = tatbestandAOWI3;
        tatbestand3Betrag = parseFloat(betrag3AOWI.replace(",", "."));
        //  tatbestand3Text = item.f010.AOWItbtext3;
    }

    if (tatbestand3Nummer) {
        tatbestaende.push({
            index: 3, nummer: tatbestand3Nummer, betrag: tatbestand3Betrag,
            paragraphenzeile: item.f010.paragraphenzeile3,
            punkte: item.f010.punkte3, // das ist number
            //    tatbestandstext: tatbestand3Text
            tatbestandstext: item.f010.tatbestandstext3,
        });
    }

    // Tatbestaend 4		
    let tatbestand4Nummer = '';
    let tatbestand4Betrag = 0;
    //let tatbestand4Text = '';
    if (betrag4) {
        tatbestand4Nummer = tatbestand4;
        tatbestand4Betrag = parseFloat(betrag4.replace(",", "."));

        if (betrag4Vorsatz) {
            tatbestand4Betrag = parseFloat(betrag4Vorsatz.replace(",", "."));
        }
        //  tatbestand4Text = item.f010.tatbestandstext4;
    } else if (betrag4AOWI) {
        tatbestand4Nummer = tatbestandAOWI4;
        tatbestand4Betrag = parseFloat(betrag4AOWI.replace(",", "."));
        //tatbestand4Text = item.f010.AOWItbtext4;
    }

    if (tatbestand4Nummer) {
        tatbestaende.push({
            index: 4, nummer: tatbestand4Nummer, betrag: tatbestand4Betrag,
            paragraphenzeile: item.f010.paragraphenzeile4,
            punkte: item.f010.punkte4, // das ist number
            //  tatbestandstext: tatbestand4Text
            tatbestandstext: item.f010.tatbestandstext4,
        });
    }

    // Tatbestand 5
    let tatbestand5Nummer = '';
    let tatbestand5Betrag = 0;
    //  let tatbestand5Text = '';
    if (betrag5) {
        tatbestand5Nummer = tatbestand5;
        tatbestand5Betrag = parseFloat(betrag5.replace(",", "."));

        if (betrag5Vorsatz) {
            tatbestand5Betrag = parseFloat(betrag5Vorsatz.replace(",", "."));
        }
        //    tatbestand5Text = item.f010.tatbestandstext5;
    } else if (betrag5AOWI) {
        tatbestand5Nummer = tatbestandAOWI5;
        tatbestand5Betrag = parseFloat(betrag5AOWI.replace(",", "."));
        //  tatbestand5Text = item.f010.AOWItbtext5;
    }

    if (tatbestand5Nummer) {
        tatbestaende.push({
            index: 5, nummer: tatbestand5Nummer, betrag: tatbestand5Betrag,
            paragraphenzeile: item.f010.paragraphenzeile5,
            punkte: item.f010.punkte5, // das ist number
            //    tatbestandstext: tatbestand5Text
            tatbestandstext: item.f010.tatbestandstext5,
        });
    }


    tatbestaende.sort(compareByTatbestandsbetrag);

    return tatbestaende;
}


export const get_bild_nummer_gruppen = (nummer?: number): string => {
    let bildnummer = "000";

    if (!nummer) {
        bildnummer = "000";
    } else if (nummer < 10) {
        bildnummer = "00" + nummer.toString();
    } else if (nummer < 100) {
        bildnummer = "0" + nummer.toString();
    } else if (nummer < 1000) {
        bildnummer = nummer.toString();
    }

    return bildnummer;
}
const get_adresse = (f010: any): string => {
    let result = "";
    if (f010.strasse) result += ' ' + f010.strasse;
    if (f010.Hnrvorsatz) result += ' ' + f010.Hnrvorsatz;
    if (f010.ziphausnr) result += ' ' + f010.ziphausnr;
    if (f010.strassendetail) result += ' ' + f010.strassendetail;
    if (f010.GKZ) result += ' (GKZ: ' + f010.GKZ + ")";
    return result.trim();
}

const get_zeit_von_bis = (item: IFallData): string => {
    let zeitVon = item.f010.Kontrolle;
    let zeitBis = '';
    if (zeitVon) {
        zeitBis = item.f010.Tatzeit || "";
    }
    else {
        zeitVon = item.f010.Tatzeit || "";
    }
    let zeitVonBis = zeitVon.substr(0, 5);
    if (zeitBis) {
        zeitVonBis += '-' + zeitBis.substr(0, 5);
    }

    return zeitVonBis;
}



const get_record_typ = (f005: string): string => {
    if (f005 === "Fall") return "";
    else if (f005 === "Fall Aenderung") return "Änderung";
    else if (f005 === "Fall Vornotierung") return "Vornotierung";
    else return "Löschsatz";
}


const get_kfz_kennzeichen = (item: IFallData): string => {
    const kennzeichenart = item.f010.KzArt;
    let kfzKennzeichen = item.f007;

    let kfzKennezeichenZusatz = item.f010.kzzusatz;
    if (kfzKennezeichenZusatz)
        kfzKennzeichen += " " + kfzKennezeichenZusatz;


    if (kennzeichenart === "Versicherung grün") {
        kfzKennzeichen += ' (Ver. grün)';
    } else if (kennzeichenart === "Versicherung blau") {
        kfzKennzeichen += ' (Ver. blau)';
    } else if (kennzeichenart === "Versicherung schwarz") {
        kfzKennzeichen += ' (Ver. schwarz)';
    }
    //console.log(kfzKennzeichen)
    //kfzKennzeichen = kfzKennzeichen.replace(/ /gi, "&nbsp;");
    //console.log(kfzKennzeichen)
    return kfzKennzeichen;
}




const get_fall_art = (item: IFallData): 1 | 2 | 3 | 4 | 5 => {
    const fall_art_array = [1, 2, 3, 4, 5];

    if (fall_art_array.includes(item.f009)) {
        return item.f009 as 1 | 2 | 3 | 4 | 5;
    } else {
        let result: 1 | 2 | 3 | 4 | 5;
        switch (item.f010.fArt) {
            case "VOWI":
                result = 1;
                break;
            case "FOWI":
                result = 2;
                break;
            case "AOWI":
                result = 3;
                break;
            case "ABS":
                result = 4;
                break;
            case "Auftrag":
                result = 5;
                break;
            default:
                result = 1;
                break;
        }
        return result;
    }
}

export const getGesamtbetragAusF010 = (f010: any): number => {
    let gesamtbetrag: number = 0;

    let betrag: number = 0;
    let fallbetrag: number = 0;
    let vorsatzbetrag: number = 0;

    // 6.6.2018: Bugfix für Fehler in der iOS Version. Manchmal kein Betrag vorhanden
    // if (!f010.AOWIziptbnrBetrag && f010.AOWITatbestand1Ursprungsbetrag) f010.AOWIziptbnrBetrag = f010.AOWITatbestand1Ursprungsbetrag;
    // if (!f010.AOWIziptbnr2Betrag && f010.AOWITatbestand2Ursprungsbetrag) f010.AOWIziptbnr2Betrag = f010.AOWITatbestand2Ursprungsbetrag;
    // if (!f010.AOWIziptbnr3Betrag && f010.AOWITatbestand3Ursprungsbetrag) f010.AOWIziptbnr3Betrag = f010.AOWITatbestand3Ursprungsbetrag;
    // if (!f010.AOWIziptbnr4Betrag && f010.AOWITatbestand4Ursprungsbetrag) f010.AOWIziptbnr4Betrag = f010.AOWITatbestand4Ursprungsbetrag;
    // if (!f010.AOWIziptbnr5Betrag && f010.AOWITatbestand5Ursprungsbetrag) f010.AOWIziptbnr5Betrag = f010.AOWITatbestand5Ursprungsbetrag;
    //MD 20.09.2022 so muss der Wert geändert werden.
    if (!f010.AOWIziptbnrBetrag && f010.AOWITatbestand1Ursprungsbetrag) f010 = { ...f010, AOWIziptbnrBetrag: f010.AOWITatbestand1Ursprungsbetrag };
    if (!f010.AOWIziptbnr2Betrag && f010.AOWITatbestand2Ursprungsbetrag) f010 = { ...f010, AOWIziptbnr2Betrag: f010.AOWITatbestand2Ursprungsbetrag };
    if (!f010.AOWIziptbnr3Betrag && f010.AOWITatbestand3Ursprungsbetrag) f010 = { ...f010, AOWIziptbnr3Betrag: f010.AOWITatbestand3Ursprungsbetrag };
    if (!f010.AOWIziptbnr4Betrag && f010.AOWITatbestand4Ursprungsbetrag) f010 = { ...f010, AOWIziptbnr4Betrag: f010.AOWITatbestand4Ursprungsbetrag };
    if (!f010.AOWIziptbnr5Betrag && f010.AOWITatbestand5Ursprungsbetrag) f010 = { ...f010, AOWIziptbnr5Betrag: f010.AOWITatbestand5Ursprungsbetrag };


    if (f010.Betrag1) fallbetrag = parseFloat(f010.Betrag1.replace(",", "."))
    else if (f010.AOWIziptbnrBetrag) fallbetrag = parseFloat(f010.AOWIziptbnrBetrag.replace(",", "."));

    if (f010.mBetrag1) vorsatzbetrag = parseFloat(f010.mBetrag1.replace(",", "."))
    vorsatzbetrag > fallbetrag ? betrag = vorsatzbetrag : betrag = fallbetrag;
    f010.Tatmehrheit && f010.Tatmehrheit === "Ja" ? gesamtbetrag += betrag : gesamtbetrag = betrag


    betrag = 0;
    fallbetrag = 0;
    vorsatzbetrag = 0;
    if (f010.Betrag2) fallbetrag = parseFloat(f010.Betrag2.replace(",", "."))
    else if (f010.AOWIziptbnr2Betrag) fallbetrag = parseFloat(f010.AOWIziptbnr2Betrag.replace(",", "."));

    if (f010.mBetrag2) vorsatzbetrag = parseFloat(f010.mBetrag2.replace(",", "."));
    vorsatzbetrag > fallbetrag ? betrag = vorsatzbetrag : betrag = fallbetrag;

    if (f010.Tatmehrheiteit && f010.Tatmehrheit === "Ja") gesamtbetrag += betrag
    else {
        if (betrag > gesamtbetrag)
            gesamtbetrag = betrag;
    }
    betrag = 0;
    fallbetrag = 0;
    vorsatzbetrag = 0;
    if (f010.Betrag3) fallbetrag = parseFloat(f010.Betrag3.replace(",", "."));
    else if (f010.AOWIziptbnr3Betrag) fallbetrag = parseFloat(f010.AOWIziptbnr3Betrag.replace(",", "."));

    if (f010.mBetrag3) vorsatzbetrag = parseFloat(f010.mBetrag3.replace(",", "."));

    vorsatzbetrag > fallbetrag ? betrag = vorsatzbetrag : betrag = fallbetrag;

    if (f010.Tatmehrheit && f010.Tatmehrheit === "Ja") gesamtbetrag += betrag;
    else {
        if (betrag > gesamtbetrag)
            gesamtbetrag = betrag;
    }

    betrag = 0;
    fallbetrag = 0;
    vorsatzbetrag = 0;
    if (f010.Betrag4) fallbetrag = parseFloat(f010.Betrag4.replace(",", "."));
    else if (f010.AOWIziptbnr4Betrag) fallbetrag = parseFloat(f010.AOWIziptbnr4Betrag.replace(",", "."));

    if (f010.mBetrag4) vorsatzbetrag = parseFloat(f010.mBetrag4.replace(",", "."))
    vorsatzbetrag > fallbetrag ? betrag = vorsatzbetrag : betrag = fallbetrag;

    if (f010.Tatmehrheit && f010.Tatmehrheit === "Ja") gesamtbetrag += betrag;
    else {
        if (betrag > gesamtbetrag)
            gesamtbetrag = betrag;
    }

    betrag = 0;
    fallbetrag = 0;
    vorsatzbetrag = 0;
    if (f010.Betrag5) fallbetrag = parseFloat(f010.Betrag5.replace(",", "."));
    else if (f010.AOWIziptbnr5Betrag) fallbetrag = parseFloat(f010.AOWIziptbnr5Betrag.replace(",", "."));

    if (f010.mBetrag5) vorsatzbetrag = parseFloat(f010.mBetrag5.replace(",", "."));

    vorsatzbetrag > fallbetrag ? betrag = vorsatzbetrag : betrag = fallbetrag;


    if (f010.Tatmehrheit && f010.Tatmehrheit === "Ja") gesamtbetrag += betrag;
    else {
        if (betrag > gesamtbetrag)
            gesamtbetrag = betrag;
    }

    return gesamtbetrag;
}



export const get_show_fremd_zeuge = (fremd_zeuge: IFremdZeuge[]): boolean => {
    for (let i of fremd_zeuge) {
        if (i.FrzAnrede || i.FrzVorname || i.FrzName || i.FrzStrasse
            || i.FrzHausnummer || i.FrzLand || i.FrzPostleitzahl || i.FrzStadt)
            return true;
    }

    return false;
}
export const get_fremd_zeuge = (f010: any): IFremdZeuge[] => {
    let result: IFremdZeuge[] = [];
    let i = 1;
    while (i < 4) {
        result.push({
            FrzAnrede: f010["FrzAnrede" + i] || '',
            FrzVorname: f010["FrzVorname" + i] || '',
            FrzName: f010["FrzName" + i] || '',
            FrzStrasse: f010["FrzStrasse" + i] || '',
            FrzHausnummer: f010["FrzHausnummer" + i] || '',
            FrzLand: f010["FrzLand" + i] || '',
            FrzPostleitzahl: f010["FrzPostleitzahl" + i] || '',
            FrzStadt: f010["FrzStadt" + i] || '',
        });
        i++;
    }
    return result;
}

export const get_faelle_anschrift_daten = (f010: any): IFaelleAnschriftDaten => {
    return {
        AnschriftPersoNr: f010["AnschriftPersoNr"] || '',
        AnschriftAnrede: f010["AnschriftAnrede"] || '',
        AnschriftVorname: f010["AnschriftVorname"] || '',
        AnschriftName: f010["AnschriftName"] || '',
        AnschriftStrasse: f010["AnschriftStrasse"] || '',
        AnschriftHausnummer: f010["AnschriftHausnummer"] || '',
        AnschriftPostleitzahl: f010["AnschriftPostleitzahl"] || '',
        AnschriftStadt: f010["AnschriftStadt"] || '',
        AnschriftGeburtsdatum: f010["AnschriftGeburtsdatum"] || '',
        AnschriftGeburtsname: f010["AnschriftGeburtsname"] || '',
        AnschriftGeburtsort: f010["AnschriftGeburtsort"] || '',
    }
}

export const get_auftrag_anschrift_daten = (f010: any): IAuftragAnschriftDaten[] => {
    let result: IAuftragAnschriftDaten[] = [];
    let i = 1;

    while (f010["AnschriftAnrede" + i]) {
        result.push({
            AnschriftPersonentyp: f010["AnschriftPersonentyp" + i] || '',
            AnschriftAnrede: f010["AnschriftAnrede" + i] || '',
            AnschriftVorname: f010["AnschriftVorname" + i] || '',
            AnschriftName: f010["AnschriftName" + i] || '',
            AnschriftStrasse: f010["AnschriftStrasse" + i] || '',
            AnschriftHausnummer: f010["AnschriftHausnummer" + i] || '',
            AnschriftLand: f010["AnschriftLand" + i] || '',
            AnschriftPostleitzahl: f010["AnschriftPostleitzahl" + i] || '',
            AnschriftStadt: f010["AnschriftStadt" + i] || '',
            AnschriftGeburtsdatum: f010["AnschriftGeburtsdatum" + i] || '',
            AnschriftGeburtsname: f010["AnschriftGeburtsname" + i] || '',
            AnschriftGeburtsort: f010["AnschriftGeburtsort" + i] || '',
        });
        i++;
    }
    /*
     let result = [{
        AnschriftPersonentyp: 'personTyp1',
        AnschriftAnrede: 'personAnrede1',
        AnschriftVorname: 'vorname1',
        AnschriftName: 'name1',
        AnschriftStrasse: 'strasse1',
        AnschriftHausnummer: 'hausnnummer1',
        AnschriftLand: 'land1',
        AnschriftPostleitzahl: '78628',
        AnschriftStadt: 'rottweil',
        AnschriftGeburtsdatum: '01.11.1992',
        AnschriftGeburtsname: 'test',
        AnschriftGeburtsort: 'ewqtre',
       }, {
        AnschriftPersonentyp: 'personTyp2',
        AnschriftAnrede: 'personAnrede2',
        AnschriftVorname: 'vorname2',
        AnschriftName: 'name2',
        AnschriftStrasse: 'strasse2',
        AnschriftHausnummer: 'hausnnummer2',
        AnschriftLand: 'land2',
        AnschriftPostleitzahl: '78628',
        AnschriftStadt: 'rottweil',
        AnschriftGeburtsdatum: '01.11.1992',
        AnschriftGeburtsname: 'test',
        AnschriftGeburtsort: 'ewqtre',
       }];*/
    return result;
}

const get_bezeichnung_spalten = (s: string): string => {
    if (!s) return '';
    switch (s) {
        case "f010.Akz":
            return "aktenzeichen"
        case "f010.Kassenzeichen":
            return "kassenzeichen"
        case "f010.strasse":
            return "strasse"
        case "f010.ort":
            return "ort"
        case "f010.KzArt":
            return "Kennzeichenart"
        case "f010.Zeugen":
            return "zeugen"
        case "f010.bar":
            return "bar"
        case "f010.MPStatus":
            return "Handyparken"
        case "f010.tatbestandsnummer":
            return "Tatbestandsnummer"
        case "f010.Grund":
            return "loeschgrund"
        case "f010.Notiz":
            return "notiz"
        default:
            return s;
    }
}

export const get_end_spalten_to_db = (...args: any) => {
    const change = args[0];

    if (change.name === "columns") {
        const konfiguration_spalten = args[1];
        const all_spalten = args[2];
        const dispatch = args[3];
        const tabelle: "T701_ABS" | "T701_AOWI" | "T701_VOWI" | "T705" | "T715" | "T002" = args[4];

        const fullName: string = change.fullName;
        const id = fullName.substring(fullName.indexOf("[") + 1, fullName.indexOf("]"));
        const opration = fullName.substring(fullName.indexOf("]") + 2, fullName.length);
        if (opration === "visible") {
            const arr_spalten = konfiguration_spalten.split(";").filter((i: any) => i).map((i: any) => i.trim());
            const bezeichnung = tabelle.startsWith("T701") ? get_bezeichnung_spalten(all_spalten[parseInt(id)].dataField) : all_spalten[parseInt(id)].dataField;
            let end_spalten: string = konfiguration_spalten;
            if (konfiguration_spalten.includes(bezeichnung)) {
                arr_spalten.splice(arr_spalten.indexOf(bezeichnung), 1);
                end_spalten = arr_spalten.join(";") + ';';
            } else {
                end_spalten = arr_spalten.join(";") + ";" + bezeichnung + ';';
            }
            dispatch(setTabellenSpalten({ tabelle: tabelle, value: end_spalten }));
        }
    }
}