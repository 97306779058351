import React from 'react';
//import ReactDOM from 'react-dom';
import './index.scss';
import './bootstrap-grid.css';
import './animation.css'
import App from './components/app/app';
import themes from 'devextreme/ui/themes';
import '@fortawesome/fontawesome-free/css/all.css';
import { store } from "./state"
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom/client'
import { createBrowserHistory } from "history";

const rootElement = document.getElementById('root');
if (!rootElement) throw new Error('Kein Element gefunden');
const root = ReactDOM.createRoot(rootElement);

//MD history soll hier kreiert werden.
const history = createBrowserHistory();

if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
    themes.current("material.blue.dark")
} else {
    themes.current("material.blue.light");
}

themes.initialized(() =>
    root.render(<Provider store={store}>
        <App history={history} />
    </Provider>)
);


//Nur Zum Git Testen

