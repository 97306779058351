
import CardControlBack from "./card_content_back";
import CardHeader from "./card_header_back";
import CardControl from "../card_control";
import { IFallData, IFallDataZusaetzlich } from "../../interfaces";
import { memo } from "react";


interface ICardBackProps {
    item: IFallData,
    itemZus: IFallDataZusaetzlich
}
const CardBack: React.FC<ICardBackProps> = ({item, itemZus}):JSX.Element => {


    return(
            <div className='p-1 pr-2'>
                <CardHeader  item={item} itemZus={itemZus}/>  
                <CardControl item={item} itemZus={itemZus}/>
                <CardControlBack item={item} itemZus={itemZus}/>
              {/** <CardFooter item={item} itemZus={itemZus}/> */} 
            </div>
        );
}

export default memo(CardBack);