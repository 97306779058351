import { LoadPanel } from "devextreme-react";
import { TextBox, Button as TextBoxButton } from 'devextreme-react/text-box';
import { useCallback, useEffect, useMemo, useState } from "react";
import { GetData } from "../../global/communication";
import { icon_eye, icon_eye_slash } from "../../global/const";
import { handleContentReadyTextBox } from "../../global/hilfs_functions";
import { setAlert, setLoggedIn, setPasswortaenderung, setToast, useAppDispatch } from "../../state";
import Footer from "../footer/footer";
import { MDButton } from "../../global/components";
import { fix_document_title } from "../../sonstiges/hilfs_functions";

const inputAttrKennung = {
    "aria-label": "Kennung",
    "autocomplete": "username",
}
const inputAttrPasswort = {
    "aria-label": "Passwort",
    "autocomplete": "current-password",
}
const Login: React.FC = () => {
    const [kennung, setKennung] = useState<string>('');
    const [passwort, setPasswort] = useState<string>('');
    const [showAsPassword, setShowAsPassword] = useState<boolean>(true);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [barrierefreiheit, setBarrierefreiheit] = useState<boolean>(false);

    const dispatch = useAppDispatch();
    // const [flag, setFlag] = useState(true);

    useEffect(() => {
        if (isLoading) {
            GetData({
                Operation: "CheckIfSessionExist"
            }, dispatch, false)
                .then((res) => {
                    if (!res) {
                        dispatch(setAlert({
                            text: "Es ist ein Fehler aufgetreten.",
                            type: "Fehler",
                        }));
                        return
                    }
                    if (res.programm) window.document.title = 'Winowig Online NT ' + res.programm;
                    if (res.erfolg) {
                        dispatch(setLoggedIn(true));
                        res.passwortaenderung && dispatch(setPasswortaenderung(true));
                    } else {
                        (res.barrierefreiheit && res.barrierefreiheit === "JA") && setBarrierefreiheit(true);
                        setIsLoading(false);
                        window.history.replaceState(null, "", "/login")
                        fix_document_title("/login");
                    }
                });

        }
    }, [dispatch, kennung, isLoading]);

    const handleClickLogIn = useCallback(() => {
        if (!kennung) {
            dispatch(setToast({
                message: "Kennung darf nicht leer sein.",
                type: "error"
            }));
            return;
        }
        if (kennung.indexOf("@") < 0) {
            dispatch(setToast({
                message: "Kennung muss ein @ enthalten.",
                type: "error"
            }));
            return;
        }
        if (!passwort) {
            dispatch(setToast({
                message: "Passwort darf nicht leer sein.",
                type: "error"
            }));
            return;
        }

        GetData({
            Operation: "GetLogin",
            Kennung: kennung,
            Passwort: passwort,
        }, dispatch, false)
            .then((res) => {
                if (!res || !res.erfolg) {
                    dispatch(setAlert({
                        text: !res ? "Es ist ein Fehler aufgetreten." : res.hinweis ? res.hinweis : "Kennung oder Passwort ungültig.",
                        type: "Fehler",
                    }));
                    return
                }
                dispatch(setLoggedIn(true));
                res.passwortaenderung && dispatch(setPasswortaenderung(true));
            });


    }, [kennung, passwort, dispatch]);



    const memo_setShowAsPassword = useCallback(() => {
        setShowAsPassword(prev => !prev);
    }, []);


    const memo_options_passwort = useMemo(() => {
        return {
            icon: showAsPassword ? icon_eye : icon_eye_slash,
            onClick: memo_setShowAsPassword,
            elementAttr: {
                "role": "checkbox",
                "aria-checked": showAsPassword ? "false" : "true",
                "aria-label": showAsPassword ? "Passwort in Klartext anzeigen" : "Passwort verstecken",
            }
        }
    }, [showAsPassword, memo_setShowAsPassword]);

    return (
        isLoading ? <LoadPanel visible={true} /> :
            <>
                <div className="login_container">
                    <main className="row g-0">
                        <div className="col-12 d-flex justify-content-center align-items-baseline">
                            <div className="card card-login w-auto p-5 animate__animated animate__fadeIn text-center">
                                <h2 className='text-primary fw-500 mt-0 h2'>WiNOWiG Online</h2>

                                <div className="d-flex">
                                    <i
                                        className="dx-icon fas fa-user text-primary icon_big"
                                        aria-hidden="true"
                                    ></i>
                                    <TextBox
                                        //   stylingMode='underlined'
                                        //   placeholder="Kennung"
                                        //   labelMode="static"
                                        label="Kennung"
                                        labelMode="floating"
                                        stylingMode="outlined"
                                        onValueChange={setKennung}
                                        value={kennung}
                                        valueChangeEvent="input"
                                        maxLength={60}
                                        inputAttr={inputAttrKennung}
                                        onContentReady={handleContentReadyTextBox}
                                    />
                                </div>
                                <div className="d-flex mt-3 mt-md-4">
                                    <i
                                        className="dx-icon fas fa-fingerprint text-primary icon_big"
                                        aria-hidden="true"
                                    ></i>
                                    <TextBox
                                        //  stylingMode='underlined'
                                        //  placeholder="Passwort"
                                        //  labelMode="static"
                                        label="Passwort"
                                        labelMode="floating"
                                        stylingMode="outlined"
                                        mode={showAsPassword ? "password" : "text"}
                                        value={passwort}
                                        onValueChange={setPasswort}
                                        /**Mit Validator kann man onEnterKey nicht verwenden, weil der event beihaltet dann keine ValidatorGroup*/
                                        onEnterKey={handleClickLogIn}
                                        valueChangeEvent="input"
                                        maxLength={60}
                                        inputAttr={inputAttrPasswort}
                                        onContentReady={handleContentReadyTextBox}
                                    >
                                        <TextBoxButton
                                            name='passwortLogin'
                                            location="after"
                                            options={memo_options_passwort}

                                        />
                                    </TextBox>
                                </div>
                                <MDButton page="login_" className="mt-3 mt-md-5" onClick={handleClickLogIn} stylingMode="outlined" type="default" button_text="Login" />
                            </div>
                        </div>
                    </main>
                    <Footer barrierefreiheit={barrierefreiheit} />
                </div>
            </>
    )
}

export default Login;

