
import React, { useCallback } from 'react';
import { Redirect } from "react-router";
import { Switch, Route } from 'react-router-dom';
import Content from '../content/content';
import Footer from "../footer/footer";
import { Paths } from '../navigation/navigationLogik';
import Faelle from '../faelle/faelle';
import DoppelVerwarnungen from '../doppelVerwarnungen';
import { withRouter } from 'react-router-dom';
import DatenexportStarten from '../datenExport/starten';
import DatenexportHerunterladen from '../datenExport/herunterladen';
import AbschleppUnternehmen from '../einstellungen/abschlepp_unternehmen';
import Geraete from '../einstellungen/geraete/index';
import Statistik_Benutzerdefiniert from '../statistik/Benutzerdefiniert';
import Statistik_Individuell from '../statistik/Individuell';
import Statistik_Tag from '../statistik/tag';
import Statistik_Monat from '../statistik/monat';
import Statistik_GeraeteLogins from '../statistik/geraeteLogins';
import KFZ_Hinweis from '../einstellungen/kfz_hinweise';
import Sachbearbeiter from '../einstellungen/sachbearbeiter';
import SachbearbeiterGruppen from '../einstellungen/sb_gruppen';
import Stammdaten from '../einstellungen/stammdaten';
import Strasse from '../einstellungen/strassen';
import Tatbestaende from '../einstellungen/tatbestaende';
import TatbestaendeKategorie from '../einstellungen/tatbestand_kategorie';
import TatbestandHinweise from '../einstellungen/tatbestand_hinweise';
import PasswortAendern from '../einstellungen/passwort';
import Fernwartung from '../support/fernwatung';
import { AutorisierungKonfigurator, AutorisierungDruckLayout } from '../support/autorisierung';
import { useAppSelector } from '../../state';
import DatenExportBearbeiten from '../datenExport/bearbeiten/bearbeiten';
import GeraeteKonfigurationen from '../einstellungen/geraeteKonfigurationen';

const SwitchNav: React.FC = () => {
  const konfigurator_mobil = useAppSelector((state) => state.store_einstellungen.konfiguration.mobil);
  const konfigurator_faelle_verkehr = useAppSelector((state) => state.store_einstellungen.konfiguration.faelle_verkehr);
  const konfigurator_faelle_abschlepp = useAppSelector((state) => state.store_einstellungen.konfiguration.faelle_abschlepp);
  const konfigurator_faelle_aowi = useAppSelector((state) => state.store_einstellungen.konfiguration.faelle_aowi);
  const konfigurator_fliess_verkehr = useAppSelector((state) => state.store_einstellungen.konfiguration.faelle_fliess_verkehr);
  const barrierefreiheit = useAppSelector((state) => state.store_einstellungen.konfiguration.barrierefreiheit);

  const load_default_view = useCallback((): string => {
    if (konfigurator_mobil && konfigurator_faelle_verkehr) {
      return Paths.PATH_FAELLEVERKEHR;
    } else if (konfigurator_faelle_abschlepp) {
      return Paths.PATH_ABSCHLEPPFAELLE
    } else if (konfigurator_mobil && konfigurator_faelle_aowi) {
      return Paths.PATH_FAELLEAOWI
    } else if (konfigurator_mobil && konfigurator_fliess_verkehr) {
      return Paths.PATH_FAELLEFLIESSVERKEHR
    }
    return '';
  }, [konfigurator_mobil, konfigurator_faelle_verkehr, konfigurator_faelle_abschlepp, konfigurator_faelle_aowi, konfigurator_fliess_verkehr]);


  return (
    <div className="app">
      <Content footer={<Footer barrierefreiheit={barrierefreiheit} />}>
        <Switch>
          {/** Mobil */}
          <Route exact path={Paths.PATH_FAELLEVERKEHR} >
            <Faelle fall_art={1} />
          </Route>
          <Route exact path={Paths.PATH_FAELLEFLIESSVERKEHR} >
            <Faelle fall_art={2} />
          </Route>
          <Route exact path={Paths.PATH_FAELLEAOWI} >
            <Faelle fall_art={3} />
          </Route>
          <Route exact path={Paths.PATH_ABSCHLEPPFAELLE} >
            <Faelle fall_art={4} />
          </Route>
          <Route exact path={Paths.PATH_DOPPELVERWARNUNGEN} component={DoppelVerwarnungen} />

          {/** Datenexport */}
          <Route exact path={Paths.PATH_DATENEXPORTSTARTEN} component={DatenexportStarten} />
          <Route exact path={Paths.PATH_DATENEXPORTHERUNTERLADEN} component={DatenexportHerunterladen} />
          <Route exact path={Paths.PATH_DATENEXPORTBEARBEITEN} component={DatenExportBearbeiten} />

          {/** Statistik */}
          <Route exact path={Paths.PATH_STATISTIKBENUTZERDEFINIERT} component={Statistik_Benutzerdefiniert} />
          <Route exact path={Paths.PATH_STATISTIKINDIVIDUELL} component={Statistik_Individuell} />
          <Route exact path={Paths.PATH_STATISTIKTAGESAUSWERTUNG} component={Statistik_Tag} />
          <Route exact path={Paths.PATH_STATISTIKMONAT} component={Statistik_Monat} />
          <Route exact path={Paths.PATH_STATISTIKGERAETELOGINS} component={Statistik_GeraeteLogins} />

          {/** Support */}
          <Route exact path={Paths.PATH_KONFIGURATOR}>
            <AutorisierungKonfigurator />
          </Route>
          <Route exact path={Paths.PATH_DRUCKLAYOUTS} >
            <AutorisierungDruckLayout />
          </Route>
          <Route exact path={Paths.PATH_FERNWARTUNG} component={Fernwartung} />

          {/** Einstellungen */}
          <Route exact path={Paths.PATH_ABSCHLEPPUNTERNEHMEN} component={AbschleppUnternehmen} />
          <Route exact path={Paths.PATH_GERAETE} component={Geraete} />
          <Route exact path={Paths.PATH_GERAETE_KONFIGURATIONEN} component={GeraeteKonfigurationen} />
          <Route exact path={Paths.PATH_KFZHINWEISE} component={KFZ_Hinweis} />
          <Route exact path={Paths.PATH_SACHBEARBEITER} component={Sachbearbeiter} />
          <Route exact path={Paths.PATH_SACHBEARBEITERGRUPPEN} component={SachbearbeiterGruppen} />
          <Route exact path={Paths.PATH_STAMMDATEN} component={Stammdaten} />
          <Route exact path={Paths.PATH_STRASSEN} component={Strasse} />
          <Route exact path={Paths.PATH_TATBESTAENDE} component={Tatbestaende} />
          <Route exact path={Paths.PATH_TATBKATEGORIEN} component={TatbestaendeKategorie} />
          <Route exact path={Paths.PATH_TATBHINWEIS} component={TatbestandHinweise} />
          <Route exact path={Paths.PATH_PASSWORTAENDERN} component={PasswortAendern} />
          <Redirect to={load_default_view()} />
        </Switch>
      </Content>
    </div>
  )
}

export default withRouter(SwitchNav);