import { memo } from "react";

const LeichteSprache = (): JSX.Element => {
    return (
        <div className="leichte_sprache">
            <h3 className="mt-0"> Worum geht es</h3>
            <p>Es geht um unsere Internet-Seite Winowig Online NT.</p>
            <p>Da sehen Sie sofort:</p>
            <p className="ml-4">Welche Fälle wurden gemeldet</p>
            <p className="ml-4">über die Handy-App 'WiNOWiG.mobil'.</p>
            <p className="ml-4">Sie sehen auch die Fotos.</p>
            <br />
            <p>Wenn der Außen&middot;dienst eine Frage hat</p>
            <p>kann der Innen&middot;dienst sofort antworten.</p>
            <br />
            <p>Sie können Daten eingeben.</p>
            <p>Zum Beispiel:</p>
            <ul>
                <li>Straßen&middot;liste</li>
                <li>Benutzer</li>
                <li>Hinweise</li>
            </ul>
            <br />
            <p>Da ist auch eine Liste mit Hinweisen</p>
            <p>zu Fahrzeug-Kennzeichen.</p>
            <p>Zum Beispiel steht da:</p>
            <ul>
                <li>Welches Fahrzeug hat Sonder&middot;park&middot;genehmigung.</li>
                <li>Welches Fahrzeug hat einen Anwohner&middot;park&middot;ausweis.</li>
            </ul>
            <br />
            <p>Es gibt auch eine Geräte&middot;verwaltung:</p>
            <p className="ml-4">Da sieht man alle Geräte</p>
            <p className="ml-4">die benutzt werden.</p>
            <p className="ml-4">Man kann Geräte sperren.</p>
            <p className="ml-4">Und man bekommt Statistiken.</p>
            <br />
            <br />
            <h3>So funktioniert unsere Internet-Seite</h3>
            <p>In der Leiste auf der linken Seite</p>
            <p>sind die wichtigsten Links zum Anklicken.</p>
            <br />
            <p className="text-decoration-underline">Sie sehen das Wort "Mobil" mit einem Auto-Bild.</p>
            <p>Da sind die Fall&middot;daten</p>
            <p>die mit der Handy-App gemacht worden.</p>
            <p>Sie können die Fall&middot;daten sehen und speichern.</p>
            <p>Sie können auch Statistiken ansehen.</p>
            <br />
            <p className="text-decoration-underline">Sie sehen das Wort "Einstellungen".</p>
            <p>Da können Sie:</p>
            <ul>
                <li>Daten verändern</li>
                <li>Benutzer bearbeiten</li>
                <li>Geräte bearbeiten</li>
                <li>Die Anzeige einstellen</li>
            </ul>
            <br />
            <p>Auf manchen Seiten gibt es noch mehr Möglichkeiten.</p>
            <p>Dann können Sie oben</p>
            <p>suchen und filtern</p>
            <p>und die Anzeige verändern.</p>
            <br />
            <br />
            <h3>Erklärung zur Barrierefreiheit & Melde&middot;stelle</h3>
            <h4>Barriere&middot;freiheit hier:</h4>
            <p>Die Firma Schelhorn OWiG Software GmbH</p>
            <p>macht die Software WiNOWiG.Online NT.</p>
            <p>Die Firma will</p>
            <p>dass die Internet-Seite barrierefrei ist.</p>
            <p>So steht es im Gesetz für öffentliche Stellen.</p>
            <p>Mehr dazu steht in der Erklärung zur Barrierefreiheit.</p>
            <br />
            <h4>Barrieren melden</h4>
            <p>Sie haben etwas gefunden</p>
            <p>was nicht barrierefrei ist?</p>
            <p>Oder Sie möchten nachfragen</p>
            <p>wie die Barrierefreiheit ist?</p>
            <br />
            <p>Das können Sie hier:</p>
            <p className="ml-4">Post:</p>
            <p className="ml-4">Fa. Schelhorn OWiG Software GmbH</p>
            <p className="ml-4">Schillerstr. 21</p>
            <p className="ml-4">78628 Rottweil</p>
            <br />
            <p className="ml-4">Telefon: 0741 / 17449 61</p>
            <p className="ml-4">E-Mail: <a href="mailto:barrierefrei@owig.de" className="text-primary text-decoration-underline">barrierefrei@owig.de</a></p>
            <br />
            <h4>Streit lösen:</h4>
            <p>Wenn Sie eine Barriere melden</p>
            <p>und wir nichts dagegen tun</p>
            <p>können Sie mit der Schlichtungs&middot;stelle sprechen.</p>
            <p>Das steht im Gesetz.</p>
            <br />
            <p>Die Schlichtungs&middot;stelle versucht</p>
            <p>den Streit zu lösen.</p>
            <p>Das kostet Sie nichts.</p>
            <p>Sie brauchen keinen Anwalt.</p>
            <p>Mehr dazu steht hier: <a href="https://www.schlichtungsstelle-bgg.de" rel="noreferrer" className="text-primary text-decoration-underline" target="_blank">www.schlichtungsstelle-bgg.de</a></p>
            <p>Sie können der Schlichtungs&middot;stelle eine E-Mail schreiben:</p>
            <p><a href="mailto:info@schlichtungsstelle-bgg.de" className="text-primary text-decoration-underline">info@schlichtungsstelle-bgg.de</a></p>
            <br />
            <strong><p>Was gibt es hier noch</p></strong>
            <strong><p>in Leichter Sprache</p></strong>
            <strong><p>oder in Gebärden&middot;sprache:</p></strong>
            <p>Wir haben keine anderen Texte</p>
            <p>in Leichter Sprache</p>
            <p>oder in Gebärden&middot;sprache.</p>
        </div>
    )
}

export default memo(LeichteSprache);