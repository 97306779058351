import { Popup, ScrollView } from "devextreme-react";
import { ToolbarItem } from "devextreme-react/autocomplete";
import { useCallback } from "react";
import { handleErrorImg } from "../../../../../global/hilfs_functions";
import { IFoto } from "../../../interfaces";

interface IFotosPopupProps {
    onHide: (e: any) => void,
    fotos: IFoto[],
    weitere_fotos?: boolean,
}

const FotosPopup: React.FC<IFotosPopupProps> = ({ onHide, fotos, weitere_fotos }): JSX.Element => {
    const RenderePopupTitle = useCallback(() => {
        return (<h2 className="title_popup">{weitere_fotos ? "Weitere Bilder" : "Bilder"}</h2>)
    }, [weitere_fotos]);

    return (<>
        <Popup
            visible={true}
            onHiding={onHide}
            dragEnabled={true}
            hideOnOutsideClick={true}
            showCloseButton={true}
            fullScreen={true}
        >
            <ToolbarItem
                toolbar="top"
                location={'before'}
                render={RenderePopupTitle}
            />
            <ScrollView>
                <div className="row ">
                    {fotos.map((foto, i) => {
                        return (
                            <div key={i} className={"col-12 col-sm-6 col-lg-4 col-xxl-3"}>
                                <a href={foto.url} target="_blank" rel="noreferrer">
                                    <img alt={foto.alt} src={foto.url} className="img-fluid fotos_in_popup" onError={handleErrorImg} />
                                </a>
                            </div>)
                    })}

                </div>
            </ScrollView>

        </Popup>
    </>)
}

export default FotosPopup;




