
import { Button, Tooltip } from "devextreme-react"
import { memo, useCallback, useState, useMemo } from "react"
import { useId } from "react-id-generator";
import { setTooltipEvents } from "./hilfs_functions";
import { Popup, TextBox } from "devextreme-react"
import { ToolbarItem } from "devextreme-react/autocomplete";
import { handleContentReadyTextBox } from "./hilfs_functions";
import { setAlert } from "./notificationsSlice";
import { useAppDispatch } from "../state";


export const NewlineText = (text: string) => {
    const newText = text.split('\\n').map((str: string, i: number) => <p key={i} > {str} </p>);
    return newText;
}
export const RendereGridTitle = (title: string) => {
    return (<h1 className="title_grid" > {title} </h1>)
};
export const RenderePopupTitle = (title: string): JSX.Element => {
    return (<h2 className="title_popup" > {title} </h2>)
};


interface IMDButtonProps {
    page: string,
    stylingMode?: "text" | "outlined" | "contained",
    type?: "default" | "back" | "danger" | "normal" | "success",
    tooltip_text?: string | JSX.Element,
    button_text?: string,
    icon?: string,
    onClick?: (e?: any) => void,
    className?: string,
    disabled?: boolean,
    ariaLabel?: any,
    tabIndex?: number,
    data?: any,
    ariaExpanded?: any,
    ariaDisabled?: boolean,
}
export const MDButton: React.FC<IMDButtonProps> = memo(({ ariaDisabled, ariaExpanded, tabIndex, type, stylingMode, page, onClick, tooltip_text, icon, button_text, className, disabled, ariaLabel, data }) => {
    const ids = useId(1, page + '_');
    const [tooltip, setTooltip] = useState({
        visible: false
    });

    //const aria_label = (ariaLabel !== undefined) ? { "aria-label": ariaLabel } : undefined;
    const element_attr = {} as any;
    if (ariaLabel !== undefined && ariaLabel === "") {
        element_attr["aria-label"] = "";//kann sein dass ich hier leer übergebe.
    } else if (tooltip_text) {
        element_attr["aria-label"] = tooltip_text;
    } else if (ariaLabel) {
        element_attr["aria-label"] = ariaLabel;
    }
    // (ariaLabel !== undefined) && (element_attr["aria-label"] = ariaLabel);
    (ariaExpanded) && (element_attr["aria-expanded"] = ariaExpanded);
    (typeof ariaDisabled !== "undefined") && (element_attr["aria-disabled"] = ariaDisabled);
    (data) && (element_attr["data"] = JSON.stringify(data))

    const handleClick = useCallback((e: any) => {
        if (ariaExpanded) {
            if (e.element.getAttribute("aria-expanded") === "false") {
                e.element.setAttribute("aria-expanded", "true")
            } else {
                e.element.setAttribute("aria-expanded", "false")
            }
        }
        onClick && e.event.stopPropagation();
        onClick && onClick(e)
    }, [onClick, ariaExpanded]);

    const handleContentReady = useCallback((item: any) => {
        setTooltipEvents(item, (value: boolean) => { setTooltip({ ...tooltip, visible: value }) })
        let element: HTMLElement = item.element;
        element.onmousedown = (e: any) => {
            // Wenn ein Button geklickt wird, bekommt er on mouse down die Klasse dx-state-focused. dies willl ich nicht
            e.preventDefault();
        }
    }, [tooltip]);

    return (
        <>
            <Button
                stylingMode={stylingMode ? stylingMode : "text"}
                type={type ? type : "default"}
                id={ids[0]}
                disabled={disabled ? true : false}
                className={className}
                onClick={handleClick}
                onContentReady={handleContentReady}
                icon={icon ? icon : undefined}
                text={button_text ? button_text : undefined}
                elementAttr={element_attr}
                tabIndex={tabIndex ? tabIndex : undefined}
            >
            </Button>
            {tooltip_text &&
                <Tooltip
                    target={'#' + ids[0]}
                    visible={tooltip.visible}
                    hideOnOutsideClick={false}
                >
                    {typeof tooltip_text === "string" ? <p>{tooltip_text}</p> : tooltip_text}
                </Tooltip>}
        </>
    )
})



interface IMDBezeichnung {
    callback: (speichern?: boolean, bezeichnung?: string, data?: any) => void,
    title: string,
    data?: any
}
export const MDBezeichnung: React.FC<IMDBezeichnung> = ({ title, data, callback }) => {
    const [bezeichnung, setBezeichnung] = useState("");
    const dispatch = useAppDispatch();

    const handleClickSpeichern = useCallback(() => {
        if (!bezeichnung) {
            dispatch(setAlert({
                text: "Bezeichnung darf nicht leer sein.",
                type: "Fehler",
            }))
            return
        }
        callback(true, bezeichnung, data);
    }, [bezeichnung, data, dispatch, callback]);

    const memo_options_ok = useMemo(() => {
        return {
            text: "Speichern",
            onClick: handleClickSpeichern,
        }
    }, [handleClickSpeichern]);

    const handleAbbrechen = useCallback(() => {
        callback(false);
    }, [callback]);
    const memo_options_abbrechen = useMemo(() => {
        return {
            text: 'Abbrechen',
            onClick: handleAbbrechen,
        }
    }, [handleAbbrechen]);

    return (
        <>
            <Popup
                visible={true}
                onHiding={handleAbbrechen}
                dragEnabled={false}
                hideOnOutsideClick={true}
                fullScreen={false}
                showCloseButton={true}
                showTitle={true}
                title={title}
                height="auto"
                maxHeight="450px"
                maxWidth="400px"
            >
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={memo_options_ok}
                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={memo_options_abbrechen}
                />
                <TextBox
                    label='Bezeichnung'
                    labelMode='floating'
                    stylingMode="outlined"
                    mode='text'
                    showClearButton={false}
                    onValueChange={setBezeichnung}
                    value={bezeichnung}
                    valueChangeEvent="input"
                    onEnterKey={handleClickSpeichern}
                    onContentReady={handleContentReadyTextBox}
                />
            </Popup>
        </>
    )
}

