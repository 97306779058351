
import { FileUploader, Popup, ScrollView, SelectBox } from "devextreme-react"
import { ToolbarItem } from "devextreme-react/autocomplete"
import { memo, useMemo } from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import { GetData } from "../../../global/communication";
import { datei_bereit_zum_hochladen } from "../../../global/const";
import { handleContentReadySelectBox } from "../../../global/hilfs_functions";
import { setAlert, setToast, useAppDispatch } from "../../../state";

interface IImportProps {
    bild?: boolean,
    callback: (value: boolean) => void;
}
const ImportDrucker: React.FC<IImportProps> = ({ bild, callback }): JSX.Element => {
    const bild_import = (bild !== undefined && bild === true);
    const [druckerModelle, setDruckerModelle] = useState<string[]>([]);
    const [druckerValue, setDruckerValue] = useState("");
    const refForm = useRef() as React.MutableRefObject<HTMLFormElement>;
    const refIFrame = useRef() as React.MutableRefObject<HTMLIFrameElement>;
    const refFileUploader = useRef() as React.MutableRefObject<FileUploader>;
    const [files, setFiles] = useState<any[]>([]);
    const dispatch = useAppDispatch();

    useEffect(() => {
        GetData({
            Operation: "GetDruckermodelle",
        }, dispatch).then((res) => {
            if (!res) {
                setDruckerModelle([]);
                return
            }
            setDruckerModelle(res.data);
            setDruckerValue(res.data[0])
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleIfram = useCallback((e: any) => {
        if (refIFrame.current.contentDocument?.children[0].children[1].hasChildNodes()) {
            //MD am 10.08.2023 angepasst.
            let result = JSON.parse(refIFrame.current.contentDocument?.children[0].children[1].children[0].innerHTML);
            refFileUploader.current.instance.reset();
            if (result.erfolg) {
                dispatch(setToast({
                    message: "Datei wurde erfolgreich verarbeitet.",
                    type: "success",
                }));
            } else {
                dispatch(setAlert({
                    text: result.hinweis ? result.hinweis : "Es ist ein Fehler beim Import aufgetreten.",
                    type: "Fehler",
                    maxWidth: 600
                }));
            }
            callback(false);
        }
    }, [refIFrame, dispatch, callback]);


    const handleCallBack = useCallback(() => {
        callback(false)
    }, [callback]);

    const handleImportStart = useCallback(() => {
        if (files.length === 0) {
            dispatch(setToast({
                message: "Wählen Sie bitte eine Datei aus.",
                type: "warning",
            }))
            return
        }

        if ((bild && files[0].type.substring(0, 6) !== "image/") || (!bild && files[0].type !== "text/plain")) {
            dispatch(setToast({
                message: bild ? "Es dürfen nur Bilder importiert werden." : "Es dürfen nur Datei mit der Endung .txt importiert werden.",
                type: "warning",
            }))
            return
        }

        if (refForm.current.querySelector(".dx-fileuploader-file-status-message")?.innerHTML === datei_bereit_zum_hochladen) {
            refForm.current.submit();
        }


    }, [files, dispatch, bild]);
    const memo_options_import = useMemo(() => {
        return {
            text: 'Import starten',
            onClick: handleImportStart,
        }
    }, [handleImportStart]);

    const memo_options_abbrechen = useMemo(() => {
        return {
            text: 'Abbrechen',
            onClick: handleCallBack,
        }
    }, [handleCallBack]);
    const RenderePopupTitle = useCallback(() => {
        return (<h2 className="title_popup">{bild_import ? 'Bild importieren' : 'Drucklayout importieren'}</h2>)
    }, [bild_import]);
    return (

        <Popup
            visible={true}
            onHiding={handleCallBack}
            dragEnabled={true}
            hideOnOutsideClick={true}
            fullScreen={false}
            showCloseButton={true}
            height='auto'
            maxHeight="70vh"
            maxWidth="450px"
        >
            <ToolbarItem
                toolbar="top"
                location={'before'}
                render={RenderePopupTitle}
            />
            <ToolbarItem
                widget="dxButton"
                toolbar="bottom"
                location="after"
                options={memo_options_import}
            />
            <ToolbarItem
                widget="dxButton"
                toolbar="bottom"
                location="after"
                options={memo_options_abbrechen}
            />
            <ScrollView>
                <div className="row p-2 p-md-3">
                    <div className="col-12 p-1 p-md-2">
                        <SelectBox
                            dataSource={druckerModelle}
                            value={druckerValue}
                            onValueChange={setDruckerValue}
                            label='Wählen Sie die Drucker:'
                            labelMode='floating'
                            stylingMode='outlined'
                            onContentReady={handleContentReadySelectBox}
                        />
                    </div>
                    <div className="col-12 p-1 p-md-2">
                        {bild_import ? <p>Wählen Sie die Bilddatei die sie importieren wollen:</p>
                            : <p>Wählen Sie die Layoutdatei die sie importieren wollen:</p>
                        }
                    </div>
                    <div className="col-12 p-1 p-md-2">
                        <form ref={refForm} method="post" target="iframe_ducklayout_importieren" action="/" encType="multipart/form-data">
                            <input type="hidden" name="Operation" value="ImportDrucklayout" />
                            <input id="dialog-drucklayout-import-layout-druckermodell" type="hidden" name="Druckermodell" value={druckerValue} />
                            <FileUploader
                                multiple={false}
                                accept={bild_import ? "image/*" : "text/plain"}
                                uploadMode={"useForm"}
                                uploadUrl="/"
                                labelText=""
                                ref={refFileUploader}
                                selectButtonText="Datei ..."
                                readyToUploadMessage={datei_bereit_zum_hochladen}
                                name="Dateiname"
                                onValueChange={setFiles}
                            />
                        </form>
                        <iframe title="iframe_ducklayout_importieren" name="iframe_ducklayout_importieren" ref={refIFrame} onLoad={handleIfram} className="d-none"></iframe>

                    </div>
                </div>
            </ScrollView>

        </Popup>
    )
}

export default memo(ImportDrucker);