import { Popup, TextBox } from "devextreme-react";
import { memo, useCallback, useState } from "react";
import Confirm from "../../global/confirm";
import { ToolbarItem } from "devextreme-react/autocomplete"
import Konfigurator from "./konfigurator/index";
import DruckerLayouts from "./layouts";
import { GetData } from "../../global/communication";
import md5 from 'md5';
import { setKonfigSupportZugangAuth, useAppDispatch, useAppSelector } from "../../state";
import { useMemo } from "react";
import { handleContentReadyTextBox } from "../../global/hilfs_functions";


export const AutorisierungKonfigurator: React.FC = () => {
  const isAllowed = useAppSelector((state) => state.store_einstellungen.konfiguration.support_zugang_authentifiziert);
  const [passwort, setPasswort] = useState("");
  const [showPopupConfirm, setShowPopupConfirm] = useState(true);
  const dispatch = useAppDispatch();
  const [showPopup, setShowPopup] = useState(false);

  const handleHidePopup = useCallback(() => {
    setShowPopup(false)
  }, []);
  const handleOkClick = useCallback(() => {
    GetData({
      Operation: "CheckSupportPasswortHash",
      PASSWORTHASH: md5(passwort)
    }, dispatch).then((res) => {
      if (!res) return
      if (res.berechtigung) {
        setPasswort("");
        dispatch(setKonfigSupportZugangAuth(true));
      } else { setShowPopup(true); }
    });
  }, [passwort, dispatch]);

  const handleHidePopupAutorisierung = useCallback(() => {
    setShowPopupConfirm(false)
  }, []);




  return (<>
    {(!isAllowed && showPopupConfirm) ?
      <Confirm
        body={<ConfirmBody handleOkClick={handleOkClick} passwort={passwort} setPasswort={setPasswort} />}
        on_ok={handleOkClick}
        on_abbrechen={handleHidePopupAutorisierung}
        on_hiding={handleHidePopupAutorisierung}
        title={"Autorisierung"}
      ></Confirm>
      : (!isAllowed && !showPopupConfirm) ? <></> : <Konfigurator />}
    <PopupZugriffVerweigert handleHidePopup={handleHidePopup} showPopup={showPopup} />
  </>)
}



export const AutorisierungDruckLayout: React.FC = () => {
  const isAllowed = useAppSelector((state) => state.store_einstellungen.konfiguration.support_zugang_authentifiziert);
  const [passwort, setPasswort] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [showPopupConfirm, setShowPopupConfirm] = useState(true);
  const dispatch = useAppDispatch();


  const handleOkClick = useCallback(() => {
    GetData({
      Operation: "CheckSupportPasswortHash",
      PASSWORTHASH: md5(passwort)
    }, dispatch).then((res) => {
      if (!res) return
      if (res.berechtigung) {
        setPasswort("");
        dispatch(setKonfigSupportZugangAuth(true));
      } else { setShowPopup(true); }
    });
  }, [passwort, dispatch]);

  const handleHidePopupAutorisierung = useCallback(() => {
    setShowPopupConfirm(false)
  }, []);

  const handleHidePopup = useCallback(() => {
    setShowPopup(false)
  }, []);

  return (<>
    {(!isAllowed && showPopupConfirm) ?
      <Confirm
        body={<ConfirmBody handleOkClick={handleOkClick} passwort={passwort} setPasswort={setPasswort} />}
        on_ok={handleOkClick}
        on_abbrechen={handleHidePopupAutorisierung}
        on_hiding={handleHidePopupAutorisierung}
        title={"Autorisierung"}
      ></Confirm>
      : (!isAllowed && !showPopupConfirm) ? <></> : <DruckerLayouts />}

    <PopupZugriffVerweigert handleHidePopup={handleHidePopup} showPopup={showPopup} />

  </>)
}


const ConfirmBody: React.FC<{ handleOkClick: any, setPasswort: any, passwort: any }> = memo(({ handleOkClick, passwort, setPasswort }) => {

  return (
    <TextBox
      label="Passwort"
      labelMode="floating"
      stylingMode="outlined"
      onEnterKey={handleOkClick}
      mode="password"
      id="input_password_support"
      onValueChange={(e: any) => setPasswort(e)}
      value={passwort}
      valueChangeEvent="input"
      maxLength={60}
      //onInitialized={OnInit}
      onContentReady={OnInit}
    />
  )
})
const OnInit = (e: any) => {

  handleContentReadyTextBox(e);

  //nur mit setTimeout geht nicht.
  let interval = setInterval(function () {
    if (!document.activeElement?.classList.contains("dx-texteditor-input")) {
      e.component.focus();
    };
  }, 5);
  let timer = setTimeout(function () {
    clearInterval(interval);
    clearTimeout(timer);
  }, 1000);

}

const PopupZugriffVerweigert = (data: any) => {
  const memo_options_abbrechen = useMemo(() => {
    return {
      text: 'Abbrechen',
      onClick: data.handleHidePopup,
    }
  }, [data.handleHidePopup]);

  return (
    <Popup
      visible={data.showPopup}
      onHiding={data.handleHidePopup}
      dragEnabled={true}
      hideOnOutsideClick={true}
      fullScreen={false}
      showCloseButton={true}
      height='auto'
      maxHeight="70vh"
      maxWidth="500px"
    > <div className="alert alert-danger">Der Zugriff wurde verweigert</div>
      <ToolbarItem
        toolbar="top"
        location={'before'}
        render={RenderePopupTitle}
      />
      <ToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="after"
        options={memo_options_abbrechen}
      />  </Popup>
  )
}

const RenderePopupTitle = () => {
  return (<h2 className="title_popup">Fehler</h2>)
};