import { memo, useCallback, useEffect, useRef, useState } from "react";
import { DataGrid } from "devextreme-react";
import { Column, Toolbar, Item as ItemGridToolbar, Button as ButtonGrid } from "devextreme-react/data-grid";
import { konfigurator_columns } from "../../../sonstiges/columns";
import './index.scss'
import KonfiguratorEintragAdd from "./add";
import KonfiguratorEintragVerwaltePopup from "./add_bearbeite_konfigurator_eintrag";
import { IKonfiguratorEintrag } from "../interfaces";
import { GetData } from "../../../global/communication";
import { setAlert, setConfirm, setKonfiguratorBearbeitet, setToast, useAppDispatch } from "../../../state";
import UmbennenPopup from "./umbennen_popup";
import { Computer, TreeViewNodeKonfigurator } from ".";
import { MDButton } from "../../../global/components";
import { icon_copy, icon_delete, icon_edit, icon_i_cursor, icon_paste, scroll_mode_virtual } from "../../../global/const";
import { handleContentReadyDataGrid } from "../../../global/hilfs_functions";

export interface IKonfiguratorGridProps {
    node: TreeViewNodeKonfigurator,
    setSelectedItem: (item: TreeViewNodeKonfigurator | undefined) => void,
    allComputer: Computer[],
    get_data_all_Computer: () => void,
    SearchValue: string,
}

const paging_enabled = { enabled: false };
const columns = konfigurator_columns;
const KonfiguratorGrid: React.FC<IKonfiguratorGridProps> = ({ get_data_all_Computer, allComputer, node, setSelectedItem, SearchValue }) => {
    const [data, setData] = useState<IKonfiguratorEintrag[]>([]);
    const [isloading, setIsloading] = useState(true);
    const dispatch = useAppDispatch();
    const refGrid = useRef() as React.MutableRefObject<DataGrid<any, any>>;
    const [einfuegen, setEinfuegen] = useState<{ show: boolean, row: IKonfiguratorEintrag | undefined }>({
        show: false,
        row: undefined,
    });

    const [bearbeitePopUp, setBearbeitePopup] = useState<{ show: boolean, row: IKonfiguratorEintrag | undefined, einfuegen: boolean }>({
        show: false,
        row: undefined,
        einfuegen: false,
    });
    const [schluesselUmbennenPopUp, setSchluesselUmbennenPopUp] = useState<{ show: boolean, row: IKonfiguratorEintrag | undefined }>({
        show: false,
        row: undefined,
    });

    useEffect(() => {
        get_data();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [node, dispatch])

    const get_data = useCallback(() => {
        setIsloading(true);
        GetData({
            Operation: "GetBereichWerte",
            Computername: node.parentText,
            Bereich: node.text,
            SearchValue: SearchValue,
        }, dispatch).then((res) => {
            if (!res) {
                setData([]);
                setIsloading(false);
                return
            }
            setData(res.data);
            setIsloading(false);
        })
    }, [node, dispatch, SearchValue]);


    const get_methode = useCallback((row: IKonfiguratorEintrag) => {
        let items =
            [{
                text: "Eintrag einfügen",
                onItemClick: () => {
                    setBearbeitePopup({ show: true, row: einfuegen.row, einfuegen: true });
                    setEinfuegen({ show: false, row: undefined });
                },
                visible: false,
                icon: icon_paste
            },
            {
                text: "Eintrag kopieren",
                onItemClick: () => {
                    setEinfuegen({ show: true, row: row });
                },
                icon: icon_copy
            },
            {
                text: "Eintrag bearbeiten",
                onItemClick: () => {
                    setBearbeitePopup({ row: row, show: true, einfuegen: false });
                }, icon: icon_edit
            },
            {
                text: "Eintrag löschen",
                onItemClick: () => {
                    delete_konfigurator_eintrag_2(dispatch, row, data, setData);
                }, icon: icon_delete
            },
            {
                text: "Schlüssel umbennen",
                onItemClick: (e: any) => {
                    setSchluesselUmbennenPopUp({ show: true, row: row });
                }, icon: icon_i_cursor
            },
            ];
        einfuegen.show && (items[0].visible = true);

        // [...contextMenuItems][0].onItemClick=() => {

        //}
        return items;
    }, [einfuegen, data, dispatch]);
    /*
        const handleOKDelete = useCallback((row_new?: IKonfiguratorEintrag) => {
            GetData({
                Operation: "DeleteKonfiguration",
                Computername: (row_new && row_new.f001) ? row_new.f001 : confirmLoeschen.row?.f001,
                Bereich: (row_new && row_new.f002) ? row_new.f002 : confirmLoeschen.row?.f002,
                Schluessel: (row_new && row_new.f003) ? row_new.f003 : confirmLoeschen.row?.f003
            })
                .then((res) => {
                    if (!res || !res.erfolg) {
                        dispatch(setAlert({
                            text: (res && res.hinweis) ? res.hinweis : "Es ist ein Fehler aufgetreten. Die Änderungen konnten nicht vorgenommen werden.",
                            type: "Fehler",
                        }))
                        return
                    }
                    dispatch(setToast({
                        message: "Der Eintrag wurde erfolgreich gelöscht",
                        type: "success",
                        position: "bottom center"
                    }));
    
                    if (confirmLoeschen.row) {
                        let new_data = [...data];
                        new_data.splice(new_data.indexOf(confirmLoeschen.row), 1);
                        setData(new_data);
                    }
                    if (confirmLoeschen.row) setConfirmLoeschen({ show: false, row: undefined })
                    //MD new 12.01.2023
                    dispatch(setKonfiguratorBearbeitet(true));
    
                });
    
    
        }, [confirmLoeschen, dispatch, data]);
    */
    const handleCallBack = useCallback((row_new?: IKonfiguratorEintrag, delete_row?: boolean) => {
        if (bearbeitePopUp.einfuegen) {
            get_data();
        } else {
            //Hier ist bearbeiten
            if (row_new) {
                let data_new = [...data];
                if (delete_row) {
                    //Eintrag löschen
                    delete_eintrag_anfrage(row_new, dispatch, data, setData)
                    //    data_new.splice(data_new.indexOf(row_new), 1); in delete_eintrag_anfrage gemacht
                } else {
                    for (let i of data_new) {
                        if (i.f003 === bearbeitePopUp.row?.f003) {
                            i.f004 = row_new!.f004;
                            i.f005 = row_new!.f005;
                            break;
                        }
                    }
                    setData(data_new);
                }

            }
        }
        // refGrid.current.instance.focus(refGrid.current.instance.getRowElement(refGrid.current.instance.getRowIndexByKey(bearbeitePopUp.row!.f003))![0]);
        setBearbeitePopup({ show: false, row: undefined, einfuegen: false })
    }, [bearbeitePopUp, data, get_data, dispatch]);

    const handleClickEintragBearbeiten = useCallback((e: any) => {
        e.event.stopPropagation();
        setBearbeitePopup({ show: true, row: JSON.parse(e.element.getAttribute("data")), einfuegen: false })
    }, []);

    const handleSchluesselPopup = useCallback((new_value: any) => {
        if (schluesselUmbennenPopUp.row && new_value) {
            let data_new = data;
            for (let i of data_new) {
                if (i.f003 === schluesselUmbennenPopUp.row?.f003) {
                    i.f003 = new_value;
                    break;
                }
            }
        }
        setSchluesselUmbennenPopUp({ show: false, row: undefined })
    }, [schluesselUmbennenPopUp.row, data]);

    const handleContextMenu = useCallback((e: any) => {
        e.items = get_methode(e.row.data)
    }, [get_methode]);


    const handleAddToData = useCallback((row: IKonfiguratorEintrag) => {
        setData(data.concat([row]));
    }, [data]);

    const handleSchluesselUmbennenClick = useCallback((e: any) => {
        setSchluesselUmbennenPopUp({ show: true, row: JSON.parse(e.element.getAttribute("data")) })
    }, []);

    const RenderButtonSchuesselUmbennen = useCallback((e: any) => {
        return (
            <MDButton
                page='konfigurator'
                tooltip_text='Schlüssel umbennen'
                icon={icon_i_cursor}
                onClick={handleSchluesselUmbennenClick}
                data={e.data}
            />)
    }, [handleSchluesselUmbennenClick]);

    const RenderButtonEintragBearbeiten = useCallback((e: any) => {
        return (
            <MDButton
                page='konfigurator'
                tooltip_text='Eintrag bearbeiten'
                icon={icon_edit}
                className="mr-2"
                onClick={handleClickEintragBearbeiten}
                data={e.data}
            />)
    }, [handleClickEintragBearbeiten]);

    const handleCellClick = useCallback((e: any) => {
        if (e.column.dataField === "f003") {
            // Schlüsselgeklickt
            setSchluesselUmbennenPopUp({ show: true, row: e.data })
        } else {
            setBearbeitePopup({ show: true, row: e.data, einfuegen: false })
        }
    }, []);
    return (
        <>
            <div className="konfigurator_grid_container">
                {schluesselUmbennenPopUp.show && <UmbennenPopup art="Schlüssel" row={schluesselUmbennenPopUp.row!} setShowPopUp={handleSchluesselPopup} />}
                {bearbeitePopUp.show && <KonfiguratorEintragVerwaltePopup allComputer={allComputer} einfuegen_computer={false} einfuegen={bearbeitePopUp.einfuegen} row={bearbeitePopUp.row} callback={handleCallBack} />}
                {!isloading &&
                    //MD 02.12.2022 mache ich erstmal weg dann schau ma mal  (!schluesselUmbennenPopUp.show && !bearbeitePopUp.show) &&
                    <DataGrid
                        dataSource={data}
                        allowColumnReordering={true}
                        allowColumnResizing={true}
                        columnAutoWidth={false}
                        renderAsync={true}
                        showBorders={true}
                        keyExpr="f003"
                        noDataText="Keine Einträge"
                        rowAlternationEnabled={true}
                        ref={refGrid}
                        wordWrapEnabled={true}
                        onContextMenuPreparing={handleContextMenu}
                        onCellClick={handleCellClick}
                        height={"auto"}
                        //scrolling={scroll_mode_infinite}
                        scrolling={scroll_mode_virtual}
                        className="cursor_pointer"
                        paging={paging_enabled}
                        onContentReady={handleContentReadyDataGrid}
                    >
                        {columns.map((column) => {
                            return (
                                <Column
                                    dataField={column.dataField}
                                    caption={column.caption}
                                    dataType={column.dataType}
                                    alignment={column.alignment}
                                    cellRender={column.cellRender}
                                    key={column.dataField}
                                    visible={column.visible}
                                />
                            )
                        })}
                        <Column type="buttons">
                            <ButtonGrid
                                cssClass={'d-inline-block'}
                                render={RenderButtonSchuesselUmbennen} />
                            <ButtonGrid
                                cssClass={'d-inline-block'}
                                render={RenderButtonEintragBearbeiten} />
                        </Column>
                        <Toolbar>
                            <ItemGridToolbar location="after" locateInMenu="auto">
                                <KonfiguratorEintragAdd get_data_all_Computer={get_data_all_Computer} allComputer={allComputer} node={node} setSelectedItem={setSelectedItem} addToData={handleAddToData} />
                            </ItemGridToolbar>
                        </Toolbar>
                    </DataGrid>

                }
            </div>
        </>

    )
}
export default memo(KonfiguratorGrid);


const delete_eintrag_anfrage = (data: IKonfiguratorEintrag, dispatch: any, data_all: any, setData: any) => {
    GetData({
        Operation: "DeleteKonfiguration",
        Computername: data.f001,
        Bereich: data.f002,
        Schluessel: data.f003
    }, dispatch)
        .then((res) => {
            if (!res) return
            dispatch(setToast({
                message: "Der Eintrag wurde erfolgreich gelöscht",
                type: "success",
                position: "bottom center"
            }));

            let new_data = [...data_all];
            new_data.splice(new_data.indexOf(data), 1);
            setData(new_data);
            //MD new 12.01.2023
            dispatch(setKonfiguratorBearbeitet(true));

        });
}

const delete_konfigurator_eintrag_2 = (dispatch: any, data: IKonfiguratorEintrag, data_all: any, setData: any) => {
    dispatch(setConfirm({
        body:
            <div className="alert alert-danger">
                <p>Wollen sie den Eintrag <b>{data.f003}</b> wirklich löschen?</p>
            </div>,
        on_ok: () => {
            delete_eintrag_anfrage(data, dispatch, data_all, setData)
        },
        title: "Eintrag löschen",
    }));
}