
import { DataGrid, TextBox } from "devextreme-react";
import { Column, Toolbar, Item as ItemGridToolbar, Button as ButtonGrid, Scrolling, Paging } from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ISelectedItemStrassen } from "..";
import { GetData } from "../../../../global/communication";
import { strasse_detail_columns } from "../../../../sonstiges/columns";
import { icon_edit, icon_pdf, MAX_ITEMS_DATA_GRID } from "../../../../global/const";
import { exportGridAsPDF, get_search_as_filter, handleContentReadyDataGrid, handleContentReadyTextBox } from "../../../../global/hilfs_functions";
import { MDButton, RendereGridTitle } from "../../../../global/components";
import { IStrasseDetail } from "../interfaces";
import StrassenExport from "../strassen_export";
import StrassenUpload from "../strassen_import";
import StrasseDetailAdd from "./add";

interface IStrassenDetailsGridProps {
    selectedItem: ISelectedItemStrassen,
    setBearbeitePopup: (value: { show: boolean, row: IStrasseDetail | null }) => void,
    refresh_grid: boolean,
}


const columns = strasse_detail_columns();

const StrasseDetailsGrid: React.FC<IStrassenDetailsGridProps> = ({ refresh_grid, selectedItem, setBearbeitePopup }) => {
    const refGrid = useRef() as React.MutableRefObject<DataGrid<any, any>>;
    const [searchValue, setSearchValue] = useState("");
    const filter = useMemo(() => get_search_as_filter(searchValue), [searchValue]);
    const store_end = useMemo(() => store(selectedItem.lfdnr_strasse),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [selectedItem.lfdnr_strasse, refresh_grid]);
    const get_data = useCallback(() => {
        //setSearchValue("");
        //refGrid && refGrid.current && refGrid.current.instance.clearSorting();
        refGrid && refGrid.current && refGrid.current.instance.refresh();
    }, []);

    useEffect(() => {
        refGrid && refGrid.current && refGrid.current.instance.filter(filter);
    }, [filter]);

    const handleBearbeite = useCallback((e: any) => {
        setBearbeitePopup({ show: true, row: JSON.parse(e.element.getAttribute("data")) })
    }, [setBearbeitePopup]);

    const handlePDF = useCallback(() => {
        let header = get_header_pdf_strasse_details(searchValue, selectedItem.bezeichnung_ort || "", selectedItem.bezeichnung_bezirk || "", selectedItem.bezeichnung_strasse || "");
        exportGridAsPDF(refGrid, "Straßendetail.pdf", header)
    }, [searchValue, selectedItem]);


    const RendereTitle = useCallback(() => {
        return RendereGridTitle(selectedItem.bezeichnung_ort + ' ' + selectedItem.bezeichnung_bezirk + ' ' + selectedItem.bezeichnung_strasse)
    }, [selectedItem]);

    const RenderButtonBearbeite = useCallback((e: any) => {
        return (<MDButton
            page='strassen_strassen_details'
            tooltip_text='Straßendetail bearbeiten'
            icon={icon_edit}
            onClick={handleBearbeite}
            data={e.data}
        />)
    }, [handleBearbeite]);

    return (
        <div className="data_grid_container  data_grid_with_header mt-2 mt-md-3">
            <DataGrid
                dataSource={store_end}
                columnAutoWidth={false}
                // keyExpr="lfdnr"
                wordWrapEnabled={true}
                renderAsync={true}
                noDataText="Keine Straßendetails"
                className=" animate__animated animate__fadeIn"
                remoteOperations={true}
                rowAlternationEnabled={true}
                ref={refGrid}
                hoverStateEnabled={true}
                onContentReady={handleContentReadyDataGrid}
            >
                <Scrolling mode="virtual" rowRenderingMode="virtual" preloadEnabled={true} />
                <Paging defaultPageSize={MAX_ITEMS_DATA_GRID} />


                {columns.map((column) => {
                    return (
                        <Column
                            dataField={column.dataField}
                            caption={column.caption}
                            dataType={column.dataType}
                            alignment={column.alignment}
                            cellRender={column.cellRender}
                            key={column.dataField}
                            visible={column.visible}
                        />
                    )
                })}
                <Column type="buttons">
                    <ButtonGrid
                        cssClass={'d-inline-block'}
                        render={RenderButtonBearbeite} />
                </Column>

                <Toolbar>
                    <ItemGridToolbar
                        location={'before'}
                        render={RendereTitle}
                    ></ItemGridToolbar>
                    <ItemGridToolbar
                        location={'after'}
                        locateInMenu="never"
                    >
                        <TextBox
                            label="Suche"
                            stylingMode='outlined'
                            mode='text'
                            className="suche_feld"
                            value={searchValue}
                            onValueChange={setSearchValue}
                            showClearButton={true}
                            maxLength={60}
                            onContentReady={handleContentReadyTextBox}>
                        </TextBox>
                    </ItemGridToolbar>
                    <ItemGridToolbar location="after" locateInMenu="auto">
                        <StrasseDetailAdd selectedItem={selectedItem} get_data={get_data} />
                    </ItemGridToolbar>
                    <ItemGridToolbar location="after" locateInMenu="auto">
                        <StrassenUpload />
                    </ItemGridToolbar>
                    <ItemGridToolbar location="after" locateInMenu="auto">
                        <StrassenExport />
                    </ItemGridToolbar>
                    <ItemGridToolbar location="after" locateInMenu="auto">
                        <MDButton
                            page="strassen_strassendetail"
                            icon={icon_pdf}
                            tooltip_text="Tabelle als PDF herunterladen"
                            onClick={handlePDF}
                        />
                    </ItemGridToolbar>
                </Toolbar>
            </DataGrid>
        </div>
    )
}

export default memo(StrasseDetailsGrid);


const store = (lfdnr_strasse?: number) => {

    if ((!lfdnr_strasse) && lfdnr_strasse !== 0) return []

    return new CustomStore({
        key: ["lfdnr"],
        load(loadOptions: any) {

            //Achtung isLoadingAll => pdf Download
            return GetData(
                {
                    Operation: "GetStrassendetails",
                    LfdNrStrasse: (lfdnr_strasse || lfdnr_strasse === 0) ? lfdnr_strasse.toString() : "",
                    skip: (loadOptions.isLoadingAll || loadOptions.requireTotalCount) ? "0" : loadOptions["skip"].toString(),
                    take: loadOptions["isLoadingAll"] ? "1000" : loadOptions["take"].toString(),
                    filter: loadOptions.filter ? loadOptions.filter : "",
                    sortSpalte: loadOptions.sort ? loadOptions.sort[0].selector : "",
                    sortRichtung: loadOptions.sort ? loadOptions.sort[0].desc ? "DESC" : "ASC" : "",
                }, undefined, false
            )
                .then((res) => {
                    if (!res || !res.erfolg)
                        return {
                            data: [],
                            totalCount: 0,
                        }

                    //  if(totalCount === 0){
                    //    setTotalCount(res.totalCount)
                    //  }else{
                    //  totalCount !== parseInt(res.totalCount) && setTotalCount(res.totalCount)
                    // }
                    return {
                        data: res.data,
                        totalCount: res.totalCount,
                    }

                });
            //   }


            //  }
        }

    });
}


const get_header_pdf_strasse_details = (searchValue: string, ort: string, bezirk: string, strasse: string) => {
    let header = "";
    searchValue && (header += "Suchbegriff: " + searchValue + "\n");
    header += "Ort: " + ort + "\n";
    header += "Bezirk: " + bezirk + "\n";
    header += "Straße: " + strasse;
    return header
}
