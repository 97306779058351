import { FileUploader } from 'devextreme-react';
import { useCallback, useEffect, useRef, useState } from "react";
import { GetData } from '../../../../global/communication';
import { datei_bereit_zum_hochladen, icon_delete } from '../../../../global/const';
import { setAlert, setToast, useAppDispatch } from '../../../../state';
import { MDButton } from '../../../../global/components';


interface ISqlSkriptProps {
    geraet_nummer: string,
}
const SqlSkript: React.FC<ISqlSkriptProps> = ({ geraet_nummer }) => {

    const [sqlStatus, setSqlStatus] = useState(false);
    const refForm = useRef() as React.MutableRefObject<HTMLFormElement>;
    const refIFrame = useRef() as React.MutableRefObject<HTMLIFrameElement>;
    const refFileUploader = useRef() as React.MutableRefObject<FileUploader>;
    const [files, setFiles] = useState<any[]>([]);
    const dispatch = useAppDispatch();

    useEffect(() => {
        GetData({
            Operation: "GetSQLSkript",
            Geraetenummer: geraet_nummer,
        }, dispatch)
            .then((res) => {
                if (!res) return
                setSqlStatus(res.status)
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);





    const handleIfram = useCallback((e: any) => {
        if (refIFrame.current.contentDocument?.children[0].children[1].hasChildNodes()) {
            //MD am 10.08.2023 angepasst.
            let result = JSON.parse(refIFrame.current.contentDocument?.children[0].children[1].children[0].innerHTML);
            refFileUploader.current.instance.reset();
            if (result.erfolg) {
                setSqlStatus(true);
                dispatch(setToast({
                    message: "Die Datei wurde erfolgreich importiert.",
                    type: "success",
                }));
            } else {
                dispatch(setAlert({
                    text: result.hinweis ? result.hinweis : "Es ist ein Fehler beim Import aufgetreten.",
                    type: "Fehler",
                    maxWidth: 600
                }));
            }
        }
    }, [refIFrame, dispatch]);

    const handleClickDelete = useCallback(() => {
        GetData({
            Operation: "DeleteSQLSkript",
            Geraetenummer: geraet_nummer,
        }, dispatch)
            .then((res) => {
                if (!res) return

                setSqlStatus(false);
                dispatch(setToast({
                    message: "Die Datei wurde erfolgreich gelöscht.",
                    type: "success",
                    position: "bottom center"
                }));
            });
    }, [geraet_nummer, dispatch]);


    const handleHochladen = useCallback(() => {
        if (files.length > 0) {
            if (refForm.current.querySelector(".dx-fileuploader-file-status-message")?.innerHTML === datei_bereit_zum_hochladen) {
                refForm.current.submit()
            }
        } else {
            dispatch(setToast({
                message: "Wählen Sie bitte eine Datei aus.",
                type: "error",
            }))
        }
    }, [files, refForm, dispatch]);

    return (
        <div className="row p-3 p-md-5 ">
            <div className='col-12 d-flex justify-content-start align-items-center'>
                <p>SQL-Skript:&nbsp;&nbsp;<strong>{sqlStatus ? "vorhanden" : "nicht vorhanden"}</strong></p>
                {sqlStatus &&
                    <MDButton
                        page='geraete_sql_skript'
                        icon={icon_delete}
                        tooltip_text='SQL Skript löschen'
                        type='danger'
                        onClick={handleClickDelete}
                        className='ml-3 ml-md-5'
                    />}
            </div>
            <div className='col-12'>
                <form className='mt-3 mt-md-4' ref={refForm} method="post" target="iframe_sql_skripts" action="/" encType="multipart/form-data">
                    <input type="hidden" name="Operation" value="ImportSQLSkript" />
                    <input type="hidden" name="Geraetenummer" value={geraet_nummer} />
                    <FileUploader
                        multiple={false}
                        accept={"text/plain"}
                        uploadMode={"useForm"}
                        uploadUrl="/"
                        labelText=""
                        ref={refFileUploader}
                        selectButtonText="Datei ..."
                        name="Dateiname"
                        readyToUploadMessage={datei_bereit_zum_hochladen}
                        onValueChange={setFiles}
                    //onValueChanged={this.onSelectedFilesChanged} 
                    />
                    <MDButton
                        button_text='Hochladen'
                        stylingMode='outlined'
                        page='geraete_sqlskript'
                        onClick={handleHochladen}
                        className='mt-2 mt-md-4'
                    />
                </form>
                <iframe title="iframe_sql_skripts" name="iframe_sql_skripts" ref={refIFrame} onLoad={handleIfram} className="d-none"></iframe>

            </div>
        </div>
    )
}


export default SqlSkript;

