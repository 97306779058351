import React, { useCallback, useState } from 'react';
import Popup from 'devextreme-react/popup';
import ScrollView from 'devextreme-react/scroll-view';
import TextBox from 'devextreme-react/text-box';
import DateBox from 'devextreme-react/date-box';
import { ToolbarItem } from 'devextreme-react/autocomplete';
import '../STAT.scss'
import { IStatistikIndividuellAuswahl, IStatistikIndividuellFilterData } from '../interfaces';
import { get_date_ajax_param, handleContentReadyDateBox, handleContentReadyTextBox } from "../../../global/hilfs_functions";
import { useScreenSize } from '../../../global/media-query';
import { Validator } from 'devextreme-react';
import { NumericRule, RequiredRule } from 'devextreme-react/data-grid';
import { useMemo } from 'react';


interface IStatistikDialogProps {
  onOkClick: (daten: string, datenfelder_for_pdf: string) => void,
  onCloseClick: any,
  item: IStatistikIndividuellAuswahl,
}

const dilim = '#§@';

const Statistik_Individuell_Filter: React.FC<IStatistikDialogProps> = ({ onOkClick, onCloseClick, item }): JSX.Element => {

  const [data, setData] = useState<IStatistikIndividuellFilterData[]>(get_data(item));
  const screenSize = useScreenSize();

  const onAuswertungClick = useCallback((e: any) => {
    let result = e.validationGroup.validate();
    if (!result.isValid) return;
    const get_dateDB = (date: string) => {
      let arr = date.split('.');
      return arr[2] + '-' + arr[1] + '-' + arr[0]
    }

    let tmp = data;
    for (let i of tmp) {
      if (i.datentyp.toUpperCase() === 'DATE')
        i.wert = get_dateDB(i.wert);
    }


    let datenfelder_for_pdf = "";
    for (let i = 0; i < tmp.length; i++) {
      if (datenfelder_for_pdf !== "")
        datenfelder_for_pdf = datenfelder_for_pdf + ',';

      datenfelder_for_pdf += " " + tmp[i].bezeichner + " " + tmp[i].wert;
    }
    datenfelder_for_pdf += '\n';

    let daten = "<datenfelder>"
    for (let i = 0; i < tmp.length; i++) {
      daten += "<datenfeld><orginal>" + tmp[i].orginal + "</orginal><datentyp>" + tmp[i].datentyp + "</datentyp><bezeichner>" + tmp[i].bezeichner + "</bezeichner><wert>" + tmp[i].wert + "</wert></datenfeld>";
    }
    daten += "</datenfelder>";


    onOkClick(daten, datenfelder_for_pdf);
  }, [data, onOkClick]);

  const onTextBoxValueChanged = useCallback((e: any, ItemID: string, datenTyp: string) => {
    //die MEthode hier wird aufgerufen, wenn man den Datepicker aufmacht. Bleibtnoch unklar warum
    if (datenTyp.toUpperCase() === 'DATE' && !e) return
    // wenn man den Datepicker nicht öffnet sondern nur das Datum eingibt, wird e = jahr/monat/tag so übergeben
    if (datenTyp.toUpperCase() === 'DATE' && typeof e !== 'object' && e.split("/").length === 3)
      e = new Date(e);

    let tmpArray = [...data];
    for (let i in tmpArray) {
      if (tmpArray[i].orginal === ItemID) {
        let tmp = { ...tmpArray[i] };
        if (datenTyp.toUpperCase() === 'DATE') {
          tmp.wert = e ? get_date_ajax_param(e) : "";
        } else if (datenTyp.toUpperCase() === 'FLOAT') {
          tmp.wert = e ? e.replace(",", '.') : "";
        } else if (datenTyp.toUpperCase() === 'INTEGER') {
          // (tmp.wert = (e.indexOf('.') > 0 || e.indexOf(",") > 0) ? "" : e);
          tmp.wert = e;
        } else {
          tmp.wert = e ? e : "";
        }
        tmpArray[i] = tmp;
        setData(tmpArray)
      }
    }
  }, [data]);

  const handleChangeValue = useCallback((e: any) => {
    onTextBoxValueChanged(e.value, e.element.id.split(dilim)[0], e.element.id.split(dilim)[1]);
  }, [onTextBoxValueChanged]);

  const memo_options_auswerten = useMemo(() => {
    return {
      text: 'Auswerten',
      stylingMode: "outlined",
      onClick: onAuswertungClick,
    }
  }, [onAuswertungClick]);
  const memo_options_abbrechen = useMemo(() => {
    return {
      text: 'Abbrechen',
      stylingMode: "outlined",
      onClick: onCloseClick,
    }
  }, [onCloseClick]);

  return (
    <Popup
      visible={true}
      hideOnOutsideClick={true}
      showCloseButton={true}
      onHiding={onCloseClick}
      height="auto"
      maxHeight="75vh"
      maxWidth={(screenSize.isMedium || screenSize.isSmall) ? "90vw" : "500px"}
    >
      <ToolbarItem
        toolbar="top"
        location={'before'}
        render={RenderePopupTitle}
      />
      <ScrollView>
        <div className="row align-items-center">
          {(!data || data.length === 0) ?
            <div className="col-12 py-1">
              Keine Eingabe benötigt!
            </div>
            :
            data.map((item, i) => {
              return (
                <div key={i} className="col-11 mx-auto py-2">
                  {(item.datentyp.toUpperCase() === "INTEGER") ?
                    <TextBox maxLength={60} label={item.bezeichner + ' (Ganzzahl)'} labelMode='floating' valueChangeEvent="input" stylingMode='outlined' id={item.orginal + dilim + item.datentyp} /*value={item.wert} valueChangeEvent="input" onValueChange={(e)=>onTextBoxValueChanged(e,item.orginal, item.datentyp)} */ onValueChanged={handleChangeValue} onContentReady={handleContentReadyTextBox} >
                      <Validator>
                        <RequiredRule message={item.bezeichner + " darf nicht leer sein"} />
                        <NumericRule message={item.bezeichner + " ist eine Ganzzahl."} />
                      </Validator>
                    </TextBox> :
                    (item.datentyp.toUpperCase() === "FLOAT") ?
                      <TextBox maxLength={60} label={item.bezeichner + ' (Gleitkommazahl)'} labelMode='floating' valueChangeEvent="input" stylingMode='outlined' id={item.orginal + dilim + item.datentyp} /*value={item.wert} valueChangeEvent="input" onValueChange={(e)=>onTextBoxValueChanged(e,item.orginal, item.datentyp)} */ onValueChanged={handleChangeValue} onContentReady={handleContentReadyTextBox} >
                        <Validator>
                          <RequiredRule message={item.bezeichner + " darf nicht leer sein"} />
                          <NumericRule message={item.bezeichner + " ist eine Gleitkommazahl."} />
                        </Validator>
                      </TextBox> :
                      (item.datentyp.toUpperCase() === "DATE") ?
                        <DateBox label={item.bezeichner + ' (Datum)'} labelMode='floating'
                          showClearButton={true}
                          onContentReady={handleContentReadyDateBox}
                          useMaskBehavior={true} stylingMode='outlined' id={item.orginal + dilim + item.datentyp} /* value={item.wert ? get_date_from_string(item.wert) : ""} onValueChanged={(e)=>onTextBoxValueChanged(e.value,item.orginal, item.datentyp)} */ displayFormat={"dd.MM.yyyy"} onValueChanged={handleChangeValue}>
                          <Validator>
                            <RequiredRule message={item.bezeichner + " darf nicht leer sein"} />
                          </Validator>
                        </DateBox>
                        : <TextBox maxLength={512} label={item.bezeichner + ' (Text)'} labelMode='floating' valueChangeEvent="input" stylingMode='outlined' id={item.orginal + dilim + item.datentyp}/* value={item.wert} valueChangeEvent="input" onValueChange={(e)=>onTextBoxValueChanged(e,item.orginal, item.datentyp)}*/ onValueChanged={handleChangeValue} onContentReady={handleContentReadyTextBox} />
                  }

                </div>
              )
            })
          }</div>


      </ScrollView>
      <ToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="after"
        options={memo_options_auswerten}
      />
      <ToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="after"
        options={memo_options_abbrechen}
      />
    </Popup>
  );
}

export default Statistik_Individuell_Filter;


const RenderePopupTitle = () => {
  return (<h2 className="title_popup">Datenfelder - Eingabe</h2>)
};

const get_data = (item: IStatistikIndividuellAuswahl): IStatistikIndividuellFilterData[] => {
  let sql = '';
  item.f081 && (sql += item.f081);
  item.f082 && (sql += ' ' + item.f082);
  return get_eingabe_liste_for_statistik_individuell(sql);
}


const get_eingabe_liste_for_statistik_individuell = (sql: string): IStatistikIndividuellFilterData[] => {

  var eingabeliste = [];
  var regEx = /{\?[ ]*(.*?)[ ]+AS[ ]+([^ ]*)[ ]*}/gi; // /{\?([^ ]*) AS ([^ ]*)}/gi; // Suche nach {? xxx AS INTEGER} // i = case insensivtive ? = nur minimal soviele Zeichen [^ ] = ohne Leerzeichen
  var match: any;
  while ((match = regEx.exec(sql)) != null) {
    var bezeichner = match[1].trim();
    var datentyp = match[2].trim();
    if (!bezeichner || !datentyp) {
      // Fehler
      return [];
    }
    bezeichner = bezeichner.trim();
    datentyp = datentyp.trim();

    var vorhanden = false;
    for (var i = 0; i < eingabeliste.length; i++) {
      if (eingabeliste[i].orginal === match[0]) {
        vorhanden = true;
        break;
      }
    }

    if (!vorhanden)
      eingabeliste.push({ orginal: match[0], bezeichner: bezeichner, datentyp: datentyp, wert: "" });
  }

  regEx = /{\?[ ]*(.*?)[ ]*}/gi; // /{\?[ ]*([^ ]*)[ ]*}/gi; // Suche nach {? xxx }
  match = "";
  while ((match = regEx.exec(sql)) != null) {
    var bezeichner_1 = match[1].trim();
    if (!bezeichner_1) {
      // Fehler
      return [];
    }
    bezeichner_1 = bezeichner_1.trim();

    var vorhanden_1 = false;
    for (var k = 0; k < eingabeliste.length; k++) {
      if (eingabeliste[k].orginal === match[0]) {
        vorhanden_1 = true;
        break;
      }
    }

    if (!vorhanden_1)
      eingabeliste.push({ orginal: match[0], bezeichner: bezeichner_1, datentyp: "string", wert: "" });
  }

  return eingabeliste;
}