import { memo } from "react";
import { icon_check } from "../../../../../global/const";
import { MDButton } from "../../../../../global/components";
import { IFallData, IFallDataZusaetzlich } from "../../../interfaces";


interface IExportiertProps {
  item: IFallData,
  itemZus: IFallDataZusaetzlich,
}
const Exportiert: React.FC<IExportiertProps> = ({ item, itemZus }) => {

  //   const geloescht = item.f006 >= 2;
  const text = "Exportiert um " + itemZus.exportUhrZeit + " Uhr am " + item.f110;

  /*** Nur wenn exportiert. Wenn gelöscht soll nichts angezeigt werden. Text soll dann beim Rüberfahren auf das KAssenzeichen angezeigt werden. */
  return (
    itemZus.exportiert ?
      <li>
        <MDButton
          page="fall_card"
          tooltip_text={text}
          icon={icon_check}
          className="icon_check"
          tabIndex={-1}
        />
      </li> : <></>
  );
}
export default memo(Exportiert);

/**
 * 
 * 
 * 
 * 
 *  geloescht ?
            <> 
            <i className="dx-icon dx-icon-close"
            ref={refIcon} 
            onMouseEnter={() => setTooltipVisibleIcon(true)} 
            onMouseLeave={() => setTooltipVisibleIcon(false)} 
            ></i>
            <Tooltip
                target={refIcon.current!}
                visible={tooltipVisibleIcon}
                closeOnOutsideClick={false}
                maxWidth={'450px'}
                > 
                <div className="text-left">
                    <p>{itemZus.loesch_text}</p> <br /> <br />
                    {item.f010.Grund && <p><strong>Grund:</strong><br />{item.f010.Grund}</p>}
                </div>
                </Tooltip>
                </>:
            <></>
 */