import { Popup, ScrollView } from "devextreme-react"
import { ToolbarItem } from "devextreme-react/autocomplete"
import { useCallback } from "react";
import { IDrucklayout } from "../../interfaces";
import { GetData } from "../../../../global/communication";
import { setConfirm, useAppDispatch, useAppSelector } from "../../../../state";
import { useScreenSize } from "../../../../global/media-query";
import { handleErrorImg, stretch0Left } from "../../../../global/hilfs_functions";
import { useMemo } from "react";

interface IBildBearbeitePopupProps {
    setShowPopUp: (value: boolean) => void,
    row: IDrucklayout,
}

const style1 = { maxWidth: "100%", maxHeight: "100%" };
const BildBearbeitePopup: React.FC<IBildBearbeitePopupProps> = ({ setShowPopUp, row }): JSX.Element => {
    const mnr = useAppSelector((state) => state.store_einstellungen.konfiguration.mandantennummer);
    const dispatch = useAppDispatch();
    const screenSize = useScreenSize();

    const handleHidePopup = useCallback(() => {
        setShowPopUp(false)
    }, [setShowPopUp]);

    const handleShowPopupLoeschen = useCallback(() => {
        delete_bild(dispatch, row, handleHidePopup)
    }, [dispatch, row, handleHidePopup]);

    const memo_options_loeschen = useMemo(() => {
        return {
            text: 'Löschen',
            type: "danger",
            stylingMode: "outlined",
            onClick: handleShowPopupLoeschen,
        }
    }, [handleShowPopupLoeschen]);

    const memo_options_abbrechen = useMemo(() => {
        return {
            text: 'Abbrechen',
            onClick: handleHidePopup,
        }
    }, [handleHidePopup]);

    const RenderePopupTitle = useCallback(() => {
        return (<h2 className="title_popup">{row.dateiname}</h2>)
    }, [row.dateiname]);
    return (
        <>
            <Popup
                visible={true}
                onHiding={handleHidePopup}
                dragEnabled={true}
                hideOnOutsideClick={true}
                fullScreen={false}
                showCloseButton={true}
                animation={undefined}
                maxHeight="80vh"
                height="auto"
                width={"auto"}
                minWidth={"300px"}
                maxWidth={(screenSize.isMedium || screenSize.isSmall) ? "90vw" : "1500px"}
            >
                <ToolbarItem
                    toolbar="top"
                    location={'before'}
                    render={RenderePopupTitle}
                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="before"
                    options={memo_options_loeschen}
                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={memo_options_abbrechen}
                />
                <ScrollView>
                    <div className="row p-2 p-md-3">
                        <div className="col-12 p-1 p-md-2 text-center">
                            {<img style={style1} src={"/Files/WoOnline/M" + stretch0Left(mnr.toString(), 3) + "/Printer/" + row.druckermodell + "/" + row.dateiname} alt={"Drucklayoutbild für " + row.druckermodell + ' ' + row.dateiname}
                                onError={handleErrorImg}></img>}
                        </div>
                    </div>
                </ScrollView>

            </Popup>
        </>
    )
}

export default BildBearbeitePopup;


const delete_bild = (dispatch: any, data: any, callback: any) => {
    dispatch(setConfirm({
        body:
            <div className="alert alert-danger">
                <p>Wollen sie das Bild mit dem Dateinamen <b>{data.dateiname}</b> wirklich löschen?</p>
            </div>,
        on_ok: () => {
            GetData({
                Operation: "DeleteDrucklayoutBild",
                Druckermodell: data.druckermodell,
                Dateiname: data.dateiname
            }, dispatch).then((res) => {
                if (!res) return
                callback();
            })
        },
        title: "Bild löschen",
    }));
}