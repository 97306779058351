export class Seperatorliste {
	trenner: string = "";
	zuweisungsoperator: string = "";
	mData: string[][] = [];

	constructor (trenner: string, zuweisungsoperator: string){
		this.trenner = trenner;
		this.zuweisungsoperator = zuweisungsoperator;
	}
		

	
	setValue = (key: string, value: string) => {
		let gefunden = false;
		for (let i = 0; i < this.mData.length; i++) {
			if (this.mData[i][0] === key) {
				this.mData[i][1] = value;
				gefunden = true;
			}
		}
		
		if (!gefunden) {
			this.mData.push([key, value]);
		}
	}
	
	getValue = (key: string): string | null => {
		for (let i = 0; i < this.mData.length; i++) {
			if (this.mData[i][0] === key) {
				return this.mData[i][1];
			}
		}
		
		return null;
	}
	
	removeKey = (key: string) => {
		for (let i = 0; i < this.mData.length; i++) {
			if (this.mData[i][0] === key) {
				this.mData.splice(i, 1);
				return;
			}
		}
	}
	
	getSeperatorliste = (): string => {
		let str: string = "";
		for (let i = 0; i < this.mData.length; i++) {
			if (str !== "")
				str += this.trenner;

			str += this.mData[i][0] + this.zuweisungsoperator + this.mData[i][1];
		}
		//return str;
		return this.trenner + str + this.trenner;
	}
	
	clear = () => {
		this.mData = [];
	}
}