import { memo, useMemo } from "react";
import { icon_handy } from "../../../../../global/const";
import { getHandyparkenElement } from "../../../../../sonstiges/hilfs_functions";
import { MDButton } from "../../../../../global/components";


interface IHandyParken {
    lfdnr: number,
    f010: any,
}
const HandyParken: React.FC<IHandyParken> = ({ lfdnr, f010 }) => {
    const handy_parken_element: JSX.Element | null = useMemo(() => getHandyparkenElement(f010), [f010]);

    return (
        handy_parken_element !== null ?
            <li>
                <MDButton
                    page="fall_card"
                    icon={icon_handy}
                    className="handy_parken"
                    tooltip_text={handy_parken_element}
                /></li> : <> </>
    );
}
export default memo(HandyParken);





