import { Popup } from 'devextreme-react';
import { TextBox } from 'devextreme-react/text-box';
import { ToolbarItem } from 'devextreme-react/autocomplete';
import { useCallback, useMemo, useState } from 'react';
import { Seperatorliste } from '../../../global/seperator_liste';
import { GetData } from '../../../global/communication';
import { setAlert, useAppDispatch } from '../../../state';
import { handleContentReadyTextBox } from "../../../global/hilfs_functions";
import { IVerwahflaecheDaten } from './interfaces';

interface IVerwahrFlaechePopupProps {
    callBack: (update?: boolean, loeschen?: boolean) => void,
    anlegen: boolean,
    data: IVerwahflaecheDaten,
    lfdnr: number,
}

const VerwahrFlaechePopup: React.FC<IVerwahrFlaechePopupProps> = ({ callBack, anlegen, data, lfdnr }) => {
    const dispatch = useAppDispatch();
    const [bezeichnung, setBezeichnung] = useState(anlegen ? "" : data.f001);

    const handleClickSpeichern = useCallback((e: any) => {
        if (!bezeichnung) {
            dispatch(setAlert({
                text: "Bezeichnung muss vorhanden sein.",
                type: "Fehler",
            }));
            return
        }
        if (!anlegen && (bezeichnung === data.f001)) {
            callBack();
            return
        }

        let daten = new Seperatorliste("%00", "%01");
        daten.setValue("Firmennummer", lfdnr?.toString());
        daten.setValue("VerwahrFlaeche", bezeichnung);
        !anlegen && daten.setValue("VerwahrFlaecheAlt", data.f001);

        GetData({
            Operation: anlegen ? "InsertAbschleppVerwahrFlaeche" : "UpdateAbschleppVerwahrFlaeche",
            Daten: daten.getSeperatorliste()
        }, dispatch).then((res) => {
            if (!res) return
            callBack(true);
        })
    }, [lfdnr, bezeichnung, anlegen, callBack, dispatch, data]);


    const handleCallBack = useCallback(() => {
        callBack();
    }, [callBack]);


    const memo_options_abbrechen = useMemo(() => {
        return {
            text: 'Abbrechen',
            onClick: handleCallBack,
        }
    }, [handleCallBack]);
    const handleCallBackLoeschen = useCallback(() => {
        callBack(undefined, true)
    }, [callBack]);

    const memo_options_loeschen = useMemo(() => {
        return {
            text: 'Löschen',
            type: "danger",
            onClick: handleCallBackLoeschen,
        }
    }, [handleCallBackLoeschen]);

    const memo_options_speichern = useMemo(() => {
        return {
            text: 'Speichern',
            onClick: handleClickSpeichern,
        }
    }, [handleClickSpeichern]);

    const RenderePopupTitle = useCallback(() => {
        return (<h2 className="title_popup">{anlegen ? "Neue Verwahrfläche anlegen" : "Verwahrfläche bearbeiten"}</h2>)
    }, [anlegen]);

    return (
        <Popup
            visible={true}
            onHiding={handleCallBack}
            hideOnOutsideClick={true}
            fullScreen={false}
            showCloseButton={true}
            // maxHeight="319px"
            maxWidth="550px"
            height="200px"
        >
            <ToolbarItem
                toolbar="top"
                location={'before'}
                render={RenderePopupTitle}
            />
            {!anlegen &&
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="before"
                    options={memo_options_loeschen}
                />
            }
            <ToolbarItem
                widget="dxButton"
                toolbar="bottom"
                location="after"
                options={memo_options_speichern}
            />
            <ToolbarItem
                widget="dxButton"
                toolbar="bottom"
                location="after"
                options={memo_options_abbrechen}
            />
            <TextBox
                label='Bezeichnung'
                labelMode='floating'
                stylingMode='outlined'
                mode='text'
                showClearButton={false}
                onValueChange={setBezeichnung}
                value={bezeichnung}
                valueChangeEvent="input"
                maxLength={60}
                onContentReady={handleContentReadyTextBox}
            >
            </TextBox>
        </Popup>
    )
}
export default VerwahrFlaechePopup;
