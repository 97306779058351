import { Popup } from "devextreme-react";
import { ToolbarItem } from "devextreme-react/autocomplete";
import { useCallback, useMemo } from "react";



export interface IConfirmProps {
    on_ok?: () => void | undefined,
    ok_text?: string,
    on_abbrechen?: () => void | undefined,
    abbrechen_text?: string,
    on_hiding?: () => void | undefined,
    title: string,
    body: JSX.Element | undefined,
}


const Confirm: React.FC<IConfirmProps> = ({ on_abbrechen, on_hiding, on_ok, abbrechen_text, ok_text, title, body }) => {


    const memo_options_ok = useMemo(() => {
        return {
            text: ok_text ? ok_text : "Ok",
            onClick: on_ok,
        }
    }, [on_ok, ok_text]);


    const memo_options_abbrechen = useMemo(() => {
        return {
            text: abbrechen_text ? abbrechen_text : 'Abbrechen',
            onClick: on_abbrechen,
        }
    }, [on_abbrechen, abbrechen_text]);

    const RenderePopupTitle = useCallback(() => {
        return (<h2 className="title_popup">{title}</h2>)
    }, [title]);

    return (

        <Popup
            visible={true}
            onHiding={on_hiding}
            dragEnabled={true}
            hideOnOutsideClick={true}
            fullScreen={false}
            showCloseButton={true}
            height="auto"
            maxHeight="450px"
            maxWidth="400px"
        >
            <ToolbarItem
                toolbar="top"
                location={'before'}
                render={RenderePopupTitle}
            />
            <ToolbarItem
                widget="dxButton"
                toolbar="bottom"
                location="before"
                options={memo_options_ok}
            />
            <ToolbarItem
                widget="dxButton"
                toolbar="bottom"
                location="after"
                options={memo_options_abbrechen}
            />
            {body}
        </Popup>
    )
}

export default Confirm;