import { useCallback, useState } from "react";
import { ISelectedItemKategorie } from "..";
import HauptGruppeVerwaltePopup from "./verwalten_hauptgruppe";
import { ITatbestandHauptKategorie } from "../../tatbestaende/interfaces";
import { setConfirm, setToast, useAppDispatch } from "../../../../state";
import { GetData } from "../../../../global/communication";
import HauptGruppenGrid from "./haupt_gruppen_grid";


interface IHauptGruppenProps {
    selectedItem: any,
    setSelectedItem: (obj: ISelectedItemKategorie) => void,
}


const HauptGruppen: React.FC<IHauptGruppenProps> = ({ selectedItem, setSelectedItem }) => {
    const [bearbeitePopUp, setBearbeitePopup] = useState<{ show: boolean, row: ITatbestandHauptKategorie | null }>({
        show: false,
        row: null,
    });
    const dispatch = useAppDispatch();
    const [refreshGrid, setRefreshGrid] = useState(true);

    const handleCallBack = useCallback((update?: boolean, loeschen?: boolean) => {
        (loeschen) &&
            delete_hauptgruppe(dispatch, bearbeitePopUp.row, () => setRefreshGrid(prev => !prev))
        setBearbeitePopup({ show: false, row: null })
        update && setRefreshGrid(prev => !prev);
    }, [bearbeitePopUp, dispatch]);

    return (<>
        {bearbeitePopUp.show && <HauptGruppeVerwaltePopup row={bearbeitePopUp.row} callback={handleCallBack} />}
        <HauptGruppenGrid refresh_grid={refreshGrid} selectedItem={selectedItem} setBearbeitePopup={setBearbeitePopup} setSelectedItem={setSelectedItem} />

    </>
    )
}

export default HauptGruppen;

const delete_hauptgruppe = (dispatch: any, data: any, callback: any) => {
    dispatch(setConfirm({
        body:
            <div className="alert alert-danger">
                <p>Wollen sie die Hauptgruppe <b>{data.f003}</b> wirklich löschen?</p>
            </div>,
        on_ok: () => {
            GetData({
                Operation: "DeleteHauptgruppe",
                Hauptgruppennummer: data.lfdnr.toString()
            }, dispatch).then((res) => {
                if (!res) return
                dispatch(setToast({
                    message: "Hauptgruppe wurde erfolgreich gelöscht",
                    type: "success",
                    position: "bottom center"
                }));
                callback();
            });

        },
        title: "Hauptgruppe löschen",
    }));
}