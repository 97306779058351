import { useCallback, useState } from "react";
import ExportBearbeitenPopup from './bearbeite_popup';
import DatenExportBearbeitenGrid from './grid';

const DatenExportBearbeiten: React.FC = () => {
  const [bearbeitePopup, setBearbeitePopup] = useState<{ show: boolean, exportnummer: number }>({
    show: false,
    exportnummer: 0,
  });

  const memo_reset_bearbeite_popup = useCallback(() => {
    setBearbeitePopup({
      show: false,
      exportnummer: 0,
    })
  }, []);

  return (
    <>
      {(bearbeitePopup.show && bearbeitePopup.exportnummer !== 0) &&
        <ExportBearbeitenPopup
          handleClickHidePopup={memo_reset_bearbeite_popup}
          exportnummer={bearbeitePopup.exportnummer}
        />
      }
      <DatenExportBearbeitenGrid
        setBearbeitePopup={setBearbeitePopup}
      />

    </>);
}
export default DatenExportBearbeiten;
