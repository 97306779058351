
import CardHeader from "./card_header";
import CardFooter from "./card_footer";
import { IFallData, IFallDataZusaetzlich } from "../../interfaces";


interface ICardFrontProps {
    item: IFallData,
    itemZus: IFallDataZusaetzlich
}
const CardFront: React.FC<ICardFrontProps> = ({item, itemZus}):JSX.Element => {
    
    return(
        <>
            <CardHeader  item={item} itemZus={itemZus}/>  
            {/** <CardControl item={item} itemZus={itemZus}/>  */}
            <CardFooter item={item} itemZus={itemZus}/>
         </>
        );
}

export default CardFront;
