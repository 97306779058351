import { Popup, ScrollView, SelectBox } from "devextreme-react"
import { Position, ToolbarItem } from "devextreme-react/autocomplete"
import { useCallback, useMemo, useState } from "react";
import { GetData } from "../../../global/communication";
import { icon_delete } from "../../../global/const";
import { handleContentReadySelectBox } from "../../../global/hilfs_functions";
import { useScreenSizeClass } from "../../../global/media-query";
import { setConfirm, setToast, useAppDispatch } from "../../../state";
import { MDButton } from "../../../global/components";


interface IKFZHinweiseLoeschenPopupProps {
    kategorieArray: string[],
    reset_data: () => void,
}

const KFZHinweiseLoeschenPopup: React.FC<IKFZHinweiseLoeschenPopupProps> = ({ reset_data, kategorieArray }): JSX.Element => {
    const screenSize = useScreenSizeClass();
    const isScreenSmall = (screenSize === "sm" || screenSize === "md");
    const [showPopUpLoeschen, setShowPopUpLoeschen] = useState(false);
    const [kategorieValue, setKategorieValue] = useState("");
    const dispatch = useAppDispatch();



    const handleHidePopup = useCallback(() => {
        setShowPopUpLoeschen(false)
    }, []);

    const handleShowPopup = useCallback(() => {
        setShowPopUpLoeschen(true)
    }, []);


    const callbackDelete = useCallback(() => {
        setShowPopUpLoeschen(false)
        reset_data();
    }, [reset_data]);

    const memo_option_loeschen = useMemo(() => {
        return {
            text: 'Löschen',
            type: "danger",
            onClick: () => { delete_kfz_kategorie(dispatch, kategorieValue, callbackDelete) },
        }
    }, [dispatch, kategorieValue, callbackDelete]);

    const memo_option_abbrechen = useMemo(() => {
        return {
            text: 'Abbrechen',
            onClick: handleHidePopup,
        }
    }, [handleHidePopup]);


    return (
        <>
            <div id="kfz_hinweis_loeschen_button">
                <MDButton
                    icon={icon_delete}
                    type="danger"
                    page="kfz_hinweise"
                    tooltip_text="Kategorie löschen"
                    onClick={handleShowPopup}
                />
            </div>
            <Popup
                visible={showPopUpLoeschen}
                onHiding={handleHidePopup}
                dragEnabled={true}
                hideOnOutsideClick={true}
                fullScreen={false}
                showCloseButton={true}
                height='auto'
                maxHeight="750px"
                maxWidth="500px"
            >
                <ToolbarItem
                    toolbar="top"
                    location={'before'}
                    render={RenderePopupTitle}
                />

                <Position
                    at={isScreenSmall ? "center" : "right"}
                    my={isScreenSmall ? "center" : "right top"}
                    of={isScreenSmall ? window : '#kfz_hinweis_loeschen_button'}
                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="before"
                    options={memo_option_loeschen}
                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={memo_option_abbrechen}
                />
                <ScrollView>
                    <div className='row p-2 p-md-3'>
                        <div className="col-12 p-1 p-md-2">
                            <SelectBox
                                dataSource={kategorieArray}
                                value={kategorieValue}
                                onValueChange={setKategorieValue}
                                label='Kategorie'
                                labelMode='floating'
                                stylingMode='outlined'
                                onContentReady={handleContentReadySelectBox}
                            />
                        </div>
                        <div className="col-12 p-1 p-md-2">
                            <p className=" alert alert-danger"><strong>Beim Löschen einer Kategorie werden auch alle KFZ-Hinweise innerhalb dieser Kategorie gelöscht.</strong></p>
                        </div>
                    </div>
                </ScrollView>
            </Popup>
        </>
    )
}

export default KFZHinweiseLoeschenPopup


const RenderePopupTitle = () => {
    return (<h2 className="title_popup">KFZ Hinweis Kategorie löschen</h2>)
};

const delete_kfz_kategorie = (dispatch: any, kategorie: any, reset_data: any) => {
    dispatch(setConfirm({
        body:
            <div className="alert alert-danger">
                <p><strong>Wollen Sie wirklich alle Hinweise der Kategorie <b>{kategorie}</b> löschen?</strong></p>
            </div>,
        on_ok: () => {
            GetData({
                Operation: "DeleteKfzHinweisKategorie",
                Kategorie: kategorie,
            }, dispatch).then((res) => {
                if (!res) return

                dispatch(setToast({
                    message: "Kategorie wurde erfolgreich gelöscht",
                    type: "success"
                }));
                reset_data();

            });
        },
        title: "KFZ Hinweis Kategorie löschen",
    }));
}