import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { DataGrid, TextBox } from "devextreme-react";
import { Column, Toolbar, Item as ItemGridToolbar } from "devextreme-react/data-grid";
import { tatbestand_hinweise_columns } from "../../../sonstiges/columns";
import TatbestandHinweisVerwaltePopup from "./verwalten_tatbestandhinweis";
import TatbestandHinweisAdd from "./add";
import { exportGridAsPDF, get_header_pdf_search, get_search_as_filter, handleContentReadyDataGrid, handleContentReadyTextBox } from "../../../global/hilfs_functions";
import { ITatbestandHinweis } from "./interfaces";
import { GetData } from "../../../global/communication";
import { useAppDispatch } from "../../../state";
import { MDButton } from "../../../global/components";
import { icon_pdf, scroll_mode_infinite } from "../../../global/const";


const columns = tatbestand_hinweise_columns();
const TatbestandHinweise: React.FC = () => {
    const [data, setData] = useState<ITatbestandHinweis[]>([]);
    const refGrid = useRef() as React.MutableRefObject<DataGrid<any, any>>;
    const [bearbeitePopUp, setBearbeitePopup] = useState<{ show: boolean, row: ITatbestandHinweis | null }>({
        show: false,
        row: null,
    });
    const [searchValue, setSearchValue] = useState("");
    const filter = useMemo(() => get_search_as_filter(searchValue), [searchValue]);
    const dispatch = useAppDispatch();

    useEffect(() => {
        get_data();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter]);

    const get_data = useCallback(() => {
        GetData({
            Operation: "GetTatbestandshinweise",
            filter: filter,
        }, dispatch).then((res) => {
            if (!res) {
                setData([])
                return
            }
            setData(res.data)
        });
    }, [filter, dispatch]);

    const handleBearbeiteCloase = useCallback((update?: boolean) => {
        setBearbeitePopup({ show: false, row: null })
        update && get_data();
    }, [get_data])

    const handleRowCLick = useCallback(({ data }: any) => {
        setBearbeitePopup({ show: true, row: data })
    }, [])

    const handlePDF = useCallback(() => {
        let header = get_header_pdf_search(searchValue);
        exportGridAsPDF(refGrid, "Tatbestandshinweise.pdf", header)
    }, [searchValue]);

    return (
        <div className="data_grid_container  mt-2 mt-md-3">
            {bearbeitePopUp.show && <TatbestandHinweisVerwaltePopup row={bearbeitePopUp.row} callback={handleBearbeiteCloase} />}

            <DataGrid
                dataSource={data}
                columnAutoWidth={true}
                renderAsync={true}
                keyExpr="f001"
                ref={refGrid}
                scrolling={scroll_mode_infinite}
                wordWrapEnabled={true}
                rowAlternationEnabled={true}
                onRowClick={handleRowCLick}
                className="cursor_pointer"
                hoverStateEnabled={true}
                noDataText="Keine Tatbestandhinweise"
                onContentReady={handleContentReadyDataGrid}
            >
                {columns.map((column) => {
                    return (
                        <Column
                            dataField={column.dataField}
                            caption={column.caption}
                            dataType={column.dataType}
                            alignment={column.alignment}
                            cellRender={column.cellRender}
                            key={column.dataField}
                            visible={column.visible}
                        />
                    )
                })}
                <Toolbar>
                    <ItemGridToolbar
                        location={'after'}
                        locateInMenu="never"
                    >
                        <TextBox
                            label="Suche"
                            stylingMode='outlined'
                            mode='text'
                            className="suche_feld"
                            showClearButton={true}
                            value={searchValue}
                            onValueChange={setSearchValue}
                            maxLength={60}
                            onContentReady={handleContentReadyTextBox}>
                        </TextBox>
                    </ItemGridToolbar>
                    <ItemGridToolbar location="after" locateInMenu="auto">
                        <TatbestandHinweisAdd get_data={get_data} />
                    </ItemGridToolbar>
                    <ItemGridToolbar location="after" locateInMenu="auto">
                        <MDButton
                            page="tatbestand_hinweise"
                            icon={icon_pdf}
                            tooltip_text="Tabelle als PDF herunterladen"
                            onClick={handlePDF}

                        />
                    </ItemGridToolbar>
                </Toolbar>
            </DataGrid>
        </div>
    )
}

export default TatbestandHinweise;