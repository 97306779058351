import { memo, useMemo } from 'react';
import { mZeichen } from "../../../../../global/const";
import { get_auftrag_anschrift_daten, get_faelle_anschrift_daten, get_fremd_zeuge, get_show_fremd_zeuge } from '../../../hilfs_functions';
import { IFaelleAnschriftDaten, IFallData, IFallDataZusaetzlich, IFremdZeuge } from "../../../interfaces";

export interface ICardContentBackProps {
    item: IFallData,
    itemZus: IFallDataZusaetzlich,
}


const ErsetzeZeichen: React.FC<{ tatbestandstext: string }> = ({ tatbestandstext }): JSX.Element => {
    if (tatbestandstext && tatbestandstext.indexOf("Zeichen") > -1) {
        for (let [key, value] of Object.entries(mZeichen)) {
            let position = tatbestandstext.indexOf(key);
            if (!(key.indexOf(".") > -1)) {
                if (tatbestandstext.charAt(position + 3) === ".") position = -1
            }

            if (position > -1) {
                var imageHtml = ' <img src="/Files/WinowigOnlineNT/Source/img/tatbestandsbilder/' + value.foto + '" alt="' + value.alt + '" style="height:25px;margin-bottom:-5px" />';
                tatbestandstext = "<span>" + tatbestandstext.substr(0, position + key.length) + "</span>" + imageHtml + "<span>" + tatbestandstext.substr(position + key.length) + "</span>";
            }

        }
        while (tatbestandstext.indexOf("<span><span>") > -1) {
            tatbestandstext = tatbestandstext.replace(/<span><span>/, "<span>");
        }
        while (tatbestandstext.indexOf("</span></span>") > -1) {
            tatbestandstext = tatbestandstext.replace(/<\/span><\/span>/, "</span>");
        }
    } else {
        tatbestandstext = "<span>" + tatbestandstext + "</span>";
    }

    return <div dangerouslySetInnerHTML={{ __html: tatbestandstext }}></div>;
}
const CardContentBack: React.FC<ICardContentBackProps> = ({ item, itemZus }): JSX.Element => {

    const fremd_zeugen: IFremdZeuge[] = useMemo(() => get_fremd_zeuge(item.f010), [item.f010]);
    const show_fremd_zeugen: boolean = useMemo(() => get_show_fremd_zeuge(fremd_zeugen), [fremd_zeugen]);
    const faelle_anschrift_daten: IFaelleAnschriftDaten = useMemo(() => get_faelle_anschrift_daten(item.f010), [item.f010]);
    const show_faelle_anschrift_daten: boolean = useMemo(() => get_show_faelle_anschrift_daten(faelle_anschrift_daten), [faelle_anschrift_daten]);
    const auftrag_anschrift_daten = useMemo(() => get_auftrag_anschrift_daten(item.f010), [item.f010]);

    let ort_bezirk = '';
    let ort = item.f010.ort ? item.f010.ort : "";
    let bezirk = item.f010.bezirk;
    if (!bezirk || bezirk === "Standard")
        bezirk = "";
    else
        bezirk = " (" + bezirk + ")";

    ort_bezirk = ort + bezirk;

    return (<>
        {itemZus.loesch_text_back &&
            <>
                <div className='d-flex'>
                    <div className=''>
                        <p>{itemZus.loesch_text_back} </p>
                        {item.f010.Grund && <p><strong>Grund:</strong> {item.f010.Grund}<br /></p>}
                        <p>Status: {itemZus.status_text}</p>
                        <br />
                    </div>

                </div>
            </>
        }
        {itemZus.fall_art !== 5 &&
            <>
                {itemZus.tatzeit_back && <p>Tatzeit: {itemZus.tatzeit_back}</p>}
                {itemZus.fahrzeug_back && <p>{itemZus.fahrzeug_back}</p>}
                {item.f010.KzAnhaenger && <p>Anhänger: {item.f010.KzAnhaenger}</p>}
                <br />
                {item.f010.Land && <><p>Land: {item.f010.Land}</p> <br /></>}
                {/***
                     *   <p>Tatbestand {itemZus.angezeigte_tatbestand_nummer} {itemZus.angezeigte_tatbestand_nummer_weiter}</p>
                    {(itemZus.tatbestaende && itemZus.tatbestaende.length > 0) && <ErsetzeZeichen tatbestandstext={itemZus.tatbestaende[0].tatbestandstext}/>}
                    {(itemZus.tatbestaende && itemZus.tatbestaende.length > 0) && <p className="card_paragraph">{itemZus.tatbestaende[0].paragraphenzeile}</p> }
                  
                     * Hier sollen alle Tatbestände aufgelistet sein
                     */}
                {
                    (itemZus.tatbestaende && itemZus.tatbestaende.length > 0) &&
                    <>
                        {itemZus.tatbestaende.map((tatbestand) => {
                            return (
                                <div key={tatbestand.nummer}>
                                    <p>Tatbestand <strong className='text-primary'>{tatbestand.nummer}</strong></p>
                                    <ErsetzeZeichen tatbestandstext={tatbestand.tatbestandstext} />
                                    <p className="card_paragraph">{tatbestand.paragraphenzeile}</p>
                                    <br />
                                </div>
                            )
                        })}
                    </>
                }
                <br />
                <p>{itemZus.tatort}</p>
                {ort_bezirk && <p>{ort_bezirk}</p>}
                <br />
            </>
        }
        {
            item.f200 &&
            <>
                <p><strong>QR-ID:</strong> {item.f200}</p>
                <br />
            </>
        }
        {
            itemZus.bezahlt &&
            <>
                <p><strong>Bar bezahlt</strong></p>
                {itemZus.quittungsnummer && <p>Quittungsnummer: {itemZus.quittungsnummer}</p>}
                <br />
            </>
        }
        {
            (item.f010.mbName || item.f010.mbDatum || item.f010.mbKfz) &&
            <>
                <p><strong>Mobile Beschilderung:</strong></p>
                {item.f010.mbName && <p>Aufgestellt von {item.f010.mbName}</p>}
                {item.f010.mbDatum && <p>Aufgestellt um {item.f010.mbDatum}</p>}
                {item.f010.mbKfz && <p>Kfz stand bereits: {item.f010.mbKfz}</p>}
                <br />
            </>
        }
        {
            (item.f010.Bedingungen && itemZus.erschwerte_bedingungen) &&
            <>
                <p><strong>Erschwerte Bedingungen:</strong></p>
                <p>{itemZus.erschwerte_bedingungen}</p>
                <br />
            </>
        }
        {
            (item.f010.KfzGeschlossen) &&
            <>
                <p><strong>Fahrzeug verschlossen:</strong></p>
                <p>{item.f010.KfzGeschlossen}</p>
                <br />
            </>
        }
        {
            (item.f010.KofferraumGeschlossen) &&
            <>
                <p><strong>Kofferraum verschlossen:</strong></p>
                <p>{item.f010.KofferraumGeschlossen}</p>
                <br />
            </>
        }
        {
            (item.f010.AnschriftFDatum || item.f010.AnschriftFKlasse || item.f010.AnschriftFBehoerde || item.f010.AnschriftFNummer) &&
            <>
                <p><strong>Führerschein</strong></p>
                {item.f010.AnschriftFKlasse && <p>Klasse: {item.f010.AnschriftFKlasse}</p>}
                {item.f010.AnschriftFNummer && <p>Nummer: {item.f010.AnschriftFNummer}</p>}
                {item.f010.AnschriftFDatum && <p>Ausstellungsdatum: {item.f010.AnschriftFDatum}</p>}
                {item.f010.AnschriftFBehoerde && <p>Ausstellungsbehörde: {item.f010.AnschriftFBehoerde}</p>}
                <br />
            </>
        }
        {
            itemZus.fall_art === 4 &&
            <>
                {item.f010.Zustand &&
                    <>
                        <p><strong>Allgemeinzustand des Fahrzeuges:</strong></p>
                        <p>{item.f010.Zustand}</p>
                        <br />
                    </>
                }
                {item.f010.Ladung &&
                    <>
                        <p><strong>Ladung:</strong></p>
                        <p>{item.f010.Ladung}</p>
                        <br />
                    </>
                }
                {(item.f010.Firma && item.f010.abs_firmen_daten) &&
                    <>
                        <p><strong>Abschleppunternehmen:</strong></p>
                        {item.f010.abs_firmen_daten.f001 && <p>{item.f010.abs_firmen_daten.f001}</p>}
                        {item.f010.abs_firmen_daten.f002 && <p>{item.f010.abs_firmen_daten.f002}</p>}
                        {(item.f010.abs_firmen_daten.f004 || item.f010.abs_firmen_daten.f005) &&
                            <>
                                {item.f010.abs_firmen_daten.f004 && <span>{item.f010.abs_firmen_daten.f004}</span>}
                                {item.f010.abs_firmen_daten.f005 && <span> {item.f010.abs_firmen_daten.f005}</span>}
                                <br />
                            </>
                        }
                        {item.f010.abs_firmen_daten.f003 && <p>{item.f010.abs_firmen_daten.f003}</p>}
                        {item.f010.abs_firmen_daten.f006 && <p>Tel.: {item.f010.abs_firmen_daten.f006}</p>}
                        {item.f010.abs_firmen_daten.f007 && <p>Mobil: {item.f010.abs_firmen_daten.f007}</p>}
                        {item.f010.abs_firmen_daten.f008 && <p>E-Mail: {item.f010.abs_firmen_daten.f008}</p>}
                        <br />
                    </>
                }
                {item.f010.Anforderung &&
                    <>
                        <p><strong>Beauftragt:</strong></p>
                        <p>{item.f010.Anforderung}</p>
                        <br />
                    </>
                }
                {item.f010.Schleppzeit &&
                    <>
                        <p><strong>Abgeschleppt:</strong></p>
                        <p>{item.f010.Schleppzeit}</p>
                        <br />
                    </>
                }
                {item.f010.Ankunftszeit &&
                    <>
                        <p><strong>Ankunftszeit:</strong></p>
                        <p>{item.f010.Ankunftszeit}</p>
                        <br />
                    </>
                }
                {item.f010.AbsOrt &&
                    <>
                        <p><strong>Abstellort:</strong></p>
                        <p>{item.f010.AbsOrt}</p>
                        <br />
                    </>
                }
                {item.f010.Leerfahrt &&
                    <>
                        <p><strong>Leerfahrt:</strong></p>
                        <p>{item.f010.Leerfahrt}</p>
                        <br />
                    </>
                }
                {item.f010.Geoeffnet &&
                    <>
                        <p><strong>Kfz wurde geöffnet:</strong></p>
                        <p>{item.f010.Geoeffnet}</p>
                        <br />
                    </>
                }
                {item.f010.Abbestellt &&
                    <>
                        <p><strong>Abbestellt:</strong></p>
                        <p>{item.f010.Abbestellt}</p>
                        <br />
                    </>
                }
                {item.f010.Beifahrer &&
                    <>
                        <p><strong>Beifahrer Abschleppfahrer:</strong></p>
                        <p>{item.f010.Beifahrer}</p>
                        <br />
                    </>
                }
                {item.f010.HalterErmittelt &&
                    <>
                        <p><strong>Halter ermittelt:</strong></p>
                        <p>{item.f010.HalterErmittelt}</p>
                        <br />
                    </>
                }
                {(item.f010.AbsKfzArt || item.f010.AbsKfzVH) &&
                    <>
                        <p><strong>Abschleppung durchgeführt:</strong></p>
                        <span>{item.f010.AbsKfzArt && 'mit ' + item.f010.AbsKfzArt}</span>
                        <span>{item.f010.AbsKfzVH && item.f010.AbsKfzArt ? ', ' + item.f010.AbsKfzVH : item.f010.AbsKfzVH}</span>
                        <br />
                        <br />
                    </>
                }
                {item.f010.AbsKennz &&
                    <>
                        <p><strong>Kfz des Abschleppwagens:</strong></p>
                        <p>{item.f010.AbsKennz}</p>
                        <br />
                    </>
                }
                {(item.f010.Fahrer && item.f010.Fahrer === "neu") ? //Daten sind in f010
                    <>
                        <p><strong>Abschleppfahrer:</strong></p>
                        {(item.f010.FahrerAnrede || item.f010.FahrerVorname || item.f010.FahrerName) &&
                            <>
                                {item.f010.FahrerAnrede === "1" && <span>Herr </span>}
                                {item.f010.FahrerAnrede === "2" && <span>Frau </span>}
                                {item.f010.FahrerVorname && <span>{item.f010.FahrerVorname} </span>}
                                {item.f010.FahrerName && <span>{item.f010.FahrerName} </span>}
                                <br />
                            </>
                        }
                        {(item.f010.FahrerPlz || item.f010.FahrerOrt) &&
                            <>
                                {item.f010.FahrerPlz && <span>{item.f010.FahrerPlz}</span>}
                                {item.f010.FahrerOrt && <span> {item.f010.FahrerOrt}</span>}
                                <br />
                            </>
                        }
                        {item.f010.FahrerTel && <p>Tel.: {item.f010.FahrerTel}</p>}
                        {item.f010.FahrerMobil && <p>Mobil: {item.f010.FahrerMobil}</p>}
                        {item.f010.FahrerMail && <p>E-Mail: {item.f010.FahrerMail}</p>}
                        <br />
                    </> :
                    (item.f010.Fahrer && item.f010.abs_fahrer_daten) ?
                        <>
                            <p><strong>Abschleppfahrer:</strong></p>
                            {(item.f010.abs_fahrer_daten.f002 || item.f010.abs_fahrer_daten.f003 || item.f010.abs_fahrer_daten.f004) &&
                                <>
                                    {item.f010.abs_fahrer_daten.f002 === "1" && <span>Herr </span>}
                                    {item.f010.abs_fahrer_daten.f002 === "2" && <span>Frau </span>}
                                    {item.f010.abs_fahrer_daten.f003 && <span>{item.f010.abs_fahrer_daten.f003} </span>}
                                    {item.f010.abs_fahrer_daten.f004 && <span>{item.f010.abs_fahrer_daten.f004} </span>}
                                    <br />
                                </>
                            }
                            {(item.f010.abs_fahrer_daten.f006 || item.f010.abs_fahrer_daten.f007) &&
                                <>
                                    {item.f010.abs_fahrer_daten.f006 && <span>{item.f010.abs_fahrer_daten.f006}</span>}
                                    {item.f010.abs_fahrer_daten.f007 && <span> {item.f010.abs_fahrer_daten.f007}</span>}
                                    <br />
                                </>
                            }
                            {item.f010.abs_fahrer_daten.f008 && <p>Tel.: {item.f010.abs_fahrer_daten.f008}</p>}
                            {item.f010.abs_fahrer_daten.f009 && <p>Mobil: {item.f010.abs_fahrer_daten.f009}</p>}
                            {item.f010.abs_fahrer_daten.f010 && <p>E-Mail: {item.f010.abs_fahrer_daten.f010}</p>}
                            <br />
                        </> :
                        <></>
                }

            </>
            //Abschlepp fertig
        }
        { /**Auftrag gibt es nicht mehr */
            itemZus.fall_art === 5 &&
            <>
                {item.f010.auftragsart && <p>Art: {item.f010.auftragsart}</p>}
                {(item.f010.auftragsart === "Einzelauftrag" || item.f010.auftragsart === "Dauerauftrag") && <p>Gültigkeit: {itemZus.tatzeit_back}</p>}
                {<p><strong>Nummer {itemZus.angezeigte_tatbestand_nummer} {itemZus.angezeigte_tatbestand_nummer_weiter}</strong></p>}
                {item.f010.Freitext && <><p>{item.f010.Freitext}</p><br /></>}
                <p>{itemZus.tatort}</p>
                {ort_bezirk && <p>{ort_bezirk}</p>}<br />
                {(auftrag_anschrift_daten && auftrag_anschrift_daten.length > 0) &&
                    <>
                        {
                            auftrag_anschrift_daten.map((item, i) => {

                                return (
                                    <div key={i + 1}>
                                        {item.AnschriftPersonentyp && <><p>{item.AnschriftPersonentyp}</p></>}

                                        <span>{item.AnschriftAnrede} </span>
                                        <span>{item.AnschriftVorname} </span>
                                        <span>{item.AnschriftName} </span>
                                        {(item.AnschriftAnrede || item.AnschriftVorname || item.AnschriftName) && <> <br /></>}

                                        <span>{item.AnschriftStrasse} </span>
                                        <span>{item.AnschriftHausnummer} </span>
                                        {(item.AnschriftStrasse || item.AnschriftHausnummer) && <> <br /></>}

                                        <span>{item.AnschriftLand} </span>
                                        <span>{item.AnschriftPostleitzahl} </span>
                                        <span>{item.AnschriftStadt} </span>
                                        {(item.AnschriftLand || item.AnschriftPostleitzahl || item.AnschriftStadt) && <> <br /></>}

                                        <span>{item.AnschriftGeburtsdatum} </span>
                                        <span>{item.AnschriftGeburtsname} </span>
                                        <span>{item.AnschriftGeburtsort} </span>
                                        {(item.AnschriftGeburtsdatum || item.AnschriftGeburtsname || item.AnschriftGeburtsort) && <> <br /></>}

                                        <br />
                                    </div>
                                )
                            })

                        }
                    </>
                }
                <br />
            </>
        }
        {item.f010.ZeugenFormatiert &&
            <>
                <p><strong>Zeuge:</strong></p>
                <p>{item.f010.ZeugenFormatiert}</p>
                <br />
            </>
        }
        {item.f010.SbIstZeuge &&
            <>
                <p>Sachbearbeiter ist Zeuge: Ja</p>
                <br />
            </>
        }
        {item.f010.bemerkg &&
            <>
                <p><strong>Bemerkung:</strong></p>
                <p>{item.f010.bemerkg}</p>
                <br />
            </>
        }
        {item.f010.Notiz &&
            <>
                <p><strong>Notiz:</strong></p>
                <p>{item.f010.Notiz}</p>
                <br />
            </>
        }
        {item.f010.AbsNotiz &&
            <>
                <p><strong>Abschleppnotiz:</strong></p>
                <p>{item.f010.AbsNotiz}</p>
                <br />
            </>
        }
        {
            itemZus.fall_art === 5 &&
            <>
                {item.f010.Status &&
                    <>
                        <p><strong>Status:</strong></p>
                        <p>{item.f010.Status}</p>
                        <br />
                    </>
                }
                {item.f010.GrundStatus &&
                    <>
                        <p><strong>Grund:</strong></p>
                        <p>{item.f010.GrundStatus}</p>
                        <br />
                    </>
                }
            </>
        }


        {show_fremd_zeugen &&
            <>
                {fremd_zeugen.map((item, i) => {
                    return (
                        <div
                            key={i}
                            className='text-left'>
                            {(item.FrzAnrede || item.FrzVorname || item.FrzName) &&
                                <>
                                    <span>{item.FrzAnrede}</span>
                                    <span> {item.FrzVorname}</span>
                                    <span> {item.FrzName}</span>
                                    <br />
                                </>
                            }
                            {(item.FrzStrasse || item.FrzHausnummer) &&
                                <>
                                    <span>{item.FrzStrasse}</span>
                                    <span> {item.FrzHausnummer}</span>
                                    <br />
                                </>
                            }
                            {(item.FrzLand || item.FrzPostleitzahl || item.FrzStadt) &&
                                <>
                                    <span>{item.FrzLand}</span>
                                    <span> {item.FrzPostleitzahl}</span>
                                    <span> {item.FrzStadt}</span>
                                    <br />
                                </>
                            }
                            {fremd_zeugen.length === i && <br />}
                        </div>
                    );
                })}
            </>
        }

        {
            show_faelle_anschrift_daten &&
            <>
                <div className='text-left'>
                    {(faelle_anschrift_daten.AnschriftAnrede || faelle_anschrift_daten.AnschriftVorname || faelle_anschrift_daten.AnschriftName) &&
                        <>
                            <span>{faelle_anschrift_daten.AnschriftAnrede}</span>
                            <span> {faelle_anschrift_daten.AnschriftVorname}</span>
                            <span> {faelle_anschrift_daten.AnschriftName}</span>
                            <br />
                        </>
                    }
                    {(faelle_anschrift_daten.AnschriftStrasse || faelle_anschrift_daten.AnschriftHausnummer) &&
                        <>
                            <span>{faelle_anschrift_daten.AnschriftStrasse}</span>
                            <span> {faelle_anschrift_daten.AnschriftHausnummer}</span>
                            <br />
                        </>
                    }
                    {(faelle_anschrift_daten.AnschriftPostleitzahl || faelle_anschrift_daten.AnschriftStadt) &&
                        <>
                            <span>{faelle_anschrift_daten.AnschriftPostleitzahl}</span>
                            <span> {faelle_anschrift_daten.AnschriftStadt}</span>
                            <br />
                        </>
                    }
                    {(faelle_anschrift_daten.AnschriftGeburtsdatum || faelle_anschrift_daten.AnschriftGeburtsname || faelle_anschrift_daten.AnschriftGeburtsort) &&
                        <>
                            <span>Geb.</span>
                            {faelle_anschrift_daten.AnschriftGeburtsdatum && <span> am {faelle_anschrift_daten.AnschriftGeburtsdatum}</span>}
                            {faelle_anschrift_daten.AnschriftGeburtsort && <span> in {faelle_anschrift_daten.AnschriftGeburtsort}</span>}
                            {faelle_anschrift_daten.AnschriftGeburtsname && <span> als {faelle_anschrift_daten.AnschriftGeburtsname}</span>}
                            <br />
                        </>
                    }
                    {(faelle_anschrift_daten.AnschriftPersoNr) &&
                        <>
                            <span>Ausweisnummer: {faelle_anschrift_daten.AnschriftPersoNr}</span>
                            <br />
                        </>
                    }
                </div>
            </>
        }


    </>)
}

export default memo(CardContentBack);

const get_show_faelle_anschrift_daten = (faelle_anschrift_daten: IFaelleAnschriftDaten): boolean => {
    if (faelle_anschrift_daten.AnschriftAnrede ||
        faelle_anschrift_daten.AnschriftPersoNr ||
        faelle_anschrift_daten.AnschriftVorname ||
        faelle_anschrift_daten.AnschriftName ||
        faelle_anschrift_daten.AnschriftGeburtsdatum ||
        faelle_anschrift_daten.AnschriftGeburtsname ||
        faelle_anschrift_daten.AnschriftGeburtsort ||
        faelle_anschrift_daten.AnschriftHausnummer ||
        faelle_anschrift_daten.AnschriftPostleitzahl ||
        faelle_anschrift_daten.AnschriftStadt ||
        faelle_anschrift_daten.AnschriftStrasse) return true
    else return false;
}