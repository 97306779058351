
import { INavigationLogikReturn } from "./navigation";
import { useCallback, useRef } from "react";
import * as events from 'devextreme/events';
import { IKonfiguration, resetDataTempObj, resetEinstellungen, resetNotification, setKonfigSupportZugangAuth, useAppDispatch, useAppSelector } from "../../state";
import { INavigation } from "../../global/interfaces";
import { GetData } from "../../global/communication";
import themes from "devextreme/ui/themes";
import { icon_car, icon_person_running, icon_preferences, icon_user } from "../../global/const";


export enum Paths {
  PATH_FAELLEVERKEHR = "/faelleVerkehr",
  PATH_ABSCHLEPPFAELLE = "/abschleppFaelle",
  PATH_FAELLEAOWI = "/faelleAOWI",
  PATH_FAELLEFLIESSVERKEHR = "/faelleFliessVerkehr",
  PATH_AUFTRAEGE = "/auftraege",
  PATH_DOPPELVERWARNUNGEN = "/doppelVerwarnungen",
  PATH_DATENEXPORT = "/datenExport",
  PATH_DATENEXPORTSTARTEN = "/exportStarten",
  PATH_DATENEXPORTHERUNTERLADEN = "/exportHerunterladen",
  PATH_DATENEXPORTBEARBEITEN = "/exportBearbeiten",
  PATH_STATISTIK = "/statistik",
  PATH_STATISTIKBENUTZERDEFINIERT = "/statistikbenutzerdefiniert",
  PATH_STATISTIKINDIVIDUELL = "/statistikindividuell",
  PATH_STATISTIKTAGESAUSWERTUNG = "/statistiktag",
  PATH_STATISTIKMONAT = "/statistikmonat",
  PATH_STATISTIKGERAETELOGINS = "/geraetelogins",
  PATH_DRUCKLAYOUTS = "/druckLayouts",
  PATH_KONFIGURATOR = "/konfigurator",
  PATH_FERNWARTUNG = "/fernwartung",
  PATH_ABSCHLEPPUNTERNEHMEN = "/abschleppUnternehmen",
  PATH_GERAETE = "/geraete",
  PATH_GERAETE_KONFIGURATIONEN = "/geraeteKonfigurationen",
  PATH_KFZHINWEISE = "/kfzHinweise",
  PATH_SACHBEARBEITER = "/sachbearbeiter",
  PATH_SACHBEARBEITERGRUPPEN = "/sachbearbeitergruppen",
  PATH_STAMMDATEN = "/stammdaten",
  PATH_STRASSEN = "/strassen",
  PATH_TATBESTAENDE = "/tatbestaende",
  PATH_TATBKATEGORIEN = "/tatbKategorien",
  PATH_TATBHINWEIS = "/tatbHinweis",
  PATH_PASSWORTAENDERN = "/passwortAendern",
}

export enum Texte {
  TEXT_MOBIL = "Mobil",
  TEXT_FAELLEVERKEHR = "Fälle Verkehr",
  TEXT_ABSCHLEPPFAELLE = "Abschleppfälle",
  TEXT_FAELLEAOWI = "Fälle AOWI",
  TEXT_FAELLEFLIESSVERKEHR = "Fälle Fließverkehr",
  TEXT_AUFTRAEGE = "Aufträge",
  TEXT_DOPPELVERWARNUNGEN = "Doppelverwarnungen",
  TEXT_DATENEXPORT = "Datenexport",
  TEXT_DATENEXPORTSTARTEN = "Starten",
  TEXT_DATENEXPORTHERUNTERLADEN = "Herunterladen",
  TEXT_DATENEXPORTBEARBEITEN = "Bearbeiten",
  TEXT_STATISTIK = "Statistik",
  TEXT_ABMELDEN = "Abmelden",
  TEXT_STATISTIK_BENUTZERDEFINIERT = "Bentuzerdefiniert",
  TEXT_STATISTIK_INDIVIDUELL = "Individuell",
  TEXT_STATISTIK_TAGESAUSWERTUNG = "Tagesauswertung",
  TEXT_STATISTIK_MONAT = "Monat",
  TEXT_STATISTIK_GERAETELOGINS = "Gerätelogins",
  TEXT_SUPPORT = 'Support',
  TEXT_DRUCKLAYOUTS = "Drucklayouts",
  TEXT_KONFIGURATOR = "Konfigurator",
  TEXT_FERNWARTUNG = "Fernwartung",
  TEXT_EINSTELLUNGEN = 'Einstellungen',
  TEXT_ABSCHLEPPUNTERNEHMEN = "Abschleppunternehmen",
  TEXT_GERAETE = "Geräte",
  TEXT_GERAETE_KONFIGURATIONEN = "Geräte Konfigurationen",
  TEXT_KFZHINWEISE = "KFZ Hinweis",
  TEXT_SACHBEARBEITER = "Sachbearbeiter",
  TEXT_SACHBEARBEITERGRUPPEN = "SB. Gruppen",
  TEXT_STAMMDATEN = "Stammdaten",
  TEXT_STRASSEN = "Straßen",
  TEXT_TATBESTAENDE = "Tatbestände",
  TEXT_TATBKATEGORIEN = "Tatb. Kategorien",
  TEXT_TATBHINWEIS = "Tatb. Hinweis",
  TEXT_PASSWORTAENDERN = "Passwort ändern",
  TEXT_MODUS = 'Darstellung',
  TEXT_MODUS_STANDARD = "Standard",
  TEXT_MODUS_DUNKEL_MODUS = "Dunkel Modus",
  TEXT_MODUS_ORANGE_HELL = "Orange Hell",
  TEXT_MODUS_ORANGE_DUNKEL = "Orange Dunkel",
  TEXT_MODUS_LILA_HELL = "Lila Hell",
  TEXT_MODUS_LIMETTE_DUNKEL = "Limette Dunkel",
}


interface INavigationLogikProps {
  selectedItemChanged: (path: string, event: any) => void,
  openMenu: () => void,
  collapseAll: Boolean,
}


const useNavigationLogik = (props: INavigationLogikProps): INavigationLogikReturn => {
  const konfiguration = useAppSelector((state) => state.store_einstellungen.konfiguration);
  const konfigurator_bearbeitet = useAppSelector((state) => state.store_einstellungen.konfigurator_bearbeitet);
  //Geht nicht
  // const items: INavigation[] = useMemo(() => get_end_nav(navigation, konfiguration), [konfiguration]);
  const items: INavigation[] = get_end_nav(konfiguration);
  const treeViewRef = useRef<any>(null);
  const wrapperRef = useRef();
  const dispatch = useAppDispatch();

  const getWrapperRef = useCallback((element) => {
    const prevElement = wrapperRef.current;
    if (prevElement) {
      events.off(prevElement, 'dxclick');
    }

    wrapperRef.current = element;
    events.on(element, 'dxclick', () => {
      props.openMenu();
    });


  }, [props]);

  const handleLogout = useCallback(() => {
    GetData({
      Operation: "Logout",
    }, dispatch)
      .then((res) => {
        if (!res) return
        // dispatch(set_loggedIn(false));
        // dispatch(set_konfiguration_support_zugang_authentifiziert(false));
        dispatch(resetEinstellungen());
        dispatch(resetNotification());
        dispatch(resetDataTempObj());
      })
  }, [dispatch]);


  const handleTheme = useCallback((text: string): boolean => {
    switch (text) {
      case Texte.TEXT_MODUS_STANDARD:
        themes.current("material.blue.light");
        return true
      case Texte.TEXT_MODUS_DUNKEL_MODUS:
        themes.current("material.blue.dark")
        return true
      case Texte.TEXT_MODUS_ORANGE_HELL:
        themes.current("material.orange.light");
        return true
      case Texte.TEXT_MODUS_ORANGE_DUNKEL:
        themes.current("material.orange.dark");
        return true
      case Texte.TEXT_MODUS_LILA_HELL:
        themes.current("material.purpel.light");
        return true
      case Texte.TEXT_MODUS_LIMETTE_DUNKEL:
        themes.current("material.limette.dark");
        return true
      default:
        return false
    }
  }, []);

  const ClickItem = useCallback(({ itemData, event, node }: any) => {

    props.openMenu();

    if (itemData.text === Texte.TEXT_ABMELDEN) {
      handleLogout();
      return
    }
    if (node.selected || (itemData.items && itemData.items.length > 0))
      return;

    if (konfigurator_bearbeitet) {
      dispatch(setKonfigSupportZugangAuth(false))
    }
    if (handleTheme(itemData.text)) {
      GetData({ Operation: "SetTheme", value: itemData.path.split(";")[1] }, dispatch)
        .then((res) => {
          if (!res) return
        });
      return
    }


    const path = itemData.path;
    //const treeView = treeViewRef.current && treeViewRef.current.instance;
    const nodeTreeView = treeViewRef.current as any;
    const treeView = nodeTreeView!.instance;

    treeView.selectItem(itemData);

    //selectedItemChanged({path , event})
    props.selectedItemChanged(path, event)
  }, [treeViewRef, props, handleLogout, handleTheme, dispatch, konfigurator_bearbeitet]);

  return {
    getWrapperRef: getWrapperRef,
    treeViewRef: treeViewRef,
    items: items,
    ClickItem: ClickItem,
  }
}
export default useNavigationLogik;

const get_end_nav = (konfiguration: IKonfiguration): INavigation[] => {
  let result: INavigation[] = navigation;
  !konfiguration.mobil && (result[0].visible = false);
  (!konfiguration.faelle_verkehr || !konfiguration.vowi) && (result[0].items![0].visible = false);
  !konfiguration.faelle_abschlepp && (result[0].items![1].visible = false);
  (!konfiguration.faelle_aowi || !konfiguration.aowi) && (result[0].items![2].visible = false);
  (!konfiguration.faelle_fliess_verkehr || !konfiguration.fowi) && (result[0].items![3].visible = false);
  !konfiguration.doppel_verwarnungen && (result[0].items![4].visible = false);
  !konfiguration.daten_export && (result[0].items![5].visible = false);
  !konfiguration.daten_export_starten && (result[0].items![5].items![0].visible = false);
  !konfiguration.daten_export_bearbeiten && (result[0].items![5].items![1].visible = false);
  !konfiguration.daten_export_herunterladen && (result[0].items![5].items![2].visible = false);
  !konfiguration.statistik && (result[0].items![6].visible = false);
  !konfiguration.statistik_benutzer_definiert && (result[0].items![6].items![0].visible = false);
  !konfiguration.statistik_individuell && (result[0].items![6].items![1].visible = false);
  !konfiguration.statistik_tages_auswertung && (result[0].items![6].items![2].visible = false);
  !konfiguration.statistik_monat_auswertung && (result[0].items![6].items![3].visible = false);
  !konfiguration.statistik_geraete_logins && (result[0].items![6].items![4].visible = false);

  !konfiguration.support && (result[1].visible = false);
  !konfiguration.support_druck_layouts && (result[1].items![0].visible = false);
  !konfiguration.support_konfigurator && (result[1].items![1].visible = false);
  !konfiguration.einstellungen_fernwartung && (result[1].items![2].visible = false);

  !konfiguration.einstellungen && (result[2].visible = false);
  !konfiguration.einstellungen_abschleppunternehmen && (result[2].items![0].visible = false);
  !konfiguration.einstellungen_geraete && (result[2].items![1].visible = false);
  !konfiguration.einstellungen_geraete && (result[2].items![2].visible = false);
  !konfiguration.einstellungen_kfz_hinweise && (result[2].items![3].visible = false);
  !konfiguration.einstellungen_sachbearbeiter && (result[2].items![4].visible = false);
  !konfiguration.einstellungen_sachbearbeiter_gruppen && (result[2].items![5].visible = false);
  !konfiguration.einstellungen_stammdaten && (result[2].items![6].visible = false);
  !konfiguration.einstellungen_strassen && (result[2].items![7].visible = false);
  (!konfiguration.einstellungen_tatbestaende || konfiguration.gemeinsam_genutzte_tatbestandstabelle) && (result[2].items![8].visible = false);
  (!konfiguration.einstellungen_tatbestaende_kategorie || konfiguration.gemeinsam_genutzte_tatbestandstabelle) && (result[2].items![9].visible = false);
  !konfiguration.einstellungen_tatbestaende_hinweise && (result[2].items![10].visible = false);
  !konfiguration.einstellungen_passwort_aendern && (result[2].items![11].visible = false);
  return result;

}



export const navigation: INavigation[] = [
  {
    text: Texte.TEXT_MOBIL,
    icon: icon_car,
    expanded: true,
    items: [
      {
        text: Texte.TEXT_FAELLEVERKEHR,
        path: Paths.PATH_FAELLEVERKEHR,
        selected: true,
      },
      {
        text: Texte.TEXT_ABSCHLEPPFAELLE,
        path: Paths.PATH_ABSCHLEPPFAELLE,
      },
      {
        text: Texte.TEXT_FAELLEAOWI,
        path: Paths.PATH_FAELLEAOWI,
      },
      {
        text: Texte.TEXT_FAELLEFLIESSVERKEHR,
        path: Paths.PATH_FAELLEFLIESSVERKEHR,
      },
      // { MD fällt weg
      //     text: Texte.TEXT_AUFTRAEGE,
      //     path: Paths.PATH_AUFTRAEGE,
      // },
      {
        text: Texte.TEXT_DOPPELVERWARNUNGEN,
        path: Paths.PATH_DOPPELVERWARNUNGEN,
      },
      {
        text: Texte.TEXT_DATENEXPORT,
        path: Paths.PATH_DATENEXPORT,
        items: [
          {
            text: Texte.TEXT_DATENEXPORTSTARTEN,
            path: Paths.PATH_DATENEXPORTSTARTEN,
          },
          {
            text: Texte.TEXT_DATENEXPORTBEARBEITEN,
            path: Paths.PATH_DATENEXPORTBEARBEITEN,
          },
          {
            text: Texte.TEXT_DATENEXPORTHERUNTERLADEN,
            path: Paths.PATH_DATENEXPORTHERUNTERLADEN,
          }
        ],
      },
      {
        text: Texte.TEXT_STATISTIK,
        path: Paths.PATH_STATISTIK,
        items: [
          {
            text: Texte.TEXT_STATISTIK_BENUTZERDEFINIERT,
            path: Paths.PATH_STATISTIKBENUTZERDEFINIERT,
          },
          {
            text: Texte.TEXT_STATISTIK_INDIVIDUELL,
            path: Paths.PATH_STATISTIKINDIVIDUELL,
          },
          {
            text: Texte.TEXT_STATISTIK_TAGESAUSWERTUNG,
            path: Paths.PATH_STATISTIKTAGESAUSWERTUNG,
          },
          {
            text: Texte.TEXT_STATISTIK_MONAT,
            path: Paths.PATH_STATISTIKMONAT,
          },
          {
            text: Texte.TEXT_STATISTIK_GERAETELOGINS,
            path: Paths.PATH_STATISTIKGERAETELOGINS,
          },
        ],
      }
    ]
  },
  {
    text: Texte.TEXT_SUPPORT,
    icon: icon_user,
    items: [
      {
        text: Texte.TEXT_DRUCKLAYOUTS,
        path: Paths.PATH_DRUCKLAYOUTS,
      },
      {
        text: Texte.TEXT_KONFIGURATOR,
        path: Paths.PATH_KONFIGURATOR,
      },
      {
        text: Texte.TEXT_FERNWARTUNG,
        path: Paths.PATH_FERNWARTUNG,
      }
    ]
  },
  {
    text: Texte.TEXT_EINSTELLUNGEN,
    icon: icon_preferences,
    items: [
      {
        text: Texte.TEXT_ABSCHLEPPUNTERNEHMEN,
        path: Paths.PATH_ABSCHLEPPUNTERNEHMEN,
      },
      {
        text: Texte.TEXT_GERAETE,
        path: Paths.PATH_GERAETE,
      },
      {
        text: Texte.TEXT_GERAETE_KONFIGURATIONEN,
        path: Paths.PATH_GERAETE_KONFIGURATIONEN,
      },
      {
        text: Texte.TEXT_KFZHINWEISE,
        path: Paths.PATH_KFZHINWEISE,
      },
      {
        text: Texte.TEXT_SACHBEARBEITER,
        path: Paths.PATH_SACHBEARBEITER,
      },
      {
        text: Texte.TEXT_SACHBEARBEITERGRUPPEN,
        path: Paths.PATH_SACHBEARBEITERGRUPPEN,
      },
      {
        text: Texte.TEXT_STAMMDATEN,
        path: Paths.PATH_STAMMDATEN,
      },
      {
        text: Texte.TEXT_STRASSEN,
        path: Paths.PATH_STRASSEN,
      },
      {
        text: Texte.TEXT_TATBESTAENDE,
        path: Paths.PATH_TATBESTAENDE,
      },
      {
        text: Texte.TEXT_TATBKATEGORIEN,
        path: Paths.PATH_TATBKATEGORIEN,
      },
      {
        text: Texte.TEXT_TATBHINWEIS,
        path: Paths.PATH_TATBHINWEIS,
      },
      {
        text: Texte.TEXT_PASSWORTAENDERN,
        path: Paths.PATH_PASSWORTAENDERN,
      }, {
        text: Texte.TEXT_MODUS,
        //icon: icon_theme,
        items: [
          { text: Texte.TEXT_MODUS_STANDARD, path: Texte.TEXT_MODUS_STANDARD + ';0' },
          { text: Texte.TEXT_MODUS_DUNKEL_MODUS, path: Texte.TEXT_MODUS_DUNKEL_MODUS + ';1' },
          { text: Texte.TEXT_MODUS_ORANGE_HELL, path: Texte.TEXT_MODUS_ORANGE_HELL + ';2' },
          { text: Texte.TEXT_MODUS_ORANGE_DUNKEL, path: Texte.TEXT_MODUS_ORANGE_DUNKEL + ';3' },
          { text: Texte.TEXT_MODUS_LILA_HELL, path: Texte.TEXT_MODUS_LILA_HELL + ';4' },
          { text: Texte.TEXT_MODUS_LIMETTE_DUNKEL, path: Texte.TEXT_MODUS_LIMETTE_DUNKEL + ';5' },
        ]
      }
    ]
  },

  {
    text: Texte.TEXT_ABMELDEN,
    icon: icon_person_running,
  }
];
