import React, { memo, useCallback } from 'react';
import DataGrid, {
  Selection, Button,
  Column,
} from 'devextreme-react/data-grid';
import '../DPV.scss';
import { DPVTabellenDetailsSpalten } from '../../../sonstiges/columns';
import { IDetailData } from '../interfaces';
import { MDButton } from "../../../global/components";
import { icon_delete } from '../../../global/const';

interface IDetailTabelleProps {
  onSelectionChanged: (e: any) => void,
  data: IDetailData[],
  onDeleteClick: (e: any) => void,
}
const columns = DPVTabellenDetailsSpalten;
const DPV_Details_Tabelle: React.FC<IDetailTabelleProps> = ({ onSelectionChanged, data, onDeleteClick }): JSX.Element => {

  const handleDeleteClick = useCallback((e: any) => {
    e.event.stopPropagation();
    onDeleteClick(e)
  }, [onDeleteClick]);

  return (
    <div className={"DPV-Container99"}>
      {data && data.length > 0 ?
        <>
          <div className="row">
            <div className="col-12 col-sm-6 col-lg-4 p-1 p-md-3">
              <p><strong>KFZ-Kennzeichen:</strong>&nbsp;&nbsp;{data[0].kfzkz}</p>
            </div>
            <div className="col-12 col-sm-6 col-lg-4 p-1 p-md-3">
              <p><strong>Tattag:</strong>&nbsp;&nbsp;{data[0].tattag}</p>
            </div>
            <div className="col-12 col-sm-6 col-lg-4 p-1 p-md-3">
              <p><strong>Ort:</strong>&nbsp;&nbsp;{data[0].ort}</p>
            </div>
            <div className="col-12 col-sm-6 col-lg-4 p-1 p-md-3">
              <p><strong>Bezirk:</strong>&nbsp;&nbsp;{data[0].bezirk}</p>
            </div>
            <div className="col-12 col-sm-6 col-lg-4 p-1 p-md-3">
              <p><strong>Straße:</strong>&nbsp;&nbsp;{data[0].strasse}</p>
            </div>
          </div>

          <DataGrid
            className={'dx-card wide-card DPV-Details-Grid'}
            dataSource={data}
            showBorders={false}
            wordWrapEnabled={true}
            columnAutoWidth={false}
            allowColumnResizing={true}
            rowAlternationEnabled={true}
            focusedRowEnabled={true}
            keyExpr={'lfdnr'}
            renderAsync={true}
            noDataText={"Keine Details"}
            onSelectionChanged={onSelectionChanged}
          //scrolling={{mode: "infinite"}}
          >
            <Selection mode="multiple" showCheckBoxesMode={"always"} />
            {/** <Paging defaultPageSize={5} /> 
                <Pager showPageSizeSelector={true} showInfo={true} />
                <FilterRow visible={true} />Wir brauchen hier keine Paging auch keinen Filter
                
                 <Column     
                dataField={'tatzeitBis'} 
                caption={'Tatzeit'}  
                />
                */}

            {
              columns.map((column) => {
                return (
                  <Column
                    key={column.dataField}
                    dataField={column.dataField}
                    caption={column.caption}
                    dataType={column.dataType}
                    cellRender={column.cellRender}
                    minWidth={column.minWidth}
                    visible={column.visible}
                  />
                )
              })
            }
            <Column
              type='buttons'>
              <Button
                cssClass={'d-inline-block'}
                onClick={handleDeleteClick}
                render={RenderButtonBearbeite} />
            </Column>
          </DataGrid>  </> : <></>}
    </div>
  );
}

export default memo(DPV_Details_Tabelle);

const RenderButtonBearbeite: React.FC = () => {
  return (<MDButton
    page='doppel_verwarnung_satz'
    tooltip_text='löschen'
    icon={icon_delete}
    type='danger'
  />)
}