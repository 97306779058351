import React, { memo } from 'react';
import '../DPV.scss';
import { MDButton } from "../../../global/components"
import { icon_arrow_left, icon_arrow_right } from '../../../global/const';

interface IFooterProps {
  maxPages: number,
  aktPage: number,
  onClickNext: () => void,
  onClickPrevious: () => void,
  onClickCombine: () => void,
}

const style_strih = { margin: "10px" }
const DPV_Details_Footer: React.FC<IFooterProps> = ({ maxPages, aktPage, onClickNext, onClickPrevious, onClickCombine }): JSX.Element => {
  return (
    <div className={"DPV_Detail_Footer d-flex"}>
      <MDButton
        onClick={onClickCombine}
        page='doppel_verwarnungen_details_combine'
        button_text='&rArr;&lArr;'
        tooltip_text='Doppelverwarnungen zusammenführen'
      />

      <MDButton
        onClick={onClickPrevious}
        page='doppel_verwarnungen_details_prev'
        icon={icon_arrow_left}
        className='ml-auto'
        tooltip_text='Vorheriger Satz'
      />
      < span style={style_strih}>{aktPage}/{maxPages}</span>
      <MDButton
        onClick={onClickNext}
        page='doppel_verwarnungen_details_next'
        icon={icon_arrow_right}
        tooltip_text='Nächster Satz'
      />
    </div>
  );
}

export default memo(DPV_Details_Footer);
