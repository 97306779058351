import { CheckBox, DateBox, Popup, RadioGroup, ScrollView, SelectBox } from 'devextreme-react';
import { Position, ToolbarItem } from 'devextreme-react/autocomplete';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { useScreenSizeClass } from '../../../global/media-query';
import ArrayStore from 'devextreme/data/array_store';
import { get_date_ajax_param, get_date_from_string, handleContentReadyCheckBox, handleContentReadyDateBox, handleContentReadySelectBox, handleContentReadyRadioGroup } from "../../../global/hilfs_functions";
import { resetDataTempSatz, setFilterKonfig, useAppDispatch, useAppSelector } from '../../../state';
import { IFaelleFilter } from '../interfaces';
import { icon_filter, MIN_DATE } from '../../../global/const';
import { MDButton } from "../../../global/components";
import { useMemo } from 'react';
import { GetData } from '../../../global/communication';
import { IZeuge } from '../../../global/interfaces';



interface IHeaderFilterProps {
    fall_art: number,
    filter: IFaelleFilter,
    setFilter: (value: IFaelleFilter) => void,
}

const data_Source_vornotierung = new ArrayStore({
    data: [
        { name: 'Anzeigen', id: "Ja" },
        { name: 'Nicht anzeigen', id: "Nein" },
        { name: 'Ausschließlich anzeigen', id: "Ausschließlich" },
    ],
    key: 'id',
});

const items_filter_datum = ['Datum filtern', 'Heute', 'Alle'];
const HeaderFilter: React.FC<IHeaderFilterProps> = ({ fall_art, filter, setFilter }): JSX.Element => {

    const screenSize = useScreenSizeClass();
    const konfiguration = useAppSelector((state) => state.store_einstellungen.konfiguration);
    const isScreenSmall = (screenSize === "sm" || screenSize === "md");
    const [faelleZuegen, setFaelleZuegen] = useState<IZeuge[]>([{ sachbearbeitername: "Alle", sachbearbeiternummer: 0 }]);
    const [faelleDienstGruppen, setFaelleDienstGruppen] = useState<string[]>(["Alle"]);
    const [faelleOrte, setFaelleOrte] = useState<string[]>(["Alle"]);
    const dispatch = useAppDispatch();
    const data_source_zeugen = useMemo(() => new ArrayStore({
        data: faelleZuegen,
        key: 'sachbearbeiternummer',
    }), [faelleZuegen]);
    const [startValue, setStartValue] = useState((get_date_from_string(filter.DatumVon).getTime() === MIN_DATE.getTime() ? new Date() : get_date_from_string(filter.DatumVon)));
    const [endValue, setEndValue] = useState(get_date_from_string(filter.DatumBis));
    const [showPopupFilter, setShowPopupFilter] = useState(false);
    const [geloeschteFaelleNichtAnzeigen, setGeloeschteFaelleNichtAnzeigen] = useState(filter.KeineGeloeschten);
    const [nurAenderungenAnzeigen, setNurAenderungenAnzeigen] = useState(filter.NurAenderungen);
    const [nurFaelleAnzeigen, setNurFaelleAnzeigen] = useState(filter.NurFaelle);
    const [nurEingestellteFaelleAnzeigen, setNurEingestellteFaelleAnzeigen] = useState(filter.NurEingestellteFaelle);
    const [mehrereSachbearbeiter, setMehrereSachbearbeiter] = useState<boolean | null>(filter.MehrereSachbearbeiter);

    const [radioButtonValue, setRadioButtonValue] = useState(get_radio_buttons_default_wert(filter));

    const [sachbearbeiterNummer, setSachbearbeiterNummer] = useState(parseInt(filter.Sachbearbeiternummer) || 0);
    const [filterDienstGruppe, setFilterDienstGruppe] = useState(filter.FilterDienstgruppe || "Alle");
    const [ort, setOrt] = useState(filter.Ort || "Alle");
    const [vornortierung, setVornotierung] = useState(filter.VornotierungenAnzeigen);
    const isGefiltert = useMemo(() => {
        if ((radioButtonValue === items_filter_datum[1]) ||
            (radioButtonValue === items_filter_datum[0]) ||
            geloeschteFaelleNichtAnzeigen ||
            nurAenderungenAnzeigen ||
            nurFaelleAnzeigen ||
            mehrereSachbearbeiter ||
            nurEingestellteFaelleAnzeigen ||
            sachbearbeiterNummer ||
            (filterDienstGruppe !== 'Alle') ||
            (ort !== 'Alle') ||
            (vornortierung !== 'Ja'))
            return true
        return false;
    }, [radioButtonValue, mehrereSachbearbeiter, geloeschteFaelleNichtAnzeigen, nurAenderungenAnzeigen, nurFaelleAnzeigen, nurEingestellteFaelleAnzeigen, sachbearbeiterNummer, filterDienstGruppe, ort, vornortierung]);

    const handleAnwenden = useCallback(() => {
        //Datumfiltern
        let datum_von = '';
        let datum_bis = '';
        if (radioButtonValue === items_filter_datum[2]) {
            datum_von = get_date_ajax_param(MIN_DATE);
            datum_bis = get_date_ajax_param(new Date())
        } else if (radioButtonValue === items_filter_datum[1]) {
            datum_von = get_date_ajax_param(new Date());
            datum_bis = get_date_ajax_param(new Date());
        } else if (radioButtonValue === items_filter_datum[0]) {
            datum_von = get_date_ajax_param(startValue);
            datum_bis = get_date_ajax_param(endValue);
        }

        setShowPopupFilter(false);

        let new_filter: IFaelleFilter = {
            ...filter,
            DatumVon: datum_von,
            DatumBis: datum_bis,
            KeineGeloeschten: geloeschteFaelleNichtAnzeigen,
            NurAenderungen: nurAenderungenAnzeigen,
            NurFaelle: nurFaelleAnzeigen,
            NurEingestellteFaelle: nurEingestellteFaelleAnzeigen,
            MehrereSachbearbeiter: mehrereSachbearbeiter!,
            VornotierungenAnzeigen: vornortierung === "Nein" ? "Nein" : vornortierung === "Ausschließlich" ? "Ausschließlich" : "Ja",
            Sachbearbeiternummer: sachbearbeiterNummer === 0 ? '' : sachbearbeiterNummer.toString(),
            FilterDienstgruppe: filterDienstGruppe === "Alle" ? '' : filterDienstGruppe,
            Ort: ort === "Alle" ? '' : ort,
        }

        dispatch(setFilterKonfig(new_filter));
        dispatch(resetDataTempSatz()); //MD 17.08.2022 hat gefehlt.
        setFilter(new_filter);


        /*
        brauche ich das??
        result += "SachbearbeiterName" + zuweis + get_json_object_in_arr(FaelleVerkehrZeugen, "id", sachbearbeiterNummer).Name + trenner;
       */

    }, [radioButtonValue, mehrereSachbearbeiter, startValue, endValue, filter, geloeschteFaelleNichtAnzeigen, nurAenderungenAnzeigen, nurFaelleAnzeigen, nurEingestellteFaelleAnzeigen, vornortierung, sachbearbeiterNummer, filterDienstGruppe, ort, dispatch, setFilter]);

    const handleShowPopup = useCallback(() => {
        setShowPopupFilter(true)
    }, []);
    const handleHidePopup = useCallback(() => {
        setShowPopupFilter(false)
    }, []);


    useEffect(() => {
        if (showPopupFilter && faelleZuegen.length === 1) {
            GetData({ Operation: "GetFaelleFilterData" }, dispatch)
                .then((res) => {
                    if (!res) return
                    setFaelleZuegen(res.faelle_zeugen);
                    setFaelleDienstGruppen(res.faelle_dienst_gruppen);
                    setFaelleOrte(res.faelle_orte);
                });
        }
    }, [showPopupFilter, faelleZuegen, dispatch]);

    const memo_options_anwenden = useMemo(() => {
        return {
            text: 'anwenden',
            onClick: handleAnwenden,
        }
    }, [handleAnwenden]);

    const memo_options_abbrechen = useMemo(() => {
        return {
            text: 'Abbrechen',
            onClick: handleHidePopup,
        }
    }, [handleHidePopup]);

    const handleChangeDateStart = useCallback((e: any) => {
        setStartValue(e.value)
    }, []);

    const handleChangeDateEnd = useCallback((e: any) => {
        setEndValue(e.value)
    }, []);

    const handleSetVornotierung = useCallback((value: any) => {
        setVornotierung(value)
        if (value === "Ausschließlich") {
            setNurAenderungenAnzeigen(false);
            setNurFaelleAnzeigen(false);
        }
    }, []);

    const handleNurAenderungenAnzeigen = useCallback((value: any) => {
        setNurAenderungenAnzeigen(value)
        if (value) {
            setVornotierung("Ja");
            setNurFaelleAnzeigen(false);
        }
    }, []);
    const handleNurFaelleAnzeigen = useCallback((value: any) => {
        setNurFaelleAnzeigen(value)
        if (value) {
            setNurAenderungenAnzeigen(false);
            setVornotierung("Ja");
        }
    }, []);

    const handleNurEingestelltFaelleAnzeigen = useCallback((value: any) => {
        setNurEingestellteFaelleAnzeigen(value)
        if (value) {
            setGeloeschteFaelleNichtAnzeigen(false);
        }
    }, []);

    const handleGeloeschteFaelleNichtAnzeigen = useCallback((value: any) => {
        setGeloeschteFaelleNichtAnzeigen(value)
        if (value) {
            setNurEingestellteFaelleAnzeigen(false);
        }
    }, []);
    return (
        <>
            <div id="faelle_filter_button">
                <MDButton
                    page='faelle'
                    className={isGefiltert ? 'filter_button highlight' : "filter_button"}
                    icon={icon_filter}
                    onClick={handleShowPopup}
                    tooltip_text="Fälle filtern"
                />
            </div>
            <Popup
                visible={showPopupFilter}
                onHiding={handleHidePopup}
                dragEnabled={true}
                hideOnOutsideClick={true}
                fullScreen={false}
                showCloseButton={true}
                height='auto'
                maxHeight="75vh"
                maxWidth="500px"
            >
                <Position
                    at={isScreenSmall ? "center" : "right"}
                    my={isScreenSmall ? "center" : "right top"}
                    of={isScreenSmall ? window : '#faelle_filter_button'}
                />
                <ToolbarItem
                    toolbar="top"
                    location={'before'}
                    render={RenderePopupTitle}
                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="before"
                    options={memo_options_anwenden}
                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={memo_options_abbrechen}
                />
                <ScrollView>
                    <div className='row p-3'>
                        <RadioGroup className='pt-1' items={items_filter_datum} value={radioButtonValue} onValueChange={setRadioButtonValue} layout="horizontal" onContentReady={handleContentReadyRadioGroup} />
                        {
                            (radioButtonValue === items_filter_datum[0]) &&
                            <>
                                <div className='col-12 col-md-6 p-4'>
                                    <DateBox
                                        value={startValue}
                                        onValueChanged={handleChangeDateStart}
                                        displayFormat={"dd.MM.yyyy"}
                                        label='Startdatum'
                                        labelMode='floating'
                                        showClearButton={true}
                                        useMaskBehavior={true}
                                        stylingMode='outlined'
                                        max={new Date()}
                                        onContentReady={handleContentReadyDateBox}
                                    />
                                </div>
                                <div className='col-12 col-md-6 pt-0 pt-md-4 px-4 pb-4'>
                                    <DateBox
                                        value={endValue}
                                        onValueChanged={handleChangeDateEnd}
                                        displayFormat={"dd.MM.yyyy"}
                                        showClearButton={true}
                                        useMaskBehavior={true}
                                        label='Enddatum'
                                        labelMode='floating'
                                        stylingMode='outlined'
                                        onContentReady={handleContentReadyDateBox}
                                    />
                                </div> </>
                        }
                        {
                            konfiguration.nach_zeugen_filtern &&
                            <>
                                <div className='col-12  pt-4'>
                                    <SelectBox
                                        dataSource={data_source_zeugen}
                                        displayExpr="sachbearbeitername"
                                        valueExpr="sachbearbeiternummer"
                                        value={sachbearbeiterNummer}
                                        onValueChange={setSachbearbeiterNummer}
                                        label='Zeuge'
                                        labelMode='floating'
                                        stylingMode='outlined'
                                        //  onContentReady={handleContentReadySelectBox}
                                        onContentReady={handleContentReadySelectBox}
                                    />
                                </div>
                            </>
                        }
                        {
                            konfiguration.filter_auf_freifeld2?.toUpperCase() === 'DIENSTGRUPPE' &&
                            <>
                                <div className='col-12  pt-4'>
                                    <SelectBox
                                        dataSource={faelleDienstGruppen}
                                        // displayExpr="Name"
                                        // valueExpr="ID"
                                        value={filterDienstGruppe}
                                        onValueChange={setFilterDienstGruppe}
                                        label='Dienstgruppe'
                                        labelMode='floating'
                                        stylingMode='outlined'
                                        onContentReady={handleContentReadySelectBox}
                                    />
                                </div>
                            </>
                        }

                        <>
                            <div className='col-12  pt-4'>
                                <SelectBox
                                    dataSource={faelleOrte}
                                    //  displayExpr="Name"
                                    //  valueExpr="ID"
                                    value={ort}
                                    onValueChange={setOrt}
                                    label='Ort'
                                    labelMode='floating'
                                    stylingMode='outlined'
                                    onContentReady={handleContentReadySelectBox}
                                />
                            </div>
                        </>
                        {
                            fall_art === 1 &&
                            <>
                                <div className='col-12  pt-4'>
                                    <SelectBox
                                        dataSource={data_Source_vornotierung}
                                        displayExpr="name"
                                        valueExpr="id"
                                        value={vornortierung}
                                        onValueChange={handleSetVornotierung}
                                        label='Vornotierungen'
                                        labelMode='floating'
                                        stylingMode='outlined'
                                        onContentReady={handleContentReadySelectBox}
                                    />
                                </div>
                            </>
                        }
                        <div className='col-12 pt-4'>
                            <CheckBox onContentReady={handleContentReadyCheckBox} value={geloeschteFaelleNichtAnzeigen} onValueChange={handleGeloeschteFaelleNichtAnzeigen} text='Gelöschte Fälle nicht anzeigen' />
                        </div>
                        <div className='col-12 pt-4'>
                            <CheckBox onContentReady={handleContentReadyCheckBox} value={nurFaelleAnzeigen} onValueChange={handleNurFaelleAnzeigen} text='Nur Fälle anzeigen' />
                        </div>
                        <div className='col-12 pt-4'>
                            <CheckBox onContentReady={handleContentReadyCheckBox} value={nurAenderungenAnzeigen} onValueChange={handleNurAenderungenAnzeigen} text='Nur Änderungen anzeigen' />
                        </div>
                        <div className='col-12 pt-4'>
                            <CheckBox onContentReady={handleContentReadyCheckBox} value={nurEingestellteFaelleAnzeigen} onValueChange={handleNurEingestelltFaelleAnzeigen} text='Nur eingestellte Fälle anzeigen' />
                        </div>
                        <div className='col-12 pt-4'>
                            <CheckBox onContentReady={handleContentReadyCheckBox} value={mehrereSachbearbeiter} onValueChange={setMehrereSachbearbeiter} text='Mehrere Sachbearbeiter' />
                        </div>
                    </div>
                </ScrollView>
            </Popup>
        </>
    );
}
export default memo(HeaderFilter);

const RenderePopupTitle = () => {
    return (<h2 className="title_popup">Filter konfigurieren</h2>)
};

const get_radio_buttons_default_wert = (filter: IFaelleFilter) => {
    if (filter.DatumVon === get_date_ajax_param(MIN_DATE)) {
        return items_filter_datum[2];
    } else if (filter.DatumVon === get_date_ajax_param(new Date()) && filter.DatumBis === get_date_ajax_param(new Date())) {
        return items_filter_datum[1]
    } else {
        return items_filter_datum[0]
    }
}