import { CheckBox, Popover } from "devextreme-react";
import TextBox from 'devextreme-react/text-box';
import { dxPopoverAnimation } from "devextreme/ui/popover";
import { useRef, useState, memo, useCallback } from "react";
import { ICardControlProps } from "..";
import { GetData } from "../../../../../global/communication";
import { icon_euro_sign } from "../../../../../global/const";
import { handleContentReadyCheckBox, handleContentReadyTextBox } from "../../../../../global/hilfs_functions";
import { setDataTemp, setToast, useAppDispatch, useAppSelector } from "../../../../../state";
import { MDButton } from "../../../../../global/components";
import { IFallData } from "../../../interfaces";


const animation_: dxPopoverAnimation = { show: { type: 'fade', duration: 300, from: 0, to: 1 }, hide: { type: 'fadeOut', duration: 100, to: 1 } }

const ZahlBetrag: React.FC<ICardControlProps> = ({ item, itemZus }) => {
    const konfiguration = useAppSelector((state) => state.store_einstellungen.konfiguration);
    const refIcon = useRef(null);
    const [popoverVisible, setPopoverVisible] = useState(false);
    const [barZahlungValue, setBarZahlungValue] = useState(false);
    const [bezahlt, setBezahlt] = useState<boolean>(itemZus.bezahlt);
    const [qnTextValue, setQnTextValue] = useState(item.f132);
    const data = useAppSelector((state) => state.store_temp_data.data) as IFallData[];
    const dispatch = useAppDispatch();

    let msg: string = "Zahlbertrag erfassen";
    if (bezahlt) msg = "Es wurde Bar (über OnlineNT) bezahlt.";

    if (bezahlt && qnTextValue) msg += " Quittungsnummer " + qnTextValue;

    const handleClickZahlBetrag = useCallback(() => {
        (bezahlt || itemZus.bar_bezahlt_f010) ? dispatch(setToast({
            message: 'Der Fall wurde bereits bezahlt. Eine Änderung ist nicht mehr möglich',
            type: 'info',
        })) : itemZus.exportiert ? dispatch(setToast({
            message: 'Der Fall wurde bereits exportiert. Eine Änderung ist nicht mehr möglich',
            type: 'info',
        })) :
            setPopoverVisible(true);
    }, [bezahlt, dispatch, itemZus.exportiert, itemZus.bar_bezahlt_f010]);

    const handleChangeQN = useCallback((e: any) => {
        setQnTextValue(e.value);
        e.value !== '' ? setBarZahlungValue(true) : setBarZahlungValue(false);
    }, []);

    const handleClickSpeichern = useCallback(() => {
        let betrag_end = "0";
        barZahlungValue && (betrag_end = itemZus.gesamt_betrag_aus_f010.toString()); // Sollbetrag

        let qu_num = qnTextValue.trim();
        GetData({
            Operation: "ChangeZahlbetragErfassen",
            LfdNr: item.lfdnr,
            Betrag: betrag_end,
            Quittungsnummer: qu_num
        }, dispatch
        )
            .then((res) => {
                if (!res) return
                barZahlungValue && setBezahlt(true); // Wenn erfolg
                setPopoverVisible(false);
                let temp_data = [...data];
                let end_data: IFallData[] = [];
                temp_data.forEach((itemData) => {
                    if (itemData.lfdnr === item.lfdnr) {
                        itemData = { ...itemData, f131: betrag_end }
                        itemData = { ...itemData, f132: qu_num }
                    }
                    end_data.push(itemData);
                });
                dispatch(setDataTemp({ data: end_data, new_data: true }));
            }
            );

    }, [barZahlungValue, itemZus, qnTextValue, item, data, dispatch]);

    const handleChangeCheckBox = useCallback((value: any) => {
        setBarZahlungValue(value);
        value === false && setQnTextValue('');
    }, []);
    const hadleHidePopover = useCallback(() => {
        setPopoverVisible(false)
    }, []);
    return (
        (konfiguration.zahlbetrag_erfassen && !itemZus.bar_bezahlt_f010) ?
            <>
                <li ref={refIcon}>
                    <MDButton
                        page="fall_card"
                        icon={icon_euro_sign}
                        className={bezahlt ? "highlight" : ""}
                        onClick={handleClickZahlBetrag}
                        tooltip_text={msg}
                    />
                </li>

                <Popover
                    target={refIcon.current!}
                    animation={animation_}
                    //position="top"
                    width={390}
                    title='Zahlbetrag erfassen'
                    visible={popoverVisible}
                    onHiding={hadleHidePopover}
                    shading={true}
                    shadingColor="rgba(0, 0, 0, 0.35)"
                >
                    <div className="dx-fieldset">
                        <div className="dx-field">
                            <div className="dx-field-label">Barzahlung</div>
                            <div className="dx-field-value">
                                <CheckBox
                                    value={barZahlungValue}
                                    onValueChange={handleChangeCheckBox} onContentReady={handleContentReadyCheckBox} />
                            </div>
                        </div>
                        <div className="dx-field">
                            <TextBox
                                label='Quittungsnummer'
                                labelMode='floating'
                                stylingMode='outlined'
                                mode='text'
                                showClearButton={false}
                                valueChangeEvent="input"
                                onValueChanged={handleChangeQN}
                                value={qnTextValue}
                                maxLength={30}
                                onContentReady={handleContentReadyTextBox}
                            />
                        </div>
                        <div className="dx-field">
                            <MDButton
                                page="symbole"
                                stylingMode='outlined'
                                type='default'
                                button_text='Speichern'
                                className='mx-auto'
                                onClick={handleClickSpeichern}
                            />
                        </div>
                    </div>
                </Popover>
            </> :
            <></>
    );
}
export default memo(ZahlBetrag);


