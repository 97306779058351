import { Popup, ScrollView } from "devextreme-react";
import { Position, ToolbarItem } from "devextreme-react/autocomplete";
import { memo, useCallback, useMemo, useState } from "react";
import { useId } from "react-id-generator";
import { icon_export } from "../../../global/const";
import { useScreenSizeClass } from "../../../global/media-query";
import { MDButton } from "../../../global/components";

const StrassenExport: React.FC = (): JSX.Element => {
    const [showPopUpExport, setShowPopUpExport] = useState(false);
    const screenSize = useScreenSizeClass();
    const ids = useId();
    const isScreenSmall = (screenSize === "sm" || screenSize === "md");


    const handleShowPopup = useCallback(() => {
        setShowPopUpExport(true)
    }, []);
    const handleHidePopup = useCallback(() => {
        setShowPopUpExport(false)
    }, []);
    const handleHidePopupStarten = useCallback(() => {
        setShowPopUpExport(false)
        window.location.href = "/Files/WoOnline/Source/Export/T710.xml"
    }, []);

    const memo_options_starten = useMemo(() => {
        return {
            text: 'Export starten',
            onClick: handleHidePopupStarten,
        }
    }, [handleHidePopupStarten]);

    const memo_options_abbrechen = useMemo(() => {
        return {
            text: 'Abbrechen',
            onClick: handleHidePopup,
        }
    }, [handleHidePopup]);


    return (
        <div>
            <div id={"strasse_export_button" + ids[0]}>
                <MDButton
                    page="strassen"
                    icon={icon_export}
                    tooltip_text='Straßen exportieren'
                    onClick={handleShowPopup}
                />
            </div>
            <Popup
                visible={showPopUpExport}
                onHiding={handleHidePopup}
                dragEnabled={true}
                hideOnOutsideClick={true}
                fullScreen={false}
                showCloseButton={true}
                height='auto'
                maxHeight="550px"
                maxWidth="300px"
            >
                <ToolbarItem
                    toolbar="top"
                    location={'before'}
                    render={RenderePopupTitle}
                />
                <Position
                    at={isScreenSmall ? "center" : "right"}
                    my={isScreenSmall ? "center" : "right top"}
                    of={isScreenSmall ? window : '#strasse_export_button' + ids[0]}
                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="before"
                    options={memo_options_starten}
                />

                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={memo_options_abbrechen}
                />
                <ScrollView>
                    <div className='row p-2 p-md-3'>
                        <div className="col-12 p-1 p-md-2">
                            <p>Wählen Sie 'Export starten' um alle Strassen zu exportieren.</p>
                        </div>
                    </div>
                </ScrollView>
            </Popup></div>
    )
}
export default memo(StrassenExport);
const RenderePopupTitle = () => {
    return (<h2 className="title_popup">Straßen exportieren</h2>)
};
