import { memo, useCallback, useState } from "react";
import { ISelectedItemStrassen } from "..";
import { GetData } from "../../../../global/communication";
import { setConfirm, setToast, useAppDispatch } from "../../../../state";
import { IStrasse } from "../interfaces";
import StrassenTabVerwaltePopup from "./verwalten_popup";
import StrasseGrid from "./strasse_grid";

interface IStrassenProps {
    selectedItem: ISelectedItemStrassen,
    setSelectedItem: (obj: ISelectedItemStrassen) => void,
}

const StrassenTab: React.FC<IStrassenProps> = ({ selectedItem, setSelectedItem }) => {

    const dispatch = useAppDispatch();
    const [refreshGrid, setRefreshGrid] = useState(true);
    const [bearbeitePopUp, setBearbeitePopup] = useState<{ show: boolean, row: IStrasse | null }>({
        show: false,
        row: null,
    });
    const handleCallBack = useCallback((update?: boolean, loeschen?: boolean) => {
        (loeschen) &&
            delete_strasse(dispatch, bearbeitePopUp.row, () => setRefreshGrid(prev => !prev));
        setBearbeitePopup({ show: false, row: null })
        update && setRefreshGrid(prev => !prev);
    }, [bearbeitePopUp, dispatch]);


    return (
        <div className="data_grid_container data_grid_with_header  mt-2 mt-md-3">
            {bearbeitePopUp.show && <StrassenTabVerwaltePopup selectedItem={selectedItem} row={bearbeitePopUp.row} callback={handleCallBack} />}
            <StrasseGrid refresh_grid={refreshGrid} selectedItem={selectedItem} setBearbeitePopup={setBearbeitePopup} setSelectedItem={setSelectedItem} />
        </div>
    )
}

export default memo(StrassenTab);


const delete_strasse = (dispatch: any, data: any, callback: any) => {
    dispatch(setConfirm({
        body:
            <div className="alert alert-danger">
                <p>Wollen sie die Straße <b>{data.f001}</b> wirklich löschen?</p>
            </div>,
        on_ok: () => {
            GetData({
                Operation: "DeleteStrasse",
                LfdNr: data.lfdnr
            }, dispatch).then((res) => {
                if (!res) return
                dispatch(setToast({
                    message: "Straße wurde erfolgreich gelöscht",
                    type: "success",
                    position: "bottom center"
                }));
                callback();
            });
        },
        title: "Löschen",
    }));
}