import React, { memo, useMemo } from "react"
import { IFoto } from "../../../interfaces";
import { useCallback } from "react";
import { Popup } from "devextreme-react";
import { ToolbarItem } from "devextreme-react/autocomplete";
import Gallery from 'devextreme-react/gallery';
import { useScreenSize } from "../../../../../global/media-query";
import { handleErrorImg, handleContentReadyGallery } from "../../../../../global/hilfs_functions";


interface IPopUpGalleryProps {
    onHide: () => void;
    fotos: IFoto[],
    selectedItem: number,
}


const PopUpGallery: React.FC<IPopUpGalleryProps> = memo(({ onHide, fotos, selectedItem }) => {
    const screenSize = useScreenSize();
    const memo_options_abbrechen = useMemo(() => {
        return {
            text: 'Schließen',
            onClick: onHide,
        }
    }, [onHide]);

    /* brauchen wir nicht mehr
    const handleItemClick = useCallback((item: any) => {
        window.open(item.itemData.url, '_blank');
    }, []);
*/

    const handleOptionChanged = useCallback((data: any) => {
        if (data.name === "selectedItem") {
            const value = data.value;
            const ind = fotos.indexOf(value);
            const arr: Element[] = data.element.querySelectorAll(".dx-gallery-indicator-item");
            arr.forEach((item, i) => {
                if (i === ind) {
                    item.ariaSelected = "true"
                    item.ariaDisabled = "true"
                } else {
                    item.ariaSelected = "false"
                    item.ariaDisabled = "false"
                }
            });
        }
    }, [fotos]);

    return (

        <Popup
            visible={true}
            onHiding={onHide}
            dragEnabled={true}
            hideOnOutsideClick={true}
            showCloseButton={true}
            maxHeight="75vh"
            maxWidth={(screenSize.isMedium || screenSize.isSmall) ? "90vw" : "900px"}
        >
            <ToolbarItem
                toolbar="top"
                location={'before'}
                render={RenderePopupTitle}
            />
            <ToolbarItem
                widget="dxButton"
                toolbar="bottom"
                location="after"
                options={memo_options_abbrechen}
            />


            <Gallery
                dataSource={fotos}
                loop={true}
                showIndicator={true}
                // onItemClick={handleItemClick}
                height={"100%"}
                defaultSelectedItem={fotos[selectedItem]}
                showNavButtons={true}
                itemRender={RenderFoto}
                onContentReady={handleContentReadyGallery}
                onOptionChanged={handleOptionChanged}
            />  </Popup>

    )
})

export default memo(PopUpGallery);
const RenderFoto = (foto: IFoto) => {
    return (
        <img src={foto.url} alt={foto.alt} className="img-fluid popup_bild_gross" onError={handleErrorImg} />
    );
}
const RenderePopupTitle = () => {
    return (<h2 className="title_popup">Bilder</h2>)
};
