import { memo, useCallback } from "react"
import { DataGrid, LoadPanel } from "devextreme-react";
import { Column, Toolbar, Button, Item as ItemGridToolbar } from "devextreme-react/data-grid";
import { useEffect, useState } from "react";
import { GetData } from "../../../global/communication";
import { exporte_columns } from "../../../sonstiges/columns";
import { setAlert, setConfirm, useAppDispatch } from "../../../state";
import { RendereGridTitleExporteBearbeiten } from "../../../sonstiges/hilfs_functions";
import { MDButton } from "../../../global/components";
import { IExport } from "../interfaces";
import { icon_edit, icon_import, scroll_mode_infinite } from "../../../global/const";
import { handleContentReadyDataGrid } from "../../../global/hilfs_functions";
import { IColumn } from "../../../global/interfaces";


interface IDatenExportBearbeitenGridProps {
    setBearbeitePopup: (value: { show: boolean, exportnummer: number }) => void,
}
const DatenExportBearbeitenGrid: React.FC<IDatenExportBearbeitenGridProps> = ({ setBearbeitePopup }) => {
    const [data, setData] = useState<IExport[]>([]);
    const columns = exporte_columns();
    const [isloading, setIsloading] = useState(true);
    const dispatch = useAppDispatch();

    useEffect(() => {
        GetData(
            { Operation: "GetExporte" }, dispatch
        )
            .then((res) => {
                setIsloading(false);
                if (!res) {
                    setData([]);
                    return
                }
                setData(res.data)
            });
    }, [dispatch]);


    const handleClickExportStarten = useCallback((e: any) => {
        e.event.stopPropagation();
        ExportStarte(dispatch, e.row.data.exportnummer);
    }, [dispatch]);

    const handleClickExportBearbeiten = useCallback((e: any) => {
        e.event.stopPropagation();
        setBearbeitePopup({ exportnummer: e.row.data.exportnummer, show: true, });
    }, [setBearbeitePopup]);


    return (
        <>
            {isloading ?
                <LoadPanel visible={true} /> :
                <div className="data_grid_container mt-2 mt-md-3">
                    <DataGrid
                        dataSource={data}
                        columnAutoWidth={false}
                        allowColumnResizing={true}
                        wordWrapEnabled={true}
                        rowAlternationEnabled={true}
                        noDataText={"Keine Daten"}
                        scrolling={scroll_mode_infinite}
                        keyExpr={grid_key}
                        onContentReady={handleContentReadyDataGrid}
                        renderAsync={true}
                    >
                        {
                            columns.map((column: IColumn) => {
                                return (
                                    <Column
                                        key={column.dataField}
                                        dataField={column.dataField}
                                        caption={column.caption}
                                        dataType={column.dataType}
                                        alignment={column.alignment}
                                        width={column.width}
                                        cellRender={column.cellRender}
                                    />
                                )
                            })
                        }
                        <Column
                            type='buttons'
                        >
                            <Button
                                cssClass={'d-inline-block'}
                                onClick={handleClickExportStarten}
                                render={RenderButtonExportStarten}
                            ></Button>
                            <Button
                                cssClass={'d-inline-block'}
                                onClick={handleClickExportBearbeiten}
                                render={RenderButtonExportBearbeiten}

                            ></Button>
                        </Column>
                        <Toolbar>
                            <ItemGridToolbar
                                location={'before'}
                                render={RendereGridTitleExporteBearbeiten}
                            />
                        </Toolbar>
                    </DataGrid>
                </div>
            }
        </>);
}

export default memo(DatenExportBearbeitenGrid)

const grid_key = ['exportnummer'];
const RenderButtonExportStarten: React.FC = () => {
    return (<MDButton icon={icon_import} tooltip_text="Export starten" page="daten_export_bearbeiten" />)
}
const RenderButtonExportBearbeiten: React.FC = () => {
    return (<MDButton icon={icon_edit} tooltip_text="Export bearbeiten" page="daten_export_bearbeiten" />)
}


const ExportStarte = (dispatch: any, exportnummer: any) => {
    dispatch(setConfirm({
        body:
            <div className="alert alert-info">
                <p>Wollen Sie den Export <strong>({exportnummer})</strong> starten?</p>
            </div>,
        on_ok: () => {
            GetData(
                {
                    Operation: "CreateAusgabedateienErzeugenExport",
                    EXPORTFALLART: "VOWI", //Bei dem alten Programm ist es so. gibt es nicht mehr
                    EXPORTNUMMER: exportnummer
                }, dispatch
            )
                .then((res) => {
                    if (!res) return

                    dispatch(setAlert({
                        text: "Datenexport erfolgreich ausgeführt.",
                        type: "Info",
                    }))
                });
        },
        title: "Export",
    }));

}