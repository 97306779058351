import { useMemo, memo } from "react";
import { get_fall_data_zusaetzlich } from "../hilfs_functions";
import { IFallData, IFallDataZusaetzlich } from "../interfaces";
import './card.scss';
import CardFront from "./card_front";

export interface ICardProps {
    item: IFallData,
}


const Card: React.FC<ICardProps> = ({ item }): JSX.Element => {

    const fall_data_zusaetzlich: IFallDataZusaetzlich = useMemo(() => get_fall_data_zusaetzlich(item), [item]);
    /*
    const [selectedTab, setSelectedTab] = useState(0);
    const handleChangeCard = (args: any) => {
        args.name === 'selectedIndex' &&
            setSelectedTab(args.value);
    }*/

    return (
        <li className="col-11 col-sm-9 col-md-6 col-lg-5 col-xxl-4 col-xxxl-3 mx-auto p-2 p-lg-3">
            <div className="card_fall">
                {/** MultiView will ich nicht mehr benutzen wegen barrierfreiheit
                 *   <MultiView
                    dataSource={[{ data: item, dataZus: fall_data_zusaetzlich }, { data: item, dataZus: fall_data_zusaetzlich }]}
                    //dataSource={testData}
                    itemComponent={CardContent}
                    // itemRender={CardContent}
                    selectedIndex={selectedTab}
                    onOptionChanged={handleChangeCard}
                    loop={true}
                    animationEnabled={true}
                >
                </MultiView>
                 * 
                 */}


                <CardFront item={item} itemZus={fall_data_zusaetzlich} />

            </div>
        </li>
    )

}

export default memo(Card);


/*
const CardContent = (data: any) => {

    if (data.index === 0) {
        return (
            <CardFront item={data.data.data} itemZus={data.data.dataZus} />
        )
    } else if (data.index === 1) {
        return (
            <CardBack item={data.data.data} itemZus={data.data.dataZus} />
        );
    } else {
        return (<></>)
    }
}

*/