
import { useCallback } from "react";
import { memo } from "react";
import { icon_listen_ansicht } from "../../../../../global/const";
import { setDataTempSatz, setFaelleDarstellung, useAppDispatch } from "../../../../../state";
import { MDButton } from "../../../../../global/components";
import { IFallData } from "../../../interfaces";


interface IListenAnsichtProps {
    item: IFallData,
}
const ListenAnsicht: React.FC<IListenAnsichtProps> = ({ item }) => {
    const dispatch = useAppDispatch();

    const handleClick = useCallback(() => {
        dispatch(setDataTempSatz(item))
        dispatch(setFaelleDarstellung(true));
    }, [dispatch, item]);

    return (
        <li>
            <MDButton
                icon={icon_listen_ansicht}
                page="fall_card"
                tooltip_text={"Fall in Listenansicht öffnen"}
                onClick={handleClick}
            /></li>
    );
}
export default memo(ListenAnsicht);