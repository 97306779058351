
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { DataGrid, TextBox } from "devextreme-react";
import { Column, Toolbar, Item as ItemGridToolbar, Button as ButtonGrid } from "devextreme-react/data-grid";
import { ISelectedItemKategorie } from "..";
import { format_haupt_gruppe_verwendet, haupt_gruppen_columns } from "../../../../sonstiges/columns";
import { exportGridAsPDF, get_header_pdf_search, get_search_as_filter, handleContentReadyDataGrid, handleContentReadyTextBox } from "../../../../global/hilfs_functions";
import HauptGruppenAdd from "./add";
import { ITatbestandHauptKategorie } from "../../tatbestaende/interfaces";
import { GetData } from "../../../../global/communication";
import { useAppDispatch } from "../../../../state";
import { RendereGridTitleTatbestandKategorien } from "../../../../sonstiges/hilfs_functions";
import { MDButton } from "../../../../global/components";
import { icon_edit, icon_pdf, scroll_mode_infinite } from "../../../../global/const";

interface IHauptGruppenGridProps {
    selectedItem: any,
    setSelectedItem: (obj: ISelectedItemKategorie) => void,
    setBearbeitePopup: (value: { show: boolean, row: ITatbestandHauptKategorie | null }) => void,
    refresh_grid: boolean,
}



const columns = haupt_gruppen_columns();
const HauptGruppenGrid: React.FC<IHauptGruppenGridProps> = ({ setSelectedItem, selectedItem, setBearbeitePopup, refresh_grid }) => {
    const [data, setData] = useState<ITatbestandHauptKategorie[]>([]);
    const refGrid = useRef() as React.MutableRefObject<DataGrid<any, any>>;
    const [searchValue, setSearchValue] = useState("");
    const filter = useMemo(() => get_search_as_filter(searchValue), [searchValue]);
    const dispatch = useAppDispatch();

    const get_data = useCallback(() => {
        GetData(
            {
                Operation: "GetHauptgruppen",
                filter: filter,
            }, dispatch
        )
            .then((res) => {
                if (!res) return
                setData(res.data);
            });
    }, [filter, dispatch])

    useEffect(() => {
        get_data();
    }, [filter, get_data]);



    useEffect(() => {
        get_data();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refresh_grid, get_data]);

    const handleClickSelect = useCallback((e: any) => {
        if (e.event.originalEvent.currentTarget.role !== "button") {
            //e.event.stopPropagation();
            setSelectedItem({ ...selectedItem, haupt_gruppen_nummer: e.data.lfdnr, haupt_gruppen_bezeichnung: e.data.f003 })
        }
    }, [setSelectedItem, selectedItem]);


    const handleBearbeiteHauptGruppe = useCallback((e: any) => {
        setBearbeitePopup({ show: true, row: JSON.parse(e.element.getAttribute("data")) })
    }, [setBearbeitePopup]);

    const handlePDF = useCallback(() => {
        let header = get_header_pdf_search(searchValue)
        exportGridAsPDF(refGrid, "HauptGruppen.pdf", header, "landscape", "striped", (cellData: any) => {
            if (cellData.gridCell.column.dataField === 'f005' && cellData.gridCell.rowType === 'data') {
                cellData.pdfCell.text = format_haupt_gruppe_verwendet(parseInt(cellData.pdfCell.text));
            } else if (cellData.pdfCell.text === 'Darstellung' && cellData.gridCell.rowType === 'header') {
                cellData.pdfCell.text = "Bildnummer";
            }
        })
    }, [searchValue]);

    const RenderButtonBearbeite = useCallback((e: any) => {

        return (<MDButton
            page='tatbestand_kategorie'
            tooltip_text={'Hauptgruppe mit der Nummer ' + e.data.lfdnr + ' bearbeiten '}
            icon={icon_edit}
            onClick={handleBearbeiteHauptGruppe}
            data={e.data}
        />)
    }, [handleBearbeiteHauptGruppe]);

    return (
        <div className="data_grid_container  data_grid_with_header mt-2 mt-md-3">
            <DataGrid
                dataSource={data}
                columnAutoWidth={false}
                keyExpr="lfdnr"
                ref={refGrid}
                renderAsync={true}
                scrolling={scroll_mode_infinite}
                wordWrapEnabled={true}
                onRowClick={handleClickSelect}
                rowAlternationEnabled={true}
                hoverStateEnabled={true}
                className="cursor_pointer"
                noDataText="Keine Tatbestandkategorien"
                onContentReady={handleContentReadyDataGrid}
            >

                {columns.map((column) => {
                    return (
                        <Column
                            dataField={column.dataField}
                            caption={column.caption}
                            dataType={column.dataType}
                            alignment={column.alignment}
                            cellRender={column.cellRender}
                            key={column.dataField}
                            minWidth={column.minWidth}
                            visible={column.visible}
                        />
                    )
                })}
                <Column type="buttons">
                    <ButtonGrid
                        cssClass={'d-inline-block'}
                        render={RenderButtonBearbeite} />
                </Column>
                <Toolbar>
                    <ItemGridToolbar
                        location={'before'}
                        render={RendereGridTitleTatbestandKategorien}
                    ></ItemGridToolbar>
                    <ItemGridToolbar
                        location={'after'}
                        locateInMenu="never"
                    >
                        <TextBox
                            label="Suche"
                            stylingMode='outlined'
                            mode='text'
                            className="suche_feld"
                            value={searchValue}
                            onValueChange={setSearchValue}
                            showClearButton={true}
                            maxLength={60}
                            onContentReady={handleContentReadyTextBox}>
                        </TextBox>
                    </ItemGridToolbar>
                    <ItemGridToolbar location="after" locateInMenu="auto">
                        <HauptGruppenAdd get_data={get_data} />
                    </ItemGridToolbar>
                    <ItemGridToolbar location="after" locateInMenu="auto">
                        <MDButton
                            page="tatbestand_kategorien"
                            icon={icon_pdf}
                            tooltip_text="Tabelle als PDF herunterladen"
                            onClick={handlePDF}
                        />
                    </ItemGridToolbar>
                </Toolbar>
            </DataGrid>
        </div>
    )
}

export default memo(HauptGruppenGrid);

/*
const RenderButtonDelete: React.FC = () => {
    return(
        <MDButton 
          page='tatbestand_kategorie'
          tooltip_text='Hauptgruppe löschen'
          icon={icon_delete}
          type='danger'
          className="mr-2"
        />)
}*/

