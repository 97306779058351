import { useEffect, useRef, useState } from "react";
import TabPanel, { Item } from "devextreme-react/tab-panel";
import HauptGruppen from "./haupt_gruppen";
import UnterGruppen from "./unter_gruppen";

export interface ISelectedItemKategorie {
    haupt_gruppen_nummer?: number,
    haupt_gruppen_bezeichnung?: string,
}

const TatbestaendeKategorie: React.FC = () => {
    const refTabPanel = useRef() as React.MutableRefObject<any>;

    const [selectedItem, setSelectedItem] = useState<ISelectedItemKategorie>({})
    //  const [selectedIndexTabPanel, setSelectedIndexTabPanel] = useState(selectedItem.haupt_gruppen_nummer ? 1 : 0);
    const [selectedIndexTabPanel, setSelectedIndexTabPanel] = useState(0);


    useEffect(() => {
        // Es kann auch sein dass eine Gruppe die Nummer 0 hat.
        if ((selectedItem.haupt_gruppen_nummer) || selectedItem.haupt_gruppen_nummer === 0) {
            setSelectedIndexTabPanel(1);
        } else {
            setSelectedIndexTabPanel(0);
        }
    }, [selectedItem])

    const handleChangeSelectedItem = (value: number) => {
        value === 0 ? setSelectedItem({}) : setSelectedItem({ ...selectedItem });
    }

    return (
        <>
            <TabPanel ref={refTabPanel} animationEnabled={true} className="mt-1 mt-md-3" selectedIndex={selectedIndexTabPanel} onSelectedIndexChange={handleChangeSelectedItem}>
                <Item title="Hauptgruppen" ><HauptGruppen selectedItem={selectedItem} setSelectedItem={setSelectedItem}></HauptGruppen></Item>
                <Item title='Untergruppen' disabled={selectedItem.haupt_gruppen_nummer !== undefined ? false : true}> <UnterGruppen selectedItem={selectedItem} setSelectedItem={setSelectedItem} /></Item>
            </TabPanel>
        </>

    )
}

export default TatbestaendeKategorie;
