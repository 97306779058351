import { Toast } from "devextreme-react";
import { useCallback } from "react";
import { resetAlert, resetConfirm, resetToast } from "./notificationsSlice";
import { useAppDispatch, useAppSelector } from "../state"
import Alert from "./alert";
import Confirm from "./confirm";
import { PositionConfig } from "devextreme/animation/position";


const MDNotifications = () => {

  const alert = useAppSelector((state) => state.store_notification.alert);
  const toast = useAppSelector((state) => state.store_notification.toast);
  const confirm = useAppSelector((state) => state.store_notification.confirm);
  const dispatch = useAppDispatch();
  const position = get_position_object(toast.position);


  const handleHiding = useCallback(() => {
    dispatch(resetAlert());
    alert.onHiding && alert.onHiding();
  }, [dispatch, alert]);

  const handleToastHiding = useCallback(() => {
    dispatch(resetToast());
  }, [dispatch]);

  const handleConfirmHiding = useCallback(() => {
    dispatch(resetConfirm());
    confirm.on_hiding && confirm.on_hiding();
  }, [dispatch, confirm]);

  //MD 30.07.2023 hat gefehlt
  const handleConfirmAbbrechen = useCallback(() => {
    dispatch(resetConfirm());
    confirm.on_abbrechen && confirm.on_abbrechen();
    confirm.on_hiding && confirm.on_hiding();
  }, [dispatch, confirm]);
  const handleConfirmOK = useCallback(() => {
    dispatch(resetConfirm());
    confirm.on_ok && confirm.on_ok();
  }, [dispatch, confirm]);


  return (
    <>
      {
        alert.show &&
        <Alert
          text={alert.text}
          type={alert.type}
          title={alert.title || alert.type}
          onHiding={handleHiding}
          maxWidth={alert.maxWidth}
        />
      }{
        toast.show &&
        <Toast
          visible={toast.show}
          message={toast.message}
          type={toast.type}
          hideOnOutsideClick={true}
          position={position}
          //position={toastAllgemein.position} 
          onHiding={handleToastHiding}
          displayTime={toast.displayTime}
        />
      }{
        confirm.show &&
        <Confirm
          on_ok={handleConfirmOK}
          ok_text={confirm.ok_text}
          on_abbrechen={handleConfirmAbbrechen}
          abbrechen_text={confirm.abbrechen_text}
          on_hiding={handleConfirmHiding}
          title={confirm.title}
          body={confirm.body}
        />
      }
    </>
  );
}

export default MDNotifications;



const get_position_object = (postion?: string): PositionConfig => {
  if (!postion) return {
    my: 'center',
    at: 'center',
    of: window,
    offset: '0 0'
  };
  //Wenn notwendig andere Postionen auch implementieren
  switch (postion) {
    case "center center":
      return {
        at: 'center',
        my: 'center',
        of: window,
        offset: '0 0'
      }
    case "bottom center":
      return {
        at: "bottom",
        my: "center",
        of: window,
        offset: '0 -40'
      }
    case "bottom left":
      return {
        at: { x: "left", y: "bottom" },
        //  my: 'left',  
        of: window,
        offset: '300 -40'
      }
    default:
      return {
        my: 'center',
        at: 'center',
        of: window,
        offset: '0 0'
      };
  }
}
