import { memo, useCallback, useState } from "react";
import { icon_plus } from "../../../global/const";
import { MDButton } from "../../../global/components";
import KFZHinweiseVerwaltePopup from "./verwalten_popup";

interface IKFZHinweisAddProps {
    kategorieArray: string[],
    callback: () => void,
}
const KFZHinweiseAdd: React.FC<IKFZHinweisAddProps> = ({ kategorieArray, callback }): JSX.Element => {
    const [showPopUp, setShowPopUp] = useState(false);
    const handleClick = useCallback(() => {
        setShowPopUp(true)
    }, []);

    const handleCallback = useCallback((update?: boolean) => {
        setShowPopUp(false);
        update && callback()
    }, [callback]);

    return (
        <div id="kfz_hinweis_add_button">
            <MDButton
                icon={icon_plus}
                type="success"
                tooltip_text="Neuen KFZ-Hinweis anlegen"
                onClick={handleClick}
                page="kfz_hinweis"
            />
            {showPopUp && <KFZHinweiseVerwaltePopup kategorie={kategorieArray} callBack={handleCallback} />}
        </div>
    )
}

export default memo(KFZHinweiseAdd);