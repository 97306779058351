import { ISelectedItemKategorie } from "..";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { DataGrid, TextBox } from "devextreme-react";
import { Column, Toolbar, Item as ItemGridToolbar, Button as ButtonGrid } from "devextreme-react/data-grid";
import { format_unter_gruppe_verwendet, unter_gruppen_columns } from "../../../../sonstiges/columns";
import { exportGridAsPDF, get_header_pdf_search, get_search_as_filter, handleContentReadyDataGrid, handleContentReadyTextBox } from "../../../../global/hilfs_functions";
import { ITatbestandUnterKategorie } from "../../tatbestaende/interfaces";
import { GetData } from "../../../../global/communication";
import UnterGruppenAdd from "./add";
import { useAppDispatch } from "../../../../state";
import { MDButton, RendereGridTitle } from "../../../../global/components";
import { icon_edit, icon_pdf, scroll_mode_infinite } from "../../../../global/const";


interface IUnterGruppenGridProps {
    selectedItem: ISelectedItemKategorie,
    setSelectedItem: (obj: ISelectedItemKategorie) => void,
    setBearbeitePopup: (value: { show: boolean, row: ITatbestandUnterKategorie | null }) => void,
    refresh_grid: boolean,
}

const UnterGruppenGrid: React.FC<IUnterGruppenGridProps> = ({ refresh_grid, selectedItem, setBearbeitePopup, setSelectedItem }) => {

    const [data, setData] = useState<ITatbestandUnterKategorie[]>([]);
    const columns = unter_gruppen_columns();
    const refGrid = useRef() as React.MutableRefObject<DataGrid<any, any>>;
    const [searchValue, setSearchValue] = useState("");
    const filter = useMemo(() => get_search_as_filter(searchValue), [searchValue]);
    const dispatch = useAppDispatch();
    useEffect(() => {
        get_data();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter]);

    const get_data = useCallback(() => {
        GetData(
            {
                Operation: "GetUntergruppen",
                Hauptgruppennummer: selectedItem.haupt_gruppen_nummer!.toString(),
                filter: filter,
            }, dispatch
        )
            .then((res) => {
                if (!res) return
                setData(res.data);
            });
    }, [filter, selectedItem, dispatch])

    useEffect(() => {
        setData([]);
        get_data();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refresh_grid]);


    const handleBearbeiteUnterGruppen = useCallback((e: any) => {
        setBearbeitePopup({ show: true, row: JSON.parse(e.element.getAttribute("data")) })
    }, [setBearbeitePopup]);

    const handlePDF = useCallback(() => {
        let header = get_header_pdf_search(searchValue);
        exportGridAsPDF(refGrid, "Untergruppen.pdf", header, "landscape", "striped", (cellData: any) => {
            if (cellData.gridCell.column.dataField === 'f005' && cellData.gridCell.rowType === 'data') {
                cellData.pdfCell.text = format_unter_gruppe_verwendet(parseInt(cellData.pdfCell.text));
            } else if (cellData.pdfCell.text === 'Darstellung' && cellData.gridCell.rowType === 'header') {
                cellData.pdfCell.text = "Bildnummer";
            }
        })
    }, [searchValue]);

    const RendereTitle = useCallback(() => {
        return RendereGridTitle(selectedItem.haupt_gruppen_bezeichnung ? "Tatbestand Kategorien Hauptgruppe: " + selectedItem.haupt_gruppen_bezeichnung : "")
    }, [selectedItem.haupt_gruppen_bezeichnung]);

    const RenderButtonBearbeite = useCallback((e: any) => {
        return (<MDButton
            page='tatbestand_kategorien'
            tooltip_text='Untergruppe bearbeiten'
            icon={icon_edit}
            onClick={handleBearbeiteUnterGruppen}
            data={e.data}
        />)
    }, [handleBearbeiteUnterGruppen]);

    return (
        <div className="data_grid_container  data_grid_with_header mt-2 mt-md-3">
            <DataGrid
                dataSource={data}
                columnAutoWidth={false}
                keyExpr="f002"
                ref={refGrid}
                renderAsync={true}
                scrolling={scroll_mode_infinite}
                wordWrapEnabled={true}
                noDataText={""}
                rowAlternationEnabled={true}
                hoverStateEnabled={true}
                onContentReady={handleContentReadyDataGrid}
            >

                {columns.map((column) => {
                    return (
                        <Column
                            dataField={column.dataField}
                            caption={column.caption}
                            dataType={column.dataType}
                            alignment={column.alignment}
                            cellRender={column.cellRender}
                            key={column.dataField}
                            minWidth={column.minWidth}
                            visible={column.visible}
                        />
                    )
                })}
                <Column type="buttons">
                    <ButtonGrid
                        cssClass={'d-inline-block'}
                        render={RenderButtonBearbeite} />
                </Column>
                <Toolbar>
                    <ItemGridToolbar
                        location={'before'}
                        render={RendereTitle}
                    ></ItemGridToolbar>
                    <ItemGridToolbar
                        location={'after'}
                        locateInMenu="never"
                    >
                        <TextBox
                            label="Suche"
                            stylingMode='outlined'
                            mode='text'
                            className="suche_feld"
                            value={searchValue}
                            onValueChange={setSearchValue}
                            showClearButton={true}
                            maxLength={60}
                            onContentReady={handleContentReadyTextBox}>
                        </TextBox>
                    </ItemGridToolbar>
                    <ItemGridToolbar location="after" locateInMenu="auto">
                        <UnterGruppenAdd get_data={get_data} selectedItem={selectedItem} />
                    </ItemGridToolbar>
                    <ItemGridToolbar location="after" locateInMenu="auto">
                        <MDButton
                            page="tatbestand_kategorien"
                            icon={icon_pdf}
                            tooltip_text="Tabelle als PDF herunterladen"
                            onClick={handlePDF}

                        />
                    </ItemGridToolbar>
                </Toolbar>
            </DataGrid>
        </div>
    )
}
export default memo(UnterGruppenGrid);

