import React, { memo, useCallback, useEffect, useState } from 'react';
import Popup from 'devextreme-react/popup';
import ScrollView from 'devextreme-react/scroll-view';
import StatistikIndividuellFilter from './Filter';
import DataGrid, { Scrolling, Column } from 'devextreme-react/data-grid';
import { StatistikTabelleIndividuellSpalten } from '../../../sonstiges/columns';
import { ToolbarItem } from 'devextreme-react/autocomplete';
import { IStatistikIndividuellAuswahl } from '../interfaces';
import { GetData } from '../../../global/communication';
import { useScreenSize } from '../../../global/media-query';
import { useAppDispatch } from '../../../state';
import { useMemo } from 'react';

interface IStatistikDialogProps {
  onOkClick: (daten: string, datenfelder_for_opdf: string, selected: IStatistikIndividuellAuswahl) => void,
  onCloseClick: any,
}

const columns = StatistikTabelleIndividuellSpalten;
const Statisitik_Individuell_Tabelle: React.FC<IStatistikDialogProps> = ({ onOkClick, onCloseClick }): JSX.Element => {
  const [data, setData] = useState<IStatistikIndividuellAuswahl[]>([]);
  const [hidePopUp, sethidePopUp] = useState<Boolean>(true);
  const [selected, setSelected] = useState<IStatistikIndividuellAuswahl>();
  const screenSize = useScreenSize();
  const dispatch = useAppDispatch();

  useEffect(() => {
    GetData(
      {
        Operation: "GetStatistikIndividuell",
      }, dispatch
    )
      .then((res) => {
        if (!res) {
          setData([]);
          return
        }
        setData(res.data);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onPopupAuswertenClick = useCallback((daten: string, datenfelder_for_pdf: string) => {
    sethidePopUp(true);
    selected && onOkClick(daten, datenfelder_for_pdf, selected);
  }, [selected, onOkClick]);;

  const handleRowClick = useCallback((e: any) => {
    setSelected(e.data);
    sethidePopUp(false);
  }, []);

  const handleHidePopup = useCallback(() => {
    sethidePopUp(true)
  }, []);

  const memo_options_abbrechen = useMemo(() => {
    return {
      text: 'Schließen',
      stylingMode: "outlined",
      onClick: onCloseClick,
    }
  }, [onCloseClick]);
  return (
    <Popup
      visible={true}
      hideOnOutsideClick={true}
      showCloseButton={true}
      animation={undefined}
      onHiding={onCloseClick}
      // width={800}
      // height={height} Keine statische width oder hight definieren Benutzerfreundlichkeit!
      height="auto"
      maxHeight="75vh"
      maxWidth={(screenSize.isMedium || screenSize.isSmall) ? "90vw" : "900px"}
    >
      <ToolbarItem
        toolbar="top"
        location={'before'}
        render={RenderePopupTitle}
      />
      <ScrollView width='100%' height='100%'>
        <DataGrid
          showBorders={true}
          columnAutoWidth={false}
          allowColumnResizing={true}
          rowAlternationEnabled={true}
          focusedRowEnabled={false}
          renderAsync={true}
          keyExpr={'f003'}
          wordWrapEnabled={true}
          dataSource={data}
          onRowClick={handleRowClick}
          noDataText={"Keine Daten"}
          hoverStateEnabled={true}
          className="cursor_pointer"

        /** cursor Pointer??
        onCellPrepared={(e) => {
          if(e.rowType == 'data' && e.column){
                  var elem = e.cellElement;
                  elem.style.setProperty('cursor', 'pointer', 'important');  
          }}} */
        /**  **/
        >
          {/**  <FilterRow visible={true} /> brauchen wir das??*/}

          {
            columns.map((column) => {
              return (
                <Column
                  dataField={column.dataField}
                  caption={column.caption}
                  dataType={column.dataType}
                  width={column.width}
                  key={column.dataField}
                  visible={column.visible}
                />
              )
            })
          }
          {/**
                 *  <Column
                  type='buttons'
                  width={130}          >
                  <Button name={"edit"}  icon="edit" visible={true} onClick={onEditClick}/>
                </Column>
                 */}

          <Scrolling mode={"infinite"} />
        </DataGrid>
      </ScrollView>
      {(!hidePopUp && selected) && <StatistikIndividuellFilter onCloseClick={handleHidePopup} onOkClick={onPopupAuswertenClick} item={selected}></StatistikIndividuellFilter>}
      <ToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="after"
        options={memo_options_abbrechen}
      />
    </Popup>
  );
}

export default memo(Statisitik_Individuell_Tabelle);

const RenderePopupTitle = () => {
  return (<h2 className="title_popup">Statistikauswahl</h2>)
};