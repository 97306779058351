import { memo, useCallback } from "react"
import { DataGrid, LoadPanel } from "devextreme-react";
import { Column, Toolbar, Button, Item as ItemGridToolbar } from "devextreme-react/data-grid";
import { useEffect, useState } from "react";
import { DPVTabellenSpalten } from '../../sonstiges/columns';
import { GetData } from '../../global/communication';
import { IDPVData } from './interfaces';
import { useAppDispatch } from '../../state';
import { RendereGridTitleDoppelVerwarnungen } from "../../sonstiges/hilfs_functions";
import { MDButton } from "../../global/components";
import { icon_edit, scroll_mode_infinite } from "../../global/const";
import { handleContentReadyDataGrid } from "../../global/hilfs_functions";

interface IDoppelVerwarnungGridProps {
  setexportNumber: (value: number) => void,
}
const columns = DPVTabellenSpalten;
const grid_keys = ['f001', 'f010', 'f011'];
const DatenExportBearbeitenGrid: React.FC<IDoppelVerwarnungGridProps> = ({ setexportNumber }) => {
  const [data, setData] = useState<IDPVData[]>([]);
  const [isloading, setIsloading] = useState(true);
  const dispatch = useAppDispatch();

  useEffect(() => {
    GetData(
      {
        Operation: "GetVorverarbeitungsexporte"
      }, dispatch
    )
      .then((res) => {
        if (!res) {
          setData([]);
          setIsloading(false);
          return
        }
        setData(res.data)
        setIsloading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickSetExportNummer = useCallback((e: any) => {
    e.event.stopPropagation();
    setexportNumber(e.row.data.f001)
  }, [setexportNumber]);

  return (
    <>
      {isloading ?
        <LoadPanel visible={true} /> :
        <div className="data_grid_container mt-2 mt-md-3">
          <DataGrid
            //className={'dx-card wide-card'}
            dataSource={data}
            columnAutoWidth={false}
            allowColumnResizing={true}
            wordWrapEnabled={true}
            rowAlternationEnabled={true}
            renderAsync={true}
            className=" animate__animated animate__fadeIn"
            noDataText={"Keine Daten"}
            // onRowClick={(e: any) => {setexportNumber(e.data.f001)}}
            // onRowDblClick={RowDblClick}   
            //  remoteOperations={true}
            scrolling={scroll_mode_infinite}
            //onOptionChanged={onFilterChanged} 
            keyExpr={grid_keys}
            onContentReady={handleContentReadyDataGrid}
          >
            {/** Filter ist momentan nicht erforderlich <FilterRow visible={true}/> */}

            {
              columns.map((column) => {
                return (
                  <Column
                    key={column.dataField}
                    dataField={column.dataField}
                    caption={column.caption}
                    dataType={column.dataType}
                    alignment={column.alignment}
                    width={column.width}
                    cellRender={column.cellRender}

                  />
                )
              })
            }
            <Column
              type='buttons'>
              <Button
                cssClass={'d-inline-block'}
                onClick={handleClickSetExportNummer}
                render={RenderButtonSetExportNummer}
              ></Button>

            </Column>
            <Toolbar>
              <ItemGridToolbar
                location={'before'}
                render={RendereGridTitleDoppelVerwarnungen}
              />
            </Toolbar>
          </DataGrid></div>
      }
    </>);
}

export default memo(DatenExportBearbeitenGrid)

const RenderButtonSetExportNummer: React.FC = () => {
  return (<MDButton icon={icon_edit} tooltip_text="Doppelverwarnung bearbeiten" page="doppel_verwarnung" />)
}