import { exportDataGrid } from "devextreme/pdf_exporter";
import { Seperatorliste } from "./seperator_liste"
import * as events from 'devextreme/events';
import ScrollView from "devextreme/ui/scroll_view";
import TextBox from "devextreme/ui/text_box";
import CheckBox from "devextreme/ui/check_box";
import { icon_battery, icon_code, icon_file, icon_history, icon_info, icon_preferences } from "./const";
import { Workbook } from 'exceljs';
import { exportDataGrid as exportDataGridAsExcel } from "devextreme/excel_exporter";
import { saveAs } from 'file-saver';
import jsPDF from "jspdf";


export const get_column_min_width = (anzahl_buchstaben: number): number => {
    if (!anzahl_buchstaben) return 10 // devexpress Default
    return 32 + ((anzahl_buchstaben + 2) * 8)// 16 pl + 16 pr + 8 px für ein Buchstabe addiere 2 für Sortiere symbol
}
export const handleErrorImg = (e: any) => {
    e.target.alt = "Dieses Foto wurde auf dem Server nicht gefunden."
}

export const dbTimeToString = function (dbTime: number): string {
    if (!dbTime) return "";
    let pad = "00";
    let dbTimeString = dbTime.toString();
    let uhrHH = dbTimeString.substring(dbTimeString.length - 9, dbTimeString.length - 7);
    let uhrMM = dbTimeString.substring(dbTimeString.length - 7, dbTimeString.length - 5);
    return (dbTimeString) ?
        pad.substring(0, pad.length - uhrHH.length) + uhrHH
        + ":"
        + pad.substring(0, pad.length - uhrMM.length) + uhrMM : "";
}

export const get_search_as_filter = (seachValue: string) => {
    let filter_end = new Seperatorliste("%00", "%01");
    seachValue && filter_end.setValue("Suchbegriff", seachValue);
    return filter_end.getSeperatorliste();
}

export const get_header_pdf_search = (searchValue: string) => {
    let header = "";
    searchValue && (header += "Suchbegriff: " + searchValue + "\n");
    return header
}

function set_aria_label_toolbar(e: any) {
    e.element.querySelectorAll(".dx-toolbar-before, .dx-toolbar-center, .dx-toolbar-after").forEach((item1: Element) => {
        item1.setAttribute("role", "list");
        item1.querySelectorAll(".dx-toolbar-item, .dx-toolbar-menu-container").forEach((item2: Element) => {
            if ((item2.className.indexOf("dx-toolbar-item-invisible") === -1) && (item2.className.indexOf("dx-state-invisible") === -1)) {
                item2.setAttribute("role", "listitem");
            } else {
                item2.setAttribute("aria-hidden", "true");
            }
        })
    });
}
export function handleContentReadyToolbar(e: any) {
    if (!e.component._MD_Initialized) {
        set_aria_label_toolbar(e);
        e.component._MD_Initialized = true;
    }
}


export const setTooltipEvents = (item: any, setTooltipVisible: (value: boolean) => void) => {
    events.on(item.element, 'dxhoverstart', () => {
        setTooltipVisible(true);
    });
    events.on(item.element, 'dxhoverend', () => {
        setTooltipVisible(false);
    });
}


export const exportGridAsPDF = (refGrid: any, pdf_name: string, header: string = '', orientation: "portrait" | "landscape" = 'landscape', theme: 'striped' | 'plain' | 'grid' = 'striped', customizeCell?: any) => {

    const doc = new jsPDF({
        orientation: orientation
    });
    let rows = 0;

    if (header) {
        header.split('').forEach((s) => {
            if (s === '\n') {
                rows++;
            }
        })

        doc.setFontSize(12)
        doc.text(header, 15, 10, {
            //Wird dann automatisch gesplittet.
            maxWidth: orientation === "landscape" ? 270 : 130
        })


    }
    exportDataGrid({
        jsPDFDocument: doc,
        component: refGrid.current!.instance,
        customizeCell: (cellData: any) => {
            customizeCell && customizeCell(cellData);
            /*
            if (cellData.gridCell.rowType === 'data') {
              cellData.pdfCell.text.length > 35 &&
                (cellData.pdfCell.styles.cellWidth = 50)
            }*/
            if (cellData.gridCell.rowType === 'header') {
                cellData.pdfCell.backgroundColor = '#195DA3'
                cellData.pdfCell.textColor = '#fff'

            }
        },

        margin: { top: rows * 6 + 13, right: 5, left: 10 },

        /*
        autoTableOptions: { 
          margin: { top: rows*6 + 13, right: 5 }, 
          theme: theme,
    //      tableWidth: 'wrap',
          horizontalPageBreak: true,
          columnStyles: {
          //  cellWidth: 5,
          },
        
        //  styles: { overflow: 'linebreak' },
         // bodyStyles: { overflow: 'linebreak'  },
     
    //    rowPageBreak: 'auto',
      headStyles: {
        textColor: 255, fontStyle: 'bold'
      },
        },*/
    }).then(() => {
        doc.save(pdf_name);
    });

};

export const customItemCreating = (args: any) => {
    if (!args.text) {
        args.customItem = null;
        return;
    }
    args.customItem = args.text;
};


export const stretch0Left = (s: string, length: number): string => {
    while (s.length < length)
        s = "0" + s;
    return s;
}
export const stretch0Right = (s: string, length: number): string => {
    while (s.length < length)
        s = s + "0";
    return s;
}

export const get_date_ajax_param = (date: Date | undefined, WithTime: boolean = false): string => {
    if (!date) return ""
    if (WithTime)
        return stretch0Left(date.getDate().toString(), 2) + "." +
            stretch0Left((date.getMonth() + 1).toString(), 2) + "." +
            date.getFullYear().toString() + " " +
            stretch0Left((date.getHours()).toString(), 2) + ":" +
            stretch0Left((date.getMinutes()).toString(), 2) + ":" +
            stretch0Left((date.getSeconds()).toString(), 2);
    else
        return stretch0Left(date.getDate().toString(), 2) + "." +
            stretch0Left((date.getMonth() + 1).toString(), 2) + "." +
            date.getFullYear().toString();
}
export const get_uhrzeit_aus_datetime = (date: Date | undefined): number => {
    if (!date) return 0
    return parseInt(stretch0Left(date.getHours().toString(), 2) + stretch0Left(date.getMinutes().toString(), 2));
}


export const get_date_from_string = (str: string, uhrzeit?: number): Date => {
    if (!str) return new Date();
    //Datetime
    if (str.split(" ").length === 2) {
        if (str.split(" ")[0].split(".").length !== 3 || str.split(" ")[1].split(":").length !== 3) return new Date();
        return new Date(
            parseInt(str.split(" ")[0].split(".")[2]),
            parseInt(str.split(" ")[0].split(".")[1]) - 1,
            parseInt(str.split(" ")[0].split(".")[0]),
            parseInt(str.split(" ")[1].split(":")[0]),
            parseInt(str.split(" ")[1].split(":")[1]),
            parseInt(str.split(" ")[1].split(":")[2]));
    } else if (uhrzeit) {
        let zeit = format_zeit(uhrzeit);
        return new Date(
            parseInt(str.split(".")[2]),
            parseInt(str.split(".")[1]) - 1,
            parseInt(str.split(".")[0]),
            parseInt(zeit.split(":")[0]),
            parseInt(zeit.split(":")[1]));
        //parseInt(zeit.split(":")[2]));
    } else if (str.split(" ").length === 1) {
        //Date
        if (str.split(".").length !== 3) return new Date();
        return new Date(parseInt(str.split(".")[2]), parseInt(str.split(".")[1]) - 1, parseInt(str.split(".")[0]), 0, 0);
    }
    return new Date();
}

export const OdbcDate = (date: Date): string => {
    if (!date) return ""
    return "{d '" + date.getFullYear().toString() + "-" + stretch0Left((date.getMonth() + 1).toString(), 2) + "-" + stretch0Left(date.getDate().toString(), 2) + "'}"
}


export const format_zeit = (value: number): string => {
    let zeit = stretch0Left(value.toString(), 4)
    return zeit.substr(0, 2) + ":" + zeit.substr(2, 2);
}


export const handleContentReadyDateBox = (e: any) => {
    e.element.querySelectorAll(".dx-clear-button-area").forEach((item: HTMLElement) => item.setAttribute("aria-label", "Datum löschen"));
    if (e.component.option("labelMode") === "static") {
        const label = e.element.querySelector(".dx-placeholder");
        const container = e.element.querySelector(".dx-texteditor-container input"); // hier input sonst sieht nicht gut aus bei Suche im Konfigurator
        (label && container) &&
            container.before(label);
    } else {
        const label = e.element.querySelector(".dx-texteditor-label");
        const container = e.element.querySelector(".dx-texteditor-container");

        (label && container) &&
            container.before(label);
    }
    const clear_icon = e.element.querySelector('.dx-clear-button-area') as HTMLElement;
    if (clear_icon) {
        clear_icon.setAttribute("tabindex", "0");
        clear_icon.setAttribute("role", "button");
        clear_icon.onblur = () => {
            clear_icon.classList.remove("md-dx-state-focused");
        }

        clear_icon.onkeyup = (e1) => {
            if (e1.key === "Enter") {
                e.component.reset();
            } else if (e1.key === "Tab") {
                !clear_icon.classList.contains("md-dx-state-focused") &&
                    clear_icon.classList.add("md-dx-state-focused");
            }
        }


    }

    const dropdown_button = e.element.querySelector('.dx-dropdowneditor-button') as HTMLElement;
    if (dropdown_button) {
        dropdown_button.setAttribute("tabindex", "0");
        /*
        dropdown_button.onfocus = (e: any) => {
          Nicht benutzen, sonst wird das auch getriggert bei Click
          dropdown_button.classList.add("md-dx-state-focused")
        }  */
        dropdown_button.onblur = () => {
            dropdown_button.classList.remove("md-dx-state-focused");
        }

        dropdown_button.onkeyup = (e1) => {
            if (e1.key === "Enter") {
                dropdown_button.click();
            } else if (e1.key === "Tab") {
                !dropdown_button.classList.contains("md-dx-state-focused") &&
                    dropdown_button.classList.add("md-dx-state-focused");
            }
        }
    }


    if (!e.component._MD_Initialized) {
        e.component.option("dropDownOptions", {
            maxHeight: "50vh",
            height: "322",
            onShowing(e: any) {
                const content = e.element.querySelector('.dx-popup-content');
                content && new ScrollView(content, {})
            }
        });

        e.component._MD_Initialized = true;
    }
}


function handleKeyDownDataGrid(e: any) {
    if (e.event.keyCode === 16) {
        e.component._MD_SHIFT_IS_PRESSED = true
    } else if (e.event.keyCode === 9) {
        if (e.event.originalEvent && e.event.originalEvent.target) {
            let myTarget = e.event.originalEvent.target as HTMLElement;
            if (myTarget.tagName !== "TD") {
                //GET TD Dies passiert bei Buttons-column wie Abschleppunternehmen
                myTarget = myTarget.parentElement!.parentElement as HTMLElement;
            }
            if (!e.component._MD_SHIFT_IS_PRESSED) {
                if (!myTarget.nextSibling) { //bin hier
                    let masterDetailsRow = myTarget.parentElement!.nextSibling as HTMLElement;
                    if (masterDetailsRow && masterDetailsRow.classList && masterDetailsRow.classList.contains("dx-master-detail-row")) {
                        e.event.stopPropagation();
                        let tabpanel = masterDetailsRow.querySelector(".dx-tabpanel .dx-tabs.dx-tabs-expanded") as HTMLElement;
                        if (tabpanel) {
                            let event = new CustomEvent("focusout");
                            myTarget.dispatchEvent(event);
                            setTimeout(() => {
                                tabpanel.focus();
                                tabpanel?.classList.add("md-dx-state-focused");
                            }, 20);
                        }
                    }
                }
                if (myTarget.classList.contains("md-dx-state-focused")) {
                    myTarget.classList.remove("md-dx-state-focused");
                }
            } else {
                if (!myTarget.previousSibling) {
                    let masterDetailsRow = myTarget.parentElement!.previousSibling as HTMLElement;
                    if (masterDetailsRow && masterDetailsRow.classList && masterDetailsRow.classList.contains("dx-master-detail-row")) {
                        e.event.stopPropagation();
                        let tabpanel = masterDetailsRow.querySelector(".dx-tabpanel .dx-tabs.dx-tabs-expanded") as HTMLElement;
                        if (tabpanel) {
                            let event = new CustomEvent("focusout");
                            myTarget.dispatchEvent(event);
                            setTimeout(() => {
                                tabpanel.focus();
                                tabpanel?.classList.add("md-dx-state-focused");
                            }, 20);
                        }
                    }
                }
            }
            if (e.event.originalEvent.target.classList.contains("md-dx-state-focused")) {
                e.event.originalEvent.target.classList.remove("md-dx-state-focused");
            }
        }
    }
}
export function handleContentReadyDataGrid(e: any) {

    if (e.element.getAttribute("role") === "presentation") {
        e.element.setAttribute("role", "")
    }
    e.element.querySelectorAll('[role="presentation"]').forEach(function (el: Element) {
        el.setAttribute("role", "");
    });
    set_column_chooser_position(e);
    if (!e.component._MD_Initialized) {
        const interval = setInterval(function () {
            if (e.component._isReady && e.element.querySelector('.dx-datagrid') && e.element.querySelector('.dx-datagrid').getAttribute("aria-colcount")) {
                set_aria_label_toolbar(e);


                if (e.component.columnCount() !== e.component.getVisibleColumns().length) {
                    e.element.querySelector('.dx-datagrid') && e.element.querySelector('.dx-datagrid').setAttribute("aria-colcount", e.component.getVisibleColumns().length.toString())
                }

                let ele: HTMLElement = e.element;
                ele.onkeyup = (e1: any) => {
                    if (e1.keyCode === 16) {
                        e.component._MD_SHIFT_IS_PRESSED = false
                    }
                }
                //Manche Datagrids haben schon bereits onKeyDown events.
                let onKeyDownEnd: any;
                if (e.component.option("onKeyDown")) {
                    e.component._MD_onKeyDown = e.component.option("onKeyDown");
                    onKeyDownEnd = (e1: any) => {
                        e.component._MD_onKeyDown(e1);
                        handleKeyDownDataGrid(e1)
                    }
                } else {
                    onKeyDownEnd = (e1: any) => {
                        handleKeyDownDataGrid(e1)
                    }
                }

                e.component.option("onKeyDown", onKeyDownEnd);
                ele.querySelectorAll(".dx-command-expand").forEach((item: Element) => {
                    (item.firstElementChild) && item.firstElementChild.setAttribute("aria-expanded", "false")
                });
                e.component.option("onRowExpanding", function (e1: any) {
                    e1.component.collapseAll(-1)
                })
                e.component.option("onRowExpanded", function (e1: any) {
                    e1.element.querySelectorAll(".dx-command-expand").forEach((item: Element) => {
                        (item.firstElementChild) && item.firstElementChild.setAttribute("aria-expanded", "false")
                    })

                    let rowIndex = e1.component.getRowIndexByKey(e1.key) + 1;
                    let temp1 = e1.element.querySelector('[aria-rowindex="' + rowIndex + '"]') as HTMLElement;
                    let temp2 = temp1.querySelector(".dx-command-expand") as HTMLElement;
                    (temp2.firstElementChild) && temp2.firstElementChild.setAttribute("aria-expanded", "true")
                })
                e.component.option("onRowCollapsed", function (e1: any) {
                    e1.element.querySelectorAll(".dx-command-expand").forEach((item: Element) => {
                        (item.firstElementChild) && item.firstElementChild.setAttribute("aria-expanded", "false")
                    })
                })
                clearInterval(interval);
            }
        }, 10);
        e.component._MD_Initialized = true;
    }
}

export const handleContentReadyGallery = (e: any) => {
    if (!e.component._MD_Initialized) {
        const next_button = e.element.querySelector(".dx-gallery-nav-button-next") as HTMLElement;
        if (next_button) {
            next_button.ariaLabel = "Nächstes Foto ansehen";
            next_button.setAttribute("tabindex", "0");
            next_button.setAttribute("role", "button");
            next_button.onkeyup = (e: any) => {
                if (e.key === "Enter") {
                    next_button.click();
                }
            }
            next_button.onfocus = () => {
                next_button.classList.add("md-dx-state-focused");
            }
            next_button.onblur = () => {
                next_button.classList.remove("md-dx-state-focused");
            }
        }
        const prev_button = e.element.querySelector(".dx-gallery-nav-button-prev") as HTMLElement;
        if (prev_button) {
            prev_button.ariaLabel = "Vorheriges Foto ansehen";
            prev_button.setAttribute("tabindex", "0");
            prev_button.setAttribute("role", "button");
            prev_button.onkeyup = (e: any) => {
                if (e.key === "Enter") {
                    prev_button.click();
                }
            }

            prev_button.onfocus = () => {
                prev_button.classList.add("md-dx-state-focused");
            }
            prev_button.onblur = () => {
                prev_button.classList.remove("md-dx-state-focused");
            }
        }

        const timer = setTimeout(() => {
            if (!document.querySelector(".dx-gallery")) {
                return
            }

            let container = document.querySelector(".dx-gallery") as HTMLElement;
            container.setAttribute("role", "group");
            container.querySelector(".dx-gallery-container")?.setAttribute("aria-roledescription", "Karussell");
            container.querySelector(".dx-gallery-container")?.setAttribute("aria-label", "Alle Bilder");

            container.querySelectorAll(".dx-item.dx-gallery-item")?.forEach((item) => {
                item.setAttribute("role", "group");
                item.setAttribute("aria-roledescription", "Foto");
            });

            const arr = container.querySelectorAll(".dx-gallery-indicator-item") as NodeListOf<HTMLElement>;
            arr.forEach((item, i) => {
                // (item.ariaLabel !== "Foto " + (i + 1)) && (item.ariaLabel = "Foto " + (i + 1));
                item.ariaLabel = e.component.getDataSource()._items[i].alt
                if (item.className.indexOf("dx-gallery-indicator-item-selected") > -1) {
                    item.ariaSelected = "true"
                    item.ariaDisabled = "true"
                } else {
                    item.ariaSelected = "false"
                    item.ariaDisabled = "false"
                }

                item.setAttribute("tabindex", "0");
                item.setAttribute("role", "button");
                item.onkeyup = (e: any) => {
                    if (e.key === "Enter") {
                        item.click();
                    }
                }

                item.onfocus = () => {
                    item.classList.add("md-dx-state-focused");
                }
                item.onblur = () => {
                    item.classList.remove("md-dx-state-focused");
                }
            });
            clearTimeout(timer);
        }, 500);

        e.component._MD_Initialized = true;
    }

}

export function handleContentReadyRadioGroup(e: any) {
    if (!e.component._MD_Initialized) {
        e.component.option("onValueChanged", function (e: any) {
            if (e.event.type === "dxclick") {
                //Nur bei Tastaturbedinung soll dx-state-focused sichtbar sein. Bei Mouse klick soll dies überschrieben werden.
                let ele = e.event.currentTarget as HTMLElement;
                e.element.querySelectorAll(".md-dx-state-focused-override").forEach((element: HTMLElement) => {
                    element.classList.remove("md-dx-state-focused-override");
                });
                ele.classList.add("md-dx-state-focused-override");
            }
        })
        e.component._MD_Initialized = true;
    }

}
export function handleContentReadyTabPanel(e: any) {
    if (!e.component._MD_Initialized) {
        let classes = ".dx-icon-" + icon_info + ",." + icon_history + ",." + icon_battery + ",." + icon_code + ",." + icon_file + ",.dx-icon-" + icon_preferences;
        classes = classes.replace(/fas |fa /g, "");
        e.element.querySelectorAll(classes).forEach((item: HTMLElement) => item.setAttribute("aria-hidden", "true"));
        e.component._MD_Initialized = true;
    }
}

export function handleContentReadyTreeView(e: any) {
    e.element.querySelectorAll(".dx-treeview-item").forEach((element: HTMLElement) => {
        if (!element.onmouseup) {
            element.onmouseup = () => {
                (element.parentElement && element.parentElement.classList.contains("dx-state-focused")) && element.parentElement!.classList.remove("dx-state-focused");
            }
        }

    });

    if (!e.component._MD_Initialized && e.component._contentAlreadyRendered) {
        e.element.querySelectorAll(".dx-treeview-toggle-item-visibility").forEach((item: HTMLElement) => item.setAttribute("aria-hidden", "true"));
        e.component._MD_Initialized = true;
    }
}
export function set_aria_for_elements(parent: string | Element, element: string, aria_label: string, aria_hidden: boolean) {
    const timer = setTimeout(() => {
        const parent_ele = (typeof parent === "string") ? document.querySelector(parent) : parent;
        const arr = parent_ele ? parent_ele.querySelectorAll(element) : [];
        arr.forEach((item) => {
            if (aria_hidden) {
                (!item.getAttribute("aria-hidden")) && item.setAttribute("aria-hidden", "true");
            } else {
                (!(item.getAttribute("aria-label") === aria_label)) && item.setAttribute("aria-label", aria_label);
            }
        });
        clearTimeout(timer);
    }, 2000);
}


export function makeStr(length: number): string {
    if (length < 5) return '';
    var result = '';
    var characters = 'ABCDE FGHIJKLM NOP     QRSTUVW XYZabcdef     gh ijklmnopq rstu    v wxy z0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() *
            charactersLength));
    }
    return result;
}


export const set_column_chooser_position = (e: any) => {
    var columnChooserView = e.component.getView("columnChooserView");
    columnChooserView._popupContainer && columnChooserFixElements(columnChooserView._popupContainer._$element[0]);
    if (!columnChooserView._popupContainer) {
        columnChooserView._initializePopupContainer();
        columnChooserView.render();
        columnChooserView._popupContainer.option('height', 'auto');
        columnChooserView._popupContainer.option('maxHeight', '80vh');
        columnChooserView._popupContainer.option('width', 'auto');
        columnChooserView._popupContainer.option('minWidth', '260px'); // width spalten auswählen
        const ele = e.element as HTMLElement;

        (ele.classList.contains("faelle_listen_ansicht")) ?
            columnChooserView._popupContainer.option("position", { of: e.element, my: "right top", at: "right top", offset: "0 -40" })
            :
            columnChooserView._popupContainer.option("position", { of: e.element, my: "right top", at: "right top", offset: "0 0" });

        let toolbarItems = columnChooserView._popupContainer.option('toolbarItems');
        const title = toolbarItems[0].text;
        toolbarItems.splice(0, 1);
        //const title_comp: JSX.Element = RenderePopupTitle(title)
        toolbarItems.push({
            toolbar: 'top',
            location: 'before',
            html: "<h2 class='title_popup'>" + title + "</h2>", //Es gibt nur diese Möglichkeit. Siehe Ticket T1120766. 
            // component: () => (<h2 className="title_popup">{title}</h2>)
        });
        columnChooserView._popupContainer.option('toolbarItems', toolbarItems)

        /* Falls ein Resetbutton erwünscht ist.
            columnChooserView._popupContainer.option('toolbarItems').push({
              toolbar: 'bottom',
              location: 'center',
              widget: 'dxButton',
              options: {
                icon: 'revert',
                text: 'Reset',
                elementAttr: { class: 'dx-reset-button' },
                onClick: function () {
                  e.component.state({});
                }
              }
            });*/




        // label vor dem Input
        const element = columnChooserView._popupContainer._$element[0].querySelector('.dx-treeview-search .dx-texteditor-input-container .dx-texteditor-input');
        const placeholder = columnChooserView._popupContainer._$element[0].querySelector('.dx-treeview-search .dx-texteditor-input-container .dx-placeholder');
        (element && placeholder) &&
            element.before(placeholder);


        const clear_icon = columnChooserView._popupContainer._$element[0].querySelector('.dx-clear-button-area') as HTMLElement;

        if (clear_icon) {
            clear_icon.setAttribute("tabindex", "0");
            clear_icon.setAttribute("role", "button");
            clear_icon.setAttribute("aria-label", "suche löschen");
            clear_icon.onfocus = () => {
                clear_icon.classList.add("md-dx-state-focused")
            }
            clear_icon.onblur = () => {
                clear_icon.classList.remove("md-dx-state-focused");
            }
            clear_icon.onkeyup = (e1) => {
                if (e1.key === "Enter") {
                    let instance = TextBox.getInstance(columnChooserView._popupContainer._$popupContent[0].querySelector(".dx-searchbox")) as TextBox;
                    instance.reset();
                    columnChooserFixElements(instance.element().nextElementSibling);
                }
            }
            clear_icon.onclick = (e: any) => {
                columnChooserFixElements(columnChooserView._popupContainer._$element[0]);
            }
        }

        columnChooserView._columnChooserList.option("focusStateEnabled", false);

        //    const clear_icon_container = columnChooserView._popupContainer._$element[0].querySelector('.dx-clear-button-area') as HTMLElement
        //   if (clear_icon_container) {
        //     clear_icon_container.onclick = (e: any) => {
        //       columnChooserFixElements(columnChooserView._popupContainer._$element[0]);
        //     }
        //   }
        const searchBox = columnChooserView._popupContainer._$element[0].querySelector('.dx-searchbox') as HTMLElement;
        let instance = TextBox.getInstance(searchBox) as TextBox;
        //immer warten bis devexpress die ul upgedatet hat.
        instance && instance.option("onChange", function (e: any) {
            columnChooserFixElements(e.element.nextElementSibling);
        })

        instance && instance.option("onKeyUp", function (e: any) {
            //  if (!e.component.option("value")) { MD 11.08.2023 
            //Anmerkung: Wenn man was such dann nochmal auf ein Item klickt, funktioniert es nicht. 
            //Deshalb bei jeder Eingebae muss diese aktualisiert
            columnChooserFixElements(e.element.nextElementSibling);
            // }
        })

    }
}

const columnChooserFixElements = (component: any) => {

    const timer = setTimeout(() => {
        const arr = component ? component.querySelectorAll('li.dx-treeview-node') as HTMLElement[] : [];
        if ((arr.length === 0) || (arr[0].getAttribute("tabindex") === "0")) return
        arr.forEach((item) => {
            item.setAttribute("tabindex", "0");

            item.onblur = () => {
                item.classList.remove("md-dx-state-focused");
            }
            item.onkeyup = (e: any) => {
                if (e.key === "Enter") {
                    let checkbox = item.querySelector(".dx-checkbox");
                    if (checkbox) {
                        let instance = CheckBox.getInstance(checkbox) as CheckBox;
                        instance.option("value", !instance.option("value"))
                    }
                } else if (e.key === "Tab") {
                    !item.classList.contains("md-dx-state-focused") &&
                        item.classList.add("md-dx-state-focused");
                }
            }
            /* Devexpress hat das eingebaut ab Version 23.01
            item.onclick = (e: any) => {
              let checkbox = item.querySelector(".dx-checkbox");
              if (checkbox && !e.DXCLICK_FIRED) {
                //Wenn DXCLICK_FIRED heißt das, dass man auf den checkbox geklickt hat.
                let instance = CheckBox.getInstance(checkbox) as CheckBox;
                instance.option("value", !instance.option("value"))
              }
            }*/

        });

        clearTimeout(timer)
    }, 500);

}

export const handleContentReadyCheckBox = (e: any) => {
    if (!e.component._MD_Initialized) {

        //Ich möchte auch gerne mit enter nicht nur space
        e.component.registerKeyHandler('enter', function (e: any) {
            let instance = CheckBox.getInstance(e.currentTarget) as CheckBox;
            instance.option("value", !instance.option("value"))
        });
        let ele = e.element as HTMLElement;
        ele.onclick = (e1) => {
            !ele.classList.contains("md-dx-state-focused-override") && ele.classList.add("md-dx-state-focused-override")
        }

        events.on(ele, 'blur', () => {
            ele.classList.contains("md-dx-state-focused-override") && ele.classList.remove("md-dx-state-focused-override")
        });

        e.component._MD_Initialized = true;
    }
}
export const handleContentReadySelectBox = (e: any) => {
    if (!e.component._MD_Initialized) {
        const label = e.element.querySelector(".dx-texteditor-label");
        const container = e.element.querySelector(".dx-selectbox-container");

        (label && container) &&
            container.before(label);
        e.component.option("onOpened", () => {
            e.component._MD_opened = true;
            if (e.element.classList.contains("tagbox_hide_select_all")) {
                e.component.content().querySelector('.dx-list-select-all').classList.add("d-none");
                //sonst wird der Platz für select all sichtbar sein
                e.component.content().style.maxHeight = e.component.content().querySelectorAll('.dx-list-item').length * 49 + "px"
            }

        })
        e.component.option("onClosed", () => e.component._MD_opened = false)
        e.component.option("onEnterKey", () => !e.component._MD_opened && e.component.open())
        e.component._MD_Initialized = true;
    }
}

export const handleEventEnterToClick = (e: any) => {
    if (e.keyCode === 13) {
        let element = e.target as HTMLElement;
        element.click();
    }
}
export const handleContentReadyTextBox = (e: any) => {
    if (!e.component._MD_Initialized) {
        if (e.component.option("labelMode") === "static") {
            const label = e.element.querySelector(".dx-placeholder");
            const container = e.element.querySelector(".dx-texteditor-container input"); // hier input sonst sieht nicht gut aus bei Suche im Konfigurator

            (label && container) &&
                container.before(label);
        } else {
            const label = e.element.querySelector(".dx-texteditor-label");
            const container = e.element.querySelector(".dx-texteditor-container");
            (label && container) &&
                container.before(label);

            /*
          if (e.element.classList.contains("fix_label")) {
            //geht nicht autofill bleibt immer da
          }
      */

        }


        const clear_icon = e.element.querySelector('.dx-clear-button-area') as HTMLElement;
        if (clear_icon) {

            clear_icon.setAttribute("aria-label", "Löschen");
            clear_icon.setAttribute("tabindex", "0");
            clear_icon.setAttribute("role", "button");
            clear_icon.onblur = () => {
                clear_icon.classList.remove("md-dx-state-focused");
            }

            clear_icon.onkeyup = (e1) => {
                if (e1.key === "Enter") {
                    e.component.reset();
                } else if (e1.key === "Tab") {
                    !clear_icon.classList.contains("md-dx-state-focused") &&
                        clear_icon.classList.add("md-dx-state-focused");
                }
            }


        }

        e.component._MD_Initialized = true;
    }
}

export const exportGridAsExcel = (refGrid: any, file_name: string) => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Main sheet');
    exportDataGridAsExcel({
        component: refGrid.current.instance,
        worksheet,
        autoFilterEnabled: true,
    }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), file_name);
        });
    });
}


export const encodeUTF8 = (s: string): string => {
    let string = s;
    string = string.replace(/\r\n/g, "\n");
    let utftext = "";

    for (let n = 0; n < string.length; n++) {
        let c = string.charCodeAt(n);

        if (c < 128) {
            utftext += String.fromCharCode(c);
        } else if ((c > 127) && (c < 2048)) {
            utftext += String.fromCharCode((c >> 6) | 192);
            utftext += String.fromCharCode((c & 63) | 128);
        } else {
            utftext += String.fromCharCode((c >> 12) | 224);
            utftext += String.fromCharCode(((c >> 6) & 63) | 128);
            utftext += String.fromCharCode((c & 63) | 128);
        }

    }

    return utftext;
};
export const encodeBASE64 = (s: string): string => {
    let input = s;
    let keyStr = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
    let output = "";
    let chr1, chr2, chr3, enc1, enc2, enc3, enc4;
    let i = 0;

    input = encodeUTF8(input);

    while (i < input.length) {
        chr1 = input.charCodeAt(i++);
        chr2 = input.charCodeAt(i++);
        chr3 = input.charCodeAt(i++);

        enc1 = chr1 >> 2;
        enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
        enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
        enc4 = chr3 & 63;

        if (isNaN(chr2)) {
            enc3 = enc4 = 64;
        } else if (isNaN(chr3)) {
            enc4 = 64;
        }

        output += keyStr.charAt(enc1) + keyStr.charAt(enc2) +
            keyStr.charAt(enc3) + keyStr.charAt(enc4);
    }

    return output;
};
