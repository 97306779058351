import { DataGrid, TextBox, LoadPanel } from "devextreme-react";
import { Column, MasterDetail, Toolbar, Item as ItemGridToolbar, Button as ButtonGrid } from "devextreme-react/data-grid";
import { useCallback, useEffect, useRef, useState } from "react";
import UnternehmenPopup from "./unternehmen_popup";
import MasterDetailSection from "./abschlepp_unternehmen_master_detail";
import { IUnternehmenDaten } from "./interfaces";
import { abschlepp_unternehmen_columns } from "../../../sonstiges/columns";
import { GetData } from "../../../global/communication";
import { setConfirm, setToast, useAppDispatch } from "../../../state";
import { Seperatorliste } from "../../../global/seperator_liste";
import { exportGridAsPDF, handleContentReadyDataGrid, handleContentReadyTextBox } from "../../../global/hilfs_functions";
import { MDButton } from "../../../global/components";
import { RendereGridTitleAbschleppUnternehemen } from "../../../sonstiges/hilfs_functions"
import { icon_delete, icon_pdf, icon_plus, scroll_mode_infinite } from "../../../global/const";


const columns = abschlepp_unternehmen_columns;
const AbschleppUnternehmen = () => {
    const dataGridRef = useRef() as React.MutableRefObject<DataGrid<any, any>>;
    const [searchValue, setSearchValue] = useState('');
    const [unternehmenPopupDaten, setUnternehmenPopupDaten] = useState({ visible: false, anlegen: false, data: undefined });
    const [data, setData] = useState<IUnternehmenDaten[]>([]);
    const [isloading, setIsloading] = useState(true);
    const dispatch = useAppDispatch();


    useEffect(() => {
        get_data();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const get_data = useCallback(() => {
        let filter = new Seperatorliste("%00", "%01");
        filter.setValue("Suchbegriff", searchValue);
        GetData({
            Operation: "GetAbschleppunternehmen",
            Filter: filter.getSeperatorliste(),
        }, dispatch).then((res) => {
            setIsloading(false);
            if (!res) {
                setData([]);
                return
            }
            setData(res.data)
        })
    }, [searchValue, dispatch]);

    const handleClickRow = useCallback(({ data, event, handled }: any) => {
        //nur beim Klicken von dataGrid-Rows Also nicht beim Clicken des MasterDetails buttons
        if (!handled && event.originalEvent.currentTarget.className === "dx-datagrid-table dx-datagrid-table-fixed") {
            setUnternehmenPopupDaten({
                anlegen: false,
                visible: true,
                data: data
            });
        }
    }, []);


    const callBackPopUp = useCallback((update?: boolean, loeschen?: boolean) => {
        if (loeschen) {
            delete_unternehmen(dispatch, unternehmenPopupDaten.data, data, setData);
        } else {
            if (update) {
                setIsloading(true);
                get_data();
            }
        }
        setUnternehmenPopupDaten({
            visible: false,
            anlegen: false,
            data: undefined
        });

    }, [unternehmenPopupDaten, get_data, data, dispatch]);

    const handleClickDelete = useCallback((e: any) => {
        e.event.stopPropagation();
        delete_unternehmen(dispatch, JSON.parse(e.element.getAttribute("data")), data, setData);
    }, [data, dispatch]);


    const handleClickPDF = useCallback(() => {
        exportGridAsPDF(dataGridRef, "Abschleppunternehmen.pdf", searchValue ? "Suchbegriff: " + searchValue + "\n" : "", "landscape", "striped")
    }, [dataGridRef, searchValue]);

    const handleClickPlus = useCallback(() => {
        setUnternehmenPopupDaten({ ...unternehmenPopupDaten, anlegen: true, visible: true })
    }, [unternehmenPopupDaten]);

    const RenderButtonDelete = useCallback((e: any) => {
        return (
            <MDButton
                page='abschlepp_unternehmen'
                tooltip_text='löschen'
                icon={icon_delete}
                type='danger'
                onClick={handleClickDelete}
                data={e.data}
            />)
    }, [handleClickDelete]);


    return (<>
        {isloading ? <LoadPanel visible={true} /> :
            <div className="data_grid_container mt-2 mt-md-3">
                <DataGrid
                    dataSource={data}
                    columnAutoWidth={true}
                    allowColumnResizing={true}
                    rowAlternationEnabled={true}
                    wordWrapEnabled={true}
                    renderAsync={true}
                    keyExpr="lfdnr"
                    scrolling={scroll_mode_infinite}
                    noDataText={"Keine Abschleppunternehmen"}
                    className="abschlepp_unternhmen_data_grid animate__animated animate__fadeIn cursor_pointer"
                    onRowClick={handleClickRow}
                    hoverStateEnabled={true}
                    ref={dataGridRef}
                    height={"auto"}
                    onContentReady={handleContentReadyDataGrid}
                >
                    <MasterDetail
                        enabled={true}
                        component={MasterDetailSection}
                    />
                    {columns.map((column) => {
                        return (
                            <Column
                                dataField={column.dataField}
                                caption={column.caption}
                                dataType={column.dataType}
                                alignment={column.alignment}
                                cellRender={column.cellRender}
                                key={column.dataField}
                                minWidth={column.minWidth}
                                visible={column.visible}
                            />
                        )
                    })}
                    <Column type="buttons">
                        <ButtonGrid
                            cssClass={'d-inline-block'}
                            render={RenderButtonDelete} />
                    </Column>
                    <Toolbar>
                        <ItemGridToolbar
                            location={'before'}
                            render={RendereGridTitleAbschleppUnternehemen}
                        />

                        <ItemGridToolbar location="after" locateInMenu="auto" >
                            <TextBox
                                label="Suche"
                                stylingMode='outlined'
                                mode='text'
                                className="suche_feld"
                                showClearButton={true}
                                value={searchValue}
                                onValueChange={setSearchValue}
                                onEnterKey={get_data}
                                maxLength={60}
                                onContentReady={handleContentReadyTextBox}
                            >
                            </TextBox>
                        </ItemGridToolbar>
                        <ItemGridToolbar location="after" locateInMenu="auto">
                            <MDButton
                                icon={icon_pdf}
                                tooltip_text="Tabelle als PDF herunterladen"
                                onClick={handleClickPDF}
                                page="abschlepp_unternehmen"
                            />
                        </ItemGridToolbar>
                        <ItemGridToolbar location="after" locateInMenu="auto">
                            <MDButton
                                icon={icon_plus}
                                type="success"
                                tooltip_text="Ein neues Unternehmen anlegen"
                                onClick={handleClickPlus}
                                page="abschlepp_unternehmen"
                            />
                        </ItemGridToolbar>
                    </Toolbar>
                </DataGrid>
            </div>}
        {unternehmenPopupDaten.visible && <UnternehmenPopup anlegen={unternehmenPopupDaten.anlegen} data={unternehmenPopupDaten.data} callBack={callBackPopUp} />}
    </>)
}
export default AbschleppUnternehmen;


const delete_unternehmen = (dispatch: any, data_unternehmen: any, data: any, setData: any) => {
    dispatch(setConfirm({
        body:
            <div className="alert alert-danger">
                <p>Wollen sie das Unternehmen <b>{data_unternehmen.f002 ? data_unternehmen.f001 + ' ' + data_unternehmen.f002 : data_unternehmen.f001}</b> wirklich löschen?</p>
            </div>,
        on_ok: () => {
            let daten_server = new Seperatorliste("%00", "%01");
            daten_server.setValue("Firmennummer", data_unternehmen.lfdnr.toString());

            GetData({
                Operation: "DeleteAbschleppunternehmen",
                Daten: daten_server.getSeperatorliste()
            }, dispatch).then((res) => {
                if (!res) return
                let new_data = [...data];
                new_data.splice(new_data.indexOf(data), 1);
                setData(new_data);
                dispatch(setToast({
                    message: "Das Unternehmen wurde erfolgreich gelöscht.",
                    type: "success",
                    position: "bottom center"
                }));
            })
        },
        title: "Unternehmen löschen",
    }));
}