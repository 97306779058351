import { memo } from "react";
import { IFallData, IFallDataZusaetzlich } from "../../../interfaces";


export interface ICardHeaderBackProps {
    item: IFallData,
    itemZus: IFallDataZusaetzlich,
}
const CardHeaderBack: React.FC<ICardHeaderBackProps> = ({ item, itemZus }): JSX.Element => {
    const geloeschter_fall = item.f006 >= 2;

    return (<>
        <div className={geloeschter_fall ? "row geloeschter_fall" : "row"}>
            <div className="col-6">
                <h3 className="card_kennzeichen">
                    {itemZus.kfz_kennziehcen}
                </h3>
                <div>
                    {item.f010.Akz && <p>Akz:  {item.f010.Akz} </p>}
                    {item.f010.Kassenzeichen && <p>Kz:  {item.f010.Kassenzeichen}</p>}
                </div>
            </div>
            <div className="col-6 text-right">
                <p>{itemZus.record_typ} erfasst am {item.f002.substr(0, 6)} {itemZus.uhrzeit}<br />durch {item.sachbearbeitername}</p>
                <span>{itemZus.gesamt_betrag_aus_f010 > 0 && itemZus.gesamt_betrag_aus_f010 + ' Euro'}</span>
                <span>{item.f010.Tatmehrheit === "Ja" && ' (Tatmehrheit)'}</span>
                {(itemZus.tatbestaende && itemZus.tatbestaende.length > 0) &&
                    <span>{itemZus.tatbestaende[0].punkte > 0 ? itemZus.tatbestaende[0].punkte === 1 ? ' ' + itemZus.tatbestaende[0].punkte + ' Punkt' : ' ' + itemZus.tatbestaende[0].punkte + ' Punkte' : <></>}</span>
                }

            </div>
        </div><hr />
    </>)
}

export default memo(CardHeaderBack);