import { DataGrid, LoadPanel } from "devextreme-react";
import { Column, Toolbar, Item as ItemGridToolbar } from "devextreme-react/data-grid";
import { useCallback } from "react";
import { useEffect, useMemo, useRef, useState } from "react";
import { GetData } from "../../../global/communication";
import { icon_arrow_left, icon_arrow_right, icon_excel, icon_pdf, scroll_mode_infinite } from "../../../global/const";
import { exportGridAsExcel, exportGridAsPDF, get_column_min_width, get_date_ajax_param, handleContentReadyDataGrid, stretch0Left } from "../../../global/hilfs_functions";
import { useAppDispatch } from "../../../state";
import { MDButton, RendereGridTitle } from "../../../global/components";
import { IStatistikDataMonat } from "../interfaces";
import { IColumn } from "../../../global/interfaces";


const Statistik_Monat: React.FC = (): JSX.Element => {
    const [aktuellerMonat, setAktuellerMonat] = useState(0);
    const startDatum: Date = useMemo(() => new Date(new Date().getFullYear(), new Date().getMonth() + aktuellerMonat, 1, 1), [aktuellerMonat]);
    //const [startDatum, setStartDatum] = useState<Date>(new Date(new Date().getFullYear(), new Date().getMonth() + aktuellerMonat, 1, 1));
    const columns: IColumn[] = useMemo(() => Statistik_Monat_Spalten(startDatum), [startDatum]);

    const [data, setData] = useState<IStatistikDataMonat[]>([]);
    const dataGridRef = useRef() as React.MutableRefObject<DataGrid<any, any>>;
    const header = useMemo(() => get_header_pdf_statistik_monat(startDatum), [startDatum]);
    const dispatch = useAppDispatch();

    useEffect(() => {
        GetData({
            Operation: "GetMonatsStatistik",
            Datum: get_date_ajax_param(startDatum),
        }, dispatch)
            .then((res) => {
                if (!res) {
                    setData([]);
                    return
                }
                setData(res.data);
            });
    }, [startDatum, dispatch]);

    const handleClickLeft = useCallback(() => {
        setData([]);
        setAktuellerMonat(prev => prev + 1);
    }, []);

    const handleClickRight = useCallback(() => {
        setData([]);
        setAktuellerMonat(prev => prev - 1);
    }, []);

    const handlePDF = useCallback(() => {
        exportGridAsPDF(dataGridRef, 'MonatStatistik.pdf', header);
    }, [header]);

    const handleExcel = useCallback(() => {
        exportGridAsExcel(dataGridRef, 'MonatStatistik.xlsx')
    }, []);


    const RendereTitle = useCallback(() => {
        return RendereGridTitle(header)
    }, [header]);

    return (
        data.length === 0 ? <LoadPanel visible={true}></LoadPanel> :
            <div className="data_grid_container mt-2 mt-md-3">
                <DataGrid
                    dataSource={data}
                    allowColumnResizing={true}
                    columnAutoWidth={false}
                    className=" animate__animated animate__fadeIn"
                    rowAlternationEnabled={true}
                    focusedRowEnabled={false}
                    ref={dataGridRef}
                    renderAsync={true}
                    scrolling={scroll_mode_infinite}
                    wordWrapEnabled={true}
                    keyExpr={'name'}
                    remoteOperations={true}
                    noDataText={"Keine Statistiken"}
                    height={"auto"}
                    onContentReady={handleContentReadyDataGrid}
                >

                    {
                        columns.map((column) => {
                            return (
                                <Column
                                    dataField={column.dataField}
                                    caption={column.caption}
                                    dataType={column.dataType}
                                    key={column.dataField}
                                    minWidth={column.minWidth}
                                    alignment={column.alignment}
                                    visible={column.visible}
                                />
                            )
                        })
                    }
                    <Toolbar>
                        <ItemGridToolbar
                            location={'before'}
                            render={RendereTitle}
                        ></ItemGridToolbar>

                        <ItemGridToolbar
                            location="after"
                        >
                            <MDButton
                                icon={icon_arrow_left}
                                tooltip_text="Vorheriger Monat"
                                onClick={handleClickRight}
                                page="statistik_monat"
                            />
                        </ItemGridToolbar>
                        <ItemGridToolbar
                            location="after"
                        >
                            <MDButton
                                icon={icon_arrow_right}
                                tooltip_text="Nächster Monat"
                                disabled={aktuellerMonat === 0}
                                onClick={handleClickLeft}
                                page="statistik_monat"
                            />
                        </ItemGridToolbar>
                        <ItemGridToolbar location="after" locateInMenu="auto">
                            <MDButton
                                icon={icon_pdf}
                                tooltip_text="Tabelle als PDf herunterladen"
                                onClick={handlePDF}
                                page="statistik_monat"
                            />

                        </ItemGridToolbar>
                        <ItemGridToolbar location="after" locateInMenu="auto">
                            <MDButton
                                icon={icon_excel}
                                tooltip_text="Tabelle als Excel herunterladen"
                                onClick={handleExcel}
                                page="statistik_monat"
                            />
                        </ItemGridToolbar>
                    </Toolbar>
                </DataGrid></div>)
}
export default Statistik_Monat;

const Statistik_Monat_Spalten = (startDatum: Date): IColumn[] => {
    return [
        {
            dataField: "name",
            caption: "Sachbearbeiter",
            minWidth: get_column_min_width(13),
        }, {
            dataField: "tag1",
            caption: "01." + stretch0Left((startDatum.getMonth() + 1).toString(), 2) + ".",
            minWidth: get_column_min_width(5),
        }, {
            dataField: "tag2",
            caption: "02." + stretch0Left((startDatum.getMonth() + 1).toString(), 2) + ".",
            minWidth: get_column_min_width(5),
        }, {
            dataField: "tag3",
            caption: "03." + stretch0Left((startDatum.getMonth() + 1).toString(), 2) + ".",
            minWidth: get_column_min_width(5),
        }, {
            dataField: "tag4",
            caption: "04." + stretch0Left((startDatum.getMonth() + 1).toString(), 2) + ".",
            minWidth: get_column_min_width(5),
        }, {
            dataField: "tag5",
            caption: "05." + stretch0Left((startDatum.getMonth() + 1).toString(), 2) + ".",
            minWidth: get_column_min_width(5),
        }, {
            dataField: "tag6",
            caption: "06." + stretch0Left((startDatum.getMonth() + 1).toString(), 2) + ".",
            minWidth: get_column_min_width(5),
        }, {
            dataField: "tag7",
            caption: "07." + stretch0Left((startDatum.getMonth() + 1).toString(), 2) + ".",
            minWidth: get_column_min_width(5),
        }, {
            dataField: "tag8",
            caption: "08." + stretch0Left((startDatum.getMonth() + 1).toString(), 2) + ".",
            minWidth: get_column_min_width(5),
        }, {
            dataField: "tag9",
            caption: "09." + stretch0Left((startDatum.getMonth() + 1).toString(), 2) + ".",
            minWidth: get_column_min_width(5),
        }, {
            dataField: "tag10",
            caption: "10." + stretch0Left((startDatum.getMonth() + 1).toString(), 2) + ".",
            minWidth: get_column_min_width(5),
        }, {
            dataField: "tag11",
            caption: "11." + stretch0Left((startDatum.getMonth() + 1).toString(), 2) + ".",
            minWidth: get_column_min_width(5),
        }, {
            dataField: "tag12",
            caption: "12." + stretch0Left((startDatum.getMonth() + 1).toString(), 2) + ".",
            minWidth: get_column_min_width(5),
        }, {
            dataField: "tag13",
            caption: "13." + stretch0Left((startDatum.getMonth() + 1).toString(), 2) + ".",
            minWidth: get_column_min_width(5),
        }, {
            dataField: "tag14",
            caption: "14." + stretch0Left((startDatum.getMonth() + 1).toString(), 2) + ".",
            minWidth: get_column_min_width(5),
        }, {
            dataField: "tag15",
            caption: "15." + stretch0Left((startDatum.getMonth() + 1).toString(), 2) + ".",
            minWidth: get_column_min_width(5),
        }, {
            dataField: "tag16",
            caption: "16." + stretch0Left((startDatum.getMonth() + 1).toString(), 2) + ".",
            minWidth: get_column_min_width(5),
        }, {
            dataField: "tag17",
            caption: "17." + stretch0Left((startDatum.getMonth() + 1).toString(), 2) + ".",
            minWidth: get_column_min_width(5),
        }, {
            dataField: "tag18",
            caption: "18." + stretch0Left((startDatum.getMonth() + 1).toString(), 2) + ".",
            minWidth: get_column_min_width(5),
        }, {
            dataField: "tag19",
            caption: "19." + stretch0Left((startDatum.getMonth() + 1).toString(), 2) + ".",
            minWidth: get_column_min_width(5),
        }, {
            dataField: "tag20",
            caption: "20." + stretch0Left((startDatum.getMonth() + 1).toString(), 2) + ".",
            minWidth: get_column_min_width(5),
        }, {
            dataField: "tag21",
            caption: "21." + stretch0Left((startDatum.getMonth() + 1).toString(), 2) + ".",
            minWidth: get_column_min_width(5),
        }, {
            dataField: "tag22",
            caption: "22." + stretch0Left((startDatum.getMonth() + 1).toString(), 2) + ".",
            minWidth: get_column_min_width(5),
        }, {
            dataField: "tag23",
            caption: "23." + stretch0Left((startDatum.getMonth() + 1).toString(), 2) + ".",
            minWidth: get_column_min_width(5),
        }, {
            dataField: "tag24",
            caption: "24." + stretch0Left((startDatum.getMonth() + 1).toString(), 2) + ".",
            minWidth: get_column_min_width(5),
        }, {
            dataField: "tag25",
            caption: "25." + stretch0Left((startDatum.getMonth() + 1).toString(), 2) + ".",
            minWidth: get_column_min_width(5),
        }, {
            dataField: "tag26",
            caption: "26." + stretch0Left((startDatum.getMonth() + 1).toString(), 2) + ".",
            minWidth: get_column_min_width(5),
        }, {
            dataField: "tag27",
            caption: "27." + stretch0Left((startDatum.getMonth() + 1).toString(), 2) + ".",
            minWidth: get_column_min_width(5),
        }, {
            dataField: "tag28",
            caption: "28." + stretch0Left((startDatum.getMonth() + 1).toString(), 2) + ".",
            minWidth: get_column_min_width(5),
        }, {
            dataField: "tag29",
            caption: "29." + stretch0Left((startDatum.getMonth() + 1).toString(), 2) + ".",
            minWidth: get_column_min_width(5),
        }, {
            dataField: "tag30",
            caption: "30." + stretch0Left((startDatum.getMonth() + 1).toString(), 2) + ".",
            minWidth: get_column_min_width(5),
        }, {
            dataField: "tag31",
            caption: "31." + stretch0Left((startDatum.getMonth() + 1).toString(), 2) + ".",
            minWidth: get_column_min_width(5),
        }
        , {
            dataField: "faelle",
            caption: "Fälle",
            dataType: "number",
            minWidth: get_column_min_width(5),
        }
    ]
}

const get_header_pdf_statistik_monat = (startDatum: Date): string => {
    let monat = startDatum.getMonth();
    let jahr = startDatum.getFullYear();

    let header = 'Statistik ';
    if (monat === 1) header += "Februar ";
    else if (monat === 2) header += "März ";
    else if (monat === 3) header += "April ";
    else if (monat === 4) header += "Mai ";
    else if (monat === 5) header += "Juni ";
    else if (monat === 6) header += "Juli ";
    else if (monat === 7) header += "August ";
    else if (monat === 8) header += "September ";
    else if (monat === 9) header += "Oktober ";
    else if (monat === 10) header += "November ";
    else if (monat === 11) header += "Dezember ";
    else header += "Januar ";
    header += jahr;

    return header

}