import { NumberBox, Popup, ScrollView, SelectBox, TextBox } from "devextreme-react"
import { ToolbarItem } from "devextreme-react/autocomplete"
import ArrayStore from "devextreme/data/array_store";
import { useCallback, useEffect, useMemo, useState } from "react";
import { GetData } from "../../../../global/communication";
import { tatbestand_haupt_katogieren_alt_bilder } from "../../../../global/const";
import { handleErrorImg, handleContentReadySelectBox, handleContentReadyTextBox, stretch0Left } from "../../../../global/hilfs_functions";
import { Seperatorliste } from "../../../../global/seperator_liste";
import { setToast, useAppDispatch } from "../../../../state";
import { ITatbestandHauptKategorie } from "../../tatbestaende/interfaces";




interface IHauptGruppeVerwaltePopupProps {
    callback: (update?: boolean, loeschen?: boolean) => void,
    row?: ITatbestandHauptKategorie | null,
}


const style_img = { backgroundColor: "rgba(25, 94, 163,1)", borderRadius: "3px" };
const data_source_gruppe_verwendet = new ArrayStore({
    data: [
        { text: "nein", value: 0 },
        { text: "Allgemeine OWIG", value: 1 },
        { text: "Verkehrs OWIG", value: 2 },
        { text: "Allg. OWIG und Verkehrs-OWIG", value: 3 },
        { text: "Fliessverkehr", value: 4 },
        { text: "Fliessverkehr und Verkehrs-OWIG", value: 5 },
        { text: "in den Bereichen AOWI, VOWI, FOWI verwenden", value: 6 },
        { text: "AMM Feststellungen", value: 7 },
        { text: "AMM und AOWI", value: 8 },
        { text: "AMM, AOWI und VOWI", value: 9 }
    ],
    key: 'value',
})

const HauptGruppeVerwaltePopup: React.FC<IHauptGruppeVerwaltePopupProps> = ({ callback, row }): JSX.Element => {
    const isAdd = row === undefined;
    const [hauptGruppeNummer, setHauptGruppeNummer] = useState<number>((!isAdd && row?.lfdnr) ? row?.lfdnr : 0);
    const [bezeichnung, setBezeichnung] = useState((!isAdd && row?.f003) ? row?.f003 : "");
    const [bildNummer, setBildNummer] = useState((!isAdd && row?.f004) ? row?.f004 : 1);
    const [gruppeVerwendetValue, setGruppeVerwendetValue] = useState((!isAdd && row?.f005) ? row?.f005 : 0);
    const [hinweisFrist, setHinweisFrist] = useState((!isAdd && row?.f006) ? row?.f006 : 0);
    const dispatch = useAppDispatch();
    const haupt_kategorie_bilder_items = useMemo(() => haupt_kategorie_bilder_items_func(), []);

    useEffect(() => {
        if (isAdd) {
            GetData({
                Operation: "GetNextHauptgruppennummer"
            }, dispatch).then((res) => {
                if (!res) return
                setHauptGruppeNummer(parseInt(res.hauptgruppennummer))
            });

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleClickSpeichern = useCallback(() => {
        if (!hauptGruppeNummer) {
            dispatch(setToast({
                message: "Hauptgruppennummer muss vorhanden sein.",
                type: "error"
            }));
            return
        }

        if (!bezeichnung) {
            dispatch(setToast({
                message: "Bezeichnung muss vorhanden sein.",
                type: "error"
            }));
            return
        }
        if (!bildNummer) {
            dispatch(setToast({
                message: "Bildnummer muss vorhanden sein.",
                type: "error"
            }));
            return
        }
        let hauptgruppendaten = new Seperatorliste("%00", "%01");
        isAdd && hauptgruppendaten.setValue("Hauptgruppennummer", hauptGruppeNummer.toString());
        hauptgruppendaten.setValue("Bezeichnung", bezeichnung.trim());
        hauptgruppendaten.setValue("Bildnummer", (bildNummer).toString());
        hauptgruppendaten.setValue("GruppeVerwenden", gruppeVerwendetValue.toString());
        hauptgruppendaten.setValue("HinweisfristDoppelerfassung", hinweisFrist.toString());

        let obj;
        if (isAdd) {
            obj = {
                Operation: "InsertHauptgruppe",
                Hauptgruppendaten: hauptgruppendaten.getSeperatorliste()
            }
        } else {
            obj = {
                Operation: "ChangeHauptgruppe",
                Hauptgruppendaten: hauptgruppendaten.getSeperatorliste(),
                Hauptgruppennummer: hauptGruppeNummer.toString(),
            }
        }

        GetData(obj, dispatch).then((res) => {
            if (!res) {
                callback();
                return
            }
            dispatch(setToast({
                message: !isAdd ? "Änderungen wurden erfolgreich gespeichert" : "Hauptgruppe wurde erfolgreich hinzugefügt.",
                type: "success",
                position: "bottom center"
            }));
            callback(true);
        });

    }, [hauptGruppeNummer, bezeichnung, bildNummer, gruppeVerwendetValue, hinweisFrist, isAdd, dispatch, callback]);


    const handleHidePopup = useCallback(() => {
        callback();
    }, [callback]);
    const handleHidePopupLoeschen = useCallback(() => {
        callback(undefined, true);
    }, [callback]);

    const memo_options_loeschen = useMemo(() => {
        return {
            text: 'Löschen',
            type: "danger",
            onClick: handleHidePopupLoeschen,
        }
    }, [handleHidePopupLoeschen]);

    const memo_options_speichern = useMemo(() => {
        return {
            text: 'Speichern',
            onClick: handleClickSpeichern,
        }
    }, [handleClickSpeichern]);


    const memo_options_abbrechen = useMemo(() => {
        return {
            text: 'Abbrechen',
            onClick: handleHidePopup,
        }
    }, [handleHidePopup]);


    const RenderePopupTitle = useCallback(() => {
        return (<h2 className="title_popup">{isAdd ? "Neue Hauptgruppe anlegen" : "Hauptgruppe bearbeiten"}</h2>)
    }, [isAdd]);
    return (
        <>
            <Popup
                visible={true}
                onHiding={handleHidePopup}
                dragEnabled={true}
                hideOnOutsideClick={true}
                animation={undefined}
                fullScreen={false}
                showCloseButton={true}
                height='auto'
                maxHeight="70vh"
                maxWidth="500px"
            >

                <ToolbarItem
                    toolbar="top"
                    location={'before'}
                    render={RenderePopupTitle}
                />
                {!isAdd &&
                    <ToolbarItem
                        widget="dxButton"
                        toolbar="bottom"
                        location="before"
                        options={memo_options_loeschen}
                    />}
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={memo_options_speichern}
                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={memo_options_abbrechen}
                />
                <ScrollView>
                    <div className="row p-2 p-md-3">
                        <div className="col-12  col-md-6 p-1 p-md-2">
                            <NumberBox
                                label="Hauptgruppenummer"
                                labelMode="floating"
                                stylingMode="outlined"
                                onValueChange={setHauptGruppeNummer}
                                value={hauptGruppeNummer}
                                valueChangeEvent="input"
                                disabled={!isAdd}
                                onContentReady={handleContentReadyTextBox}
                            />
                        </div>
                        <div className="col-12 col-md-6 p-1 p-md-2">
                            <TextBox
                                label="Bezeichnung"
                                labelMode="floating"
                                stylingMode="outlined"
                                onValueChange={setBezeichnung}
                                value={bezeichnung}
                                valueChangeEvent="input"
                                maxLength={60}
                                onContentReady={handleContentReadyTextBox}
                            />
                        </div>
                        {/*
                            <div className="col-12 col-md-6 p-1 p-md-2">
                            <NumberBox
                                label="Bildnummer"
                                labelMode="floating"
                                stylingMode="outlined"
                                onValueChange={setBildNummer}
                                value={bildNummer}
                                valueChangeEvent="input"
                                max={75}
                                min={1}
                                onContentReady={handleContentReadyTextBox}
                            />
                        </div>
                        <div className="col-12 col-md-6  p-1 p-md-2  d-flex justify-content-center">
                            <img                                                                             
                                src={"/Files/WinowigOnlineNT/Source/img/tatbestandgruppen/hauptgruppen/Hauptgruppe" + (bildNummer ? stretch0Left((bildNummer).toString(), 3) : "000") + ".png"}
                                alt={tatbestand_haupt_katogieren_alt_bilder[bildNummer]}
                                onError={handleErrorImg}
                                style={style_img}
                            />
                        </div>

                        
                        */}
                        <div className="col-7 p-1 p-md-2">
                            <SelectBox
                                items={haupt_kategorie_bilder_items}
                                value={bildNummer}
                                onValueChange={setBildNummer}
                                label='Visualisierung'
                                labelMode='floating'
                                stylingMode='outlined'
                                itemComponent={HauptGruppenItem}
                                onContentReady={handleContentReadySelectBox}
                            />
                        </div>
                        <div className="col-5  p-1 p-md-2  d-flex justify-content-end">
                            <img
                                src={"/Files/WinowigOnlineNT/Source/img/tatbestandgruppen/hauptgruppen/Hauptgruppe" + (bildNummer ? stretch0Left((bildNummer).toString(), 3) : "000") + ".png"}
                                alt={tatbestand_haupt_katogieren_alt_bilder[bildNummer]}
                                onError={handleErrorImg}
                                style={style_img}
                            />
                        </div>
                        <div className="col-12 p-1 p-md-2">
                            <SelectBox
                                dataSource={data_source_gruppe_verwendet}
                                displayExpr="text"
                                valueExpr="value"
                                value={gruppeVerwendetValue}
                                onValueChange={setGruppeVerwendetValue}
                                label='Gruppe verwendet'
                                labelMode='floating'
                                stylingMode='outlined'
                                onContentReady={handleContentReadySelectBox}
                            />
                        </div>
                        <div className="col-12 p-1 p-md-2">
                            <NumberBox
                                label="Hinweisfrist Doppelerfassung in Stunden"
                                labelMode="floating"
                                stylingMode="outlined"
                                onValueChange={setHinweisFrist}
                                value={hinweisFrist}
                                valueChangeEvent="input"
                                onContentReady={handleContentReadyTextBox}
                            />
                        </div>
                    </div>
                </ScrollView>

            </Popup>
        </>
    )
}

export default HauptGruppeVerwaltePopup;


const haupt_kategorie_bilder_items_func = () => {
    let result: number[] = [];
    for (let i = 0; i < 76; i++) result.push(i)
    return result
}

const HauptGruppenItem = ({ data }: any) => {
    return (
        <div className="custom_items_gruppen">
            <div className="text">{data}</div>
            <img
                src={"/Files/WinowigOnlineNT/Source/img/tatbestandgruppen/hauptgruppen/Hauptgruppe" + (data ? stretch0Left((data).toString(), 3) : "000") + '.png'}
                alt={"Hauptgruppen Bild nummer: " + data}
                onError={handleErrorImg}
                style={style_img} />
        </div>
    )
}