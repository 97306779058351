
import { Popup, ScrollView, SelectBox, TextBox } from "devextreme-react"
import { ToolbarItem } from "devextreme-react/autocomplete";
import Accordion, { Item } from "devextreme-react/accordion";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import ArrayStore from "devextreme/data/array_store";
import { setAlert, setConfirm, setToast, useAppDispatch } from "../../../state";
import { GetData } from "../../../global/communication";
import { IStammdatenSatzartData } from "./interfaces";
import { handleContentReadySelectBox, handleContentReadyTextBox } from "../../../global/hilfs_functions";
import { MDButton } from "../../../global/components";
import { icon_edit } from "../../../global/const";
import { get_json_object_in_arr } from "../../../sonstiges/hilfs_functions";

interface ISatzartAddLoeschenProps {
    get_data: () => void,
}
const SatzartAddLoeschen: React.FC<ISatzartAddLoeschenProps> = ({ get_data }): JSX.Element => {
    const [showPopUp, setShowPopUp] = useState(false);
    const [bezeichnung, setBezeichnung] = useState("");
    const refBezeichnung = useRef() as React.MutableRefObject<TextBox>;
    const [satzartValue, setSatzartValue] = useState(undefined);
    const [satzarte, setSatzarte] = useState<IStammdatenSatzartData[]>([]);
    const dispatch = useAppDispatch();
    const data_source_satzarte = useMemo(() => new ArrayStore({
        data: satzarte,
        key: 'satzart',
    }), [satzarte]);
    const handleAddSatzart = useCallback(() => {
        if (!bezeichnung) {
            dispatch(setAlert({
                text: "Bezeichnung ist leer.",
                type: "Fehler"
            }));
            return;
        }
        if (bezeichnung.indexOf(';') >= 0 || bezeichnung.indexOf(',') >= 0 || bezeichnung.indexOf('#') >= 0 || bezeichnung.indexOf('{') >= 0 || bezeichnung.indexOf('}') >= 0) {
            dispatch(setAlert({
                text: "Ein Strichpunkt bzw. Semikolon. ist in der Bezeichnung nicht erlaubt.",
                type: "Fehler"
            }));
            return;
        }
        GetData({
            Operation: "InsertStammdatenKategorie",
            Bezeichnung: bezeichnung
        }, dispatch).then((res) => {
            if (!res) return
            get_data();
        });

        setShowPopUp(false);
    }, [bezeichnung, dispatch, get_data]);

    useEffect(() => {
        GetData({
            Operation: "GetStammdatenSatzart",
            NurTatauswahlen: "Ja",
        }, dispatch).then((res) => {
            if (!res) return
            setSatzarte(res.data);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const handleShowPopupConfirm = useCallback(() => {
        if (!satzartValue) {
            dispatch(setToast({ message: "Es muss eine Satzart ausgewählt werden.", type: "error" }));
            return
        }
        delete_satzart(dispatch, satzarte, satzartValue, () => {
            get_data();
            setShowPopUp(false);
        });
    }, [satzartValue, dispatch, get_data, satzarte]);

    const handleShowPopup = useCallback(() => {
        setShowPopUp(true)
    }, []);
    const handleHidePopup = useCallback(() => {
        setShowPopUp(false)
    }, []);

    const memo_optione_abbrechen = useMemo(() => {
        return {
            text: 'Abbrechen',
            onClick: handleHidePopup,
        }
    }, [handleHidePopup])

    return (
        <>
            <MDButton
                icon={icon_edit}
                page="stammdaten_satzart"
                onClick={handleShowPopup}
                tooltip_text="Satzart bearbeiten"
            />
            <Popup
                visible={showPopUp}
                onHiding={handleHidePopup}
                dragEnabled={true}
                hideOnOutsideClick={true}
                fullScreen={false}
                showCloseButton={true}
                height='auto'
                maxHeight="70vh"
                width='auto'
                maxWidth="90vw"
            >

                <ToolbarItem
                    toolbar="top"
                    location={'before'}
                    render={RenderePopupTitle}
                />

                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={memo_optione_abbrechen}
                />
                <ScrollView>
                    <Accordion
                        //dataSource={["Neue Satzart", "Satzart löschen"]}
                        collapsible={true}
                        multiple={false}
                        animationDuration={300}
                    //selectedItems={selectedItems}
                    //onSelectionChanged={this.selectionChanged}
                    //itemTitleRender={(data) => <h5>{data}</h5>}
                    >
                        <Item title="Neue Satzart" >
                            <>
                                <div className="row p-2 p-md-3">
                                    <div className="col-12 p-1 p-md-2">
                                        <TextBox
                                            label="Bezeichnung"
                                            labelMode="floating"
                                            stylingMode="outlined"
                                            onValueChange={setBezeichnung}
                                            value={bezeichnung}
                                            ref={refBezeichnung}
                                            valueChangeEvent="input"
                                            maxLength={60}
                                            onContentReady={handleContentReadyTextBox}
                                        />

                                    </div>
                                    <div className="col-12 p-1 p-md-2 d-flex">
                                        <MDButton
                                            page="stammdaten_satzart"
                                            button_text="hinzufügen"
                                            className="mx-auto"
                                            stylingMode="outlined"
                                            onClick={handleAddSatzart}
                                        />
                                    </div>
                                </div>
                            </>

                        </Item>
                        <Item title="Satzart löschen" component={() => {
                            return (
                                <>
                                    <div className="row p-2 p-md-3">
                                        <div className="col-12 p-1 p-md-2">
                                            <SelectBox
                                                dataSource={data_source_satzarte}
                                                displayExpr="bezeichnung"
                                                valueExpr="satzart"
                                                //acceptCustomValue={true}
                                                value={satzartValue}
                                                //onCustomItemCreating={customItemCreating}
                                                onValueChange={setSatzartValue}
                                                label='Satzart'
                                                labelMode='floating'
                                                stylingMode='outlined'
                                                onContentReady={handleContentReadySelectBox}
                                            />
                                        </div>
                                        <div className="col-12 p-1 p-md-2 d-flex">
                                            <MDButton
                                                page="stammdaten_satzart"
                                                button_text="löschen"
                                                className="mx-auto"
                                                type="danger"
                                                stylingMode="outlined"
                                                onClick={handleShowPopupConfirm}
                                            />
                                        </div>
                                    </div>
                                </>
                            )
                        }} />
                    </Accordion>
                </ScrollView>
            </Popup>

        </>
    )
}

export default memo(SatzartAddLoeschen);

const RenderePopupTitle = () => {
    return (<h2 className="title_popup">Satzart</h2>)
};

const delete_satzart = (dispatch: any, satzarte: any, satzartValue: any, callback: any) => {
    let bezeichnung = get_json_object_in_arr(satzarte, "satzart", satzartValue).bezeichnung;
    dispatch(setConfirm({
        body:
            <div className="alert alert-danger">
                <p>Wollen sie die Satzart <b>{bezeichnung}</b> wirklich löschen?</p>
            </div>,
        on_ok: () => {
            GetData({
                Operation: "DeleteStammdatenKategorie",
                Satzartnummer: satzartValue,
                Satzartbezeichnung: bezeichnung
            }, dispatch).then((res) => {
                if (!res) return
                callback();
            });
        },
        title: "Satzart löschen",
    }));

}
