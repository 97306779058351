import { configureStore } from '@reduxjs/toolkit'
import notificationsSlice from '../global/notificationsSlice';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import dataTempSlice from './features/dataTempSlice';
import einstellungenOnlineNT from './features/einstellungenSlice';

export const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, //MD so dürfen Methoden und objekten in dem Store geladen werden
    }),
  reducer: {
    store_notification: notificationsSlice,
    store_temp_data: dataTempSlice,
    store_einstellungen: einstellungenOnlineNT,
  }
})


export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch


export const useAppDispatch = () => useDispatch<typeof store.dispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector