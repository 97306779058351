import { DateBox, Popup, SelectBox } from "devextreme-react";
import { ToolbarItem } from "devextreme-react/autocomplete";
import ArrayStore from "devextreme/data/array_store";
import { ReactElement, useCallback, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { GetData } from "../../global/communication";
import { NOW } from "../../global/const";
import { get_date_ajax_param, handleContentReadyDateBox, handleContentReadySelectBox } from "../../global/hilfs_functions";
import { setAlert } from "../../state";



const data_source = new ArrayStore({
    data: [
        { Name: "Heutige ausnehmen", ID: 1 },
        { Name: "Heutige und gestrige ausnehmen", ID: 2 },
        { Name: "Letzte 3 Tage ausnehmen", ID: 3 },
        { Name: "Benutzerdefinierter Zeitraum", ID: 4 },
        { Name: "Alle Fälle", ID: 0 },
    ],
    key: 'ID',
});
const DatenexportStarten: React.FC = (): ReactElement => {

    const [showPopupFilter, setShowPopupFilter] = useState(true);
    const [dateValue, setDateValue] = useState(new Date());
    const [selectBoxValue, setSelectBoxValue] = useState(1);
    const [showDateBox, setShowDateBox] = useState(false);
    const dispatch = useDispatch();

    const handleExportieren = useCallback(() => {
        let value = selectBoxValue.toString();
        selectBoxValue === 4 &&
            (value = get_date_ajax_param(dateValue));

        GetData({ Operation: "StartExport", EXPORTBIS: value }, dispatch)
            .then((res) => {
                if (!res) return
                setShowPopupFilter(false)
                dispatch(setAlert({
                    text: "Datenexport erfolgreich ausgeführt.",
                    type: "Info",
                }))
            });
    }, [selectBoxValue, dispatch, dateValue]);

    const handleClickHidePopup = useCallback(() => {
        setShowPopupFilter(false)
    }, []);

    const memo_options_exportieren = useMemo(() => {
        return {
            text: 'Exportieren',
            onClick: handleExportieren,
        }
    }, [handleExportieren]);

    const memo_options_abbrechen = useMemo(() => {
        return {
            text: 'Abbrechen',
            onClick: handleClickHidePopup,
        }
    }, [handleClickHidePopup]);

    const handleValueChange = useCallback((value: any) => {
        setSelectBoxValue(value);
        value === 4 ?
            setShowDateBox(true)
            :
            setShowDateBox(false);
    }, []);
    const handleValueDateChange = useCallback((e: any) => {
        setDateValue(e.value)
    }, []);
    return (<>
        <Popup
            visible={showPopupFilter}
            onHiding={handleClickHidePopup}
            dragEnabled={true}
            hideOnOutsideClick={true}
            fullScreen={false}
            showCloseButton={true}
            height='auto'
            maxHeight="450px"
            maxWidth="500px"
        > <ToolbarItem
                toolbar="top"
                location={'before'}
                render={RenderePopupTitle}
            />
            <ToolbarItem
                widget="dxButton"
                toolbar="bottom"
                location="before"
                options={memo_options_exportieren}
            />
            <ToolbarItem
                widget="dxButton"
                toolbar="bottom"
                location="after"
                options={memo_options_abbrechen}
            />
            <div className='p-3'>
                <p>Wollen Sie den Datenexport jetzt starten?</p><br />
                <SelectBox
                    dataSource={data_source}
                    displayExpr="Name"
                    valueExpr="ID"
                    value={selectBoxValue}
                    onValueChange={handleValueChange}
                    // label='Zu exportierenden Fälle eingrenzen'
                    label="Fälle auswählen"
                    labelMode='floating'
                    stylingMode='outlined'
                    onContentReady={handleContentReadySelectBox}
                />
                {
                    showDateBox &&
                    <DateBox
                        value={dateValue}
                        //   onValueChanged={(e: any) => setDateValue(e.value)}
                        onValueChanged={handleValueDateChange}
                        displayFormat={"dd.MM.yyyy"}
                        //  useMaskBehavior={true} useMaskbehavior oder changeeventinput will ich nicht sonst beim löschen geht nicht mit Backspace
                        showClearButton={true}
                        useMaskBehavior={true}
                        label='Fälle exportieren bis:'
                        labelMode='floating'
                        stylingMode='outlined'
                        className='mt-4'
                        max={NOW}
                        onContentReady={handleContentReadyDateBox}
                    />
                }

            </div>
        </Popup>
    </>)
}
export default DatenexportStarten;

const RenderePopupTitle = () => {
    return (<h2 className="title_popup">Fälle exportieren</h2>)
};

