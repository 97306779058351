import axios from 'axios';
import { ITabellen } from './interfaces';
import fileDownload from 'js-file-download';
import { setAlert } from './notificationsSlice';

const apiClient = axios.create({
  baseURL: '/',
  headers: {
    'content-type': 'application/json; charset=utf-8',
  },
  responseType: 'json',
  withCredentials: false,
  // responseEncoding: 'utf8',
})

//export const Get = (param) => apiClient.post('/', qs.stringify(param))

export const Get = (param: any) => apiClient.post('/', param)

export const GetDokument = (data: any, filename: string) => {
  axios.get("/", {
    params: data,
    responseType: 'blob',
    withCredentials: true,
  }).then(res => {
    fileDownload(res.data, filename);
  });
}


export const GetData = (data: any, dispatch?: any, handle_error: boolean = true) => {
  return Get(data)
    .then(response => {
      //Wenn Server Down, dann an login weiterleiten
      if (response.data && response.data.go_to_login) {
        window.location.href = "/login"
        return
      }

      if (handle_error && (!response.data || !response.data.erfolg)) {
        console.log(response)// Fehler in der Konsole ausgeben schadet nicht
        dispatch && dispatch(setAlert({
          text: (response.data && response.data.hinweis) ? response.data.hinweis : "Es ist ein Fehler aufgetreten.",
          type: "Fehler",
        }))
        return undefined
      }

      return response.data
    })
    .catch((error) => {
      console.log(error + ' Data= ' + data.Operation);
    });
}
export async function GetDataAsync(data: any) {
  try {
    const response = await apiClient.post('/', data);
    return response.data;
  } catch (error) {
    console.error(error);
  }
}

export const myData = (dispatch: any, Tabelle: ITabellen, merkmal: string, param?: string) => {
  return GetData({
    Operation: "GetData",
    Tabelle: Tabelle,
    Merkmal: merkmal,
    Param: param ? param : "",
  }, dispatch, false).then((res) => {
    if (!res || !res.erfolg) {
      dispatch(setAlert({
        text: (res && res.hinweis) ? res.hinweis : "Es ist ein Fehler aufgetreten. Die Daten der Tabelle " + Tabelle + " konnten nicht geladen werden.",
        type: "Fehler",
      }))
      return ""
    }
    return res
  })
}