import { ToolbarItem } from "devextreme-react/autocomplete";
import { DataGrid, LoadPanel, Popup } from 'devextreme-react';
import { useCallback, useEffect, useMemo, useState } from "react";
import { useScreenSize } from "../../../global/media-query";
import { GetDataAsync } from "../../../global/communication";
import { IExportFaelle } from "../interfaces";
import { export_faelle_columns } from "../../../sonstiges/columns";
import { Column } from "devextreme-react/data-grid";
import ExportBearbeiteFallPopup from "./bearbeite_fall_popup";
import { scroll_mode_infinite } from "../../../global/const";
import { IColumn } from "../../../global/interfaces";

interface IExportBearbeitePopupProps {
    handleClickHidePopup: (e: any) => void,
    exportnummer: number,
}
const key_grid = ['exportnummer', 'lfdnr_fall'];

const ExportBearbeitenPopup: React.FC<IExportBearbeitePopupProps> = ({ exportnummer, handleClickHidePopup }): JSX.Element => {
    const screenSize = useScreenSize();
    const [isloading, setIsloading] = useState(true);
    const columns = export_faelle_columns;
    const [data, setData] = useState<IExportFaelle[]>([]);
    const [dataFallSelected, setDataFallSelected] = useState<IExportFaelle | undefined>(undefined);


    const get_data = useCallback(async () => {
        setIsloading(true);
        const res = await GetDataAsync({
            Operation: "GetFaelleVonExport",
            Exportnummer: exportnummer.toString()
        });
        if (!res) return
        if (res.erfolg === false) {
            setData([]);
        } else {
            setData(res.data);
        }
        setIsloading(false);
    }, [exportnummer]);

    useEffect(() => {
        get_data();
    }, [exportnummer, get_data]);


    const memo_reset_data_fall_selected = useCallback(() => {
        setDataFallSelected(undefined)
    }, []);
    const memo_set_data_fall_selected = useCallback((e: any) => {
        setDataFallSelected(e.data)
    }, []);

    const memo_optios_abbrechen = useMemo(() => {
        return {
            text: 'Abbrechen',
            onClick: handleClickHidePopup,
        }
    }, [handleClickHidePopup]);

    const RenderePopupTitle = useCallback(() => {
        return (<h2 className="title_popup">Export bearbeiten ({exportnummer})</h2>)
    }, [exportnummer]);

    return (
        isloading ?
            <LoadPanel visible={true} /> :
            <>

                {dataFallSelected &&
                    <ExportBearbeiteFallPopup
                        dataDB={dataFallSelected}
                        handleClickHidePopup={memo_reset_data_fall_selected}
                        update_data={get_data}
                    />
                }


                <Popup
                    visible={true}
                    onHiding={handleClickHidePopup}
                    hideOnOutsideClick={true}
                    showCloseButton={true}
                    animation={undefined}
                    height="auto"
                    maxHeight="75vh"
                    maxWidth={(screenSize.isMedium || screenSize.isSmall) ? "90vw" : "700px"}
                >
                    <ToolbarItem
                        toolbar="top"
                        location={'before'}
                        render={RenderePopupTitle}
                    />
                    <ToolbarItem
                        widget="dxButton"
                        toolbar="bottom"
                        location="after"
                        options={memo_optios_abbrechen}
                    />
                    <div className="data_grid_container data_grid_exporte_bearbeiten mt-2 mt-md-3 cursor_pointer">
                        <DataGrid
                            dataSource={data}
                            columnAutoWidth={false}
                            allowColumnResizing={true}
                            rowAlternationEnabled={true}
                            wordWrapEnabled={true}
                            noDataText={"Keine Daten"}
                            hoverStateEnabled={true}
                            onRowClick={memo_set_data_fall_selected}
                            keyExpr={key_grid}
                            scrolling={scroll_mode_infinite}
                            renderAsync={true}
                        >
                            {
                                columns.map((column: IColumn) => {
                                    return (
                                        <Column
                                            key={column.dataField}
                                            dataField={column.dataField}
                                            caption={column.caption}
                                            dataType={column.dataType}
                                            alignment={column.alignment}
                                            width={column.width}
                                            cellRender={column.cellRender}
                                        />
                                    )
                                })
                            }
                        </DataGrid>
                    </div>

                </Popup></>

    )
}

export default ExportBearbeitenPopup;