
import { memo, useCallback, useState } from "react";
import { icon_plus } from "../../../global/const";
import { MDButton } from "../../../global/components";
import TatbestandVerwaltePopup from "./verwalten_popup";

interface ITatbestandAddProps {
    get_data: () => void,
}


const TatbestaendAdd: React.FC<ITatbestandAddProps> = ({ get_data }): JSX.Element => {
    const [showPopUp, setShowPopUp] = useState(false);

    const handleShowPopup = useCallback(() => {
        setShowPopUp(true);
    }, []);
    const handleHidePopup = useCallback((update?: boolean) => {
        setShowPopUp(false);
        update && get_data()
    }, [get_data]);
    return (
        <div>
            <MDButton
                page="tatbestaende"
                icon={icon_plus}
                type="success"
                tooltip_text='Neuen Tatbestand anlegen'
                onClick={handleShowPopup}
            />
            {showPopUp && <TatbestandVerwaltePopup callback={handleHidePopup} />}
        </div>
    )
}

export default memo(TatbestaendAdd);