import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'


export interface IAlert {
    show?: boolean,
    text: string | JSX.Element,
    title?: string,
    type: "Warnung" | "Fehler" | "Info" | "Hinweis" | "Modal",
    onHiding?: () => void | undefined,
    maxWidth?: number,
}


export interface IToast {
    show?: boolean,
    message: string,
    type: "custom" | "error" | "info" | "success" | "warning",
    position?: "center center" | "bottom center" | "bottom left" | "bottom right" | "center left" | "center right",
    displayTime?: number
}

export interface IConfirm {
    show?: boolean,
    on_ok?: () => void | undefined,
    ok_text?: string,
    on_abbrechen?: () => void | undefined,
    abbrechen_text?: string,
    on_hiding?: () => void | undefined,
    title: string,
    body: JSX.Element | undefined,
}
interface IStateNotification {
    alert: IAlert,
    toast: IToast,
    confirm: IConfirm
}
const initialState: IStateNotification = {
    alert: {
        show: false,
        text: "",
        title: "",
        type: "Fehler",
        onHiding: undefined,
        maxWidth: undefined,
    },
    toast: {
        show: false,
        message: "",
        type: "info",
        position: "center center",
        displayTime: 2500,
    },
    confirm: {
        show: false,
        on_ok: undefined,
        ok_text: "Ok",
        on_abbrechen: undefined,
        abbrechen_text: "Abbrechen",
        on_hiding: undefined,
        title: "",
        body: undefined
    }
}

export const notificationsSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        resetNotification: () => initialState,
        setAlert: (state, action: PayloadAction<IAlert>) => {
            state.alert = {
                show: true,
                text: action.payload.text,
                title: action.payload.title || "",
                type: action.payload.type,
                onHiding: action.payload.onHiding ? action.payload.onHiding : undefined,
                maxWidth: action.payload.maxWidth || undefined,
            }
        },
        resetAlert: (state) => {
            state.alert = {
                show: false,
                text: "",
                title: "",
                type: "Fehler",
                onHiding: undefined,
                maxWidth: undefined,
            }
        },
        setToast: (state, action: PayloadAction<IToast>) => {
            state.toast = {
                show: true,
                message: action.payload.message,
                type: action.payload.type,
                position: action.payload.position ? action.payload.position : "center center",
                displayTime: action.payload.displayTime ? action.payload.displayTime : 2500,
            }
        },
        resetToast: (state) => {
            state.toast = {
                show: false,
                message: "",
                type: "info",
                position: "center center",
                displayTime: 2500
            }
        },
        setConfirm: (state, action: PayloadAction<IConfirm>) => {
            state.confirm = {
                show: true,
                on_ok: action.payload.on_ok,
                ok_text: action.payload.ok_text,
                on_abbrechen: action.payload.on_abbrechen,
                abbrechen_text: action.payload.abbrechen_text,
                on_hiding: action.payload.on_hiding,
                title: action.payload.title,
                body: action.payload.body
            }
        },
        resetConfirm: (state) => {
            state.confirm = {
                show: false,
                on_ok: undefined,
                ok_text: "Ok",
                on_abbrechen: undefined,
                abbrechen_text: "Abbrechen",
                on_hiding: undefined,
                title: "",
                body: undefined
            }
        },
    }
});

export default notificationsSlice.reducer;
export const { resetNotification, setAlert, resetAlert, setToast, resetToast, setConfirm, resetConfirm } = notificationsSlice.actions