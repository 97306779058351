import React, { useCallback, useEffect, useMemo, useState } from 'react';
import 'devextreme/data/odata/store';
import SelectBox from 'devextreme-react/select-box';
import TextBox from 'devextreme-react/text-box';
import Popup from 'devextreme-react/popup';
import NumberBox from 'devextreme-react/number-box';
import CheckBox from 'devextreme-react/check-box';
import DateBox from 'devextreme-react/date-box';
import ScrollView from 'devextreme-react/scroll-view';
import { TagBox } from 'devextreme-react/tag-box';
import { ToolbarItem } from 'devextreme-react/autocomplete';
import TabPanel, { Item } from "devextreme-react/tab-panel";
import { IStatistikFilter } from '../interfaces';
import { useScreenSize } from '../../../global/media-query';
import { useAppDispatch, useAppSelector } from '../../../state';
import ArrayStore from 'devextreme/data/array_store';
import { GetData } from '../../../global/communication';
import { icon_car_crash, icon_clock, icon_user } from '../../../global/const';
import { IZeuge } from '../../../global/interfaces';
import { get_date_ajax_param, get_date_from_string, handleContentReadyCheckBox, handleContentReadyDateBox, handleContentReadySelectBox, handleContentReadyTextBox, stretch0Left } from '../../../global/hilfs_functions';



interface IStatistikDialogProps {
    onOkClick: any,
    onCloseClick: any,
    sbDaten: IZeuge[],
}


const get_sb_daten_ohne_alle = (sbDaten: IZeuge[]) => {
    if (sbDaten[0].sachbearbeitername.toLowerCase() === "alle") {
        return sbDaten.slice(1);
    }
    return sbDaten;
}
export const GetCompareValuesOperatorToText = (operation: string) => {
    switch (operation) {
        case "=":
            return "gleich"
        case ">=":
            return "größer gleich";
        case ">":
            return "größer";
        case "<=":
            return "kleiner gleich";
        case "<":
            return "kleiner";
        default:
            return "gleich";
    }
}
const get_statistik_filter = (db_value: string | undefined): IStatistikFilter[] => {

    if (!db_value) return BasisFilter;
    let temp_filter = JSON.parse(JSON.stringify(BasisFilter));
    let arr = db_value.split('%00');
    arr.forEach((item, i) => {
        let arr1 = item.split('%01');
        if (arr1[0] === "true") {
            temp_filter[i].isChecked = true;
            if (arr1.length === 2) {
                temp_filter[i].Value = arr1[1];
            }
            if (arr1.length === 3) {
                temp_filter[i].Value = arr1[2];
                temp_filter[i].Operation = GetCompareValuesOperatorToText(arr1[1]);
            }
        }
    });
    return temp_filter;
}
const BenutzerdefiniertStatistikFilter: React.FC<IStatistikDialogProps> = ({ onOkClick, onCloseClick, sbDaten }): JSX.Element => {

    //const sbNamen = useAppSelector((state) => state.store_einstellungen.konfiguration2.sachbearbeiter);
    //const [sbNamen, setSbNamen] = useState<IZeuge []>([]);
    const [geraete, setGeraete] = useState<string[]>([]);
    const benutzer_definiert_statistik = useAppSelector((state) => state.store_einstellungen.konfiguration.statistik_benutzer_definiert_value);
    const statistik_filter_end = useMemo(() => get_statistik_filter(benutzer_definiert_statistik), [benutzer_definiert_statistik]);
    const [StatistikFilter, setStatistikFilter] = useState<IStatistikFilter[]>(statistik_filter_end);
    const screenSize = useScreenSize();
    const dispatch = useAppDispatch();
    const sb_value = StatistikFilter[18].Value;
    const sb_filter = useMemo(() => sb_value ? sb_value.split(",") : [], [sb_value]);
    const data_source_sb = useMemo(() =>
        new ArrayStore({
            //  data: sbNamen.map((item: ISachbearbeiter) => {
            //    return {value: item.f07001, text: item.f07002}
            //  }),
            data: get_sb_daten_ohne_alle(sbDaten),
            key: 'value',
        })
        , [sbDaten]);

    useEffect(() => {
        GetData({ Operation: "GetGeraeteFuerStatistik" }, dispatch)
            .then((res) => {
                if (!res) return
                setGeraete(res.data);
                // setSbNamen(res.sachbearbeiter);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onOperationValueChanged = useCallback((e: any, index: number) => {
        let tmpArray = [...StatistikFilter];
        let tmp = { ...tmpArray[index] };
        tmp.Operation = e;
        tmpArray[index] = tmp;
        setStatistikFilter(tmpArray);
    }, [StatistikFilter]);

    const onCheckBoxValueChanged = useCallback((e: any, index: number) => {
        const tmpArray = [...StatistikFilter];
        const tmp = { ...tmpArray[index] };
        tmp.isChecked = e
        tmpArray[index] = tmp;
        setStatistikFilter(tmpArray);
    }, [StatistikFilter]);


    const onTextBoxValueChanged = useCallback((e: any, index: number) => {
        let tmpArray = [...StatistikFilter];
        let tmp = { ...tmpArray[index] };
        if (tmp.type === 'date') {
            tmp.Value = get_date_ajax_param(e);
        } else if (tmp.type === 'time') {
            if (typeof e === 'object') {
                let date = e as Date;
                tmp.Value = stretch0Left(date.getHours().toString(), 2) + ':' + stretch0Left(date.getMinutes().toString(), 2);
            } else {
                tmp.Value = e;
            }
        } else {
            //Sachbearbeiternamme ist Array TagBox
            index === 18 ? tmp.Value = e.join(',') : tmp.Value = e;
        }
        tmpArray[index] = tmp;
        setStatistikFilter(tmpArray);
    }, [StatistikFilter]);


    const handleCheckBox1 = useCallback((value: boolean | null) => {
        onCheckBoxValueChanged(value, 1);
    }, [onCheckBoxValueChanged]);

    const handleCheckBox0 = useCallback((value: boolean | null) => {
        onCheckBoxValueChanged(value, 0);
    }, [onCheckBoxValueChanged]);

    const handleCheckBox2 = useCallback((value: boolean | null) => {
        onCheckBoxValueChanged(value, 2);
    }, [onCheckBoxValueChanged]);

    const handleCheckBox3 = useCallback((value: boolean | null) => {
        onCheckBoxValueChanged(value, 3);
    }, [onCheckBoxValueChanged]);

    const handleCheckBox4 = useCallback((value: boolean | null) => {
        onCheckBoxValueChanged(value, 4);
    }, [onCheckBoxValueChanged]);

    const handleCheckBox10 = useCallback((value: boolean | null) => {
        onCheckBoxValueChanged(value, 10);
    }, [onCheckBoxValueChanged]);

    const handleCheckBox11 = useCallback((value: boolean | null) => {
        onCheckBoxValueChanged(value, 11);
    }, [onCheckBoxValueChanged]);

    const handleCheckBox12 = useCallback((value: boolean | null) => {
        onCheckBoxValueChanged(value, 12);
    }, [onCheckBoxValueChanged]);
    const handleCheckBox13 = useCallback((value: boolean | null) => {
        onCheckBoxValueChanged(value, 13);
    }, [onCheckBoxValueChanged]);

    const handleCheckBox16 = useCallback((value: boolean | null) => {
        onCheckBoxValueChanged(value, 16);
    }, [onCheckBoxValueChanged]);

    const handleCheckBox17 = useCallback((value: boolean | null) => {
        onCheckBoxValueChanged(value, 17);
    }, [onCheckBoxValueChanged]);

    const handleCheckBox18 = useCallback((value: boolean | null) => {
        onCheckBoxValueChanged(value, 18);
    }, [onCheckBoxValueChanged]);

    const handleCheckBox5 = useCallback((value: boolean | null) => {
        onCheckBoxValueChanged(value, 5);
    }, [onCheckBoxValueChanged]);

    const handleCheckBox6 = useCallback((value: boolean | null) => {
        onCheckBoxValueChanged(value, 6);
    }, [onCheckBoxValueChanged]);

    const handleCheckBox7 = useCallback((value: boolean | null) => {
        onCheckBoxValueChanged(value, 7);
    }, [onCheckBoxValueChanged]);

    const handleCheckBox8 = useCallback((value: boolean | null) => {
        onCheckBoxValueChanged(value, 8);
    }, [onCheckBoxValueChanged]);
    const handleCheckBox19 = useCallback((value: boolean | null) => {
        onCheckBoxValueChanged(value, 19);
    }, [onCheckBoxValueChanged]);
    const handleCheckBox9 = useCallback((value: boolean | null) => {
        onCheckBoxValueChanged(value, 9);
    }, [onCheckBoxValueChanged]);
    const handleCheckBox14 = useCallback((value: boolean | null) => {
        onCheckBoxValueChanged(value, 14);
    }, [onCheckBoxValueChanged]);
    const handleCheckBox15 = useCallback((value: boolean | null) => {
        onCheckBoxValueChanged(value, 15);
    }, [onCheckBoxValueChanged]);


    const handleSelectBox1 = useCallback((e: any) => {
        onOperationValueChanged(e, 1)
    }, [onOperationValueChanged]);
    const handleSelectBox2 = useCallback((e: any) => {
        onOperationValueChanged(e, 2)
    }, [onOperationValueChanged]);
    const handleSelectBox3 = useCallback((e: any) => {
        onOperationValueChanged(e, 3)
    }, [onOperationValueChanged]);
    const handleSelectBox4 = useCallback((e: any) => {
        onOperationValueChanged(e, 4)
    }, [onOperationValueChanged]);
    const handleSelectBox5 = useCallback((e: any) => {
        onOperationValueChanged(e, 5)
    }, [onOperationValueChanged]);
    const handleSelectBox6 = useCallback((e: any) => {
        onOperationValueChanged(e, 6)
    }, [onOperationValueChanged]);
    const handleSelectBox7 = useCallback((e: any) => {
        onOperationValueChanged(e, 7)
    }, [onOperationValueChanged]);
    const handleSelectBox8 = useCallback((e: any) => {
        onOperationValueChanged(e, 8)
    }, [onOperationValueChanged]);
    const handleSelectBox19 = useCallback((e: any) => {
        onOperationValueChanged(e, 19)
    }, [onOperationValueChanged]);
    const handleSelectBox9 = useCallback((e: any) => {
        onOperationValueChanged(e, 9)
    }, [onOperationValueChanged]);
    const handleSelectBox10 = useCallback((e: any) => {
        onOperationValueChanged(e, 10)
    }, [onOperationValueChanged]);
    const handleSelectBox11 = useCallback((e: any) => {
        onOperationValueChanged(e, 11)
    }, [onOperationValueChanged]);
    const handleSelectBox12 = useCallback((e: any) => {
        onOperationValueChanged(e, 12)
    }, [onOperationValueChanged]);
    const handleSelectBox13 = useCallback((e: any) => {
        onOperationValueChanged(e, 13)
    }, [onOperationValueChanged]);
    const handleSelectBox14 = useCallback((e: any) => {
        onOperationValueChanged(e, 14)
    }, [onOperationValueChanged]);
    const handleSelectBox15 = useCallback((e: any) => {
        onOperationValueChanged(e, 15)
    }, [onOperationValueChanged]);
    const handleSelectBox18 = useCallback((e: any) => {
        onOperationValueChanged(e, 18)
    }, [onOperationValueChanged]);
    const handleSelectBox0 = useCallback((e: any) => {
        onOperationValueChanged(e, 0)
    }, [onOperationValueChanged]);


    const handleTextBox0 = useCallback((e: any) => {
        onTextBoxValueChanged(e, 0)
    }, [onTextBoxValueChanged]);
    const handleTextBox1 = useCallback((e: any) => {
        onTextBoxValueChanged(e, 1)
    }, [onTextBoxValueChanged]);
    const handleTextBox2 = useCallback((e: any) => {
        onTextBoxValueChanged(e, 2)
    }, [onTextBoxValueChanged]);
    const handleTextBox3 = useCallback((e: any) => {
        onTextBoxValueChanged(e, 3)
    }, [onTextBoxValueChanged]);
    const handleTextBox4 = useCallback((e: any) => {
        onTextBoxValueChanged(e, 4)
    }, [onTextBoxValueChanged]);
    const handleTextBox5 = useCallback((e: any) => {
        onTextBoxValueChanged(e, 5)
    }, [onTextBoxValueChanged]);
    const handleTextBox6 = useCallback((e: any) => {
        onTextBoxValueChanged(e, 6)
    }, [onTextBoxValueChanged]);
    const handleTextBox7 = useCallback((e: any) => {
        onTextBoxValueChanged(e, 7)
    }, [onTextBoxValueChanged]);
    const handleTextBox8 = useCallback((e: any) => {
        onTextBoxValueChanged(e, 8)
    }, [onTextBoxValueChanged]);
    const handleTextBox19 = useCallback((e: any) => {
        onTextBoxValueChanged(e, 19)
    }, [onTextBoxValueChanged]);
    const handleTextBox9 = useCallback((e: any) => {
        onTextBoxValueChanged(e, 9)
    }, [onTextBoxValueChanged]);
    const handleTextBox10 = useCallback((e: any) => {
        onTextBoxValueChanged(e, 10)
    }, [onTextBoxValueChanged]);
    const handleTextBox11 = useCallback((e: any) => {
        onTextBoxValueChanged(e, 11)
    }, [onTextBoxValueChanged]);
    const handleTextBox12 = useCallback((e: any) => {
        onTextBoxValueChanged(e, 12)
    }, [onTextBoxValueChanged]);
    const handleTextBox13 = useCallback((e: any) => {
        onTextBoxValueChanged(e, 13)
    }, [onTextBoxValueChanged]);
    const handleTextBox14 = useCallback((e: any) => {
        onTextBoxValueChanged(e, 14)
    }, [onTextBoxValueChanged]);
    const handleTextBox15 = useCallback((e: any) => {
        onTextBoxValueChanged(e, 15)
    }, [onTextBoxValueChanged]);
    const handleTextBox16 = useCallback((e: any) => {
        onTextBoxValueChanged(e, 16)
    }, [onTextBoxValueChanged]);
    const handleTextBox18 = useCallback((e: any) => {
        onTextBoxValueChanged(e, 18)
    }, [onTextBoxValueChanged]);

    const handleContentReady = useCallback((e: any) => {
        e.element.children[0].children[1].children[0].children[1].attributes[0].value = 'Gerät auswählen..';
        handleContentReadySelectBox(e);
    }, []);

    const handleOkClick = useCallback(() => {
        onOkClick(statistik_build_filter(StatistikFilter))
    }, [StatistikFilter, onOkClick]);

    const memo_options_ok = useMemo(() => {
        return {
            text: 'OK',
            stylingMode: "outlined",
            onClick: handleOkClick,
        }
    }, [handleOkClick]);

    const handleResetFilter = useCallback(() => {
        setStatistikFilter(BasisFilter)
    }, []);

    const memo_options_reset = useMemo(() => {
        return {
            text: 'Reset',
            stylingMode: "outlined",
            onClick: handleResetFilter,
        }
    }, [handleResetFilter]);
    return (
        <Popup
            visible={true}
            hideOnOutsideClick={true}
            showCloseButton={true}
            onHiding={onCloseClick}
            // width={"800px"} Keine statische width definieren Benutzerfreundlichkeit!
            height="auto"
            maxHeight="75vh"
            maxWidth={(screenSize.isMedium || screenSize.isSmall) ? "90vw" : "900px"}
        >
            <ToolbarItem
                toolbar="top"
                location={'before'}
                render={RenderePopupTitle}
            />
            <ScrollView>
                <TabPanel animationEnabled={true} width={'100%'} className="statistik_popup">
                    <Item title="Zeitraum" icon={icon_clock}>
                        <div className="p-2 p-md-4 ">
                            <div className="row  align-items-center">
                                <div className="col-12 col-md-4 py-1">
                                    <CheckBox onContentReady={handleContentReadyCheckBox} value={StatistikFilter[1].isChecked} onValueChange={handleCheckBox1} text="Erfassungsdatum Von" />
                                </div>
                                <div className="col-12 col-md-4 py-1">
                                    <SelectBox value={StatistikFilter[1].Operation} onValueChange={handleSelectBox1} items={statistik_compare_values_von} onContentReady={handleContentReadySelectBox} />
                                </div>
                                <div className="col-12 col-md-4 py-1">
                                    <DateBox value={StatistikFilter[1].Value ? get_date_from_string(StatistikFilter[1].Value) : StatistikFilter[1].Value} onValueChange={handleTextBox1} pickerType="calendar" displayFormat={"dd.MM.yyyy"} onContentReady={handleContentReadyDateBox} />
                                </div>
                                <div className="col-12 col-md-4 py-1">
                                    <CheckBox onContentReady={handleContentReadyCheckBox} value={StatistikFilter[3].isChecked} onValueChange={handleCheckBox3} text="Erfassungsuhrzeit Von" />
                                </div>
                                <div className="col-12 col-md-4 py-1">
                                    <SelectBox value={StatistikFilter[3].Operation} onValueChange={handleSelectBox3} items={statistik_compare_values_von} onContentReady={handleContentReadySelectBox} />
                                </div>
                                <div className="col-12 col-md-4 py-1">
                                    <DateBox value={StatistikFilter[3].Value} onValueChange={handleTextBox3} type="time" pickerType="rollers" />
                                </div>
                                <div className="col-12 col-md-4 py-1">
                                    <CheckBox onContentReady={handleContentReadyCheckBox} value={StatistikFilter[2].isChecked} onValueChange={handleCheckBox2} text="Erfassungsdatum Bis" />
                                </div>
                                <div className="col-12 col-md-4 py-1">
                                    <SelectBox value={StatistikFilter[2].Operation} onValueChange={handleSelectBox2} items={statistik_compare_values_bis} onContentReady={handleContentReadySelectBox} />
                                </div>
                                <div className="col-12 col-md-4 py-1">
                                    <DateBox value={StatistikFilter[2].Value ? get_date_from_string(StatistikFilter[2].Value) : StatistikFilter[2].Value} onValueChange={handleTextBox2} type="date" pickerType="calendar" displayFormat={"dd.MM.yyyy"} onContentReady={handleContentReadyDateBox} />
                                </div>
                                <div className="col-12 col-md-4 py-1">
                                    <CheckBox onContentReady={handleContentReadyCheckBox} value={StatistikFilter[4].isChecked} onValueChange={handleCheckBox4} text="Erfassungsuhrzeit Bis" />
                                </div>
                                <div className="col-12 col-md-4 py-1">
                                    <SelectBox value={StatistikFilter[4].Operation} onValueChange={handleSelectBox4} items={statistik_compare_values_bis} onContentReady={handleContentReadySelectBox} />
                                </div>
                                <div className="col-12 col-md-4 py-1">
                                    <DateBox value={StatistikFilter[4].Value} onValueChange={handleTextBox4} type="time" pickerType="rollers" />
                                </div>
                                <div className="col-12 col-md-4 py-1">
                                    <CheckBox onContentReady={handleContentReadyCheckBox} value={StatistikFilter[10].isChecked} onValueChange={handleCheckBox10} text="Tattag Von" />
                                </div>
                                <div className="col-12 col-md-4 py-1">
                                    <SelectBox value={StatistikFilter[10].Operation} onValueChange={handleSelectBox10} items={statistik_compare_values_von} onContentReady={handleContentReadySelectBox} />
                                </div>
                                <div className="col-12 col-md-4 py-1">
                                    <DateBox value={StatistikFilter[10].Value ? get_date_from_string(StatistikFilter[10].Value) : StatistikFilter[10].Value} onValueChange={handleTextBox10} type="date" pickerType="calendar" displayFormat={"dd.MM.yyyy"} onContentReady={handleContentReadyDateBox} />
                                </div>
                                <div className="col-12 col-md-4 py-1">
                                    <CheckBox onContentReady={handleContentReadyCheckBox} value={StatistikFilter[11].isChecked} onValueChange={handleCheckBox11} text="Tatzeit Von" />
                                </div>
                                <div className="col-12 col-md-4 py-1">
                                    <SelectBox value={StatistikFilter[11].Operation} onValueChange={handleSelectBox11} items={statistik_compare_values_von} onContentReady={handleContentReadySelectBox} />
                                </div>
                                <div className="col-12 col-md-4 py-1">
                                    <DateBox value={StatistikFilter[11].Value} onValueChange={handleTextBox11} type="time" pickerType="rollers" />
                                </div>
                                <div className="col-12 col-md-4 py-1">
                                    <CheckBox onContentReady={handleContentReadyCheckBox} value={StatistikFilter[12].isChecked} onValueChange={handleCheckBox12} text="Tattag Bis" />
                                </div>
                                <div className="col-12 col-md-4 py-1">
                                    <SelectBox value={StatistikFilter[12].Operation} onValueChange={handleSelectBox12} items={statistik_compare_values_bis} onContentReady={handleContentReadySelectBox} />
                                </div>
                                <div className="col-12 col-md-4 py-1">
                                    <DateBox value={StatistikFilter[12].Value ? get_date_from_string(StatistikFilter[12].Value) : StatistikFilter[12].Value} onValueChange={handleTextBox12} type="date" pickerType="calendar" displayFormat={"dd.MM.yyyy"} onContentReady={handleContentReadyDateBox} />
                                </div>
                                <div className="col-12 col-md-4 py-1">
                                    <CheckBox onContentReady={handleContentReadyCheckBox} value={StatistikFilter[13].isChecked} onValueChange={handleCheckBox13} text="Tatzeit Bis" />
                                </div>
                                <div className="col-12 col-md-4 py-1">
                                    <SelectBox value={StatistikFilter[13].Operation} onValueChange={handleSelectBox13} items={statistik_compare_values_bis} onContentReady={handleContentReadySelectBox} />
                                </div>
                                <div className="col-12 col-md-4 py-1">
                                    <DateBox value={StatistikFilter[13].Value} onValueChange={handleTextBox13} type="time" pickerType="rollers" />
                                </div>
                            </div>
                        </div>
                    </Item>
                    <Item title="Tatdaten" icon={icon_car_crash} >
                        <div className="p-2 p-md-4 ">
                            <div className="row  align-items-center">
                                <div className="col-12 col-md-4 py-1">
                                    <CheckBox onContentReady={handleContentReadyCheckBox} value={StatistikFilter[5].isChecked} onValueChange={handleCheckBox5} text="Fallart" />
                                </div>
                                <div className="col-12 col-md-4 py-1">
                                    <SelectBox disabled={true} value={StatistikFilter[5].Operation} onValueChange={handleSelectBox5} items={statistik_compare_values} onContentReady={handleContentReadySelectBox} />
                                </div>
                                <div className="col-12 col-md-4 py-1">
                                    <SelectBox value={StatistikFilter[5].Value} onValueChange={handleTextBox5} items={statistik_fall_art} onContentReady={handleContentReadySelectBox} />
                                </div>
                                <div className="col-12 col-md-4 py-1">
                                    <CheckBox onContentReady={handleContentReadyCheckBox} value={StatistikFilter[6].isChecked} onValueChange={handleCheckBox6} text="Ort" />
                                </div>
                                <div className="col-12 col-md-4 py-1">
                                    <SelectBox disabled={true} value={StatistikFilter[6].Operation} onValueChange={handleSelectBox6} items={statistik_compare_values} onContentReady={handleContentReadySelectBox} />
                                </div>
                                <div className="col-12 col-md-4 py-1">
                                    <TextBox value={StatistikFilter[6].Value} onValueChange={handleTextBox6} maxLength={400} valueChangeEvent="input" onContentReady={handleContentReadyTextBox} />
                                </div>
                                <div className="col-12 col-md-4 py-1">
                                    <CheckBox onContentReady={handleContentReadyCheckBox} value={StatistikFilter[7].isChecked} onValueChange={handleCheckBox7} text="Bezirk" />
                                </div>
                                <div className="col-12 col-md-4 py-1">
                                    <SelectBox disabled={true} value={StatistikFilter[7].Operation} onValueChange={handleSelectBox7} items={statistik_compare_values} onContentReady={handleContentReadySelectBox} />
                                </div>
                                <div className="col-12 col-md-4 py-1">
                                    <TextBox value={StatistikFilter[7].Value} onValueChange={handleTextBox7} maxLength={400} valueChangeEvent="input" onContentReady={handleContentReadyTextBox} />
                                </div>
                                <div className="col-12 col-md-4 py-1">
                                    <CheckBox onContentReady={handleContentReadyCheckBox} value={StatistikFilter[8].isChecked} onValueChange={handleCheckBox8} text="Straße" />
                                </div>
                                <div className="col-12 col-md-4 py-1">
                                    <SelectBox disabled={true} value={StatistikFilter[8].Operation} onValueChange={handleSelectBox8} items={statistik_compare_values} onContentReady={handleContentReadySelectBox} />
                                </div>
                                <div className="col-12 col-md-4 py-1">
                                    <TextBox value={StatistikFilter[8].Value} onValueChange={handleTextBox8} maxLength={400} valueChangeEvent="input" onContentReady={handleContentReadyTextBox} />
                                </div>
                                <div className="col-12 col-md-4 py-1">
                                    <CheckBox onContentReady={handleContentReadyCheckBox} value={StatistikFilter[19].isChecked} onValueChange={handleCheckBox19} text="Hausnummer" />
                                </div>
                                <div className="col-12 col-md-4 py-1">
                                    <SelectBox disabled={true} value={StatistikFilter[19].Operation} onValueChange={handleSelectBox19} items={statistik_compare_values} onContentReady={handleContentReadySelectBox} />
                                </div>
                                <div className="col-12 col-md-4 py-1">
                                    <TextBox value={StatistikFilter[19].Value} onValueChange={handleTextBox19} maxLength={400} valueChangeEvent="input" onContentReady={handleContentReadyTextBox} />
                                </div>
                                <div className="col-12 col-md-4 py-1">
                                    <CheckBox onContentReady={handleContentReadyCheckBox} value={StatistikFilter[9].isChecked} onValueChange={handleCheckBox9} text="KFZ-Kennzeichen" />
                                </div>
                                <div className="col-12 col-md-4 py-1">
                                    <SelectBox disabled={true} value={StatistikFilter[9].Operation} onValueChange={handleSelectBox9} items={statistik_compare_values} onContentReady={handleContentReadySelectBox} />
                                </div>
                                <div className="col-12 col-md-4 py-1">
                                    <TextBox value={StatistikFilter[9].Value} onValueChange={handleTextBox9} maxLength={400} valueChangeEvent="input" onContentReady={handleContentReadyTextBox} />
                                </div>
                                <div className="col-12 col-md-4 py-1">
                                    <CheckBox onContentReady={handleContentReadyCheckBox} value={StatistikFilter[14].isChecked} onValueChange={handleCheckBox14} text="Tatbestandsnummer" />
                                </div>
                                <div className="col-12 col-md-4 py-1">
                                    <SelectBox value={StatistikFilter[14].Operation} onValueChange={handleSelectBox14} items={statistik_compare_values} onContentReady={handleContentReadySelectBox} />
                                </div>
                                <div className="col-12 col-md-4 py-1">
                                    <TextBox value={StatistikFilter[14].Value} onValueChange={handleTextBox14} maxLength={10} valueChangeEvent="input" onContentReady={handleContentReadyTextBox} />
                                </div>
                                <div className="col-12 col-md-4 py-1">
                                    <CheckBox onContentReady={handleContentReadyCheckBox} value={StatistikFilter[15].isChecked} onValueChange={handleCheckBox15} text="Tatbetrag" />
                                </div>
                                <div className="col-12 col-md-4 py-1">
                                    <SelectBox value={StatistikFilter[15].Operation} onValueChange={handleSelectBox15} items={statistik_compare_values} onContentReady={handleContentReadySelectBox} />
                                </div>
                                <div className="col-12 col-md-4 py-1">
                                    <TextBox value={StatistikFilter[15].Value} onValueChange={handleTextBox15} maxLength={400} valueChangeEvent="input" onContentReady={handleContentReadyTextBox} />
                                </div>
                            </div>
                        </div>
                    </Item>
                    <Item title="Erfassung" icon={icon_user}>
                        <div className="p-2 p-md-4 ">
                            <div className="row  align-items-center">
                                <div className="col-12 col-md-4 py-1">
                                    <CheckBox onContentReady={handleContentReadyCheckBox} value={StatistikFilter[18].isChecked} onValueChange={handleCheckBox18} text="Sachbearbeitername" />
                                </div>
                                <div className="col-12 col-md-4 py-1">
                                    <SelectBox disabled={true} value={StatistikFilter[18].Operation} onValueChange={handleSelectBox18} items={statistik_compare_values} onContentReady={handleContentReadySelectBox} />
                                </div>
                                <div className="col-12 col-md-4 py-1">
                                    <TagBox maxDisplayedTags={1} height={"42px"} dataSource={data_source_sb}
                                        displayExpr="sachbearbeitername"
                                        valueExpr="sachbearbeiternummer"
                                        placeholder='' searchEnabled={true} value={sb_filter} onValueChange={handleTextBox18} />
                                </div>
                                <div className="col-12 col-md-4 py-1">
                                    <CheckBox onContentReady={handleContentReadyCheckBox} value={StatistikFilter[0].isChecked} onValueChange={handleCheckBox0} text="Sachbearbeiternummer" />
                                </div>
                                <div className="col-12 col-md-4 py-1">
                                    <SelectBox value={StatistikFilter[0].Operation} onValueChange={handleSelectBox0} items={statistik_compare_values} onContentReady={handleContentReadySelectBox} />
                                </div>
                                <div className="col-12 col-md-4 py-1">
                                    <NumberBox value={StatistikFilter[0].Value ? parseInt(StatistikFilter[0].Value) : undefined} onValueChange={handleTextBox0} showSpinButtons={true} min={0}
                                        onContentReady={handleContentReadyTextBox} />
                                </div>
                                <div className="col-12 col-md-4 py-1">
                                    <CheckBox onContentReady={handleContentReadyCheckBox} value={StatistikFilter[16].isChecked} onValueChange={handleCheckBox16} text="Gerätenummer" />
                                </div>
                                <div className="col-12 col-md-8 py-1">
                                    <SelectBox onContentReady={handleContentReady} items={geraete} value={StatistikFilter[16].Value} onValueChange={handleTextBox16} />
                                </div>
                                <div className="col-12 py-1 pt-3">
                                    <CheckBox onContentReady={handleContentReadyCheckBox} value={StatistikFilter[17].isChecked} onValueChange={handleCheckBox17} text="Nach Löschgrund aufsplitten (maximal 1000 Datensätze)" />
                                </div>
                            </div>
                        </div>
                    </Item>
                </TabPanel>

            </ScrollView>
            <ToolbarItem
                widget="dxButton"
                toolbar="bottom"
                location="after"
                options={memo_options_ok}
            />
            <ToolbarItem
                widget="dxButton"
                toolbar="bottom"
                location="after"
                options={memo_options_reset}
            />
        </Popup>
    )
}


export default BenutzerdefiniertStatistikFilter;
const RenderePopupTitle = () => {
    return (<h2 className="title_popup">Benutzerdefinierte Statistik erstellen</h2>)
};



const statistik_compare_values = ["gleich", "größer gleich", "größer", "kleiner gleich", "kleiner"];
const statistik_compare_values_von = ["gleich", "größer gleich", "größer"];
const statistik_compare_values_bis = ["gleich", "kleiner gleich", "kleiner"];
const statistik_fall_art = ["Alle", "VOWI", "AOWI", "ABS"];


const BasisFilter = [
    {
        ID: "Sachbearbeiternummer",
        isChecked: false,
        Operation: statistik_compare_values[0],
        Value: undefined,
        type: "number"
    },
    {
        ID: "Erfassungsdatum Von",
        isChecked: false,
        Operation: statistik_compare_values[1],
        Value: undefined,
        type: "date"
    },
    {
        ID: "Erfassungsdatum Bis",
        isChecked: false,
        Operation: statistik_compare_values[3],
        Value: undefined,
        type: "date"
    },
    {
        ID: "Erfassungsuhrzeit Von",
        isChecked: false,
        Operation: statistik_compare_values[1],
        Value: undefined,
        type: "time"
    },
    {
        ID: "Erfassungsuhrzeit Bis",
        isChecked: false,
        Operation: statistik_compare_values[3],
        Value: undefined,
        type: "time"
    },
    {
        ID: "Fallart",
        isChecked: false,
        Operation: statistik_compare_values[0],
        Value: statistik_fall_art[0],
        type: "string"
    },
    {
        ID: "Ort",
        isChecked: false,
        Operation: statistik_compare_values[0],
        Value: undefined,
        type: "string"
    },
    {
        ID: "Bezirk",
        isChecked: false,
        Operation: statistik_compare_values[0],
        Value: undefined,
        type: "string"
    },
    {
        ID: "Strasse",
        isChecked: false,
        Operation: statistik_compare_values[0],
        Value: undefined,
        type: "string"
    },
    {
        ID: "KFZ-Kennzeichen",
        isChecked: false,
        Operation: statistik_compare_values[0],
        Value: undefined,
        type: "string"
    },
    {
        ID: "Tattag Von",
        isChecked: false,
        Operation: statistik_compare_values[1],
        Value: undefined,
        type: "date"
    },
    {
        ID: "Tatzeit Von",
        isChecked: false,
        Operation: statistik_compare_values[1],
        Value: undefined,
        type: "time"
    },
    {
        ID: "Tattag Bis",
        isChecked: false,
        Operation: statistik_compare_values[3],
        Value: undefined,
        type: "date"
    },
    {
        ID: "Tatzeit Bis",
        isChecked: false,
        Operation: statistik_compare_values[3],
        Value: undefined,
        type: "time"
    },
    {
        ID: "Tatbestandsnummer",
        isChecked: false,
        Operation: statistik_compare_values[0],
        Value: undefined,
        type: "string"
    },
    {
        ID: "Tatbetrag",
        isChecked: false,
        Operation: statistik_compare_values[0],
        Value: undefined,
        type: "string"
    },
    {
        ID: "Gerätenummer",
        isChecked: false,
        Operation: statistik_compare_values[0],
        Value: undefined,
        type: "string"
    },
    {
        ID: "Löschgrund",
        isChecked: false,
        Operation: "",
        Value: undefined,
        type: "string"
    },
    {
        ID: "Sachbearbeitername",
        isChecked: false,
        Operation: statistik_compare_values[0],
        Value: undefined,
        type: "string"
    },
    /*  { // Neu aufnehmen zuerst ohne dass kann man nachher einbauen
        ID:"Länderkennzeichen",
        isChecked:false,
        Operation:statistik_compare_values[0],
        Value:"",
        type:"string"
      }*/
    {
        ID: "Hausnummer",
        isChecked: false,
        Operation: statistik_compare_values[0],
        Value: undefined,
        type: "string"
    },
];


const GetCompareValuesTextToOperator = (text: string) => {
    switch (text) {
        case "gleich":
            return "=";
        case "größer gleich":
            return ">=";
        case "größer":
            return ">";
        case "kleiner gleich":
            return "<=";
        case "kleiner":
            return "<";
        default:
            return "=";
    }
}

export const statistik_build_filter = (filter: IStatistikFilter[]): string => {

    let daten: string = '';
    filter.forEach((item: IStatistikFilter) => {
        if (item.ID === "Fallart" || item.ID === "Ort" || item.ID === "Bezirk" || item.ID === "Strasse" || item.ID === "Hausnummer" || item.ID === "KFZ-Kennzeichen" || item.ID === "Gerätenummer" || item.ID === "Sachbearbeitername") {
            daten += item.isChecked + "%01" + (item.Value ? item.Value : "") + "%00";
        } else if (item.ID === "Löschgrund") {
            daten += item.isChecked + "%00";
        } else {
            daten += item.isChecked + "%01" + GetCompareValuesTextToOperator(item.Operation) + "%01" + (item.Value ? item.Value : "") + "%00";
        }
    })

    return daten;
}