

import { DataGrid, TextBox } from "devextreme-react";
import { Column, Toolbar, Item as ItemGridToolbar, Button as ButtonGrid, Scrolling, Paging } from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import { memo, useCallback, useMemo, useRef, useState } from "react";
import { ISelectedItemStrassen } from "..";
import { GetData } from "../../../../global/communication";
import { strasse_columns } from "../../../../sonstiges/columns";
import { icon_edit, icon_pdf, MAX_ITEMS_DATA_GRID } from "../../../../global/const";
import { exportGridAsPDF, get_search_as_filter, handleContentReadyDataGrid, handleContentReadyTextBox } from "../../../../global/hilfs_functions";
import { MDButton, RendereGridTitle } from "../../../../global/components";
import { IStrasse } from "../interfaces";
import StrassenExport from "../strassen_export";
import StrassenUpload from "../strassen_import";
import StrasseAdd from "./add";


interface IStrassenGridProps {
    selectedItem: ISelectedItemStrassen,
    setSelectedItem: (obj: ISelectedItemStrassen) => void,
    setBearbeitePopup: (value: { show: boolean, row: IStrasse | null }) => void,
    refresh_grid: boolean,
}


const columns = strasse_columns();
const StrassenGrid: React.FC<IStrassenGridProps> = ({ setSelectedItem, selectedItem, setBearbeitePopup, refresh_grid }) => {
    const refGrid = useRef() as React.MutableRefObject<DataGrid<any, any>>;
    const [searchValue, setSearchValue] = useState("");
    const filter = useMemo(() => get_search_as_filter(searchValue), [searchValue]);
    const store_end = useMemo(() => store(filter, selectedItem.lfdnr_bezirk),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [selectedItem.lfdnr_bezirk, refresh_grid, filter]);

    const get_data = useCallback(() => {
        // setSearchValue("");
        // refGrid && refGrid.current && refGrid.current.instance.clearSorting();
        refGrid && refGrid.current && refGrid.current.instance.refresh();
    }, []);

    const handleClickStrasseSelect = useCallback((e: any) => {
        if (e.event.originalEvent.currentTarget.role !== "button") {
            setSelectedItem({ ...selectedItem, lfdnr_strasse: e.data.lfdnr, bezeichnung_strasse: e.data.f001 })
        }
    }, [selectedItem, setSelectedItem]);

    const handleBearbeiteStrasse = useCallback((e: any) => {
        setBearbeitePopup({ show: true, row: JSON.parse(e.element.getAttribute("data")) })
    }, [setBearbeitePopup]);

    const handlePDF = useCallback(() => {
        let header = get_header_pdf_strasse(searchValue, selectedItem.bezeichnung_ort || "", selectedItem.bezeichnung_bezirk || "");
        exportGridAsPDF(refGrid, "Straßen.pdf", header, "landscape", "striped", (cellData: any) => {
            if (cellData.gridCell.column.dataField === 'f002' && cellData.gridCell.rowType === 'data') {
                cellData.pdfCell.text = cellData.pdfCell.text.toString() === "1" ? "Ja" : "Nein";
            }
        })
    }, [searchValue, selectedItem]);


    const RendereTitle = useCallback(() => {
        return RendereGridTitle(selectedItem.bezeichnung_ort + ' ' + selectedItem.bezeichnung_bezirk)
    }, [selectedItem.bezeichnung_ort, selectedItem.bezeichnung_bezirk]);

    const RenderButtonStrasseBearbeite = useCallback((e: any) => {
        return (
            <MDButton
                page='strassen_bezirk'
                tooltip_text='Straße bearbeiten'
                icon={icon_edit}
                onClick={handleBearbeiteStrasse}
                data={e.data}
            />)
    }, [handleBearbeiteStrasse]);

    return (
        <div className="data_grid_container  data_grid_with_header mt-2">
            <DataGrid
                dataSource={store_end}
                columnAutoWidth={false}
                // keyExpr="lfdnr"
                wordWrapEnabled={true}
                renderAsync={true}
                className=" animate__animated animate__fadeIn cursor_pointer"
                remoteOperations={true}
                ref={refGrid}
                rowAlternationEnabled={true}
                onRowClick={handleClickStrasseSelect}
                hoverStateEnabled={true}
                noDataText="Keine Straßen"
                onContentReady={handleContentReadyDataGrid}
            >
                <Scrolling mode="virtual" rowRenderingMode="virtual" preloadEnabled={true} />
                <Paging defaultPageSize={MAX_ITEMS_DATA_GRID} />
                {columns.map((column) => {
                    return (
                        <Column
                            dataField={column.dataField}
                            caption={column.caption}
                            dataType={column.dataType}
                            alignment={column.alignment}
                            cellRender={column.cellRender}
                            key={column.dataField}
                            visible={column.visible}
                        />
                    )
                })}
                <Column type="buttons">
                    <ButtonGrid
                        cssClass={'d-inline-block'}
                        render={RenderButtonStrasseBearbeite}
                    />
                </Column>
                <Toolbar>
                    <ItemGridToolbar
                        location={'before'}
                        render={RendereTitle}
                    ></ItemGridToolbar>
                    <ItemGridToolbar
                        location={'after'}
                        locateInMenu="never"
                    >
                        <TextBox
                            label="Suche"
                            stylingMode='outlined'
                            mode='text'
                            value={searchValue}
                            className="suche_feld"
                            onValueChange={setSearchValue}
                            showClearButton={true}
                            maxLength={60}
                            onContentReady={handleContentReadyTextBox}>
                        </TextBox>
                    </ItemGridToolbar>
                    <ItemGridToolbar location="after" locateInMenu="auto">
                        <StrasseAdd selectedItem={selectedItem} get_data={get_data} />
                    </ItemGridToolbar>
                    <ItemGridToolbar location="after" locateInMenu="auto">
                        <StrassenUpload />
                    </ItemGridToolbar>
                    <ItemGridToolbar location="after" locateInMenu="auto">
                        <StrassenExport />
                    </ItemGridToolbar>
                    <ItemGridToolbar location="after" locateInMenu="auto">
                        <MDButton
                            page="strassen_strassetab"
                            icon={icon_pdf}
                            tooltip_text="Tabelle als PDF herunterladen"
                            onClick={handlePDF}
                        />
                    </ItemGridToolbar>
                </Toolbar>
            </DataGrid></div>
    )
}
export default memo(StrassenGrid);


const store = (filter: string, lfdnr_bezirk?: number) => {
    if ((!lfdnr_bezirk) && lfdnr_bezirk !== 0) return []
    return new CustomStore({
        key: ["lfdnr"],
        load(loadOptions: any) {

            //Achtung isLoadingAll => pdf Download
            return GetData(
                {
                    Operation: "GetStrassen",
                    LfdNrBezirk: (lfdnr_bezirk || lfdnr_bezirk === 0) ? lfdnr_bezirk.toString() : "",
                    skip: loadOptions.isLoadingAll ? "0" : loadOptions["skip"].toString(),
                    take: loadOptions["isLoadingAll"] ? "1000" : loadOptions["take"].toString(),
                    filter: filter ? filter : "",
                    sortSpalte: loadOptions.sort ? loadOptions.sort[0].selector : "",
                    sortRichtung: loadOptions.sort ? loadOptions.sort[0].desc ? "DESC" : "ASC" : "",
                }, undefined, false
            )
                .then((res) => {
                    if (!res || !res.erfolg)
                        return {
                            data: [],
                            totalCount: 0,
                        }

                    //  if(totalCount === 0){
                    //    setTotalCount(res.totalCount)
                    //  }else{
                    //  totalCount !== parseInt(res.totalCount) && setTotalCount(res.totalCount)
                    // }
                    return {
                        data: res.data,
                        totalCount: res.totalCount,
                    }

                });
            //   }


            //  }
        }

    });
}

/*
  const RenderButtonStrasseDelete: React.FC = () => {
    return(
        <MDButton 
        page='strassen_strassentab'
        tooltip_text='Straße löschen'
        icon={icon_delete}
        type='danger'
        className="mr-2"
        />)
}*/


const get_header_pdf_strasse = (searchValue: string, ort: string, bezirk: string) => {
    let header = "";
    searchValue && (header += "Suchbegriff: " + searchValue + "\n");
    header += "Ort: " + ort + "\n";
    header += "Bezirk: " + bezirk;
    return header
}
