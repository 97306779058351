import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import 'devextreme/data/odata/store';
import DataGrid, {
  Column, Toolbar, Item as ItemGridToolbar, ColumnChooser, Scrolling, Paging, Grouping, ColumnChooserSelection,
} from 'devextreme-react/data-grid';
import '../STAT.scss';
import StatistikFilter, { GetCompareValuesOperatorToText } from './filter'
import { StatistikTabellenSpaltenAlle } from '../../../sonstiges/columns';
import { formatErfassungsZeit, formatTatzeit } from "../../../sonstiges/hilfs_functions";
import { default_spalten_t715, icon_column_chooser, icon_csv, icon_filter, icon_pdf, MAX_ITEMS_DATA_GRID } from '../../../global/const';
import { SetBenutzerDefStatistik, useAppDispatch, useAppSelector } from '../../../state';
import { GetData } from '../../../global/communication';
import CustomStore from 'devextreme/data/custom_store';
import { get_end_spalten_to_db } from '../../faelle/hilfs_functions';
import { RendereGridTitleStatistikBenutzerdefiniert } from '../../../sonstiges/hilfs_functions';
import { MDButton } from '../../../global/components';
import { IColumn, IZeuge } from '../../../global/interfaces';
import { IStatistikBenutzerDefiniertData } from '../interfaces';
import { exportGridAsPDF, handleContentReadyDataGrid } from '../../../global/hilfs_functions';



const Statistik_Benutzerdefiniert = (): JSX.Element => {
  const [hideFilter, sethideFilter] = useState<Boolean>(true);
  const konfiguration_spalten = useAppSelector((state) => state.store_einstellungen.konfiguration.t715_spalten);
  const columns = useMemo(() => get_columns(konfiguration_spalten), [konfiguration_spalten]);
  const dataGridRef = useRef() as React.MutableRefObject<DataGrid<any, any>>;
  const benutzer_definiert_statistik = useAppSelector((state) => state.store_einstellungen.konfiguration.statistik_benutzer_definiert_value);
  const [filter, setFilter] = useState(benutzer_definiert_statistik ? benutzer_definiert_statistik : "false%01=%01%00false%01>=%01%00false%01<=%01%00false%01>=%01%00false%01<=%01%00false%01Alle%00false%01%00false%01%00false%01%00false%01%00false%01>=%01%00false%01>=%01%00false%01<=%01%00false%01<=%01%00false%01=%01%00false%01=%01%00false%01%00false%00false%01%00false%01%00");
  const dispatch = useAppDispatch();
  //const sb_daten = useAppSelector((state) => state.store_einstellungen.konfiguration2.sachbearbeiter);
  const [sbDaten, setSbDaten] = useState<IZeuge[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [sort, setSort] = useState({ sortSpalte: "", sortRichtung: "" });
  const store_end = useMemo(() => store(filter, totalCount, setTotalCount, setSort, sort),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filter, setTotalCount, setSort]);
  const isGefiltert = useMemo(() => {
    let arr = filter.split('%00');
    let gefunden = false;
    for (let item of arr) {
      let arr1 = item.split('%01');
      if (arr1[0] === "true") {
        gefunden = true;
        break;
      }
    }
    return gefunden;
  }, [filter]);


  useEffect(() => {
    GetData({ Operation: "GetZeugen" }, dispatch)
      .then((res) => {
        if (!res) return
        setSbDaten(res.faelle_zeugen);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const onOkClick = useCallback((filter: string) => {
    sethideFilter(true);
    setFilter(filter);
    dispatch(SetBenutzerDefStatistik(filter));
  }, [dispatch]);


  const handleDownloadCSV = useCallback(() => {
    GetData(
      {
        Operation: "GetStatistik",
        skip: "0",
        take: (filter && filter.split("%00")[17].split("%01")[0] === "true") ? "1000" : "1000",
        filter: filter,
        csv: 'Ja',
        sortSpalte: sort.sortSpalte,
        sortRichtung: sort.sortRichtung,
      }, dispatch
    )
      .then((res) => {
        if (!res) return

        var pfad = res.Wert;
        // Als Download öffnen				
        var link = document.createElement("a");
        link.href = '/' + pfad.substring(0, pfad.lastIndexOf("/") + 1) + encodeURIComponent(pfad.substring(pfad.lastIndexOf("/") + 1, pfad.length));
        link.setAttribute("download", pfad.substring(pfad.lastIndexOf("/") + 1, pfad.length))
        link.click();
      });
  }, [filter, sort, dispatch]);

  const handleHideFilter = useCallback(() => {
    sethideFilter(true)
  }, []);
  const handleShowFilter = useCallback(() => {
    sethideFilter(false)
  }, []);
  const handleOptionsChange = useCallback((e: any) => {
    get_end_spalten_to_db(e, konfiguration_spalten, StatistikTabellenSpaltenAlle, dispatch, "T715")
  }, [dispatch, konfiguration_spalten]);


  const handlePDF = useCallback(() => {
    let header = get_pdf_header_statistik_benutzer_definiert(filter, sbDaten, totalCount)
    exportGridAsPDF(dataGridRef, "Statstik.pdf", header, "landscape", "striped", (cellData: any) => {
      if (cellData.gridCell.column.dataField === 'f002' && cellData.gridCell.rowType === 'data') {
        cellData.pdfCell.text = formatErfassungsZeit(cellData.gridCell.data.f002, cellData.gridCell.data.f003);
      } else if (cellData.gridCell.column.dataField === 'f024' && cellData.gridCell.rowType === 'data') {
        cellData.pdfCell.text = formatTatzeit(cellData.gridCell.data.f024, cellData.gridCell.data.f025);
      } else if (cellData.gridCell.column.dataField === 'f026' && cellData.gridCell.rowType === 'data') {
        cellData.pdfCell.text = formatTatzeit(cellData.gridCell.data.f026, cellData.gridCell.data.f027);
      }
    })
  }, [filter, sbDaten, totalCount]);


  const handleColumnChooser = useCallback(() => {
    if (dataGridRef && dataGridRef.current) {
      dataGridRef.current.instance.showColumnChooser();
    }
  }, [dataGridRef]);
  return (
    <>
      {/** (hideSpaltenauswahl===false)?<Spaltenauswahl onCloseClick={onCloseSpaltenClick} onSaveClick={onSaveSpaltenClick} TabellenSpalten={columns}></Spaltenauswahl>:<></> **/}
      {(hideFilter === false) ? <StatistikFilter sbDaten={sbDaten} onOkClick={onOkClick} onCloseClick={handleHideFilter}></StatistikFilter> :
        <>
          {/**<ScrollView className="mt-2 mt-md-4">  */}
          <div className="data_grid_container mt-2 mt-md-3">
            <DataGrid
              dataSource={store_end}
              allowColumnResizing={true}
              columnAutoWidth={false}
              rowAlternationEnabled={true}
              focusedRowEnabled={false}
              remoteOperations={true}
              renderAsync={true}
              ref={dataGridRef}
              noDataText={"Keine Statistiken"}
              className=" animate__animated animate__fadeIn"
              allowColumnReordering={true}
              onOptionChanged={handleOptionsChange}//handleOptionsChange}  
              wordWrapEnabled={true}
              //  keyExpr={['f001','f002', 'f003']}
              // height={'500px'} Kein Height
              height={"auto"}
              onContentReady={handleContentReadyDataGrid}
            >
              {/**wir machen sortierung nur eine Richtung.*/}
              {(filter && filter.split("%00")[17].split("%01")[0] === "true") && <Grouping autoExpandAll={true} />}
              <Scrolling mode="virtual" rowRenderingMode="virtual" preloadEnabled={true} />
              <Paging defaultPageSize={MAX_ITEMS_DATA_GRID} />
              <ColumnChooser enabled={true} mode="select" title="Spalten auswählen" allowSearch={true} sortOrder="asc">
                <ColumnChooserSelection
                  selectByClick={true} />
              </ColumnChooser>

              {
                columns.map((column) => {
                  return (
                    <Column
                      dataField={column.dataField}
                      caption={column.caption}
                      dataType={column.dataType}
                      visible={column.visible}
                      key={column.dataField}
                      cellRender={column.cellRender}
                      alignment={column.alignment}
                      allowSorting={false}
                      minWidth={column.minWidth}
                      groupIndex={(filter && filter.split("%00")[17].split("%01")[0] === "true" && column.dataField === "f007") ? 0 : undefined}
                    />
                  )
                })
              }
              {/**
         *         <Scrolling mode="infinite" rowRenderingMode="virtual" />
        <Paging defaultPageSize={100} />
         */}
              <Toolbar>
                <ItemGridToolbar
                  location={'before'}
                  render={RendereGridTitleStatistikBenutzerdefiniert}
                />
                <ItemGridToolbar location="after" locateInMenu="auto">
                  <MDButton
                    page='statistik'
                    icon={icon_filter}
                    onClick={handleShowFilter}
                    tooltip_text="Statistik filtern"
                    className={isGefiltert ? "highlight" : ""}
                  />
                </ItemGridToolbar>
                <ItemGridToolbar
                  location={'after'}
                  locateInMenu="auto"
                >
                  <MDButton
                    page='statistik'
                    icon={icon_pdf}
                    onClick={handlePDF}
                    tooltip_text="Tabelle als PDF herunterladen"
                  />
                </ItemGridToolbar>
                <ItemGridToolbar
                  location={'after'}
                  locateInMenu="auto"
                >
                  <MDButton
                    page='statistik'
                    icon={icon_csv}
                    tooltip_text='Statstik als csv herunterladen'
                    onClick={handleDownloadCSV}

                  />
                </ItemGridToolbar>
                <ItemGridToolbar location="after" locateInMenu="auto">
                  <MDButton
                    page='statistik'
                    icon={icon_column_chooser}
                    onClick={handleColumnChooser}
                    tooltip_text="Spalten auswählen"
                  />
                </ItemGridToolbar>
              </Toolbar>
            </DataGrid></div>

          {/** </ScrollView>  <StatistikFooter STAT_Footer={footerItems}></StatistikFooter> */}
        </>}</>
  )
}

export default Statistik_Benutzerdefiniert;




const store = (filter: string, totalCount: number, setTotalCount: any, setSort: any, sort: any) => {
  return new CustomStore({
    key: ['f001', 'f002', 'f003'],
    load(loadOptions: any) {
      let group = false;
      if (filter && filter.split("%00")[17].split("%01")[0] === "true") {
        group = true;
      }
      let sortSpalte = loadOptions.sort ? loadOptions.sort[0].selector : "";
      let sortRichtung = loadOptions.sort ? loadOptions.sort[0].desc ? "DESC" : "ASC" : "";
      if (sort.sortSpalte !== sortSpalte || sort.sortRichtung !== sortRichtung) {
        setSort({
          sortSpalte: sortSpalte,
          sortRichtung: sortRichtung,
        });
      }

      //Achtung isLoadingAll => pdf Download
      //Achtung group => Löschgrund aufsplitten maximal 1000
      return GetData(
        {
          Operation: "GetStatistik",
          skip: (loadOptions.isLoadingAll || loadOptions["group"] || loadOptions.requireTotalCount) ? "0" : loadOptions["skip"].toString(),
          take: (loadOptions.isLoadingAll || loadOptions["group"]) ? "1000" : loadOptions["take"].toString(),
          filter: filter,
          csv: 'Nein',
          sortSpalte: sortSpalte,
          sortRichtung: sortRichtung,
        }, undefined, false
      )
        .then((res) => {
          if (!res || !res.erfolg) return {
            data: [],
            totalCount: 0,
          }

          if (totalCount === 0) {
            setTotalCount(res.totalCount)
          } else {
            totalCount !== parseInt(res.totalCount) && setTotalCount(res.totalCount)
          }
          return {
            //   data: res.data,
            data: group ? get_statistik_data_aufgesplittet(res.data) : res.data,
            totalCount: res.totalCount,
          }

        });
      //   }


      //  }
    }

  });
}

const get_columns = (konfiguration_spalten: string): IColumn[] => {

  !konfiguration_spalten && (konfiguration_spalten = default_spalten_t715)
  let arr: string[] = konfiguration_spalten.split(';').filter(i => i).map(i => i.trim());

  for (let i of arr) {
    for (let j in StatistikTabellenSpaltenAlle) {
      if (StatistikTabellenSpaltenAlle[j].dataField === i) {
        StatistikTabellenSpaltenAlle[j].visible = true;
        break;
      }
    }
  }

  return StatistikTabellenSpaltenAlle;

}


const get_pdf_header_statistik_benutzer_definiert = (filter: string, sb_daten: IZeuge[], totalCount: number): string => {
  if (!filter) return ""

  let end_pdfHeader = "Benutzerdefinierte Statistik";
  let pdfHeader = '';
  let arr = filter.split('%00');

  if (arr[18].split("%01")[0] === "true" && sb_daten.length > 0) {
    let arrSB = arr[18].split("%01")[1].split(',');
    let sachbearbeiternamen = '';
    arrSB.forEach((sb_nummer: string) => {
      sachbearbeiternamen !== '' &&
        (sachbearbeiternamen += ', ')

      for (let sachbearbeiter of sb_daten) {
        if (sachbearbeiter.sachbearbeiternummer === parseInt(sb_nummer)) {
          sachbearbeiternamen += sachbearbeiter.sachbearbeitername;
          break;
        }
      }

    });


    (pdfHeader += '\nSachbearbeiternamen: ' + sachbearbeiternamen)
  };
  arr[0].split("%01")[0] === "true" &&
    (pdfHeader += '\nSachbearbeiternummer: ' + GetCompareValuesOperatorToText(arr[0].split("%01")[1]) + ' ' + arr[0].split("%01")[2])
  arr[1].split("%01")[0] === "true" &&
    (pdfHeader += '\nErfassungsdatum von: ' + GetCompareValuesOperatorToText(arr[1].split("%01")[1]) + ' ' + arr[1].split("%01")[2])
  arr[2].split("%01")[0] === "true" &&
    (pdfHeader += '\nErfassungsdatum bis: ' + GetCompareValuesOperatorToText(arr[2].split("%01")[1]) + ' ' + arr[2].split("%01")[2])
  arr[3].split("%01")[0] === "true" &&
    (pdfHeader += '\nErfassungsuhrzeit von: ' + GetCompareValuesOperatorToText(arr[3].split("%01")[1]) + ' ' + arr[3].split("%01")[2] + ' Uhr')
  arr[4].split("%01")[0] === "true" &&
    (pdfHeader += '\nErfassungsuhrzeit bis: ' + GetCompareValuesOperatorToText(arr[4].split("%01")[1]) + ' ' + arr[4].split("%01")[2] + ' Uhr');
  (arr[5].split("%01")[0] === "true" && arr[5].split("%01")[1].toUpperCase() !== "ALLE") &&
    (pdfHeader += '\nFallart: ' + arr[5].split("%01")[1])
  arr[6].split("%01")[0] === "true" &&
    (pdfHeader += '\nOrt: ' + arr[6].split("%01")[1])
  arr[7].split("%01")[0] === "true" &&
    (pdfHeader += '\nBezirk: ' + arr[7].split("%01")[1])
  arr[8].split("%01")[0] === "true" &&
    (pdfHeader += '\nStraße: ' + arr[8].split("%01")[1])
  arr[9].split("%01")[0] === "true" &&
    (pdfHeader += '\nKFZ-Kennzeichen: ' + arr[9].split("%01")[1])
  arr[10].split("%01")[0] === "true" &&
    (pdfHeader += '\nTattag von: ' + GetCompareValuesOperatorToText(arr[10].split("%01")[1]) + ' ' + arr[10].split("%01")[2])
  arr[11].split("%01")[0] === "true" &&
    (pdfHeader += '\nTatzeit von: ' + GetCompareValuesOperatorToText(arr[11].split("%01")[1]) + ' ' + arr[11].split("%01")[2] + ' Uhr')
  arr[12].split("%01")[0] === "true" &&
    (pdfHeader += '\nTattag bis: ' + GetCompareValuesOperatorToText(arr[12].split("%01")[1]) + ' ' + arr[12].split("%01")[2])
  arr[13].split("%01")[0] === "true" &&
    (pdfHeader += '\nTatzeit bis: ' + GetCompareValuesOperatorToText(arr[13].split("%01")[1]) + ' ' + arr[13].split("%01")[2] + ' Uhr')
  arr[14].split("%01")[0] === "true" &&
    (pdfHeader += '\nTatbestandsnummer: ' + GetCompareValuesOperatorToText(arr[14].split("%01")[1]) + ' ' + arr[14].split("%01")[2])
  arr[15].split("%01")[0] === "true" &&
    (pdfHeader += '\nTatbetrag: ' + GetCompareValuesOperatorToText(arr[15].split("%01")[1]) + ' ' + arr[15].split("%01")[2])
  arr[16].split("%01")[0] === "true" &&
    (pdfHeader += '\nGerätenummer: ' + arr[16].split("%01")[1])
  arr[17].split("%01")[0] === "true" &&
    (pdfHeader += '\nNach Löschgrund aufsplitten')
  arr[19].split("%01")[0] === "true" &&
    (pdfHeader += '\nHausnummer: ' + arr[19].split("%01")[1])


  pdfHeader && (end_pdfHeader += pdfHeader)
  let anzahl = "0";
  if (totalCount) anzahl = totalCount.toString();
  end_pdfHeader += "\nAnzahl der Fälle: " + anzahl;

  return end_pdfHeader;
}

const get_statistik_data_aufgesplittet = (data: IStatistikBenutzerDefiniertData[]) => {
  let result: any[] = [];
  //const data = generate_data_statistik();
  const is_loeschGrund_exists = (f007: string): boolean => {
    let resultBool = false;
    result.forEach((item: any) => {
      if (item["key"] === f007) {
        resultBool = true;
      }
    })
    return resultBool;
  }
  data.forEach((item: IStatistikBenutzerDefiniertData) => {
    let loeschGrund = item.f007;
    if (!is_loeschGrund_exists(loeschGrund)) {
      result.push({
        key: loeschGrund,
        items: [item],
      });
    } else {
      result.forEach((item_temp) => {
        if (item_temp["key"] === loeschGrund) {
          item_temp["items"].push(item);
        }
      })
    }
  })
  return result;
}
