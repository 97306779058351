
import { memo } from 'react';
import SchelppungVowi from './symbole/schelppung_vowi';
import ListenAnsicht from "./symbole/listenansicht";
import FallSpeichern from "./symbole/fall_pdf_speichern";
import Anmerkung from './symbole/anmerkung';
import ZahlBetrag from './symbole/zahl_betrag';
import Delete from './symbole/delete';
import BarBezahlung from './symbole/bar_bezahlung';
import HandyParken from './symbole/handy_parken';
import Exportiert from './symbole/exportiert';
import ABSAbschleppProtokoll from './symbole/abs_protokoll';
import ABSAbschleppFoto from './symbole/abs_foto';
import { IFallData, IFallDataZusaetzlich } from '../../interfaces';
import { useAppSelector } from '../../../../state';

export interface ICardControlProps {
    item: IFallData,
    itemZus: IFallDataZusaetzlich,
}
const CardControl: React.FC<ICardControlProps> = ({ item, itemZus }): JSX.Element => {
    const listenAnsicht = useAppSelector((state) => state.store_einstellungen.konfiguration.faelle_ansicht_liste);

    return (
        <ul className="container_card_control d-flex  flex-wrap mt-2 mt-xl-2 mb-3 mb-xl-4">
            {itemZus.fall_art === 1 && <SchelppungVowi abs_zsu={item.f010.absZSU} />}
            {itemZus.fall_art === 4 && <ABSAbschleppFoto item={item} itemZus={itemZus} />}
            <FallSpeichern zsu={item.f008} f007={item.f007} match_code={item.f070} aktzenzeichen={item.f202} lfdnr={item.lfdnr} />
            {!listenAnsicht && <ListenAnsicht item={item} />}
            {itemZus.fall_art === 4 && <ABSAbschleppProtokoll lfdnr={item.lfdnr} />}
            <Anmerkung anmerkung={item.f130} lfdnr={item.lfdnr} exportiert={itemZus.exportiert} />
            <ZahlBetrag item={item} itemZus={itemZus} />
            {itemZus.fall_art !== 5 && <li><Delete item={item} itemZus={itemZus} /></li>}
            {itemZus.fall_art !== 5 && <BarBezahlung itemZus={itemZus} lfdnr={item.lfdnr} />}
            {itemZus.fall_art !== 5 && <HandyParken f010={item.f010} lfdnr={item.lfdnr} />}
            <div className='ml-auto'>
                <Exportiert item={item} itemZus={itemZus} />
            </div>
        </ul>
    );
}

export default memo(CardControl);


