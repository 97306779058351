import { Popup, TextArea } from "devextreme-react";
import { ToolbarItem } from "devextreme-react/autocomplete";
import { useState, memo, useMemo, useCallback } from "react";
import { GetData } from "../../../../../global/communication";
import { icon_delete, icon_rotate } from "../../../../../global/const";
import { handleContentReadyTextBox } from "../../../../../global/hilfs_functions";
import { IKonfiguration, setDataTemp, setToast, useAppDispatch, useAppSelector } from "../../../../../state";
import { MDButton } from "../../../../../global/components";
import { IFallData, IFallDataZusaetzlich } from "../../../interfaces";

interface IDeleteProps {
    item: IFallData,
    itemZus?: IFallDataZusaetzlich,
    callback?: () => void,
}

const Delete: React.FC<IDeleteProps> = ({ item, itemZus, callback }) => {
    const konfiguration = useAppSelector((state) => state.store_einstellungen.konfiguration);
    const show = useMemo(() => get_status_delete_foto(item.f006, item.f009, item.istBarBezahlt, konfiguration), [item.f006, item.f009, item.istBarBezahlt, konfiguration]);
    const [showPopupLoeschen, setShowPopupLoeschen] = useState(false);
    const [loeschenLabel, setLoeschLabel] = useState('');
    const [loeschenTitle, setLoeschTitle] = useState('');
    const [showLoeschgrundPopup, setShowLoeschgrundPopup] = useState(false);
    const [texrAreaValue, setTexrAreaValue] = useState('');

    const [aktiviereLabel, setAktiviereLabel] = useState('');
    const [aktiviereTitle, setaktiviereTitle] = useState('');
    const [showAktivierePopup, setShowAktivierePopup] = useState(false);
    const dispatch = useAppDispatch();
    const data = useAppSelector((state) => state.store_temp_data.data) as IFallData[];
    const sb_name = useAppSelector((state) => state.store_einstellungen.konfiguration.sachbearbeitername);

    const handleClickLoschen = useCallback(() => {
        if (item.f006 === 1) {
            if (loeschenTitle === 'Änderung löschen') {
                setLoeschTitle('Exportierter Fall löschen');
                setLoeschLabel('Der Fall wurde bereits exportiert. Wollen sie diesen Fall unwiderruflich löschen?');
            } else {
                GetData({
                    Operation: "DeleteExportFallT701",
                    KFZKZ: item.f007,
                    ZSU: item.f008,
                }, dispatch
                )
                    .then((res) => {
                        if (!res) return
                        setShowPopupLoeschen(false);
                        let temp_data = [...data];
                        let end_data: IFallData[] = [];
                        temp_data.forEach((itemData) => {
                            if (itemData.lfdnr !== item.lfdnr) {
                                end_data.push(itemData);
                            }
                        });
                        dispatch(setDataTemp({ data: end_data, new_data: true }));

                        callback && callback();
                    }

                    );

            }
        } else {
            setShowPopupLoeschen(false);
            setShowLoeschgrundPopup(true);
        }
    }, [item, loeschenTitle, dispatch, data, callback]);


    const handleClickFallLoeschen = useCallback(() => {
        if (!texrAreaValue) {
            dispatch(setToast({
                message: 'Es muss ein Löschgrund angegeben werden.',
                type: 'error',
                displayTime: 2000
            }));
        }
        else {
            GetData({
                Operation: "DeleteFall",
                LFDNR: item.lfdnr.toString(),
                KFZKZ: item.f007,
                TATTAG: item.f002,
                TATZEIT: item.f003,
                LOESCHGRUND: texrAreaValue.trim(),
            }, dispatch
            )
                .then((res) => {
                    if (!res) return
                    setShowLoeschgrundPopup(false);
                    dispatch(setToast({
                        message: "Der Fall wurde gelöscht",
                        type: "success",
                    }));
                    let temp_data = [...data];
                    let end_data: IFallData[] = [];
                    temp_data.forEach((itemData) => {
                        if (itemData.lfdnr === item.lfdnr) {
                            //MD 21.09.2022 so sollte es sein
                            let heute = new Date();
                            let new_f010 = { ...itemData.f010 };
                            new_f010 = { ...new_f010, Grund: texrAreaValue.trim() }
                            new_f010 = { ...new_f010, LoeschSachbearbeitername: sb_name }
                            new_f010 = { ...new_f010, LoeschsatzDatum: heute.getDate() + "." + (heute.getMonth() + 1) + "." + heute.getFullYear() }
                            new_f010 = { ...new_f010, loesch_uhrzeit: ("0" + heute.getHours()).slice(-2) + ":" + ("0" + heute.getMinutes()).slice(-2) }
                            itemData = { ...itemData, f010: new_f010 }
                            itemData = { ...itemData, f006: 2 }
                        }
                        end_data.push(itemData);
                    });
                    dispatch(setDataTemp({ data: end_data, new_data: true }));
                    callback && callback();

                }
                );
        }


    }, [texrAreaValue, dispatch, item, data, sb_name, callback]);

    const handleClickAktiviereFall = useCallback(() => {
        if (item.f005 !== "Fall") {
            setAktiviereLabel('Es wird neben der Änderung der gesamte Fall inkl. aller Änderungen aktiviert.');
            setaktiviereTitle('Änderung aktivieren');
        }
        else {
            setAktiviereLabel('Wollen Sie den Fall jetzt aktivieren?');
            setaktiviereTitle('Fall aktivieren');
        }
        setShowAktivierePopup(true);

    }, [item.f005]);

    const handleClickFallAktivieren = useCallback(() => {
        GetData({
            Operation: "ActivateFall",
            LfdNr: item.lfdnr,
        }, dispatch
        )
            .then((res) => {
                if (!res) return
                setShowAktivierePopup(false);
                dispatch(setToast({
                    message: "Der Fall wurde aktiviert",
                    type: "success",
                }));
                let temp_data = [...data];
                let end_data: IFallData[] = [];
                temp_data.forEach((itemData) => {
                    if (itemData.lfdnr === item.lfdnr) {
                        itemData = { ...itemData, f006: 0 }
                        let new_f010 = { ...itemData.f010 };
                        new_f010 = { ...new_f010, Grund: undefined }
                        new_f010 = { ...new_f010, LoeschSachbearbeitername: undefined }
                        new_f010 = { ...new_f010, LoeschsatzDatum: undefined }
                        new_f010 = { ...new_f010, loesch_uhrzeit: undefined }
                        if (itemData.f004 === 2) {
                            itemData = { ...itemData, f004: 1 }
                        }
                        itemData = { ...itemData, f010: new_f010 }
                    }
                    end_data.push(itemData);
                });
                dispatch(setDataTemp({ data: end_data, new_data: true }));
                callback && callback();
            }
            );


    }, [item, dispatch, data, callback]);


    const handleClick1 = useCallback(() => {
        if (item.f005 !== "Fall") {
            setLoeschTitle("Änderung löschen");
            setLoeschLabel('Durch das Löschen einer Änderung wird der gesamte Fall gelöscht!');
            setShowPopupLoeschen(true);
        }
        else {
            if (item.f006 === 1) {
                setLoeschTitle('Exportierter Fall löschen');
                setLoeschLabel('Der Fall wurde bereits exportiert. Wollen sie diesen Fall unwiderruflich löschen?');
                setShowPopupLoeschen(true);
            } else {
                setShowLoeschgrundPopup(true);
            }
        }
    }, [item]);

    const handeHidePopupLoeschen = useCallback(() => {
        setShowPopupLoeschen(false)
    }, []);

    const memo_options_loeschen = useMemo(() => {
        return {
            text: 'Ok',
            onClick: handleClickLoschen,
        }
    }, [handleClickLoschen]);
    const memo_options_abbrechen = useMemo(() => {
        return {
            text: 'Abbrechen',
            onClick: handeHidePopupLoeschen,
        }
    }, [handeHidePopupLoeschen]);


    const handeHidePopupLoeschGrund = useCallback(() => {
        setShowLoeschgrundPopup(false)
    }, []);

    const memo_options_loeschen_grund = useMemo(() => {
        return {
            text: 'löschen',
            type: 'danger',
            onClick: handleClickFallLoeschen,
        }
    }, [handleClickFallLoeschen]);
    const memo_options_abbrechen_grund = useMemo(() => {
        return {
            text: 'Abbrechen',
            onClick: handeHidePopupLoeschGrund,
        }
    }, [handeHidePopupLoeschGrund]);

    const handleChangeTextArea = useCallback((e: any) => {
        setTexrAreaValue(e.value)
    }, []);

    const handeHidePopupAktiviere = useCallback(() => {
        setShowAktivierePopup(false)
    }, []);

    const memo_options_aktiviere = useMemo(() => {
        return {
            text: 'Ok',
            onClick: handleClickFallAktivieren,
        }
    }, [handleClickFallAktivieren]);
    const memo_options_abbrechen_aktiviere = useMemo(() => {
        return {
            text: 'Abbrechen',
            onClick: handeHidePopupAktiviere,
        }
    }, [handeHidePopupAktiviere]);

    const RenderePopupTitleLoeschtitle = useCallback(() => {
        return (<h2 className="title_popup">{loeschenTitle}</h2>)
    }, [loeschenTitle]);

    const RenderePopupTitleAktiviere = useCallback(() => {
        return (<h2 className="title_popup">{aktiviereTitle}</h2>)
    }, [aktiviereTitle]);

    return (
        <>
            {show === 1 ?
                <MDButton
                    icon={icon_delete}
                    type="danger"
                    page="fall_card"
                    tooltip_text="Löscht den ganzen Fall inkl. aller Änderungen."
                    onClick={handleClick1}
                /> :
                show === 2 ?
                    <MDButton
                        page="fall_card"
                        type="success"
                        onClick={handleClickAktiviereFall}
                        tooltip_text="Aktiviert den gelöschten Fall inkl. aller Änderungen."
                        icon={icon_rotate}
                    /> :
                    <></>
            }
            <Popup
                visible={showPopupLoeschen}
                onHiding={handeHidePopupLoeschen}
                dragEnabled={true}
                hideOnOutsideClick={true}
                fullScreen={false}
                showCloseButton={true}
                maxHeight="200px"
                maxWidth="450px"
            >
                <ToolbarItem
                    toolbar="top"
                    location={'before'}
                    render={RenderePopupTitleLoeschtitle}
                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    //location="after"
                    options={memo_options_loeschen}
                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    // location="after"
                    options={memo_options_abbrechen}
                />
                <div className="row ">
                    <div className='col-12'>
                        <p>{loeschenLabel}</p>
                    </div>
                </div>
            </Popup>
            <Popup
                visible={showLoeschgrundPopup}
                onHiding={handeHidePopupLoeschGrund}
                dragEnabled={true}
                hideOnOutsideClick={true}
                fullScreen={false}
                showCloseButton={true}
                maxHeight="230px"
                maxWidth="450px"
            >
                <ToolbarItem
                    toolbar="top"
                    location={'before'}
                    render={RenderePopupTitleFallLoeschen}
                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="before"
                    options={memo_options_loeschen_grund}
                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={memo_options_abbrechen_grund}
                />
                <div className="row ">
                    <div className='col-12'>
                        <TextArea
                            height={70}
                            label='Geben Sie einen löschgrund an'
                            labelMode='floating'
                            stylingMode='underlined'
                            value={texrAreaValue}
                            valueChangeEvent="input"
                            onValueChanged={handleChangeTextArea}
                            maxLength={512}
                            onContentReady={handleContentReadyTextBox}
                        />
                    </div>
                </div>
            </Popup>

            <Popup
                visible={showAktivierePopup}
                onHiding={handeHidePopupAktiviere}
                dragEnabled={true}
                hideOnOutsideClick={true}
                fullScreen={false}
                showCloseButton={true}
                maxHeight="200px"
                maxWidth="450px"
            >
                <ToolbarItem
                    toolbar="top"
                    location={'before'}
                    render={RenderePopupTitleAktiviere}
                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    // location="before"
                    options={memo_options_aktiviere}
                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    //  location="after"
                    options={memo_options_abbrechen_aktiviere}
                />
                <div className="row ">
                    <div className='col-12'>
                        <p>{aktiviereLabel}</p>
                    </div>
                </div>
            </Popup>
        </>
    );
}
export default memo(Delete);

const get_status_delete_foto = (status: number, f009: number, istBarBezahlt: boolean, konfiguration: IKonfiguration): 1 | 2 | 3 => {
    /**
     * 1: löschen Button soll angezeigt werden
     * 2: aktiviere Button soll angezeigt werden
     * 3: Nichts soll angezeigt werden.
     */
    const exportierte_loeschen = konfiguration.exportierte_faelle_loeschen;

    if (
        (status === 0 || (exportierte_loeschen && status === 1)) &&
        konfiguration.mobil_loeschen &&
        (
            (f009 === 1 && konfiguration.faelle_verkehr_loeschen) ||
            (f009 === 2 && konfiguration.faelle_fliess_verkehr_loeschen) ||
            (f009 === 3 && konfiguration.faelle_aowi_loeschen) ||
            (f009 === 4 && konfiguration.faelle_abschleppen_loeschen)
        )
    ) {
        if (!istBarBezahlt || (exportierte_loeschen && status === 1)) {
            return 1
        }
    }
    else if (
        (status === 2 || status === 3) &&
        (
            (f009 === 1 && konfiguration.faelle_verkehr_aktivieren) ||
            (f009 === 2 && konfiguration.faelle_fliess_verkehr_aktivieren) ||
            (f009 === 3 && konfiguration.faelle_aowi_aktivieren) ||
            (f009 === 4 && konfiguration.faelle_abschleppen_aktivieren)
        )
    ) {
        return 2;
    }


    return 3;
}

const RenderePopupTitleFallLoeschen = () => {
    return (<h2 className="title_popup">Fall löschen</h2>)
};