import { Popup, ScrollView, SelectBox } from "devextreme-react"
import { Position, ToolbarItem } from "devextreme-react/autocomplete"
import { useCallback, useMemo, useState } from "react";
import { icon_filter } from "../../../global/const";
import { handleContentReadySelectBox } from "../../../global/hilfs_functions";
import { useScreenSizeClass } from "../../../global/media-query";
import { MDButton } from "../../../global/components";



interface IKFZHinweiseFilterKategoriePopupProps {
    setKategorie: (value: string) => void,
    kategorieArray: string[],
}

const KFZHinweiseFilterKategoriePopup: React.FC<IKFZHinweiseFilterKategoriePopupProps> = ({ kategorieArray, setKategorie }): JSX.Element => {
    const screenSize = useScreenSizeClass();
    const isScreenSmall = (screenSize === "sm" || screenSize === "md");
    const [showPopUpFilterKateogrie, setShowPopUpFilterKateogrie] = useState(false);
    const [kategorieValue, setKategorieValue] = useState("");
    const data_source = useMemo(() => ["Alle"].concat(kategorieArray), [kategorieArray]);
    const isGefiltert = (kategorieValue && kategorieValue !== "Alle") ? true : false;
    const handleShowPopup = useCallback(() => {
        setShowPopUpFilterKateogrie(true)
    }, []);

    const handleHidePopup = useCallback(() => {
        setShowPopUpFilterKateogrie(false)
    }, []);

    const handleClcikSpeichern = useCallback(() => {
        setKategorie(kategorieValue);
        setShowPopUpFilterKateogrie(false);
    }, [kategorieValue, setKategorie]);

    const memo_options_speichern = useMemo(() => {
        return {
            text: 'Speichern',
            onClick: handleClcikSpeichern
        }
    }, [handleClcikSpeichern]);
    const memo_options_abbrechen = useMemo(() => {
        return {
            text: 'Abbrechen',
            onClick: handleHidePopup,
        }
    }, [handleHidePopup]);

    return (
        <>
            <div id='kfz_hinweis_kategorie_filter_button'>
                <MDButton
                    page='kfz_hinweise'
                    icon={icon_filter}
                    className={isGefiltert ? "highlight" : ""}
                    tooltip_text="Kategorie auswählen"
                    onClick={handleShowPopup}
                />
            </div>
            <Popup
                visible={showPopUpFilterKateogrie}
                onHiding={handleHidePopup}
                dragEnabled={true}
                hideOnOutsideClick={true}
                fullScreen={false}
                showCloseButton={true}
                height='auto'
                maxHeight={"265px"}
                maxWidth="500px"
            >
                <ToolbarItem
                    toolbar="top"
                    location={'before'}
                    render={RenderePopupTitle}
                />
                <Position
                    at={isScreenSmall ? "center" : "right"}
                    my={isScreenSmall ? "center" : "right top"}
                    of={isScreenSmall ? window : '#kfz_hinweis_kategorie_filter_button'}
                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="before"
                    options={memo_options_speichern}
                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={memo_options_abbrechen}
                />
                <ScrollView>
                    <div className='row g-0'>
                        <div className="col-12 p-1 p-md-2">
                            <SelectBox
                                dataSource={data_source}
                                acceptCustomValue={false}
                                value={kategorieValue}
                                // onCustomItemCreating={customItemCreating}
                                onValueChange={setKategorieValue}
                                label='Kategorie'
                                labelMode='floating'
                                stylingMode='outlined'
                                onContentReady={handleContentReadySelectBox}
                            />
                        </div>
                    </div>
                </ScrollView>
            </Popup>
        </>
    )
}

export default KFZHinweiseFilterKategoriePopup
const RenderePopupTitle = () => {
    return (<h2 className="title_popup">Kategorie auswählen</h2>)
};