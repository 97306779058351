import { NumberBox, Popup, ScrollView, SelectBox, TextBox } from "devextreme-react"
import { ToolbarItem } from "devextreme-react/autocomplete"
import ArrayStore from "devextreme/data/array_store";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ISelectedItemKategorie } from "..";
import { GetData } from "../../../../global/communication";
import { tatbestand_unter_katogieren_alt_bilder } from "../../../../global/const";
import { handleErrorImg, handleContentReadySelectBox, handleContentReadyTextBox, stretch0Left } from "../../../../global/hilfs_functions";
import { Seperatorliste } from "../../../../global/seperator_liste";
import { setToast, useAppDispatch } from "../../../../state";
import { ITatbestandUnterKategorie } from "../../tatbestaende/interfaces";

interface IUnterGruppeVerwaltePopupProps {
    callback: (update?: boolean, loeschen?: boolean) => void,
    row?: ITatbestandUnterKategorie | null,
    selectedItem: ISelectedItemKategorie,
}

const style_img = { backgroundColor: "rgba(25, 94, 163,1)", borderRadius: "3px" };
const data_source = new ArrayStore({
    data: [
        { text: "nein", value: 0 },
        { text: "ja", value: 1 },
    ],
    key: 'value',
})
const UnterGruppeVerwaltePopup: React.FC<IUnterGruppeVerwaltePopupProps> = ({ callback, row, selectedItem }): JSX.Element => {

    const isAdd = row === undefined;
    const [unterGruppeNummer, setUnterGruppeNummer] = useState((!isAdd && row?.f002) ? row?.f002 : 0);
    const [bezeichnung, setBezeichnung] = useState((!isAdd && row?.f003) ? row?.f003 : "");
    const [bildNummer, setBildNummer] = useState((!isAdd && row?.f004) ? row?.f004 : 1);
    const [gruppeVerwendetValue, setGruppeVerwendetValue] = useState((!isAdd && row?.f005) ? row?.f005 : 0);
    const [hinweisFrist, setHinweisFrist] = useState((!isAdd && row?.f006) ? row?.f006 : 0);
    const dispatch = useAppDispatch();
    const unter_kategorie_bilder_items = useMemo(() => unter_kategorie_bilder_items_func(), []);

    useEffect(() => {
        if (isAdd) {
            GetData({
                Operation: "GetNextUntergruppennummer",
                Hauptgruppennummer: selectedItem.haupt_gruppen_nummer!.toString(),
            }, dispatch).then((res) => {
                if (!res) return
                setUnterGruppeNummer(parseInt(res.untergruppennummer))
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleClickSpeichern = useCallback(() => {
        if (!unterGruppeNummer) {
            dispatch(setToast({
                message: "Untergruppennummer muss vorhanden sein.",
                type: "error"
            }));
            return
        }

        if (!bezeichnung) {
            dispatch(setToast({
                message: "Bezeichnung muss vorhanden sein.",
                type: "error"
            }));
            return
        }
        if (!bildNummer) {
            dispatch(setToast({
                message: "Bildnummer muss vorhanden sein.",
                type: "error"
            }));
            return
        }

        let untergruppendaten = new Seperatorliste("%00", "%01");
        isAdd && untergruppendaten.setValue("Untergruppennummer", unterGruppeNummer.toString());
        untergruppendaten.setValue("Bezeichnung", bezeichnung.trim());
        untergruppendaten.setValue("Bildnummer", (bildNummer).toString());
        untergruppendaten.setValue("GruppeVerwenden", gruppeVerwendetValue.toString());
        untergruppendaten.setValue("HinweisfristDoppelerfassung", hinweisFrist.toString());

        let obj;
        if (isAdd) {
            obj = {
                Operation: "InsertUntergruppe",
                Untergruppendaten: untergruppendaten.getSeperatorliste(),
                Hauptgruppennummer: selectedItem.haupt_gruppen_nummer!.toString(),
            }
        } else {
            obj = {
                Operation: "ChangeUntergruppe",
                Untergruppendaten: untergruppendaten.getSeperatorliste(),
                Hauptgruppennummer: row?.f001,
                Untergruppennummer: row?.f002,
            }
        }

        GetData(obj, dispatch).then((res) => {
            if (!res) {
                callback();
                return
            }
            dispatch(setToast({
                message: !isAdd ? "Änderungen wurden erfolgreich gespeichert" : "Untergruppe wurde erfolgreich hinzugefügt.",
                type: "success",
                position: "bottom center"
            }));
            callback(true);
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [unterGruppeNummer, bezeichnung, bildNummer, gruppeVerwendetValue, hinweisFrist, isAdd]);

    const handleHidePopup = useCallback(() => {
        callback();
    }, [callback]);
    const handleHidePopupLoeschen = useCallback(() => {
        callback(undefined, true);
    }, [callback]);
    const memo_options_loeschen = useMemo(() => {
        return {
            text: 'Löschen',
            type: "danger",
            onClick: handleHidePopupLoeschen,
        }
    }, [handleHidePopupLoeschen]);
    const memo_options_speichern = useMemo(() => {
        return {
            text: 'Speichern',
            onClick: handleClickSpeichern,
        }
    }, [handleClickSpeichern]);
    const memo_options_abbrechen = useMemo(() => {
        return {
            text: 'Abbrechen',
            onClick: handleHidePopup,
        }
    }, [handleHidePopup]);
    const RenderePopupTitle = useCallback(() => {
        return (<h2 className="title_popup">{isAdd ? "Neue Untergruppe anlegen" : "Untergruppe bearbeiten"}</h2>)
    }, [isAdd]);
    return (<>
        <Popup
            visible={true}
            onHiding={handleHidePopup}
            dragEnabled={true}
            hideOnOutsideClick={true}
            fullScreen={false}
            showCloseButton={true}
            height='auto'
            maxHeight="70vh"
            maxWidth="500px"
        >
            <ToolbarItem
                toolbar="top"
                location={'before'}
                render={RenderePopupTitle}
            />
            {!isAdd &&
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="before"
                    options={memo_options_loeschen}
                />}
            <ToolbarItem
                widget="dxButton"
                toolbar="bottom"
                location="after"
                options={memo_options_speichern}
            />
            <ToolbarItem
                widget="dxButton"
                toolbar="bottom"
                location="after"
                options={memo_options_abbrechen}
            />
            <ScrollView>
                <div className="row p-2 p-md-3">
                    <div className="col-12  col-md-6 p-1 p-md-2">
                        <NumberBox
                            label="Untergruppenummer"
                            labelMode="floating"
                            stylingMode="outlined"
                            onValueChange={setUnterGruppeNummer}
                            value={unterGruppeNummer}
                            disabled={!isAdd}
                            valueChangeEvent="input"
                            onContentReady={handleContentReadyTextBox}
                        />
                    </div>
                    <div className="col-12 col-md-6 p-1 p-md-2">
                        <TextBox
                            label="Bezeichnung"
                            labelMode="floating"
                            stylingMode="outlined"
                            onValueChange={setBezeichnung}
                            value={bezeichnung}
                            valueChangeEvent="input"
                            maxLength={60}
                            onContentReady={handleContentReadyTextBox}
                        />
                    </div>
                    {/**   <div className="col-12 col-md-6 p-1 p-md-2">
                        <NumberBox
                            label="Bildnummer"
                            labelMode="floating"
                            stylingMode="outlined"
                            onValueChange={setBildNummer}
                            value={bildNummer}
                            valueChangeEvent="input"
                            max={80}
                            min={1}
                            onContentReady={handleContentReadyTextBox}
                        />
                    </div>
                    <div className="col-12 col-md-6  p-1 p-md-2 d-flex justify-content-center">
                        <img                                                                                            //MD warum war hier bildnummer -1 
                            src={"/Files/WinowigOnlineNT/Source/img/tatbestandgruppen/untergruppen/Untergruppe" + (bildNummer ? stretch0Left((bildNummer).toString(), 3) : "000") + ".png"}
                            // alt={"Untergruppe Bildnummer: " + (bildNummer ? bildNummer.toString() : "000")}
                            alt={tatbestand_unter_katogieren_alt_bilder[bildNummer]}
                            onError={handleErrorImg}
                            style={style_img}
                        />
                    </div>

                     * 
                     */}
                    <div className="col-7 p-1 p-md-2">
                        <SelectBox
                            items={unter_kategorie_bilder_items}
                            value={bildNummer}
                            onValueChange={setBildNummer}
                            label='Visualisierung'
                            labelMode='floating'
                            stylingMode='outlined'
                            itemComponent={UnterGruppenItem}
                            onContentReady={handleContentReadySelectBox}
                        />
                    </div>
                    <div className="col-5  p-1 p-md-2  d-flex justify-content-end">
                        <img
                            src={"/Files/WinowigOnlineNT/Source/img/tatbestandgruppen/untergruppen/Untergruppe" + (bildNummer ? stretch0Left((bildNummer).toString(), 3) : "000") + ".png"}
                            alt={tatbestand_unter_katogieren_alt_bilder[bildNummer]}
                            onError={handleErrorImg}
                            style={style_img}
                        />
                    </div>
                    <div className="col-12 p-1 p-md-2">
                        <SelectBox
                            dataSource={data_source}
                            displayExpr="text"
                            valueExpr="value"
                            value={gruppeVerwendetValue}
                            onValueChange={setGruppeVerwendetValue}
                            label='Gruppe verwendet'
                            labelMode='floating'
                            stylingMode='outlined'
                            onContentReady={handleContentReadySelectBox}
                        />
                    </div>
                    <div className="col-12 p-1 p-md-2">
                        <NumberBox
                            label="Hinweisfrist Doppelerfassung in Stunden"
                            labelMode="floating"
                            stylingMode="outlined"
                            onValueChange={setHinweisFrist}
                            value={hinweisFrist}
                            valueChangeEvent="input"
                            onContentReady={handleContentReadyTextBox}
                        />
                    </div>
                </div>
            </ScrollView>

        </Popup>
    </>
    )
}

export default UnterGruppeVerwaltePopup;


const unter_kategorie_bilder_items_func = () => {
    let result: number[] = [];
    for (let i = 0; i < 81; i++) result.push(i)
    return result
}

const UnterGruppenItem = ({ data }: any) => {
    return (
        <div className="custom_items_gruppen">
            <div className="text">{data}</div>
            <img
                src={"/Files/WinowigOnlineNT/Source/img/tatbestandgruppen/untergruppen/Untergruppe" + (data ? stretch0Left((data).toString(), 3) : "000") + '.png'}
                alt={"Untergruppen Bild nummer: " + data}
                onError={handleErrorImg}
                style={style_img} />
        </div>
    )
}