import { memo, useEffect } from 'react'
import { DataGrid } from "devextreme-react";
import { Column, ColumnChooser, Scrolling, Paging, Button, ColumnChooserSelection } from "devextreme-react/data-grid";
import { useMemo, useState } from "react";
import { handleContentReadyDataGrid } from "../../global/hilfs_functions";
import { setDataTemp, setTotalCount, useAppDispatch, useAppSelector } from "../../state";
import { get_end_spalten_to_db, get_fall_data_zusaetzlich } from "./hilfs_functions";
import CardBackPopup from "./card/card_front/card_footer/card_back_popup";
import './index.scss'
import { IFaelleFilter, IFallData, IFallDataZusaetzlich } from "./interfaces";
import CustomStore from "devextreme/data/custom_store";
import { FaelleAllColumns } from "../../sonstiges/columns";
import { GetData } from "../../global/communication";
import { default_spalten_t701, MAX_ITEMS_DATA_GRID } from "../../global/const";
import { useCallback } from 'react';
import Delete from './card/card_control/symbole/delete';
import { IColumn } from '../../global/interfaces';
import { get_faelle_filter } from '../../sonstiges/hilfs_functions';

interface IListenAnischtProps {
    dataGridRef: React.MutableRefObject<DataGrid<any, any>>
    fall_art: 1 | 2 | 3 | 4,
    filter: IFaelleFilter,
    setFilter: (filter: IFaelleFilter) => void,
    kfz_suche: boolean,
    refresh: boolean;
    handleRefresh: () => void,
}



const ListenAnsicht: React.FC<IListenAnischtProps> = ({ handleRefresh, refresh, dataGridRef, fall_art, filter, setFilter, kfz_suche }): JSX.Element => {
    //const dataGridRef = useRef() as React.MutableRefObject<DataGrid<any, any>>;
    const [cardBackPopup, setCardBackPopup] = useState<{ show: boolean, item: IFallData | null, itemZus: IFallDataZusaetzlich | null }>({
        show: false,
        item: null,
        itemZus: null,
    });

    const handleHidePopup = useCallback(() => {
        setCardBackPopup({
            show: false,
            item: null,
            itemZus: null,
        });
    }, []);

    return (
        <div className="data_grid_container  listen_ansicht_grid data_grid_with_header mt-2">
            {(cardBackPopup.show && cardBackPopup.item !== null && cardBackPopup.itemZus !== null) &&
                <CardBackPopup handleClickHidePopup={handleHidePopup} item={cardBackPopup.item} itemZus={cardBackPopup.itemZus} />
            }
            <ListenAnsichtDataGrid handleRefresh={handleRefresh} refresh={refresh} fall_art={fall_art} setCardBackPopup={setCardBackPopup} dataGridRef={dataGridRef} filter={filter} setFilter={setFilter} kfz_suche={kfz_suche} />
        </div>

    )
}

export default memo(ListenAnsicht);

interface IListenAnsichtDataGridProps {
    dataGridRef: React.MutableRefObject<DataGrid<any, any>>,
    setCardBackPopup: (obj: any) => void,
    fall_art: 1 | 2 | 3 | 4,
    filter: IFaelleFilter,
    setFilter: (filter: IFaelleFilter) => void,
    kfz_suche: boolean,
    refresh: boolean,
    handleRefresh: () => void,
}


const ListenAnsichtDataGrid: React.FC<IListenAnsichtDataGridProps> = memo(({ handleRefresh, refresh, dataGridRef, setCardBackPopup, fall_art, filter, setFilter, kfz_suche }) => {
    const dispatch = useAppDispatch();
    const konfiguration_spalten = useAppSelector((state) =>
        fall_art === 4 ? state.store_einstellungen.konfiguration.t701_abs_spalten :
            fall_art === 3 ? state.store_einstellungen.konfiguration.t701_aowi_spalten :
                state.store_einstellungen.konfiguration.t701_vowi_spalten);
    const columns = useMemo(() => get_columns(konfiguration_spalten, fall_art), [konfiguration_spalten, fall_art]);
    const temp_data = useAppSelector((state) => state.store_temp_data.temp_data_satz);
    const store_end = useMemo(() => {
        if (temp_data) {
            return [temp_data]
        } else {
            return store(fall_art, kfz_suche, filter, dispatch)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [temp_data, fall_art, kfz_suche, filter, dispatch, refresh]);


    const handleRowClick = useCallback((e: any) => {
        let data: IFallData = e.data;
        // let data: IFallData[] = generet_data(1, 1, 1);
        let dataZus: IFallDataZusaetzlich = get_fall_data_zusaetzlich(data);

        setCardBackPopup({
            show: true,
            item: data,
            itemZus: dataZus,
        });
    }, [setCardBackPopup]);

    const handleOptionChange = useCallback((e: any) => {
        get_end_spalten_to_db(e, konfiguration_spalten, FaelleAllColumns, dispatch, fall_art === 4 ? "T701_ABS" : fall_art === 3 ? "T701_AOWI" : "T701_VOWI")

    }, [konfiguration_spalten, dispatch, fall_art]);

    const RenderButtonFallDelete = useCallback((e: any) => {
        let data: IFallData = e.data;
        return (
            <Delete item={data} callback={handleRefresh} />
        )
    }, [handleRefresh]);

    useEffect(() => {
        dataGridRef.current && dataGridRef.current.instance.hideColumnChooser();
    }, [fall_art, dataGridRef]);
    return (
        <DataGrid
            dataSource={store_end}
            allowColumnResizing={true}
            columnAutoWidth={false}
            renderAsync={true}
            //columnMinWidth={100}
            rowAlternationEnabled={true}
            focusedRowEnabled={false}
            remoteOperations={true}
            noDataText={"Keine Fälle"}
            onRowClick={handleRowClick}
            hoverStateEnabled={true}
            className="cursor_pointer faelle_listen_ansicht"
            ref={dataGridRef}
            //  height={"auto"} Soll dieses Auto hier rein? soll bei größere Mengen getestet werden.
            //height={"200px"}
            //pager={undefined}
            wordWrapEnabled={true}

            // keyExpr={['lfdnr']}
            onOptionChanged={handleOptionChange}//handleOptionsChange}
            onContentReady={handleContentReadyDataGrid}
        >
            <Scrolling mode="virtual" rowRenderingMode="virtual" preloadEnabled={true} />
            {/**
         * 
         * Wenn man ganz am Ende scrollt und nach oben geht wird trotzdem eine Anfrage zum Server geschicht mit skip: totalCount. kann man das überhaupt verhindern?
         * so ist aber richtig. Es wird nur an die DOM geschrieben, was man sieht
         *  */}
            <Paging defaultPageSize={MAX_ITEMS_DATA_GRID / 2} /> {/** nur fälle die Hälfte, wird aufm Server viel gemacht */}
            <ColumnChooser enabled={true} mode="select" title="Spalten auswählen" allowSearch={true} sortOrder="asc">
                <ColumnChooserSelection
                    selectByClick={true} />
            </ColumnChooser>
            {
                columns.map((column) => {
                    return (
                        <Column
                            dataField={column.dataField}
                            caption={((column.caption === "KFZ-Kennzeichen") && (fall_art === 3)) ? "Kennung" : column.caption}
                            dataType={column.dataType}
                            visible={column.visible}
                            key={column.dataField}
                            cellRender={column.cellRender}
                            alignment={column.alignment}
                            allowSorting={column.allowSorting}
                            minWidth={column.minWidth}
                            showInColumnChooser={column.showInColumnChooser}
                        />
                    )
                })
            }


            <Column type="buttons" showInColumnChooser={false}>
                <Button
                    cssClass={'d-inline-block'}
                    render={RenderButtonFallDelete}
                />
            </Column>
        </DataGrid>
    )
});






const get_columns = (konfiguration_spalten: string, fall_art: number): IColumn[] => {
    if (!konfiguration_spalten) {
        konfiguration_spalten = default_spalten_t701;
    }
    let arr: string[] = konfiguration_spalten.split(';').filter(i => i).map(i => i.trim());
    const get_bezeichnung = (s: string) => {
        if (!s) return '';
        switch (s) {
            case "aktenzeichen":
                return "f010.Akz"
            case "kassenzeichen":
                return "f010.Kassenzeichen"
            case "strasse":
                return "f010.strasse"
            case "ort":
                return "f010.ort"
            case "Kennzeichenart":
                return "f010.KzArt"
            case "zeugen":
                return "f010.Zeugen"
            case "bar":
                return "f010.bar"
            case "Handyparken":
                return "f010.MPStatus"
            case "Tatbestandsnummer":
                return "f010.tatbestandsnummer"
            case "loeschgrund":
                return "f010.Grund"
            case "notiz":
                return "f010.Notiz"
            default:
                return s;
        }
    }
    let bezeichnug: string = "";
    let temp_columns = [...FaelleAllColumns];
    temp_columns.forEach((item) => {
        item.visible = false;
        item.showInColumnChooser = true
        if ((item.caption === "GPS") && (![1, 3].includes(fall_art))) //MD 11.08.2023 GPS nur bei AOWI VOWI aufnehmen
            item.showInColumnChooser = false

        //   return item;
    })
    for (let i of arr) {
        bezeichnug = get_bezeichnung(i);
        for (let j of temp_columns) {
            if (j.dataField === bezeichnug) {
                j.visible = true;
                break;
            }
        }
    }
    return temp_columns;

}

const store = (fall_art: number, kfz_suche: boolean, filter: IFaelleFilter, dispatch: any) => new CustomStore({
    key: 'lfdnr',
    errorHandler: (error: any) => {
        console.log(error.message);
    },
    load(loadOptions: any) {

        let skip_0: boolean = loadOptions.isLoadingAll || (loadOptions["skip"] === 0) || loadOptions.requireTotalCount
        //Achtung isLoadingAll => pdf Download
        return GetData(
            {
                Operation: fall_art === 1 ?
                    "GetFaelleVerkehr" : fall_art === 2 ?
                        "GetFaelleFliessverkehr" : fall_art === 3 ?
                            "GetFaelleAOWI" : "GetFaelleABS",
                skip: skip_0 ? "0" : loadOptions["skip"].toString(),
                take: loadOptions.isLoadingAll ? "1000" : loadOptions["take"].toString(),
                matchcodeSuche: !kfz_suche ? "Nein" : "Ja",
                filter: get_faelle_filter(filter),
                sortSpalte: loadOptions.sort ? loadOptions.sort[0].selector : "",
                sortRichtung: loadOptions.sort ? loadOptions.sort[0].desc ? "DESC" : "ASC" : "",
            }, undefined, false
        )
            .then((res) => {
                if (!res) return {
                    data: [],
                    totalCount: 0,
                }
                if (res.erfolg === false) {
                    dispatch(setDataTemp({ data: [], new_data: true }));
                    return {
                        data: [],
                        totalCount: 0,
                    }
                } else {
                    if (res.data.length === 0) {
                    } else {
                        dispatch(setDataTemp({ data: res.data, new_data: skip_0 }));
                        dispatch(setTotalCount(res.end_data ? res.data.length : res.totalCount));
                    }
                    return {
                        data: res.data,
                        totalCount: res.end_data ? res.data.length : res.totalCount,
                    }
                }
            })
            .catch(() => { throw new Error('Data Loading Error'); });
        //   }


        //  }
    }

});