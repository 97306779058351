import React, { useEffect, memo, useRef, useCallback, useState, useMemo } from 'react';
import TreeView from 'devextreme-react/tree-view';
import * as events from 'devextreme/events';
import { ContextMenu, LoadPanel } from 'devextreme-react';
import { TextBox, Button as TextBoxButton } from 'devextreme-react/text-box';
import { setAlert, setConfirm, setKonfiguratorBearbeitet, setToast, useAppDispatch } from '../../../state';
import { Computer, TreeViewNodeKonfigurator } from '.';
import KonfiguratorEintragVerwaltePopup from './add_bearbeite_konfigurator_eintrag';
import UmbennenPopup from './umbennen_popup';
import { GetData } from '../../../global/communication';
import { icon_copy, icon_delete, icon_folder, icon_info, icon_i_cursor, icon_laptop, icon_paste } from '../../../global/const';
import { handleContentReadyTreeView, handleContentReadyTextBox } from "../../../global/hilfs_functions";


interface INavigationProps {
  children: React.ReactNode,
  selectedItemChanged: (event: any) => void,
  openMenu: () => void,
  collapseAll: Boolean,
  setSelectedItem: (item: TreeViewNodeKonfigurator | undefined) => void,
  allComputer: Computer[],
  setAllComputer: (allComputer: Computer[]) => void,
  get_data: () => void,
  searchValue: string,
  setSearchValue: (searchValue: string) => void,
}
const place_holder_suche = { placeholder: "Suche" };

const ContextMenuItems = [
  { id: 0, text: "Computer einfügen", icon: icon_paste },
  { id: 1, text: "Computer kopieren", icon: icon_copy },
  { id: 2, text: "Computer umbennen", icon: icon_i_cursor },
  { id: 3, text: "Computer löschen", icon: icon_delete },
  { id: 4, text: "Bereich einfügen", icon: icon_paste },
  { id: 5, text: "Bereich kopieren", icon: icon_copy },
  { id: 6, text: "Bereich umbennen", icon: icon_i_cursor },
  { id: 7, text: "Bereich löschen", icon: icon_delete },
]
const KonfiguratorNav: React.FC<INavigationProps> = ({ searchValue, setSearchValue, get_data, setAllComputer, allComputer, setSelectedItem, children, collapseAll, openMenu, selectedItemChanged }): JSX.Element => {
  const treeViewRef = useRef() as React.MutableRefObject<TreeView<any>>;
  const wrapperRef = useRef();
  const [isloading, setisloading] = useState(false);
  const items = useMemo(() => {
    setisloading(false);
    return allComputer.map((computer, i) => {
      return {
        id: i.toString(),
        text: computer.name,
        icon: icon_laptop,
        items: computer.bereiche.map((bereich, j) => {
          return {
            id: i.toString() + '_' + j.toString(),
            text: bereich,
            icon: icon_folder,
            parentId: i.toString(),
            parentText: computer.name,
          }
        })
      }
    })
  }
    , [allComputer]) as TreeViewNodeKonfigurator[];
  const refContextMenu = useRef() as React.MutableRefObject<ContextMenu<any>>;
  const [einfuegen, setEinfuegen] = useState({ computername: "", bereichname: "", showPopUpEinfuegen: false });

  const [umbennenPopUp, setUmbennenPopUp] = useState<{ show: boolean, computername_alt: string, bereichname_alt: string, key: string }>({
    show: false,
    computername_alt: "",
    bereichname_alt: "",
    key: "",
  });
  const dispatch = useAppDispatch();

  const getWrapperRef = useCallback((element) => {
    const prevElement = wrapperRef.current;
    if (prevElement) {
      events.off(prevElement, 'dxclick');
    }

    wrapperRef.current = element;
    events.on(element, 'dxclick', () => {
      openMenu();
    });
  }, [wrapperRef, openMenu]);

  const ClickItem = useCallback(({ itemData, event, node }: any) => {

    setSelectedItem(itemData);
    if (itemData && itemData.id.split("_").length === 2) {
      selectedItemChanged(event);
    }
    const nodeTreeView = treeViewRef.current as any;
    const treeView = nodeTreeView!.instance;
    if (node.key.split("_").length === 1) {
      if (!node.expanded) {
        treeView.expandItem(itemData);
      } else {
        treeView.collapseItem(itemData);
      }
    } else {
      //MD 30.01.2023 aber nicht bei Bereichswechsel. Es reicht bei Menü wechsel
      /*
      if (konfigurator_bearbeitet) {
        dispatch(setKonfigSupportZugangAuth(false))
      }*/

      treeView.selectItem(itemData);
    }
  }, [treeViewRef, selectedItemChanged, setSelectedItem]);

  //( treeViewRef && treeViewRef.current) && console.log(treeViewRef.current.instance.getSelectedNodes())
  const handleCloseUmbennen = useCallback((new_name?: string) => {
    setSelectedItem(undefined);
    if (new_name) {
      umbennenPopUp.bereichname_alt === "" ?
        setAllComputer(change_computer_name([...allComputer], umbennenPopUp.computername_alt, new_name)) :
        setAllComputer(change_bereich_name([...allComputer], umbennenPopUp.computername_alt, umbennenPopUp.bereichname_alt, new_name));

    }
    setUmbennenPopUp({ show: false, computername_alt: "", bereichname_alt: "", key: "" })

  }, [setSelectedItem, umbennenPopUp, allComputer, setAllComputer]);

  /*
  const handleOKDelete = useCallback(() => {
    GetData({
      Operation: (confirmLoeschen.computername && confirmLoeschen.bereichname) ? "DeleteBereich" : "DeleteComputer",
      Computername: confirmLoeschen.computername,
      Bereich: confirmLoeschen.bereichname,
    })
      .then((res) => {
        if (!res || !res.erfolg) {
          dispatch(setAlert({
            text: (res && res.hinweis) ? res.hinweis : "Es ist ein Fehler aufgetreten. Die Änderungen konnten nicht vorgenommen werden.",
            type: "Fehler",
          }))
          return
        }
        dispatch(setToast({
          message: (confirmLoeschen.computername && confirmLoeschen.bereichname) ? "Den Bereich " + confirmLoeschen.bereichname + " aus dem Computer " + confirmLoeschen.computername + " wurde erfolgreich gelöscht." : "Den Computer " + confirmLoeschen.computername + " wurde erfolgreich gelöscht.",
          type: "success",
          position: "bottom center"
        }));


        setSelectedItem(undefined);
        if (confirmLoeschen.computername && confirmLoeschen.bereichname) {
          let new_allComputer = [...allComputer]
          for (let i of new_allComputer) {
            if (i.name === confirmLoeschen.computername) {
              i.bereiche.splice(i.bereiche.indexOf(confirmLoeschen.bereichname), 1);
              break;
            }
          }
          setAllComputer(new_allComputer);
        } else {
          let new_allComputer = [...allComputer]
          for (let i in new_allComputer) {
            if (new_allComputer[i].name === confirmLoeschen.computername) {
              new_allComputer.splice(parseInt(i), 1);
              break;
            }
          }
          setAllComputer(new_allComputer);
        }

        //MD new 12.01.2023
        dispatch(setKonfiguratorBearbeitet(true));
      });
    setConfirmLoeschen({ show: false, computername: "", bereichname: "" })
  }, [confirmLoeschen, dispatch, setSelectedItem, setAllComputer, allComputer]);
*/

  const callback_delete = useCallback((computername: string, bereichname: string) => {
    setSelectedItem(undefined);
    if (computername && bereichname) {
      let new_allComputer = [...allComputer]
      for (let i of new_allComputer) {
        if (i.name === computername) {
          i.bereiche.splice(i.bereiche.indexOf(bereichname), 1);
          break;
        }
      }
      setAllComputer(new_allComputer);
    } else {
      let new_allComputer = [...allComputer]
      for (let i in new_allComputer) {
        if (new_allComputer[i].name === computername) {
          new_allComputer.splice(parseInt(i), 1);
          break;
        }
      }
      setAllComputer(new_allComputer);
    }

    //MD new 12.01.2023
    dispatch(setKonfiguratorBearbeitet(true));
  }, [allComputer, dispatch, setAllComputer, setSelectedItem]);

  const handleOnItemContextMenu = useCallback((e: any) => {
    //das Element an Contextmenuitem anhängen. Danach müssen wir drauf zugreifen
    if (e.itemData.id.split("_").length === 1) {
      //Computer
      (einfuegen.computername && !einfuegen.bereichname) ?
        refContextMenu.current.instance.option('items[0].visible', true) :
        refContextMenu.current.instance.option('items[0].visible', false);

      refContextMenu.current.instance.option('items[1].visible', true);
      refContextMenu.current.instance.option('items[2].visible', true);
      refContextMenu.current.instance.option('items[3].visible', true);
      einfuegen.bereichname ?
        refContextMenu.current.instance.option('items[4].visible', true) :
        refContextMenu.current.instance.option('items[4].visible', false);
      refContextMenu.current.instance.option('items[5].visible', false);
      refContextMenu.current.instance.option('items[6].visible', false);
      refContextMenu.current.instance.option('items[7].visible', false);
    } else if (e.itemData.id.split("_").length === 2) {
      //Bereiche         
      (einfuegen.computername && !einfuegen.bereichname) ?
        refContextMenu.current.instance.option('items[0].visible', true) :
        refContextMenu.current.instance.option('items[0].visible', false);
      refContextMenu.current.instance.option('items[1].visible', false);
      refContextMenu.current.instance.option('items[2].visible', false);
      refContextMenu.current.instance.option('items[3].visible', false);

      einfuegen.bereichname ?
        refContextMenu.current.instance.option('items[4].visible', true) :
        refContextMenu.current.instance.option('items[4].visible', false);
      refContextMenu.current.instance.option('items[5].visible', true);
      refContextMenu.current.instance.option('items[6].visible', true);
      refContextMenu.current.instance.option('items[7].visible', true);
    }

    //Zuerst alles auf undefined setzten. Sonst werden die Objekte zusammen geführt
    refContextMenu.current.instance.option('items[0].element', undefined);
    refContextMenu.current.instance.option('items[1].element', undefined);
    refContextMenu.current.instance.option('items[2].element', undefined);
    refContextMenu.current.instance.option('items[3].element', undefined);
    refContextMenu.current.instance.option('items[4].element', undefined);
    refContextMenu.current.instance.option('items[5].element', undefined);
    refContextMenu.current.instance.option('items[6].element', undefined);
    refContextMenu.current.instance.option('items[7].element', undefined);

    refContextMenu.current.instance.option('items[0].element', e.itemData);
    refContextMenu.current.instance.option('items[1].element', e.itemData);
    refContextMenu.current.instance.option('items[2].element', e.itemData);
    refContextMenu.current.instance.option('items[3].element', e.itemData);
    refContextMenu.current.instance.option('items[4].element', e.itemData);
    refContextMenu.current.instance.option('items[5].element', e.itemData);
    refContextMenu.current.instance.option('items[6].element', e.itemData);
    refContextMenu.current.instance.option('items[7].element', e.itemData);
  }, [einfuegen]);

  const handleOnItemClickContextMenu = useCallback((e: any) => {
    if (e.itemData.id === 0) {
      setEinfuegen({ ...einfuegen, showPopUpEinfuegen: true })
    } else if (e.itemData.id === 1) {
      setEinfuegen({
        ...einfuegen,
        computername: e.itemData.element.text,
        bereichname: "",
      });
    } else if (e.itemData.id === 2) {
      setUmbennenPopUp({
        computername_alt: e.itemData.element.text,
        bereichname_alt: "",
        key: e.itemData.element.id,
        show: true,
      });
    } else if (e.itemData.id === 3) {
      delete_computer_bereich(dispatch, callback_delete, e.itemData.element.text, "");
    } else if (e.itemData.id === 4) {
      setEinfuegen({
        ...einfuegen,
        computername: (e.itemData.element.parentText ? e.itemData.element.parentText : e.itemData.element.text) + '_' + einfuegen.computername,
        bereichname: (e.itemData.element.parentText ? e.itemData.element.text : "") + '_' + einfuegen.bereichname,
        showPopUpEinfuegen: true
      })
    } else if (e.itemData.id === 5) {
      setEinfuegen({
        ...einfuegen,
        computername: e.itemData.element.parentText,
        //   computername: "",
        bereichname: e.itemData.element.text,
      });
    } else if (e.itemData.id === 6) {
      setUmbennenPopUp({
        computername_alt: e.itemData.element.parentText,
        bereichname_alt: e.itemData.element.text,
        key: e.itemData.element.id,
        show: true,
      });

    } else if (e.itemData.id === 7) {
      delete_computer_bereich(dispatch, callback_delete, e.itemData.element.parentText, e.itemData.element.text);
    }
  }, [einfuegen, callback_delete, dispatch]);

  useEffect(() => {
    // bei small und Medium wird CollaseAll gemacht
    if (collapseAll) {
      (treeViewRef.current && treeViewRef.current.instance) &&
        treeViewRef.current.instance.collapseAll();
    }
  }, [collapseAll]);

  useEffect(() => {
    (searchValue.length > 0 && treeViewRef.current && treeViewRef.current.instance) &&
      treeViewRef.current.instance.expandAll();
  }, [items, searchValue]);


  const callbackChangeKonfigurator = useCallback(() => {
    setSelectedItem(undefined);
    get_data();
    setEinfuegen({ bereichname: "", computername: "", showPopUpEinfuegen: false });
  }, [setSelectedItem, get_data]);


  /*
     const handleContentReady = useCallback((data: any) => {
      if (selectedItem){
        if(selectedItem.id.split("_").length === 1){
          (treeViewRef.current && treeViewRef.current.instance) && treeViewRef.current.instance.scrollToItem(selectedItem);
        }else{
          // das könnte ich vielleicht gebrauchen
          //Bei dem Computer bleiben und nicht scrollen
          (treeViewRef.current && treeViewRef.current.instance && selectedItem.parentId) && treeViewRef.current.instance.scrollToItem(selectedItem.parentId);
        }
      }
     }, [selectedItem]);
  
    //  text: "<Suchbegriff> = Suche in den Werten" + "\n".charCodeAt(index); +"+<Suchbegriff> = Suche in Schlüssel und Bereich\n"
        // + ""
     */

  const handleClickInfo = useCallback(() => {
    dispatch(setAlert({
      text: "<Suchbegriff>\t= Suche in den Werten\n"
        + "+<Suchbegriff>\t= Suche in Schlüssel und Bereich\n"
        + "<Suchbegriff>++\t= zeigt alle Schlüssel des Bereichs, wenn ein Wert gefunden wurde\n"
        + "#pfade\t\t\t= sucht alle Pfadangaben\n"
        + "#doppelte\t\t= doppelte Schlüssel bei case-sensitiven Datenbanken (z.B Oracle)",
      title: "Info zur Suche",
      type: 'Info',
    }));

  }, [dispatch]);

  const handleClickReload = useCallback(() => {
    setisloading(true);
    get_data();
    (treeViewRef.current && treeViewRef.current.instance) &&
      treeViewRef.current.instance.expandAll();
  }, [get_data]);

  const memo_options_info = useMemo(() => {
    return {
      icon: icon_info,
      onClick: handleClickInfo,
    }
  }, [handleClickInfo]);

  return (
    <div
      className={'konfigurator_container'}
      ref={getWrapperRef}
    >
      {children}
      {einfuegen.showPopUpEinfuegen &&
        <KonfiguratorEintragVerwaltePopup
          einfuegen_computer={(einfuegen.computername !== "" && einfuegen.bereichname === "")}
          einfuegen_bereich={(einfuegen.computername !== "" && einfuegen.bereichname !== "")}
          row={{ f001: einfuegen.computername, f002: einfuegen.bereichname, f003: "", f004: "", f005: "" }}
          callback={callbackChangeKonfigurator}
          allComputer={allComputer}
        />}
      {umbennenPopUp.show && <UmbennenPopup art={umbennenPopUp.bereichname_alt === "" ? "Computer" : "Bereich"} bereichname_alt={umbennenPopUp.bereichname_alt} computername_alt={umbennenPopUp.computername_alt} setShowPopUp={handleCloseUmbennen} />}
      {isloading && <LoadPanel visible={true} />}
      <TextBox
        stylingMode='underlined'
        //label="Suche"
        placeholder="Suche"
        labelMode="static"
        //labelMode='floating'
        mode={"text"}
        className="mb-3 ml-2 pl-2 suche_text_feld"
        value={searchValue}
        onValueChange={setSearchValue}
        onEnterKey={handleClickReload}
        maxLength={60}
        onContentReady={handleContentReadyTextBox}
      >
        <TextBoxButton
          name='passwortLogin'
          location="after"
          options={memo_options_info}
        />
      </TextBox>
      <div className="nav_container_konfigurator">

        <TreeView
          id="treeview_konfigurator"
          dataSource={items}
          ref={treeViewRef}
          width={"100%"}
          keyExpr={"id"}
          selectionMode={'single'}
          focusStateEnabled={true}
          searchMode={"contains"}
          noDataText="Keine Ergebnisse"
          searchEnabled={false}
          scrollDirection="both"
          expandAllEnabled={true}
          searchEditorOptions={place_holder_suche}
          onItemClick={ClickItem}
          onContentReady={handleContentReadyTreeView}
          // createChildren={handleCreateChild}
          // onContentReady={handleContentReady}
          //ContextMenu auch nicht vergessen
          onItemContextMenu={handleOnItemContextMenu}
        >
        </TreeView>
        <ContextMenu
          ref={refContextMenu}
          dataSource={ContextMenuItems}
          target="#treeview_konfigurator  .dx-treeview-item"
          onItemClick={handleOnItemClickContextMenu}
        />
      </div>
    </div>
  );
}

export default memo(KonfiguratorNav);

const delete_computer_bereich = (dispatch: any, callback: any, computername: string, bereichname: string) => {
  dispatch(setConfirm({
    body:
      <div className="alert alert-danger">
        {(computername && bereichname) ?
          <p>Wollen Sie den Bereich <b>{bereichname}</b> aus <b>{computername}</b> wirklich löschen?</p>
          :
          (computername && !bereichname) ?
            <p>Wollen Sie den Computer <b>{computername}</b> wirklich löschen?</p> : <></>
        }
      </div>,
    on_ok: () => {
      GetData({
        Operation: (computername && bereichname) ? "DeleteBereich" : "DeleteComputer",
        Computername: computername,
        Bereich: bereichname,
      }, dispatch)
        .then((res) => {
          if (!res) return
          dispatch(setToast({
            message: (computername && bereichname) ? "Den Bereich " + bereichname + " aus dem Computer " + computername + " wurde erfolgreich gelöscht." : "Den Computer " + computername + " wurde erfolgreich gelöscht.",
            type: "success",
            position: "bottom center"
          }));
          callback(computername, bereichname);
        })
    },
    title: (computername && bereichname) ? 'Bereich löschen' : "Computer löschen"

  }))
}


/*
const createChildren = (data: any, allComputer: Computer [], bereicheSelected: {computername: string, bereiche: string[]}) => {
    if(!data){
        //GetAllComputer  
        let result: any = [];
        if (!allComputer) return result;
        for (let i = 0; i < allComputer.length; i += 1) {
          let item = {
              id: i.toString(),
              text: allComputer[i].name,
              icon: icon_laptop,
          }
          result.push(item);
      }
      return result;
    }else{
      if (data.itemData.text !== bereicheSelected.computername){
        return []
      }
      let result = [];
      for (let i = 0; i < bereicheSelected.bereiche.length; i += 1) {
        let item: TreeViewNodeKonfigurator = {
            id: data.key + '_' + i.toString(),
            text: bereicheSelected.bereiche[i],
            parentId: data.key,
            hasItems: false,
            icon: icon_folder,
            parentText: data.itemData.text
        }
        result.push(item);
      }
      return result;
}
}
*/
const change_computer_name = (allComputer: Computer[], computername_alt: string, computername_new: string) => {
  let result = allComputer;
  for (let i in result) {
    if (result[i].name === computername_alt) {
      result[i].name = computername_new;
      break;
    }
  }
  return result;
}

const change_bereich_name = (allComputer: Computer[], computername: string, bereichname_alt: string, bereichname_new: string) => {
  let result = allComputer;
  for (let i in result) {
    if (result[i].name === computername) {
      for (let j in result[i].bereiche) {
        if (result[i].bereiche[j] === bereichname_alt) {
          result[i].bereiche[j] = bereichname_new;
          break;
        }
      }
    }
  }
  return result;
}
